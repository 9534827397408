import { makeObservable, observable, action, reaction, when } from 'mobx';
import { AccountInfo, IAccountApi, StageData, useRaidToolkitApi } from '@raid-toolkit/webclient';
import { AccountManagerStore } from './AccountManagerStore';
import { AccountStore } from './AccountStore';
import {
  Affinity,
  AcademyData,
  ChampionInstance,
  ChampionTypeEx,
  FactionId,
  Rank,
  Rarity,
  Role,
  GearSlot,
  ArtifactRarity,
  StatKind,
  StatBonus,
  ArtifactStatBonus,
} from 'Types';
import { useStaticData } from 'Data';
import { createStore } from './Forage';
import { parseEnum } from 'Helpers';
import { ArtifactType } from 'Types/Entities/ArtifactType';
import { ArtifactSetKindId } from '../Types/Enums/ArtifactSetKindId';

type HeroId = number;

export enum ConnectionState {
  None,
  Connecting,
  Connected,
}

const unknownChampion: ChampionTypeEx = {
  typeId: 10,
  slug: 'unknown',
  name: 'Unknown',
  avatarImageUrl: 'https://ik.imagekit.io/raidchamps/heroAvatars/_placeholder.png',
  affinity: Affinity.void,
  faction: FactionId.unknown,
  rarity: Rarity.mythical,
  ascended: 0,
  role: Role.support,
  skillIds: [],
  baseStats: {
    health: 87,
    attack: 79,
    defense: 109,
    speed: 98,
    resistance: 30,
    accuracy: 0,
    criticalchance: 15,
    criticaldamage: 50,
    criticalheal: 50,
  },
  indexed: {
    tags: [],
  },
  ratings: {
    detailed: {},
    overall: 10,
  },
};

function readStatBonus<T extends StatBonus>({ kind, ...rest }: T & { kind?: string }): T {
  return {
    ...rest,
    statKind: parseEnum(kind!, StatKind),
  } as T;
}

function readArtifactStatBonus<T extends ArtifactStatBonus>({
  kind,
  glyphPower,
  level,
  ...rest
}: T & { kind?: string; glyphPower?: number; level?: number }): T {
  return {
    ...rest,
    statKind: parseEnum(kind!, StatKind),
    glyphPower: glyphPower || 0,
    level: level || 0,
  } as T;
}
export class RTKClient {
  public state: ConnectionState = ConnectionState.None;
  private accountApi!: IAccountApi;
  private autoConnect: boolean = false;
  private forage = createStore('rtk');

  constructor(private readonly accountMgr: AccountManagerStore) {
    makeObservable<RTKClient, 'updateAccountInfos' | 'accountUpdated' | 'refreshAccount'>(this, {
      connect: action.bound,
      disconnect: action.bound,
      updateAccountInfos: action.bound,
      accountUpdated: action.bound,
      refreshAccount: action.bound,
      state: observable,
    });
    reaction(
      () => {
        return this.state === ConnectionState.Connected && this.accountMgr.selectedAccount?.isDirty
          ? this.accountMgr.selectedAccount
          : undefined;
      },
      (selectedAccount) => {
        selectedAccount?.isDirty && this.refreshAccount(selectedAccount);
      }
    );
    Promise.all([this.forage.getItem<boolean>('auto-connect')]).then((args) => this.initializeData(...args));
  }

  initializeData(autoConnect: boolean | null): any {
    if (autoConnect !== null) {
      if ((this.autoConnect = autoConnect)) {
        this.connect();
      }
    }
  }

  get autoConnectEnabled() {
    return this.autoConnect;
  }

  setAutoConnectEnabled(value: boolean) {
    this.autoConnect = value;
    this.forage.setItem('auto-connect', value);
  }

  connect() {
    if (this.state !== ConnectionState.None) return;

    this.state = ConnectionState.Connecting;
    this.accountApi = useRaidToolkitApi(IAccountApi);
    this.accountApi.on('updated', this.accountUpdated as any);
    this.accountApi.getAccounts().then(this.updateAccountInfos);

    this.setAutoConnectEnabled(true);
  }

  disconnect() {
    this.setAutoConnectEnabled(false);
    this.accountApi?.off('updated', this.accountUpdated as any);
    this.state = ConnectionState.None;
  }

  private updateAccountInfos(accounts: AccountInfo[]) {
    this.accountMgr.discoverAccounts(accounts);
    this.state = ConnectionState.Connected;
  }

  private async refreshAccount(account: AccountStore) {
    const { heroTypes } = useStaticData();
    //console.log(Object.getOwnPropertyNames(Object.getPrototypeOf(this.accountApi)));

    const accountDump = (await this.accountApi.getAccountDump(account.info.id)) as Record<number, number[]>;
    let stagePresetsData: Record<number, number[]>;
    if (accountDump !== undefined) {
      stagePresetsData = accountDump.stagePresets;
      //console.log(`accountDump`, accountDump);
      //console.log(`stagePresetsData`, stagePresetsData);
    }

    account.setStagePresets(stagePresetsData);

    const [heroes, artifacts, arenaData, academyData] = (await Promise.all([
      this.accountApi.getHeroes(account.info.id),
      this.accountApi.getArtifacts(account.info.id),
      this.accountApi.getArena(account.info.id),
      this.accountApi.getAcademy(account.info.id),
    ])) as [any[], ArtifactType[], any, AcademyData];

    account.setChampions(
      heroes
        .filter((hero) => !hero.deleted)
        .map<ChampionInstance>((hero) => ({
          key: `champion_${hero.id}`,
          instance: {
            id: hero.id,
            idOrigin: hero.idOrigin,
            empowerLevel: hero.empowerLevel,
            level: hero.level,
            rank: parseEnum(hero.rank, Rank),
            awakenRank: hero.awakenRank,
            blessing: hero.blessing,
            blessingResetUsed: hero.blessingResetUsed,
            exp: hero.exp,
            fullExp: hero.fullExp,
            deleted: hero.deleted,
            locked: hero.locked,
            inVault: hero.inVault,
            inDeepVault: hero.inDeepVault,
            marker: hero.marker,
            masteries: hero.masteries,
            assignedMasteryScrolls: hero.assignedMasteryScrolls,
            unassignedMasteryScrolls: hero.unassignedMasteryScrolls,
            totalMasteryScrolls: hero.totalMasteryScrolls,
            equippedArtifactIds: Object.fromEntries(
              Object.entries(hero.equippedArtifactIds).map<[GearSlot, number]>(([slot, id]) => [
                parseEnum(slot, GearSlot),
                id as number,
              ])
            ) as Partial<Record<GearSlot, number>>,
            skillLevelsByTypeId: hero.skillLevelsByTypeId,
          },
          type: heroTypes[hero.typeId] || unknownChampion,
        }))
    );

    account.setArtifacts(
      artifacts.map<ArtifactType>((artifact) => ({
        key: `artifact_${artifact.id}`,
        activated: artifact.activated,
        kindId: parseEnum(artifact.kindId, GearSlot),
        setKindId: parseEnum(artifact.setKindId, ArtifactSetKindId),
        ascendLevel: artifact.ascendLevel,
        ascendBonus: artifact.ascendBonus,
        ascendBonusValue: artifact?.ascendBonus?.value ? artifact.ascendBonus.value : 0,
        ascendBonusKind: artifact?.ascendBonus?.kind ? artifact.ascendBonus.kind : 'Unascended',
        ascendBonusIsAbsolute: artifact?.ascendBonus?.absolute === true ? artifact.ascendBonus.absolute : false,
        faction: parseEnum(artifact.faction, FactionId),
        failedUpgrades: artifact.failedUpgrades,
        id: artifact.id,
        level: artifact.level,
        price: artifact.price,
        primaryBonus: readArtifactStatBonus(artifact.primaryBonus),
        rank: artifact.rank,
        rarity: parseEnum(artifact.rarity, ArtifactRarity),
        revision: artifact.revision,
        secondaryBonuses: artifact.secondaryBonuses?.map(readArtifactStatBonus),
        seen: artifact.seen,
        sellPrice: artifact.sellPrice,
      }))
    );
    account.setArenaData({
      ...arenaData,
      greatHallBonuses: arenaData.greatHallBonuses.map(({ affinity, bonus, levels }: any) => ({
        affinity,
        bonus: bonus.map(readStatBonus),
        levels,
      })),
    });
    account.setAcademyData({
      guardians: Object.fromEntries(
        Object.entries(academyData.guardians).map(([factionName, byRarity]) => [
          parseEnum(factionName, FactionId),
          Object.fromEntries(
            Object.entries(byRarity).map(([rarityName, { assignedHeroes, bonuses }]) => [
              parseEnum(rarityName, Rarity),
              { assignedHeroes, bonuses: bonuses.map(readStatBonus) },
            ])
          ),
        ])
      ) as any,
    });

    account.clearDirty();
  }

  private accountUpdated([accountId]: [string]) {
    const account = this.accountMgr.accounts.get(accountId);
    if (!account) {
      this.accountApi
        .getAccounts()
        .then(this.updateAccountInfos)
        // re-enter after loading account info
        .then(() => this.accountUpdated([accountId]));
      return;
    }

    if (this.accountMgr.selectedAccount === account) {
      this.refreshAccount(account);
    } else {
      account?.setDirty();
    }
  }
}
