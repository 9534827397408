import React, { useEffect, useState } from 'react';
import './ChampListPage.css';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { BoundSortedEditor } from '../Library/BoundSortedEditor';
import { useSetting } from '../Hooks';
import { observer } from 'mobx-react-lite';
import {
  AyumiloveRatingFilter,
  BookValueFilter,
  ChosenArenaRatingFilter,
  ChosenHydraRatingFilter,
  ChosenDemonLordRatingFilter,
  ChosenDoomTowerRatingFilter,
  ChosenDragonRatingFilter,
  ChosenFactionWarsRatingFilter,
  ChosenFireKnightRatingFilter,
  ChosenIceGolemRatingFilter,
  ChosenRatingFilter,
  ChosenSpiderRatingFilter,
  DamageBasedOnFilter,
  FactionFilter,
  HellHadesAllDungeonsRatingFilter,
  HellHadesArenaAttackRatingFilter,
  HellHadesArenaDefenseRatingFilter,
  HellHadesHydraRatingFilter,
  HellHadesArenaOverallRatingFilter,
  HellHadesDemonLordRatingFilter,
  HellHadesDoomTowerRatingFilter,
  HellHadesDragonRatingFilter,
  HellHadesDTBossAgrethRatingFilter,
  HellHadesDTBossAstranyxRatingFilter,
  HellHadesDTBossBommalRatingFilter,
  HellHadesDTBossBorgothRatingFilter,
  HellHadesDTBossCelestialGriffinRatingFilter,
  HellHadesDTBossIragothRatingFilter,
  HellHadesDTBossKuldathRatingFilter,
  HellHadesDTBossSorathRatingFilter,
  HellHadesFactionWarsRatingFilter,
  HellHadesFireKnightRatingFilter,
  HellHadesFWCrowdControlRatingFilter,
  HellHadesFWDamageRatingFilter,
  HellHadesFWDecreaseAttackRatingFilter,
  HellHadesFWDecreaseDefenseRatingFilter,
  HellHadesFWProtectionAndSupportRatingFilter,
  HellHadesFWReviverRatingFilter,
  HellHadesFWTurnMeterControlRatingFilter,
  HellHadesIceGolemRatingFilter,
  HellHadesRatingFilter,
  HellHadesSpiderRatingFilter,
  RankFilter,
  TagFilter,
  EquippedSetsFilter,
  MasteriesFilter,
  LevelFilter,
  AscendLevelFilter,
  EmpowerLevelFilter,
  RarityFilter,
  MaxedToggleFilter,
  ReadyToRankUpToggleFilter,
  LockedToggleFilter,
  InMasterVaultToggleFilter,
  MasteriesToggleFilter,
  MasteryScrollsToggleFilter,
  GearedToggleFilter,
  RoleFilter,
  AuraAreaFilter,
  AuraAffinityFilter,
  AuraStatFilter,
  AuraFlatIncreaseFilter,
  AuraPercentageFilter,
  AffinityFilter,
  ChosenScarcityRatingFilter,
  ChosenEndGameRatingFilter,
  ChosenProgressionRatingFilter,
  ChosenIronTwinsRatingFilter,
  StatusEffectMultiSelectFilter,
  StatusEffectThreeMultiSelectFilter,
  StatusEffectTwoMultiSelectFilter,
  InReserveVaultToggleFilter,
  AwakenRankFilter,
  InVaultToggleFilter,
  ChosenSandDevilRatingFilter,
  BookedAbilitiesToggleFilter,
  FactionGuardianToggleFilter,
  TargetTypesMultiSelectFilter,
  TargetTypesThreeMultiSelectFilter,
  TargetTypesTwoMultiSelectFilter,
  EffectKindsMultiSelectFilter,
  EffectKindsThreeMultiSelectFilter,
  EffectKindsTwoMultiSelectFilter,
  PassiveSingleToggleFilter,
  DupesToggleFilter,
  AdvancedStatusEffectAutocompleteFilter,
  AdvancedEffectKindAutocompleteFilter,
  BlessingFilter,
  PotentialFactionGuardianToggleFilter,
  HellHadesHardDragonRatingFilter,
  HellHadesHardFireKnightRatingFilter,
  HellHadesHardIceGolemRatingFilter,
  HellHadesHardSpiderRatingFilter,
  HellHadesIronTwinsRatingFilter,
  HellHadesSandDevilRatingFilter,
  AdvancedTargetTypesAutocompleteFilter,
  HellHadesArenaRolesRatingFilter,
  HellHadesPhantomShogunRatingFilter,
  ArenaRolesFilter,
  AyumiloveArcaneKeepRatingFilter,
  AyumiloveArenaDefenseRatingFilter,
  AyumiloveArenaOffenseRatingFilter,
  AyumiloveCampaignRatingFilter,
  AyumiloveCelestialGriffinRatingFilter,
  AyumiloveClanBossRatingFilter,
  AyumiloveDarkFaeRatingFilter,
  AyumiloveDoomTowerFloorsRatingFilter,
  AyumiloveDragonRatingFilter,
  AyumiloveDreadhornRatingFilter,
  AyumiloveEternalDragonRatingFilter,
  AyumiloveFactionWarsRatingFilter,
  AyumiloveFireKnightRatingFilter,
  AyumiloveForceKeepRatingFilter,
  AyumiloveFrostSpiderRatingFilter,
  AyumiloveHydraRatingFilter,
  AyumiloveIceGolemRatingFilter,
  AyumiloveIronTwinsRatingFilter,
  AyumiloveMagicKeepRatingFilter,
  AyumiloveMagmaDragonRatingFilter,
  AyumiloveMinotaurRatingFilter,
  AyumiloveNetherSpiderRatingFilter,
  AyumiloveSandDevilRatingFilter,
  AyumiloveScarabKingRatingFilter,
  AyumiloveSpiderRatingFilter,
  AyumiloveSpiritKeepRatingFilter,
  AyumiloveVoidKeepRatingFilter,
} from '../DataView/Filters';
import './SharedFilters.css';
import {
  ArtifactRarityFilter,
  ArtifactRankFilter,
  ArtifactLevelFilter,
  MaxLevelArtifactToggleFilter,
  EquippedArtifactToggleFilter,
  ArtifactSetsFilter,
  ArtifactSlotsFilter,
  ArtifactFactionFilter,
  ArtifactPrimaryStatToggleFilter,
  ArtifactFlatSubstatsToggleFilter,
  WorthRollingUpFurtherToggleFilter,
  AccessoriesOrArtifactsToggleFilter,
  ArtifactBestSubRollLevelFilter,
  ArtifactRequiredSubstatsFilter,
  ArtifactExcludedSubstatsFilter,
  NewArtifactToggleFilter,
  // StageOfTheGameToggleFilter,
  StageOfTheGamSelector,
  GlyphedToggleFilter,
  ArtifactSubstatToggleFilter,
  SellOrKeepToggleFilter,
  ArtifactSubstatsFilter,
  ArtifactSubstatsFourFilter,
  ArtifactSubstatsThreeFilter,
  ArtifactSubstatsTwoFilter,
  ArtifactAscendLevelFilter,
} from '../DataView/ArtifactFilters';
import { Button } from '@mui/material';
import $ from 'jquery';
import ResetFiltersButton from 'Components/Library/ResetFiltersButton';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';
import Tooltip from 'rc-tooltip';
import {
  AdvancedDurationRangeFilter,
  AdvancedCooldownRangeFilter,
  AdvancedA1SingleToggleFilter,
  AdvancedHitsRangeFilter,
  AdvancedSkillIndexRangeFilter,
  AdvancedChanceToPlacePercentRangeFilter,
  AdvancedFullyBookedChanceToPlacePercentRangeFilter,
  AdvancedFullyBookedCooldownRangeFilter,
  AdvancedMyChanceToPlacePercentRangeFilter,
  AdvancedMyCooldownRangeFilter,
} from 'Components/DataView/AdvancedAbilityFilters';
import SaveAndRemoveSavedFiltersButton from 'Components/Library/SaveAndRemoveSavedFiltersButton';
import { NextGenImage } from 'Components/Library';
import ToggleButton from '@mui/material/ToggleButton';
import { hasRole } from 'Components/Layout/Header';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: '16px' }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const SharedFilters: React.FC = observer(() => {
  const [tabIndex, setTabIndex] = useSetting('filter-tab');
  const onTabChange = React.useCallback((_event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  }, []);

  const [experimentalEnabled, setExperimentalEnabled] = useState(
    JSON.parse(localStorage.getItem('experimentalEnabled')) ?? false
  );

  const toggleExperimental = () => {
    const enabled = !experimentalEnabled;
    setExperimentalEnabled(enabled);
    localStorage.setItem('experimentalEnabled', enabled);
  };

  const { user, isAuthenticated, isLoading } = useAuth0();

  let roles = [];
  if (user && user['https://raidchamps.com/app_metadata'].roles) {
    if (user['https://raidchamps.com/app_metadata'].roles.length > 0) {
      roles = user['https://raidchamps.com/app_metadata'].roles;
    }
  }

  const [imageName, setImageName] = useState('advancedFeaturesPreview');

  useEffect(() => {
    const siteTheme = JSON.parse(localStorage.getItem('setting:site-theme'));
    if (siteTheme === 'light') {
      setImageName((prevName) => `${prevName}-lightmode`);
    }
  }, []);

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabIndex} onChange={onTabChange} aria-label="basic tabs example">
            <Tab label="Sorting Options" {...a11yProps(0)} />
            <Tab label="Basic Filters" {...a11yProps(1)} />
            <Tab className="AbilityFilters HideOnMyArtifactsPage" label="Ability Filters" {...a11yProps(2)} />
            <Tab className="RatingFilters HideOnMyArtifactsPage" label="Rating Filters" {...a11yProps(3)} />
            <Tab label="Hide Options" {...a11yProps(4)} />
          </Tabs>
        </Box>

        <div className="HowFiltersWork info">
          <span
            className="howFiltersWorkTooltip"
            title="Selecting multiple items within a single filter means that you want to view champs that have ANY of the selected items. You can then use additional filters to filter for champs that ALSO have ANY of the items that you select in another input. So when making multiple selections within a single filter it uses the OR operator. When making multiple selections in separate filters then it uses the AND operator."
          >
            How the filters work
          </span>
        </div>
        <TabPanel value={tabIndex} index={0}>
          <div className="DraggableSortOrderAndCaption-wrap">
            <p>
              <span>Drag them into any order</span>
              <span className="yellow">|</span>
              <span>Select each option you want to sort by</span>
            </p>
            <div className="DraggableSortOrder-wrap noselect">
              <BoundSortedEditor />
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          <div className="SaveAndRemoveSavedAndResetFiltersButton-wrap flex">
            <SaveAndRemoveSavedFiltersButton />
            <ResetFiltersButton />
          </div>
          <div className="filters">
            {/* // TODO: Still need to add Faction Alliance options to the Faction Filter */}
            <div className="row">
              <RarityFilter.Component />
              <AffinityFilter.Component />
              <RoleFilter.Component />
              <FactionFilter.Component />
              <DamageBasedOnFilter.Component />
              <AuraAreaFilter.Component />
              <AuraAffinityFilter.Component />
              <AuraStatFilter.Component />
            </div>
            <div className="row">
              <AuraFlatIncreaseFilter.Component />
              <AuraPercentageFilter.Component />
            </div>
            <div className="MyDataFilters-wrap">
              <div className="row">
                <div className="MyDataMultiSelectFilters-wrap">
                  <div>
                    <RankFilter.Component />
                    <TagFilter.Component />
                  </div>
                  <div className="flex multiSelectAutocompleteFilters-wrap">
                    <MasteriesFilter.Component />
                    <EquippedSetsFilter.Component />
                    <BlessingFilter.Component />
                  </div>
                </div>
              </div>
              <div className="row">
                <LevelFilter.Component />
                <AscendLevelFilter.Component />
                <EmpowerLevelFilter.Component />
                <AwakenRankFilter.Component />
              </div>
              <div className="row">
                <MaxedToggleFilter.Component />
                <ReadyToRankUpToggleFilter.Component />
                <LockedToggleFilter.Component />
                <div className="row">
                  <InVaultToggleFilter.Component />
                  <InMasterVaultToggleFilter.Component />
                  <InReserveVaultToggleFilter.Component />
                </div>
                <div className="row">
                  <MasteriesToggleFilter.Component />
                  <BookedAbilitiesToggleFilter.Component />
                  <MasteryScrollsToggleFilter.Component />
                  <FactionGuardianToggleFilter.Component />
                  <DupesToggleFilter.Component />
                  <GearedToggleFilter.Component />
                  <PotentialFactionGuardianToggleFilter.Component />
                </div>

                {/* My Artifacts page Only */}
                <div className="MyArtifactFilters-wrap">
                  {/* <MyArtifactsSearchFilter.Component /> */}
                  <div className="MyArtifactFilters-wrap">
                    <div className="ArtifactSlotAndStatsToggleFilters-wrap">
                      <div className="ArtifactPrimaryStatAndLevelFilters-wrap">
                        <div className="ArtifactMultiSelectFilters">
                          {/* TODO: Still need to figure out how to sort Set Kind and Slot to be listed in the correct order. */}
                          <ArtifactSlotsFilter.Component />
                          <ArtifactRarityFilter.Component />
                          <ArtifactRankFilter.Component />
                          <ArtifactFactionFilter.Component />
                          <ArtifactSetsFilter.Component />
                        </div>
                        {/* Not sure what is wrong with these
                         <div className="ArtifactSubstatsToggleFilter-wrap">
                          <ArtifactRequiredSubstatsFilter.Component />
                          <ArtifactExcludedSubstatsFilter.Component />
                        </div> */}
                        <div className="ArtifactSimpleToggleFilters-wrap">
                          <MaxLevelArtifactToggleFilter.Component />
                          <EquippedArtifactToggleFilter.Component />
                          <NewArtifactToggleFilter.Component />
                          <AccessoriesOrArtifactsToggleFilter.Component />
                          <GlyphedToggleFilter.Component />
                        </div>
                        <div className="ArtifactRangeFilters-wrap">
                          <ArtifactLevelFilter.Component />
                          <ArtifactAscendLevelFilter.Component />
                          <ArtifactBestSubRollLevelFilter.Component />
                        </div>
                        <div className="ArtifactPrimaryStatToggleFilter-wrap">
                          <ArtifactPrimaryStatToggleFilter.Component />
                          <div>
                            <ArtifactSubstatToggleFilter.Component />
                          </div>
                        </div>
                        <div className="ArtifactSubstatsOneThroughFourFilters-wrap">
                          <ArtifactSubstatsFilter.Component />
                          <ArtifactSubstatsTwoFilter.Component />
                          <ArtifactSubstatsThreeFilter.Component />
                          <ArtifactSubstatsFourFilter.Component />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="SubstatsToggleFilter-wrap">
                  <ArtifactFlatSubstatsToggleFilter.Component />

                  <div className="WorthRollingUpFurtherToggleFilter-wrap MyArtifactsPageOnly outline">
                    <div>
                      <ToggleButton value="check" selected={experimentalEnabled} onChange={toggleExperimental}>
                        Experimental Features
                      </ToggleButton>
                    </div>
                    {experimentalEnabled && (
                      <>
                        {!hasRole(roles, ['Content Creator', 'Advanced Features']) && (
                          <p>This will also require the Advanced Features power if I ever get it perfected.</p>
                        )}
                        <div className="StageOfTheGamSelector-wrap">
                          <p style={{ margin: '0 15px' }}>
                            First select the{' '}
                            <a
                              href="https://aftershockunited.gg/raid/blog/the-4-stages-of-progression-in-raid-shadow-legends"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              stage of the game
                            </a>{' '}
                            you are at if you haven't already using the input below...
                          </p>
                          <StageOfTheGamSelector.Component />
                        </div>
                        <WorthRollingUpFurtherToggleFilter.Component />
                        <SellOrKeepToggleFilter.Component />
                        <h4 className="redNote">
                          Use at your own risk, this is far from perfected. Taking feedback for this my{' '}
                          <a href="https://discord.gg/zhqmadUyys" target={'_blank'}>
                            Discord server
                          </a>
                          .
                        </h4>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>

        <TabPanel value={tabIndex} index={2}>
          <div className="SaveAndRemoveSavedAndResetFiltersButton-wrap flex">
            <SaveAndRemoveSavedFiltersButton />
            <ResetFiltersButton />
          </div>
          <div className="AbilityFilters-wrap flexCentered">
            <div className="row fullWidth">
              <h2 className="HideOnMyArtifactsPage" style={{ marginBottom: '5px' }}>
                Ability Filters
              </h2>
            </div>
            <div className="row fullWidth">
              <BookedAbilitiesToggleFilter.Component />
              <PassiveSingleToggleFilter.Component />
            </div>
            <div className="row">
              <div className="flex multiSelectAutocompleteFilters-wrap">
                <div className="TheOneFilters flex">
                  <StatusEffectMultiSelectFilter.Component />
                  <EffectKindsMultiSelectFilter.Component />
                  <TargetTypesMultiSelectFilter.Component />

                  {!hasRole(roles, ['Content Creator', 'Advanced Features']) ? (
                    <>
                      <Tooltip
                        overlay={
                          <>
                            <p>
                              This feature requires the <b>Advanced Features</b> power. You can subscribe to it from
                              your <Link to="/profile">profile</Link>.
                            </p>
                          </>
                        }
                        arrowContent={<div className="rc-tooltip-arrow-inner" />}
                        placement="bottom"
                      >
                        <Button
                          className="AndButton info disabled"
                          title={`Click here to add another set of ability filters.`}
                        >
                          AND
                        </Button>
                      </Tooltip>
                    </>
                  ) : (
                    <>
                      <Button
                        className="AndButton info"
                        title={`Click here to add another set of ability filters. (This will also require the Advanced Features power after the Advanced Ability Filters are perfected.)`}
                        onClick={() => {
                          $('body').addClass('ShowTheTwoFilters');
                        }}
                      >
                        AND
                      </Button>
                    </>
                  )}
                </div>

                <div className="TheTwoFilters flex">
                  <StatusEffectTwoMultiSelectFilter.Component />
                  <EffectKindsTwoMultiSelectFilter.Component />
                  <TargetTypesTwoMultiSelectFilter.Component />

                  {!hasRole(roles, ['Content Creator', 'Advanced Features']) ? (
                    <>
                      <Tooltip
                        overlay={
                          <>
                            <p>
                              This feature requires the <b>Advanced Features</b> power. You can subscribe to it from
                              your <Link to="/profile">profile</Link>.
                            </p>
                          </>
                        }
                        arrowContent={<div className="rc-tooltip-arrow-inner" />}
                        placement="bottom"
                      >
                        <Button
                          className="AndButton info disabled"
                          title={`Click here to add another set of ability filters.`}
                        >
                          AND
                        </Button>
                      </Tooltip>
                    </>
                  ) : (
                    <>
                      <Button
                        className="AndButton info"
                        title={`Click here to add another set of ability filters. (This will also require the Advanced Features power after the Advanced Ability Filters are perfected.)`}
                        onClick={() => {
                          $('body').addClass('ShowTheThreeFilters');
                        }}
                      >
                        AND
                      </Button>
                    </>
                  )}
                </div>

                <div className="TheThreeFilters flex">
                  <StatusEffectThreeMultiSelectFilter.Component />
                  <EffectKindsThreeMultiSelectFilter.Component />
                  <TargetTypesThreeMultiSelectFilter.Component />
                </div>
              </div>
            </div>
            <div className="column">
              <h2 className="HideOnMyArtifactsPage" style={{ marginBottom: '5px' }}>
                Advanced Ability Filters
              </h2>
              {!hasRole(roles, ['Content Creator', 'Advanced Features']) ? (
                <>
                  <p className="center maxWidth100Percent">
                    You need to be subscribed to <a href="/profile">Advanced Features</a> to use these.
                  </p>
                  <p className="center maxWidth100Percent">
                    <a href="https://www.youtube.com/watch?v=R4tpUqdp0oc" target="_blank">
                      Click here
                    </a>{' '}
                    to see them in action.
                  </p>
                  {/* <p className="center maxWidth100Percent">
                    <img src="https://ik.imagekit.io/raidchamps/powers/advancedFeaturesPreviewGif.gif?updatedAt=1690574041788" />
                  </p> */}
                </>
              ) : (
                <></>
              )}
              <p className="MyArtifactsPageOnly" style={{ margin: '5px' }}>
                The tab you have selected is not available on this page, select one of the tabs above for sorting and
                filtering options.
              </p>
            </div>
            <div className="flex HideOnMyArtifactsPage Autocomplete-wrap">
              <div className="advancedFeaturesPreview-wrap">
                {!hasRole(roles, ['Content Creator', 'Advanced Features']) ? (
                  <>
                    <p className="advancedFeaturesPreview-img-wrap">
                      <a href="https://www.youtube.com/watch?v=R4tpUqdp0oc" target="_blank">
                        <NextGenImage
                          whichDirectory="powers"
                          whichImage={imageName}
                          title="Preview of Advanced Ability Filters. Click here to open a video in a new tab that shows you them in action."
                          alt="Preview of Advanced Ability Filters."
                        />
                      </a>
                    </p>
                    <a href="/profile" className="btn">
                      Click here to Subscribe to Advanced Powers
                    </a>
                  </>
                ) : (
                  <div className={`outline advancedFeatures-wrap`}>
                    <div className="flex giveInputsInsideMinWidthToFitLongestOptions">
                      <AdvancedStatusEffectAutocompleteFilter.Component />
                      <AdvancedEffectKindAutocompleteFilter.Component />
                    </div>
                    <p className="center" style={{ margin: '10px auto', textAlign: 'center', maxWidth: '75%' }}>
                      All the filters below are for the <b>Status Effect</b> & <b>Effect Kind</b> you select above. If
                      you select something in both of them and then adjust the filters below, you will only see
                      champions that match both selections. But you don't have to select both, you can just pick one and
                      then use the filters below to make your results more specific based on the <b>Status Effect</b> or{' '}
                      <b>Effect Kind</b> that you selected.
                    </p>
                    <AdvancedTargetTypesAutocompleteFilter.Component />
                    <AdvancedA1SingleToggleFilter.Component />
                    <div className="flex">
                      <AdvancedHitsRangeFilter.Component />
                      <AdvancedDurationRangeFilter.Component />
                      <div className="flex">
                        <AdvancedCooldownRangeFilter.Component />
                        <AdvancedMyCooldownRangeFilter.Component />
                        <AdvancedFullyBookedCooldownRangeFilter.Component />
                      </div>
                      <div className="flex">
                        <AdvancedChanceToPlacePercentRangeFilter.Component />
                        <AdvancedMyChanceToPlacePercentRangeFilter.Component />
                        <AdvancedFullyBookedChanceToPlacePercentRangeFilter.Component />
                      </div>
                    </div>
                    <div className="yellow mt-1" style={{ margin: '0 auto', textAlign: 'center', maxWidth: '100%' }}>
                      Please let me know if you find any issues with it.
                      <br />
                      You can reach me via my{' '}
                      <a href="https://discord.gg/zhqmadUyys" target="_blank">
                        Discord server
                      </a>{' '}
                      or the <Link to="/contact">Contact</Link> page.
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tabIndex} index={3}>
          <div className="SaveAndRemoveSavedAndResetFiltersButton-wrap flex">
            <SaveAndRemoveSavedFiltersButton />
            <ResetFiltersButton />
          </div>
          <div className="filters">
            <div className="row">
              <BookValueFilter.Component />
              <HellHadesRatingFilter.Component />
              <ChosenRatingFilter.Component />
              <AyumiloveRatingFilter.Component />
            </div>
            <div className="hoverForMoreRatingFilters flex">
              <Tooltip
                arrowContent={<div className="rc-tooltip-arrow-inner" />}
                placement="bottom"
                overlay={
                  <>
                    <div className="center">
                      <h3>Key Areas</h3>
                      <div className="flex rangeSliders">
                        <HellHadesDemonLordRatingFilter.Component />
                        {/* <HellHadesArenaOverallRatingFilter.Component />
                        <HellHadesArenaAttackRatingFilter.Component />
                        <HellHadesArenaDefenseRatingFilter.Component /> */}
                        <HellHadesHydraRatingFilter.Component />
                        <HellHadesDoomTowerRatingFilter.Component />
                        <HellHadesIronTwinsRatingFilter.Component />
                        <HellHadesSandDevilRatingFilter.Component />
                        <HellHadesPhantomShogunRatingFilter.Component />
                      </div>
                      <h3>Arena</h3>
                      <div className="flex rangeSliders">
                        <HellHadesArenaRolesRatingFilter.Component />
                        {/* <ArenaRolesFilter.Component /> */}
                        {/*
                        <HellHadesLiveArenaRatesByTierFilter.Component /> */}
                      </div>
                      <h3>Dungeons</h3>
                      <div className="flex rangeSliders">
                        <HellHadesAllDungeonsRatingFilter.Component />
                        <HellHadesSpiderRatingFilter.Component />
                        <HellHadesDragonRatingFilter.Component />
                        <HellHadesFireKnightRatingFilter.Component />
                        <HellHadesIceGolemRatingFilter.Component />
                      </div>
                      <h3>Hard Dungeons</h3>
                      <div className="flex rangeSliders">
                        {/* <HellHadesAllDungeonsRatingFilter.Component /> */}
                        <HellHadesHardSpiderRatingFilter.Component />
                        <HellHadesHardDragonRatingFilter.Component />
                        <HellHadesHardFireKnightRatingFilter.Component />
                        <HellHadesHardIceGolemRatingFilter.Component />
                      </div>
                      <h3>Faction Wars</h3>
                      <div className="flex rangeSliders">
                        <HellHadesFactionWarsRatingFilter.Component />
                        <HellHadesFWDecreaseDefenseRatingFilter.Component />
                        <HellHadesFWDecreaseAttackRatingFilter.Component />
                        <HellHadesFWDamageRatingFilter.Component />
                        <HellHadesFWCrowdControlRatingFilter.Component />
                        <HellHadesFWTurnMeterControlRatingFilter.Component />
                        <HellHadesFWProtectionAndSupportRatingFilter.Component />
                        <HellHadesFWReviverRatingFilter.Component />
                      </div>
                      <h3>Doom Tower</h3>
                      <div className="flex rangeSliders">
                        <HellHadesDTBossAgrethRatingFilter.Component />
                        <HellHadesDTBossAstranyxRatingFilter.Component />
                        <HellHadesDTBossBommalRatingFilter.Component />
                        <HellHadesDTBossBorgothRatingFilter.Component />
                        <HellHadesDTBossIragothRatingFilter.Component />
                        <HellHadesDTBossKuldathRatingFilter.Component />
                        <HellHadesDTBossCelestialGriffinRatingFilter.Component />
                        <HellHadesDTBossSorathRatingFilter.Component />
                      </div>
                    </div>
                  </>
                }
              >
                <span className="yellow center">
                  More <b>HellHades</b> Rating Filters
                </span>
              </Tooltip>
              <Tooltip
                arrowContent={<div className="rc-tooltip-arrow-inner" />}
                placement="bottom"
                overlay={
                  <>
                    <div className="center">
                      <h3>Grinding</h3>
                      <div className="flex rangeSliders">
                        <AyumiloveCampaignRatingFilter.Component />
                        <AyumiloveArenaDefenseRatingFilter.Component />
                        <AyumiloveArenaOffenseRatingFilter.Component />
                        <AyumiloveClanBossRatingFilter.Component />
                        <AyumiloveHydraRatingFilter.Component />
                        <AyumiloveFactionWarsRatingFilter.Component />
                      </div>
                      <h3>Dungeons</h3>
                      <div className="flex rangeSliders">
                        <AyumiloveMinotaurRatingFilter.Component />
                        <AyumiloveSpiderRatingFilter.Component />
                        <AyumiloveFireKnightRatingFilter.Component />
                        <AyumiloveDragonRatingFilter.Component />
                        <AyumiloveIceGolemRatingFilter.Component />
                        <AyumiloveIronTwinsRatingFilter.Component />
                        <AyumiloveSandDevilRatingFilter.Component />
                      </div>
                      <h3>Potion Keeps</h3>
                      <div className="flex rangeSliders">
                        <AyumiloveArcaneKeepRatingFilter.Component />
                        <AyumiloveVoidKeepRatingFilter.Component />
                        <AyumiloveForceKeepRatingFilter.Component />
                        <AyumiloveSpiritKeepRatingFilter.Component />
                        <AyumiloveMagicKeepRatingFilter.Component />
                      </div>
                      <h3>Doom Tower</h3>
                      <div className="flex rangeSliders">
                        <AyumiloveDoomTowerFloorsRatingFilter.Component />
                        <AyumiloveMagmaDragonRatingFilter.Component />
                        <AyumiloveNetherSpiderRatingFilter.Component />
                        <AyumiloveFrostSpiderRatingFilter.Component />
                        <AyumiloveScarabKingRatingFilter.Component />
                        <AyumiloveCelestialGriffinRatingFilter.Component />
                        <AyumiloveEternalDragonRatingFilter.Component />
                        <AyumiloveDreadhornRatingFilter.Component />
                        <AyumiloveDarkFaeRatingFilter.Component />
                      </div>
                    </div>
                  </>
                }
              >
                <span className="yellow center">
                  More <b>Ayumilove</b> Rating Filters
                </span>
              </Tooltip>
              <Tooltip
                arrowContent={<div className="rc-tooltip-arrow-inner" />}
                placement="bottom"
                overlay={
                  <>
                    <div className="center">
                      <h3>Key Areas</h3>
                      <div className="flex rangeSliders">
                        <ChosenDemonLordRatingFilter.Component />
                        <ChosenDoomTowerRatingFilter.Component />
                        <ChosenFactionWarsRatingFilter.Component />
                        <ChosenArenaRatingFilter.Component />
                        {/* <ChosenCampaignRatingFilter.Component /> */}
                        <ChosenHydraRatingFilter.Component />
                      </div>
                      <h3>Dungeons</h3>
                      <div className="flex rangeSliders">
                        <ChosenSpiderRatingFilter.Component />
                        <ChosenDragonRatingFilter.Component />
                        <ChosenFireKnightRatingFilter.Component />
                        <ChosenIceGolemRatingFilter.Component />
                        <ChosenIronTwinsRatingFilter.Component />
                        {/* <ChosenSandDevilRatingFilter.Component /> */}
                        {/* <ChosenMinotaurRatingFilter.Component /> */}
                      </div>
                      <h3>Other</h3>
                      <div className="flex rangeSliders">
                        <ChosenScarcityRatingFilter.Component />
                        <ChosenProgressionRatingFilter.Component />
                        <ChosenEndGameRatingFilter.Component />
                      </div>
                      {/*
                      <h3>Potion Keeps</h3>
                      <div className="flex rangeSliders">
                        <Collapsible title="Potion Keeps">
                          <ChosenPotionKeepArcaneRatingFilter.Component />
                          <ChosenPotionKeepForceRatingFilter.Component />
                          <ChosenPotionKeepMagicRatingFilter.Component />
                          <ChosenPotionKeepSpiritRatingFilter.Component />
                          <ChosenPotionKeepVoidRatingFilter.Component />
                        </Collapsible>
                      </div>
                      */}
                    </div>
                  </>
                }
              >
                <span className="yellow center">
                  More <b>Aftershock United</b> Rating Filters
                </span>
              </Tooltip>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tabIndex} index={4}>
          {/* Nothing goes here */}
        </TabPanel>
      </Box>
    </>
  );
});
