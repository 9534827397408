export enum ArtifactSlots {
  none = 'none',
  weapon = 'weapon',
  helmet = 'helmet',
  shield = 'shield',
  gloves = 'gloves',
  chest = 'chest',
  boots = 'boots',
  ring = 'ring',
  pendant = 'pendant',
  banner = 'banner',
}
