import React from 'react';
import { mergeStyles } from '@fluentui/merge-styles';
import * as Styles from './SiteMenuButton.styles';

export interface SiteMenuButtonProps {
  className?: string;
  style?: React.CSSProperties;
  onActivate?: () => void;
  active?: boolean;
  icon?: React.ReactNode;
}

export const SiteMenuButton: React.FC<SiteMenuButtonProps> = ({
  active,
  icon,
  onActivate,
  className,
  children,
  ...props
}) => {
  return (
    <button
      onClick={onActivate}
      className={mergeStyles('block-accent', active && 'toggle', Styles.buttonStyle, className)}
      {...props}
    >
      {children} {icon}
    </button>
  );
};
