import React, { useState } from 'react';
import { ChampionTile, StarRating, ChampMasteryScrolls, ChampStats, Blessing, NextGenImage } from 'Components';
import { getHeroTypesEx, getHeroRating } from 'Data';
import { Box, Tabs, Tab } from '@mui/material';
import { usePageSetting } from 'Components/Hooks';
import ChampMasteriesCondensed from 'Components/Library/ChampMasteriesCondensed';
import { getAllStarRatings, getRarityColorForRating } from 'Components/DataView/Helpers';
import { ArtifactItem, ChampionType, ChampionTypeEx, GearSlot } from 'Types';
import { getGlobalRootStore, useRootStore } from 'Model';
import './ModalContent.css';
import { ChampionArtifacts } from './ChampionArtifacts';
import { ChampionAbilities } from './ChampionAbilities';
import { ChampStatsTable } from './Library/ChampStatsTable';
import { observer } from 'mobx-react-lite';
import ModalImage from 'react-modal-image';
import Modal from 'react-modal';
import HellHadesLiveArenaTable from './Library/HellHadesLiveArenaTable';

interface TabPanelProps {
  index: number;
  value: number;
  className?: string;
  children?: React.ReactNode;
}

let artifacts: any[] | Record<number, ArtifactItem>;
let artifactIds;
let arenaLeague;
let greatHallLevels;
//let factionGuardians;
let allEquippedArtifacts: any[];

function TabPanel(props: TabPanelProps) {
  const { children, value, index, className, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: '16px' }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

Modal.setAppElement('html');

export const ModalContent: React.FC<{ champType?: ChampionTypeEx }> = observer((props) => {
  const store = useRootStore();

  // Add this state variable to keep track of the current form
  const [currentFormIndex, setCurrentFormIndex] = useState(0);

  // Function to toggle between forms
  const toggleForm = () => {
    setCurrentFormIndex((prevIndex) => (prevIndex === 0 ? 1 : 0));
  };

  const championItem = store.modalChampion;

  let championType: ChampionType | undefined;
  let hideOpenChampPageInNewTabButton = false;
  if (props.champType) {
    championType = props.champType;
    hideOpenChampPageInNewTabButton = true;
  } else {
    championType = championItem?.type;
  }

  const rarity = championType?.rarity;
  console.log(`ModalContent rarity`, rarity);

  const slug = championType?.slug;
  // rest of the component code

  const instance = championItem?.instance;
  const rootStore = getGlobalRootStore();
  const account = rootStore.accounts.selectedAccount;
  artifacts = account?.artifacts || [];

  if (window.location.hostname === 'localhost') {
    console.log(`championItem`, championItem);
  }

  if (instance) {
    artifactIds = Object.values(instance.equippedArtifactIds);

    arenaLeague = store.accounts.selectedAccount?.arena.classic.league;
    /* console.log("arenaLeague:", arenaLeague) */
    greatHallLevels = store.accounts.selectedAccount?.arena.classic.greatHallBonuses;
    //factionGuardians = store.accounts.selectedAccount?.guardians;

    //let artifactsById = {};

    allEquippedArtifacts = [];

    if (instance && instance.equippedArtifactIds) {
      const keys: GearSlot[] = [
        GearSlot.weapon,
        GearSlot.helmet,
        GearSlot.shield,
        GearSlot.gloves,
        GearSlot.chest,
        GearSlot.boots,
        GearSlot.ring,
        GearSlot.pendant,
        GearSlot.banner,
      ];

      keys.forEach((key) => {
        const artifactId = instance.equippedArtifactIds[key];
        if (artifactId && artifacts[artifactId]) {
          allEquippedArtifacts.push(artifacts[artifactId]);
        }
      });
    }
  }

  // get last entry for fully ranked/ascended version of hero
  const [type] = getHeroTypesEx()
    .filter((type) => type.slug === slug)
    .slice(-1, 1) as ChampionTypeEx[];
  //   console.log(`type`, type);

  const thisChampsSkillIds = type?.skillIds!;
  //   console.log(`thisChampsSkillIds`, thisChampsSkillIds);

  const name = type?.name;

  const allStarRatings = getAllStarRatings(slug);

  const ChosenVideoGuideId = getHeroRating(slug!)?.detailed.chosenExtra?.videoGuideId || undefined;

  const ChosenInfographicId = getHeroRating(slug!)?.detailed.chosenExtra?.infographicId || undefined;

  const HellHadesVideoGuideId = getHeroRating(slug!)?.detailed.hellhades?.videoUrls?.[0] || undefined;

  // HellHades_GearAndStat_Priorities
  const HellHadesPveSets = getHeroRating(slug!)?.detailed.hellhades?.pve_sets || undefined;
  const HellHadesPveStats = getHeroRating(slug!)?.detailed.hellhades?.pve_stats || undefined;
  const HellHadesPvpSets = getHeroRating(slug!)?.detailed.hellhades?.pvp_sets || undefined;
  const HellHadesPvpStats = getHeroRating(slug!)?.detailed.hellhades?.pvp_stats || undefined;

  const HellHadesPvpMasteries = getHeroRating(slug!)?.detailed.hellhades?.pvp_masteries || undefined;
  const HellHadesPveMasteries = getHeroRating(slug!)?.detailed.hellhades?.pve_masteries || undefined;

  //HellHades Arena Roles Rating
  const HellHadesArenaRolesRating = getHeroRating(slug!)?.detailed.hellhades?.arenaRolesRating || undefined;
  console.log(`HellHadesArena: HellHadesArenaRolesRating`, HellHadesArenaRolesRating);

  //HellHades Arena Roles
  const HellHadesArenaRoles = getHeroRating(slug!)?.detailed.hellhades?.arenaRoles || [];
  console.log(`HellHadesArena: HellHadesArenaRoles`, HellHadesArenaRoles);

  //HellHades Live Arena
  const HellHadesLiveArena = getHeroRating(slug!)?.detailed.hellhades?.liveArena || undefined;
  console.log(`HellHadesArena: HellHadesLiveArena`, HellHadesLiveArena);

  console.log(`areaRatings`, getHeroRating(slug!)?.detailed.hellhades?.areaRatings);

  // HellHades Blessing Recommendations
  const HellHadesBlessings = getHeroRating(slug!)?.detailed.hellhades?.blessings || undefined;

  //Ayumilove Ratings
  const AyumiloveRatings = getHeroRating(slug!)?.detailed.ayumilove?.areaRatings || undefined;
  console.log(`AyumiloveRatings`, AyumiloveRatings);

  const ratingsBySection = {
    Grinding: ['Campaign', 'Arena Defense', 'Arena Offense', 'Clan Boss', 'Hydra', 'Faction Wars'],
    Dungeons: [
      'Minotaur',
      'Spider',
      'Fire Knight',
      'Dragon',
      'Ice Golem',
      'Iron Twins',
      'Sand Devil',
      'Phantom Shogun',
    ],
    Potion: ['Arcane Keep', 'Void Keep', 'Force Keep', 'Spirit Keep', 'Magic Keep'],
    'Doom Tower': [
      'Floors',
      'Magma Dragon',
      'Nether Spider',
      'Frost Spider',
      'Scarab King',
      'Celestial Griffin',
      'Eternal Dragon',
      'Dreadhorn',
      'Dark Fae',
    ],
  };

  // Faction Alliances
  let factionAlliance;
  if (
    type?.faction === 'bannerlords' ||
    type?.faction === 'barbarians' ||
    type?.faction === 'highelves' ||
    type?.faction === 'sacredorder'
  ) {
    //factionAlliance = ['Telerians (BL/BA/HE/SO)'];
    factionAlliance = ['Telerians'];
  }

  if (
    type?.faction === 'ogryntribes' ||
    type?.faction === 'lizardmen' ||
    type?.faction === 'skinwalkers' ||
    type?.faction === 'orcs'
  ) {
    //factionAlliance = ['Gaellen Pact (LZ/OT/OR/SW)'];
    factionAlliance = ['Gaellen Pact'];
  }

  if (
    type?.faction === 'demonspawn' ||
    type?.faction === 'undeadhordes' ||
    type?.faction === 'darkelves' ||
    type?.faction === 'knightsrevenant'
  ) {
    //factionAlliance = ['The Corrupted (DE/DS/KR/UH)'];
    factionAlliance = ['The Corrupted'];
  }

  if (type?.faction === 'dwarves' || type?.faction === 'samurai') {
    //factionAlliance = ['Nyresan Union (DW/SK)'];
    factionAlliance = ['Nyresan Union'];
  }

  const [tabIndex, setTabIndex] = usePageSetting('filter-tab');
  const onTabChange = React.useCallback((_event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  }, []);

  const [contentCreatorTabIndex, setContentCreatorTabIndex] = usePageSetting('filter-cc-tab');
  const onContentCreatorTabChange = React.useCallback((_event: React.SyntheticEvent, newValue: number) => {
    setContentCreatorTabIndex(newValue);
  }, []);

  //const heroTypeId = `${type?.typeId && type?.typeId - (type?.typeId % 10)}`;

  const [isCopied, setIsCopied] = useState(false);

  function handleClick(type: ChampionTypeEx) {
    if (window.location.hostname === 'localhost') {
      // existing onClick function for localhost
      console.log(`http://localhost:8080/champions/${type?.slug}`);
    } else {
      // existing onClick function for production
      console.log(`https://raidchamps.com/champions/${type?.slug}`);
    }

    // function to copy URL to clipboard and show notification
    const url =
      window.location.hostname === 'localhost'
        ? `http://localhost:8080/champions/${type?.slug}`
        : `https://raidchamps.com/champions/${type?.slug}`;

    navigator.clipboard.writeText(url).then(() => {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000); // hide notification after 2 seconds
    });
  }

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleModalOpen = () => {
    setModalIsOpen(true);
    document.body.classList.add('lightboxOpen');
  };

  const handleModalClose = () => {
    setModalIsOpen(false);
    document.body.classList.remove('lightboxOpen');
  };

  const roleDescriptions: { [key: string]: string } = {
    Cleanser: 'Cleanser: Uses skills to remove debuffs and prevent re-application.',
    'Crowd Control': 'Crowd Control: Applies soft and hard debuffs to disable enemies.',
    Damage: 'Damage: Deals significant damage to defeat enemies.',
    'Damage Absorption': 'Damage Absorption: Reduces incoming damage through skills or effects.',
    Healer: 'Healer: Restores health, including Continuous Heals.',
    Debuffer: 'Debuffer: Places debuffs other than Crowd Control or Skill Manipulation, may also remove buffs.',
    Reviver: 'Reviver: Revives allies, including Revive on Death buffs.',
    'Skill Manipulator': 'Skill Manipulator: Alters skill cooldowns for allies or enemies.',
    'Speed Manipulator': 'Speed Manipulator: Affects turn meter, including speed buffs and debuffs.',
  };

  return slug && type ? (
    <div className="ModalContent-innerWrap">
      <div className="title">
        <h2 className="modalChampName">{type?.name}</h2>
      </div>
      <div className="ModalAvatar-wrap">
        <div className="flex ModalTopButtons">
          {(rarity === '6' || rarity === 'mythic') && (
            <a className="toggleFormButton btn" onClick={toggleForm}>
              Toggle Form
            </a>
          )}
          {hideOpenChampPageInNewTabButton !== true && (
            <>
              {/* <a className="btn openChampPageInNewTabButton" href={`/champions/${type?.slug}`} target="_blank">
                Open {type?.name}'s Page in New Tab
              </a> */}
              <a className="copyLinkToThisPageButtonInModal btn" onClick={() => handleClick(type)}>
                Copy link to {type?.name}'s Page
              </a>
            </>
          )}
        </div>
        <div className="column-wrapper">
          <div className="main">
            {isCopied && (
              <div className="ModalTopButtons notification">
                <p>URL has been copied to your clipboard!</p>
              </div>
            )}
            <div className="TabPanelContent-wrap AbilityInfo sectionFlex-wrap space-between">
              <div className="left">
                <div className="ChampionTileHeroScreenshotAndFactionAlliance flex">
                  <ChampionTile
                    item={championItem}
                    champType={championType}
                    inModal={true}
                    currentFormIndex={currentFormIndex}
                  />

                  <div className="lightboxImageForModalContent">
                    <ModalImage
                      small={`https://ik.imagekit.io/raidchamps/heroScreenshots/tr:w-140/${`${
                        type.typeId - (type.typeId % 10)
                      }`}${
                        rarity === '6' || rarity === 'mythic' ? `_f${currentFormIndex === 0 ? '1' : '2'}` : ''
                      }big.webp`}
                      large={`https://ik.imagekit.io/raidchamps/heroScreenshots/${`${
                        type.typeId - (type.typeId % 10)
                      }`}${
                        rarity === '6' || rarity === 'mythic' ? `_f${currentFormIndex === 0 ? '1' : '2'}` : ''
                      }big.webp`}
                      className="heroScreenshot"
                      hideDownload
                      hideZoom
                    />
                  </div>

                  <div className="lightboxImageForChampPage">
                    <img
                      src={`https://ik.imagekit.io/raidchamps/heroScreenshots/tr:w-150/${`${
                        type.typeId - (type.typeId % 10)
                      }`}${
                        rarity === '6' || rarity === 'mythic' ? `_f${currentFormIndex === 0 ? '1' : '2'}` : ''
                      }big.webp`}
                      alt="An example image"
                      onClick={handleModalOpen}
                      style={{ cursor: 'pointer' }}
                    />
                    <Modal
                      isOpen={modalIsOpen}
                      onRequestClose={handleModalClose}
                      onAfterOpen={handleModalOpen}
                      style={{
                        overlay: {
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        },
                        content: {
                          position: 'relative',
                          overflow: 'auto',
                          maxHeight: '80%',
                          maxWidth: '80%',
                        },
                      }}
                    >
                      <img
                        src={`https://ik.imagekit.io/raidchamps/heroScreenshots/${`${
                          type.typeId - (type.typeId % 10)
                        }`}${
                          rarity === '6' || rarity === 'mythic' ? `_f${currentFormIndex === 0 ? '1' : '2'}` : ''
                        }big.webp`}
                        alt="In-game Screenshot"
                        style={{ maxHeight: '36vw', maxWidth: '69vw' }}
                      />
                      <button onClick={handleModalClose} style={{ position: 'absolute', top: '20px', right: '20px' }}>
                        Close
                      </button>
                    </Modal>
                  </div>

                  {/* Faction Alliance */}
                  {factionAlliance && (
                    <section className="factionAllianceSection-wrap">
                      <div className="factionAlliance-wrap">
                        <h3>Faction Alliance</h3>
                        <div className="factionAlliance break-lines">{factionAlliance}</div>
                      </div>
                    </section>
                  )}
                </div>

                {/* <NextGenImage
                  whichDirectory="heroScreenshots"
                  whichImage={`tr:w-180/${`${type.typeId - (type.typeId % 10)}`}big`}
                  title={`In-game screenshot of ${type.name}`}
                  alt={`In-game screenshot of ${type.name}`}
                /> */}
                <div className="RightOfChampionTile-wrap">
                  {/* Base Stats */}
                  {championType?.baseStats && (
                    <section className="Stats-section">
                      <ChampStats item={championItem} champType={championType} currentFormIndex={currentFormIndex} />
                    </section>
                  )}
                  {instance && (
                    <>
                      <div className="MyMasteriesAndExtraSectionWrap">
                        <section className="TabPanelContent-wrap MyMasteries">
                          {instance?.masteries?.length > 0 && (
                            <h3 className="MasteriesHeadingInChampionModal">Masteries</h3>
                          )}
                          {instance?.masteries?.length > 0 && (
                            <>
                              <section className="sectionFlex-rightSection">
                                <ChampMasteryScrolls totalMasteryScrolls={instance.totalMasteryScrolls} />
                              </section>
                              <section className="Masteries-section">
                                <ChampMasteriesCondensed masteries={instance?.masteries} />
                              </section>
                            </>
                          )}
                        </section>
                        {/* <section className="Masteries-section">
                      <h4 className="redNote">Room for something else here </h4>
                    </section> */}
                      </div>
                    </>
                  )}
                </div>
              </div>
              {instance ? (
                <>
                  {/* Instance Data (My Champs Only) */}
                  <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <Tabs
                        value={tabIndex}
                        onChange={onTabChange}
                        aria-label="Champion Page Tabs"
                        variant="scrollable"
                        scrollButtons="auto"
                      >
                        <Tab label="Abilities" {...a11yProps(0)} />
                        <Tab label="Artifacts" {...a11yProps(1)} />
                        <Tab label="Stats Breakdown" {...a11yProps(2)} />
                      </Tabs>
                    </Box>
                    <TabPanel value={tabIndex} index={0} className="modalTopTabPanel Abilities">
                      <div className="ChampionAbilities-wrap myChamps">
                        <ChampionAbilities instance={instance} type={type} currentFormIndex={currentFormIndex} />
                      </div>
                    </TabPanel>
                    <TabPanel value={tabIndex} index={1} className="modalTopTabPanel Artifacts">
                      <div className="ChampionArtifacts-wrap myChamps">
                        <ChampionArtifacts item={championItem} />
                      </div>
                    </TabPanel>
                    <TabPanel value={tabIndex} index={2} className="modalTopTabPanel StatsBreakdown">
                      <div className="ChampStatsTable-wrap myChamps">
                        <ChampStatsTable item={championItem} />
                      </div>
                    </TabPanel>
                  </Box>
                </>
              ) : (
                <>
                  {/* No Instance Data (All Champs Only) */}
                  <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <Tabs
                        value={tabIndex}
                        onChange={onTabChange}
                        aria-label="Champion Page Tabs"
                        variant="scrollable"
                        scrollButtons="auto"
                      >
                        <Tab label="Abilities" {...a11yProps(0)} />
                      </Tabs>
                    </Box>
                    {/* Abilities */}
                    <TabPanel value={tabIndex} index={0} className="modalTopTabPanel Abilities">
                      <div className="ChampionAbilities-wrap allChamps">
                        <ChampionAbilities instance={instance} type={type} currentFormIndex={currentFormIndex} />
                      </div>
                    </TabPanel>
                  </Box>
                </>
              )}
            </div>
            <div className="bottomContent">
              <h2 className="with-underline">Content Creator Info</h2>
              <Box sx={{ width: '100%' }} className="ContentCreatorInfo">
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs
                    value={contentCreatorTabIndex}
                    onChange={onContentCreatorTabChange}
                    aria-label="Champion Page Tabs"
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    {Number(championItem?.type.ratings.detailed.hellhades?.overall) > 0 && (
                      <Tab label="HellHades' Info" {...a11yProps(0)} />
                    )}
                    {Number(championItem?.type.ratings.detailed.ayumilove?.base100) > 0 && (
                      <Tab label="Ayumilove Info" {...a11yProps(1)} />
                    )}
                    {Number(championItem?.type.ratings.detailed.chosen?.overall) > 0 && (
                      <Tab label="Aftershock United's Info" {...a11yProps(2)} />
                    )}
                  </Tabs>
                </Box>
                {/* HellHadesInfo */}
                <TabPanel value={contentCreatorTabIndex} index={0}>
                  <div className="TabPanelContent-wrap HellHadesInfo">
                    <div className="sectionFlex-wrap HellHadesLocationRatings">
                      {/* Key Areas */}
                      <section>
                        <h3>Key Areas</h3>
                        <div className="rating-area-wrap">
                          <span className="rating-area-name">Arena: </span>
                          <StarRating
                            totalStars={5}
                            filledStars={HellHadesArenaRolesRating}
                            size="small"
                            color={`var(--rarity-${getRarityColorForRating(
                              allStarRatings.HellHadesArenaOverallStarRating
                            )})`}
                          />
                        </div>
                        {/* <div className="rating-area-wrap">
                          <span className="rating-area-name">Arena Defense: </span>
                          <StarRating
                            totalStars={5}
                            filledStars={allStarRatings.HellHadesArenaDefenseStarRating}
                            size="small"
                            color={`var(--rarity-${getRarityColorForRating(
                              allStarRatings.HellHadesArenaDefenseStarRating
                            )})`}
                          />
                        </div>
                        <div className="rating-area-wrap">
                          <span className="rating-area-name">Arena Offense: </span>
                          <StarRating
                            totalStars={5}
                            filledStars={allStarRatings.HellHadesArenaAttackStarRating}
                            size="small"
                            color={`var(--rarity-${getRarityColorForRating(
                              allStarRatings.HellHadesArenaAttackStarRating
                            )})`}
                          />
                        </div> */}
                        <div className="rating-area-wrap">
                          <span className="rating-area-name">Demon Lord: </span>
                          <StarRating
                            totalStars={5}
                            filledStars={allStarRatings.HellHadesDemonLordStarRating}
                            size="small"
                            color={`var(--rarity-${getRarityColorForRating(
                              allStarRatings.HellHadesDemonLordStarRating
                            )})`}
                          />
                        </div>
                        <div className="rating-area-wrap">
                          <span className="rating-area-name">Hydra: </span>
                          <StarRating
                            totalStars={5}
                            filledStars={allStarRatings.HellHadesHydraStarRating}
                            size="small"
                            color={`var(--rarity-${getRarityColorForRating(allStarRatings.HellHadesHydraStarRating)})`}
                          />
                        </div>
                        <div className="rating-area-wrap">
                          <span className="rating-area-name">Doom Tower: </span>
                          <StarRating
                            totalStars={5}
                            filledStars={allStarRatings.HellHadesDoomTowerOverallStarRating}
                            size="small"
                            color={`var(--rarity-${getRarityColorForRating(
                              allStarRatings.HellHadesDoomTowerOverallStarRating
                            )})`}
                          />
                        </div>
                        <div className="rating-area-wrap">
                          <span className="rating-area-name">Iron Twins: </span>
                          <StarRating
                            totalStars={5}
                            filledStars={allStarRatings.HellHadesIronTwinsStarRating}
                            size="small"
                            color={`var(--rarity-${getRarityColorForRating(
                              allStarRatings.HellHadesIronTwinsStarRating
                            )})`}
                          />
                        </div>
                        <div className="rating-area-wrap">
                          <span className="rating-area-name">Sand Devil: </span>
                          <StarRating
                            totalStars={5}
                            filledStars={allStarRatings.HellHadesIceGolemStarRating}
                            size="small"
                            color={`var(--rarity-${getRarityColorForRating(
                              allStarRatings.HellHadesIceGolemStarRating
                            )})`}
                          />
                        </div>
                        <div className="rating-area-wrap">
                          <span className="rating-area-name">Phantom Shogun: </span>
                          <StarRating
                            totalStars={5}
                            filledStars={allStarRatings.HellHadesPhantomShogunStarRating}
                            size="small"
                            color={`var(--rarity-${getRarityColorForRating(
                              allStarRatings.HellHadesPhantomShogunStarRating
                            )})`}
                          />
                        </div>
                      </section>
                      {/* Dungeons */}
                      <section>
                        <h3>Dungeons</h3>
                        <div className="rating-area-wrap">
                          <span className="rating-area-name">Overall: </span>
                          <StarRating
                            totalStars={5}
                            filledStars={allStarRatings.HellHadesAllDungeonsStarRating}
                            size="small"
                            color={`var(--rarity-${getRarityColorForRating(
                              allStarRatings.HellHadesAllDungeonsStarRating
                            )})`}
                          />
                        </div>
                        <div className="rating-area-wrap">
                          <span className="rating-area-name">Spider: </span>
                          <StarRating
                            totalStars={5}
                            filledStars={allStarRatings.HellHadesSpiderStarRating}
                            size="small"
                            color={`var(--rarity-${getRarityColorForRating(allStarRatings.HellHadesSpiderStarRating)})`}
                          />
                        </div>
                        <div className="rating-area-wrap">
                          <span className="rating-area-name">Fire Knight's Castle: </span>
                          <StarRating
                            totalStars={5}
                            filledStars={allStarRatings.HellHadesFireKnightStarRating}
                            size="small"
                            color={`var(--rarity-${getRarityColorForRating(
                              allStarRatings.HellHadesFireKnightStarRating
                            )})`}
                          />
                        </div>
                        <div className="rating-area-wrap">
                          <span className="rating-area-name">Dragon's Lair: </span>
                          <StarRating
                            totalStars={5}
                            filledStars={allStarRatings.HellHadesDragonStarRating}
                            size="small"
                            color={`var(--rarity-${getRarityColorForRating(allStarRatings.HellHadesDragonStarRating)})`}
                          />
                          {/* Need to think of a smarter way to pull in the rarity based on the Rating */}
                        </div>
                        <div className="rating-area-wrap">
                          <span className="rating-area-name">Ice Golem's Peak: </span>
                          <StarRating
                            totalStars={5}
                            filledStars={allStarRatings.HellHadesIceGolemStarRating}
                            size="small"
                            color={`var(--rarity-${getRarityColorForRating(
                              allStarRatings.HellHadesIceGolemStarRating
                            )})`}
                          />
                        </div>
                      </section>
                      {/* Hard Dungeons */}
                      <section>
                        <h3>Hard Dungeons</h3>
                        {/* <div className="rating-area-wrap">
                          <span className="rating-area-name">Overall: </span>
                          <StarRating
                            totalStars={5}
                            filledStars={allStarRatings.HellHadesHardAllDungeonsStarRating}
                            size="small"
                            color={`var(--rarity-${getRarityColorForRating(
                              allStarRatings.HellHadesHardAllDungeonsStarRating
                            )})`}
                          />
                        </div> */}
                        <div className="rating-area-wrap">
                          <span className="rating-area-name">Spider: </span>
                          <StarRating
                            totalStars={5}
                            filledStars={allStarRatings.HellHadesHardSpiderStarRating}
                            size="small"
                            color={`var(--rarity-${getRarityColorForRating(
                              allStarRatings.HellHadesHardSpiderStarRating
                            )})`}
                          />
                        </div>
                        <div className="rating-area-wrap">
                          <span className="rating-area-name">Fire Knight's Castle: </span>
                          <StarRating
                            totalStars={5}
                            filledStars={allStarRatings.HellHadesHardFireKnightStarRating}
                            size="small"
                            color={`var(--rarity-${getRarityColorForRating(
                              allStarRatings.HellHadesHardFireKnightStarRating
                            )})`}
                          />
                        </div>
                        <div className="rating-area-wrap">
                          <span className="rating-area-name">Dragon's Lair: </span>
                          <StarRating
                            totalStars={5}
                            filledStars={allStarRatings.HellHadesHardDragonStarRating}
                            size="small"
                            color={`var(--rarity-${getRarityColorForRating(
                              allStarRatings.HellHadesHardDragonStarRating
                            )})`}
                          />
                          {/* Need to think of a smarter way to pull in the rarity based on the Rating */}
                        </div>
                        <div className="rating-area-wrap">
                          <span className="rating-area-name">Ice Golem's Peak: </span>
                          <StarRating
                            totalStars={5}
                            filledStars={allStarRatings.HellHadesHardIceGolemStarRating}
                            size="small"
                            color={`var(--rarity-${getRarityColorForRating(
                              allStarRatings.HellHadesHardIceGolemStarRating
                            )})`}
                          />
                        </div>
                      </section>
                      {/* Doom Tower */}
                      <section>
                        <h3>Doom Tower</h3>
                        <div className="rating-area-wrap">
                          <span className="rating-area-name">Magma Dragon (Kuldath): </span>
                          <StarRating
                            totalStars={5}
                            filledStars={allStarRatings.HellHadesDTBossKuldathStarRating}
                            size="small"
                            color={`var(--rarity-${getRarityColorForRating(
                              allStarRatings.HellHadesDTBossKuldathStarRating
                            )})`}
                          />
                        </div>
                        <div className="rating-area-wrap">
                          <span className="rating-area-name">Frost Spider (Sorath): </span>
                          <StarRating
                            totalStars={5}
                            filledStars={allStarRatings.HellHadesDTBossSorathStarRating}
                            size="small"
                            color={`var(--rarity-${getRarityColorForRating(
                              allStarRatings.HellHadesDTBossSorathStarRating
                            )})`}
                          />
                        </div>
                        <div className="rating-area-wrap">
                          <span className="rating-area-name">Nether Spider's Den (Agreth): </span>
                          <StarRating
                            totalStars={5}
                            filledStars={allStarRatings.HellHadesDTBossAgrethStarRating}
                            size="small"
                            color={`var(--rarity-${getRarityColorForRating(
                              allStarRatings.HellHadesDTBossAgrethStarRating
                            )})`}
                          />
                        </div>
                        <div className="rating-area-wrap">
                          <span className="rating-area-name">Scarab King (Borgoth): </span>
                          <StarRating
                            totalStars={5}
                            filledStars={allStarRatings.HellHadesDTBossBorgothStarRating}
                            size="small"
                            color={`var(--rarity-${getRarityColorForRating(
                              allStarRatings.HellHadesDTBossBorgothStarRating
                            )})`}
                          />
                        </div>
                        <div className="rating-area-wrap">
                          <span className="rating-area-name">Eternal Dragon (Iragoth): </span>
                          <StarRating
                            totalStars={5}
                            filledStars={allStarRatings.HellHadesDTBossIragothStarRating}
                            size="small"
                            color={`var(--rarity-${getRarityColorForRating(
                              allStarRatings.HellHadesDTBossIragothStarRating
                            )})`}
                          />
                        </div>
                        <div className="rating-area-wrap">
                          <span className="rating-area-name">Celestial Griffin: </span>
                          <StarRating
                            totalStars={5}
                            filledStars={allStarRatings.HellHadesDTBossCelestialGriffinStarRating}
                            size="small"
                            color={`var(--rarity-${getRarityColorForRating(
                              allStarRatings.HellHadesDTBossCelestialGriffinStarRating
                            )})`}
                          />
                        </div>
                        <div className="rating-area-wrap">
                          <span className="rating-area-name">Dark Fae (Astranyx): </span>
                          <StarRating
                            totalStars={5}
                            filledStars={allStarRatings.HellHadesDTBossAstranyxStarRating}
                            size="small"
                            color={`var(--rarity-${getRarityColorForRating(
                              allStarRatings.HellHadesDTBossAstranyxStarRating
                            )})`}
                          />
                        </div>
                        <div className="rating-area-wrap">
                          <span className="rating-area-name">Dreadhorn (Bommal): </span>
                          <StarRating
                            totalStars={5}
                            filledStars={allStarRatings.HellHadesDTBossBommalStarRating}
                            size="small"
                            color={`var(--rarity-${getRarityColorForRating(
                              allStarRatings.HellHadesDTBossBommalStarRating
                            )})`}
                          />
                        </div>
                      </section>
                      {/* Faction Wars */}
                      <section>
                        <h3>Faction Wars</h3>
                        <div className="rating-area-wrap">
                          <span className="rating-area-name">Faction Wars Overall: </span>
                          <StarRating
                            totalStars={5}
                            filledStars={allStarRatings.HellHadesFWOverallStarRating}
                            size="small"
                            color={`var(--rarity-${getRarityColorForRating(
                              allStarRatings.HellHadesFWOverallStarRating
                            )})`}
                          />
                        </div>
                        <div className="rating-area-wrap">
                          <span className="rating-area-name">Decrease Defense: </span>
                          <StarRating
                            totalStars={5}
                            filledStars={allStarRatings.HellHadesFWDecreaseDefenseStarRating}
                            size="small"
                            color={`var(--rarity-${getRarityColorForRating(
                              allStarRatings.HellHadesFWDecreaseDefenseStarRating
                            )})`}
                          />
                        </div>
                        <div className="rating-area-wrap">
                          <span className="rating-area-name">Decrease Attack: </span>
                          <StarRating
                            totalStars={5}
                            filledStars={allStarRatings.HellHadesFWDecreaseAttackStarRating}
                            size="small"
                            color={`var(--rarity-${getRarityColorForRating(
                              allStarRatings.HellHadesFWDecreaseAttackStarRating
                            )})`}
                          />
                        </div>
                        <div className="rating-area-wrap">
                          <span className="rating-area-name">Damage: </span>
                          <StarRating
                            totalStars={5}
                            filledStars={allStarRatings.HellHadesFWDamageStarRating}
                            size="small"
                            color={`var(--rarity-${getRarityColorForRating(
                              allStarRatings.HellHadesFWDamageStarRating
                            )})`}
                          />
                        </div>
                        <div className="rating-area-wrap">
                          <span className="rating-area-name">Crowd Control: </span>
                          <StarRating
                            totalStars={5}
                            filledStars={allStarRatings.HellHadesFWCrowdControlStarRating}
                            size="small"
                            color={`var(--rarity-${getRarityColorForRating(
                              allStarRatings.HellHadesFWCrowdControlStarRating
                            )})`}
                          />
                        </div>
                        <div className="rating-area-wrap">
                          <span className="rating-area-name">Turn Meter Control: </span>
                          <StarRating
                            totalStars={5}
                            filledStars={allStarRatings.HellHadesFWTurnMeterControlStarRating}
                            size="small"
                            color={`var(--rarity-${getRarityColorForRating(
                              allStarRatings.HellHadesFWTurnMeterControlStarRating
                            )})`}
                          />
                        </div>
                        <div className="rating-area-wrap">
                          <span className="rating-area-name">Protection and Support: </span>
                          <StarRating
                            totalStars={5}
                            filledStars={allStarRatings.HellHadesFWProtectionAndSupportStarRating}
                            size="small"
                            color={`var(--rarity-${getRarityColorForRating(
                              allStarRatings.HellHadesFWProtectionAndSupportStarRating
                            )})`}
                          />
                        </div>
                        <div className="rating-area-wrap">
                          <span className="rating-area-name">Reviver: </span>
                          <StarRating
                            totalStars={5}
                            filledStars={allStarRatings.HellHadesFWReviverStarRating}
                            size="small"
                            color={`var(--rarity-${getRarityColorForRating(
                              allStarRatings.HellHadesFWReviverStarRating
                            )})`}
                          />
                        </div>
                      </section>
                    </div>

                    <div className="HellHadesArenaRoles">
                      <div className="rating-area-wrap">
                        <h3>Arena Roles</h3>
                      </div>
                      <section className="sectionFlex-wrap hellHadesBlessing-wrap">
                        {HellHadesArenaRoles.map((role: string | undefined, index: React.Key | null | undefined) => {
                          let imageName = 'default';
                          let roleDescription = 'Unknown Role';
                          if (role) {
                            imageName = role.toLowerCase().replace(/\s+/g, '');
                            roleDescription = roleDescriptions[role] || 'Unknown Role';
                          }
                          return (
                            <NextGenImage
                              key={index}
                              whichDirectory="_NotExtracted/HellHades/ArenaRoles"
                              whichImage={`/tr:w-75/${imageName}`}
                              alt={role}
                              title={roleDescription}
                              className="ArenaRolesImage info"
                            />
                          );
                        })}
                      </section>
                    </div>

                    {/* Works great if I ever figure out how to scrape the data for every tier the stupid way their site is setup to display them each one at a time after selecting an option in a select input GRRR
                     <div className="HellHadesLiveArenaTable-wrap">
                      <HellHadesLiveArenaTable HellHadesLiveArena={HellHadesLiveArena} />
                    </div> */}

                    <div className="HellHadesBlessings">
                      <h2>Blessing Recommendations</h2>
                      <section className="sectionFlex-wrap hellHadesBlessing-wrap">
                        {HellHadesBlessings?.map((blessingData) => {
                          return (
                            <div
                              key={blessingData.title.replace(/( )/g, '-').toLowerCase()}
                              className={`Blessing ${blessingData.title.replace(/( )/g, '-').toLowerCase()}`}
                            >
                              <h3>{blessingData?.title}</h3>
                              <Blessing
                                className={`blessingImage info`}
                                blessing={blessingData.blessing}
                                rarity={championType && championType.rarity}
                                local={false}
                                hellHades={true}
                                teamBlessing={blessingData?.teamBlessing}
                              />
                            </div>
                          );
                        })}
                      </section>
                    </div>
                    <div className="sectionFlex-wrap HellHadesMasteryRecommendations">
                      <div className="sectionFlex-wrap HellHadesMasteryRecommendations">
                        <h2>Sets, Stats, & Mastery Recommendations</h2>
                        <div className="HellHadesPveMasteries PvE">
                          <h2>PvE Recommendations</h2>
                          <h3>Artifact Sets</h3>
                          <p>{HellHadesPveSets ? HellHadesPveSets : 'Not Available'}</p>
                          <h3>Stats</h3>
                          <p>{HellHadesPveStats ? HellHadesPveStats : 'Not Available'}</p>
                          <section className="sectionFlex-wrap">
                            {HellHadesPveMasteries?.map((championItem) => {
                              return (
                                <div
                                  key={championItem.title.replace(/( )/g, '-').toLowerCase()}
                                  className={`MasteriesCondensed ${championItem.title
                                    .replace(/( )/g, '-')
                                    .toLowerCase()}`}
                                >
                                  <h3>{championItem.title}</h3>
                                  <ChampMasteriesCondensed masteries={championItem.masteries} />
                                </div>
                              );
                            })}
                          </section>
                        </div>
                        <div className="HellHadesPvpMasteries PvP">
                          <h2>PvP Recommendations</h2>
                          <h3>Artifact Sets</h3>
                          <p>{HellHadesPvpSets ? HellHadesPvpSets : 'Not Available'}</p>
                          <h3>Stats</h3>
                          <p>{HellHadesPvpStats ? HellHadesPvpStats : 'Not Available'}</p>
                          <section className="sectionFlex-wrap">
                            {HellHadesPvpMasteries?.map((championItem) => {
                              return (
                                <div
                                  key={championItem.title.replace(/( )/g, '-').toLowerCase()}
                                  className={`MasteriesCondensed ${championItem.title
                                    .replace(/( )/g, '-')
                                    .toLowerCase()}`}
                                >
                                  <h3>{championItem.title}</h3>
                                  <ChampMasteriesCondensed masteries={championItem.masteries} />
                                </div>
                              );
                            })}
                          </section>
                        </div>
                      </div>
                    </div>

                    <div className="sectionFlex-wrap HellHadesVideoGuide-wrap">
                      {/* Video Guide */}
                      {HellHadesVideoGuideId && (
                        <section className="VideoGuideSection">
                          <h2>Video Guide</h2>
                          <div className="auto-resizable-iframe">
                            <div className="Video-wrap">
                              <iframe
                                width="962"
                                height="541"
                                src={`https://www.youtube.com/embed/${HellHadesVideoGuideId}`}
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                              ></iframe>
                            </div>
                          </div>
                        </section>
                      )}
                    </div>
                  </div>
                </TabPanel>
                {/* Ayumilove Info */}
                <TabPanel value={contentCreatorTabIndex} index={1}>
                  <div className="TabPanelContent-wrap AyumiloveInfo">
                    <div className="sectionFlex-wrap AyumiloveLocationRatings">
                      {Object.keys(ratingsBySection).map((section) => (
                        <section key={section}>
                          <h3>{section}</h3>
                          {ratingsBySection[section].map((rating) => (
                            <div className="rating-area-wrap" key={rating}>
                              <span className="rating-area-name">{`${rating}: `}</span>
                              {AyumiloveRatings && AyumiloveRatings[rating] !== undefined ? (
                                <StarRating
                                  totalStars={5}
                                  filledStars={AyumiloveRatings[rating]}
                                  size="small"
                                  color={`var(--rarity-${AyumiloveRatings[rating] ?? 0})`}
                                />
                              ) : (
                                <span>No rating available</span>
                              )}
                            </div>
                          ))}
                        </section>
                      ))}
                    </div>
                  </div>

                  <div className="VideoAndInfographic-wrap">
                    <div className="">
                      {/* Infographic */}
                      <section>
                        {ChosenInfographicId && (
                          <div className="choseNInfographicLinkID">
                            {/* QUESTION: @dnchattan what do you suggest I use for images that I want to have open in a lightbox when clicked with options to zoom in and out? Antd had a really nice and simple one called "Image" but MUI does not seem to have anything similar.
                          <NextGenImage
                            src={`https://i.imgur.com/UeWgZKr.jpg`}
                            width="100%"
                            height="auto"
                            alt={`ChoseN's Infographic for ${ChosenInfographicId}`}
                            title={`ChoseN's Infographic for ${ChosenInfographicId}`}
                          /> */}

                            <h2>Infographic</h2>
                            <img
                              src={`https://i.imgur.com/${ChosenInfographicId}.jpg`}
                              width="100%"
                              height="auto"
                              alt={`ChoseN's Infographic for ${name}`}
                              title={`ChoseN's Infographic for ${name}`}
                            />
                          </div>
                        )}
                      </section>
                      {/* Video Guide */}
                      {ChosenVideoGuideId && (
                        <section>
                          <h2>Video Guide</h2>
                          <div className="Video-wrap">
                            <iframe
                              width="962"
                              height="541"
                              src={`https://www.youtube.com/embed/${ChosenVideoGuideId}`}
                              title={`ChoseN's Video Guide for ${name}`}
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                            ></iframe>
                          </div>
                        </section>
                      )}
                    </div>
                  </div>
                </TabPanel>
                {/* ChosenInfo */}
                <TabPanel value={contentCreatorTabIndex} index={2}>
                  <div className="TabPanelContent-wrap ChosenInfo">
                    <div className="sectionFlex-wrap ChosenLocationRatings">
                      {/* Key Areas */}
                      <section>
                        <h3>Key Areas</h3>
                        <div className="rating-area-wrap">
                          <span className="rating-area-name">Arena: </span>
                          <StarRating
                            totalStars={5}
                            filledStars={allStarRatings.ChosenArenaStarRating}
                            size="small"
                            color={`var(--rarity-${getRarityColorForRating(allStarRatings.ChosenArenaStarRating)})`}
                          />
                        </div>
                        <div className="rating-area-wrap">
                          <span className="rating-area-name">Faction Wars: </span>
                          <StarRating
                            totalStars={5}
                            filledStars={allStarRatings.ChosenFWStarRating}
                            size="small"
                            color={`var(--rarity-${getRarityColorForRating(allStarRatings.ChosenFWStarRating)})`}
                          />
                        </div>
                        <div className="rating-area-wrap">
                          <span className="rating-area-name">Doom Tower: </span>
                          <StarRating
                            totalStars={5}
                            filledStars={allStarRatings.ChosenDoomTowerStarRating}
                            size="small"
                            color={`var(--rarity-${getRarityColorForRating(allStarRatings.ChosenDoomTowerStarRating)})`}
                          />
                        </div>
                        <div className="rating-area-wrap">
                          <span className="rating-area-name">Demon Lord: </span>
                          <StarRating
                            totalStars={5}
                            filledStars={allStarRatings.ChosenDemonLordStarRating}
                            size="small"
                            color={`var(--rarity-${getRarityColorForRating(allStarRatings.ChosenDemonLordStarRating)})`}
                          />
                        </div>
                        <div className="rating-area-wrap">
                          <span className="rating-area-name">Hydra: </span>
                          <StarRating
                            totalStars={5}
                            filledStars={allStarRatings.ChosenHydraStarRating}
                            size="small"
                            color={`var(--rarity-${getRarityColorForRating(allStarRatings.ChosenHydraStarRating)})`}
                          />
                        </div>
                        {/* <div className="rating-area-wrap">
                          <span className="rating-area-name">Campaign Farmer: </span>
                          <StarRating
                            totalStars={5}
                            filledStars={allStarRatings.ChosenCampaignStarRating}
                            size="small"
                            color={`var(--rarity-${getRarityColorForRating(allStarRatings.ChosenCampaignStarRating)})`}
                          />
                        </div> */}
                      </section>
                      {/* Dungeons */}
                      <section>
                        <h3>Dungeons</h3>
                        <div className="rating-area-wrap">
                          <span className="rating-area-name">Spider's Den: </span>
                          <StarRating
                            totalStars={5}
                            filledStars={allStarRatings.ChosenSpiderStarRating}
                            size="small"
                            color={`var(--rarity-${getRarityColorForRating(allStarRatings.ChosenSpiderStarRating)})`}
                          />
                        </div>
                        <div className="rating-area-wrap">
                          <span className="rating-area-name">Dragon's Lair: </span>
                          <StarRating
                            totalStars={5}
                            filledStars={allStarRatings.ChosenDragonStarRating}
                            size="small"
                            color={`var(--rarity-${getRarityColorForRating(allStarRatings.ChosenDragonStarRating)})`}
                          />
                        </div>
                        <div className="rating-area-wrap">
                          <span className="rating-area-name">Fire Knight's Castle: </span>
                          <StarRating
                            totalStars={5}
                            filledStars={allStarRatings.ChosenFireKnightStarRating}
                            size="small"
                            color={`var(--rarity-${getRarityColorForRating(
                              allStarRatings.ChosenFireKnightStarRating
                            )})`}
                          />
                        </div>
                        <div className="rating-area-wrap">
                          <span className="rating-area-name">Ice Golem's Peak: </span>
                          <StarRating
                            totalStars={5}
                            filledStars={allStarRatings.ChosenIceGolemStarRating}
                            size="small"
                            color={`var(--rarity-${getRarityColorForRating(allStarRatings.ChosenIceGolemStarRating)})`}
                          />
                        </div>
                        <div className="rating-area-wrap">
                          <span className="rating-area-name">Iron Twins: </span>
                          <StarRating
                            totalStars={5}
                            filledStars={allStarRatings.ChosenIronTwinsStarRating}
                            size="small"
                            color={`var(--rarity-${getRarityColorForRating(allStarRatings.ChosenIronTwinsStarRating)})`}
                          />
                        </div>
                        <div className="rating-area-wrap">
                          <span className="rating-area-name">Sand Devil: </span>
                          <StarRating
                            totalStars={5}
                            filledStars={allStarRatings.ChosenSandDevilStarRating}
                            size="small"
                            color={`var(--rarity-${getRarityColorForRating(allStarRatings.ChosenSandDevilStarRating)})`}
                          />
                        </div>
                        {/* <div className="rating-area-wrap">
                          <span className="rating-area-name">Minotaur's Labyrinth: </span>
                          <StarRating
                            totalStars={5}
                            filledStars={allStarRatings.ChosenMinotaurStarRating}
                            size="small"
                            color={`var(--rarity-${getRarityColorForRating(allStarRatings.ChosenMinotaurStarRating)})`}
                          />
                        </div> */}
                      </section>
                      {/* Other */}
                      <section>
                        <h3>Other</h3>
                        <div className="rating-area-wrap">
                          <span className="rating-area-name">Scarcity: </span>
                          <StarRating
                            totalStars={5}
                            filledStars={allStarRatings.ChosenScarcityStarRating}
                            size="small"
                            color={`var(--rarity-${getRarityColorForRating(allStarRatings.ChosenScarcityStarRating)})`}
                          />
                        </div>
                        <div className="rating-area-wrap">
                          <span className="rating-area-name">Progression: </span>
                          <StarRating
                            totalStars={5}
                            filledStars={allStarRatings.ChosenProgressionStarRating}
                            size="small"
                            color={`var(--rarity-${getRarityColorForRating(
                              allStarRatings.ChosenProgressionStarRating
                            )})`}
                          />
                        </div>
                        <div className="rating-area-wrap">
                          <span className="rating-area-name">End Game: </span>
                          <StarRating
                            totalStars={5}
                            filledStars={allStarRatings.ChosenEndGameStarRating}
                            size="small"
                            color={`var(--rarity-${getRarityColorForRating(allStarRatings.ChosenEndGameStarRating)})`}
                          />
                        </div>
                      </section>
                      {/* Potion Keeps */}
                      {/* <section>
                        <h3>Potion Keeps</h3>
                        <div className="rating-area-wrap">
                          <span className="rating-area-name">Force Keep: </span>
                          <StarRating
                            totalStars={5}
                            filledStars={allStarRatings.ChosenPotionKeepForceStarRating}
                            size="small"
                            color={`var(--rarity-${getRarityColorForRating(
                              allStarRatings.ChosenPotionKeepForceStarRating
                            )})`}
                          />
                        </div>
                        <div className="rating-area-wrap">
                          <span className="rating-area-name">Magic Keep: </span>
                          <StarRating
                            totalStars={5}
                            filledStars={allStarRatings.ChosenPotionKeepMagicStarRating}
                            size="small"
                            color={`var(--rarity-${getRarityColorForRating(
                              allStarRatings.ChosenPotionKeepMagicStarRating
                            )})`}
                          />
                        </div>
                        <div className="rating-area-wrap">
                          <span className="rating-area-name">Spirit Keep: </span>
                          <StarRating
                            totalStars={5}
                            filledStars={allStarRatings.ChosenPotionKeepSpiritStarRating}
                            size="small"
                            color={`var(--rarity-${getRarityColorForRating(
                              allStarRatings.ChosenPotionKeepSpiritStarRating
                            )})`}
                          />
                        </div>
                        <div className="rating-area-wrap">
                          <span className="rating-area-name">Void Keep: </span>
                          <StarRating
                            totalStars={5}
                            filledStars={allStarRatings.ChosenPotionKeepVoidStarRating}
                            size="small"
                            color={`var(--rarity-${getRarityColorForRating(
                              allStarRatings.ChosenPotionKeepVoidStarRating
                            )})`}
                          />
                        </div>
                        <div className="rating-area-wrap">
                          <span className="rating-area-name">Arcane Keep: </span>
                          <StarRating
                            totalStars={5}
                            filledStars={allStarRatings.ChosenPotionKeepArcaneStarRating}
                            size="small"
                            color={`var(--rarity-${getRarityColorForRating(
                              allStarRatings.ChosenPotionKeepArcaneStarRating
                            )})`}
                          />
                        </div>
                      </section> */}
                    </div>
                  </div>

                  <div className="VideoAndInfographic-wrap">
                    <div className="">
                      {/* Infographic */}
                      <section>
                        {ChosenInfographicId && (
                          <div className="choseNInfographicLinkID">
                            {/* QUESTION: @dnchattan what do you suggest I use for images that I want to have open in a lightbox when clicked with options to zoom in and out? Antd had a really nice and simple one called "Image" but MUI does not seem to have anything similar.
                          <NextGenImage
                            src={`https://i.imgur.com/UeWgZKr.jpg`}
                            width="100%"
                            height="auto"
                            alt={`ChoseN's Infographic for ${ChosenInfographicId}`}
                            title={`ChoseN's Infographic for ${ChosenInfographicId}`}
                          /> */}

                            <h2>Infographic</h2>
                            <img
                              src={`https://i.imgur.com/${ChosenInfographicId}.jpg`}
                              width="100%"
                              height="auto"
                              alt={`ChoseN's Infographic for ${name}`}
                              title={`ChoseN's Infographic for ${name}`}
                            />
                          </div>
                        )}
                      </section>
                      {/* Video Guide */}
                      {ChosenVideoGuideId && (
                        <section>
                          <h2>Video Guide</h2>
                          <div className="Video-wrap">
                            <iframe
                              width="962"
                              height="541"
                              src={`https://www.youtube.com/embed/${ChosenVideoGuideId}`}
                              title={`ChoseN's Video Guide for ${name}`}
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                            ></iframe>
                          </div>
                        </section>
                      )}
                    </div>
                  </div>
                </TabPanel>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
});
