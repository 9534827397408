import React from 'react';
import HotkeyComponent from './HotkeyComponent';

const ToggleChampPagesHotkey = () => {
  return (
    <div className="center resetFilters-wrap">
      <p className="small" style={{ width: 'fit-content', margin: '5px auto 0' }}>
        Set Hotkey to Toggle back and forth between the <b>All Champs</b> and <b>My Champs</b> pages:
      </p>
      <div>
        <HotkeyComponent storageKey="toggleChampPagesHotkey" />
      </div>
      <p className="small">
        This hotkey will only work when on one of the two pages and it is useful because the two pages share many of the
        same filters and the state of those filters is shared between the two pages. So if you set some of the shared
        filters, type in the searchbox, or temporarily adjust the sort order on one of the pages, you can use this
        hotkey to quickly switch to the other page and with all the same filters and the same sort order.
        <p className="mt-3">
          <span className="red">Caveat:</span> Even though the state of all the filters will be retained when switches
          between pages, not all of the query params in the URL will be retained for some reason I have spent way too
          long trying to fix. So just keep in mind that if you want to share your filter settings or refresh the page
          after switching pages the filter state that loads will reflect the few query params that do persist and any
          other filter settings you made will be lost.
        </p>
      </p>
    </div>
  );
};

export default ToggleChampPagesHotkey;
