import React from 'react';
import { getHeroRating } from '../../Data';

export interface AyumiloveRatingProps {
  slug: string | undefined;
}

export const AyumiloveRating: React.FC<AyumiloveRatingProps> = ({ slug }) => {
  let ratingValue;
  let ratingBase100Value;
  if (slug) {
    ratingValue = getHeroRating(slug)?.detailed?.ayumilove?.base5 || 0;
    ratingBase100Value = getHeroRating(slug)?.detailed?.ayumilove?.base100 || 0;
  }

  let correctSlugForSite = slug;
  if (slug === 'fren-zi-the-cackler') {
    correctSlugForSite = 'frenzi-the-cackler';
  }
  if (slug === 'ma-shalled') {
    correctSlugForSite = 'mashalled';
  }

  let ratingClass = 'noStar';
  let ratingLetter = 'N';
  if (ratingValue === 1) {
    ratingClass = 'oneStar';
    ratingLetter = 'F';
  }
  if (ratingValue === 2) {
    ratingClass = 'twoStar';
    ratingLetter = 'C';
  }
  if (ratingValue === 3) {
    ratingClass = 'threeStar';
    ratingLetter = 'B';
  }
  if (ratingValue === 4) {
    ratingClass = 'fourStar';
    ratingLetter = 'A';
  }
  if (ratingValue === 5) {
    ratingClass = 'fiveStar';
    ratingLetter = 'S';
  }

  return (
    <div className="RatingItem">
      <a
        href={`https://ayumilove.net/raid-shadow-legends-${correctSlugForSite}-skill-mastery-equip-guide/`}
        onClick={(event) => {
          event.preventDefault();
          window.open(event.currentTarget.href, 'AyumiloveTab');
        }}
        title={`Ayumilove Score is ${ratingLetter} Rank | ${ratingBase100Value} after converted to Base 100 Rating System - Click to View this champ on his Website in a New Tab and then any other champions you click the Ayumilove rating for will also load in that same tab which was opened previously even if you moved it to another screen.`}
        className="other-site-link"
      >
        {window.location.hostname === 'localhost' && ratingValue && ratingValue <= 0 ? (
          <div className="noStar" title="Not Rated by Ayumilove Yet - Click to Open his Website in a New Tab">
            N
          </div>
        ) : ratingValue && ratingValue <= 0 ? (
          <></>
        ) : (
          <div className={ratingClass}>{ratingLetter}</div>
        )}
      </a>
    </div>
  );
};
