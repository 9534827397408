import React from 'react';
import { getHeroRating } from '../../Data';

export interface ChosenRatingProps {
  slug: string | undefined;
}

export const ChosenRating: React.FC<ChosenRatingProps> = ({ slug }) => {
  let ratingValue: string | 0 = 0;
  if (slug !== undefined) {
    ratingValue = getHeroRating(slug)?.detailed?.chosen?.overall || 0;
  }

  let ratingValueAsNumber = 0;
  if (ratingValue !== 0) {
    ratingValueAsNumber = parseFloat(ratingValue);
  }

  let ratingClass = 'noStar';
  if (ratingValueAsNumber >= 1 && ratingValueAsNumber <= 20) {
    ratingClass = 'oneStar';
  }
  if (ratingValueAsNumber >= 20.1 && ratingValueAsNumber <= 40) {
    ratingClass = 'twoStar';
  }
  if (ratingValueAsNumber >= 40.1 && ratingValueAsNumber <= 60) {
    ratingClass = 'threeStar';
  }
  if (ratingValueAsNumber >= 60.1 && ratingValueAsNumber <= 80) {
    ratingClass = 'fourStar';
  }
  if (ratingValueAsNumber >= 80.1) {
    ratingClass = 'fiveStar';
  }

  return (
    <div className="RatingItem">
      <a
        href={`https://aftershockunited.gg/raid/champions/${slug}`}
        onClick={(event) => {
          event.preventDefault();
          window.open(event.currentTarget.href, 'AftershockUnitedTab');
        }}
        title={`Aftershock United score is ${ratingValue}/100 - Click to View this champ on his Website in a New Tab and then any other champions you click the Aftershock United rating for will also load in that same tab which was opened previously even if you moved it to another screen.`}
        className="other-site-link"
      >
        {window.location.hostname === 'localhost' && ratingValue <= 0 ? (
          <div className="noStar" title="Not Rated by Chosen Yet - Click to Open his Website in a New Tab">
            N
          </div>
        ) : ratingValue <= 0 ? (
          <div className="hidden"></div> // Display this when ratingValue is less than or equal to 0 on live site
        ) : (
          <div className={ratingClass}>{ratingValue}</div> // Display this when ratingValue is greater than 0
        )}
      </a>
    </div>
  );
};
