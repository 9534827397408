import { makeObservable, observable, action, computed, reaction } from 'mobx';
import { AccountInfo, ArenaLeagueId, StatKindId } from '@raid-toolkit/webclient';
import {
  AcademyData,
  ArenaData,
  ArtifactItem,
  ChampionInstance,
  ChampionItem,
  FactionId,
  GreatHallBonus,
  GuardianData,
  Rarity,
} from 'Types';
import { createStore } from './Forage';
import { HeroStatsBuilder, StatsSnapshot } from './HeroStatsBuilder';

const emptyBonus: Record<keyof typeof StatKindId, number> = {
  Health: 0,
  Attack: 0,
  Defense: 0,
  Speed: 0,
  Resistance: 0,
  Accuracy: 0,
  CriticalChance: 0,
  CriticalDamage: 0,
  CriticalHeal: 0,
};

const defaultGreatHallBonuses: GreatHallBonus[] = [
  {
    affinity: 'spirit',
    bonus: [],
    levels: emptyBonus,
  },
  {
    affinity: 'force',
    bonus: [],
    levels: emptyBonus,
  },
  {
    affinity: 'magic',
    bonus: [],
    levels: emptyBonus,
  },
  {
    affinity: 'void',
    bonus: [],
    levels: emptyBonus,
  },
];

const defaultArenaSharedData = {
  weeklyStats: { wins: 0, losses: 0, total: 0 },
  league: ArenaLeagueId.BronzeI,
  points: 0,
  greatHallBonuses: defaultGreatHallBonuses,
} as const;
export class AccountStore {
  private accountInfo: AccountInfo;
  private championMap: Record<number, ChampionItem> = {};
  private stageData: Record<number, number[]> = {};
  private artifactMap: Record<number, ArtifactItem> = {};
  private academyData: AcademyData = {
    guardians: {} as any,
  };
  private arenaData: ArenaData = {
    greatHallBonuses: [],
    classic: {
      defenseHeroIds: [],
      ...defaultArenaSharedData,
    },
    tag: { defenseHeroIds: [], placement: '', ...defaultArenaSharedData },
  };

  private dirty: boolean = true;
  private forage: LocalForage;

  constructor(private readonly accountId: string, accountInfo: AccountInfo = {} as AccountInfo) {
    this.accountInfo = accountInfo;
    makeObservable<
      AccountStore,
      | 'accountInfo'
      | 'championMap'
      | 'artifactMap'
      | 'arenaData'
      | 'academyData'
      | 'stageData'
      | 'dirty'
      | 'champions'
      | 'initializeData'
    >(this, {
      accountInfo: observable.ref,
      championMap: observable.ref,
      stageData: observable.ref,
      artifactMap: observable.ref,
      academyData: observable.ref,
      championStatsMap: computed,
      arenaData: observable.ref,
      dirty: observable,
      info: false,
      isDirty: false,
      artifacts: computed,
      champions: computed,
      guardians: computed,
      ownedChampionSlugs: computed,
      artifactEquippedBy: computed,
      name: computed,
      setDirty: action,
      clearDirty: action,
      updateInfo: action,
      setChampions: action.bound,
      setStagePresets: action.bound,
      setArtifacts: action.bound,
      setArenaData: action.bound,
      setAcademyData: action.bound,
      initializeData: action.bound,
    });
    this.forage = createStore(`account_${accountId}`);
    Promise.all([
      this.forage.getItem<Record<number, ChampionItem>>('champions'),
      this.forage.getItem<Record<number, number[]>>('presets'),
      this.forage.getItem<Record<number, ArtifactItem>>('artifacts'),
      this.forage.getItem<ArenaData>('arena'),
      this.forage.getItem<AcademyData>('academy'),
    ]).then((ar) => this.initializeData(...ar));
  }

  private initializeData(
    champions: Record<number, ChampionItem> | null,
    stageData: Record<number, number[]> | null,
    artifacts: Record<number, ArtifactItem> | null,
    arenaData: ArenaData | null,
    academyData: AcademyData | null
  ) {
    if (champions) {
      this.championMap = champions;
    }
    if (stageData) {
      this.stageData = stageData;
      //console.log(stageData);
    }
    if (artifacts) {
      this.artifactMap = artifacts;
    }
    if (arenaData) {
      this.arenaData = arenaData;
    }
    if (academyData) {
      this.academyData = academyData;
    }
    reaction(
      () => [this.championMap, this.artifactMap, this.arenaData, this.academyData, this.stageData],
      ([champions, artifacts, arena, academyData, presets]) => {
        this.forage.setItem('champions', champions);
        this.forage.setItem('artifacts', artifacts);
        this.forage.setItem('arena', arena);
        this.forage.setItem('academy', academyData);
        this.forage.setItem('presets', presets);
      }
    );
  }

  get champions(): ReadonlyArray<ChampionItem> {
    return Object.values(this.championMap);
  }

  get presets(): Record<number, number[]> {
    return this.stageData;
  }

  get artifacts(): Record<number, ArtifactItem> {
    return this.artifactMap;
  }

  get guardians(): Record<FactionId, Record<Rarity, GuardianData>> {
    return this.academyData.guardians;
  }

  get arena(): Readonly<ArenaData> {
    return this.arenaData;
  }

  get ownedChampionSlugs(): string[] {
    return Array.from(new Set(this.champions.map((entry) => entry.type.slug)));
  }

  get artifactEquippedBy(): Map<number, ChampionItem> {
    const artifactToChampion = new Map<number, ChampionItem>();
    for (const hero of Object.values(this.championMap)) {
      for (const artifactId of Object.values(hero.instance!.equippedArtifactIds)) {
        artifactToChampion.set(artifactId, hero);
      }
    }
    return artifactToChampion;
  }

  get info(): Readonly<AccountInfo> {
    return this.accountInfo;
  }

  get name(): string {
    return `${this.info.name}${this.dirty ? '*' : ''}`;
  }

  get isDirty(): boolean {
    return this.dirty;
  }

  get championStatsMap(): Record<number, StatsSnapshot> {
    return Object.fromEntries(
      this.champions.map((hero) => [hero.instance!.id, new HeroStatsBuilder(hero, this).Snapshot])
    );
  }

  setDirty() {
    this.dirty = true;
  }

  clearDirty() {
    this.dirty = false;
  }

  updateInfo(account: AccountInfo) {
    this.accountInfo = account;
  }

  setChampions(champions: ChampionInstance[]) {
    const champsMap: Record<number, ChampionInstance> = {};
    for (const champ of champions) {
      champsMap[champ.instance.id] = champ;
    }
    this.championMap = champsMap;
  }

  setStagePresets(stageData: Record<number, number[]>): void {
    this.stageData = stageData;
    this.forage.setItem('presets', stageData);
  }

  setArtifacts(artifacts: ArtifactItem[]) {
    const artifactMap: Record<number, ArtifactItem> = {};
    for (const artifact of artifacts) {
      artifactMap[artifact.id] = artifact;
    }
    this.artifactMap = artifactMap;
  }

  setArenaData(arenaData: ArenaData) {
    this.arenaData = arenaData;
  }

  setAcademyData(academyData: AcademyData) {
    this.academyData = academyData;
  }
}
