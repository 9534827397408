import React, { useEffect, useState } from 'react';
import axios from 'axios';

interface Props {
  trackingData: any;
}

const DynamicImage: React.FC<Props> = ({ trackingData }) => {
  const [imageSrc, setImageSrc] = useState<string>('');

  console.log(`trackingData`, trackingData);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await axios.post('/.netlify/functions/getDynamicImage', {
          trackingData,
        });
        setImageSrc(response.data.imageUrl);
      } catch (error) {
        console.error('Error fetching dynamic image:', error);
      }
    };

    fetchImage();
  }, [trackingData]);

  return <img src={imageSrc} alt="Dynamic Article Image" />;
};

export default DynamicImage;
