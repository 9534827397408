import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useLocation } from 'react-router';

type SetValue<T> = Dispatch<SetStateAction<T>>;

let isDarkMode;
if (typeof window !== 'undefined') {
  isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
}
//console.log(`DarkModeToggle isDarkMode`, isDarkMode);

const systemTheme = isDarkMode ? 'dark' : 'light';

export interface Settings {
  'open-champ-pages-in-new-tab': boolean;
  'my-champs-only': boolean;
  'show-ratings': boolean;
  'site-theme': 'light' | 'dark';
  'filter-tab': number;
  'filter-cc-tab': number;
  'stage-of-the-game': string;
}

const DefaultSettings: Settings = {
  'open-champ-pages-in-new-tab': false,
  'my-champs-only': false,
  'show-ratings': false,
  'site-theme': systemTheme,
  'filter-tab': 0,
  'filter-cc-tab': 0,
  'stage-of-the-game': undefined,
};

export function usePageSetting<K extends keyof Settings>(
  settingName: K
): [value: Settings[K], setValue: SetValue<Settings[K]>] {
  const { pathname } = useLocation();
  const initialValue = DefaultSettings[settingName];
  return useSettingInternal(`${pathname}:${settingName}` as unknown as K, initialValue);
}

export function useSetting<K extends keyof Settings>(
  settingName: K
): [value: Settings[K], setValue: SetValue<Settings[K]>] {
  const initialValue = DefaultSettings[settingName];
  return useSettingInternal(settingName, initialValue);
}

export function useSettingInternal<K extends keyof Settings, U>(
  settingName: K,
  initialValue: U
): [value: U, setValue: SetValue<U>] {
  const key = `setting:${settingName}`;
  // Get from local storage then
  // parse stored json or return initialValue
  const readValue = (): U => {
    // Prevent build error "window is undefined" but keep keep working
    if (typeof window === 'undefined') {
      return initialValue;
    }

    try {
      const item = window.localStorage.getItem(key);
      return item ? (JSON.parse(item) as U) : initialValue;
    } catch (error) {
      console.warn(`Error reading localStorage key “${key}”:`, error);
      return initialValue;
    }
  };

  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<U>(readValue);

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue: SetValue<U> = (value) => {
    // Prevent build error "window is undefined" but keeps working
    if (typeof window == 'undefined') {
      console.warn(`Tried setting localStorage key “${key}” even though environment is not a client`);
    }

    try {
      // Allow value to be a function so we have the same API as useState
      let newValue: any = value instanceof Function ? value(storedValue) : value;

      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(newValue));

      // Save state
      setStoredValue(newValue);

      // We dispatch a custom event so every useLocalStorage hook are notified
      window.dispatchEvent(new Event('local-storage'));
    } catch (error) {
      console.warn(`Error setting localStorage key “${key}”:`, error);
    }
  };

  useEffect(() => {
    const handleStorageChange = () => {
      setStoredValue(readValue());
    };

    // this only works for other documents, not the current one
    window.addEventListener('storage', handleStorageChange);

    // this is a custom event, triggered in writeValueToLocalStorage
    window.addEventListener('local-storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
      window.removeEventListener('local-storage', handleStorageChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [storedValue, setValue];
}
