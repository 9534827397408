import React from 'react';
import { mapFilter } from 'Helpers';
import { getDamageVariables } from '../../Data';
import './DamageBasedOn.css';
import { NextGenImage } from '../../Components/Library';

const damageVariables = getDamageVariables();
export interface DamageBasedOnProps {
  damageBasedOn?: string[];
  local?: boolean;
}

export const DamageBasedOn: React.FC<DamageBasedOnProps> = ({ damageBasedOn, local }) => {
  const tip = `Damage is based on`;

  return (
    <div className="hit-damageBasedOn">
      {damageBasedOn
        ? mapFilter(
            mapFilter(damageBasedOn, (key) => damageVariables[key]),
            ({ name, iconImage, iconDirectory }) =>
              iconDirectory ? (
                <div key={name} className={`hit-damageBasedOn-img-wrap ${name}`}>
                  <NextGenImage
                    whichDirectory={iconDirectory}
                    whichImage={iconImage}
                    alt={`${tip} ${name}`}
                    title={`${tip} ${name}`}
                    local={local}
                  />
                </div>
              ) : null
          )
        : null}
    </div>
  );
};
