import React from 'react';
import { Switch, FormGroup, FormControlLabel, FormControl } from '@mui/material';
import { useSetting } from '../Hooks';

export const DarkModeToggle: React.FC = () => {
  const [themeName, setThemeName] = useSetting('site-theme');
  const toggleTheme = React.useCallback((value: boolean) => {
    setThemeName(value ? 'dark' : 'light');
  }, []);
  return (
    <FormControl component="div" className="darkModeToggle">
      <FormGroup aria-label="position" row>
        <FormControlLabel
          value="Dark mode"
          control={<Switch color="primary" />}
          label="Dark mode"
          labelPlacement="end"
          onChange={(e: React.ChangeEvent<{}>) => toggleTheme((e.target as HTMLInputElement).checked as boolean)}
          checked={themeName === 'dark'}
        />
      </FormGroup>
    </FormControl>
  );
};
