import React from 'react';
import './JumpToNextChamp.css';
import { ChampionType, FactionId, Rarity } from '../../Types';
import { Autocomplete, InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import Tooltip from 'rc-tooltip';
import { useStaticData } from '../../Data';

export const JumpToNextChamp: React.FC = ({}) => {
  const { slug } = useParams<{ slug: string }>();
  const navigate = useNavigate();
  const heroTypes = useStaticData().heroTypes;

  // get last entry for fully ranked/ascended version of hero
  const allOtherChamps: ChampionType[] = (Object.values(heroTypes) as ChampionType[]).filter(
    (t) => (t.typeId % 10 === 6 || t.rarity === Rarity.common) && t.slug !== slug && t.faction !== FactionId.unknown
  );
  //   console.log(`allOtherChamps`, allOtherChamps);

  return (
    <div className="JumpToNextChamp-wrap">
      <div>
        <Autocomplete
          id="JumpToNextChamp"
          options={allOtherChamps}
          disableClearable
          forcePopupIcon={false}
          getOptionLabel={(option) => option.name}
          onChange={(event, value) => navigate(`/champions/${value?.slug}`)}
          renderInput={(params) => {
            return (
              <Tooltip
                arrowContent={<div className="rc-tooltip-arrow-inner" />}
                placement="top"
                overlay={<span>Quickly jump to another champ's page by searching for and clicking their name...</span>}
              >
                <TextField
                  {...params}
                  label="View different champ..."
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Tooltip>
            );
          }}
        />
      </div>
    </div>
  );
};
