import React from 'react';
import './ChampListPage.css';
import { ArtifactList, ChampionList, NoAccountData, SharedFilters } from 'Components';
import { Key } from 'Components/Layout/Key';
import { useListView, useRootStore } from 'Model';
import { observer } from 'mobx-react-lite';
import { ViewType } from 'Components/DataView/ViewOptions';
import { ToggleButton } from '@mui/material';
import { usePageSetting, withViewType } from 'Components/Hooks';
import './MyArtifacts.css';
import { Helmet } from 'react-helmet-async';
//import { MyArtifactsSearchFilter } from 'Components/DataView/Filters';

/* Default Sort Order: ['grade', 'ascended', 'level', 'rarity', 'affinity', 'name', ] */
export const MyArtifacts: React.FC = withViewType(
  ViewType.Artifacts,
  observer(() => {
    const root = useRootStore();
    const [ShowRatingsBoolean, setShowRatingsBoolean] = usePageSetting('show-ratings');

    const listView = root.listViews[ViewType.Artifacts];
    const artifacts = root.accounts.selectedAccount?.artifacts || [];
    const artifactsArray = Object.values(artifacts);
    const items = listView.filterAndSort(artifactsArray);
    //console.log(`artifactsArray`, artifactsArray)
    return (
      <>
        <Helmet>
          <title>My Artifacts | RaidChamps.com</title>
          <meta
            name="description"
            content="Search, sort and filter through all the artifacts on your Raid account synced live as you play!"
          />
          <meta itemProp="name" content="My Artifacts | RaidChamps.com" />
          <meta
            itemProp="description"
            content="Search, sort and filter through all the artifacts on your Raid account synced live as you play!"
          />
          <meta itemProp="image" content="https://ik.imagekit.io/raidchamps/_NotExtracted/logo.avif" />
          <meta property="og:url" content="https://raidchamps.com/mychamps" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="My Artifacts | RaidChamps.com" />
          <meta
            property="og:description"
            content="Search, sort and filter through all the artifacts on your Raid account synced live as you play!"
          />
          <meta property="og:image" content="https://ik.imagekit.io/raidchamps/_NotExtracted/logo.avif" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="My Artifacts | RaidChamps.com" />
          <meta
            name="twitter:description"
            content="Search, sort and filter through all the artifacts on your Raid account synced live as you play!"
          />
          <meta name="twitter:image" content="https://ik.imagekit.io/raidchamps/_NotExtracted/logo.avif" />
        </Helmet>
        <div
          className="MyArtifactsPage ArtifactsListPage ListPage"
          style={{ display: 'flex', flexDirection: 'column', padding: 15 }}
        >
          <h1 className="with-underline deep-underline center">My Artifacts</h1>
          <div style={{ flex: 1 }}>
            <div className="center">
              {root.accounts.selectedAccount ? (
                <>
                  <SharedFilters />
                  {/* <div className="TogglesRow-wrap">
                    <div className="ToggleButton-wrap">
                      <ToggleButton
                        className="ShowRatingsToggle"
                        value="check"
                        selected={ShowRatingsBoolean}
                        onChange={() => {
                          setShowRatingsBoolean(!ShowRatingsBoolean);
                        }}
                      >
                        <span>Show Ratings</span>
                      </ToggleButton>
                    </div>
                  </div> */}
                </>
              ) : (
                <NoAccountData />
              )}
            </div>
            <div>
              <div className="center">
                Displaying <span className="yellow champCount">{items.length}</span> artifacts
              </div>
              <ArtifactList items={items} />
            </div>
          </div>
        </div>
        <Key />
      </>
    );
  })
);
