import React from 'react';
import { useRootStore } from 'Model';
import { StatsSnapshot } from 'Model/HeroStatsBuilder';
import { ChampionItem, StatKind } from 'Types';
import './ChampStatsTable.css';
import { statSourceKeys, formatStatSource, orderedStatNameToStatKind } from './StatsHelpers';
import { observer } from 'mobx-react-lite';

export interface ChampStatsTableProps {
  item: ChampionItem;
}

const StatsRow: React.FC<{ statName: string; statKind: StatKind; stats: StatsSnapshot }> = ({
  statName,
  statKind,
  stats,
}) => {
  return (
    <tr>
      <th>{statName}</th>
      {statSourceKeys.map((statSource) => (
        <td>{formatStatSource[statSource](statKind, stats.statSources[statSource][statKind])}</td>
      ))}
    </tr>
  );
};

const StatsHeader: React.FC = () => {
  return (
    <tr>
      {statSourceKeys.map((statSource, idx) =>
        idx == 0 ? (
          <th key={`source_${idx}`} colSpan={2}>
            {statSource}
          </th>
        ) : (
          <td key={`source_${idx}`}>{statSource}</td>
        )
      )}
    </tr>
  );
};

export const ChampStatsTable: React.FC<ChampStatsTableProps> = observer(({ item }) => {
  const account = useRootStore().accounts.selectedAccount;
  const stats = item?.instance && account?.championStatsMap[item?.instance.id];
  if (!stats) return null;

  return (
    <table className="stats-table">
      <thead>
        <StatsHeader />
      </thead>
      <tbody>
        {Object.entries(orderedStatNameToStatKind).map(([statName, statKind]) => (
          <StatsRow statKind={statKind} statName={statName} stats={stats} />
        ))}
      </tbody>
    </table>
  );
});
