import React from 'react';
import { Affinity as AffinityEnum } from '../../Types';
import { getAffinityData } from '../../Data';
import { NextGenImage } from '../../Components/Library';
import { useRootStore } from '../../Model';
import { getFactionGuardians } from '../DataView/Helpers';

export interface FactionGuardianIconProps {
  className?: string;
  style?: React.CSSProperties;
  id: number;
}

export const FactionGuardianIcon: React.FC<FactionGuardianIconProps> = ({ id, style, className }) => {
  const store = useRootStore();
  const keysOfAllFactionGuardians = getFactionGuardians(store.accounts.selectedAccount?.guardians);
  if (keysOfAllFactionGuardians?.includes(id)) {
    return (
      <NextGenImage
        className={className}
        style={style}
        alt={`Faction Guardian`}
        title={`Faction Guardian`}
        whichDirectory={`_NotExtracted/myCreations`}
        whichImage={`FactionGuardianMarker`}
      />
    );
  } else {
    return <></>;
  }
};
