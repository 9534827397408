import { saveCurrentFilters } from 'Components/DataView/Helpers';
import { getGlobalRootStore } from 'Model';
import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useLocation, useNavigate } from 'react-router-dom';

const HotkeyContext = createContext({
  resetFiltersHotkey: '',
  setResetFiltersHotkey: (value: string) => {},
  saveFiltersHotkey: '',
  setSaveFiltersHotkey: (value: string) => {},
  toggleChampPagesHotkey: '',
  setToggleChampPagesHotkey: (value: string) => {},
  savedFiltersExist: false,
  setSavedFiltersExist: (value: boolean) => {},
});

export const HotkeyProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [resetFiltersHotkey, setResetFiltersHotkey] = useState('');
  const [saveFiltersHotkey, setSaveFiltersHotkey] = useState('');
  const [toggleChampPagesHotkey, setToggleChampPagesHotkey] = useState('');
  const [savedFiltersExist, setSavedFiltersExist] = useState(false);

  useEffect(() => {
    // Retrieve hotkeys from localStorage and log them
    const resetHotkey = localStorage.getItem('resetFiltersHotkey') || '';
    const saveHotkey = localStorage.getItem('saveFiltersHotkey') || '';
    const toggleHotkey = localStorage.getItem('toggleChampPagesHotkey') || '';
    //console.log(`Reset hotkey (from localStorage): ${resetHotkey}`);
    //console.log(`Save hotkey (from localStorage): ${saveHotkey}`);
    //console.log(`Toggle hotkey (from localStorage): ${toggleHotkey}`);

    // Set the hotkeys in state
    setResetFiltersHotkey(resetHotkey);
    setSaveFiltersHotkey(saveHotkey);
    setToggleChampPagesHotkey(toggleHotkey);
  }, []);

  // Hotkey action for resetting filters
  useHotkeys(resetFiltersHotkey, () => {
    // Log when this hotkey event handler is invoked
    //console.log('Reset filters hotkey invoked');

    const { protocol, host, pathname } = window.location;
    window.location.href = `${protocol}//${host}${pathname}`;
    //console.log('Reset Filters');
  });

  // Hotkey action for saving filters
  useHotkeys(saveFiltersHotkey, () => {
    saveCurrentFilters();
    setSavedFiltersExist(true);

    // Log when this hotkey event handler is invoked
    //console.log('Save filters hotkey invoked');
  });

  const { pathname } = useLocation();

  // Hotkey action for toggling champ pages
  useHotkeys(toggleChampPagesHotkey, () => {
    // Log when this hotkey event handler is invoked
    //console.log('Toggle champ pages hotkey invoked');

    if (pathname.startsWith('/mychamps')) {
      navigate('/');
    } else {
      navigate('/mychamps');
    }
    console.log('Toggle Champ Pages');
  });

  const value = {
    resetFiltersHotkey,
    setResetFiltersHotkey,
    saveFiltersHotkey,
    setSaveFiltersHotkey,
    toggleChampPagesHotkey,
    setToggleChampPagesHotkey,
    savedFiltersExist,
    setSavedFiltersExist,
  };

  return <HotkeyContext.Provider value={value}>{children}</HotkeyContext.Provider>;
};

export const useHotkeysContext = () => {
  const context = useContext(HotkeyContext);
  if (!context) {
    throw new Error('useHotkeysContext must be used within a HotkeyProvider');
  }
  return context;
};
