import React from 'react';
import Tooltip from 'rc-tooltip';
import { RTKConnectButton } from '../Library';
import { NavLinks } from '../Library';
import './Header.css';
import { CornerMenu } from './CornerMenu';
import { Helmet } from 'react-helmet-async';
import { NextGenImage } from '../../Components/Library';
import '@reach/tabs/styles.css';
import { useAuth0 } from '@auth0/auth0-react';
import Auth0LoginLogout from './Auth0LoginLogout';
import Auth0Subscribe from './Auth0Subscribe';
import Auth0Register from './Auth0Register';
import { CornerAdRandomizer } from './CornerAdRandomizer';
import Delayed from './Delayed';
import { Link } from 'react-router-dom';
import { TopNoticeToolTip } from './TopNoticeToolTip';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import HotkeyComponent from 'Components/Library/HotkeyComponent';
import ToggleChampPages from 'Components/Library/ToggleChampPagesHotkey';
import { AllChampsSearchFilter } from 'Components/DataView/Filters';

export const hasRole = (roles: string[], targetRoles: string[]): boolean => {
  return roles.some((role) => targetRoles.some((targetRole) => role.includes(targetRole)));
};

export const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'transparent',
  border: 'none',
  boxShadow: 24,
  p: 4,
};

declare global {
  interface Navigator {
    brave?: {
      isBrave(): Promise<boolean>;
    };
  }
}

declare const InstallTrigger: any;

const isBrave = (navigator.brave && (await navigator.brave.isBrave())) || false;

const isFirefox = typeof InstallTrigger !== 'undefined';

//class Header extends React.PureComponent {
export const Header: React.FC = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();

  let roles = [];
  if (user && user['https://raidchamps.com/app_metadata'].roles) {
    if (user['https://raidchamps.com/app_metadata'].roles.length > 0) {
      roles = user['https://raidchamps.com/app_metadata'].roles;
    }
  }

  //console.log(`roles from header`, roles);

  /* const Ads = [
    {
      title:
        'Liquid Web Managed Cloud Hosting: Cloud Sites starting at $51/ Private & Dedicated Cloud starting at $117!',
      image: 'https://app.impact.com/display-ad/4464-362712?v=25',
      link: 'https://liquidweb.i3f2.net/c/4090035/362708/4464?partnerpropertyid=4127155',
    },
    {
      title: 'Liquid Web Windows Dedicated Servers from $149/mo!- Fully Managed, Completely Customizable & Monitored',
      image: 'https://app.impact.com/display-ad/4464-400160?v=15',
      link: 'https://liquidweb.i3f2.net/c/4090035/390589/4464?partnerpropertyid=4127155',
    },
    {
      title: 'Liquid Web Managed WordPress - Built for Speed & Security by Hosting Experts!',
      image: 'https://app.impact.com/display-ad/4464-297275?v=27',
      link: 'https://liquidweb.i3f2.net/c/4090035/297275/4464?partnerpropertyid=4127155',
    },
    {
      title: 'Liquid Web Dedicated Server HIPAA Compliant Security & Solutions',
      image: 'https://app.impact.com/display-ad/4464-297294?v=24',
      link: 'https://liquidweb.i3f2.net/c/4090035/297294/4464?partnerpropertyid=4127155',
    },
    {
      title: 'New iThemes Brand Shared WordPress Hosting - Take 20% off on annual plan - Starting at $7.50/ month!',
      image: 'https://app.impact.com/display-ad/4464-519622?v=13',
      link: 'https://liquidweb.i3f2.net/c/4090035/519622/4464?partnerpropertyid=4127155',
    },
    {
      title:
        'Liquid Web Managed WooCommerce Hosting. The fastest, easiest and most scalable eCommerce solution available. Now starting at $28/mo!',
      image: 'https://app.impact.com/display-ad/4464-485488?v=21',
      link: 'https://liquidweb.i3f2.net/c/4090035/485488/4464?partnerpropertyid=4127155',
    },
  ]; */

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <header id="site-header" className={`site-header ${roles.includes('Callous') ? 'Callous' : ''}`}>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>

      {isAuthenticated ? (
        <>
          {roles.includes('Admin') ? (
            <p className="notice membership admin">
              <span className="adminView">ADMIN VIEW </span>
              <Auth0Subscribe roles={roles} />
              {console.log(`roles`, roles)}
            </p>
          ) : (
            <>
              <div className="notice membership">
                {roles.length > 0 && hasRole(roles, ['Content Creator', 'Advanced Features']) && (
                  <div className="powerIcon-wrap one flex">
                    POWERS:
                    <div className="powerIcon-wrap three">
                      <NextGenImage
                        whichDirectory="_NotExtracted/otherIcons"
                        whichImage="/tr:w-35/IdomooStar"
                        alt="Advanced Features"
                        title="Advanced Features"
                        className="AdvancedFeaturesIcon info"
                      />
                    </div>
                  </div>
                )}
                {/* Bring back ads
                 {roles.includes('Content Creator') ||
                roles.includes('Remove Ads') ||
                roles.includes('Remove Ads (Monthly)') ||
                roles.includes('Remove Ads (Yearly)') ? (
                  <div className="powerIcon-wrap four">
                    <NextGenImage
                      whichDirectory="_NotExtracted/otherIcons"
                      whichImage="/tr:w-35/IdomooStar"
                      alt="Remove Ads"
                      title="Remove Ads"
                      className="RemoveAdsIcon info"
                    />
                  </div>
                ) : (
                  <>
                    <Delayed>
                      <CornerAdRandomizer Ads={Ads} className="left" />
                    </Delayed>
                    <Delayed waitBeforeShow={2}>
                      <CornerAdRandomizer Ads={Ads} className="right" />
                    </Delayed>
                  </>
                )} */}
                <Auth0Subscribe roles={roles} />
              </div>
            </>
          )}
        </>
      ) : (
        <>
          {/* Bring back ads
           <Delayed>
            <CornerAdRandomizer Ads={Ads} className="left" />
          </Delayed>
          <Delayed waitBeforeShow={2}>
            <CornerAdRandomizer Ads={Ads} className="right" />
          </Delayed> */}
          <div className="notice membership loggedOut notMember">
            <Auth0Register />
          </div>
        </>
      )}
      <div className="notice">
        {/* {isAuthenticated ? (
          <>
            {roles.length >= 1 ? (
              <></>
            ) : (
              <span className="rtk-notice">
              </span>
            )}
          </>
        ) : (
          <span className="rtk-notice">
          </span>
        )} */}
      </div>
      <div className="branding">
        <div className="column left">
          <a
            href="https://discord.gg/zhqmadUyys"
            className="header-discord"
            target="_blank"
            rel="noopener noreferrer"
            title="Join us on Discord"
          >
            <NextGenImage
              whichDirectory="_NotExtracted/socialIcons"
              whichImage="discord-chat-icon"
              alt="Discord Icon"
              title="Discord Icon"
            />
            <span className="">Join us on Discord</span>
          </a>
          <a className="header-logo" href="/">
            <NextGenImage
              whichDirectory="_NotExtracted"
              whichImage="logo"
              alt="RaidChamps Logo"
              title="RaidChamps Logo"
            />
          </a>
        </div>
        <div className="column middle">
          <div className="flex">
            {isBrave && (
              <>
                <a className="ModalLink brave" onClick={handleOpen}>
                  Click here to see how to adjust your Brave browser settings to allow you to login
                </a>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={modalStyle} className="ModalBox">
                    <NextGenImage
                      whichDirectory="_NotExtracted"
                      whichImage={`fixLoginOnBrave`}
                      title={`How to adjust your Brave settings to allow you to login`}
                      alt={`How to adjust your Brave settings to allow you to login`}
                    />
                  </Box>
                </Modal>
              </>
            )}
            {isFirefox && (
              <>
                <a className="ModalLink firefox" onClick={handleOpen}>
                  Click here to see how to adjust your Firefox browser settings to allow you to login
                </a>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={modalStyle} className="ModalBox">
                    <NextGenImage
                      whichDirectory="_NotExtracted"
                      whichImage={`fixLoginOnFirefox`}
                      title={`How to adjust your Firefox settings to allow you to login`}
                      alt={`How to adjust your Firefox settings to allow you to login`}
                    />
                  </Box>
                </Modal>
              </>
            )}
            {/* {!isFirefox && (
              <>
                <Tooltip
                  arrowContent={<div className="rc-tooltip-arrow-inner" />}
                  placement="bottom"
                  overlay={
                    <>
                      <p className="center">
                        If you happen to have any idea what is causing the mouse lag in Chromium browsers please let me
                        know because it has me baffled.
                      </p>
                      <p className="center">
                        You can reach me via my{' '}
                        <a href="https://discord.gg/zhqmadUyys" target="_blank">
                          Discord server
                        </a>{' '}
                        or the <Link to="/contact">Contact</Link> page.
                      </p>
                    </>
                  }
                >
                  <p className="notFirefoxMessageAboutMouseLag">
                    If you experience mouse lag after closing the popup modals, use Firefox because it does not have
                    that issue.
                  </p>
                </Tooltip>
              </>
            )} */}
          </div>
          <div className="CTAcontent center">
            <h3>
              <span className="serif" title="I will add the new champs as soon as Raid Toolkit is fixed for Patch 6.50">
                Total Stat Calculations updated for the Blessings Rebalance
              </span>
            </h3>

            {/* <p className="yellow center">You can now filter for the Sheep,</p> */}
          </div>
        </div>
        {/* <div className="CTAcontent center">
            <h3>
              <span className="serif">
                <Link to="/profile">Subscribe to Advanced Features</Link>
              </span>
            </h3>

            <p className="yellow center">
              Free Subscriptions Available for Content Creators who do a video about the site.
            </p>
          </div>
        </div> */}
        <div className="column right">
          {/* <div className="membershipStuff-relative-wrap">
            <div className="membershipStuff-wrap">{membershipStuff}</div>
          </div> */}
          <Auth0LoginLogout />
          <div className="donateLink-wrap">
            <a
              href="https://www.paypal.com/donate?hosted_button_id=XTYYNSSB9QXA4"
              rel="noopener noreferrer"
              target="_blank"
              className="donateLink"
            >
              Donate
            </a>
          </div>
        </div>
      </div>
      <div className="main-nav-wrap">
        <NavLinks className="horizontal" />

        <div className="rtkMenu">
          <RTKConnectButton />
        </div>
      </div>
      <div className="mobile-menu">
        <CornerMenu position="top left" text="Menu">
          <NavLinks />
        </CornerMenu>

        <div className="rtkMenu">
          <RTKConnectButton />
        </div>
      </div>
      {/* <OpenChampsInNewTab_UseLocalStorageStateToggle
          name="OpenChampPagesInNewTab"
          text="Open Champ Pages in New Tab"
        /> */}
      <div className={`fixedSearchbox`}>
        <AllChampsSearchFilter.Component />
      </div>
    </header>
  );
};
