import { SortDescriptor, SortFunction } from 'Model/ListViewTypes';
import React from 'react';
import {
  getAffinities,
  getArtifactRarity,
  getArtifactSets,
  getArtifactSlots,
  getBlessings,
  getFactions,
  getGearSlots,
  getRarities,
  getRoles,
  OrderedDisplayMetadata,
} from '../../Data';
import { ArtifactType, ChampionItem, DataItem } from '../../Types';
import { canHandleAny, convertDecimalToSortableNumber, getAwakenRankNumber, getRankNumber } from './Helpers';
import { ViewOptions, ViewType } from './ViewOptions';
import { getStats, scaleBaseStat, scaleBaseStats } from 'Components/Library/StatsHelpers';
import { StatsSnapshot } from 'Model/HeroStatsBuilder';

function defaultSort(a: any, b: any) {
  if (a == b) return 0;
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
}

function defaultSortBy<T extends DataItem, R>(selector: (value: T) => R) {
  return (a: T, b: T) => defaultSort(selector(a), selector(b));
}

function sortByOrdinal<T extends DataItem, R extends string>(
  selector: (value: T) => R,
  lookup: Record<R, OrderedDisplayMetadata>
) {
  return defaultSortBy<T, number>((value) => lookup[selector(value)]?.ordinal ?? 0);
}

function reverseSort<T>(sortFunction: SortFunction<T>): SortFunction<T> {
  return (a, b) => (sortFunction(a, b) * -1) as 1 | 0 | -1;
}

export const sorts: Record<string, SortDescriptor<ChampionItem, ViewOptions>> = {
  baseHealth: {
    name: 'Health',
    canHandle(opts) {
      return opts.viewType === ViewType.Index;
    },
    sortFn: reverseSort(defaultSortBy((hero) => hero.type.baseStats.health || 0)),
  },
  baseAttack: {
    name: 'Attack',
    canHandle(opts) {
      return opts.viewType === ViewType.Index;
    },
    sortFn: reverseSort(defaultSortBy((hero) => hero.type.baseStats.attack || 0)),
  },
  baseDefense: {
    name: 'Defense',
    canHandle(opts) {
      return opts.viewType === ViewType.Index;
    },
    sortFn: reverseSort(defaultSortBy((hero) => hero.type.baseStats.defense || 0)),
  },
  baseSpeed: {
    name: 'Speed',
    canHandle(opts) {
      return opts.viewType === ViewType.Index;
    },
    sortFn: reverseSort(defaultSortBy((hero) => hero.type.baseStats.speed || 0)),
  },
  baseCriticalChance: {
    name: 'Critical Rate',
    canHandle(opts) {
      return opts.viewType === ViewType.Index;
    },
    sortFn: reverseSort(defaultSortBy((hero) => hero.type.baseStats.criticalchance || 0)),
  },
  baseCriticalDamage: {
    name: 'Critical Damage',
    canHandle(opts) {
      return opts.viewType === ViewType.Index;
    },
    sortFn: reverseSort(defaultSortBy((hero) => hero.type.baseStats.criticaldamage || 0)),
  },
  baseResistance: {
    name: 'Resistance',
    canHandle(opts) {
      return opts.viewType === ViewType.Index;
    },
    sortFn: reverseSort(defaultSortBy((hero) => hero.type.baseStats.resistance || 0)),
  },
  baseAccuracy: {
    name: 'Accuracy',
    canHandle(opts) {
      return opts.viewType === ViewType.Index;
    },
    sortFn: reverseSort(defaultSortBy((hero) => hero.type.baseStats.accuracy || 0)),
  },
  health: {
    name: 'Health',
    canHandle(opts) {
      return opts.viewType === ViewType.Account;
    },
    sortFn: reverseSort(defaultSortBy((hero) => getStats(hero)?.effectiveStats.health || 0)),
  },
  attack: {
    name: 'Attack',
    canHandle(opts) {
      return opts.viewType === ViewType.Account;
    },
    sortFn: reverseSort(defaultSortBy((hero) => getStats(hero)?.effectiveStats.attack || 0)),
  },
  defense: {
    name: 'Defense',
    canHandle(opts) {
      return opts.viewType === ViewType.Account;
    },
    sortFn: reverseSort(defaultSortBy((hero) => getStats(hero)?.effectiveStats.defense || 0)),
  },
  speed: {
    name: 'Speed',
    canHandle(opts) {
      return opts.viewType === ViewType.Account;
    },
    sortFn: reverseSort(defaultSortBy((hero) => getStats(hero)?.effectiveStats.speed || 0)),
  },
  criticalchance: {
    name: 'Critical Rate',
    canHandle(opts) {
      return opts.viewType === ViewType.Account;
    },
    sortFn: reverseSort(defaultSortBy((hero) => getStats(hero)?.effectiveStats.criticalchance || 0)),
  },
  criticaldamage: {
    name: 'Critical Damage',
    canHandle(opts) {
      return opts.viewType === ViewType.Account;
    },
    sortFn: reverseSort(defaultSortBy((hero) => getStats(hero)?.effectiveStats.criticaldamage || 0)),
  },
  resistance: {
    name: 'Resistance',
    canHandle(opts) {
      return opts.viewType === ViewType.Account;
    },
    sortFn: reverseSort(defaultSortBy((hero) => getStats(hero)?.effectiveStats.resistance || 0)),
  },
  accuracy: {
    name: 'Accuracy',
    canHandle(opts) {
      return opts.viewType === ViewType.Account;
    },
    sortFn: reverseSort(defaultSortBy((hero) => getStats(hero)?.effectiveStats.accuracy || 0)),
  },
  name: {
    name: 'Name',
    canHandle: canHandleAny,
    sortFn: defaultSortBy((hero) => hero.type.name),
  },
  typeId: {
    name: 'Date Added',
    canHandle: canHandleAny,
    sortFn: reverseSort(defaultSortBy((hero) => hero.type.typeId)),
  },
  affinity: {
    name: 'Affinity',
    canHandle: canHandleAny,
    sortFn: sortByOrdinal((hero) => hero.type.affinity, getAffinities()),
  },
  rarity: {
    name: 'Rarity',
    canHandle: canHandleAny,
    sortFn: reverseSort(sortByOrdinal((hero) => hero.type.rarity, getRarities())),
  },
  faction: {
    name: 'Faction',
    canHandle: canHandleAny,
    sortFn: sortByOrdinal((hero) => hero.type.faction, getFactions()),
  },
  type: {
    name: 'Type',
    canHandle: canHandleAny,
    sortFn: sortByOrdinal((hero) => hero.type.role, getRoles()),
  },
  damageBasedOn: {
    name: 'Damage Based On',
    canHandle: canHandleAny,
    sortFn: defaultSortBy((hero) => hero.type.indexed.damageBasedOn?.slice().sort().join(' ') || ''),
  },
  ratingOverall: {
    name: 'Rating: Overall',
    canHandle: canHandleAny,
    sortFn: reverseSort(defaultSortBy((hero) => hero.type.ratings.overall || 0)),
  },
  ratingHH: {
    name: 'Rating: HellHades',
    canHandle: canHandleAny,
    sortFn: reverseSort(defaultSortBy((hero) => hero.type.ratings.detailed.hellhades?.base100 || 0)),
  },
  ratingChoseN: {
    name: 'Rating: Aftershock United',
    canHandle: canHandleAny,
    sortFn: reverseSort(defaultSortBy((hero) => hero.type.ratings.detailed.chosen?.base100 || 0)),
  },
  ratingAyumilove: {
    name: 'Rating: Ayumilove',
    canHandle: canHandleAny,
    sortFn: reverseSort(defaultSortBy((hero) => hero.type.ratings.detailed.ayumilove?.base100 || 0)),
  },
  level: {
    name: 'Level',
    canHandle(opts) {
      return opts.viewType === ViewType.Account;
    },
    sortFn: reverseSort(defaultSortBy((hero) => hero.instance?.level || 0)),
  },
  rank: {
    name: 'Rank',
    canHandle(opts) {
      return opts.viewType === ViewType.Account;
    },
    sortFn: reverseSort(defaultSortBy((hero) => hero.instance?.rank || 0)),
  },
  tag: {
    name: 'Tag',
    canHandle(opts) {
      return opts.viewType === ViewType.Account;
    },
    sortFn: defaultSortBy((hero) => hero.instance?.marker || 0),
  },
  empowerLevel: {
    name: 'Empower Level',
    canHandle(opts) {
      return opts.viewType === ViewType.Account;
    },
    sortFn: reverseSort(defaultSortBy((hero) => hero.instance?.empowerLevel || 0)),
  },
  ascensionLevel: {
    name: 'Ascension Level',
    canHandle(opts) {
      return opts.viewType === ViewType.Account;
    },
    sortFn: reverseSort(defaultSortBy((hero) => hero.type?.ascended || 0)),
  },
  awakenRank: {
    name: 'Awaken Rank',
    canHandle(opts) {
      return opts.viewType === ViewType.Account;
    },
    sortFn: reverseSort(defaultSortBy((hero) => getAwakenRankNumber(hero.instance?.awakenRank) || 0)),
  },
};

export const artifactSorts: Record<string, SortDescriptor<ArtifactType, ViewOptions>> = {
  name: {
    name: 'Set Kind',
    canHandle: canHandleAny,
    sortFn: reverseSort(defaultSortBy((artifact) => artifact.setKindId || 0)),
    //This one shows no problems but when you add this option to the sort on the site it says...
    //Uncaught TypeError: Cannot read properties of undefined (reading 'ordinal')
    //Which now makes me confused about how I got the rarity one to work using getArtifactRarity even
    //though it does not seem to have the ordinal data either.
    //sortFn: reverseSort(sortByOrdinal((artifact) => artifact.setKindId, getArtifactSets())),
  },
  kindId: {
    name: 'Slot',
    canHandle(opts) {
      return opts.viewType === ViewType.Artifacts;
    },
    sortFn: defaultSortBy((artifact) => artifact.kindId || 0),
    //sortFn: reverseSort(sortByOrdinal((artifact) => artifact.kindId, getArtifactSlots())),
  },
  level: {
    name: 'Level',
    canHandle(opts) {
      return opts.viewType === ViewType.Artifacts;
    },
    sortFn: reverseSort(defaultSortBy((artifact) => artifact.level || 0)),
  },
  ascendLevel: {
    name: 'Ascend Level',
    canHandle(opts) {
      return opts.viewType === ViewType.Artifacts;
    },
    sortFn: reverseSort(defaultSortBy((artifact) => artifact.ascendLevel || 0)),
  },
  ascendBonusValue: {
    name: 'Ascend Bonus Value',
    canHandle(opts) {
      return opts.viewType === ViewType.Artifacts;
    },
    sortFn: reverseSort(defaultSortBy((artifact) => convertDecimalToSortableNumber(artifact.ascendBonus?.value) || 0)),
  },
  ascendBonusKind: {
    name: 'Ascend Bonus Kind',
    canHandle(opts) {
      return opts.viewType === ViewType.Artifacts;
    },
    sortFn: reverseSort(defaultSortBy((artifact) => convertDecimalToSortableNumber(artifact.ascendBonus?.kind) || 0)),
  },
  rank: {
    name: 'Rank',
    canHandle(opts) {
      return opts.viewType === ViewType.Artifacts;
    },
    sortFn: reverseSort(defaultSortBy((artifact) => artifact.rank || 0)),
  },
  rarity: {
    name: 'Rarity',
    canHandle(opts) {
      return opts.viewType === ViewType.Artifacts;
    },
    sortFn: reverseSort(sortByOrdinal((artifact) => artifact.rarity, getArtifactRarity())),
  },
  /* Confused about how to sort by a specific primary stat I can't use something like artifact.primaryBonus.kind.speed below because speed doesn't exist on kind but kind might === 'Speed'. It currently seems to sort all the artifacts in order alphabetical order of their stat kind and then from highest stat to lowest. Which is not very useful at all...  */
  /*   speed: {
    name: 'Primary Stat: Speed',
    canHandle(opts) {
      return opts.viewType === ViewType.Artifacts;
    },
    sortFn: defaultSortBy((artifact) => artifact.primaryBonus.kind || 0),
  }, */

  /* Confused about the same as above plus confused about how to have it check each of the substats to see if they are speed and then I will need to also have it add the glyphPower to the value. Not sure what exactly it is doing right now */
  /*   speedSecondary: {
    name: 'Secondary Stats: Speed',
    canHandle(opts) {
      return opts.viewType === ViewType.Artifacts;
    },
    sortFn: defaultSortBy((artifact) => artifact.secondaryBonuses[0]?.kind === 'Speed' || 0),
  }, */
};

export default sorts;
