import React from 'react';
import { PageLayout } from '../Layout';
import { AllChamps, MyChamps, ShareMyTeam, About, Contact, Contribute, MyArtifacts } from '../Pages';
import { ChampionPage } from '../Templates';
import { Route, Routes } from 'react-router';
//import { Profile } from 'Components/Layout/Profile';
import { Account } from '../Layout/Account';
import { Profile } from 'Components/Pages/Profile';
import { Callback } from 'Components/Pages/Callback';
import { StripeCancel } from 'Components/Pages/StripeCancel';
import SuccessPage from 'Components/Pages/StripeSuccess';
import { Hotkeys } from 'Components/Pages/Hotkeys';
import { Maps } from 'Components/Pages/Maps';
import { Sandbox } from 'Components/Pages/Sandbox';

export interface RouteInfo {
  caseSensitive?: boolean;
  index?: boolean;
  path?: string;
  element?: React.ReactNode;
  children?: RouteInfo[];
}

export const SiteRoutes: RouteInfo[] = [
  {
    element: <PageLayout />,
    children: [
      { index: true, element: <AllChamps /> },
      { path: '/profile', caseSensitive: false, element: <Profile /> },
      { path: '/callback', caseSensitive: false, element: <Callback /> },
      { path: '/success', caseSensitive: false, element: <SuccessPage /> },
      { path: '/cancel', caseSensitive: false, element: <StripeCancel /> },
      { path: '/mychamps', caseSensitive: false, element: <MyChamps /> },
      { path: '/myartifacts', caseSensitive: false, element: <MyArtifacts /> },
      { path: '/sharemyteam', caseSensitive: false, element: <ShareMyTeam /> },
      { path: '/hotkeys', caseSensitive: false, element: <Hotkeys /> },
      { path: '/about', caseSensitive: false, element: <About /> },
      { path: '/contribute', caseSensitive: false, element: <Contribute /> },
      { path: '/sandbox', caseSensitive: false, element: <Sandbox /> },
      { path: '/contact', caseSensitive: false, element: <Contact /> },
      { path: '/champions/:slug', caseSensitive: false, element: <ChampionPage /> },
      { path: '*', element: <h1>Not found</h1> },
    ],
  },
];

export function renderRoute({ children, ...props }: RouteInfo) {
  return (
    <Route key={`_${props.path}${props.index}`} {...props}>
      {children?.map(renderRoute)}
    </Route>
  );
}

export function renderRoutes() {
  return <Routes>{SiteRoutes.map(renderRoute)}</Routes>;
}
