import { action, computed, makeObservable, observable } from 'mobx';

export class ObservableProperty<T> {
  constructor(private rawValue?: T) {
    makeObservable<ObservableProperty<T>, 'rawValue'>(this, {
      rawValue: observable.ref,
      value: computed,
      setValue: action,
    });
  }

  get value(): T | undefined {
    return this.rawValue;
  }

  setValue(value: T | undefined) {
    this.rawValue = value;
  }
}
