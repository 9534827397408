import React from 'react';
import { mergeStyles } from '@fluentui/merge-styles';
import * as Styles from './Tile.styles';

export interface TileProps {
  /**
   * Handles when the user activates this tile
   */
  onActivate?: () => void;
  /**
   * Custom CSS Class name
   */
  className?: string;
  /**
   * Style properties
   */
  style?: React.CSSProperties;
}

export const Tile: React.FC<TileProps> = ({ children, className, onActivate, ...props }) => {
  return (
    <div {...props} onClick={onActivate} className={mergeStyles(Styles.root, className, 'basic-transition', 'shadow')}>
      {children}
    </div>
  );
};
