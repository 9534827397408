import React, { useEffect } from 'react';
import { NavLinks } from '../Library';
import './Footer.css';
import { NextGenImage } from '../../Components/Library';
import { useLocation } from 'react-router-dom';

const thisYear = new Date().getFullYear();

// https://regex101.com/r/ABFEGl/1
//const regexHomePageWithOrWithoutQueryParams = /(^https?:\/\/.*)(:\d+|\.com|\.app)(\/|)(\?.*|\/|)$/gim;

//https://regex101.com/r/h5wUp1/1
//const regexMyChampsPageWithOrWithoutQueryParams = /(^https?:\/\/.*)(:\d+|\.com|\.app)\/mychamps(\?.*|\/|)$/gim;

//const regexMyArtifactsPageWithOrWithoutQueryParams = /(^https?:\/\/.*)(:\d+|\.com|\.app)\/myartifacts(\?.*|\/|)$/gim;

//const regexAnyPageWithQueryParams = /(^https?:\/\/.*)(:\d+|\.com|\.app)(\/|)(\/.*|\/)(\?.*)$/gim;

const regexAnyPageWithQueryParamsForStatusEffect =
  /(^https?:\/\/.*)(:\d+|\.com|\.app)(\/|)(\/.*|\/)(\?.*(statusEffect=\d*))$/gim;

//const regexChampionPage = /http.*\/\/.*\/champions\/.*/gim;

function useRunOnPageLoadAndOnClick() {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/' || location.pathname === '/allchamps') {
      const myChampsOnlyToggle = document.querySelector('.allchamps-page .MyChampsOnlyToggle');
      if (myChampsOnlyToggle && !myChampsOnlyToggle.classList.contains('Mui-selected')) {
        document.body.classList.remove('MyChampsOnly');
        document.body.classList.add('AllChamps');
      } else {
        document.body.classList.remove('AllChamps');
        document.body.classList.add('MyChampsOnly');
      }
    }

    if (location.pathname === '/mychamps') {
      document.body.classList.add('mychamps-page');
      const showRatingsToggle = document.querySelector('.ShowRatingsToggle');
      if (showRatingsToggle && !showRatingsToggle.classList.contains('Mui-selected')) {
        document.body.classList.remove('ShowRatings');
        document.body.classList.add('HideRatings');
      } else {
        document.body.classList.remove('HideRatings');
        document.body.classList.add('ShowRatings');
      }
    } else {
      document.body.classList.remove('mychamps-page');
    }
  }, [location.pathname]);
}

function useHighlightElementOnClick() {
  useEffect(() => {
    const handleClick = function (event: any) {
      const CTAcontent = document.querySelector('.CTAcontent');
      const simpleTab2 = document.querySelector('#simple-tab-2');
      if (CTAcontent && simpleTab2) {
        CTAcontent.classList.toggle('on');
        simpleTab2.classList.toggle('imHere');
      }
    };

    const CTAcontent = document.querySelector('.CTAcontent');
    CTAcontent?.addEventListener('click', handleClick);

    return () => {
      CTAcontent?.removeEventListener('click', handleClick);
    };
  }, []);
}

function useDomManipulation() {
  const location = useLocation();

  // Replace your regex checks with simple string comparisons
  const isHomePage = location.pathname === '/';
  const isMyArtifactsPage = location.pathname === '/myartifacts';
  const isMyChampsPage = location.pathname === '/mychamps';
  const isChampPage = location.pathname.includes('/champions');
  const isShareMyTeamPage = location.pathname.includes('/sharemyteam');

  useEffect(() => {
    let bodyTag = document.getElementsByTagName('body')[0];

    if (isHomePage) {
      bodyTag?.classList.add('home', 'allchamps-page');
      bodyTag?.classList.remove('inner-page');
    } else {
      bodyTag?.classList.add('inner-page');
      bodyTag?.classList.remove('allchamps-page', 'home');
    }

    if (isMyArtifactsPage) {
      bodyTag?.classList.add('myartifacts-page');
    } else {
      bodyTag?.classList.remove('myartifacts-page');
    }

    if (isMyChampsPage) {
      bodyTag?.classList.add('mychamps-page');
    } else {
      bodyTag?.classList.remove('mychamps-page');
    }

    if (isMyChampsPage || isHomePage) {
      bodyTag?.classList.add('champList-page');
    } else {
      bodyTag?.classList.remove('champList-page');
    }

    if (isChampPage) {
      bodyTag?.classList.add('champ-page');
    } else {
      bodyTag?.classList.remove('champ-page');
    }

    if (isShareMyTeamPage) {
      bodyTag?.classList.add('shareMyTeam-page');
    } else {
      bodyTag?.classList.remove('shareMyTeam-page');
    }

    if (!regexAnyPageWithQueryParamsForStatusEffect.exec(window.location.href)) {
      window.onunload = function () {
        localStorage.removeItem('setting:saved-selection-statusEffect');
        localStorage.removeItem('AdvancedAbilityFilters-selectedStatusEffect');
      };
    }
  }, [location.pathname]);

  return null; // For components with no UI, return null instead of an empty fragment
}

export const Footer: React.FC = () => {
  useRunOnPageLoadAndOnClick();
  useHighlightElementOnClick();
  useDomManipulation();
  return (
    <footer className="site-footer dark">
      <section id="nav_menu-8" className="widget widget_nav_menu">
        <div className="menu-main-container">
          <ul id="menu-main" className="menu">
            <NavLinks>
              <a
                href="https://discord.gg/zhqmadUyys"
                className="discord"
                target="_blank"
                rel="noopener noreferrer"
                title="Click here to join our Discord Community"
              >
                <NextGenImage
                  whichDirectory="_NotExtracted/socialIcons"
                  whichImage="discord-chat-icon"
                  alt="Discord Icon"
                  title="Discord Icon"
                />
              </a>
            </NavLinks>
          </ul>
          <a href="https://www.buymeacoffee.com/cmarabate">
            <img
              className="BuyMeAPizza"
              src="https://img.buymeacoffee.com/button-api/?text=Buy me a pizza&emoji=🍕&slug=cmarabate&button_colour=233c99&font_colour=ffffff&font_family=Cookie&outline_colour=ffffff&coffee_colour=FFDD00"
              alt="Buy me a pizza"
            />
          </a>
        </div>
      </section>
      <div className="footer-bottom social-right-menu ">
        <div className="site-info">
          ©{thisYear} RaidChamps.com. All rights reserved
          <br />
          <br />
          Designed & Developed by{' '}
          <a href="https://chrismarabate.com" target="_blank" rel="noopener noreferrer">
            Chris Marabate
          </a>
        </div>
      </div>
    </footer>
  );
};
