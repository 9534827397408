import { observer } from 'mobx-react-lite';
import { useListView, useRootStore } from 'Model';
import React, { useState } from 'react';
import SortedEditor from './SortedEditor';
import { ViewType } from 'Components/DataView/ViewOptions';
import styles from './BoundSortedEditor.module.css';
import Tooltip from 'rc-tooltip';

function getKey(value: [key: string, value: React.ReactNode]) {
  return value[0];
}

function renderItem(value: [key: string, value: React.ReactNode]) {
  return value[1];
}

function fallbackItem(key: string): [key: string, value: React.ReactNode] {
  return [key, `Sort by: ${key}`];
}

export interface BoundSortedEditorProps {}

export const BoundSortedEditor: React.FC<BoundSortedEditorProps> = observer(() => {
  // can this always just be index, or does it need to use the
  const listView = useListView();
  const rootStore = useRootStore(); // Add this line

  const handleSaveSortOrder = () => {
    rootStore.saveCurrentSort(listView.options.viewType, listView.sort);
    showTemporaryMessage();
  };

  const resetSortToDefault = () => {
    rootStore.resetSortToDefault(listView.options.viewType);
    listView.setSort(listView.options.defaultSort);
  };

  const hasCustomSortOrder = () => {
    return localStorage.getItem(`currentSort_${listView.options.viewType}`) !== null;
  };

  const [showSavedMessage, setShowSavedMessage] = useState(false);

  const showTemporaryMessage = () => {
    setShowSavedMessage(true);
    setTimeout(() => {
      setShowSavedMessage(false);
    }, 3000); // Display the message for 3 seconds
  };

  return (
    <>
      <SortedEditor
        renderItem={renderItem}
        resolveValue={getKey}
        fallbackItem={fallbackItem}
        value={listView.sort}
        dataSource={listView.sortOptions}
        onChange={listView.setSort}
      />
      <Tooltip
        arrowContent={<div className="rc-tooltip-arrow-inner" />}
        placement="bottom"
        overlay={
          <span>
            Click here to save your current sort order for this page so that it will not revert back to the default sort
            order after refreshing the page.
          </span>
        }
      >
        <button className="saveSortOrderButton" onClick={handleSaveSortOrder}>
          Save
        </button>
      </Tooltip>
      {hasCustomSortOrder() && (
        <Tooltip
          arrowContent={<div className="rc-tooltip-arrow-inner" />}
          placement="bottom"
          overlay={
            <span>
              Clicking this will revert the sort order back to the default order for this page and refresh the page.
            </span>
          }
        >
          <button onClick={resetSortToDefault}>Reset to Default Order</button>
        </Tooltip>
      )}
      {showSavedMessage && <div className={`${styles.message}`}>Current Sort Order Saved!</div>}
    </>
  );
});
