import React from 'react';
import { ArtifactRarity, Rarity } from '../../Types';
import { getArtifactRarities, getRarities } from '../../Data';
import './RarityBox.css';

export interface RarityBoxProps {
  /**
   * Rarity
   */
  rarity: Rarity | ArtifactRarity | undefined;
  /**
   * Custom CSS Class name
   */
  className?: string;
  /**
   * Style properties
   */
  style?: React.CSSProperties;
  artifact?: boolean;
}

export const RarityBox: React.FC<RarityBoxProps> = ({ children, className, style, rarity, artifact, ...props }) => {
  const rarityData = getArtifactRarities()[rarity];
  /*   const memoStyle = React.useMemo(
    () => ({ backgroundColor: rarityData?.color, padding: 4, ...style }),
    [rarityData?.color, style]
  ); */
  return (
    <div {...props} /* style={memoStyle} */ className={`RarityBox ${rarity}`}>
      {children}
    </div>
  );
};
