import { useViewType } from '../Components';
import React, { createContext, useContext } from 'react';
import { RootStore } from './RootStore';
import { observer } from 'mobx-react-lite';

const rootStore = new RootStore();

export const RootStoreContext = createContext<RootStore | null>(null);

export const useRootStore = () => {
  const store = useContext(RootStoreContext);
  if (!store) {
    throw new Error('Root store context not provided');
  }
  return store;
};

export function useListView() {
  const root = useRootStore();
  return root.listViews[useViewType()];
}

export function getGlobalRootStore() {
  return rootStore;
}

export const ProvideRootStore: React.FC = observer(({ children }) => (
  <RootStoreContext.Provider value={rootStore}>{children}</RootStoreContext.Provider>
));

export * from './ListViewTypes';
