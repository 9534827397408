import { StatKind } from '../Enums';

export type Stats = Record<StatKind, number>;

export const emptyStats = (): Stats => ({
  health: 0,
  attack: 0,
  defense: 0,
  speed: 0,
  resistance: 0,
  accuracy: 0,
  criticalchance: 0,
  criticaldamage: 0,
  criticalheal: 0,
});
