export enum AuraAreas {
  none = 'none',
  unknown = 'allbattles',
  allianceBoss = 'allianceboss',
  allBattles = 'allbattles',
  arena = 'arena',
  campaign = 'campaign',
  doomTower = 'doomtower',
  dungeons = 'dungeons',
  fractions = 'fractions',
  hydra = 'hydra',
}
