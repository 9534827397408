import React from 'react';
import { WindowScroller, Grid, AutoSizer, GridCellRenderer } from 'react-virtualized';
import { ChampionTile } from './ChampionTile';
import { ChampionItem, ChampionType } from '../Types';
import { getAreaData } from 'Data';
import { Ability } from './Library';
import { useRootStore } from 'Model';
import { observer } from 'mobx-react-lite';

export interface ChampionAbilitiesProps {
  type: ChampionType;
  instance: any;
  currentFormIndex: number;
}

export const ChampionAbilities: React.FC<ChampionAbilitiesProps> = observer(({ type, instance, currentFormIndex }) => {
  const store = useRootStore();
  const championItem = store.modalChampion;
  const heroTypeId = `${type?.typeId && type?.typeId - (type?.typeId % 10)}`;

  const allSkillTypeIds = type.forms?.flatMap((form) => form.skillTypeIds) || [];
  console.log('ChampionAbilities allSkillTypeIds', allSkillTypeIds);

  console.log('ChampionAbilities type', type);

  console.log('ChampionAbilities currentFormIndex', currentFormIndex);
  console.log('ChampionAbilities type.forms.length', type?.forms?.length);

  const currentForms = type?.forms[currentFormIndex];
  console.log('ChampionAbilities currentForms', currentForms);

  const currentFormSkillTypeIds = type?.forms[currentFormIndex]?.skillTypeIds || [];
  console.log('ChampionAbilities currentFormSkillTypeIds', currentFormSkillTypeIds);

  const rarity = type.rarity;
  console.log('ChampionAbilities rarity', rarity);

  return (
    <>
      <section className="AbilityInfo-section sectionFlex-wrap">
        {/* Abilities */}
        <section>
          {type?.aura && (
            /* Aura */
            <div className="aura">
              <div className="aura-wrap">
                <h3>Aura</h3>
                <span className="aura">
                  Increase{' '}
                  <span className="capitalCase">{type.aura?.affinity ? type.aura?.affinity : 'All'} Ally </span>
                  <span className="capitalCase">{type.aura?.statKind && type.aura?.statKind}</span> in{' '}
                  {(type.aura.area && getAreaData(type.aura.area))?.name ?? 'All Battles'} by{' '}
                  <span className="auraAmtAsPercent">
                    {type.aura.absolute ? +type.aura.value : `${Math.round(type.aura.value * 100)}%`}
                  </span>
                </span>
              </div>
            </div>
          )}
          <div className="abilities">
            <h3>Abilities</h3>
            {currentFormSkillTypeIds.map((skillId: any) => (
              <Ability
                key={`skill${skillId}`}
                skillId={skillId}
                heroTypeId={heroTypeId}
                skillIndex={Number(String(skillId).slice(-1))}
                instance={instance}
                currentFormIndex={currentFormIndex}
                rarity={rarity}
              />
            ))}
          </div>
        </section>
      </section>
    </>
  );
});
