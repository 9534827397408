import React from 'react';
import { Button, Chip, MenuItem, MenuList, StyledComponentProps } from '@mui/material';
import { withStyles } from '@mui/styles';
import Dropdown from './Dropdown';
import { Input, IconButton } from './utils';
import { ItemProps } from './items';
import { filterItems } from './Helpers';
import Tooltip from '@mui/material/Tooltip';

const styles = {
  root: {
    listStyle: 'none' as const,
    display: 'flex' as const,
    padding: 0,
    alignItems: 'center' as const,
    flexWrap: 'wrap' as const,
    width: '100%' as const,
    border: '1px solid black' as const,
    borderRadius: 2,
  },
  chip: {
    marginRight: 4,
    height: 25,
    borderRadius: 2,
  },
  menu: {
    overflowY: 'auto' as const,
    maxHeight: 300,
  },
};

interface MultiSelectState {
  inputValue: string;
  inputOpen: boolean;
  selectedItems: string[];
}

export type MultiSelectProps = StyledComponentProps &
  React.HTMLProps<HTMLUListElement> & {
    items: string[];
    inputProps: React.ComponentProps<typeof Input>;
  };

class MultiSelect extends React.PureComponent<MultiSelectProps, MultiSelectState> {
  private rootEl?: HTMLUListElement;
  private inputEl?: HTMLInputElement;

  override state: MultiSelectState = {
    inputValue: '',
    inputOpen: false,
    selectedItems: [],
  };

  override componentDidMount() {
    document.addEventListener('keydown', this.keyDown);
  }

  override componentWillUnmount() {
    document.removeEventListener('keydown', this.keyDown);
  }

  keyDown = (e: KeyboardEvent) => {
    if (!this.rootEl || !this.rootEl.contains(document.activeElement)) return;

    if (!this.state.inputValue && this.state.selectedItems.length && e.key === 'Backspace') {
      // pop latest select item
      const { selectedItems } = this.state;
      this.setState({
        selectedItems: selectedItems.slice(0, -1),
      });
    }
    const li: HTMLLIElement = (
      document.activeElement?.tagName === 'li' ? document.activeElement : document.activeElement?.closest('li')
    ) as HTMLLIElement;
    if (!li) return;
    if (e.key === 'Delete' && li.dataset.key) {
      this.setState({ selectedItems: this.state.selectedItems.filter((item) => item !== li.dataset.key) });
    }
    if (e.key === 'ArrowLeft' && li.previousElementSibling) {
      (li.previousElementSibling as HTMLElement).focus();
    }
    if (e.key === 'ArrowRight' && li.nextElementSibling) {
      (li.nextElementSibling as HTMLElement).focus();
    }
  };

  override render() {
    const {
      classes,
      items,
      inputProps: { placeholder, ...inputProps },
      ...rest
    } = this.props;
    const { inputValue = '', inputOpen, selectedItems } = this.state;
    const filteredItems = filterItems(items, inputValue, selectedItems);

    return (
      <ul
        className={classes?.root}
        {...rest}
        ref={(el) => {
          this.rootEl = el || undefined;
        }}
      >
        {[...selectedItems].map((text) => (
          <Chip
            component="li"
            role="button"
            tabIndex={0}
            data-key={text}
            label={text}
            key={text}
            onDelete={() => {
              this.setState({
                inputValue: '',
                selectedItems: this.state.selectedItems.filter((item) => item !== text),
              });
            }}
            style={{ marginRight: 4, height: 25, borderRadius: 2 }}
          />
        ))}

        <Dropdown
          component="li"
          style={{ flex: 1 }}
          onClose={() => this.setState({ inputOpen: false })}
          open={inputOpen}
          input={
            <Input
              value={inputValue}
              fullWidth
              placeholder={selectedItems.length ? undefined : placeholder}
              {...inputProps}
              inputRef={(el) => {
                this.inputEl = el;
              }}
              disableUnderline
              onFocus={() => this.setState({ inputOpen: true })}
              onChange={(e) => this.setState({ inputValue: e.target.value, inputOpen: true })}
            />
          }
        >
          <MenuList component="nav" role="menu" className={classes?.menu}>
            {filteredItems.map((name) => (
              <MenuItem
                key={name}
                component={Button}
                disableGutters
                onClick={() =>
                  this.setState(
                    {
                      inputValue: '',
                      selectedItems: selectedItems.includes(name) ? selectedItems : [...selectedItems, name],
                    },
                    () => this.inputEl && this.inputEl.focus()
                  )
                }
              >
                {name}
              </MenuItem>
            ))}
          </MenuList>
        </Dropdown>
        {selectedItems.length || inputValue ? (
          <li>
            <Tooltip title="Delete">
              <IconButton onClick={() => this.setState({ inputValue: '', selectedItems: [] })}>clear</IconButton>
            </Tooltip>
          </li>
        ) : null}
      </ul>
    );
  }
}

export default withStyles(styles)(MultiSelect);
