import React, { useState } from 'react';
import { Ability, ChampionTile, ModalContent, NextGenImage, StarRating } from 'Components';
import { getAreaData, getHeroTypesEx, getHeroRating, getSkills } from 'Data';
import { useParams } from 'react-router';
import './ChampionPage.css';
import { Box, Tabs, Tab } from '@mui/material';
import { usePageSetting } from 'Components/Hooks';
import ChampMasteriesCondensed from 'Components/Library/ChampMasteriesCondensed';
import { JumpToNextChamp } from '../Library/JumpToNextChamp';
import {
  capitalizeFirstLetter,
  getAllStarRatings,
  getFactionName,
  getRarityColorForRating,
} from 'Components/DataView/Helpers';
import { ChampionTypeEx } from 'Types';
import { Helmet } from 'react-helmet-async';
import ModalImage from 'react-modal-image';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: '16px' }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const ChampionPage: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();

  let allSkills = getSkills();
  //   console.log(`allSkills`, allSkills);

  const [type] = getHeroTypesEx()
    .filter((type) => type.slug === slug)
    .slice(-1, 1) as ChampionTypeEx[];

  let thisChampsSkillIds: any[] = [];

  if (type?.forms) {
    type.forms.forEach((form) => {
      if (form.skillTypeIds) {
        thisChampsSkillIds = [...thisChampsSkillIds, ...form.skillTypeIds];
      }
    });
  }
  //   console.log(`thisChampsAbilitiesData`, thisChampsAbilitiesData);

  const name = type?.name;

  const allStarRatings = getAllStarRatings(slug);

  const ChosenVideoGuideId = getHeroRating(slug!)?.detailed.chosen?.videoGuideId || undefined;

  const ChosenInfographicId = getHeroRating(slug!)?.detailed.chosen?.infographicId || undefined;

  const HellHadesVideoGuideId = getHeroRating(slug!)?.detailed.hellhades?.videoUrls?.[0] || undefined;

  // HellHades_GearAndStat_Priorities
  const HellHadesPveSets = getHeroRating(slug!)?.detailed.hellhades?.pve_sets || undefined;
  const HellHadesPveStats = getHeroRating(slug!)?.detailed.hellhades?.pve_stats || undefined;
  const HellHadesPvpSets = getHeroRating(slug!)?.detailed.hellhades?.pvp_sets || undefined;
  const HellHadesPvpStats = getHeroRating(slug!)?.detailed.hellhades?.pvp_stats || undefined;

  const HellHadesPvpMasteries = getHeroRating(slug!)?.detailed.hellhades?.pvp_masteries || undefined;
  const HellHadesPveMasteries = getHeroRating(slug!)?.detailed.hellhades?.pve_masteries || undefined;

  // Faction Alliances
  let factionAlliance;
  if (
    type?.faction === 'bannerlords' ||
    type?.faction === 'barbarians' ||
    type?.faction === 'highelves' ||
    type?.faction === 'sacredorder'
  ) {
    //factionAlliance = ['Telerians (BL/BA/HE/SO)'];
    factionAlliance = ['Telerians'];
  }

  if (
    type?.faction === 'ogryntribes' ||
    type?.faction === 'lizardmen' ||
    type?.faction === 'skinwalkers' ||
    type?.faction === 'orcs'
  ) {
    //factionAlliance = ['Gaellen Pact (LZ/OT/OR/SW)'];
    factionAlliance = ['Gaellen Pact'];
  }

  if (
    type?.faction === 'demonspawn' ||
    type?.faction === 'undeadhordes' ||
    type?.faction === 'darkelves' ||
    type?.faction === 'knightsrevenant'
  ) {
    //factionAlliance = ['The Corrupted (DE/DS/KR/UH)'];
    factionAlliance = ['The Corrupted'];
  }

  if (type?.faction === 'dwarves' || type?.faction === 'samurai') {
    //factionAlliance = ['Nyresan Union (DW/SK)'];
    factionAlliance = ['Nyresan Union'];
  }

  const [tabIndex, setTabIndex] = usePageSetting('filter-tab');
  const onTabChange = React.useCallback((_event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  }, []);

  const heroTypeId = `${type?.typeId && type?.typeId - (type?.typeId % 10)}`;

  const [isCopied, setIsCopied] = useState(false);

  function handleClick(type: ChampionTypeEx) {
    if (window.location.hostname === 'localhost') {
      // existing onClick function for localhost
      console.log(`http://localhost:8080/champions/${type?.slug}`);
    } else {
      // existing onClick function for production
      console.log(`https://raidchamps.com/champions/${type?.slug}`);
    }

    // function to copy URL to clipboard and show notification
    const url =
      window.location.hostname === 'localhost'
        ? `http://localhost:8080/champions/${type?.slug}`
        : `https://raidchamps.com/champions/${type?.slug}`;

    navigator.clipboard.writeText(url).then(() => {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000); // hide notification after 2 seconds
    });
  }

  return slug && type ? (
    <div className="champion-wrapper">
      <Helmet>
        <title>{`${capitalizeFirstLetter(type.name)} | ${capitalizeFirstLetter(type.rarity)} ${capitalizeFirstLetter(
          type.affinity
        )} ${capitalizeFirstLetter(type.role)} Champion from the ${getFactionName(
          type.faction
        )} faction. | Raid: Shadow Legends Champion`}</title>
        <meta
          name="description"
          content={`${type.name} is a ${capitalizeFirstLetter(type.rarity)} of ${capitalizeFirstLetter(
            type.affinity
          )} ${capitalizeFirstLetter(type.role)} Affinity Champion from the ${getFactionName(
            type.faction
          )} Faction from Raid: Shadow Legends`}
        />
        <meta
          itemProp="name"
          content={`${capitalizeFirstLetter(type.name)} | ${capitalizeFirstLetter(type.rarity)} ${capitalizeFirstLetter(
            type.affinity
          )} ${capitalizeFirstLetter(type.role)} Champion from the ${getFactionName(
            type.faction
          )} faction. | Raid: Shadow Legends Champion`}
        />
        <meta
          itemProp="description"
          content={`${type.name} is a ${capitalizeFirstLetter(type.rarity)} of ${capitalizeFirstLetter(
            type.affinity
          )} ${capitalizeFirstLetter(type.role)} Affinity Champion from the ${getFactionName(
            type.faction
          )} Faction from Raid: Shadow Legends`}
        />
        <meta
          itemProp="image"
          content={`https://ik.imagekit.io/raidchamps/heroAvatars/${`${type.typeId - (type.typeId % 10)}`}`}
        />
        <meta property="og:url" content="https://raidchamps.com/mychamps" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`${capitalizeFirstLetter(type.name)} | ${capitalizeFirstLetter(type.rarity)} ${capitalizeFirstLetter(
            type.affinity
          )} ${capitalizeFirstLetter(type.role)} Champion from the ${getFactionName(
            type.faction
          )} faction. | Raid: Shadow Legends Champion`}
        />
        <meta
          property="og:description"
          content={`${type.name} is a ${capitalizeFirstLetter(type.rarity)} of ${capitalizeFirstLetter(
            type.affinity
          )} ${capitalizeFirstLetter(type.role)} Affinity Champion from the ${getFactionName(
            type.faction
          )} Faction from Raid: Shadow Legends`}
        />
        <meta
          property="og:image"
          content={`https://ik.imagekit.io/raidchamps/heroAvatars/${`${type.typeId - (type.typeId % 10)}`}`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content={`${capitalizeFirstLetter(type.name)} | ${capitalizeFirstLetter(type.rarity)} ${capitalizeFirstLetter(
            type.affinity
          )} ${capitalizeFirstLetter(type.role)} Champion from the ${getFactionName(
            type.faction
          )} faction. | Raid: Shadow Legends Champion`}
        />
        <meta
          name="twitter:description"
          content={`${type.name} is a ${capitalizeFirstLetter(type.rarity)} of ${capitalizeFirstLetter(
            type.affinity
          )} ${capitalizeFirstLetter(type.role)} Affinity Champion from the ${getFactionName(
            type.faction
          )} Faction from Raid: Shadow Legends`}
        />
        <meta
          name="twitter:image"
          content={`https://ik.imagekit.io/raidchamps/heroAvatars/${`${type.typeId - (type.typeId % 10)}`}`}
        />
      </Helmet>
      <JumpToNextChamp />

      <a className="copyLinkToThisPageButton btn" onClick={() => handleClick(type)}>
        Copy link to this page
      </a>

      {isCopied && (
        <div className="ModalTopButtons notification">
          <p>URL has been copied to your clipboard!</p>
        </div>
      )}

      <ModalContent champType={type} />
    </div>
  ) : null;
};
