// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js!../../node_modules/rc-tooltip/assets/bootstrap.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rc-tooltip {\n  max-width: 55vw;\n  max-height: 80vw;\n  color: #fff;\n  z-index: 1300;\n}\n\n.rc-tooltip:not(.rc-tooltip-hidden) {\n  display: block;\n}\n\n.rc-tooltip-inner {\n  font-size: 0.9rem;\n  background-color: black;\n  border: 2px solid #e8c817;\n}\n\n@media (max-width: 1023px) {\n  .rc-tooltip {\n    max-width: 80vw;\n  }\n}\n\n@media (max-width: 767px) {\n  h3.CTAcontent {\n    font-size: 1.1rem;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/Styles/rc-tooltip.css"],"names":[],"mappings":"AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,WAAW;EACX,aAAa;AACf;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,iBAAiB;EACjB,uBAAuB;EACvB,yBAAyB;AAC3B;;AAEA;EACE;IACE,eAAe;EACjB;AACF;;AAEA;EACE;IACE,iBAAiB;EACnB;AACF","sourcesContent":["@import url(~rc-tooltip/assets/bootstrap.css);\n\n.rc-tooltip {\n  max-width: 55vw;\n  max-height: 80vw;\n  color: #fff;\n  z-index: 1300;\n}\n\n.rc-tooltip:not(.rc-tooltip-hidden) {\n  display: block;\n}\n\n.rc-tooltip-inner {\n  font-size: 0.9rem;\n  background-color: black;\n  border: 2px solid #e8c817;\n}\n\n@media (max-width: 1023px) {\n  .rc-tooltip {\n    max-width: 80vw;\n  }\n}\n\n@media (max-width: 767px) {\n  h3.CTAcontent {\n    font-size: 1.1rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
