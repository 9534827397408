import React from 'react';
import { ClickAwayListener, Paper, Grow, StyledComponentProps } from '@mui/material';
import { StyleRules, withStyles } from '@mui/styles';

const styles: StyleRules<any, any> = {
  root: {
    position: 'relative',
  },
  open: {
    '& $paper': {
      zIndex: 10,
    },
  },
  paper: {
    position: 'absolute',
    top: '100%',
    left: 0,
    '& [role=menu]': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
};

export interface DropdownProps extends StyledComponentProps {
  open: boolean;
  onClose?: (e: MouseEvent | TouchEvent | KeyboardEvent) => void;
  input: React.ReactElement;
  button?: React.ReactElement;
  className?: string;
  component: React.ElementType;
}

interface DropdownState {
  open?: boolean;
}

class Dropdown extends React.PureComponent<DropdownProps, DropdownState> {
  /*   static propTypes = {
    // will use TS instead
    open: PropTypes.bool, // for controlled dropdowns
    onClose: PropTypes.func, // for controlled dropdowns
    // pass either input or button, button has precedence:
    input: PropTypes.node, // for autocomplete dropdowns
    button: PropTypes.node, // for dropdown triggered with a button
  }; */
  override state: DropdownState = {
    open: false,
  };
  private inputEl?: HTMLInputElement;

  override componentDidMount() {
    document.addEventListener('keydown', this.close);
  }
  override componentWillUnmount() {
    document.removeEventListener('keydown', this.close);
  }

  isControlled = () => this.props.open !== undefined;
  isOpen = () => (this.props.open !== undefined ? this.props.open : this.state.open);

  close = (e: MouseEvent | TouchEvent | KeyboardEvent) => {
    if (
      (e.type === 'keydown' && (e as KeyboardEvent).key !== 'Escape') ||
      (e.type !== 'keydown' && this.inputEl && this.inputEl.contains(e.target as Node))
    ) {
      return; // ignore button interactions
    }
    if (typeof this.props.onClose === 'function') {
      return this.props.onClose(e);
    }
    this.setState({ open: false });
  };

  // only for non-controlled (=non-props-open) dropdowns
  toggle = () => this.setState({ open: !this.state.open });
  open = () => this.setState({ open: true });

  override render() {
    const {
      className,
      classes,
      component: Component = 'div',
      input,
      button: buttonProp,
      children,
      open = this.state.open,
      ...props
    } = this.props;
    const button = input || buttonProp;
    // Input should have an explicit type to differentiate them, else assume it's a Button
    const refProp = this.props.button ? 'buttonRef' : 'inputRef';
    const Btn = React.cloneElement(button, {
      'aria-haspopup': 'true',
      [refProp]: (el: HTMLInputElement) => {
        this.inputEl = el;
        if (typeof button.props[refProp] === 'function') {
          button.props[refProp](el);
        }
      },
      ...(!this.isControlled() && { onClick: this.open, onFocus: this.open }),
    });
    return (
      <Component {...props} className={`${className} ${open ? classes?.open : ''}`}>
        <div className={`${classes?.root}`}>
          {Btn}
          <ClickAwayListener onClickAway={this.close}>
            <Grow in={open} style={{ transformOrigin: '0 0 0' }}>
              <Paper className={classes?.paper}>{children}</Paper>
            </Grow>
          </ClickAwayListener>
        </div>
      </Component>
    );
  }
}

export default withStyles(styles)(Dropdown);
