import React from 'react';
import { Key } from 'Components/Layout/Key';
import { useListView, useRootStore } from 'Model';
import { observer } from 'mobx-react-lite';
import { ViewType } from 'Components/DataView/ViewOptions';
import { withViewType } from 'Components/Hooks';
import './ShareMyTeam.css';
import SelectThenDisplayTeam from 'Components/Library/SelectThenDisplayTeam';
import { NoAccountData } from 'Components/Library';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

export const ShareMyTeam: React.FC = withViewType(
  ViewType.Account,
  observer(() => {
    const root = useRootStore();
    const listView = useListView();
    const options = root?.accounts?.selectedAccount?.champions || [];

    let sortedOptions: readonly any[] = [];
    if (options !== undefined) {
      sortedOptions = listView.filterAndSort(options);
    }

    return (
      <>
        <Helmet>
          <title>Share My Team | RaidChamps.com</title>
          <meta
            name="description"
            content="Quickly select all the champs on your team and and have all their details displayed with a button to download a screenshot of them all even if they are not all currently visible on your screen."
          />
          <meta itemProp="name" content="Share My Team | RaidChamps.com" />
          <meta
            itemProp="description"
            content="Quickly select all the champs on your team and and have all their details displayed with a button to download a screenshot of them all even if they are not all currently visible on your screen."
          />
          <meta itemProp="image" content="https://ik.imagekit.io/raidchamps/_NotExtracted/logo.avif" />
          <meta property="og:url" content="https://raidchamps.com/mychamps" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Share My Team | RaidChamps.com" />
          <meta
            property="og:description"
            content="Quickly select all the champs on your team and and have all their details displayed with a button to download a screenshot of them all even if they are not all currently visible on your screen."
          />
          <meta property="og:image" content="https://ik.imagekit.io/raidchamps/_NotExtracted/logo.avif" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Share My Team | RaidChamps.com" />
          <meta
            name="twitter:description"
            content="Quickly select all the champs on your team and and have all their details displayed with a button to download a screenshot of them all even if they are not all currently visible on your screen."
          />
          <meta name="twitter:image" content="https://ik.imagekit.io/raidchamps/_NotExtracted/logo.avif" />
        </Helmet>
        <div className="ShareMyTeam-page page">
          <div className="container center">
            <h1 className="with-underline text-center">Share My Team</h1>

            <p>
              <span className="green">Info: </span>Don't worry if everything does not fit on your screen, it will still
              generate an image that will include all the champs from your selection(s) up to as many as 60 champions.
            </p>
            <p>
              <span className="green">Info: </span>Please keep in mind that the more champs that you add, the longer it
              will take for it to generate the image and then trigger the download after clicking the{' '}
              <b>Download Image</b> button.
            </p>
            <p>
              <span className="yellow">Tip: </span>You can use the filters on the{' '}
              <Link to="/mychamps">My Champs page </Link>
              and then return to this page and it will filter and sort the list of champs you select from after clicking
              the button below and if you save the sort order the order they are listed in will persist after refreshing
              the page or visiting it another time.
            </p>
          </div>
          <div className="center">
            {root.accounts.selectedAccount ? (
              <>
                <SelectThenDisplayTeam options={sortedOptions} />
              </>
            ) : (
              <NoAccountData />
            )}
          </div>
        </div>
        <Key />
      </>
    );
  })
);
