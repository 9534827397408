import React, { useState, useEffect, useContext } from 'react';
import { ChampionItem, ChampionType, Rarity, RoleEnum } from '../Types';
import './ChampionTile.css';
import './ChampionModal.css';
import {
  AffinityBanner,
  Aura,
  BookRating,
  DamageBasedOn,
  FactionBanner,
  StarRating,
  RoleIcon,
  Tile,
  RarityBox,
  HellHadesRating,
  ChosenRating,
  AyumiloveRating,
  NextGenImage,
  Blessing,
  FactionGuardianIcon,
} from './Library';
import { getHeroRating, getRarityOrdinalData } from '../Data';
import { getAwakenRankNumber, getRankNumber } from './DataView/Helpers';
import { useRootStore } from 'Model';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';

export interface ChampionTileProps {
  item?: ChampionItem;
  champType?: ChampionType;
  local?: boolean;
  inModal?: boolean;
  currentFormIndex?: number;
  showBothRoles?: boolean;
}
export const ChampionTile: React.FC<ChampionTileProps> = observer(
  ({ item, champType, local, inModal, currentFormIndex, showBothRoles }) => {
    const store = useRootStore();

    let championType;
    if (champType) {
      championType = champType;
    } else {
      championType = item?.type;
    }

    let tagName;
    let tagTitleAndAlt;
    let tagImage: {} | null | undefined;
    if (item?.instance) {
      tagName = item?.instance?.marker;
      tagTitleAndAlt = `${tagName} Tag`;
      if (tagName !== 'None') {
        tagImage = (
          <NextGenImage
            className={tagTitleAndAlt}
            local={local}
            title={tagTitleAndAlt}
            alt={tagTitleAndAlt}
            whichDirectory={`_NotExtracted/myCreations/tagMarkers`}
            whichImage={local !== true ? `${tagName !== 'ArenaSecond' ? 'tr:w-25/' : ''}${tagName}` : tagName}
          />
        );
      }
    }

    let rarity: Rarity = Rarity.common;
    if (championType) {
      rarity = championType.rarity;
      if (String(championType?.rarity) === '6' || String(championType?.rarity) === 'mythic') {
        rarity = Rarity.mythical;
      }
    }

    const image = (
      <RarityBox style={{ position: 'relative' }} rarity={rarity}>
        <NextGenImage
          local={local}
          className="avatar"
          whichDirectory="heroAvatars"
          whichImage={
            championType?.typeId ? `${championType?.typeId - (championType?.typeId % 10)}` : `heroPlaceholder`
          }
        />
      </RarityBox>
    );

    //Rating (Overall)
    let ratingValue;
    if (championType?.slug) {
      ratingValue = getHeroRating(championType?.slug)?.overall || 0;
    }
    let stars: 1 | 2 | 3 | 4 | 5;
    let ratingAlt;
    let color;
    if (ratingValue && ratingValue >= 81) {
      stars = 5;
    } else if (ratingValue && ratingValue >= 61) {
      stars = 4;
    } else if (ratingValue && ratingValue >= 41) {
      stars = 3;
    } else if (ratingValue && ratingValue >= 21) {
      stars = 2;
    } else {
      stars = 1;
    }

    ratingAlt = `RaidChamps rating calculated from the average of all other scores after converting them each to a 0-100 point rating scale: ${
      ratingValue && ratingValue.toFixed(1)
    }`;

    color = getRarityOrdinalData(stars)?.color;

    const handleOpen = (event: React.MouseEvent) => {
      // Check if the clicked element or any of its parents have a class 'other-site-link'
      let element: HTMLElement | null = event.target as HTMLElement;
      while (element) {
        if (element.classList.contains('other-site-link')) {
          // Click was inside an other site link, do not open the modal
          return;
        }
        element = element.parentElement;
      }

      // If the click was not inside an other site link, proceed to open the modal
      store.modalChampion = item;
    };

    let awakenedNumber: number = 0;
    if (item?.instance?.awakenRank && getAwakenRankNumber(item?.instance?.awakenRank) > 0) {
      awakenedNumber = getAwakenRankNumber(item?.instance?.awakenRank);
      //console.log(`awakenedNumber`, awakenedNumber)
    }

    let allRoles: RoleEnum[];
    // Calculate allRoles and roleForCurrentForm based on currentFormIndex
    if (championType?.forms) {
      allRoles = championType?.forms.map((form) => form.role.toLowerCase() as RoleEnum) || [];
    }
    let roleForCurrentForm: RoleEnum | undefined;
    if (
      currentFormIndex !== undefined &&
      championType?.forms !== undefined &&
      championType?.forms[currentFormIndex] !== undefined
    ) {
      const role = championType?.forms[currentFormIndex]?.role.toLowerCase();
      roleForCurrentForm = role as RoleEnum;
    }

    const ChampionTileAvatarPart = (
      <div>
        <div className="ChampTileImage-wrap">{image}</div>
        <FactionBanner className={`overAvatarItem factionBanner`} faction={championType?.faction} local={local} />
        <AffinityBanner
          className={`overAvatarItem affintyBadge info`}
          affinity={championType?.affinity}
          local={local}
        />
        <RoleIcon
          className={`overAvatarItem roleBadge info`}
          allRoles={allRoles}
          local={local}
          rarity={rarity}
          showBothRoles={showBothRoles}
          currentFormIndex={currentFormIndex}
        />
        {item?.instance && item?.instance?.blessing && (
          <Blessing className={`blessingIcon info`} blessing={item?.instance?.blessing} local={local} />
        )}
        {championType?.aura && <Aura className={`overAvatarItem auraBadge info`} aura={championType?.aura} />}
        {championType?.indexed.damageBasedOn && (
          <DamageBasedOn damageBasedOn={championType?.indexed.damageBasedOn} local={local} />
        )}
        {item?.instance && (item?.instance?.inVault || item?.instance?.inDeepVault) && (
          <div className="inVaultImage overAvatarItem info">
            <NextGenImage
              local={local}
              whichDirectory="_NotExtracted/myCreations"
              whichImage="inStorageIcon"
              title={item?.instance?.inDeepVault ? 'In Reserve Vault' : 'In Master Vault'}
              alt={item?.instance?.inDeepVault ? 'In Reserve Vault' : 'In Master Vault'}
              className={item?.instance?.inDeepVault ? 'reserveVault' : 'masterVault'}
            />
          </div>
        )}
        {item?.instance && (
          <>
            <FactionGuardianIcon className={`overAvatarItem factionGuardian info`} id={item?.instance.id} />
            <div className={`tagImage overAvatarItem info ${tagName}`}>{tagImage}</div>
            <div
              className="rankStars overAvatarItem info"
              title={`${getRankNumber(item?.instance?.rank)} star champ ascended ${
                championType && championType.ascended
              } times and awakened ${getAwakenRankNumber(String(item?.instance.awakenRank))} times`}
            >
              <StarRating
                totalStars={6}
                filledStars={getRankNumber(item?.instance?.rank)}
                ascendedStars={championType && championType.ascended}
                awakenedStars={awakenedNumber}
                color="#EE8100"
                size="large"
              />
            </div>
          </>
        )}
        <div className="bottomRightWell">
          <div className="RatingItems-wrap">
            <ChosenRating slug={championType?.slug} />
            <HellHadesRating slug={championType?.slug} />
            <AyumiloveRating slug={championType?.slug} />
          </div>
          {item?.instance && (
            <>
              <span className="empowerment">
                {item?.instance?.empowerLevel > 0 && `+${item?.instance?.empowerLevel}`}
              </span>
              <span className="level">{item?.instance?.level}</span>
            </>
          )}
        </div>
        <BookRating slug={championType?.slug} />
      </div>
    );

    return (
      <div style={{ position: 'relative' }}>
        <Tile className={`champion-tile`}>
          {inModal === true ? (
            <Link
              className="ChampAvatar-wrap"
              style={{ position: 'relative', width: '100%' }}
              to={`/champions/${item?.type?.slug}`}
              target="_blank"
              title={`Open ${item?.type?.name}'s Page in New Tab`}
            >
              {ChampionTileAvatarPart}
            </Link>
          ) : (
            <Tile
              className="ChampAvatar-wrap"
              style={{ position: 'relative', width: '100%' }}
              onActivate={handleOpen}
              title={`Click to learn more about ${item?.type?.name}`}
            >
              {ChampionTileAvatarPart}
            </Tile>
          )}
          <div className="NameAndStarRating-wrap" style={{ width: '100%', padding: ' 0px 10px' }}>
            <div>
              <div className={`rating`} title={ratingAlt}>
                <StarRating className="RatingItem" totalStars={5} filledStars={stars} color={color} size="normal" />
              </div>
            </div>
            <div className="title name-wrap">{championType?.name}</div>
          </div>
        </Tile>
      </div>
    );
  }
);
