import React from 'react';
import {
  Icon,
  Input as MuiInput,
  IconButton as MuiIconButton,
  MenuList as MuiMenuList,
  StyledComponentProps,
  InputProps,
  MenuListProps,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { useStaticData } from 'Data';
import { FactionId, Rarity } from 'Types';

const inputStyles = {
  input: {
    '&::placeholder': {
      padding: '0 .5rem',
    },
  },
};

export const Input = withStyles(inputStyles)((props: InputProps & StyledComponentProps) => (
  <MuiInput placeholder="Advanced Ability Filters" classes={props.classes} fullWidth disableUnderline {...props} />
));

const menuListStyles = {
  menu: {
    overflowY: 'auto' as const,
    maxHeight: 300,
  },
};
export const MenuList = withStyles(menuListStyles)(({ classes, ...props }: MenuListProps & StyledComponentProps) => (
  <MuiMenuList component="nav" role="menu" className={classes?.menu} {...props} />
));

const iconButtonStyles = {
  root: {
    padding: 4,
    width: 'auto',
    height: 'auto',
  },
};
export const IconButton = withStyles(iconButtonStyles)(
  ({
    classes,
    children,
    ...props
  }: React.ComponentProps<typeof MuiIconButton> & React.PropsWithChildren<StyledComponentProps>) => (
    <MuiIconButton
      classes={classes}
      children={typeof children === 'string' ? <Icon>{children}</Icon> : children}
      {...props}
    />
  )
);
