import React from 'react';
import HotkeyComponent from './HotkeyComponent';

const ResetFiltersHotkey = () => {
  return (
    <div className="center resetFilters-wrap">
      <p className="small" style={{ width: 'fit-content', margin: '5px auto 0' }}>
        Set Hotkey to Reset Filters:
      </p>
      <HotkeyComponent storageKey="resetFiltersHotkey" />
    </div>
  );
};

export default ResetFiltersHotkey;
