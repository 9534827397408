import { useRootStore } from 'Model';
import { HeroStatsBuilder, StatSource } from 'Model/HeroStatsBuilder';
import React from 'react';
import { ChampionItem, ChampionType, StatKind, Stats, RoleEnum } from 'Types';
import { formatStat, scaleBaseStats, orderedStatNameToStatKind } from './StatsHelpers';
import { observer } from 'mobx-react-lite';

export interface ChampStatsProps {
  item?: ChampionItem;
  champType?: ChampionType;
  headers?: boolean;
  currentFormIndex: number;
}

export const ChampStats: React.FC<ChampStatsProps> = observer(({ item, champType, headers, currentFormIndex }) => {
  let championType;
  if (champType) {
    championType = champType;
  } else {
    championType = item?.type;
  }

  const account = useRootStore().accounts.selectedAccount;
  const stats = item?.instance && account?.championStatsMap[item?.instance.id];

  let currentFormStats;
  // Get the unscaled stats for the current form
  if (champType?.forms && champType?.forms[currentFormIndex] !== undefined) {
    currentFormStats = champType?.forms[currentFormIndex]?.unscaledStats;
  }

  // Convert the keys of currentFormStats to lowercase
  const lowerCaseCurrentFormStats = Object.fromEntries(
    Object.entries(currentFormStats || {}).map(([key, value]) => [key.toLowerCase(), value])
  );

  // Scale the current form stats
  const scaledCurrentFormStats = lowerCaseCurrentFormStats ? scaleBaseStats(lowerCaseCurrentFormStats) : undefined;

  // Debugging: Log the scaledCurrentFormStats to see what it contains
  console.log('scaledCurrentFormStats:', scaledCurrentFormStats);

  // Determine which stats to use: scaledCurrentFormStats or championType.baseStats
  let baseStats: Stats = scaledCurrentFormStats ?? scaleBaseStats(championType?.baseStats);

  return (
    <div className="stats-wrap">
      {headers !== false && (
        <>
          <h3>Stats</h3>
          <div>
            STAT: <span>Base</span>
            {item?.instance && <span className="stat myStat"> / Mine</span>}
          </div>
          <hr />
        </>
      )}
      {Object.entries(orderedStatNameToStatKind).map(([statName, statKind]) => (
        <div>
          {statName}:<span className="stat baseStat">{baseStats && formatStat[statKind](baseStats[statKind])}</span>
          <span className="stat baseStat">{stats && ' / '}</span>
          {item?.instance && stats && (
            <span className="stat myStat">
              {stats.effectiveStats && formatStat[statKind](stats.effectiveStats[statKind])}
            </span>
          )}
        </div>
      ))}
    </div>
  );
});
