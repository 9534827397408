import memoizeOne from 'memoize-one';
export * from './Types';

import {
  AbilityType,
  Affinity,
  AreaId,
  ChampionRatings,
  ChampionType,
  FactionId,
  Rarity,
  Role,
  StatKind,
  Rank,
  Tag,
  MasteryKindId,
  GearSlot,
  ArtifactSlots,
  Stats,
  EffectTargetType,
  EffectKindId,
} from '../Types';
import { DisplayMetadata, Named, OrderedDisplayMetadata } from './Types';

import { getStaticData } from './StaticDataManager';
import { FactionAlliance } from 'Types/Enums/FactionAlliance';
import { AuraAreas } from 'Types/Enums/AuraAreas';
import {
  ArenaLeagueId,
  ArtifactSetKindId,
  EffectRelation,
  SkillUpgrade,
  StatusEffectTypeId,
  Visibility,
} from '@raid-toolkit/webclient';
import { ArtifactRarity } from 'Types/Enums/ArtifactRarity';
import { Blessing } from 'Types/Enums/Blessing';
import React from 'react';
import { useStaticData } from 'Data';
import { ArenaRoles } from 'Types/Enums/ArenaRoles';

export * from './StaticDataManager';

export function getAllChamps() {
  return Object.values(useStaticData().heroTypes)
    .filter((heroType) => {
      const heroTypeId = heroType.typeId;
      if (heroTypeId % 10 === 6 || heroType.rarity === Rarity.common) {
        return heroType.faction !== FactionId.unknown;
      }
      return false;
    })
    .map((type) => ({ type, key: `type_${type.slug}` }));
}

export function getFactions(): Record<FactionId, OrderedDisplayMetadata> {
  return getStaticData().faction;
}

export function getStats(): Record<StatKind, OrderedDisplayMetadata> {
  return getStaticData().stat;
}

export function getAuras(): Record<
  Exclude<StatKind, StatKind.criticalDamage | StatKind.criticalHeal>,
  DisplayMetadata
> {
  return getStaticData().aura;
}

export function getAuraAreas(): Record<AuraAreas, DisplayMetadata> {
  return getStaticData().auraArea;
}

{
  /* Failed attempt at Faction Alliance Filter...
   export function getFactionAlliances(): Record<FactionAlliance, OrderedDisplayMetadata> {
  // TODO: Going to have to redo this after this data is just added to the data for each champion
  const faction = getStaticData().faction;
  let factionAlliance = 'Unknown';
  if (faction.bannerlords || faction.barbarians || faction.highelves || faction.sacredorder) {
    factionAlliance = 'Telerians (BL/BA/HE/SO)';
  }
  if (faction.ogryntribes || faction.lizardmen || faction.skinwalkers || faction.orcs) {
    factionAlliance = 'Gaellen Pact (OT/LZ/SW/OR)';
  }
  if (faction.bannerlords || faction.barbarians || faction.highelves || faction.sacredorder) {
    factionAlliance = 'The Corrupted (DS/UH/DE/KR)';
  }
  if (faction.dwarves || faction.samurai) {
    factionAlliance = 'Nyresan Union (DW/SK)';
  }
  return factionAlliance;
} */
}

export function getArenaLeagues(): Record<ArenaLeagueId, Partial<Stats>> {
  return getStaticData().arenaLeagues;
}

export function getAuraData(key: StatKind): DisplayMetadata {
  return getStaticData().aura[key as Exclude<StatKind, StatKind.criticalDamage | StatKind.criticalHeal>];
}

export function getAffinityData(key: Affinity): OrderedDisplayMetadata {
  return getStaticData().affinity[key];
}

export function getAffinities(): Record<Affinity, OrderedDisplayMetadata> {
  return getStaticData().affinity;
}

export function getRoles(): Record<Role, OrderedDisplayMetadata> {
  return getStaticData().role;
}

export function getFormsData(): Record<string, any> {
  return getStaticData().forms;
}

// Modify getRoles to accept roleData as a parameter
export function getRolesFromFirstForm(roleForFirstChampion: Record<string, any>): Record<Role, OrderedDisplayMetadata> {
  // Use roleForFirstChampion to access the role for the first champion
  const role = roleForFirstChampion.role;

  // Rest of your getRoles function
  return {
    // Define your roles here based on roleForFirstChampion
  };
}

// Make sure to import from the correct location

export function getArenaRoles(): Record<ArenaRoles, OrderedDisplayMetadata> {
  const allRatings = getStaticData().ratings;
  let uniqueRoles: Record<ArenaRoles, OrderedDisplayMetadata> = {} as Record<ArenaRoles, OrderedDisplayMetadata>;

  for (const slug in allRatings) {
    const rating = allRatings[slug] as ChampionRatings;
    const role = rating?.detailed?.hellhades?.arenaRoles as ArenaRoles | undefined;

    if (role && !uniqueRoles[role]) {
      uniqueRoles[role] = {
        name: role,
        // Add other properties that match your OrderedDisplayMetadata type
      };
    }
  }

  return uniqueRoles;
}

export function getStatusEffects(): Partial<Record<StatusEffectTypeId, DisplayMetadata>> {
  return getStaticData().statusEffect;
}

export function getStatusEffectsWith(): Partial<Record<StatusEffectTypeId, DisplayMetadata>> {
  return getStaticData().statusEffect;
}

export function getEffectKinds(): Partial<Record<EffectKindId, DisplayMetadata>> {
  return getStaticData().effectKind;
}

export function getTargetTypes(): Partial<Record<EffectTargetType, DisplayMetadata>> {
  return getStaticData().targetType;
}

export function getAreaData(key: AreaId): Named {
  return getStaticData().area[key];
}

export function getRarities(): Record<Rarity, OrderedDisplayMetadata> {
  return getStaticData().rarity;
}

export function getArtifactRarities(): Record<ArtifactRarity, OrderedDisplayMetadata> {
  return getStaticData().artifactRarity;
}

export function getDamageVariables(): Record<string, DisplayMetadata> {
  return getStaticData().damageVariables;
}

export function getRarityOrdinalData(ordinal: number): OrderedDisplayMetadata | undefined {
  return Object.values(getRarities()).find(
    (entry) => typeof entry !== 'string' && entry.ordinal === ordinal
  ) as OrderedDisplayMetadata;
}

export function getHeroRating(slug: string): ChampionRatings | undefined {
  return getStaticData().ratings[slug] as ChampionRatings;
}

export function getHeroTypes(): ChampionType[] {
  return (
    Object.entries(getStaticData().heroTypes)
      .map(([id, value]) => {
        const heroTypeId = parseInt(id, 10);
        if (heroTypeId % 10 === 6 || value.rarity === Rarity.common) {
          return value;
        }
        return undefined;
      })
      .filter(Boolean) as ChampionType[]
  ).filter((hero) => hero.faction !== FactionId.unknown);
}

export const getHeroTypesEx = () => {
  return getHeroTypes();
};

export function getSkills(): {
  [k: string]: {
    name: string;
    description: string;
    typeId: number;
    cooldown: number;
    visibility: Visibility;
    unblockable: boolean;
    multiplier: any;
    upgrades: SkillUpgrade[];
    doesDamage: boolean;
  };
} {
  return getStaticData().skills;
}

export function getRanks(): Record<Rank, OrderedDisplayMetadata> {
  return getStaticData().rank;
}

export function getTags(): Record<Tag, OrderedDisplayMetadata> {
  return getStaticData().tag;
}

export function getBlessings(): Record<Blessing, OrderedDisplayMetadata> {
  return getStaticData().blessing;
}

export function getMasteries(): Record<MasteryKindId, OrderedDisplayMetadata> {
  return getStaticData().masteries;
}

export function getArtifactRarity(): Record<ArtifactRarity, OrderedDisplayMetadata> {
  return getStaticData().artifactRarity;
}

export function getArtifactStats(): Record<StatKind, OrderedDisplayMetadata> {
  return getStaticData().artifactPrimaryStat;
}

export function getGearSlots(): Record<ArtifactSlots, OrderedDisplayMetadata> {
  return getStaticData().artifactSlots;
}

export function getArtifactSlots(): Record<GearSlot, OrderedDisplayMetadata> {
  return getStaticData().artifactSlots;
}

export function getArtifactSets(): Record<ArtifactSetKindId, OrderedDisplayMetadata> {
  return getStaticData().artifactSets;
}
