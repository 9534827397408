import { DisplayMetadata } from '../../Data';

/* Maxed? */
export const MaxedToggleFilterOptions: Record<string, DisplayMetadata> = {
  no: {
    color: 'red',
    name: 'No',
  },
  off: {
    color: '',
    name: 'Maxed?',
  },
  yes: {
    color: 'lime',
    name: 'Yes',
  },
};
