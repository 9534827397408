import { useState } from 'react';
import { ArtifactItem, ArtifactStatBonus, ChampionItem } from 'Types';

export function getBestSubRollLevel(artifact: ArtifactItem) {
  let subRollLevelTally: number[] = [];
  let subRollLevel: number = 0;
  if (artifact) {
    const artifactSecondaryBonuses = artifact.secondaryBonuses;

    artifactSecondaryBonuses.forEach((item) => {
      if (item.level > 0) {
        subRollLevelTally.push(item.level);
      } else {
        subRollLevelTally.push(0);
      }
    });

    if (
      subRollLevelTally.includes(1) &&
      !subRollLevelTally.includes(2) &&
      !subRollLevelTally.includes(3) &&
      !subRollLevelTally.includes(4)
    ) {
      subRollLevel = 1;
    }
    if (
      !subRollLevelTally.includes(1) &&
      subRollLevelTally.includes(2) &&
      !subRollLevelTally.includes(3) &&
      !subRollLevelTally.includes(4)
    ) {
      subRollLevel = 2;
    }
    if (
      !subRollLevelTally.includes(1) &&
      !subRollLevelTally.includes(2) &&
      subRollLevelTally.includes(3) &&
      !subRollLevelTally.includes(4)
    ) {
      subRollLevel = 3;
    }
    if (
      !subRollLevelTally.includes(1) &&
      !subRollLevelTally.includes(2) &&
      !subRollLevelTally.includes(3) &&
      subRollLevelTally.includes(4)
    ) {
      subRollLevel = 4;
    }
  }

  return subRollLevel;
}

export function returnGlyphedArtifacts(item: ArtifactItem) {
  const matchingItems = [];

  if (item.secondaryBonuses) {
    for (let i = 0; i < item.secondaryBonuses.length; i++) {
      const secondaryBonus = item.secondaryBonuses[i]?.glyphPower || 0;
      if (secondaryBonus > 0) {
        matchingItems.push(item);
      }
    }
  }
  return matchingItems;
}

/* Having Trouble using this for the ArtifactSubstatToggleFilter */
export function returnArtifactsWithSelectedSubstat(item: ArtifactItem, substat: string, absolute: boolean) {
  if (item.secondaryBonuses) {
    for (let i = 0; i < item.secondaryBonuses.length; i++) {
      const bonus = item.secondaryBonuses[i];
      if (bonus) {
        const kind = bonus.statKind;
        if (substat.indexOf('%') > -1 || substat.indexOf('Percent') > -1) {
          if (kind && substat.indexOf(kind) > -1) {
            if (absolute === false) {
              return item;
            }
          }
        } else {
          if (kind && substat.indexOf(kind) > -1) {
            return item;
          }
        }
      }
    }
  }
}

/* Confirmed to work correctly */
export function returnMatchesForAllFourSubstats(
  item: ArtifactItem,
  substat: string | undefined,
  requiredRolls: number
) {
  for (var i = 0; i < 3; i++) {
    const kind = item.secondaryBonuses[i]?.statKind;
    const level = item.secondaryBonuses[i]?.level || 0;
    let requiredRollsAfterRevertingZeroAndNegativesToOne: number;
    if (requiredRolls < 1 && item.level > 3) {
      requiredRollsAfterRevertingZeroAndNegativesToOne = 1;
    } else {
      requiredRollsAfterRevertingZeroAndNegativesToOne = requiredRolls;
    }
    if (kind === substat && level >= requiredRolls) {
      /* if (item.id === 13081) {
        console.log(
          `13081 returnMatchesForAllFourSubstats substat: ${kind} requiredRolls: ${requiredRollsAfterRevertingZeroAndNegativesToOne}`,
          item
        );
      } */
      //console.log(`returnMatchesForAllFourSubstats substat: ${substat} requiredRolls: ${requiredRolls}`, item);
      /* if (item.id === 21046) {
        console.log(`21046 returnMatchesForAllFourSubstats substat: ${substat} requiredRolls: ${requiredRolls}`, item);
      } */
      return item;
    }
  }
}

/* Confirmed to work correctly */
export function returnMainPercentageStatsForAllFourSubstats(item: ArtifactItem, requiredRolls: number) {
  for (var i = 0; i < 3; i++) {
    const kind = item.secondaryBonuses[i]?.statKind;
    const level = item.secondaryBonuses[i]?.level || 0;
    const absolute = item.secondaryBonuses[i]?.absolute;
    let requiredRollsAfterRevertingZeroAndNegativesToOne: number;
    if (requiredRolls < 1 && item.level > 3) {
      requiredRollsAfterRevertingZeroAndNegativesToOne = 1;
    } else {
      requiredRollsAfterRevertingZeroAndNegativesToOne = requiredRolls;
    }
    if (
      ((kind === 'attack' && absolute === false) ||
        (kind === 'defense' && absolute === false) ||
        (kind === 'health' && absolute === false)) &&
      level >= requiredRollsAfterRevertingZeroAndNegativesToOne
    ) {
      //console.log(`returnMainPercentageStatsForAllFourSubstats kind: ${kind} requiredRolls: ${requiredRolls}`, item);
      //console.log(`returnMatchesForAllFourSubstats substat: ${kind} requiredRolls: ${requiredRolls}`, item);
      return item;
    }
  }
}

export function returnAnyPercentageStatsForAllFourSubstats(item: ArtifactItem, substat: string, requiredRolls: number) {
  for (var i = 0; i < 3; i++) {
    const kind = item.secondaryBonuses[i]?.statKind;
    const level = item.secondaryBonuses[i]?.level || 0;
    const absolute = item.secondaryBonuses[i]?.absolute;
    let requiredRollsAfterRevertingZeroAndNegativesToOne: number;
    if (requiredRolls < 1 && item.level > 3) {
      requiredRollsAfterRevertingZeroAndNegativesToOne = 1;
    } else {
      requiredRollsAfterRevertingZeroAndNegativesToOne = requiredRolls;
    }
    if (kind === substat && absolute === false && level >= requiredRollsAfterRevertingZeroAndNegativesToOne) {
      //console.log(`returnMainPercentageStatsForAllFourSubstats kind: ${kind} requiredRolls: ${requiredRolls}`, item);
      //console.log(`returnMatchesForAllFourSubstats substat: ${kind} requiredRolls: ${requiredRolls}`, item);
      return item;
    }
  }
}

/* Confirmed to work correctly */
export function returnAttackPercentageStatsForAllFourSubstats(item: ArtifactItem, requiredRolls: number) {
  let requiredRollsAfterRevertingZeroAndNegativesToOne: number;
  if (requiredRolls < 1 && item.level > 3) {
    requiredRollsAfterRevertingZeroAndNegativesToOne = 1;
  } else {
    requiredRollsAfterRevertingZeroAndNegativesToOne = requiredRolls;
  }
  for (var i = 0; i < 3; i++) {
    const kind = item.secondaryBonuses[i]?.statKind;
    const level = item.secondaryBonuses[i]?.level || 0;
    const absolute = item.secondaryBonuses[i]?.absolute;
    if (kind === 'attack' && absolute === false && level >= requiredRollsAfterRevertingZeroAndNegativesToOne) {
      //console.log(`returnAttackPercentageStatsForAllFourSubstats kind: attack requiredRolls: ${requiredRolls}`, item);
      return item;
    }
  }
}

/* Probably works correctly but not useful on top bits */
export function returnAttackPercentageStatsForPrimaryStat(item: ArtifactItem) {
  let kind;
  if (item.primaryBonus.statKind) {
    kind = item.primaryBonus.statKind;
  }
  const absolute = item.primaryBonus.absolute;
  if (kind === 'attack' && absolute === false) {
    //console.log(`returnAttackPercentageStatsForPrimaryStat kind: attack`, item);
    return item;
  }
}

/* Confirmed to work correctly */
export function returnTankMainPercentageStatsForAllFourSubstats(item: ArtifactItem, requiredRolls: number) {
  let requiredRollsAfterRevertingZeroAndNegativesToOne: number;
  if (requiredRolls < 1 && item.level > 3) {
    requiredRollsAfterRevertingZeroAndNegativesToOne = 1;
  } else {
    requiredRollsAfterRevertingZeroAndNegativesToOne = requiredRolls;
  }
  for (var i = 0; i < 3; i++) {
    const kind = item.secondaryBonuses[i]?.statKind;
    const level = item.secondaryBonuses[i]?.level || 0;
    const absolute = item.secondaryBonuses[i]?.absolute;
    if (
      ((kind === 'defense' && absolute === false) || (kind === 'health' && absolute === false)) &&
      level >= requiredRollsAfterRevertingZeroAndNegativesToOne
    ) {
      //console.log(`returnTankMainPercentageStatsForAllFourSubstats requiredRolls: ${requiredRolls}`, item);
      return item;
    }
  }
}

/* Probably works but not using */
export function returnNukerMainPercentageStatsForAllFourSubstats(item: ArtifactItem, requiredRolls: number) {
  let requiredRollsAfterRevertingZeroAndNegativesToOne: number;
  if (requiredRolls < 1 && item.level > 3) {
    requiredRollsAfterRevertingZeroAndNegativesToOne = 0;
  } else {
    requiredRollsAfterRevertingZeroAndNegativesToOne = requiredRolls;
  }
  for (var i = 0; i < 3; i++) {
    const kind = item.secondaryBonuses[i]?.statKind;
    const level = item.secondaryBonuses[i]?.level || 0;
    const absolute = item.secondaryBonuses[i]?.absolute;
    if (
      ((kind === 'attack' && absolute === false) || kind === 'criticalchance' || kind === 'criticaldamage') &&
      level >= requiredRollsAfterRevertingZeroAndNegativesToOne
    ) {
      //console.log(`returnNukerMainPercentageStatsForAllFourSubstats requiredRolls: ${requiredRolls}`, item);
      return item;
    }
  }
}

const selectedStageOfTheGame = localStorage.getItem('setting:saved-selection-stageOfTheGamSelector');

export let requiredRollsByStageOfGame: number;
if (selectedStageOfTheGame === '"earlyGame"') {
  requiredRollsByStageOfGame = 0;
}
if (selectedStageOfTheGame === '"midGame"') {
  requiredRollsByStageOfGame = 1;
}
if (selectedStageOfTheGame === '"lateGame"') {
  requiredRollsByStageOfGame = 2;
}
if (selectedStageOfTheGame === '"endGame"') {
  requiredRollsByStageOfGame = 3;
}

export function returnBetween12And15LegendaryArtifactsWithSpecifiedSubstats(
  item: ArtifactItem,
  substat1: string,
  substat2?: string,
  substat3?: string,
  substat4?: string
) {
  const allSubstats = [];
  allSubstats.push(substat1);
  if (substat2) {
    allSubstats.push(substat2);
  }
  if (substat3) {
    allSubstats.push(substat3);
  }
  if (substat4) {
    allSubstats.push(substat4);
  }
  //console.log(`allSubstats`, allSubstats);
  if (allSubstats.includes('HP%') || allSubstats.includes('DEF%') || allSubstats.includes('ATK%')) {
    /* if (
    item.level >= 12 &&
      item.level <= 16 &&
      //Is Between level 12-15
      //Is Between level 12-15 //Is Legendary //Any items with substat1 or substat2 with atleast 2 rolls between the two
      //Is Legendary
      (item.rarity === 'legendary' || item.rarity === 'mythical') && //substat1 with 2 rolls
      //Any items with substat1 or substat2 or HP% Or DEF% with atleast 2 rolls between the three
      returnAnyPercentageStatsForAllFourSubstats(item, substat1, requiredRollsByStageOfGame - 1) ||
      (substat2 && returnAnyPercentageStatsForAllFourSubstats(item, substat2, requiredRollsByStageOfGame - 1)) ||
      (substat3 && returnAnyPercentageStatsForAllFourSubstats(item, substat3, requiredRollsByStageOfGame - 1)) ||
      (substat4 && returnAnyPercentageStatsForAllFourSubstats(item, substat4, requiredRollsByStageOfGame - 1))
  ) {
    return item;
  } */
    if (allSubstats.includes('ATK% or DEF% or HP%')) {
      //console.log(`Calculating for ATK% or DEF% or HP% substats...`);
      //Is Between level 12-15 //Is Legendary //Any items with substat1 or substat1 with atleast 2 rolls between the three
      item.level >= 12 &&
        //Is Legendary
        (item.rarity === 'legendary' || item.rarity === 'mythical') && //substat1 with 2 rolls
        //Any items with substat1 or substat2 or substat3 with atleast 2 rolls between the three
        (returnMatchesForAllFourSubstats(item, substat1, requiredRollsByStageOfGame - 1) ||
          //substat1 with 2 rolls
          returnMatchesForAllFourSubstats(item, substat2, requiredRollsByStageOfGame - 1) ||
          //substat3 with 2 rolls
          returnMatchesForAllFourSubstats(item, substat3, requiredRollsByStageOfGame - 1) ||
          //ATK% or DEF% or HP% with 2 rolls
          returnMainPercentageStatsForAllFourSubstats(item, requiredRollsByStageOfGame - 1) ||
          //requiredRollsByStageOfGame === 3
          //substat1 with 2 roll AND substat2 with 1 Roll
          (requiredRollsByStageOfGame === 3 &&
            returnMatchesForAllFourSubstats(item, substat1, 1) &&
            returnMatchesForAllFourSubstats(item, substat2, 1)) ||
          //substat1 with 2 roll AND substat3 with 1 Roll
          (requiredRollsByStageOfGame === 3 &&
            returnMatchesForAllFourSubstats(item, substat1, 1) &&
            returnMatchesForAllFourSubstats(item, substat3, 1)) ||
          //substat2 with 2 roll AND substat3 with 1 Roll
          (requiredRollsByStageOfGame === 3 &&
            returnMatchesForAllFourSubstats(item, substat2, 1) &&
            returnMatchesForAllFourSubstats(item, substat3, 1)) ||
          //substat1 with 1 roll AND substat2 with 2 Roll
          (requiredRollsByStageOfGame === 3 &&
            returnMatchesForAllFourSubstats(item, substat1, 1) &&
            returnMatchesForAllFourSubstats(item, substat2, 1)) ||
          //substat1 with 1 roll AND substat3 with 2 Roll
          (requiredRollsByStageOfGame === 3 &&
            returnMatchesForAllFourSubstats(item, substat1, 1) &&
            returnMatchesForAllFourSubstats(item, substat3, 1)) ||
          //substat2 with 1 roll AND substat3 with 2 Roll
          (requiredRollsByStageOfGame === 3 &&
            returnMatchesForAllFourSubstats(item, substat2, 1) &&
            returnMatchesForAllFourSubstats(item, substat3, 1)) ||
          //requiredRollsByStageOfGame === 2
          //substat1 with 2 roll AND substat2 with 1 Roll
          (requiredRollsByStageOfGame === 2 &&
            ((returnMatchesForAllFourSubstats(item, substat1, 0) &&
              returnMatchesForAllFourSubstats(item, substat2, 1)) ||
              (returnMatchesForAllFourSubstats(item, substat1, 1) &&
                returnMatchesForAllFourSubstats(item, substat2, 0)))) ||
          //substat1 with 2 roll AND substat3 with 1 Roll
          (requiredRollsByStageOfGame === 2 &&
            ((returnMatchesForAllFourSubstats(item, substat1, 0) &&
              returnMatchesForAllFourSubstats(item, substat3, 1)) ||
              (returnMatchesForAllFourSubstats(item, substat1, 1) &&
                returnMatchesForAllFourSubstats(item, substat3, 0)))) ||
          //substat2 with 2 roll AND substat3 with 1 Roll
          (requiredRollsByStageOfGame === 2 &&
            ((returnMatchesForAllFourSubstats(item, substat2, 0) &&
              returnMatchesForAllFourSubstats(item, substat3, 1)) ||
              (returnMatchesForAllFourSubstats(item, substat2, 1) &&
                returnMatchesForAllFourSubstats(item, substat3, 0)))) ||
          //substat1 with 1 roll AND substat2 with 2 Roll
          (requiredRollsByStageOfGame === 2 &&
            ((returnMatchesForAllFourSubstats(item, substat1, 0) &&
              returnMatchesForAllFourSubstats(item, substat2, 1)) ||
              (returnMatchesForAllFourSubstats(item, substat1, 1) &&
                returnMatchesForAllFourSubstats(item, substat2, 0)))) ||
          //substat1 with 1 roll AND substat3 with 2 Roll
          (requiredRollsByStageOfGame === 2 &&
            ((returnMatchesForAllFourSubstats(item, substat1, 0) &&
              returnMatchesForAllFourSubstats(item, substat3, 1)) ||
              (returnMatchesForAllFourSubstats(item, substat1, 1) &&
                returnMatchesForAllFourSubstats(item, substat3, 0)))) ||
          //substat2 with 1 roll AND substat3 with 2 Roll
          (requiredRollsByStageOfGame === 2 &&
            ((returnMatchesForAllFourSubstats(item, substat2, 0) &&
              returnMatchesForAllFourSubstats(item, substat3, 1)) ||
              (returnMatchesForAllFourSubstats(item, substat2, 1) &&
                returnMatchesForAllFourSubstats(item, substat3, 0)))) ||
          //requiredRollsByStageOfGame === 1
          //substat1 with 2 roll AND substat2 with 1 Roll
          (requiredRollsByStageOfGame <= 1 && returnMatchesForAllFourSubstats(item, substat1, 0)) ||
          (requiredRollsByStageOfGame <= 1 && returnMatchesForAllFourSubstats(item, substat1, 0)) ||
          returnMatchesForAllFourSubstats(item, substat2, 0) ||
          //substat1 with 2 roll AND substat3 with 1 Roll
          (requiredRollsByStageOfGame <= 1 && returnMatchesForAllFourSubstats(item, substat1, 0)) ||
          (requiredRollsByStageOfGame <= 1 && returnMatchesForAllFourSubstats(item, substat1, 0)) ||
          returnMatchesForAllFourSubstats(item, substat3, 0) ||
          //substat2 with 2 roll AND substat3 with 1 Roll
          (requiredRollsByStageOfGame <= 1 && returnMatchesForAllFourSubstats(item, substat2, 0)) ||
          (requiredRollsByStageOfGame <= 1 && returnMatchesForAllFourSubstats(item, substat2, 0)) ||
          returnMatchesForAllFourSubstats(item, substat3, 0) ||
          //substat1 with 1 roll AND substat2 with 2 Roll
          (requiredRollsByStageOfGame <= 1 && returnMatchesForAllFourSubstats(item, substat1, 0)) ||
          (requiredRollsByStageOfGame <= 1 && returnMatchesForAllFourSubstats(item, substat1, 0)) ||
          returnMatchesForAllFourSubstats(item, substat2, 0) ||
          //substat1 with 1 roll AND substat3 with 2 Roll
          (requiredRollsByStageOfGame <= 1 && returnMatchesForAllFourSubstats(item, substat1, 0)) ||
          returnMatchesForAllFourSubstats(item, substat3, 0) ||
          //substat2 with 1 roll AND substat3 with 2 Roll
          (requiredRollsByStageOfGame <= 1 && returnMatchesForAllFourSubstats(item, substat2, 0)) ||
          returnMatchesForAllFourSubstats(item, substat3, 0));
    }
    if (allSubstats.includes('HP%') && allSubstats.includes('DEF%')) {
      //console.log(`Calculating for HP% & DEF% substats...`);
      if (
        item.level >= 12 &&
        item.level <= 16 &&
        //Is Between level 12-15
        //Is Between level 12-15 //Is Legendary //Any items with substat1 or substat2 with atleast 2 rolls between the two
        //Is Legendary
        (item.rarity === 'legendary' || item.rarity === 'mythical') && //substat1 with 2 rolls
        //Any items with substat1 or substat2 or HP% Or DEF% with atleast 2 rolls between the three
        (returnMatchesForAllFourSubstats(item, substat1, requiredRollsByStageOfGame - 1) ||
          //substat2 with 2 rolls
          returnMatchesForAllFourSubstats(item, substat2, requiredRollsByStageOfGame - 1) ||
          //HP% Or DEF% with 2 rolls
          returnTankMainPercentageStatsForAllFourSubstats(item, requiredRollsByStageOfGame - 1) ||
          //requiredRollsByStageOfGame === 3
          //substat1 with 1 roll AND substat2 with 1 Roll
          (requiredRollsByStageOfGame === 3 &&
            returnMatchesForAllFourSubstats(item, substat1, 1) &&
            returnMatchesForAllFourSubstats(item, substat2, 1)) ||
          //substat1 with 1 roll AND HP% Or DEF% with 1 Roll
          (requiredRollsByStageOfGame === 3 &&
            returnMatchesForAllFourSubstats(item, substat1, 1) &&
            returnTankMainPercentageStatsForAllFourSubstats(item, 1)) ||
          //substat2 with 1 roll AND HP% Or DEF% with 1 Roll
          (requiredRollsByStageOfGame === 3 &&
            returnMatchesForAllFourSubstats(item, substat2, 1) &&
            returnTankMainPercentageStatsForAllFourSubstats(item, 1)) ||
          //substat1 with 1 roll AND substat2 with 1 Roll
          (requiredRollsByStageOfGame === 3 &&
            returnMatchesForAllFourSubstats(item, substat1, 1) &&
            returnMatchesForAllFourSubstats(item, substat2, 1)) ||
          //substat1 with 1 roll AND HP% Or DEF% with 1 Roll
          (requiredRollsByStageOfGame === 3 &&
            returnMatchesForAllFourSubstats(item, substat1, 1) &&
            returnTankMainPercentageStatsForAllFourSubstats(item, 1)) ||
          //substat2 with 1 roll AND HP% Or DEF% with 1 Roll
          (requiredRollsByStageOfGame === 3 &&
            returnMatchesForAllFourSubstats(item, substat2, 1) &&
            returnTankMainPercentageStatsForAllFourSubstats(item, 1)) ||
          //requiredRollsByStageOfGame === 2
          //substat1 with 1 roll AND substat2 with 1 Roll
          (requiredRollsByStageOfGame === 2 &&
            ((returnMatchesForAllFourSubstats(item, substat1, 0) &&
              returnMatchesForAllFourSubstats(item, substat2, 1)) ||
              (returnMatchesForAllFourSubstats(item, substat1, 1) &&
                returnMatchesForAllFourSubstats(item, substat2, 0)))) ||
          //substat1 with 1 roll AND HP% Or DEF% with 1 Roll
          (requiredRollsByStageOfGame === 2 &&
            ((returnMatchesForAllFourSubstats(item, substat1, 0) &&
              returnTankMainPercentageStatsForAllFourSubstats(item, 1)) ||
              (returnMatchesForAllFourSubstats(item, substat1, 1) &&
                returnTankMainPercentageStatsForAllFourSubstats(item, 0)))) ||
          //substat2 with 1 roll AND HP% Or DEF% with 1 Roll
          (requiredRollsByStageOfGame === 2 &&
            ((returnMatchesForAllFourSubstats(item, substat2, 0) &&
              returnTankMainPercentageStatsForAllFourSubstats(item, 1)) ||
              (returnMatchesForAllFourSubstats(item, substat2, 1) &&
                returnTankMainPercentageStatsForAllFourSubstats(item, 0)))) ||
          //substat1 with 1 roll AND substat2 with 1 Roll
          (requiredRollsByStageOfGame === 2 &&
            ((returnMatchesForAllFourSubstats(item, substat1, 0) &&
              returnMatchesForAllFourSubstats(item, substat2, 1)) ||
              (returnMatchesForAllFourSubstats(item, substat1, 1) &&
                returnMatchesForAllFourSubstats(item, substat2, 0)))) ||
          //substat1 with 1 roll AND HP% Or DEF% with 1 Roll
          (requiredRollsByStageOfGame === 2 &&
            ((returnMatchesForAllFourSubstats(item, substat1, 0) &&
              returnTankMainPercentageStatsForAllFourSubstats(item, 1)) ||
              (returnMatchesForAllFourSubstats(item, substat1, 1) &&
                returnTankMainPercentageStatsForAllFourSubstats(item, 0)))) ||
          //substat2 with 1 roll AND HP% Or DEF% with 1 Roll
          (requiredRollsByStageOfGame === 2 &&
            ((returnMatchesForAllFourSubstats(item, substat2, 0) &&
              returnTankMainPercentageStatsForAllFourSubstats(item, 1)) ||
              (returnMatchesForAllFourSubstats(item, substat2, 1) &&
                returnTankMainPercentageStatsForAllFourSubstats(item, 0)))) ||
          //requiredRollsByStageOfGame <= 1
          //substat1 with 1 roll AND substat2 with 1 Roll
          (requiredRollsByStageOfGame <= 1 && returnMatchesForAllFourSubstats(item, substat1, 0)) ||
          returnMatchesForAllFourSubstats(item, substat2, 0) ||
          //substat1 with 1 roll AND HP% Or DEF% with 1 Roll
          (requiredRollsByStageOfGame <= 1 && returnMatchesForAllFourSubstats(item, substat1, 0)) ||
          returnTankMainPercentageStatsForAllFourSubstats(item, 0) ||
          //substat2 with 1 roll AND HP% Or DEF% with 1 Roll
          (requiredRollsByStageOfGame <= 1 && returnMatchesForAllFourSubstats(item, substat2, 0)) ||
          returnTankMainPercentageStatsForAllFourSubstats(item, 0) ||
          //substat1 with 1 roll AND substat2 with 1 Roll
          (requiredRollsByStageOfGame <= 1 && returnMatchesForAllFourSubstats(item, substat1, 0)) ||
          returnMatchesForAllFourSubstats(item, substat2, 0) ||
          //substat1 with 1 roll AND HP% Or DEF% with 1 Roll
          (requiredRollsByStageOfGame <= 1 && returnMatchesForAllFourSubstats(item, substat1, 0)) ||
          returnTankMainPercentageStatsForAllFourSubstats(item, 0) ||
          //substat2 with 1 roll AND HP% Or DEF% with 1 Roll
          (requiredRollsByStageOfGame <= 1 && returnMatchesForAllFourSubstats(item, substat2, 0)) ||
          returnTankMainPercentageStatsForAllFourSubstats(item, 0))
      ) {
        return item;
      }
    }
    if (allSubstats.includes('ATK%') && !allSubstats.includes(' or DEF% or HP%')) {
      /* if (item.id === 83989) {
        console.log(`item 83989`, item);
      } */
      //Is Between level 12-15 //Is Legendary //Any items with substat1 or Attack% with atleast 2 rolls between the three
      item.level >= 12 &&
        //Is Legendary
        (item.rarity === 'legendary' || item.rarity === 'mythical') &&
        //Any items with substat1 or Attack% or substat2 with atleast 2 rolls between the three
        //substat1 with 3 rolls
        (returnMatchesForAllFourSubstats(item, substat1, requiredRollsByStageOfGame - 1) ||
          //Attack% with 3 rolls
          returnAttackPercentageStatsForAllFourSubstats(item, requiredRollsByStageOfGame - 1) ||
          //substat2 with 3 rolls
          returnMatchesForAllFourSubstats(item, substat2, requiredRollsByStageOfGame - 1) ||
          //substat3 with 3 rolls
          returnMatchesForAllFourSubstats(item, substat3, requiredRollsByStageOfGame - 1) ||
          //requiredRollsByStageOfGame === 3
          //substat1 with 2 Rolls & substat2 with 1 Roll
          (requiredRollsByStageOfGame === 3 &&
            returnMatchesForAllFourSubstats(item, substat1, 1) &&
            returnMatchesForAllFourSubstats(item, substat2, 1)) ||
          //substat3 with 2 Rolls & substat2 with 1 Roll
          (requiredRollsByStageOfGame === 3 &&
            returnMatchesForAllFourSubstats(item, substat3, 1) &&
            returnMatchesForAllFourSubstats(item, substat2, 1)) ||
          //substat3 with 2 Rolls & substat1 with 1 Roll
          (requiredRollsByStageOfGame === 3 &&
            returnMatchesForAllFourSubstats(item, substat3, 1) &&
            returnMatchesForAllFourSubstats(item, substat1, 1)) ||
          //Attack% with 2 Rolls & substat1 with 1 Roll
          (requiredRollsByStageOfGame === 3 &&
            returnAttackPercentageStatsForAllFourSubstats(item, 1) &&
            returnMatchesForAllFourSubstats(item, substat1, 1)) ||
          //Attack% with 2 Rolls & substat2 with 1 Roll
          (requiredRollsByStageOfGame === 3 &&
            returnAttackPercentageStatsForAllFourSubstats(item, 1) &&
            returnMatchesForAllFourSubstats(item, substat2, 1)) ||
          //Attack% with 2 Rolls & substat3 with 1 Roll
          (requiredRollsByStageOfGame === 3 &&
            returnAttackPercentageStatsForAllFourSubstats(item, 1) &&
            returnMatchesForAllFourSubstats(item, substat3, 1)) ||
          //substat1 with 2 Rolls & substat2 with 1 Roll
          (requiredRollsByStageOfGame === 3 &&
            returnMatchesForAllFourSubstats(item, substat1, 1) &&
            returnMatchesForAllFourSubstats(item, substat2, 1)) ||
          //substat3 with 2 Rolls & substat2 with 1 Roll
          (requiredRollsByStageOfGame === 3 &&
            returnMatchesForAllFourSubstats(item, substat3, 1) &&
            returnMatchesForAllFourSubstats(item, substat2, 1)) ||
          //substat3 with 2 Rolls & substat1 with 1 Roll
          (requiredRollsByStageOfGame === 3 &&
            returnMatchesForAllFourSubstats(item, substat3, 1) &&
            returnMatchesForAllFourSubstats(item, substat1, 1)) ||
          //Attack% with 2 Rolls & substat1 with 1 Roll
          (requiredRollsByStageOfGame === 3 &&
            returnAttackPercentageStatsForAllFourSubstats(item, 1) &&
            returnMatchesForAllFourSubstats(item, substat1, 1)) ||
          //Attack% with 2 Rolls & substat2 with 1 Roll
          (requiredRollsByStageOfGame === 3 &&
            returnAttackPercentageStatsForAllFourSubstats(item, 1) &&
            returnMatchesForAllFourSubstats(item, substat2, 1)) ||
          //Attack% with 2 Rolls & substat3 with 1 Roll
          (requiredRollsByStageOfGame === 3 &&
            returnAttackPercentageStatsForAllFourSubstats(item, 1) &&
            returnMatchesForAllFourSubstats(item, substat3, 1)) ||
          //substat1 with 1 roll & substat2 with 2 Rolls
          (requiredRollsByStageOfGame === 3 &&
            returnMatchesForAllFourSubstats(item, substat1, 1) &&
            returnMatchesForAllFourSubstats(item, substat2, 1)) ||
          //substat3 with 1 roll & substat2 with 2 Rolls
          (requiredRollsByStageOfGame === 3 &&
            returnMatchesForAllFourSubstats(item, substat3, 1) &&
            returnMatchesForAllFourSubstats(item, substat2, 1)) ||
          //substat3 with 1 roll & substat1 with 2 Rolls
          (requiredRollsByStageOfGame === 3 &&
            returnMatchesForAllFourSubstats(item, substat3, 1) &&
            returnMatchesForAllFourSubstats(item, substat1, 1)) ||
          //Attack% with 1 roll & substat1 with 2 Rolls
          (requiredRollsByStageOfGame === 3 &&
            returnAttackPercentageStatsForAllFourSubstats(item, 1) &&
            returnMatchesForAllFourSubstats(item, substat1, 1)) ||
          //Attack% with 1 roll & substat2 with 2 Rolls
          (requiredRollsByStageOfGame === 3 &&
            returnAttackPercentageStatsForAllFourSubstats(item, 1) &&
            returnMatchesForAllFourSubstats(item, substat2, 1)) ||
          //Attack% with 1 roll & substat3 with 2 Rolls
          (requiredRollsByStageOfGame === 3 &&
            returnAttackPercentageStatsForAllFourSubstats(item, 1) &&
            returnMatchesForAllFourSubstats(item, substat3, 1)) ||
          //substat1 with 1 roll & substat2 with 2 Rolls
          (requiredRollsByStageOfGame === 3 &&
            returnMatchesForAllFourSubstats(item, substat1, 1) &&
            returnMatchesForAllFourSubstats(item, substat2, 1)) ||
          //substat3 with 1 roll & substat2 with 2 Rolls
          (requiredRollsByStageOfGame === 3 &&
            returnMatchesForAllFourSubstats(item, substat3, 1) &&
            returnMatchesForAllFourSubstats(item, substat2, 1)) ||
          //substat3 with 1 roll & substat1 with 2 Rolls
          (requiredRollsByStageOfGame === 3 &&
            returnMatchesForAllFourSubstats(item, substat3, 1) &&
            returnMatchesForAllFourSubstats(item, substat1, 1)) ||
          //Attack% with 1 roll & substat1 with 2 Rolls
          (requiredRollsByStageOfGame === 3 &&
            returnAttackPercentageStatsForAllFourSubstats(item, 1) &&
            returnMatchesForAllFourSubstats(item, substat1, 1)) ||
          //Attack% with 1 roll & substat2 with 2 Rolls
          (requiredRollsByStageOfGame === 3 &&
            returnAttackPercentageStatsForAllFourSubstats(item, 1) &&
            returnMatchesForAllFourSubstats(item, substat2, 1)) ||
          //Attack% with 1 roll & substat3 with 2 Rolls
          (requiredRollsByStageOfGame === 3 &&
            returnAttackPercentageStatsForAllFourSubstats(item, 1) &&
            returnMatchesForAllFourSubstats(item, substat3, 1)) ||
          //requiredRollsByStageOfGame === 2
          //substat1 with 2 Rolls & substat2 with 1 Roll
          (requiredRollsByStageOfGame === 2 &&
            ((returnMatchesForAllFourSubstats(item, substat1, 0) &&
              returnMatchesForAllFourSubstats(item, substat2, 1)) ||
              (returnMatchesForAllFourSubstats(item, substat1, 1) &&
                returnMatchesForAllFourSubstats(item, substat2, 0)))) ||
          //substat3 with 2 Rolls & substat2 with 1 Roll
          (requiredRollsByStageOfGame === 2 &&
            ((returnMatchesForAllFourSubstats(item, substat3, 0) &&
              returnMatchesForAllFourSubstats(item, substat2, 1)) ||
              (returnMatchesForAllFourSubstats(item, substat3, 1) &&
                returnMatchesForAllFourSubstats(item, substat2, 0)))) ||
          //substat3 with 2 Rolls & substat1 with 1 Roll
          (requiredRollsByStageOfGame === 2 &&
            ((returnMatchesForAllFourSubstats(item, substat3, 0) &&
              returnMatchesForAllFourSubstats(item, substat1, 1)) ||
              (returnMatchesForAllFourSubstats(item, substat3, 1) &&
                returnMatchesForAllFourSubstats(item, substat1, 0)))) ||
          //Attack% with 2 Rolls & substat1 with 1 Roll
          (requiredRollsByStageOfGame === 2 &&
            ((returnAttackPercentageStatsForAllFourSubstats(item, 0) &&
              returnMatchesForAllFourSubstats(item, substat1, 1)) ||
              (returnAttackPercentageStatsForAllFourSubstats(item, 1) &&
                returnMatchesForAllFourSubstats(item, substat1, 0)))) ||
          //Attack% with 2 Rolls & substat2 with 1 Roll
          (requiredRollsByStageOfGame === 2 &&
            ((returnAttackPercentageStatsForAllFourSubstats(item, 0) &&
              returnMatchesForAllFourSubstats(item, substat2, 1)) ||
              (returnAttackPercentageStatsForAllFourSubstats(item, 1) &&
                returnMatchesForAllFourSubstats(item, substat2, 0)))) ||
          //Attack% with 2 Rolls & substat3 with 1 Roll
          (requiredRollsByStageOfGame === 2 &&
            returnAttackPercentageStatsForAllFourSubstats(item, 0) &&
            returnMatchesForAllFourSubstats(item, substat3, 1)) ||
          (returnAttackPercentageStatsForAllFourSubstats(item, 1) &&
            returnMatchesForAllFourSubstats(item, substat3, 0)) ||
          //substat1 with 2 Rolls & substat2 with 1 Roll
          (requiredRollsByStageOfGame === 2 &&
            ((returnMatchesForAllFourSubstats(item, substat1, 0) &&
              returnMatchesForAllFourSubstats(item, substat2, 1)) ||
              (returnMatchesForAllFourSubstats(item, substat1, 1) &&
                returnMatchesForAllFourSubstats(item, substat2, 0)))) ||
          //substat3 with 2 Rolls & substat2 with 1 Roll
          (requiredRollsByStageOfGame === 2 &&
            returnMatchesForAllFourSubstats(item, substat3, 0) &&
            returnMatchesForAllFourSubstats(item, substat2, 1)) ||
          (returnMatchesForAllFourSubstats(item, substat3, 1) && returnMatchesForAllFourSubstats(item, substat2, 0)) ||
          //substat3 with 2 Rolls & substat1 with 1 Roll
          (requiredRollsByStageOfGame === 2 &&
            returnMatchesForAllFourSubstats(item, substat3, 0) &&
            returnMatchesForAllFourSubstats(item, substat1, 1)) ||
          (returnMatchesForAllFourSubstats(item, substat3, 1) && returnMatchesForAllFourSubstats(item, substat1, 0)) ||
          //Attack% with 2 Rolls & substat1 with 1 Roll
          (requiredRollsByStageOfGame === 2 &&
            ((returnAttackPercentageStatsForAllFourSubstats(item, 0) &&
              returnMatchesForAllFourSubstats(item, substat1, 1)) ||
              (returnAttackPercentageStatsForAllFourSubstats(item, 1) &&
                returnMatchesForAllFourSubstats(item, substat1, 0)))) ||
          //Attack% with 2 Rolls & substat2 with 1 Roll
          (requiredRollsByStageOfGame === 2 &&
            ((returnAttackPercentageStatsForAllFourSubstats(item, 0) &&
              returnMatchesForAllFourSubstats(item, substat2, 1)) ||
              (returnAttackPercentageStatsForAllFourSubstats(item, 1) &&
                returnMatchesForAllFourSubstats(item, substat2, 0)))) ||
          //Attack% with 2 Rolls & substat3 with 1 Roll
          (requiredRollsByStageOfGame === 2 &&
            ((returnAttackPercentageStatsForAllFourSubstats(item, 0) &&
              returnMatchesForAllFourSubstats(item, substat3, 1)) ||
              (returnAttackPercentageStatsForAllFourSubstats(item, 1) &&
                returnMatchesForAllFourSubstats(item, substat3, 0)))) ||
          //substat1 with 1 roll & substat2 with 2 Rolls
          (requiredRollsByStageOfGame === 2 &&
            ((returnMatchesForAllFourSubstats(item, substat1, 0) &&
              returnMatchesForAllFourSubstats(item, substat2, 1)) ||
              (returnMatchesForAllFourSubstats(item, substat1, 1) &&
                returnMatchesForAllFourSubstats(item, substat2, 0)))) ||
          //substat3 with 1 roll & substat2 with 2 Rolls
          (requiredRollsByStageOfGame === 2 &&
            ((returnMatchesForAllFourSubstats(item, substat3, 0) &&
              returnMatchesForAllFourSubstats(item, substat2, 1)) ||
              (returnMatchesForAllFourSubstats(item, substat3, 1) &&
                returnMatchesForAllFourSubstats(item, substat2, 0)))) ||
          //substat3 with 1 roll & substat1 with 2 Rolls
          (requiredRollsByStageOfGame === 2 &&
            ((returnMatchesForAllFourSubstats(item, substat3, 0) &&
              returnMatchesForAllFourSubstats(item, substat1, 1)) ||
              (returnMatchesForAllFourSubstats(item, substat3, 1) &&
                returnMatchesForAllFourSubstats(item, substat1, 0)))) ||
          //Attack% with 1 roll & substat1 with 2 Rolls
          (requiredRollsByStageOfGame === 2 &&
            ((returnAttackPercentageStatsForAllFourSubstats(item, 0) &&
              returnMatchesForAllFourSubstats(item, substat1, 1)) ||
              (returnAttackPercentageStatsForAllFourSubstats(item, 1) &&
                returnMatchesForAllFourSubstats(item, substat1, 0)))) ||
          //Attack% with 1 roll & substat2 with 2 Rolls
          (requiredRollsByStageOfGame === 2 &&
            ((returnAttackPercentageStatsForAllFourSubstats(item, 0) &&
              returnMatchesForAllFourSubstats(item, substat2, 1)) ||
              (returnAttackPercentageStatsForAllFourSubstats(item, 1) &&
                returnMatchesForAllFourSubstats(item, substat2, 0)))) ||
          //Attack% with 1 roll & substat3 with 2 Rolls
          (requiredRollsByStageOfGame === 2 &&
            ((returnAttackPercentageStatsForAllFourSubstats(item, 0) &&
              returnMatchesForAllFourSubstats(item, substat3, 1)) ||
              (returnAttackPercentageStatsForAllFourSubstats(item, 1) &&
                returnMatchesForAllFourSubstats(item, substat3, 0)))) ||
          //substat1 with 1 roll & substat2 with 2 Rolls
          (requiredRollsByStageOfGame === 2 &&
            ((returnMatchesForAllFourSubstats(item, substat1, 0) &&
              returnMatchesForAllFourSubstats(item, substat2, 1)) ||
              (returnMatchesForAllFourSubstats(item, substat1, 1) &&
                returnMatchesForAllFourSubstats(item, substat2, 0)))) ||
          //substat3 with 1 roll & substat2 with 2 Rolls
          (requiredRollsByStageOfGame === 2 &&
            ((returnMatchesForAllFourSubstats(item, substat3, 0) &&
              returnMatchesForAllFourSubstats(item, substat2, 1)) ||
              (returnMatchesForAllFourSubstats(item, substat3, 1) &&
                returnMatchesForAllFourSubstats(item, substat2, 0)))) ||
          //substat3 with 1 roll & substat1 with 2 Rolls
          (requiredRollsByStageOfGame === 2 &&
            ((returnMatchesForAllFourSubstats(item, substat3, 0) &&
              returnMatchesForAllFourSubstats(item, substat1, 1)) ||
              (returnMatchesForAllFourSubstats(item, substat3, 1) &&
                returnMatchesForAllFourSubstats(item, substat1, 0)))) ||
          //Attack% with 1 roll & substat1 with 2 Rolls
          (requiredRollsByStageOfGame === 2 &&
            ((returnAttackPercentageStatsForAllFourSubstats(item, 0) &&
              returnMatchesForAllFourSubstats(item, substat1, 1)) ||
              (returnAttackPercentageStatsForAllFourSubstats(item, 1) &&
                returnMatchesForAllFourSubstats(item, substat1, 0)))) ||
          //Attack% with 1 roll & substat2 with 2 Rolls
          (requiredRollsByStageOfGame === 2 &&
            ((returnAttackPercentageStatsForAllFourSubstats(item, 0) &&
              returnMatchesForAllFourSubstats(item, substat2, 1)) ||
              (returnAttackPercentageStatsForAllFourSubstats(item, 1) &&
                returnMatchesForAllFourSubstats(item, substat2, 0)))) ||
          //Attack% with 1 roll & substat3 with 2 Rolls
          (requiredRollsByStageOfGame === 2 &&
            ((returnAttackPercentageStatsForAllFourSubstats(item, 0) &&
              returnMatchesForAllFourSubstats(item, substat3, 1)) ||
              (returnAttackPercentageStatsForAllFourSubstats(item, 1) &&
                returnMatchesForAllFourSubstats(item, substat3, 0)))) ||
          //requiredRollsByStageOfGame <= 1
          //substat1 with 2 Rolls & substat2 with 1 Roll
          (requiredRollsByStageOfGame <= 1 && returnMatchesForAllFourSubstats(item, substat1, 0)) ||
          returnMatchesForAllFourSubstats(item, substat2, 0) ||
          //substat3 with 2 Rolls & substat2 with 1 Roll
          (requiredRollsByStageOfGame <= 1 && returnMatchesForAllFourSubstats(item, substat3, 0)) ||
          returnMatchesForAllFourSubstats(item, substat2, 0) ||
          //substat3 with 2 Rolls & substat1 with 1 Roll
          (requiredRollsByStageOfGame <= 1 && returnMatchesForAllFourSubstats(item, substat3, 0)) ||
          returnMatchesForAllFourSubstats(item, substat1, 0) ||
          //Attack% with 2 Rolls & substat1 with 1 Roll
          (requiredRollsByStageOfGame <= 1 && returnAttackPercentageStatsForAllFourSubstats(item, 0)) ||
          returnMatchesForAllFourSubstats(item, substat1, 0) ||
          //Attack% with 2 Rolls & substat2 with 1 Roll
          (requiredRollsByStageOfGame <= 1 && returnAttackPercentageStatsForAllFourSubstats(item, 0)) ||
          returnMatchesForAllFourSubstats(item, substat2, 0) ||
          //Attack% with 2 Rolls & substat3 with 1 Roll
          (requiredRollsByStageOfGame <= 1 && returnAttackPercentageStatsForAllFourSubstats(item, 0)) ||
          returnMatchesForAllFourSubstats(item, substat3, 0) ||
          //substat1 with 2 Rolls & substat2 with 1 Roll
          (requiredRollsByStageOfGame <= 1 && returnMatchesForAllFourSubstats(item, substat1, 0)) ||
          returnMatchesForAllFourSubstats(item, substat2, 0) ||
          //substat3 with 2 Rolls & substat2 with 1 Roll
          (requiredRollsByStageOfGame <= 1 && returnMatchesForAllFourSubstats(item, substat3, 0)) ||
          returnMatchesForAllFourSubstats(item, substat2, 0) ||
          //substat3 with 2 Rolls & substat1 with 1 Roll
          (requiredRollsByStageOfGame <= 1 && returnMatchesForAllFourSubstats(item, substat3, 0)) ||
          returnMatchesForAllFourSubstats(item, substat1, 0) ||
          //Attack% with 2 Rolls & substat1 with 1 Roll
          (requiredRollsByStageOfGame <= 1 && returnAttackPercentageStatsForAllFourSubstats(item, 0)) ||
          returnMatchesForAllFourSubstats(item, substat1, 0) ||
          //Attack% with 2 Rolls & substat2 with 1 Roll
          (requiredRollsByStageOfGame <= 1 && returnAttackPercentageStatsForAllFourSubstats(item, 0)) ||
          returnMatchesForAllFourSubstats(item, substat2, 0) ||
          //Attack% with 2 Rolls & substat3 with 1 Roll
          (requiredRollsByStageOfGame <= 1 && returnAttackPercentageStatsForAllFourSubstats(item, 0)) ||
          returnMatchesForAllFourSubstats(item, substat3, 0) ||
          //substat1 with 1 roll & substat2 with 2 Rolls
          (requiredRollsByStageOfGame <= 1 && returnMatchesForAllFourSubstats(item, substat1, 0)) ||
          returnMatchesForAllFourSubstats(item, substat2, 0) ||
          //substat3 with 1 roll & substat2 with 2 Rolls
          (requiredRollsByStageOfGame <= 1 && returnMatchesForAllFourSubstats(item, substat3, 0)) ||
          returnMatchesForAllFourSubstats(item, substat2, 0) ||
          //substat3 with 1 roll & substat1 with 2 Rolls
          (requiredRollsByStageOfGame <= 1 && returnMatchesForAllFourSubstats(item, substat3, 0)) ||
          returnMatchesForAllFourSubstats(item, substat1, 0) ||
          //Attack% with 1 roll & substat1 with 2 Rolls
          (requiredRollsByStageOfGame <= 1 && returnAttackPercentageStatsForAllFourSubstats(item, 0)) ||
          returnMatchesForAllFourSubstats(item, substat1, 0) ||
          //Attack% with 1 roll & substat2 with 2 Rolls
          (requiredRollsByStageOfGame <= 1 && returnAttackPercentageStatsForAllFourSubstats(item, 0)) ||
          returnMatchesForAllFourSubstats(item, substat2, 0) ||
          //Attack% with 1 roll & substat3 with 2 Rolls
          (requiredRollsByStageOfGame <= 1 && returnAttackPercentageStatsForAllFourSubstats(item, 0)) ||
          returnMatchesForAllFourSubstats(item, substat3, 0) ||
          //substat1 with 1 roll & substat2 with 2 Rolls
          (requiredRollsByStageOfGame <= 1 && returnMatchesForAllFourSubstats(item, substat1, 0)) ||
          returnMatchesForAllFourSubstats(item, substat2, 0) ||
          //substat3 with 1 roll & substat2 with 2 Rolls
          (requiredRollsByStageOfGame <= 1 && returnMatchesForAllFourSubstats(item, substat3, 0)) ||
          returnMatchesForAllFourSubstats(item, substat2, 0) ||
          //substat3 with 1 roll & substat1 with 2 Rolls
          (requiredRollsByStageOfGame <= 1 && returnMatchesForAllFourSubstats(item, substat3, 0)) ||
          returnMatchesForAllFourSubstats(item, substat1, 0) ||
          //Attack% with 1 roll & substat1 with 2 Rolls
          (requiredRollsByStageOfGame <= 1 && returnAttackPercentageStatsForAllFourSubstats(item, 0)) ||
          returnMatchesForAllFourSubstats(item, substat1, 0) ||
          //Attack% with 1 roll & substat2 with 2 Rolls
          (requiredRollsByStageOfGame <= 1 && returnAttackPercentageStatsForAllFourSubstats(item, 0)) ||
          returnMatchesForAllFourSubstats(item, substat2, 0) ||
          //Attack% with 1 roll & substat3 with 2 Rolls
          (requiredRollsByStageOfGame <= 1 && returnAttackPercentageStatsForAllFourSubstats(item, 0)) ||
          returnMatchesForAllFourSubstats(item, substat3, 0));
    }
  } else if (
    item.level >= 12 &&
    item.level <= 16 &&
    //Is Between level 12-15 //Is Legendary //Any items with substat1 or substat2 with atleast 2 rolls between the two
    //Is Legendary or Mythical
    (item.rarity === 'legendary' || item.rarity === 'mythical') &&
    //Any items with substat1 or substat2 with atleast 2 rolls between the two
    //substat1 with 2 rolls
    (returnMatchesForAllFourSubstats(item, substat1, requiredRollsByStageOfGame) ||
      //substat2 with 3 rolls
      returnMatchesForAllFourSubstats(item, substat2, requiredRollsByStageOfGame) ||
      //requiredRollsByStageOfGame === 3
      (requiredRollsByStageOfGame === 3 && returnMatchesForAllFourSubstats(item, substat1, 2)) ||
      //substat2 with 2 rolls
      (requiredRollsByStageOfGame === 3 && returnMatchesForAllFourSubstats(item, substat2, 2)) ||
      //substat1 with 1 roll & substat2 with 1 Roll
      (requiredRollsByStageOfGame === 3 &&
        returnMatchesForAllFourSubstats(item, substat1, 1) &&
        returnMatchesForAllFourSubstats(item, substat2, 1)) ||
      //substat1 with 2 rolls
      //requiredRollsByStageOfGame === 2
      (requiredRollsByStageOfGame === 2 && returnMatchesForAllFourSubstats(item, substat1, 1)) ||
      //substat2 with 2 rolls
      (requiredRollsByStageOfGame === 2 && returnMatchesForAllFourSubstats(item, substat2, 1)) ||
      //substat1 with 1 roll & substat2 with 1 Roll
      (requiredRollsByStageOfGame === 2 &&
        returnMatchesForAllFourSubstats(item, substat1, 0) &&
        returnMatchesForAllFourSubstats(item, substat2, 0)) ||
      //substat1 with 2 rolls
      //requiredRollsByStageOfGame <= 1
      (requiredRollsByStageOfGame <= 1 && returnMatchesForAllFourSubstats(item, substat1, 0)) ||
      //substat2 with 2 rolls
      (requiredRollsByStageOfGame <= 1 && returnMatchesForAllFourSubstats(item, substat2, 0)) ||
      //substat1 with 1 roll & substat2 with 1 Roll
      (requiredRollsByStageOfGame <= 1 &&
        returnMatchesForAllFourSubstats(item, substat1, 0) &&
        returnMatchesForAllFourSubstats(item, substat2, 0)))
  ) {
    return item;
  }
}

export function returnBetween12And15EpicArtifactsWithSpecifiedSubstats(
  item: ArtifactItem,
  substat1: string,
  substat2?: string,
  substat3?: string,
  substat4?: string
) {
  const allSubstats = [];
  allSubstats.push(substat1);
  if (substat2) {
    allSubstats.push(substat2);
  }
  if (substat3) {
    allSubstats.push(substat3);
  }
  if (substat4) {
    allSubstats.push(substat4);
  }
  //console.log(`allSubstats`, allSubstats);

  if (allSubstats.includes('HP%') || allSubstats.includes('DEF%') || allSubstats.includes('ATK%')) {
    if (allSubstats.includes('ATK% or DEF% or HP%')) {
      //Is Between level 12-15
      //Is Between level 12-15
      item.level >= 12 &&
        item.level <= 16 &&
        //Is Epic
        item.rarity === 'epic' &&
        //Any items with substat1 or substat2 or (ATK% or DEF% or HP%) with atleast 3 rolls between the three
        //substat1 with 3 rolls
        (returnMatchesForAllFourSubstats(item, substat1, requiredRollsByStageOfGame) ||
          //substat2 with 3 rolls
          returnMatchesForAllFourSubstats(item, substat2, requiredRollsByStageOfGame) ||
          //ATK% or DEF% or HP% with 3 rolls
          returnMainPercentageStatsForAllFourSubstats(item, requiredRollsByStageOfGame) ||
          //Any items with substat1 or ('ATK% or DEF% or HP%') or substat2 with atleast 2 rolls between the three
          //substat1 with 3 rolls
          returnMatchesForAllFourSubstats(item, substat1, requiredRollsByStageOfGame) ||
          //('ATK% or DEF% or HP%') with 3 rolls
          returnMainPercentageStatsForAllFourSubstats(item, requiredRollsByStageOfGame) ||
          //substat2 with 3 rolls
          returnMatchesForAllFourSubstats(item, substat2, requiredRollsByStageOfGame) ||
          //substat3 with 3 rolls
          returnMatchesForAllFourSubstats(item, substat3, requiredRollsByStageOfGame) ||
          //requiredRollsByStageOfGame === 3
          //substat1 with 2 Rolls & substat2 with 1 Roll
          (requiredRollsByStageOfGame === 3 &&
            returnMatchesForAllFourSubstats(item, substat1, 2) &&
            returnMatchesForAllFourSubstats(item, substat2, 1)) ||
          //substat3 with 2 Rolls & substat2 with 1 Roll
          (requiredRollsByStageOfGame === 3 &&
            returnMatchesForAllFourSubstats(item, substat3, 2) &&
            returnMatchesForAllFourSubstats(item, substat2, 1)) ||
          //substat3 with 2 Rolls & substat1 with 1 Roll
          (requiredRollsByStageOfGame === 3 &&
            returnMatchesForAllFourSubstats(item, substat3, 2) &&
            returnMatchesForAllFourSubstats(item, substat1, 1)) ||
          //('ATK% or DEF% or HP%') with 2 Rolls & substat1 with 1 Roll
          (requiredRollsByStageOfGame === 3 &&
            returnMainPercentageStatsForAllFourSubstats(item, 2) &&
            returnMatchesForAllFourSubstats(item, substat1, 1)) ||
          //('ATK% or DEF% or HP%') with 2 Rolls & substat2 with 1 Roll
          (requiredRollsByStageOfGame === 3 &&
            returnMainPercentageStatsForAllFourSubstats(item, 2) &&
            returnMatchesForAllFourSubstats(item, substat2, 1)) ||
          //('ATK% or DEF% or HP%') with 2 Rolls & substat3 with 1 Roll
          (requiredRollsByStageOfGame === 3 &&
            returnMainPercentageStatsForAllFourSubstats(item, 2) &&
            returnMatchesForAllFourSubstats(item, substat3, 1)) ||
          //substat1 with 2 Rolls & substat2 with 1 Roll
          (requiredRollsByStageOfGame === 3 &&
            returnMatchesForAllFourSubstats(item, substat1, 2) &&
            returnMatchesForAllFourSubstats(item, substat2, 1)) ||
          //substat3 with 2 Rolls & substat2 with 1 Roll
          (requiredRollsByStageOfGame === 3 &&
            returnMatchesForAllFourSubstats(item, substat3, 2) &&
            returnMatchesForAllFourSubstats(item, substat2, 1)) ||
          //substat3 with 2 Rolls & substat1 with 1 Roll
          (requiredRollsByStageOfGame === 3 &&
            returnMatchesForAllFourSubstats(item, substat3, 2) &&
            returnMatchesForAllFourSubstats(item, substat1, 1)) ||
          //('ATK% or DEF% or HP%') with 2 Rolls & substat1 with 1 Roll
          (requiredRollsByStageOfGame === 3 &&
            returnMainPercentageStatsForAllFourSubstats(item, 2) &&
            returnMatchesForAllFourSubstats(item, substat1, 1)) ||
          //('ATK% or DEF% or HP%') with 2 Rolls & substat2 with 1 Roll
          (requiredRollsByStageOfGame === 3 &&
            returnMainPercentageStatsForAllFourSubstats(item, 2) &&
            returnMatchesForAllFourSubstats(item, substat2, 1)) ||
          //('ATK% or DEF% or HP%') with 2 Rolls & substat3 with 1 Roll
          (requiredRollsByStageOfGame === 3 &&
            returnMainPercentageStatsForAllFourSubstats(item, 2) &&
            returnMatchesForAllFourSubstats(item, substat3, 1)) ||
          //substat1 with 1 roll & substat2 with 2 Rolls
          (requiredRollsByStageOfGame === 3 &&
            returnMatchesForAllFourSubstats(item, substat1, 1) &&
            returnMatchesForAllFourSubstats(item, substat2, 2)) ||
          //substat3 with 1 roll & substat2 with 2 Rolls
          (requiredRollsByStageOfGame === 3 &&
            returnMatchesForAllFourSubstats(item, substat3, 1) &&
            returnMatchesForAllFourSubstats(item, substat2, 2)) ||
          //substat3 with 1 roll & substat1 with 2 Rolls
          (requiredRollsByStageOfGame === 3 &&
            returnMatchesForAllFourSubstats(item, substat3, 1) &&
            returnMatchesForAllFourSubstats(item, substat1, 2)) ||
          //('ATK% or DEF% or HP%') with 1 roll & substat1 with 2 Rolls
          (requiredRollsByStageOfGame === 3 &&
            returnMainPercentageStatsForAllFourSubstats(item, 1) &&
            returnMatchesForAllFourSubstats(item, substat1, 2)) ||
          //('ATK% or DEF% or HP%') with 1 roll & substat2 with 2 Rolls
          (requiredRollsByStageOfGame === 3 &&
            returnMainPercentageStatsForAllFourSubstats(item, 1) &&
            returnMatchesForAllFourSubstats(item, substat2, 2)) ||
          //('ATK% or DEF% or HP%') with 1 roll & substat3 with 2 Rolls
          (requiredRollsByStageOfGame === 3 &&
            returnMainPercentageStatsForAllFourSubstats(item, 1) &&
            returnMatchesForAllFourSubstats(item, substat3, 2)) ||
          //substat1 with 1 roll & substat2 with 2 Rolls
          (requiredRollsByStageOfGame === 3 &&
            returnMatchesForAllFourSubstats(item, substat1, 1) &&
            returnMatchesForAllFourSubstats(item, substat2, 2)) ||
          //substat3 with 1 roll & substat2 with 2 Rolls
          (requiredRollsByStageOfGame === 3 &&
            returnMatchesForAllFourSubstats(item, substat3, 1) &&
            returnMatchesForAllFourSubstats(item, substat2, 2)) ||
          //substat3 with 1 roll & substat1 with 2 Rolls
          (requiredRollsByStageOfGame === 3 &&
            returnMatchesForAllFourSubstats(item, substat3, 1) &&
            returnMatchesForAllFourSubstats(item, substat1, 2)) ||
          //('ATK% or DEF% or HP%') with 1 roll & substat1 with 2 Rolls
          (requiredRollsByStageOfGame === 3 &&
            returnMainPercentageStatsForAllFourSubstats(item, 1) &&
            returnMatchesForAllFourSubstats(item, substat1, 2)) ||
          //('ATK% or DEF% or HP%') with 1 roll & substat2 with 2 Rolls
          (requiredRollsByStageOfGame === 3 &&
            returnMainPercentageStatsForAllFourSubstats(item, 1) &&
            returnMatchesForAllFourSubstats(item, substat2, 2)) ||
          //('ATK% or DEF% or HP%') with 1 roll & substat3 with 2 Rolls
          (requiredRollsByStageOfGame === 3 &&
            returnMainPercentageStatsForAllFourSubstats(item, 1) &&
            returnMatchesForAllFourSubstats(item, substat3, 2)) ||
          //requiredRollsByStageOfGame === 2
          //substat1 with 2 Rolls & substat2 with 1 Roll
          (requiredRollsByStageOfGame === 2 &&
            returnMatchesForAllFourSubstats(item, substat1, 1) &&
            returnMatchesForAllFourSubstats(item, substat2, 1)) ||
          //substat3 with 2 Rolls & substat2 with 1 Roll
          (requiredRollsByStageOfGame === 2 &&
            returnMatchesForAllFourSubstats(item, substat3, 1) &&
            returnMatchesForAllFourSubstats(item, substat2, 1)) ||
          //substat3 with 2 Rolls & substat1 with 1 Roll
          (requiredRollsByStageOfGame === 2 &&
            returnMatchesForAllFourSubstats(item, substat3, 1) &&
            returnMatchesForAllFourSubstats(item, substat1, 1)) ||
          //('ATK% or DEF% or HP%') with 2 Rolls & substat1 with 1 Roll
          (requiredRollsByStageOfGame === 2 &&
            returnMainPercentageStatsForAllFourSubstats(item, 1) &&
            returnMatchesForAllFourSubstats(item, substat1, 1)) ||
          //('ATK% or DEF% or HP%') with 2 Rolls & substat2 with 1 Roll
          (requiredRollsByStageOfGame === 2 &&
            returnMainPercentageStatsForAllFourSubstats(item, 1) &&
            returnMatchesForAllFourSubstats(item, substat2, 1)) ||
          //('ATK% or DEF% or HP%') with 2 Rolls & substat3 with 1 Roll
          (requiredRollsByStageOfGame === 2 &&
            returnMainPercentageStatsForAllFourSubstats(item, 1) &&
            returnMatchesForAllFourSubstats(item, substat3, 1)) ||
          //substat1 with 2 Rolls & substat2 with 1 Roll
          (requiredRollsByStageOfGame === 2 &&
            returnMatchesForAllFourSubstats(item, substat1, 1) &&
            returnMatchesForAllFourSubstats(item, substat2, 1)) ||
          //substat3 with 2 Rolls & substat2 with 1 Roll
          (requiredRollsByStageOfGame === 2 &&
            returnMatchesForAllFourSubstats(item, substat3, 1) &&
            returnMatchesForAllFourSubstats(item, substat2, 1)) ||
          //substat3 with 2 Rolls & substat1 with 1 Roll
          (requiredRollsByStageOfGame === 2 &&
            returnMatchesForAllFourSubstats(item, substat3, 1) &&
            returnMatchesForAllFourSubstats(item, substat1, 1)) ||
          //('ATK% or DEF% or HP%') with 2 Rolls & substat1 with 1 Roll
          (requiredRollsByStageOfGame === 2 &&
            returnMainPercentageStatsForAllFourSubstats(item, 1) &&
            returnMatchesForAllFourSubstats(item, substat1, 1)) ||
          //('ATK% or DEF% or HP%') with 2 Rolls & substat2 with 1 Roll
          (requiredRollsByStageOfGame === 2 &&
            returnMainPercentageStatsForAllFourSubstats(item, 1) &&
            returnMatchesForAllFourSubstats(item, substat2, 1)) ||
          //('ATK% or DEF% or HP%') with 2 Rolls & substat3 with 1 Roll
          (requiredRollsByStageOfGame === 2 &&
            returnMainPercentageStatsForAllFourSubstats(item, 1) &&
            returnMatchesForAllFourSubstats(item, substat3, 1)) ||
          //substat1 with 1 roll & substat2 with 2 Rolls
          (requiredRollsByStageOfGame === 2 &&
            returnMatchesForAllFourSubstats(item, substat1, 1) &&
            returnMatchesForAllFourSubstats(item, substat2, 1)) ||
          //substat3 with 1 roll & substat2 with 2 Rolls
          (requiredRollsByStageOfGame === 2 &&
            returnMatchesForAllFourSubstats(item, substat3, 1) &&
            returnMatchesForAllFourSubstats(item, substat2, 1)) ||
          //substat3 with 1 roll & substat1 with 2 Rolls
          (requiredRollsByStageOfGame === 2 &&
            returnMatchesForAllFourSubstats(item, substat3, 1) &&
            returnMatchesForAllFourSubstats(item, substat1, 1)) ||
          //('ATK% or DEF% or HP%') with 1 roll & substat1 with 2 Rolls
          (requiredRollsByStageOfGame === 2 &&
            returnMainPercentageStatsForAllFourSubstats(item, 1) &&
            returnMatchesForAllFourSubstats(item, substat1, 1)) ||
          //('ATK% or DEF% or HP%') with 1 roll & substat2 with 2 Rolls
          (requiredRollsByStageOfGame === 2 &&
            returnMainPercentageStatsForAllFourSubstats(item, 1) &&
            returnMatchesForAllFourSubstats(item, substat2, 1)) ||
          //('ATK% or DEF% or HP%') with 1 roll & substat3 with 2 Rolls
          (requiredRollsByStageOfGame === 2 &&
            returnMainPercentageStatsForAllFourSubstats(item, 1) &&
            returnMatchesForAllFourSubstats(item, substat3, 1)) ||
          //substat1 with 1 roll & substat2 with 2 Rolls
          (requiredRollsByStageOfGame === 2 &&
            returnMatchesForAllFourSubstats(item, substat1, 1) &&
            returnMatchesForAllFourSubstats(item, substat2, 1)) ||
          //substat3 with 1 roll & substat2 with 2 Rolls
          (requiredRollsByStageOfGame === 2 &&
            returnMatchesForAllFourSubstats(item, substat3, 1) &&
            returnMatchesForAllFourSubstats(item, substat2, 1)) ||
          //substat3 with 1 roll & substat1 with 2 Rolls
          (requiredRollsByStageOfGame === 2 &&
            returnMatchesForAllFourSubstats(item, substat3, 1) &&
            returnMatchesForAllFourSubstats(item, substat1, 1)) ||
          //('ATK% or DEF% or HP%') with 1 roll & substat1 with 2 Rolls
          (requiredRollsByStageOfGame === 2 &&
            returnMainPercentageStatsForAllFourSubstats(item, 1) &&
            returnMatchesForAllFourSubstats(item, substat1, 1)) ||
          //('ATK% or DEF% or HP%') with 1 roll & substat2 with 2 Rolls
          (requiredRollsByStageOfGame === 2 &&
            returnMainPercentageStatsForAllFourSubstats(item, 1) &&
            returnMatchesForAllFourSubstats(item, substat2, 1)) ||
          //('ATK% or DEF% or HP%') with 1 roll & substat3 with 2 Rolls
          (requiredRollsByStageOfGame === 2 &&
            returnMainPercentageStatsForAllFourSubstats(item, 1) &&
            returnMatchesForAllFourSubstats(item, substat3, 1)) ||
          //requiredRollsByStageOfGame === 1
          //substat1 with 2 Rolls & substat2 with 1 Roll
          (requiredRollsByStageOfGame === 1 && returnMatchesForAllFourSubstats(item, substat1, 1)) ||
          returnMatchesForAllFourSubstats(item, substat2, 1) ||
          //substat3 with 2 Rolls & substat2 with 1 Roll
          (requiredRollsByStageOfGame === 1 && returnMatchesForAllFourSubstats(item, substat3, 1)) ||
          returnMatchesForAllFourSubstats(item, substat2, 1) ||
          //substat3 with 2 Rolls & substat1 with 1 Roll
          (requiredRollsByStageOfGame === 1 && returnMatchesForAllFourSubstats(item, substat3, 1)) ||
          returnMatchesForAllFourSubstats(item, substat1, 1) ||
          //('ATK% or DEF% or HP%') with 2 Rolls & substat1 with 1 Roll
          (requiredRollsByStageOfGame === 1 && returnMainPercentageStatsForAllFourSubstats(item, 1)) ||
          returnMatchesForAllFourSubstats(item, substat1, 1) ||
          //('ATK% or DEF% or HP%') with 2 Rolls & substat2 with 1 Roll
          (requiredRollsByStageOfGame === 1 && returnMainPercentageStatsForAllFourSubstats(item, 1)) ||
          returnMatchesForAllFourSubstats(item, substat2, 1) ||
          //('ATK% or DEF% or HP%') with 2 Rolls & substat3 with 1 Roll
          (requiredRollsByStageOfGame === 1 && returnMainPercentageStatsForAllFourSubstats(item, 1)) ||
          returnMatchesForAllFourSubstats(item, substat3, 1) ||
          //substat1 with 2 Rolls & substat2 with 1 Roll
          (requiredRollsByStageOfGame === 1 && returnMatchesForAllFourSubstats(item, substat1, 1)) ||
          returnMatchesForAllFourSubstats(item, substat2, 1) ||
          //substat3 with 2 Rolls & substat2 with 1 Roll
          (requiredRollsByStageOfGame === 1 && returnMatchesForAllFourSubstats(item, substat3, 1)) ||
          returnMatchesForAllFourSubstats(item, substat2, 1) ||
          //substat3 with 2 Rolls & substat1 with 1 Roll
          (requiredRollsByStageOfGame === 1 && returnMatchesForAllFourSubstats(item, substat3, 1)) ||
          returnMatchesForAllFourSubstats(item, substat1, 1) ||
          //('ATK% or DEF% or HP%') with 2 Rolls & substat1 with 1 Roll
          (requiredRollsByStageOfGame === 1 && returnMainPercentageStatsForAllFourSubstats(item, 1)) ||
          returnMatchesForAllFourSubstats(item, substat1, 1) ||
          //('ATK% or DEF% or HP%') with 2 Rolls & substat2 with 1 Roll
          (requiredRollsByStageOfGame === 1 && returnMainPercentageStatsForAllFourSubstats(item, 1)) ||
          returnMatchesForAllFourSubstats(item, substat2, 1) ||
          //('ATK% or DEF% or HP%') with 2 Rolls & substat3 with 1 Roll
          (requiredRollsByStageOfGame === 1 && returnMainPercentageStatsForAllFourSubstats(item, 1)) ||
          returnMatchesForAllFourSubstats(item, substat3, 1) ||
          //substat1 with 1 roll & substat2 with 2 Rolls
          (requiredRollsByStageOfGame === 1 && returnMatchesForAllFourSubstats(item, substat1, 1)) ||
          returnMatchesForAllFourSubstats(item, substat2, 1) ||
          //substat3 with 1 roll & substat2 with 2 Rolls
          (requiredRollsByStageOfGame === 1 && returnMatchesForAllFourSubstats(item, substat3, 1)) ||
          returnMatchesForAllFourSubstats(item, substat2, 1) ||
          //substat3 with 1 roll & substat1 with 2 Rolls
          (requiredRollsByStageOfGame === 1 && returnMatchesForAllFourSubstats(item, substat3, 1)) ||
          returnMatchesForAllFourSubstats(item, substat1, 1) ||
          //('ATK% or DEF% or HP%') with 1 roll & substat1 with 2 Rolls
          (requiredRollsByStageOfGame === 1 && returnMainPercentageStatsForAllFourSubstats(item, 1)) ||
          returnMatchesForAllFourSubstats(item, substat1, 1) ||
          //('ATK% or DEF% or HP%') with 1 roll & substat2 with 2 Rolls
          (requiredRollsByStageOfGame === 1 && returnMainPercentageStatsForAllFourSubstats(item, 1)) ||
          returnMatchesForAllFourSubstats(item, substat2, 1) ||
          //('ATK% or DEF% or HP%') with 1 roll & substat3 with 2 Rolls
          (requiredRollsByStageOfGame === 1 && returnMainPercentageStatsForAllFourSubstats(item, 1)) ||
          returnMatchesForAllFourSubstats(item, substat3, 1) ||
          //substat1 with 1 roll & substat2 with 2 Rolls
          (requiredRollsByStageOfGame === 1 && returnMatchesForAllFourSubstats(item, substat1, 1)) ||
          returnMatchesForAllFourSubstats(item, substat2, 1) ||
          //substat3 with 1 roll & substat2 with 2 Rolls
          (requiredRollsByStageOfGame === 1 && returnMatchesForAllFourSubstats(item, substat3, 1)) ||
          returnMatchesForAllFourSubstats(item, substat2, 1) ||
          //substat3 with 1 roll & substat1 with 2 Rolls
          (requiredRollsByStageOfGame === 1 && returnMatchesForAllFourSubstats(item, substat3, 1)) ||
          returnMatchesForAllFourSubstats(item, substat1, 1) ||
          //('ATK% or DEF% or HP%') with 1 roll & substat1 with 2 Rolls
          (requiredRollsByStageOfGame === 1 && returnMainPercentageStatsForAllFourSubstats(item, 1)) ||
          returnMatchesForAllFourSubstats(item, substat1, 1) ||
          //('ATK% or DEF% or HP%') with 1 roll & substat2 with 2 Rolls
          (requiredRollsByStageOfGame === 1 && returnMainPercentageStatsForAllFourSubstats(item, 1)) ||
          returnMatchesForAllFourSubstats(item, substat2, 1) ||
          //('ATK% or DEF% or HP%') with 1 roll & substat3 with 2 Rolls
          (requiredRollsByStageOfGame === 1 && returnMainPercentageStatsForAllFourSubstats(item, 1)) ||
          returnMatchesForAllFourSubstats(item, substat3, 1) ||
          //requiredRollsByStageOfGame === 0
          //substat1 with 2 Rolls & substat2 with 1 Roll
          (requiredRollsByStageOfGame === 0 && returnMatchesForAllFourSubstats(item, substat1, 0)) ||
          returnMatchesForAllFourSubstats(item, substat2, 0) ||
          //substat3 with 2 Rolls & substat2 with 1 Roll
          (requiredRollsByStageOfGame === 0 && returnMatchesForAllFourSubstats(item, substat3, 0)) ||
          returnMatchesForAllFourSubstats(item, substat2, 0) ||
          //substat3 with 2 Rolls & substat1 with 1 Roll
          (requiredRollsByStageOfGame === 0 && returnMatchesForAllFourSubstats(item, substat3, 0)) ||
          returnMatchesForAllFourSubstats(item, substat1, 0) ||
          //('ATK% or DEF% or HP%') with 2 Rolls & substat1 with 1 Roll
          (requiredRollsByStageOfGame === 0 && returnMainPercentageStatsForAllFourSubstats(item, 0)) ||
          returnMatchesForAllFourSubstats(item, substat1, 0) ||
          //('ATK% or DEF% or HP%') with 2 Rolls & substat2 with 1 Roll
          (requiredRollsByStageOfGame === 0 && returnMainPercentageStatsForAllFourSubstats(item, 0)) ||
          returnMatchesForAllFourSubstats(item, substat2, 0) ||
          //('ATK% or DEF% or HP%') with 2 Rolls & substat3 with 1 Roll
          (requiredRollsByStageOfGame === 0 && returnMainPercentageStatsForAllFourSubstats(item, 0)) ||
          returnMatchesForAllFourSubstats(item, substat3, 0) ||
          //substat1 with 2 Rolls & substat2 with 1 Roll
          (requiredRollsByStageOfGame === 0 && returnMatchesForAllFourSubstats(item, substat1, 0)) ||
          returnMatchesForAllFourSubstats(item, substat2, 0) ||
          //substat3 with 2 Rolls & substat2 with 1 Roll
          (requiredRollsByStageOfGame === 0 && returnMatchesForAllFourSubstats(item, substat3, 0)) ||
          returnMatchesForAllFourSubstats(item, substat2, 0) ||
          //substat3 with 2 Rolls & substat1 with 1 Roll
          (requiredRollsByStageOfGame === 0 && returnMatchesForAllFourSubstats(item, substat3, 0)) ||
          returnMatchesForAllFourSubstats(item, substat1, 0) ||
          //('ATK% or DEF% or HP%') with 2 Rolls & substat1 with 1 Roll
          (requiredRollsByStageOfGame === 0 && returnMainPercentageStatsForAllFourSubstats(item, 0)) ||
          returnMatchesForAllFourSubstats(item, substat1, 0) ||
          //('ATK% or DEF% or HP%') with 2 Rolls & substat2 with 1 Roll
          (requiredRollsByStageOfGame === 0 && returnMainPercentageStatsForAllFourSubstats(item, 0)) ||
          returnMatchesForAllFourSubstats(item, substat2, 0) ||
          //('ATK% or DEF% or HP%') with 2 Rolls & substat3 with 1 Roll
          (requiredRollsByStageOfGame === 0 && returnMainPercentageStatsForAllFourSubstats(item, 0)) ||
          returnMatchesForAllFourSubstats(item, substat3, 0) ||
          //substat1 with 1 roll & substat2 with 2 Rolls
          (requiredRollsByStageOfGame === 0 && returnMatchesForAllFourSubstats(item, substat1, 0)) ||
          returnMatchesForAllFourSubstats(item, substat2, 0) ||
          //substat3 with 1 roll & substat2 with 2 Rolls
          (requiredRollsByStageOfGame === 0 && returnMatchesForAllFourSubstats(item, substat3, 0)) ||
          returnMatchesForAllFourSubstats(item, substat2, 0) ||
          //substat3 with 1 roll & substat1 with 2 Rolls
          (requiredRollsByStageOfGame === 0 && returnMatchesForAllFourSubstats(item, substat3, 0)) ||
          returnMatchesForAllFourSubstats(item, substat1, 0) ||
          //('ATK% or DEF% or HP%') with 1 roll & substat1 with 2 Rolls
          (requiredRollsByStageOfGame === 0 && returnMainPercentageStatsForAllFourSubstats(item, 0)) ||
          returnMatchesForAllFourSubstats(item, substat1, 0) ||
          //('ATK% or DEF% or HP%') with 1 roll & substat2 with 2 Rolls
          (requiredRollsByStageOfGame === 0 && returnMainPercentageStatsForAllFourSubstats(item, 0)) ||
          returnMatchesForAllFourSubstats(item, substat2, 0) ||
          //('ATK% or DEF% or HP%') with 1 roll & substat3 with 2 Rolls
          (requiredRollsByStageOfGame === 0 && returnMainPercentageStatsForAllFourSubstats(item, 0)) ||
          returnMatchesForAllFourSubstats(item, substat3, 0) ||
          //substat1 with 1 roll & substat2 with 2 Rolls
          (requiredRollsByStageOfGame === 0 && returnMatchesForAllFourSubstats(item, substat1, 0)) ||
          returnMatchesForAllFourSubstats(item, substat2, 0) ||
          //substat3 with 1 roll & substat2 with 2 Rolls
          (requiredRollsByStageOfGame === 0 && returnMatchesForAllFourSubstats(item, substat3, 0)) ||
          returnMatchesForAllFourSubstats(item, substat2, 0) ||
          //substat3 with 1 roll & substat1 with 2 Rolls
          (requiredRollsByStageOfGame === 0 && returnMatchesForAllFourSubstats(item, substat3, 0)) ||
          returnMatchesForAllFourSubstats(item, substat1, 0) ||
          //('ATK% or DEF% or HP%') with 1 roll & substat1 with 2 Rolls
          (requiredRollsByStageOfGame === 0 && returnMainPercentageStatsForAllFourSubstats(item, 0)) ||
          returnMatchesForAllFourSubstats(item, substat1, 0) ||
          //('ATK% or DEF% or HP%') with 1 roll & substat2 with 2 Rolls
          (requiredRollsByStageOfGame === 0 && returnMainPercentageStatsForAllFourSubstats(item, 0)) ||
          returnMatchesForAllFourSubstats(item, substat2, 0) ||
          //('ATK% or DEF% or HP%') with 1 roll & substat3 with 2 Rolls
          (requiredRollsByStageOfGame === 0 && returnMainPercentageStatsForAllFourSubstats(item, 0)) ||
          returnMatchesForAllFourSubstats(item, substat3, 0));
    }
    if (allSubstats.includes('HP%') && allSubstats.includes('DEF%')) {
      //console.log(`Calculating for HP% & DEF% substats...`);
      if (
        item.level >= 12 &&
        item.level <= 16 &&
        //Is Epic
        item.rarity === 'epic' &&
        //Any items with substat1 or Resistance with atleast 3 rolls between the three
        //substat1 with 3 rolls
        (returnMatchesForAllFourSubstats(item, substat1, requiredRollsByStageOfGame) ||
          //substat2 with 3 rolls
          returnMatchesForAllFourSubstats(item, substat2, requiredRollsByStageOfGame) ||
          //HP% Or DEF% with 3 rolls
          returnTankMainPercentageStatsForAllFourSubstats(item, requiredRollsByStageOfGame) ||
          //requiredRollsByStageOfGame === 3
          //substat1 with 2 roll substat2 with 1 Roll
          (requiredRollsByStageOfGame === 3 &&
            returnMatchesForAllFourSubstats(item, substat1, 2) &&
            returnMatchesForAllFourSubstats(item, substat2, 1)) ||
          //substat1 with 1 roll substat2 with 2 Roll
          (requiredRollsByStageOfGame === 3 &&
            returnMatchesForAllFourSubstats(item, substat1, 1) &&
            returnMatchesForAllFourSubstats(item, substat2, 2)) ||
          //substat1 with 2 roll HP% Or DEF% with 1 Roll
          (requiredRollsByStageOfGame === 3 &&
            returnMatchesForAllFourSubstats(item, substat1, 2) &&
            returnTankMainPercentageStatsForAllFourSubstats(item, 1)) ||
          //substat2 with 1 roll HP% Or DEF% with 2 Roll
          (requiredRollsByStageOfGame === 3 &&
            returnMatchesForAllFourSubstats(item, substat2, 1) &&
            returnTankMainPercentageStatsForAllFourSubstats(item, 2)) ||
          //substat2 with 2 roll HP% Or DEF% with 1 Roll
          (requiredRollsByStageOfGame === 3 &&
            returnMatchesForAllFourSubstats(item, substat2, 2) &&
            returnTankMainPercentageStatsForAllFourSubstats(item, 1)) ||
          //substat2 with 1 roll HP% Or DEF% with 2 Roll
          (requiredRollsByStageOfGame === 3 &&
            returnMatchesForAllFourSubstats(item, substat2, 1) &&
            returnTankMainPercentageStatsForAllFourSubstats(item, 2)) ||
          //requiredRollsByStageOfGame === 2
          //substat1 with 2 roll substat2 with 1 Roll
          (requiredRollsByStageOfGame === 2 &&
            returnMatchesForAllFourSubstats(item, substat1, 1) &&
            returnMatchesForAllFourSubstats(item, substat2, 1)) ||
          //substat1 with 1 roll substat2 with 2 Roll
          (requiredRollsByStageOfGame === 2 &&
            returnMatchesForAllFourSubstats(item, substat1, 1) &&
            returnMatchesForAllFourSubstats(item, substat2, 1)) ||
          //substat1 with 2 roll HP% Or DEF% with 1 Roll
          (requiredRollsByStageOfGame === 2 &&
            returnMatchesForAllFourSubstats(item, substat1, 1) &&
            returnTankMainPercentageStatsForAllFourSubstats(item, 1)) ||
          //substat2 with 1 roll HP% Or DEF% with 2 Roll
          (requiredRollsByStageOfGame === 2 &&
            returnMatchesForAllFourSubstats(item, substat2, 1) &&
            returnTankMainPercentageStatsForAllFourSubstats(item, 1)) ||
          //substat2 with 2 roll HP% Or DEF% with 1 Roll
          (requiredRollsByStageOfGame === 2 &&
            returnMatchesForAllFourSubstats(item, substat2, 1) &&
            returnTankMainPercentageStatsForAllFourSubstats(item, 1)) ||
          //substat2 with 1 roll HP% Or DEF% with 2 Roll
          (requiredRollsByStageOfGame === 2 &&
            returnMatchesForAllFourSubstats(item, substat2, 1) &&
            returnTankMainPercentageStatsForAllFourSubstats(item, 1)) ||
          //requiredRollsByStageOfGame === 1
          //substat1 with 2 roll substat2 with 1 Roll
          (requiredRollsByStageOfGame === 1 && returnMatchesForAllFourSubstats(item, substat1, 1)) ||
          returnMatchesForAllFourSubstats(item, substat2, 1) ||
          //substat1 with 1 roll substat2 with 2 Roll
          (requiredRollsByStageOfGame === 1 && returnMatchesForAllFourSubstats(item, substat1, 1)) ||
          returnMatchesForAllFourSubstats(item, substat2, 1) ||
          //substat1 with 2 roll HP% Or DEF% with 1 Roll
          (requiredRollsByStageOfGame === 1 && returnMatchesForAllFourSubstats(item, substat1, 1)) ||
          returnTankMainPercentageStatsForAllFourSubstats(item, 1) ||
          //substat2 with 1 roll HP% Or DEF% with 2 Roll
          (requiredRollsByStageOfGame === 1 && returnMatchesForAllFourSubstats(item, substat2, 1)) ||
          returnTankMainPercentageStatsForAllFourSubstats(item, 1) ||
          //substat2 with 2 roll HP% Or DEF% with 1 Roll
          (requiredRollsByStageOfGame === 1 && returnMatchesForAllFourSubstats(item, substat2, 1)) ||
          returnTankMainPercentageStatsForAllFourSubstats(item, 1) ||
          //substat2 with 1 roll HP% Or DEF% with 2 Roll
          (requiredRollsByStageOfGame === 1 && returnMatchesForAllFourSubstats(item, substat2, 1)) ||
          returnTankMainPercentageStatsForAllFourSubstats(item, 1) ||
          //requiredRollsByStageOfGame === 0
          //substat1 with 2 roll substat2 with 1 Roll
          (requiredRollsByStageOfGame === 0 && returnMatchesForAllFourSubstats(item, substat1, 0)) ||
          returnMatchesForAllFourSubstats(item, substat2, 0) ||
          //substat1 with 1 roll substat2 with 2 Roll
          (requiredRollsByStageOfGame === 0 && returnMatchesForAllFourSubstats(item, substat1, 0)) ||
          returnMatchesForAllFourSubstats(item, substat2, 0) ||
          //substat1 with 2 roll HP% Or DEF% with 1 Roll
          (requiredRollsByStageOfGame === 0 && returnMatchesForAllFourSubstats(item, substat1, 0)) ||
          returnTankMainPercentageStatsForAllFourSubstats(item, 0) ||
          //substat2 with 1 roll HP% Or DEF% with 2 Roll
          (requiredRollsByStageOfGame === 0 && returnMatchesForAllFourSubstats(item, substat2, 0)) ||
          returnTankMainPercentageStatsForAllFourSubstats(item, 0) ||
          //substat2 with 2 roll HP% Or DEF% with 1 Roll
          (requiredRollsByStageOfGame === 0 && returnMatchesForAllFourSubstats(item, substat2, 0)) ||
          returnTankMainPercentageStatsForAllFourSubstats(item, 0) ||
          //substat2 with 1 roll HP% Or DEF% with 2 Roll
          (requiredRollsByStageOfGame === 0 && returnMatchesForAllFourSubstats(item, substat2, 0)) ||
          returnTankMainPercentageStatsForAllFourSubstats(item, 0))
      ) {
        return item;
      }
    }
    if (allSubstats.includes('ATK%') && !allSubstats.includes(' or DEF% or HP%')) {
      item.level >= 12 &&
        //Is Epic
        item.rarity === 'epic' &&
        //Any items with substat1 or Attack% with atleast 3 rolls between the three
        //substat1 with 3 rolls
        (returnMatchesForAllFourSubstats(item, substat1, requiredRollsByStageOfGame) ||
          //Attack% with 3 rolls
          returnAttackPercentageStatsForAllFourSubstats(item, requiredRollsByStageOfGame) ||
          //substat2 with 3 rolls
          returnMatchesForAllFourSubstats(item, substat2, requiredRollsByStageOfGame) ||
          //requiredRollsByStageOfGame === 3
          //substat1 with 2 roll Attack% with 1 Roll
          (requiredRollsByStageOfGame === 3 &&
            returnMatchesForAllFourSubstats(item, substat1, 2) &&
            returnAttackPercentageStatsForAllFourSubstats(item, 1)) ||
          //substat1 with 1 roll Attack% with 2 Roll
          (requiredRollsByStageOfGame === 3 &&
            returnMatchesForAllFourSubstats(item, substat1, 1) &&
            returnAttackPercentageStatsForAllFourSubstats(item, 2)) ||
          //substat1 with 2 roll substat2 with 1 Roll
          (requiredRollsByStageOfGame === 3 &&
            returnMatchesForAllFourSubstats(item, substat1, 2) &&
            returnMatchesForAllFourSubstats(item, substat2, 1)) ||
          //Attack% with 1 roll substat2 with 2 Roll
          (requiredRollsByStageOfGame === 3 &&
            returnAttackPercentageStatsForAllFourSubstats(item, 1) &&
            returnMatchesForAllFourSubstats(item, substat2, 2)) ||
          //Attack% with 2 roll substat2 with 1 Roll
          (requiredRollsByStageOfGame === 3 &&
            returnAttackPercentageStatsForAllFourSubstats(item, 2) &&
            returnMatchesForAllFourSubstats(item, substat2, 1)) ||
          //Attack% with 1 roll substat2 with 2 Roll
          (requiredRollsByStageOfGame === 3 &&
            returnAttackPercentageStatsForAllFourSubstats(item, 1) &&
            returnMatchesForAllFourSubstats(item, substat2, 2)) ||
          //requiredRollsByStageOfGame === 2
          //substat1 with 2 roll Attack% with 1 Roll
          (requiredRollsByStageOfGame === 2 &&
            returnMatchesForAllFourSubstats(item, substat1, 1) &&
            returnAttackPercentageStatsForAllFourSubstats(item, 1)) ||
          //substat1 with 1 roll Attack% with 2 Roll
          (requiredRollsByStageOfGame === 2 &&
            returnMatchesForAllFourSubstats(item, substat1, 1) &&
            returnAttackPercentageStatsForAllFourSubstats(item, 1)) ||
          //substat1 with 2 roll substat2 with 1 Roll
          (requiredRollsByStageOfGame === 2 &&
            returnMatchesForAllFourSubstats(item, substat1, 1) &&
            returnMatchesForAllFourSubstats(item, substat2, 1)) ||
          //Attack% with 1 roll substat2 with 2 Roll
          (requiredRollsByStageOfGame === 2 &&
            returnAttackPercentageStatsForAllFourSubstats(item, 1) &&
            returnMatchesForAllFourSubstats(item, substat2, 1)) ||
          //Attack% with 2 roll substat2 with 1 Roll
          (requiredRollsByStageOfGame === 2 &&
            returnAttackPercentageStatsForAllFourSubstats(item, 1) &&
            returnMatchesForAllFourSubstats(item, substat2, 1)) ||
          //Attack% with 1 roll substat2 with 2 Roll
          (requiredRollsByStageOfGame === 2 &&
            returnAttackPercentageStatsForAllFourSubstats(item, 1) &&
            returnMatchesForAllFourSubstats(item, substat2, 1)) ||
          //requiredRollsByStageOfGame === 1
          //substat1 with 2 roll Attack% with 1 Roll
          (requiredRollsByStageOfGame === 1 && returnMatchesForAllFourSubstats(item, substat1, 1)) ||
          returnAttackPercentageStatsForAllFourSubstats(item, 1) ||
          //substat1 with 1 roll Attack% with 2 Roll
          (requiredRollsByStageOfGame === 1 && returnMatchesForAllFourSubstats(item, substat1, 1)) ||
          returnAttackPercentageStatsForAllFourSubstats(item, 1) ||
          //substat1 with 2 roll substat2 with 1 Roll
          (requiredRollsByStageOfGame === 1 && returnMatchesForAllFourSubstats(item, substat1, 1)) ||
          returnMatchesForAllFourSubstats(item, substat2, 1) ||
          //Attack% with 1 roll substat2 with 2 Roll
          (requiredRollsByStageOfGame === 1 && returnAttackPercentageStatsForAllFourSubstats(item, 1)) ||
          returnMatchesForAllFourSubstats(item, substat2, 1) ||
          //Attack% with 2 roll substat2 with 1 Roll
          (requiredRollsByStageOfGame === 1 && returnAttackPercentageStatsForAllFourSubstats(item, 1)) ||
          returnMatchesForAllFourSubstats(item, substat2, 1) ||
          //Attack% with 1 roll substat2 with 2 Roll
          (requiredRollsByStageOfGame === 1 && returnAttackPercentageStatsForAllFourSubstats(item, 1)) ||
          returnMatchesForAllFourSubstats(item, substat2, 1) ||
          //requiredRollsByStageOfGame === 0
          //substat1 with 2 roll Attack% with 1 Roll
          (requiredRollsByStageOfGame === 0 && returnMatchesForAllFourSubstats(item, substat1, 0)) ||
          returnAttackPercentageStatsForAllFourSubstats(item, 0) ||
          //substat1 with 1 roll Attack% with 2 Roll
          (requiredRollsByStageOfGame === 0 && returnMatchesForAllFourSubstats(item, substat1, 0)) ||
          returnAttackPercentageStatsForAllFourSubstats(item, 0) ||
          //substat1 with 2 roll substat2 with 1 Roll
          (requiredRollsByStageOfGame === 0 && returnMatchesForAllFourSubstats(item, substat1, 0)) ||
          returnMatchesForAllFourSubstats(item, substat2, 0) ||
          //Attack% with 1 roll substat2 with 2 Roll
          (requiredRollsByStageOfGame === 0 && returnAttackPercentageStatsForAllFourSubstats(item, 0)) ||
          returnMatchesForAllFourSubstats(item, substat2, 0) ||
          //Attack% with 2 roll substat2 with 1 Roll
          (requiredRollsByStageOfGame === 0 && returnAttackPercentageStatsForAllFourSubstats(item, 0)) ||
          returnMatchesForAllFourSubstats(item, substat2, 0) ||
          //Attack% with 1 roll substat2 with 2 Roll
          (requiredRollsByStageOfGame === 0 && returnAttackPercentageStatsForAllFourSubstats(item, 0)) ||
          returnMatchesForAllFourSubstats(item, substat2, 0));
    }
  } else if (
    //Is Between level 12-15 //Is Epic //Any items with substat1 or substat2 with atleast 3 rolls between the two
    //Is Between level 12-15
    item.level >= 12 &&
    item.level <= 16 &&
    //Is Epic
    item.rarity === 'epic' &&
    //Any items with substat1 or substat2 with atleast 3 rolls between the two
    //substat1 with 3 rolls
    (returnMatchesForAllFourSubstats(item, substat1, requiredRollsByStageOfGame) ||
      //substat2 with 3 rolls
      returnMatchesForAllFourSubstats(item, substat2, requiredRollsByStageOfGame) ||
      //requiredRollsByStageOfGame === 3
      //substat1 with 2 roll substat2 with 1 Roll
      (requiredRollsByStageOfGame === 3 &&
        returnMatchesForAllFourSubstats(item, substat1, 2) &&
        returnMatchesForAllFourSubstats(item, substat2, 1)) ||
      //substat1 with 1 roll substat2 with 2 Roll
      (requiredRollsByStageOfGame === 3 &&
        returnMatchesForAllFourSubstats(item, substat1, 1) &&
        returnMatchesForAllFourSubstats(item, substat2, 2)) ||
      //requiredRollsByStageOfGame === 2
      //substat1 with 2 roll substat2 with 1 Roll
      (requiredRollsByStageOfGame === 2 &&
        returnMatchesForAllFourSubstats(item, substat1, 1) &&
        returnMatchesForAllFourSubstats(item, substat2, 1)) ||
      //substat1 with 1 roll substat2 with 2 Roll
      (requiredRollsByStageOfGame === 2 &&
        returnMatchesForAllFourSubstats(item, substat1, 1) &&
        returnMatchesForAllFourSubstats(item, substat2, 1)) ||
      //requiredRollsByStageOfGame === 1
      //substat1 with 2 roll substat2 with 1 Roll
      (requiredRollsByStageOfGame === 1 && returnMatchesForAllFourSubstats(item, substat1, 1)) ||
      returnMatchesForAllFourSubstats(item, substat2, 1) ||
      //substat1 with 1 roll substat2 with 2 Roll
      (requiredRollsByStageOfGame === 1 && returnMatchesForAllFourSubstats(item, substat1, 1)) ||
      returnMatchesForAllFourSubstats(item, substat2, 1) ||
      //requiredRollsByStageOfGame === 0
      //substat1 with 2 roll substat2 with 1 Roll
      (requiredRollsByStageOfGame === 0 && returnMatchesForAllFourSubstats(item, substat1, 0)) ||
      returnMatchesForAllFourSubstats(item, substat2, 0) ||
      //substat1 with 1 roll substat2 with 2 Roll
      (requiredRollsByStageOfGame === 0 && returnMatchesForAllFourSubstats(item, substat1, 0)) ||
      returnMatchesForAllFourSubstats(item, substat2, 0))
  ) {
    return item;
  }
}

export function returnBetween8And11LegendaryArtifactsWithSpecifiedSubstats(
  item: ArtifactItem,
  substat1: string,
  substat2?: string,
  substat3?: string,
  substat4?: string
) {
  const allSubstats = [];
  allSubstats.push(substat1);
  if (substat2) {
    allSubstats.push(substat2);
  }
  if (substat3) {
    allSubstats.push(substat3);
  }
  if (substat4) {
    allSubstats.push(substat4);
  }
  //console.log(`allSubstats`, allSubstats);

  if (allSubstats.includes('HP%') || allSubstats.includes('DEF%') || allSubstats.includes('ATK%')) {
    if (allSubstats.includes('ATK% or DEF% or HP%')) {
      //Is Between level 8-11 //Is Legendary //Any items with substat1 or CriticalRate with atleast 1 roll between the three
      //Is Between level 8-11
      item.level >= 8 &&
        item.level <= 11 &&
        //Is Legendary
        (item.rarity === 'legendary' || item.rarity === 'mythical') && //requiredRollsByStageOfGame === 3
        //Any items with substat1 or CriticalRate with atleast 1 roll between the three

        //substat1 with 1 roll
        ((requiredRollsByStageOfGame === 3 && returnMatchesForAllFourSubstats(item, substat1, 1)) ||
          //substat2 with 1 roll
          (requiredRollsByStageOfGame === 3 && returnMatchesForAllFourSubstats(item, substat2, 1)) ||
          //substat3 with 1 roll
          (requiredRollsByStageOfGame === 3 && returnMatchesForAllFourSubstats(item, substat3, 1)) ||
          //ATK% or DEF% or HP% with 2 rolls
          (requiredRollsByStageOfGame === 3 && returnMainPercentageStatsForAllFourSubstats(item, 1)) ||
          //requiredRollsByStageOfGame < 2
          //substat1 with 1 roll
          (requiredRollsByStageOfGame < 2 && returnMatchesForAllFourSubstats(item, substat1, 0)) ||
          //substat2 with 1 roll
          (requiredRollsByStageOfGame < 2 && returnMatchesForAllFourSubstats(item, substat2, 0)) ||
          //substat3 with 1 roll
          (requiredRollsByStageOfGame < 2 && returnMatchesForAllFourSubstats(item, substat3, 0)) ||
          //ATK% or DEF% or HP% with 2 rolls
          (requiredRollsByStageOfGame < 2 && returnMainPercentageStatsForAllFourSubstats(item, 0)));
    }
    if (allSubstats.includes('HP%') && allSubstats.includes('DEF%')) {
      //console.log(`Calculating for HP% & DEF% substats...`);
      if (
        item.level >= 8 &&
        item.level <= 11 &&
        //Is Legendary
        (item.rarity === 'legendary' || item.rarity === 'mythical') &&
        //Any items with substat1 or substat2 or HP% Or DEFwith atleast 1 roll between the three
        //requiredRollsByStageOfGame === 3
        //substat1 with 1 roll
        ((requiredRollsByStageOfGame === 3 && returnMatchesForAllFourSubstats(item, substat1, 1)) ||
          //substat2 with 1 roll
          (requiredRollsByStageOfGame === 3 && returnMatchesForAllFourSubstats(item, substat2, 1)) ||
          //HP% Or DEF% with 1 roll
          (requiredRollsByStageOfGame === 3 && returnTankMainPercentageStatsForAllFourSubstats(item, 1)) ||
          //requiredRollsByStageOfGame < 2
          //substat1 with 1 roll
          (requiredRollsByStageOfGame < 2 && returnMatchesForAllFourSubstats(item, substat1, 0)) ||
          //substat2 with 1 roll
          (requiredRollsByStageOfGame < 2 && returnMatchesForAllFourSubstats(item, substat2, 0)) ||
          //HP% Or DEF% with 1 roll
          (requiredRollsByStageOfGame < 2 && returnTankMainPercentageStatsForAllFourSubstats(item, 0)))
      ) {
        return item;
      }
    }
    if (allSubstats.includes('ATK%') && !allSubstats.includes(' or DEF% or HP%')) {
      item.level >= 8 &&
        item.level <= 11 &&
        //Is Legendary
        (item.rarity === 'legendary' || item.rarity === 'mythical') &&
        //Any items with substat1 or substat2 or substat3 with atleast 1 roll between the three
        //requiredRollsByStageOfGame === 3
        //substat1 with 1 roll
        ((requiredRollsByStageOfGame === 3 && returnMatchesForAllFourSubstats(item, substat1, 1)) ||
          //substat2 with 1 roll
          (requiredRollsByStageOfGame === 3 && returnMatchesForAllFourSubstats(item, substat2, 1)) ||
          //substat3 with 1 roll
          (requiredRollsByStageOfGame === 3 && returnMatchesForAllFourSubstats(item, substat3, 1)) ||
          //ATK% or DEF% or HP% with 2 rolls
          (requiredRollsByStageOfGame === 3 && returnAttackPercentageStatsForAllFourSubstats(item, 1)) ||
          //requiredRollsByStageOfGame < 2
          //substat1 with 1 roll
          (requiredRollsByStageOfGame < 2 && returnMatchesForAllFourSubstats(item, substat1, 0)) ||
          //substat2 with 1 roll
          (requiredRollsByStageOfGame < 2 && returnMatchesForAllFourSubstats(item, substat2, 0)) ||
          //substat3 with 1 roll
          (requiredRollsByStageOfGame < 2 && returnMatchesForAllFourSubstats(item, substat3, 0)) ||
          //ATK% or DEF% or HP% with 2 rolls
          (requiredRollsByStageOfGame < 2 && returnAttackPercentageStatsForAllFourSubstats(item, 0)));
    }
    if (allSubstats.includes('ATK%') && !allSubstats.includes(' or DEF% or HP%')) {
      item.level >= 8 &&
        item.level <= 11 &&
        //Is Legendary
        (((item.rarity === 'legendary' || item.rarity === 'mythical') &&
          //Any items with substat1 or Attack% with atleast 1 roll between the three
          //substat1 with 1 roll
          returnMatchesForAllFourSubstats(item, substat1, 1)) ||
          //Attack% with 1 roll
          returnAttackPercentageStatsForAllFourSubstats(item, 1) ||
          //HP% Or DEF% with 1 roll
          returnMatchesForAllFourSubstats(item, substat2, 1));
    }
  } else if (
    //Is Between level 8-11 //Is Legendary //Any items with substat1 or substat2 with atleast 1 roll between the two
    //Is Between level 8-11
    item.level >= 8 &&
    item.level <= 11 &&
    //Is Legendary
    (item.rarity === 'legendary' || item.rarity === 'mythical') &&
    //Any items with substat1 or substat2 with atleast 1 roll between the two
    //requiredRollsByStageOfGame === 3
    //substat1 with 1 roll
    ((requiredRollsByStageOfGame === 3 && returnMatchesForAllFourSubstats(item, substat1, 1)) ||
      //substat2 with 1 roll
      (requiredRollsByStageOfGame === 3 && returnMatchesForAllFourSubstats(item, substat2, 1)) ||
      //requiredRollsByStageOfGame < 2
      //substat1 with 1 roll
      (requiredRollsByStageOfGame < 2 && returnMatchesForAllFourSubstats(item, substat1, 0)) ||
      //substat2 with 1 roll
      (requiredRollsByStageOfGame < 2 && returnMatchesForAllFourSubstats(item, substat2, 0)))
  ) {
    return item;
  }
}

export function returnBetween8And11EpicArtifactsWithSpecifiedSubstats(
  item: ArtifactItem,
  substat1: string,
  substat2?: string,
  substat3?: string,
  substat4?: string
) {
  const allSubstats = [];
  allSubstats.push(substat1);
  if (substat2) {
    allSubstats.push(substat2);
  }
  if (substat3) {
    allSubstats.push(substat3);
  }
  if (substat4) {
    allSubstats.push(substat4);
  }

  if (allSubstats.includes('HP%') || allSubstats.includes('DEF%') || allSubstats.includes('ATK%')) {
    if (allSubstats.includes('ATK% or DEF% or HP%')) {
      //Is Between level 8-11 //Is Epic //Any items with substat1 or substat2 with atleast 2 rolls between the three
      //Is Between level 8-11
      item.level >= 8 &&
        item.level <= 11 &&
        //Is Epic
        item.rarity === 'epic' &&
        //substat1 with 2 rolls
        //Any items with substat1 or substat2 with atleast 2 rolls between the three
        //requiredRollsByStageOfGame === 3
        ((requiredRollsByStageOfGame === 3 && returnMatchesForAllFourSubstats(item, substat1, 2)) ||
          //substat2 with 2 rolls
          (requiredRollsByStageOfGame === 3 && returnMatchesForAllFourSubstats(item, substat2, 2)) ||
          //CriticalDamage with 2 rolls
          (requiredRollsByStageOfGame === 3 && returnMatchesForAllFourSubstats(item, substat3, 2)) ||
          //ATK% or DEF% or HP% with 2 rolls
          (requiredRollsByStageOfGame === 3 && returnMainPercentageStatsForAllFourSubstats(item, 2)) ||
          //substat1 with 1 roll substat2 with 1 Roll
          (requiredRollsByStageOfGame === 3 &&
            returnMatchesForAllFourSubstats(item, substat1, 1) &&
            returnMatchesForAllFourSubstats(item, substat2, 1)) ||
          //substat1 with 1 roll CriticalDamage with 1 Roll
          (requiredRollsByStageOfGame === 3 &&
            returnMatchesForAllFourSubstats(item, substat1, 1) &&
            returnMatchesForAllFourSubstats(item, substat3, 1)) ||
          //substat2 with 1 roll CriticalDamage with 1 Roll
          (requiredRollsByStageOfGame === 3 &&
            returnMatchesForAllFourSubstats(item, substat2, 1) &&
            returnMatchesForAllFourSubstats(item, substat3, 1)) ||
          //requiredRollsByStageOfGame === 2
          (requiredRollsByStageOfGame === 2 && returnMatchesForAllFourSubstats(item, substat1, 1)) ||
          //substat2 with 2 rolls
          (requiredRollsByStageOfGame === 2 && returnMatchesForAllFourSubstats(item, substat2, 1)) ||
          //CriticalDamage with 2 rolls
          (requiredRollsByStageOfGame === 2 && returnMatchesForAllFourSubstats(item, substat3, 1)) ||
          //ATK% or DEF% or HP% with 2 rolls
          (requiredRollsByStageOfGame === 2 && returnMainPercentageStatsForAllFourSubstats(item, 1)) ||
          //substat1 with 1 roll substat2 with 1 Roll
          (requiredRollsByStageOfGame === 2 &&
            returnMatchesForAllFourSubstats(item, substat1, 0) &&
            returnMatchesForAllFourSubstats(item, substat2, 0)) ||
          //substat1 with 1 roll CriticalDamage with 1 Roll
          (requiredRollsByStageOfGame === 2 &&
            returnMatchesForAllFourSubstats(item, substat1, 0) &&
            returnMatchesForAllFourSubstats(item, substat3, 0)) ||
          //substat2 with 1 roll CriticalDamage with 1 Roll
          (requiredRollsByStageOfGame === 2 &&
            returnMatchesForAllFourSubstats(item, substat2, 0) &&
            returnMatchesForAllFourSubstats(item, substat3, 0)) ||
          //requiredRollsByStageOfGame <= 1
          (requiredRollsByStageOfGame <= 1 && returnMatchesForAllFourSubstats(item, substat1, 0)) ||
          //substat2 with 2 rolls
          (requiredRollsByStageOfGame <= 1 && returnMatchesForAllFourSubstats(item, substat2, 0)) ||
          //CriticalDamage with 2 rolls
          (requiredRollsByStageOfGame <= 1 && returnMatchesForAllFourSubstats(item, substat3, 0)) ||
          //ATK% or DEF% or HP% with 2 rolls
          (requiredRollsByStageOfGame <= 1 && returnMainPercentageStatsForAllFourSubstats(item, 0)) ||
          //substat1 with 1 roll substat2 with 1 Roll
          (requiredRollsByStageOfGame <= 1 &&
            returnMatchesForAllFourSubstats(item, substat1, 0) &&
            returnMatchesForAllFourSubstats(item, substat2, 0)) ||
          //substat1 with 1 roll CriticalDamage with 1 Roll
          (requiredRollsByStageOfGame <= 1 &&
            returnMatchesForAllFourSubstats(item, substat1, 0) &&
            returnMatchesForAllFourSubstats(item, substat3, 0)) ||
          //substat2 with 1 roll CriticalDamage with 1 Roll
          (requiredRollsByStageOfGame <= 1 &&
            returnMatchesForAllFourSubstats(item, substat2, 0) &&
            returnMatchesForAllFourSubstats(item, substat3, 0)));
    }
    if (allSubstats.includes('HP%') && allSubstats.includes('DEF%')) {
      //console.log(`Calculating for HP% & DEF% substats...`);
      if (
        item.level >= 8 &&
        item.level <= 11 &&
        //Is Legendary
        item.rarity === 'epic' && //substat1 with 2 rolls
        //Any items with substat1 or substat2 with atleast 2 rolls between the two
        //requiredRollsByStageOfGame === 3
        ((requiredRollsByStageOfGame === 3 && returnMatchesForAllFourSubstats(item, substat1, 2)) ||
          //substat2 with 2 rolls
          (requiredRollsByStageOfGame === 3 && returnMatchesForAllFourSubstats(item, substat2, 2)) ||
          //HP% Or DEF% with 2 rolls
          (requiredRollsByStageOfGame === 3 && returnTankMainPercentageStatsForAllFourSubstats(item, 2)) ||
          //substat1 with 1 roll substat2 with 1 Roll
          (requiredRollsByStageOfGame === 3 &&
            returnMatchesForAllFourSubstats(item, substat1, 1) &&
            returnMatchesForAllFourSubstats(item, substat2, 1)) ||
          //substat1 with 1 roll HP% Or DEF% with 1 Roll
          (requiredRollsByStageOfGame === 3 &&
            returnMatchesForAllFourSubstats(item, substat1, 1) &&
            returnTankMainPercentageStatsForAllFourSubstats(item, 1)) ||
          //substat2 with 1 roll HP% Or DEF% with 1 Roll
          (requiredRollsByStageOfGame === 3 &&
            returnMatchesForAllFourSubstats(item, substat2, 1) &&
            returnTankMainPercentageStatsForAllFourSubstats(item, 1)) ||
          //requiredRollsByStageOfGame === 2
          (requiredRollsByStageOfGame === 2 && returnMatchesForAllFourSubstats(item, substat1, 1)) ||
          //substat2 with 2 rolls
          (requiredRollsByStageOfGame === 2 && returnMatchesForAllFourSubstats(item, substat2, 1)) ||
          //HP% Or DEF% with 2 rolls
          (requiredRollsByStageOfGame === 2 && returnTankMainPercentageStatsForAllFourSubstats(item, 1)) ||
          //substat1 with 1 roll substat2 with 1 Roll
          (requiredRollsByStageOfGame === 2 &&
            returnMatchesForAllFourSubstats(item, substat1, 0) &&
            returnMatchesForAllFourSubstats(item, substat2, 0)) ||
          //substat1 with 1 roll HP% Or DEF% with 1 Roll
          (requiredRollsByStageOfGame === 2 &&
            returnMatchesForAllFourSubstats(item, substat1, 0) &&
            returnTankMainPercentageStatsForAllFourSubstats(item, 0)) ||
          //substat2 with 1 roll HP% Or DEF% with 1 Roll
          (requiredRollsByStageOfGame === 2 &&
            returnMatchesForAllFourSubstats(item, substat2, 0) &&
            returnTankMainPercentageStatsForAllFourSubstats(item, 0)) ||
          //requiredRollsByStageOfGame <= 1
          (requiredRollsByStageOfGame <= 1 && returnMatchesForAllFourSubstats(item, substat1, 0)) ||
          //substat2 with 2 rolls
          (requiredRollsByStageOfGame <= 1 && returnMatchesForAllFourSubstats(item, substat2, 0)) ||
          //HP% Or DEF% with 2 rolls
          (requiredRollsByStageOfGame <= 1 && returnTankMainPercentageStatsForAllFourSubstats(item, 0)) ||
          //substat1 with 1 roll substat2 with 1 Roll
          (requiredRollsByStageOfGame <= 1 &&
            returnMatchesForAllFourSubstats(item, substat1, 0) &&
            returnMatchesForAllFourSubstats(item, substat2, 0)) ||
          //substat1 with 1 roll HP% Or DEF% with 1 Roll
          (requiredRollsByStageOfGame <= 1 &&
            returnMatchesForAllFourSubstats(item, substat1, 0) &&
            returnTankMainPercentageStatsForAllFourSubstats(item, 0)) ||
          //substat2 with 1 roll HP% Or DEF% with 1 Roll
          (requiredRollsByStageOfGame <= 1 &&
            returnMatchesForAllFourSubstats(item, substat2, 0) &&
            returnTankMainPercentageStatsForAllFourSubstats(item, 0)))
      ) {
        return item;
      }
    }
    if (allSubstats.includes('ATK%') && !allSubstats.includes(' or DEF% or HP%')) {
      item.level >= 8 &&
        item.level <= 11 &&
        //Is Epic
        item.rarity === 'epic' && //Any items with substat1 or Attack% with atleast 2 rolls between the three
        //substat1 with 2 rolls
        //requiredRollsByStageOfGame === 3
        ((requiredRollsByStageOfGame === 3 && returnMatchesForAllFourSubstats(item, substat1, 2)) ||
          //Attack% with 2 rolls
          (requiredRollsByStageOfGame === 3 && returnAttackPercentageStatsForAllFourSubstats(item, 2)) ||
          //substat2 with 2 rolls
          (requiredRollsByStageOfGame === 3 && returnMatchesForAllFourSubstats(item, substat2, 2)) ||
          //substat1 with 1 roll Attack% with 1 Roll
          (requiredRollsByStageOfGame === 3 &&
            returnMatchesForAllFourSubstats(item, substat1, 1) &&
            returnAttackPercentageStatsForAllFourSubstats(item, 1)) ||
          //substat1 with 1 roll substat2 with 1 Roll
          (requiredRollsByStageOfGame === 3 &&
            returnMatchesForAllFourSubstats(item, substat1, 1) &&
            returnMatchesForAllFourSubstats(item, substat2, 1)) ||
          //Attack% with 1 roll substat1 with 1 Roll
          (requiredRollsByStageOfGame === 3 &&
            returnAttackPercentageStatsForAllFourSubstats(item, 1) &&
            returnMatchesForAllFourSubstats(item, substat1, 1)) ||
          //Attack% with 1 roll substat2 with 1 Roll
          (requiredRollsByStageOfGame === 3 &&
            returnAttackPercentageStatsForAllFourSubstats(item, 1) &&
            returnMatchesForAllFourSubstats(item, substat2, 1)) ||
          //requiredRollsByStageOfGame === 2
          (requiredRollsByStageOfGame === 2 && returnMatchesForAllFourSubstats(item, substat1, 1)) ||
          //Attack% with 2 rolls
          (requiredRollsByStageOfGame === 2 && returnAttackPercentageStatsForAllFourSubstats(item, 1)) ||
          //substat2 with 2 rolls
          (requiredRollsByStageOfGame === 2 && returnMatchesForAllFourSubstats(item, substat2, 1)) ||
          //substat1 with 1 roll Attack% with 1 Roll
          (requiredRollsByStageOfGame === 2 &&
            returnMatchesForAllFourSubstats(item, substat1, 0) &&
            returnAttackPercentageStatsForAllFourSubstats(item, 0)) ||
          //substat1 with 1 roll substat2 with 1 Roll
          (requiredRollsByStageOfGame === 2 &&
            returnMatchesForAllFourSubstats(item, substat1, 0) &&
            returnMatchesForAllFourSubstats(item, substat2, 0)) ||
          //Attack% with 1 roll substat1 with 1 Roll
          (requiredRollsByStageOfGame === 2 &&
            returnAttackPercentageStatsForAllFourSubstats(item, 0) &&
            returnMatchesForAllFourSubstats(item, substat1, 0)) ||
          //Attack% with 1 roll substat2 with 1 Roll
          (requiredRollsByStageOfGame === 2 &&
            returnAttackPercentageStatsForAllFourSubstats(item, 0) &&
            returnMatchesForAllFourSubstats(item, substat2, 0)) ||
          //requiredRollsByStageOfGame <= 1
          (requiredRollsByStageOfGame <= 1 && returnMatchesForAllFourSubstats(item, substat1, 0)) ||
          //Attack% with 2 rolls
          (requiredRollsByStageOfGame <= 1 && returnAttackPercentageStatsForAllFourSubstats(item, 0)) ||
          //substat2 with 2 rolls
          (requiredRollsByStageOfGame <= 1 && returnMatchesForAllFourSubstats(item, substat2, 0)) ||
          //substat1 with 1 roll Attack% with 1 Roll
          (requiredRollsByStageOfGame <= 1 &&
            returnMatchesForAllFourSubstats(item, substat1, 0) &&
            returnAttackPercentageStatsForAllFourSubstats(item, 0)) ||
          //substat1 with 1 roll substat2 with 1 Roll
          (requiredRollsByStageOfGame <= 1 &&
            returnMatchesForAllFourSubstats(item, substat1, 0) &&
            returnMatchesForAllFourSubstats(item, substat2, 0)) ||
          //Attack% with 1 roll substat1 with 1 Roll
          (requiredRollsByStageOfGame <= 1 &&
            returnAttackPercentageStatsForAllFourSubstats(item, 0) &&
            returnMatchesForAllFourSubstats(item, substat1, 0)) ||
          //Attack% with 1 roll substat2 with 1 Roll
          (requiredRollsByStageOfGame <= 1 &&
            returnAttackPercentageStatsForAllFourSubstats(item, 0) &&
            returnMatchesForAllFourSubstats(item, substat2, 0)));
    }
  } else if (
    //Is Between level 8-11 //Is Epic //Any items with substat1 or substat2 with atleast 2 rolls between the two
    //Is Between level 8-11
    item.level >= 8 &&
    item.level <= 11 &&
    //Is Epic
    item.rarity === 'epic' &&
    //Any items with substat1 or substat2 with atleast 2 rolls between the two
    //substat1 with 2 rolls
    //requiredRollsByStageOfGame === 3
    ((requiredRollsByStageOfGame === 3 && returnMatchesForAllFourSubstats(item, substat1, 2)) ||
      //substat2 with 2 rolls
      (requiredRollsByStageOfGame === 3 && returnMatchesForAllFourSubstats(item, substat2, 2)) ||
      //substat1 with 1 roll substat2 with 1 Roll
      (requiredRollsByStageOfGame === 3 && returnMatchesForAllFourSubstats(item, substat1, 1)) ||
      (requiredRollsByStageOfGame === 3 && returnMatchesForAllFourSubstats(item, substat2, 1)) ||
      //requiredRollsByStageOfGame === 2
      (requiredRollsByStageOfGame === 2 && returnMatchesForAllFourSubstats(item, substat1, 1)) ||
      //substat2 with 2 rolls
      (requiredRollsByStageOfGame === 2 && returnMatchesForAllFourSubstats(item, substat2, 1)) ||
      //substat1 with 1 roll substat2 with 1 Roll
      (requiredRollsByStageOfGame === 2 && returnMatchesForAllFourSubstats(item, substat1, 0)) ||
      (requiredRollsByStageOfGame === 2 && returnMatchesForAllFourSubstats(item, substat2, 0)) ||
      //requiredRollsByStageOfGame <= 1
      (requiredRollsByStageOfGame <= 1 && returnMatchesForAllFourSubstats(item, substat1, 0)) ||
      //substat2 with 2 rolls
      (requiredRollsByStageOfGame <= 1 && returnMatchesForAllFourSubstats(item, substat2, 0)) ||
      //substat1 with 1 roll substat2 with 1 Roll
      (requiredRollsByStageOfGame <= 1 && returnMatchesForAllFourSubstats(item, substat1, 0)) ||
      (requiredRollsByStageOfGame <= 1 && returnMatchesForAllFourSubstats(item, substat2, 0)))
  ) {
    return item;
  }
}

export function returnBetween4And7LegendaryArtifactsWithSpecifiedSubstats(
  item: ArtifactItem,
  substat1: string,
  substat2?: string,
  substat3?: string,
  substat4?: string
) {
  const allSubstats = [];
  allSubstats.push(substat1);
  if (substat2) {
    allSubstats.push(substat2);
  }
  if (substat3) {
    allSubstats.push(substat3);
  }
  if (substat4) {
    allSubstats.push(substat4);
  }
  //console.log(`allSubstats`, allSubstats);

  if (allSubstats.includes('HP%') || allSubstats.includes('DEF%') || allSubstats.includes('ATK%')) {
    if (allSubstats.includes('ATK% or DEF% or HP%')) {
      //Is Between level 4-7 //Is Legendary
      //Is Between level 4-7
      item.level >= 4 &&
        item.level <= 7 &&
        //Is Legendary
        (item.rarity === 'legendary' || item.rarity === 'mythical');
    }
    if (allSubstats.includes('HP%') && allSubstats.includes('DEF%')) {
      //console.log(`Calculating for HP% & DEF% substats...`);
      if (
        item.level >= 4 &&
        item.level <= 7 &&
        //Is Legendary
        (item.rarity === 'legendary' || item.rarity === 'mythical')
      ) {
        return item;
      }
    }
    if (allSubstats.includes('ATK%') && !allSubstats.includes(' or DEF% or HP%')) {
      item.level >= 4 &&
        item.level <= 7 &&
        //Is Legendary
        (item.rarity === 'legendary' || item.rarity === 'mythical');
    }
  } else if (
    //Is Between level 4-7 //Is Legendary
    //Is Between level 4-7
    item.level >= 4 &&
    item.level <= 7 &&
    //Is Legendary
    (item.rarity === 'legendary' || item.rarity === 'mythical')
  ) {
    return item;
  }
}

export function returnBetween4And7EpicArtifactsWithSpecifiedSubstats(
  item: ArtifactItem,
  substat1: string,
  substat2?: string,
  substat3?: string,
  substat4?: string
) {
  const allSubstats = [];
  allSubstats.push(substat1);
  if (substat2) {
    allSubstats.push(substat2);
  }
  if (substat3) {
    allSubstats.push(substat3);
  }
  if (substat4) {
    allSubstats.push(substat4);
  }
  //console.log(`allSubstats`, allSubstats);

  if (allSubstats.includes('HP%') || allSubstats.includes('DEF%') || allSubstats.includes('ATK%')) {
    if (allSubstats.includes('ATK% or DEF% or HP%')) {
      //Is Between level 4-7 //Is Epic //Any items with substat1 & substat2 with atleast 1 rolls
      //Is Between level 4-7
      item.level >= 4 &&
        item.level <= 7 &&
        //Is Epic
        item.rarity === 'epic' &&
        //Any items with substat1 or substat2 with atleast 1 rolls between the three
        //requiredRollsByStageOfGame === 3
        //substat1 with 1 roll
        ((requiredRollsByStageOfGame === 3 && returnMatchesForAllFourSubstats(item, substat1, 1)) ||
          //substat2 with 1 roll
          (requiredRollsByStageOfGame === 3 && returnMatchesForAllFourSubstats(item, substat2, 1)) ||
          //CriticalDamage with 1 roll
          (requiredRollsByStageOfGame === 3 && returnMatchesForAllFourSubstats(item, substat3, 1)) ||
          //ATK% or DEF% or HP% with 2 rolls
          (requiredRollsByStageOfGame === 3 && returnMainPercentageStatsForAllFourSubstats(item, 1)) ||
          //requiredRollsByStageOfGame <= 2
          //substat1 with 1 roll
          (requiredRollsByStageOfGame <= 2 && returnMatchesForAllFourSubstats(item, substat1, 0)) ||
          //substat2 with 1 roll
          (requiredRollsByStageOfGame <= 2 && returnMatchesForAllFourSubstats(item, substat2, 0)) ||
          //CriticalDamage with 1 roll
          (requiredRollsByStageOfGame <= 2 && returnMatchesForAllFourSubstats(item, substat3, 0)) ||
          //ATK% or DEF% or HP% with 2 rolls
          (requiredRollsByStageOfGame <= 2 && returnMainPercentageStatsForAllFourSubstats(item, 0)));
    }
    if (allSubstats.includes('HP%') && allSubstats.includes('DEF%')) {
      //console.log(`Calculating for HP% & DEF% substats...`);
      if (
        item.level >= 4 &&
        item.level <= 7 &&
        //Is Legendary
        item.rarity === 'epic' &&
        //Any items with substat1 or substat2 with atleast 1 rolls between the three
        //requiredRollsByStageOfGame === 3
        //substat1 with 1 roll
        ((requiredRollsByStageOfGame === 3 && returnMatchesForAllFourSubstats(item, substat1, 1)) ||
          //substat2 with 1 roll
          (requiredRollsByStageOfGame === 3 && returnMatchesForAllFourSubstats(item, substat2, 1)) ||
          //HP% Or DEF% with 1 roll
          (requiredRollsByStageOfGame === 3 && returnTankMainPercentageStatsForAllFourSubstats(item, 1)) ||
          //requiredRollsByStageOfGame <= 2
          //substat1 with 1 roll
          (requiredRollsByStageOfGame <= 2 && returnMatchesForAllFourSubstats(item, substat1, 0)) ||
          //substat2 with 1 roll
          (requiredRollsByStageOfGame <= 2 && returnMatchesForAllFourSubstats(item, substat2, 0)) ||
          //HP% Or DEF% with 1 roll
          (requiredRollsByStageOfGame <= 2 && returnTankMainPercentageStatsForAllFourSubstats(item, 0)))
      ) {
        return item;
      }
    }
    if (allSubstats.includes('ATK%') && !allSubstats.includes(' or DEF% or HP%')) {
      //Is Between level 4-7 //Is Epic //Any items with substat1 & Attack% with atleast 1 rolls
      //Is Between level 4-7
      item.level >= 4 &&
        item.level <= 7 &&
        //Is Epic
        item.rarity === 'epic' &&
        //Any items with substat1 or Attack% with atleast 1 rolls between the three
        //requiredRollsByStageOfGame === 3
        //substat1 with 1 roll
        ((requiredRollsByStageOfGame === 3 && returnMatchesForAllFourSubstats(item, substat1, 1)) ||
          //Attack% with 1 roll
          (requiredRollsByStageOfGame === 3 && returnAttackPercentageStatsForAllFourSubstats(item, 1)) ||
          //substat2 with 1 roll
          (requiredRollsByStageOfGame === 3 && returnMatchesForAllFourSubstats(item, substat2, 1)) ||
          //requiredRollsByStageOfGame <= 2
          //substat1 with 1 roll
          (requiredRollsByStageOfGame <= 2 && returnMatchesForAllFourSubstats(item, substat1, 0)) ||
          //Attack% with 1 roll
          (requiredRollsByStageOfGame <= 2 && returnAttackPercentageStatsForAllFourSubstats(item, 0)) ||
          //substat2 with 1 roll
          (requiredRollsByStageOfGame <= 2 && returnMatchesForAllFourSubstats(item, substat2, 0)));
    }
  } else if (
    //Is Between level 4-7 //Is Epic //Any items with substat1 & Accuracy with atleast 1 rolls
    //Is Between level 4-7
    item.level >= 4 &&
    item.level <= 7 &&
    //Is Epic
    item.rarity === 'epic' &&
    //Any items with substat1 or Accuracy with atleast 1 rolls between the two
    //requiredRollsByStageOfGame === 3
    //substat1 with 1 roll
    ((requiredRollsByStageOfGame === 3 && returnMatchesForAllFourSubstats(item, substat1, 1)) ||
      //substat2 with 1 roll
      (requiredRollsByStageOfGame === 3 && returnMatchesForAllFourSubstats(item, substat2, 1)) ||
      //requiredRollsByStageOfGame <= 2
      //substat1 with 1 roll
      (requiredRollsByStageOfGame <= 2 && returnMatchesForAllFourSubstats(item, substat1, 0)) ||
      //substat2 with 1 roll
      (requiredRollsByStageOfGame <= 2 && returnMatchesForAllFourSubstats(item, substat2, 0)))
  ) {
    return item;
  }
}

export function returnBetween0And3LegendaryArtifactsWithSpecifiedSubstats(
  item: ArtifactItem,
  substat1: string,
  substat2?: string,
  substat3?: string,
  substat4?: string
) {
  const allSubstats = [];
  allSubstats.push(substat1);
  if (substat2) {
    allSubstats.push(substat2);
  }
  if (substat3) {
    allSubstats.push(substat3);
  }
  if (substat4) {
    allSubstats.push(substat4);
  }
  //console.log(`allSubstats`, allSubstats);

  if (allSubstats.includes('HP%') || allSubstats.includes('DEF%') || allSubstats.includes('ATK%')) {
    if (allSubstats.includes('ATK% or DEF% or HP%')) {
      //Is Between level 0-3 //Is Legendary
      //Is Between level 0-3
      item.level >= 0 &&
        item.level <= 3 &&
        //Is Legendary
        (item.rarity === 'legendary' || item.rarity === 'mythical');
    }
    if (allSubstats.includes('HP%') && allSubstats.includes('DEF%')) {
      //console.log(`Calculating for HP% & DEF% substats...`);
      if (
        item.level >= 0 &&
        item.level <= 3 &&
        //Is Legendary
        (item.rarity === 'legendary' || item.rarity === 'mythical')
      ) {
        return item;
      }
    }
    if (allSubstats.includes('ATK%') && !allSubstats.includes(' or DEF% or HP%')) {
      item.level >= 0 &&
        item.level <= 3 &&
        //Is Legendary
        (item.rarity === 'legendary' || item.rarity === 'mythical');
    }
  } else if (
    //Is Between level 0-3 //Is Legendary
    //Is Between level 0-3
    item.level >= 0 &&
    item.level <= 3 &&
    //Is Legendary
    (item.rarity === 'legendary' || item.rarity === 'mythical')
  ) {
    return item;
  }
}

export function returnBetween0And3EpicArtifactsWithSpecifiedSubstats(
  item: ArtifactItem,
  substat1: string,
  substat2?: string,
  substat3?: string,
  substat4?: string
) {
  const allSubstats = [];
  allSubstats.push(substat1);
  if (substat2) {
    allSubstats.push(substat2);
  }
  if (substat3) {
    allSubstats.push(substat3);
  }
  if (substat4) {
    allSubstats.push(substat4);
  }
  //console.log(`allSubstats`, allSubstats);

  if (allSubstats.includes('HP%') || allSubstats.includes('DEF%') || allSubstats.includes('ATK%')) {
    if (allSubstats.includes('ATK% or DEF% or HP%')) {
      //Is Between level 0-3 //Is Epic //Any items with substat1 or substat2 with atleast 1 rolls between the three
      //Is Between level 0-3
      item.level >= 0 &&
        item.level <= 3 &&
        //Is Epic
        item.rarity === 'epic' &&
        //Any items with substat1 & substat2 with atleast 1 rolls
        //requiredRollsByStageOfGame === 3
        //substat1 with 1 roll
        ((requiredRollsByStageOfGame === 3 && returnMatchesForAllFourSubstats(item, substat1, 1)) ||
          //substat2 with 1 roll
          (requiredRollsByStageOfGame === 3 && returnMatchesForAllFourSubstats(item, substat2, 1)) ||
          //CriticalDamage with 1 roll
          (requiredRollsByStageOfGame === 3 && returnMatchesForAllFourSubstats(item, substat3, 1)) ||
          //ATK% or DEF% or HP% with 2 rolls
          (requiredRollsByStageOfGame === 3 && returnMainPercentageStatsForAllFourSubstats(item, 1)) ||
          //requiredRollsByStageOfGame <= 2
          //substat1 with 1 roll
          (requiredRollsByStageOfGame <= 2 && returnMatchesForAllFourSubstats(item, substat1, 0)) ||
          //substat2 with 1 roll
          (requiredRollsByStageOfGame <= 2 && returnMatchesForAllFourSubstats(item, substat2, 0)) ||
          //CriticalDamage with 1 roll
          (requiredRollsByStageOfGame <= 2 && returnMatchesForAllFourSubstats(item, substat3, 0)) ||
          //ATK% or DEF% or HP% with 2 rolls
          (requiredRollsByStageOfGame <= 2 && returnMainPercentageStatsForAllFourSubstats(item, 0)));
    }
    if (allSubstats.includes('HP%') && allSubstats.includes('DEF%')) {
      //console.log(`Calculating for HP% & DEF% substats...`);
      if (
        item.level >= 0 &&
        item.level <= 3 &&
        //Is Legendary
        item.rarity === 'epic' && //substat1 with 1 roll
        //Any items with substat1 & substat2 with atleast 1 rolls
        //requiredRollsByStageOfGame === 3
        ((requiredRollsByStageOfGame === 3 && returnMatchesForAllFourSubstats(item, substat1, 1)) ||
          //substat2 with 1 roll
          (requiredRollsByStageOfGame === 3 && returnMatchesForAllFourSubstats(item, substat2, 1)) ||
          //HP% Or DEF% with 1 roll
          (requiredRollsByStageOfGame === 3 && returnTankMainPercentageStatsForAllFourSubstats(item, 1)) ||
          //requiredRollsByStageOfGame <= 2
          (requiredRollsByStageOfGame <= 2 && returnMatchesForAllFourSubstats(item, substat1, 0)) ||
          //substat2 with 1 roll
          (requiredRollsByStageOfGame <= 2 && returnMatchesForAllFourSubstats(item, substat2, 0)) ||
          //HP% Or DEF% with 1 roll
          (requiredRollsByStageOfGame <= 2 && returnTankMainPercentageStatsForAllFourSubstats(item, 0)))
      ) {
        return item;
      }
    }
    if (allSubstats.includes('ATK%') && !allSubstats.includes(' or DEF% or HP%')) {
      item.level >= 0 &&
        item.level <= 3 &&
        //Is Epic
        item.rarity === 'epic' &&
        //Any items with substat1 & Attack% with atleast 1 rolls
        //requiredRollsByStageOfGame === 3
        //substat1 with 1 roll
        ((requiredRollsByStageOfGame === 3 && returnMatchesForAllFourSubstats(item, substat1, 1)) ||
          //Attack% with 1 roll
          (requiredRollsByStageOfGame === 3 && returnAttackPercentageStatsForAllFourSubstats(item, 1)) ||
          //substat2 with 1 roll
          (requiredRollsByStageOfGame === 3 && returnMatchesForAllFourSubstats(item, substat2, 1)) ||
          //requiredRollsByStageOfGame < 2
          //substat1 with 1 roll
          (requiredRollsByStageOfGame < 2 && returnMatchesForAllFourSubstats(item, substat1, 0)) ||
          //Attack% with 1 roll
          (requiredRollsByStageOfGame < 2 && returnAttackPercentageStatsForAllFourSubstats(item, 0)) ||
          //substat2 with 1 roll
          (requiredRollsByStageOfGame < 2 && returnMatchesForAllFourSubstats(item, substat2, 0)));
    }
  } else if (
    //Is Between level 0-3 //Is Epic //Any items with substat1 or substat2 with atleast 1 rolls between the two
    //Is Between level 0-3
    item.level >= 0 &&
    item.level <= 3 &&
    //Is Epic
    item.rarity === 'epic' &&
    //Any items with substat1 & substat2 with atleast 1 rolls
    //requiredRollsByStageOfGame === 3
    //substat1 with 1 roll
    ((requiredRollsByStageOfGame === 3 && returnMatchesForAllFourSubstats(item, substat1, 1)) ||
      //substat2 with 1 roll
      (requiredRollsByStageOfGame === 3 && returnMatchesForAllFourSubstats(item, substat2, 1)) ||
      //requiredRollsByStageOfGame <= 2
      //substat1 with 1 roll
      (requiredRollsByStageOfGame <= 2 && returnMatchesForAllFourSubstats(item, substat1, 0)) ||
      //substat2 with 1 roll
      (requiredRollsByStageOfGame <= 2 && returnMatchesForAllFourSubstats(item, substat2, 0)))
  ) {
    return item;
  }
}

export function returnWorthRollingUpStatsByThemselvesArtifacts(item: ArtifactItem) {
  if (
    returnBetween12And15LegendaryArtifactsWithSpecifiedSubstats(item, 'speed') ||
    returnBetween12And15EpicArtifactsWithSpecifiedSubstats(item, 'speed') ||
    returnBetween8And11LegendaryArtifactsWithSpecifiedSubstats(item, 'speed') ||
    returnBetween8And11EpicArtifactsWithSpecifiedSubstats(item, 'speed') ||
    returnBetween4And7LegendaryArtifactsWithSpecifiedSubstats(item, 'speed') ||
    returnBetween4And7EpicArtifactsWithSpecifiedSubstats(item, 'speed') ||
    returnBetween0And3LegendaryArtifactsWithSpecifiedSubstats(item, 'speed') ||
    returnBetween0And3EpicArtifactsWithSpecifiedSubstats(item, 'speed') ||
    returnBetween12And15LegendaryArtifactsWithSpecifiedSubstats(item, 'ATK%') ||
    returnBetween12And15EpicArtifactsWithSpecifiedSubstats(item, 'ATK%') ||
    returnBetween8And11LegendaryArtifactsWithSpecifiedSubstats(item, 'ATK%') ||
    returnBetween8And11EpicArtifactsWithSpecifiedSubstats(item, 'ATK%') ||
    returnBetween4And7LegendaryArtifactsWithSpecifiedSubstats(item, 'ATK%') ||
    returnBetween4And7EpicArtifactsWithSpecifiedSubstats(item, 'ATK%') ||
    returnBetween0And3LegendaryArtifactsWithSpecifiedSubstats(item, 'ATK%') ||
    returnBetween0And3EpicArtifactsWithSpecifiedSubstats(item, 'ATK%') ||
    returnBetween12And15LegendaryArtifactsWithSpecifiedSubstats(item, 'HP%') ||
    returnBetween12And15EpicArtifactsWithSpecifiedSubstats(item, 'HP%') ||
    returnBetween8And11LegendaryArtifactsWithSpecifiedSubstats(item, 'HP%') ||
    returnBetween8And11EpicArtifactsWithSpecifiedSubstats(item, 'HP%') ||
    returnBetween4And7LegendaryArtifactsWithSpecifiedSubstats(item, 'HP%') ||
    returnBetween4And7EpicArtifactsWithSpecifiedSubstats(item, 'HP%') ||
    returnBetween0And3LegendaryArtifactsWithSpecifiedSubstats(item, 'HP%') ||
    returnBetween0And3EpicArtifactsWithSpecifiedSubstats(item, 'HP%') ||
    returnBetween12And15LegendaryArtifactsWithSpecifiedSubstats(item, 'DEF%') ||
    returnBetween12And15EpicArtifactsWithSpecifiedSubstats(item, 'DEF%') ||
    returnBetween8And11LegendaryArtifactsWithSpecifiedSubstats(item, 'DEF%') ||
    returnBetween8And11EpicArtifactsWithSpecifiedSubstats(item, 'DEF%') ||
    returnBetween4And7LegendaryArtifactsWithSpecifiedSubstats(item, 'DEF%') ||
    returnBetween4And7EpicArtifactsWithSpecifiedSubstats(item, 'DEF%') ||
    returnBetween0And3LegendaryArtifactsWithSpecifiedSubstats(item, 'DEF%') ||
    returnBetween0And3EpicArtifactsWithSpecifiedSubstats(item, 'DEF%') ||
    returnBetween12And15LegendaryArtifactsWithSpecifiedSubstats(item, 'accuracy') ||
    returnBetween12And15EpicArtifactsWithSpecifiedSubstats(item, 'accuracy') ||
    returnBetween8And11LegendaryArtifactsWithSpecifiedSubstats(item, 'accuracy') ||
    returnBetween8And11EpicArtifactsWithSpecifiedSubstats(item, 'accuracy') ||
    returnBetween4And7LegendaryArtifactsWithSpecifiedSubstats(item, 'accuracy') ||
    returnBetween4And7EpicArtifactsWithSpecifiedSubstats(item, 'accuracy') ||
    returnBetween0And3LegendaryArtifactsWithSpecifiedSubstats(item, 'accuracy') ||
    returnBetween0And3EpicArtifactsWithSpecifiedSubstats(item, 'accuracy') ||
    returnBetween12And15LegendaryArtifactsWithSpecifiedSubstats(item, 'resistance') ||
    returnBetween12And15EpicArtifactsWithSpecifiedSubstats(item, 'resistance') ||
    returnBetween8And11LegendaryArtifactsWithSpecifiedSubstats(item, 'resistance') ||
    returnBetween8And11EpicArtifactsWithSpecifiedSubstats(item, 'resistance') ||
    returnBetween4And7LegendaryArtifactsWithSpecifiedSubstats(item, 'resistance') ||
    returnBetween4And7EpicArtifactsWithSpecifiedSubstats(item, 'resistance') ||
    returnBetween0And3LegendaryArtifactsWithSpecifiedSubstats(item, 'resistance') ||
    returnBetween0And3EpicArtifactsWithSpecifiedSubstats(item, 'resistance') ||
    returnBetween12And15LegendaryArtifactsWithSpecifiedSubstats(item, 'criticalchance') ||
    returnBetween12And15EpicArtifactsWithSpecifiedSubstats(item, 'criticalchance') ||
    returnBetween8And11LegendaryArtifactsWithSpecifiedSubstats(item, 'criticalchance') ||
    returnBetween8And11EpicArtifactsWithSpecifiedSubstats(item, 'criticalchance') ||
    returnBetween4And7LegendaryArtifactsWithSpecifiedSubstats(item, 'criticalchance') ||
    returnBetween4And7EpicArtifactsWithSpecifiedSubstats(item, 'criticalchance') ||
    returnBetween0And3LegendaryArtifactsWithSpecifiedSubstats(item, 'criticalchance') ||
    returnBetween0And3EpicArtifactsWithSpecifiedSubstats(item, 'criticalchance') ||
    returnBetween12And15LegendaryArtifactsWithSpecifiedSubstats(item, 'criticaldamage') ||
    returnBetween12And15EpicArtifactsWithSpecifiedSubstats(item, 'criticaldamage') ||
    returnBetween8And11LegendaryArtifactsWithSpecifiedSubstats(item, 'criticaldamage') ||
    returnBetween8And11EpicArtifactsWithSpecifiedSubstats(item, 'criticaldamage') ||
    returnBetween4And7LegendaryArtifactsWithSpecifiedSubstats(item, 'criticaldamage') ||
    returnBetween4And7EpicArtifactsWithSpecifiedSubstats(item, 'criticaldamage') ||
    returnBetween0And3LegendaryArtifactsWithSpecifiedSubstats(item, 'criticaldamage') ||
    returnBetween0And3EpicArtifactsWithSpecifiedSubstats(item, 'criticaldamage')
  ) {
    return item;
  }
}

export function returnWorthRollingUpComboArtifacts(item: ArtifactItem) {
  if (
    returnBetween12And15LegendaryArtifactsWithSpecifiedSubstats(item, 'speed', 'ATK%') ||
    returnBetween12And15EpicArtifactsWithSpecifiedSubstats(item, 'speed', 'ATK%') ||
    returnBetween8And11LegendaryArtifactsWithSpecifiedSubstats(item, 'speed', 'ATK%') ||
    returnBetween8And11EpicArtifactsWithSpecifiedSubstats(item, 'speed', 'ATK%') ||
    returnBetween4And7LegendaryArtifactsWithSpecifiedSubstats(item, 'speed', 'ATK%') ||
    returnBetween4And7EpicArtifactsWithSpecifiedSubstats(item, 'speed', 'ATK%') ||
    returnBetween0And3LegendaryArtifactsWithSpecifiedSubstats(item, 'speed', 'ATK%') ||
    returnBetween0And3EpicArtifactsWithSpecifiedSubstats(item, 'speed', 'ATK%') ||
    returnBetween12And15LegendaryArtifactsWithSpecifiedSubstats(item, 'speed', 'HP%') ||
    returnBetween12And15EpicArtifactsWithSpecifiedSubstats(item, 'speed', 'HP%') ||
    returnBetween8And11LegendaryArtifactsWithSpecifiedSubstats(item, 'speed', 'HP%') ||
    returnBetween8And11EpicArtifactsWithSpecifiedSubstats(item, 'speed', 'HP%') ||
    returnBetween4And7LegendaryArtifactsWithSpecifiedSubstats(item, 'speed', 'HP%') ||
    returnBetween4And7EpicArtifactsWithSpecifiedSubstats(item, 'speed', 'HP%') ||
    returnBetween0And3LegendaryArtifactsWithSpecifiedSubstats(item, 'speed', 'HP%') ||
    returnBetween0And3EpicArtifactsWithSpecifiedSubstats(item, 'speed', 'HP%') ||
    returnBetween12And15LegendaryArtifactsWithSpecifiedSubstats(item, 'speed', 'DEF%') ||
    returnBetween12And15EpicArtifactsWithSpecifiedSubstats(item, 'speed', 'DEF%') ||
    returnBetween8And11LegendaryArtifactsWithSpecifiedSubstats(item, 'speed', 'DEF%') ||
    returnBetween8And11EpicArtifactsWithSpecifiedSubstats(item, 'speed', 'DEF%') ||
    returnBetween4And7LegendaryArtifactsWithSpecifiedSubstats(item, 'speed', 'DEF%') ||
    returnBetween4And7EpicArtifactsWithSpecifiedSubstats(item, 'speed', 'DEF%') ||
    returnBetween0And3LegendaryArtifactsWithSpecifiedSubstats(item, 'speed', 'DEF%') ||
    returnBetween0And3EpicArtifactsWithSpecifiedSubstats(item, 'speed', 'DEF%') ||
    returnBetween12And15LegendaryArtifactsWithSpecifiedSubstats(item, 'speed', 'DEF%') ||
    returnBetween12And15EpicArtifactsWithSpecifiedSubstats(item, 'speed', 'accuracy') ||
    returnBetween8And11LegendaryArtifactsWithSpecifiedSubstats(item, 'speed', 'accuracy') ||
    returnBetween8And11EpicArtifactsWithSpecifiedSubstats(item, 'speed', 'accuracy') ||
    returnBetween4And7LegendaryArtifactsWithSpecifiedSubstats(item, 'speed', 'accuracy') ||
    returnBetween4And7EpicArtifactsWithSpecifiedSubstats(item, 'speed', 'accuracy') ||
    returnBetween0And3LegendaryArtifactsWithSpecifiedSubstats(item, 'speed', 'accuracy') ||
    returnBetween0And3EpicArtifactsWithSpecifiedSubstats(item, 'speed', 'accuracy') ||
    returnBetween12And15EpicArtifactsWithSpecifiedSubstats(item, 'speed', 'resistance') ||
    returnBetween8And11LegendaryArtifactsWithSpecifiedSubstats(item, 'speed', 'resistance') ||
    returnBetween8And11EpicArtifactsWithSpecifiedSubstats(item, 'speed', 'resistance') ||
    returnBetween4And7LegendaryArtifactsWithSpecifiedSubstats(item, 'speed', 'resistance') ||
    returnBetween4And7EpicArtifactsWithSpecifiedSubstats(item, 'speed', 'resistance') ||
    returnBetween0And3LegendaryArtifactsWithSpecifiedSubstats(item, 'speed', 'resistance') ||
    returnBetween0And3EpicArtifactsWithSpecifiedSubstats(item, 'speed', 'resistance') ||
    returnBetween12And15EpicArtifactsWithSpecifiedSubstats(item, 'speed', 'criticalchance') ||
    returnBetween8And11LegendaryArtifactsWithSpecifiedSubstats(item, 'speed', 'criticalchance') ||
    returnBetween8And11EpicArtifactsWithSpecifiedSubstats(item, 'speed', 'criticalchance') ||
    returnBetween4And7LegendaryArtifactsWithSpecifiedSubstats(item, 'speed', 'criticalchance') ||
    returnBetween4And7EpicArtifactsWithSpecifiedSubstats(item, 'speed', 'criticalchance') ||
    returnBetween0And3LegendaryArtifactsWithSpecifiedSubstats(item, 'speed', 'criticalchance') ||
    returnBetween0And3EpicArtifactsWithSpecifiedSubstats(item, 'speed', 'criticalchance') ||
    returnBetween12And15EpicArtifactsWithSpecifiedSubstats(item, 'speed', 'criticaldamage') ||
    returnBetween8And11LegendaryArtifactsWithSpecifiedSubstats(item, 'speed', 'criticaldamage') ||
    returnBetween8And11EpicArtifactsWithSpecifiedSubstats(item, 'speed', 'criticaldamage') ||
    returnBetween4And7LegendaryArtifactsWithSpecifiedSubstats(item, 'speed', 'criticaldamage') ||
    returnBetween4And7EpicArtifactsWithSpecifiedSubstats(item, 'speed', 'criticaldamage') ||
    returnBetween0And3LegendaryArtifactsWithSpecifiedSubstats(item, 'speed', 'criticaldamage') ||
    returnBetween0And3EpicArtifactsWithSpecifiedSubstats(item, 'speed', 'criticaldamage')
  ) {
    return item;
  }
}

export function returnWorthRollingUpNukerArtifacts(item: ArtifactItem) {
  // 'speed', 'criticalchance', 'criticaldamage'
  if (
    //Is Between level 12-15 //Is Legendary //Any items with Speed or Resistance with atleast 2 rolls between the three
    returnBetween12And15LegendaryArtifactsWithSpecifiedSubstats(
      item,
      'speed',
      'criticalchance',
      'criticaldamage',
      'ATK% or DEF% or HP%'
    ) ||
    //Is Between level 12-15 //Is Epic //Any items with Speed or Resistance with atleast 3 rolls between the three
    returnBetween12And15EpicArtifactsWithSpecifiedSubstats(
      item,
      'speed',
      'criticalchance',
      'criticaldamage',
      'ATK% or DEF% or HP%'
    ) ||
    //Is Between level 8-11 //Is Legendary //Any items with Speed or Resistance with atleast 1 roll between the three
    returnBetween8And11LegendaryArtifactsWithSpecifiedSubstats(
      item,
      'speed',
      'criticalchance',
      'criticaldamage',
      'ATK% or DEF% or HP%'
    ) ||
    //Is Between level 8-11 //Is Epic //Any items with Speed or Resistance with atleast 2 rolls between the three
    returnBetween8And11EpicArtifactsWithSpecifiedSubstats(
      item,
      'speed',
      'criticalchance',
      'criticaldamage',
      'ATK% or DEF% or HP%'
    ) ||
    //Is Between level 4-7 //Is Legendary
    returnBetween4And7LegendaryArtifactsWithSpecifiedSubstats(
      item,
      'speed',
      'criticalchance',
      'criticaldamage',
      'ATK% or DEF% or HP%'
    ) ||
    //Is Between level 4-7 //Is Epic //Any items with Speed & Resistance with atleast 1 rolls
    returnBetween4And7EpicArtifactsWithSpecifiedSubstats(
      item,
      'speed',
      'criticalchance',
      'criticaldamage',
      'ATK% or DEF% or HP%'
    ) ||
    //Is Between level 0-3 //Is Legendary
    returnBetween0And3LegendaryArtifactsWithSpecifiedSubstats(
      item,
      'speed',
      'criticalchance',
      'criticaldamage',
      'ATK% or DEF% or HP%'
    ) ||
    //Is Between level 0-3 //Is Epic //Any items with Speed or Resistance with atleast 1 rolls between the three
    returnBetween0And3EpicArtifactsWithSpecifiedSubstats(
      item,
      'speed',
      'criticalchance',
      'criticaldamage',
      'ATK% or DEF% or HP%'
    )
  ) {
    return item;
  }
}

export function returnWorthRollingUpSupportArtifacts(item: ArtifactItem) {
  if (
    returnBetween12And15LegendaryArtifactsWithSpecifiedSubstats(item, 'speed', 'accuracy') ||
    returnBetween12And15EpicArtifactsWithSpecifiedSubstats(item, 'speed', 'accuracy') ||
    returnBetween8And11LegendaryArtifactsWithSpecifiedSubstats(item, 'speed', 'accuracy') ||
    returnBetween8And11EpicArtifactsWithSpecifiedSubstats(item, 'speed', 'accuracy') ||
    returnBetween4And7LegendaryArtifactsWithSpecifiedSubstats(item, 'speed', 'accuracy') ||
    returnBetween4And7EpicArtifactsWithSpecifiedSubstats(item, 'speed', 'accuracy') ||
    returnBetween0And3LegendaryArtifactsWithSpecifiedSubstats(item, 'speed', 'accuracy') ||
    returnBetween0And3EpicArtifactsWithSpecifiedSubstats(item, 'speed', 'accuracy')
  ) {
    return item;
  }
}

export function returnWorthRollingUpSpeederArtifacts(item: ArtifactItem) {
  if (
    returnBetween12And15LegendaryArtifactsWithSpecifiedSubstats(item, 'speed') ||
    returnBetween12And15EpicArtifactsWithSpecifiedSubstats(item, 'speed') ||
    returnBetween8And11LegendaryArtifactsWithSpecifiedSubstats(item, 'speed') ||
    returnBetween8And11EpicArtifactsWithSpecifiedSubstats(item, 'speed') ||
    returnBetween4And7LegendaryArtifactsWithSpecifiedSubstats(item, 'speed') ||
    returnBetween4And7EpicArtifactsWithSpecifiedSubstats(item, 'speed') ||
    returnBetween0And3LegendaryArtifactsWithSpecifiedSubstats(item, 'speed') ||
    returnBetween0And3EpicArtifactsWithSpecifiedSubstats(item, 'speed')
  ) {
    return item;
  }
}

export function returnWorthRollingUpTankArtifacts(item: ArtifactItem) {
  if (
    //Is Between level 12-15 //Is Legendary //Any items with Speed or Resistance with atleast 2 rolls between the three
    returnBetween12And15LegendaryArtifactsWithSpecifiedSubstats(item, 'speed', 'resistance', 'HP%', 'DEF%') ||
    //Is Between level 12-15 //Is Epic //Any items with Speed or Resistance with atleast 3 rolls between the three
    returnBetween12And15EpicArtifactsWithSpecifiedSubstats(item, 'speed', 'resistance', 'HP%', 'DEF%') ||
    //Is Between level 8-11 //Is Legendary //Any items with Speed or Resistance with atleast 1 roll between the three
    returnBetween8And11LegendaryArtifactsWithSpecifiedSubstats(item, 'speed', 'resistance', 'HP%', 'DEF%') ||
    //Is Between level 8-11 //Is Epic //Any items with Speed or Resistance with atleast 2 rolls between the three
    returnBetween8And11EpicArtifactsWithSpecifiedSubstats(item, 'speed', 'resistance', 'HP%', 'DEF%') ||
    //Is Between level 4-7 //Is Legendary
    returnBetween4And7LegendaryArtifactsWithSpecifiedSubstats(item, 'speed', 'resistance', 'HP%', 'DEF%') ||
    //Is Between level 4-7 //Is Epic //Any items with Speed & Resistance with atleast 1 rolls
    returnBetween4And7EpicArtifactsWithSpecifiedSubstats(item, 'speed', 'resistance', 'HP%', 'DEF%') ||
    //Is Between level 0-3 //Is Legendary
    returnBetween0And3LegendaryArtifactsWithSpecifiedSubstats(item, 'speed', 'resistance', 'HP%', 'DEF%') ||
    //Is Between level 0-3 //Is Epic //Any items with Speed or Resistance with atleast 1 rolls between the three
    returnBetween0And3EpicArtifactsWithSpecifiedSubstats(item, 'speed', 'resistance', 'HP%', 'DEF%')
  ) {
    return item;
  }
}

export function returnWorthRollingUpPvETankArtifacts(item: ArtifactItem) {
  if (
    returnBetween12And15LegendaryArtifactsWithSpecifiedSubstats(item, 'speed', 'accuracy', 'resistance') ||
    returnBetween12And15EpicArtifactsWithSpecifiedSubstats(item, 'speed', 'accuracy', 'resistance') ||
    returnBetween8And11LegendaryArtifactsWithSpecifiedSubstats(item, 'speed', 'accuracy', 'resistance') ||
    returnBetween8And11EpicArtifactsWithSpecifiedSubstats(item, 'speed', 'accuracy', 'resistance') ||
    returnBetween4And7LegendaryArtifactsWithSpecifiedSubstats(item, 'speed', 'accuracy', 'resistance') ||
    returnBetween4And7EpicArtifactsWithSpecifiedSubstats(item, 'speed', 'accuracy', 'resistance') ||
    returnBetween0And3LegendaryArtifactsWithSpecifiedSubstats(item, 'speed', 'accuracy', 'resistance') ||
    returnBetween0And3EpicArtifactsWithSpecifiedSubstats(item, 'speed', 'accuracy', 'resistance')
  ) {
    return item;
  }
}

export function returnWorthRollingUpBomberArtifacts(item: ArtifactItem) {
  if (
    returnBetween12And15LegendaryArtifactsWithSpecifiedSubstats(item, 'speed', 'accuracy', 'ATK%') ||
    returnBetween12And15EpicArtifactsWithSpecifiedSubstats(item, 'speed', 'accuracy', 'ATK%') ||
    returnBetween8And11LegendaryArtifactsWithSpecifiedSubstats(item, 'speed', 'accuracy', 'ATK%') ||
    returnBetween8And11EpicArtifactsWithSpecifiedSubstats(item, 'speed', 'accuracy', 'ATK%') ||
    returnBetween4And7LegendaryArtifactsWithSpecifiedSubstats(item, 'speed', 'accuracy', 'ATK%') ||
    returnBetween4And7EpicArtifactsWithSpecifiedSubstats(item, 'speed', 'accuracy', 'ATK%') ||
    returnBetween0And3LegendaryArtifactsWithSpecifiedSubstats(item, 'speed', 'accuracy', 'ATK%') ||
    returnBetween0And3EpicArtifactsWithSpecifiedSubstats(item, 'speed', 'accuracy', 'ATK%')
  ) {
    return item;
  }
}

export function returnWorthRollingUpBomberBottomBits(item: ArtifactItem) {
  if (
    // This entire part needs to be for only the Top Bits (weapon, helmet, shield) and then it needs to be duplicated for the Bottom Bits and then adjusted to let the primary stat count as one of the required stats for each type of build (I know I am crazy)
    ((item.kindId === 'gloves' || item.kindId === 'chest' || item.kindId === 'boots') &&
      returnMatchesForAllFourSubstats(item, 'speed', 0)) ||
    /* returnAttackPercentageStatsForPrimaryStat(item) || */
    (returnMatchesForAllFourSubstats(item, 'accuracy', 0) && //Is Between level 12-15
      //Is Between level 12-15 //Is Legendary //Any items with Speed or Resistance with atleast 2 rolls between the three
      ((item.level >= 12 &&
        //Is Legendary
        (item.rarity === 'legendary' || item.rarity === 'mythical') &&
        //Any items with Speed or Resistance or HP% Or DEF% with atleast 2 rolls between the three
        //Speed with 2 rolls
        returnMatchesForAllFourSubstats(item, 'speed', 2)) ||
        //HP% Or DEF% with 2 rolls
        returnMatchesForAllFourSubstats(item, 'accuracy', 2) ||
        //Speed with 1 roll / Resistance with 1 Roll
        returnMatchesForAllFourSubstats(item, 'speed', 1) ||
        //Speed with 1 roll / HP% Or DEF% with 1 Roll
        (returnMatchesForAllFourSubstats(item, 'speed', 1) && returnMatchesForAllFourSubstats(item, 'accuracy', 1)) ||
        //Resistance with 1 roll / HP% Or DEF% with 1 Roll
        returnMatchesForAllFourSubstats(item, 'accuracy', 1) ||
        //Is Between level 12-15 //Is Epic //Any items with Speed or Resistance with atleast 3 rolls between the three
        //Is Between level 12-15
        (item.level >= 12 &&
          item.level <= 16 &&
          //Is Epic
          item.rarity === 'epic' &&
          //Any items with Speed or Resistance with atleast 3 rolls between the three
          //Speed with 3 rolls
          returnMatchesForAllFourSubstats(item, 'speed', 3)) ||
        //HP% Or DEF% with 3 rolls
        returnMatchesForAllFourSubstats(item, 'accuracy', 3) ||
        //Speed with 2 roll Resistance with 1 Roll
        returnMatchesForAllFourSubstats(item, 'speed', 2) ||
        //Speed with 1 roll Resistance with 2 Roll
        returnMatchesForAllFourSubstats(item, 'speed', 1) ||
        //Speed with 2 roll HP% Or DEF% with 1 Roll
        (returnMatchesForAllFourSubstats(item, 'speed', 2) && returnMatchesForAllFourSubstats(item, 'accuracy', 1)) ||
        //Resistance with 1 roll HP% Or DEF% with 2 Roll
        returnMatchesForAllFourSubstats(item, 'accuracy', 2) ||
        //Resistance with 2 roll HP% Or DEF% with 1 Roll
        returnMatchesForAllFourSubstats(item, 'accuracy', 1) ||
        //Resistance with 1 roll HP% Or DEF% with 2 Roll
        returnMatchesForAllFourSubstats(item, 'accuracy', 2) ||
        //Is Between level 8-11 //Is Legendary //Any items with Speed or Resistance with atleast 1 roll between the three
        //Is Between level 8-11
        (item.level >= 8 &&
          item.level <= 11 &&
          //Is Legendary
          (item.rarity === 'legendary' || item.rarity === 'mythical') &&
          //Any items with Speed or Resistance with atleast 1 roll between the three
          //Speed with 1 roll
          returnMatchesForAllFourSubstats(item, 'speed', 1)) ||
        //Resistance with 1 roll
        /* returnAttackPercentageStatsForPrimaryStat(item) || */
        //HP% Or DEF% with 1 roll
        returnMatchesForAllFourSubstats(item, 'accuracy', 1) ||
        //Is Between level 8-11 //Is Epic //Any items with Speed or Resistance with atleast 2 rolls between the three
        //Is Between level 8-11
        (item.level >= 8 &&
          item.level <= 11 &&
          //Is Epic
          item.rarity === 'epic' &&
          //Any items with Speed or Resistance with atleast 2 rolls between the three
          //Speed with 2 rolls
          returnMatchesForAllFourSubstats(item, 'speed', 2)) ||
        //Resistance with 2 rolls
        /* returnAttackPercentageStatsForPrimaryStat(item) || */
        //HP% Or DEF% with 2 rolls
        returnMatchesForAllFourSubstats(item, 'accuracy', 2) ||
        //Speed with 1 roll Resistance with 1 Roll
        returnMatchesForAllFourSubstats(item, 'speed', 1) /* && returnAttackPercentageStatsForPrimaryStat(item) */ ||
        //Speed with 1 roll HP% Or DEF% with 1 Roll
        (returnMatchesForAllFourSubstats(item, 'speed', 1) && returnMatchesForAllFourSubstats(item, 'accuracy', 1)) ||
        //Resistance with 1 roll HP% Or DEF% with 1 Roll
        (returnMatchesForAllFourSubstats(item, 'resistance', 1) &&
          returnMatchesForAllFourSubstats(item, 'accuracy', 1)) ||
        //Is Between level 4-7 //Is Legendary
        //Is Between level 4-7
        (item.level >= 4 &&
          item.level <= 7 &&
          //Is Legendary
          (item.rarity === 'legendary' || item.rarity === 'mythical')) ||
        //Is Between level 4-7 //Is Epic //Any items with Speed & Resistance with atleast 1 rolls
        //Is Between level 4-7
        (item.level >= 4 &&
          item.level <= 7 &&
          //Is Epic
          item.rarity === 'epic' &&
          //Any items with Speed or Resistance with atleast 1 rolls between the three
          //Speed with 1 roll
          returnMatchesForAllFourSubstats(item, 'speed', 1)) ||
        //Resistance with 1 roll
        /* returnAttackPercentageStatsForPrimaryStat(item) || */
        //HP% Or DEF% with 1 roll
        returnMatchesForAllFourSubstats(item, 'accuracy', 1) ||
        //Is Between level 0-3 //Is Legendary
        //Is Between level 0-3
        (item.level >= 0 &&
          item.level <= 3 &&
          //Is Legendary
          (item.rarity === 'legendary' || item.rarity === 'mythical')) ||
        //Is Between level 0-3 //Is Epic //Any items with Speed or Resistance with atleast 1 rolls between the three
        //Is Between level 0-3
        (item.level >= 0 &&
          item.level <= 3 &&
          //Is Epic
          item.rarity === 'epic' &&
          //Any items with Speed & Resistance with atleast 1 rolls
          //Speed with 1 roll
          returnMatchesForAllFourSubstats(item, 'speed', 1)) ||
        //Resistance with 1 roll
        /* returnAttackPercentageStatsForPrimaryStat(item) || */
        //Accuracy with 1 roll
        returnMatchesForAllFourSubstats(item, 'accuracy', 1)))
  ) {
    return item;
  }
}

export function returnMatchesForChampsThatHaveSelectedSkills(item: ChampionItem) {
  const matchingItems = [];
  for (var i = 0; i < 6; i++) {
    matchingItems.push(item.type.skillIds[i]);
  }
  //console.log(`returnMatchesForChampsThatHaveSelectedSkills`, matchingItems);
  return matchingItems;
}

export function returnChampAbilityNumbers(item: ChampionItem) {
  const thisChampsSkillIds = item.type?.skillIds!;
  console.log(`thisChampsSkillIds`, thisChampsSkillIds);

  let abilityNumbers: any[] = [];

  thisChampsSkillIds.forEach((item) => {
    //console.log(`thisChampsSkillIds > item`, item);
    abilityNumbers.push(item);
  });
}

export function returnArtifactsThatRolledAllInSingleGoodStat(item: ArtifactItem) {
  let returnOrNotReturn = 'false';
  if (item.level >= 12) {
    item.secondaryBonuses.forEach((item) => {
      if (item.level >= 3) {
        if (
          item.statKind === 'speed' ||
          item.statKind === 'accuracy' ||
          item.statKind === 'criticalchance' ||
          item.statKind === 'criticaldamage' ||
          item.statKind === 'resistance' ||
          (item.statKind === 'attack' && item.absolute === false) ||
          (item.statKind === 'defense' && item.absolute === false) ||
          (item.statKind === 'health' && item.absolute === false)
        ) {
          returnOrNotReturn = 'true';
        }
      }
    });
  }
  if (item.level >= 8 && item.level <= 11) {
    item.secondaryBonuses.forEach((item) => {
      if (item.level >= 2) {
        if (
          item.statKind === 'speed' ||
          item.statKind === 'accuracy' ||
          item.statKind === 'criticalchance' ||
          item.statKind === 'criticaldamage' ||
          item.statKind === 'resistance' ||
          (item.statKind === 'attack' && item.absolute === false) ||
          (item.statKind === 'defense' && item.absolute === false) ||
          (item.statKind === 'health' && item.absolute === false)
        ) {
          returnOrNotReturn = 'true';
        }
      }
    });
  }
  if (item.level >= 4 && item.level <= 7) {
    item.secondaryBonuses.forEach((item) => {
      if (item.level >= 1) {
        if (
          item.statKind === 'speed' ||
          item.statKind === 'accuracy' ||
          item.statKind === 'criticalchance' ||
          item.statKind === 'criticaldamage' ||
          item.statKind === 'resistance' ||
          (item.statKind === 'attack' && item.absolute === false) ||
          (item.statKind === 'defense' && item.absolute === false) ||
          (item.statKind === 'health' && item.absolute === false)
        ) {
          returnOrNotReturn = 'true';
        }
      }
    });
  }
  if (returnOrNotReturn === 'true') {
    return item;
  }
}

/* export function returnTopBitsBelow16(item: ArtifactItem) {
  if (item.level <= 15 && (item.kindId === 'weapon' || item.kindId === 'helmet' || item.kindId === 'shield')) {
    return item;
  }
}

export function returnTopBitsBelow16Reversed(item: ArtifactItem) {
  if (
    item.level > 16 ||
    item.kindId === 'gloves' ||
    item.kindId === 'chest' ||
    item.kindId === 'boots' ||
    item.kindId === 'ring' ||
    item.kindId === 'pendant' ||
    item.kindId === 'banner'
  ) {
    return item;
  }
} */

/* Could be the key to getting component below working perfectly */
function hasSecondaryBonuses(item: ArtifactItem): item is ArtifactItem & { secondaryBonuses: ArtifactStatBonus[] } {
  return Array.isArray(item.secondaryBonuses);
}

export function returnArtifactsWithSelectedSubstatAndRequiredRolls(
  item: ArtifactItem | undefined,
  substat: string,
  absolute: boolean,
  requiredRolls: number
) {
  const artifact = item && hasSecondaryBonuses(item) ? item : undefined;
  if (artifact) {
    for (var i = 0; i < 3; i++) {
      const bonus = artifact.secondaryBonuses[i];
      if (bonus !== undefined) {
        const kind = bonus.statKind;
        const level = bonus.level;
        console.log(`returnArtifactsWithSelectedSubstatAndRequiredRolls substat`, substat);
        if (substat.indexOf('Percent') > -1 && level >= requiredRolls) {
          if (kind && substat.indexOf(kind) > -1) {
            if (absolute === false) {
              return artifact;
            }
          }
        } else {
          if (kind && substat.indexOf(kind) > -1) {
            return artifact;
          }
        }
      }
    }
  }
  return undefined;
}

export function returnWorthRollingUpFurther(item: ArtifactItem) {
  if (
    /* ((item.rarity === 'legendary' || item.rarity === 'mythical') &&
      item.level >= 12 &&
      (returnMainPercentageStatsForAllFourSubstats(item, requiredRollsByStageOfGame - 1) ||
        returnMatchesForAllFourSubstats(item, 'speed', requiredRollsByStageOfGame - 1) ||
        returnMatchesForAllFourSubstats(item, 'accuracy', requiredRollsByStageOfGame - 1) ||
        returnMatchesForAllFourSubstats(item, 'resistance', requiredRollsByStageOfGame - 1) ||
        returnMatchesForAllFourSubstats(item, 'criticalchance', requiredRollsByStageOfGame - 1) ||
        returnMatchesForAllFourSubstats(item, 'criticaldamage', requiredRollsByStageOfGame - 1))) ||
    ((item.rarity === 'legendary' || item.rarity === 'mythical') &&
      item.level >= 8 &&
      item.level <= 11 &&
      (returnMainPercentageStatsForAllFourSubstats(item, requiredRollsByStageOfGame - 2) ||
        returnMatchesForAllFourSubstats(item, 'speed', requiredRollsByStageOfGame - 2) ||
        returnMatchesForAllFourSubstats(item, 'accuracy', requiredRollsByStageOfGame - 2) ||
        returnMatchesForAllFourSubstats(item, 'resistance', requiredRollsByStageOfGame - 2) ||
        returnMatchesForAllFourSubstats(item, 'criticalchance', requiredRollsByStageOfGame - 2) ||
        returnMatchesForAllFourSubstats(item, 'criticaldamage', requiredRollsByStageOfGame - 2))) ||
    ((item.rarity === 'legendary' || item.rarity === 'mythical') &&
      item.level >= 4 &&
      item.level <= 7 &&
      (returnMainPercentageStatsForAllFourSubstats(item, requiredRollsByStageOfGame - 3) ||
        returnMatchesForAllFourSubstats(item, 'speed', requiredRollsByStageOfGame - 3) ||
        returnMatchesForAllFourSubstats(item, 'accuracy', requiredRollsByStageOfGame - 3) ||
        returnMatchesForAllFourSubstats(item, 'resistance', requiredRollsByStageOfGame - 3) ||
        returnMatchesForAllFourSubstats(item, 'criticalchance', requiredRollsByStageOfGame - 3) ||
        returnMatchesForAllFourSubstats(item, 'criticaldamage', requiredRollsByStageOfGame - 3))) ||
    ((item.rarity === 'legendary' || item.rarity === 'mythical') &&
      item.level >= 0 &&
      item.level <= 3 &&
      (returnMainPercentageStatsForAllFourSubstats(item, requiredRollsByStageOfGame - 4) ||
        returnMatchesForAllFourSubstats(item, 'speed', requiredRollsByStageOfGame - 4) ||
        returnMatchesForAllFourSubstats(item, 'accuracy', requiredRollsByStageOfGame - 4) ||
        returnMatchesForAllFourSubstats(item, 'resistance', requiredRollsByStageOfGame - 4) ||
        returnMatchesForAllFourSubstats(item, 'criticalchance', requiredRollsByStageOfGame - 4) ||
        returnMatchesForAllFourSubstats(item, 'criticaldamage', requiredRollsByStageOfGame - 4))) ||
    (item.rarity === 'epic' &&
      item.level >= 12 &&
      (returnMainPercentageStatsForAllFourSubstats(item, requiredRollsByStageOfGame) ||
        returnMatchesForAllFourSubstats(item, 'speed', requiredRollsByStageOfGame) ||
        returnMatchesForAllFourSubstats(item, 'accuracy', requiredRollsByStageOfGame) ||
        returnMatchesForAllFourSubstats(item, 'resistance', requiredRollsByStageOfGame) ||
        returnMatchesForAllFourSubstats(item, 'criticalchance', requiredRollsByStageOfGame) ||
        returnMatchesForAllFourSubstats(item, 'criticaldamage', requiredRollsByStageOfGame))) ||
    (item.rarity === 'epic' &&
      item.level >= 8 &&
      item.level <= 11 &&
      (returnMainPercentageStatsForAllFourSubstats(item, requiredRollsByStageOfGame - 1) ||
        returnMatchesForAllFourSubstats(item, 'speed', requiredRollsByStageOfGame - 1) ||
        returnMatchesForAllFourSubstats(item, 'accuracy', requiredRollsByStageOfGame - 1) ||
        returnMatchesForAllFourSubstats(item, 'resistance', requiredRollsByStageOfGame - 1) ||
        returnMatchesForAllFourSubstats(item, 'criticalchance', requiredRollsByStageOfGame - 1) ||
        returnMatchesForAllFourSubstats(item, 'criticaldamage', requiredRollsByStageOfGame - 1))) ||
    (item.rarity === 'epic' &&
      item.level >= 4 &&
      item.level <= 7 &&
      (returnMainPercentageStatsForAllFourSubstats(item, requiredRollsByStageOfGame - 2) ||
        returnMatchesForAllFourSubstats(item, 'speed', requiredRollsByStageOfGame - 2) ||
        returnMatchesForAllFourSubstats(item, 'accuracy', requiredRollsByStageOfGame - 2) ||
        returnMatchesForAllFourSubstats(item, 'resistance', requiredRollsByStageOfGame - 2) ||
        returnMatchesForAllFourSubstats(item, 'criticalchance', requiredRollsByStageOfGame - 2) ||
        returnMatchesForAllFourSubstats(item, 'criticaldamage', requiredRollsByStageOfGame - 2))) ||
    (item.rarity === 'epic' &&
      item.level >= 0 &&
      item.level <= 3 &&
      (returnMainPercentageStatsForAllFourSubstats(item, requiredRollsByStageOfGame - 3) ||
        returnMatchesForAllFourSubstats(item, 'speed', requiredRollsByStageOfGame - 3) ||
        returnMatchesForAllFourSubstats(item, 'accuracy', requiredRollsByStageOfGame - 3) ||
        returnMatchesForAllFourSubstats(item, 'resistance', requiredRollsByStageOfGame - 3) ||
        returnMatchesForAllFourSubstats(item, 'criticalchance', requiredRollsByStageOfGame - 3) ||
        returnMatchesForAllFourSubstats(item, 'criticaldamage', requiredRollsByStageOfGame - 3))) || */
    /* returnWorthRollingUpComboArtifacts(item) ||
    returnWorthRollingUpStatsByThemselvesArtifacts(item) || */
    // Confirmed to work but pretty sure it is pointless after using the Support one
    //Done
    returnWorthRollingUpSpeederArtifacts(item) ||
    // ----- SPD / ACC (Support) -----
    //Done
    returnWorthRollingUpSupportArtifacts(item) ||
    // ----- CriticalRate / Speed / CriticalDamage / (ATK% or DEF% or HP%) (Nuker) -----
    //Done
    returnWorthRollingUpNukerArtifacts(item) ||
    // ----- SPD / RES / HP% / DEF% (Tank) -----
    //Done
    returnWorthRollingUpTankArtifacts(item) ||
    // ----- End Game Only -----
    //Done
    returnWorthRollingUpPvETankArtifacts(item) ||
    //Still needs done the same way I did Tank
    returnWorthRollingUpBomberArtifacts(item)
  ) {
    return item;
  }
}

export function getAllCapsStatName(bonus: ArtifactStatBonus) {
  let name;
  if (bonus?.statKind?.toLowerCase() === 'health' && bonus.absolute === true) {
    name = 'HP';
  }
  if (bonus?.statKind?.toLowerCase() === 'attack' && bonus.absolute === true) {
    name = 'ATK';
  }
  if (bonus?.statKind?.toLowerCase() === 'defense' && bonus.absolute === true) {
    name = 'DEF';
  }
  if (bonus?.statKind?.toLowerCase() === 'health' && bonus.absolute === false) {
    name = 'HP%';
  }
  if (bonus?.statKind?.toLowerCase() === 'attack' && bonus.absolute === false) {
    name = 'ATK%';
  }
  if (bonus?.statKind?.toLowerCase() === 'defense' && bonus.absolute === false) {
    name = 'DEF%';
  }
  if (bonus?.statKind?.toLowerCase() === 'speed') {
    name = 'SPD';
  }
  if (bonus?.statKind?.toLowerCase() === 'criticalchance') {
    name = 'C.RATE';
  }
  if (bonus?.statKind?.toLowerCase() === 'criticaldamage') {
    name = 'C.DMG';
  }
  if (bonus?.statKind?.toLowerCase() === 'resistance') {
    name = 'RES';
  }
  if (bonus?.statKind?.toLowerCase() === 'accuracy') {
    name = 'ACC';
  }

  return name;
}

export function getAllCapsStatNameForAscendBonus(bonus: ArtifactStatBonus) {
  let name;
  if (bonus?.kind?.toLowerCase() === 'health' && bonus.absolute === true) {
    name = 'HP';
  }
  if (bonus?.kind?.toLowerCase() === 'attack' && bonus.absolute === true) {
    name = 'ATK';
  }
  if (bonus?.kind?.toLowerCase() === 'defense' && bonus.absolute === true) {
    name = 'DEF';
  }
  if (bonus?.kind?.toLowerCase() === 'health' && bonus.absolute === false) {
    name = 'HP%';
  }
  if (bonus?.kind?.toLowerCase() === 'attack' && bonus.absolute === false) {
    name = 'ATK%';
  }
  if (bonus?.kind?.toLowerCase() === 'defense' && bonus.absolute === false) {
    name = 'DEF%';
  }
  if (bonus?.kind?.toLowerCase() === 'speed') {
    name = 'SPD';
  }
  if (bonus?.kind?.toLowerCase() === 'criticalchance') {
    name = 'C.RATE';
  }
  if (bonus?.kind?.toLowerCase() === 'criticaldamage') {
    name = 'C.DMG';
  }
  if (bonus?.kind?.toLowerCase() === 'resistance') {
    name = 'RES';
  }
  if (bonus?.kind?.toLowerCase() === 'accuracy') {
    name = 'ACC';
  }

  return name;
}

export function getCamelCaseStatName(bonus: ArtifactStatBonus) {
  let name;
  if (bonus.statKind === 'health' && bonus.absolute === true) {
    name = 'health';
  }
  if (bonus.statKind === 'attack' && bonus.absolute === true) {
    name = 'attack';
  }
  if (bonus.statKind === 'defense' && bonus.absolute === true) {
    name = 'defense';
  }
  if (bonus.statKind === 'health' && bonus.absolute === false) {
    name = 'healthPercent';
  }
  if (bonus.statKind === 'attack' && bonus.absolute === false) {
    name = 'attackPercent';
  }
  if (bonus.statKind === 'defense' && bonus.absolute === false) {
    name = 'defensePercent';
  }
  if (bonus.statKind === 'speed') {
    name = 'speed';
  }
  if (bonus.statKind === 'criticalchance') {
    name = 'criticalchance';
  }
  if (bonus.statKind === 'criticaldamage') {
    name = 'criticaldamage';
  }
  if (bonus.statKind === 'resistance') {
    name = 'resistance';
  }
  if (bonus.statKind === 'accuracy') {
    name = 'accuracy';
  }

  return name;
}
