import { ViewOptionsBase } from 'Model/ListViewStore';

export enum ViewType {
  Index,
  Account,
  Artifacts,
}

export interface ViewOptions extends ViewOptionsBase {
  viewType: ViewType;
  defaultSort: string[];
}
