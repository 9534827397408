import React from 'react';
import {
  Autocomplete,
  Checkbox,
  FormControl,
  InputLabel,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  InputProps,
  Box,
} from '@mui/material';
import { DisplayMetadata } from '../../../Data/Types';
import { getSortedItemsWithKey, loadFilterSettings } from '..//Helpers';
import { FilterDescriptor, getGlobalRootStore, useListView, useRootStore } from '../../../Model';
import { DataItem } from 'Types';
import { action, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react-lite';
import { ListViewStore } from '../../../Model/ListViewStore';
import { NextGenImage } from '../../../Components/Library';
import { useSetting } from 'Components/Hooks';
import { useSearchParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';

interface Settings {
  [key: string]: any;
}

interface DisplayMetadataWithKey {
  imageName: string;
  imageDirectory: string;
  key: string;
  name: string;
  iconDirectory?: string;
  iconImage?: string;
}

export interface MultiSelectFilterProps<T extends DataItem = DataItem, TNarrow extends T = T> {
  id: string;
  items: Record<string, DisplayMetadata>;
  label: string;
  minWidth?: string | number;
  single?: boolean;
  save?: boolean;
  saveAsString?: boolean;
  persist?: boolean;
  reload?: boolean;
  iconOnly?: boolean;
  autocomplete?: boolean;
  placeholderText?: string;
  hideIcons?: boolean;
  imagesInsteadOfIcons?: boolean;
  getOptionCount?: (option: string) => number;
  canHandle: (opts: any) => boolean;
  filter: (keys: string[], item: TNarrow) => boolean | undefined;
}

export class ObservableSelectFilter<T extends DataItem = DataItem, TNarrow extends T = T>
  implements FilterDescriptor<T>
{
  public selectedKeys: string[] = [];
  private filterStates: Map<string, string[]> = new Map();

  public isDefaultValue() {
    return this.selectedKeys.length === 0;
  }

  public getURLRepresentation() {
    return this.selectedKeys.join(',');
  }

  // Add 'id' as a property.
  private id: string;
  //Even though it looks like 'id' is declared but its value is never read... it is needed to make it part of the filter objects. This make it so that filter.id in updateURLWithFilterSettings can access the id of each filter. Without it this.id will not explicitly be declared as an instance property, and thus will scope only to the constructor function itself. It won't be part of the filter objects when they are passed to updateURLWithFilterSettings, causing filter.id to be undefined.

  constructor(private readonly props: MultiSelectFilterProps<T, TNarrow>) {
    // Set 'id' from 'props'.
    this.id = props.id;
    const searchParams = new URLSearchParams(document.location.search);
    let value = searchParams.get(this.props.id);
    if (value === null) {
      const filterSettings = loadFilterSettings();
      if (filterSettings && filterSettings[this.props.id]) {
        let settingsValue = filterSettings[this.props.id];
        this.selectedKeys = Array.isArray(settingsValue) ? settingsValue : settingsValue.split(',');
      }
    } else {
      this.selectedKeys = Array.isArray(value) ? value : value.split(',');
    }
    makeObservable<ObservableSelectFilter<T, TNarrow>, 'selectedKeys' | 'setSelectedKeys'>(this, {
      selectedKeys: observable,
      canHandle: false,
      filterFn: false,
      render: false,
      setSelectedKeys: action,
    });
    //console.log(`Created MultiSelectFilterBase: id=${this.props.id}, selectedKeys=${this.selectedKeys}`);
  }

  get Component() {
    return this.render;
  }

  get canHandle() {
    return this.props.canHandle as any;
  }

  filterFn = (listView: ListViewStore, item: T) => {
    if (!this.props.canHandle(listView.options)) {
      return true;
    }

    // Debugging: Log the values of this.selectedKeys and item
    //console.log('this.selectedKeys:', this.selectedKeys.slice());
    //console.log('item:', item);

    const result = !!this.props.filter(this.selectedKeys, item as TNarrow);

    // Debugging: Log the result of this.props.filter
    //console.log('result:', result);

    return result;
  };

  private setSelectedKeys(keys: string[]) {
    const filterStates = getGlobalRootStore().filterStates.set(this.props.id, keys);
    //console.log(`filterStates`, filterStates);
    this.selectedKeys = keys;
  }

  private setFilterStates(filterKeys: Map<string, string[]>) {
    this.setFilterStates(filterKeys);
  }

  render = observer(() => {
    if (!this.props.canHandle(useListView().options)) {
      return null;
    }

    const {
      items,
      label,
      id,
      minWidth,
      single,
      save,
      saveAsString,
      reload,
      persist,
      iconOnly,
      autocomplete,
      placeholderText,
      hideIcons,
      imagesInsteadOfIcons,
      getOptionCount,
    } = this.props;

    const sortedItems = getSortedItemsWithKey(items, this.props.id) as DisplayMetadataWithKey[];

    /* if (window.location.hostname === "localhost") {
      console.log(`sortedItems`, sortedItems)
    } */

    // URL Query Params
    const [searchParams, setSearchParams] = useSearchParams();
    React.useEffect(() => {
      const params = Object.fromEntries(searchParams);
      if (this.selectedKeys.length) {
        params[id] = this.selectedKeys.join(',');
      } else if (params[id]) {
        delete params[id];
      } else {
        return;
      }
      setSearchParams(params);
    }, [this.selectedKeys]);

    function useDynamicSetting<T = any>(key: string | number): [T, (value: T) => void] {
      const [value, setValue] = useSetting<any>(key as keyof Settings);
      return [value as T, setValue as (value: T) => void];
    }

    const [savedSelection, setSavedSelection] = useDynamicSetting(`saved-selection-${id}`);

    const handleChange = (event: SelectChangeEvent<string[]>) => {
      const {
        target: { value },
      } = event;
      // On autofill we get a stringified value.
      if (single !== true) {
        this.setSelectedKeys(typeof value === 'string' ? value.split(',') : value);
      } else {
        if (save === true || saveAsString === true) {
          if (saveAsString !== true) {
            setSavedSelection(Number(value));
          } else {
            setSavedSelection(String(value));
          }
        }
        if (reload === true) {
          window.location.reload();
        }
      }
      //this.setFilterStates(useRootStore().filterStates); <-- Not allowed
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = event.target.value;
      this.selectedKeys.push(inputValue);
    };

    const options = sortedItems
      .filter((option) => {
        const optionId = option.key;
        if (optionId !== 'off') {
          return option;
        }
        return false;
      })
      .map((item) => {
        // Get the count for the option
        let optionCount;

        if (getOptionCount) {
          optionCount = getOptionCount(item.key);
        } else {
          // Default to counting occurrences in selectedKeys
          optionCount = this.selectedKeys.filter((key) => key === item.key).length;
        }

        return (
          <MenuItem key={`${id}.${item?.key}`} value={item?.key}>
            {single !== true && <Checkbox checked={this.selectedKeys.includes(item?.key)} />}
            {(imagesInsteadOfIcons === false || imagesInsteadOfIcons === undefined) && item?.iconImage && (
              <NextGenImage
                whichDirectory={item?.iconDirectory}
                whichImage={item?.iconImage}
                style={{
                  maxHeight: '4.2rem',
                  marginRight: 4,
                  marginLeft: 4,
                  filter: 'drop-shadow(.5px 2px 1.5px rgba(0,0,0,.9))',
                }}
              />
            )}
            {iconOnly ? null : (
              <ListItemText
                primary={
                  <>
                    {item?.name}{' '}
                    {optionCount !== undefined && optionCount !== 0 && (
                      <span className="optionCount-wrap">({optionCount})</span>
                    )}
                  </>
                }
              />
            )}
          </MenuItem>
        );
      });

    const useStyles = makeStyles({
      option: {
        color: 'white', // Change color
        fontSize: '20px', // Increase font size
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        padding: '0px 10px',
        '&:hover': {
          color: '#e8c817', // Change background color on hover
          cursor: 'pointer',
        },
        '&:first-child': {
          paddingTop: '7px',
        },
      },
    });
    const styles = useStyles();

    const optionCount = getOptionCount ? getOptionCount(label) : undefined;

    //console.log(`optionCount`, optionCount);

    // Pre-calculate the counts for all options
    const optionCounts: Record<string, number> = {};
    sortedItems.forEach((item) => {
      const count = getOptionCount ? getOptionCount(item.key) : undefined;
      if (count !== undefined) {
        optionCounts[item.key] = count;
      }
    });

    return (
      <FormControl sx={minWidth ? { m: 1, minWidth: minWidth } : { m: 1, minWidth: 80 }} variant="standard">
        <InputLabel id={`${id}-select-label`}>{label}</InputLabel>
        {autocomplete !== true ? (
          <>
            {single !== true ? (
              <Select
                labelId={`${id}-select-label`}
                id={`${id}-select`}
                multiple
                value={persist !== true && savedSelection ? savedSelection : this.selectedKeys}
                defaultValue={persist !== true && savedSelection ? savedSelection : this.selectedKeys}
                label={label}
                onChange={handleChange}
                renderValue={(selectedKeys) =>
                  selectedKeys
                    .map((key) => items[key]?.name || items[key]?.imageName || items[key]?.iconImage)
                    .join(', ')
                }
              >
                {options} ${optionCount !== undefined ? `(${optionCount})` : ``}
              </Select>
            ) : (
              <Select
                labelId={`${id}-select-label`}
                id={`${id}-select`}
                value={persist !== true && savedSelection ? savedSelection : this.selectedKeys}
                defaultValue={persist !== true && savedSelection ? savedSelection : this.selectedKeys}
                label={label}
                onChange={handleChange}
              >
                <MenuItem value="">
                  <em>
                    {label} {optionCount !== undefined && <span className="optionCount-wrap">({optionCount})</span>}
                  </em>
                </MenuItem>

                {options}
              </Select>
            )}
          </>
        ) : (
          <Autocomplete
            disableCloseOnSelect={true}
            PaperComponent={({ children }) => (
              <Box
                sx={{
                  minWidth: '200px',
                  maxWidth: 'fit-content',
                  display: 'flex',
                  alignContent: 'center',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                  textAlign: 'center',
                  margin: '0 auto',
                  background: 'rgba(0, 0, 0, .85)',
                }}
              >
                {children}
              </Box>
            )}
            //ListboxComponent={(props) => <Box {...props} sx={{ '& ul': { maxWidth: 300, boxSizing: 'border-box' } }} />}
            multiple={!single}
            filterSelectedOptions
            options={sortedItems}
            getOptionLabel={(option: DisplayMetadataWithKey) => option.name}
            renderOption={(props, option: DisplayMetadataWithKey, { inputValue }) => {
              const optionCount = optionCounts[option.key];
              return (
                <li {...props} className={styles.option}>
                  {hideIcons !== true && (
                    <>
                      <NextGenImage
                        style={{ marginRight: '5px' }}
                        whichDirectory={option.iconDirectory}
                        whichImage={`/tr:w-23/${option.iconImage}`}
                        className={`autocompleteOptionIcon ${option.iconImage}`}
                      />
                    </>
                  )}
                  <span>
                    {option.name}{' '}
                    {optionCount !== undefined && <span className="optionCount-wrap">({optionCount})</span>}
                  </span>
                </li>
              );
            }}
            isOptionEqualToValue={(option, value) => option.key === value.key}
            value={
              single
                ? (sortedItems.find((item) => this.selectedKeys.includes(item.key)) as DisplayMetadataWithKey) || null
                : sortedItems.filter((item) => this.selectedKeys.includes(item.key)) || []
            }
            onChange={(_, newValue: DisplayMetadataWithKey[] | DisplayMetadataWithKey | null) => {
              const valueArray = Array.isArray(newValue) ? newValue : newValue ? [newValue] : [];
              if (!single) {
                const newKeys = valueArray.filter((v) => v !== null).map((v: DisplayMetadataWithKey) => v.key);
                this.setSelectedKeys(newKeys);
              } else {
                const newKeys = newValue && 'key' in newValue ? [newValue.key] : [];
                this.setSelectedKeys(newKeys);
              }
            }}
            renderInput={(params) => {
              const selectedOption =
                this.selectedKeys.length > 0 ? sortedItems.find((item) => item.key === this.selectedKeys[0]) : null;
              const inputValue = selectedOption ? selectedOption.name : '';
              /* console.log(
                'inputValue:',
                inputValue,
                'params.inputProps.value:',
                (params.inputProps as InputProps).value
              ); */

              return (
                <TextField
                  {...params}
                  value={single ? inputValue : (params.inputProps as InputProps).value}
                  placeholder={!placeholderText ? label : placeholderText}
                />
              );
            }}
            filterOptions={(options: DisplayMetadataWithKey[], params: any) => {
              const filtered = options.filter((option) =>
                option.name.toLowerCase().includes(params.inputValue.toLowerCase())
              );
              return filtered;
            }}
            clearOnEscape
          />
        )}
      </FormControl>
    );
  });
}
