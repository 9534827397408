import React, { useEffect } from 'react';
import './ChampListPage.css';
import { ChampionList } from 'Components';
import { SharedFilters } from './SharedFilters';
import { Key } from 'Components/Layout/Key';
import { useListView, useRootStore } from 'Model';
import { observer } from 'mobx-react-lite';
import { ViewType } from 'Components/DataView/ViewOptions';
import { ToggleButton } from '@mui/material';
import { usePageSetting, useSetting, withViewType } from 'Components/Hooks';
import './MyChamps.css';
import { NoAccountData } from 'Components/Library';
import { AllChampsSearchFilter } from 'Components/DataView/Filters';
import { Helmet } from 'react-helmet-async';
import { getChampCounts, importDataToIndexedDB } from 'Components/DataView/Helpers';
import ShareButton from 'Components/Library/Buttons/ShareButton';
import { useAuth0 } from '@auth0/auth0-react';

/* Default Sort Order: ['grade', 'ascended', 'level', 'rarity', 'affinity', 'name', ] */
export const MyChamps: React.FC = withViewType(
  ViewType.Account,
  observer(() => {
    useEffect(() => {
      const urlParams = new URLSearchParams(window.location.search);
      const id = urlParams.get('id');
      const accountId = urlParams.get('accountId');

      if (id && accountId) {
        // Always use the live domain for Netlify Functions
        fetch(`https://raidchamps.com/.netlify/functions/getData?id=${id}`)
          .then((response) => response.json())
          .then((data) => {
            console.log('Fetched data:', data); // Debug line
            return importDataToIndexedDB(data, 'raidchamps', accountId);
          })
          .then(() => {
            console.log('Data imported successfully'); // Debug line
          })
          .catch((error) => {
            console.error('An error occurred while importing data:', error);
          });
      }
    }, []);

    let roles: string | string[];
    const { user, isAuthenticated, isLoading } = useAuth0();

    if (user) {
      if (
        user['https://raidchamps.com/app_metadata'].roles &&
        user['https://raidchamps.com/app_metadata'].roles.length > 0
      ) {
        roles = user['https://raidchamps.com/app_metadata'].roles;
      } else {
        roles = [];
      }
    } else {
      roles = ['Not Logged-in'];
    }

    if (isLoading) {
      return <div>Loading...</div>;
    }

    const root = useRootStore();

    const [ShowRatingsBoolean, setShowRatingsBoolean] = usePageSetting('show-ratings');

    useEffect(() => {
      const bodyTag = document.getElementsByTagName('body')[0];

      if (ShowRatingsBoolean) {
        bodyTag?.classList.remove('ShowRatings');
        bodyTag?.classList.add('ShowRatings');
      } else {
        bodyTag?.classList.add('ShowRatings');
        bodyTag?.classList.remove('ShowRatings');
      }
    }, [ShowRatingsBoolean]);

    const listView = root.listViews[ViewType.Account];
    const champions = root.accounts.selectedAccount?.champions || [];
    const championsArray = Object.values(champions);
    const items = listView.filterAndSort(championsArray);
    //This brings them back but unsorted and filters and sorting  dont work
    //const items = championsArray;

    const counts = getChampCounts(champions);
    //console.log(`counts`, counts);
    localStorage.setItem('champCounts', JSON.stringify(counts));

    return (
      <>
        <Helmet>
          <title>My Champs | RaidChamps.com</title>
          <meta
            name="description"
            content="Search, sort and filter through all the champions on your Raid account synced live as you play!"
          />
          <meta itemProp="name" content="My Champs | RaidChamps.com" />
          <meta
            itemProp="description"
            content="Search, sort and filter through all the champions on your Raid account synced live as you play!"
          />
          <meta itemProp="image" content="https://ik.imagekit.io/raidchamps/_NotExtracted/logo.avif" />
          <meta property="og:url" content="https://raidchamps.com/mychamps" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="My Champs | RaidChamps.com" />
          <meta
            property="og:description"
            content="Search, sort and filter through all the champions on your Raid account synced live as you play!"
          />
          <meta property="og:image" content="https://ik.imagekit.io/raidchamps/_NotExtracted/logo.avif" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="My Champs | RaidChamps.com" />
          <meta
            name="twitter:description"
            content="Search, sort and filter through all the champions on your Raid account synced live as you play!"
          />
          <meta name="twitter:image" content="https://ik.imagekit.io/raidchamps/_NotExtracted/logo.avif" />
        </Helmet>
        <div
          className="MyChampsPage ChampListPage ListPage"
          style={{ display: 'flex', flexDirection: 'column', padding: 15 }}
        >
          <h1 className="with-underline deep-underline center">My Champs</h1>
          <div style={{ flex: 1 }}>
            <div className="center">
              {root.accounts.selectedAccount ? (
                <>
                  {roles.includes('Tester') && <ShareButton />}
                  {/* <MyChampsSearchFilter.Component /> */}
                  <AllChampsSearchFilter.Component />
                  <SharedFilters />
                  <div className="TogglesRow-wrap">
                    <div className="ToggleButton-wrap singleToggle-wrap">
                      <ToggleButton
                        className="ShowRatingsToggle"
                        value="check"
                        selected={ShowRatingsBoolean}
                        onChange={() => {
                          setShowRatingsBoolean(!ShowRatingsBoolean);
                        }}
                      >
                        <span>Show Ratings</span>
                      </ToggleButton>
                    </div>
                  </div>
                  <div>
                    Displaying <span className="yellow champCount">{items.length}</span> champs
                  </div>
                </>
              ) : (
                <NoAccountData />
              )}
            </div>

            <div className="ChampionList-wrap">
              <ChampionList items={items} />
            </div>
          </div>
        </div>
        <Key />
      </>
    );
  })
);
