export enum StatusEffectName {
  stun = 'stun',
  freeze = 'freeze',
  sleep = 'sleep',
  provoke = 'provoke',
  statuscounterattack = 'statuscounterattack',
  blockdamage = 'blockdamage',
  blockheal2 = 'blockheal2',
  blockheal = 'blockheal',
  continuousdamage2 = 'continuousdamage2',
  continuousdamage = 'continuousdamage',
  continuousheal2 = 'continuousheal2',
  continuousheal = 'continuousheal',
  blockdebuff = 'blockdebuff',
  blockbuffs = 'blockbuffs',
  statusincreaseattack = 'statusincreaseattack',
  statusincreaseattack2 = 'statusincreaseattack2',
  statusreduceattack = 'statusreduceattack',
  statusreduceattack2 = 'statusreduceattack2',
  statusincreasedefence = 'statusincreasedefence',
  statusincreasedefence2 = 'statusincreasedefence2',
  statusreducedefence = 'statusreducedefence',
  statusreducedefence2 = 'statusreducedefence2',
  statusincreasespeed = 'statusincreasespeed',
  statusincreasespeed2 = 'statusincreasespeed2',
  statusreducespeed = 'statusreducespeed',
  statusreducespeed2 = 'statusreducespeed2',
  statusincreaseaccuracy = 'statusincreaseaccuracy',
  statusincreaseaccuracy2 = 'statusincreaseaccuracy2',
  statusreduceaccuracy = 'statusreduceaccuracy',
  statusreduceaccuracy2 = 'statusreduceaccuracy2',
  statusincreasecriticalchance = 'statusincreasecriticalchance',
  statusincreasecriticalchance2 = 'statusincreasecriticalchance2',
  statusreducecriticalchance = 'statusreducecriticalchance',
  statusreducecriticalchance2 = 'statusreducecriticalchance2',
  statusincreasecriticaldamage = 'statusincreasecriticaldamage',
  statusincreasecriticaldamage2 = 'statusincreasecriticaldamage2',
  statusreducecriticaldamage = 'statusreducecriticaldamage',
  statusreducecriticaldamage2 = 'statusreducecriticaldamage2',
  shield = 'shield',
  shield2 = 'shield2',
  blockskill = 'blockskill',
  reviveondeath = 'reviveondeath',
  sharedamage2 = 'sharedamage2',
  sharedamage = 'sharedamage',
  unkillable = 'unkillable',
  timebomb = 'timebomb',
  increasedamagetaken2 = 'increasedamagetaken2',
  increasedamagetaken = 'increasedamagetaken',
  blockrevive = 'blockrevive',
  reflectdamage = 'reflectdamage',
  reflectdamage2 = 'reflectdamage2',
  minotaurincreasedamage = 'minotaurincreasedamage',
  minotaurincreasedamagetaken = 'minotaurincreasedamagetaken',
  mark = 'mark',
  lifedrainondamage = 'lifedrainondamage',
  aoecontinuousdamage = 'aoecontinuousdamage',
  invisible = 'invisible',
  invisible2 = 'invisible2',
  fear = 'fear',
  fear2 = 'fear2',
  increasepoisoning = 'increasepoisoning',
  increasepoisoning2 = 'increasepoisoning2',
  reducedamagetaken = 'reducedamagetaken',
  reducedamagetaken2 = 'reducedamagetaken2',
  blockpassiveskills = 'blockpassiveskills',
}
