import React from 'react';
import * as Styles from './Panel.styles';

export const enum PanelDirection {
  left = 'left',
  right = 'right',
}

export interface PanelProps {
  owner?: HTMLElement | null;
  direction: PanelDirection;
  visible: boolean;
  style?: React.CSSProperties;
  className?: string;
  onDismiss?: () => void;
}

export const Panel: React.FC<PanelProps> = ({ direction, visible, style, className, onDismiss, children, owner }) => {
  React.useEffect(() => {
    if (!owner || !visible) {
      return;
    }
    const onBodyClick = (e: MouseEvent) => {
      if (e.target && !owner.contains(e.target as any)) {
        onDismiss?.();
      }
    };
    document.body.addEventListener('click', onBodyClick);
    return () => {
      document.body.removeEventListener('click', onBodyClick);
    };
  }, [onDismiss, owner, visible]);
  return (
    <div
      className={[Styles.root, Styles[`${direction}Animate`], visible ? 'toggle' : undefined, className]
        .filter(Boolean)
        .join(' ')}
      style={style}
    >
      <div>{children}</div>
    </div>
  );
};
