import React from 'react';
import { ChampionAura } from '../../Types';
import { getAuraData, getAffinityData, getAreaData } from '../../Data';
import { NextGenImage } from '../../Components/Library';
import areaLookup from '../../Data/Data/auraArea.json';

export interface AuraProps {
  className?: string;
  style?: React.CSSProperties;
  aura: ChampionAura;
}

export const Aura: React.FC<AuraProps> = ({ aura, style, className }) => {
  const auraData = getAuraData(aura.statKind);
  //console.log(`aura.area`, aura.area);
  //const area = (aura.area && getAreaData(aura.area))?.statKind ?? 'All Battles';
  let area;
  if (aura.area && areaLookup.lookup.auraArea[aura.area as keyof typeof areaLookup.lookup.auraArea] !== undefined) {
    area = areaLookup.lookup.auraArea[aura.area as keyof typeof areaLookup.lookup.auraArea]?.name;
  } else {
    area = 'All Battles';
  }

  //const area = aura.area;
  const amountPart = aura.absolute ? +aura.value : `${Math.round(aura.value * 100)}%`;
  const tip = `Increases ${
    aura.affinity ? `${aura.affinity.charAt(0).toUpperCase() + aura.affinity.slice(1)} Ally` : 'Ally'
  } ${auraData.statKind} in ${area} by ${amountPart}`;

  return (
    <NextGenImage
      whichDirectory={auraData?.iconDirectory}
      whichImage={auraData?.iconImage}
      className={className}
      style={style}
      alt={tip}
      title={tip}
    />
  );
};
