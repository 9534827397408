export enum RegionTypeName {
  //Ignored
  Tower = 'Doom Tower',
  Region1 = 'Kaerok Castle',
  Region2 = 'Sewers of Arnoc',
  Region3 = 'Catacombs of Narbuk',
  Region4 = 'Durham Forest',
  Region5 = "Felwin's Gate",
  Region6 = 'Palace of Aravia',
  Region7 = 'Tilshire',
  Region8 = 'Valdemar Strait',
  Region9 = 'The Dreadlands',
  Region10 = "Godfrey's Crossing",
  Region11 = 'Hallowed Halls',
  Region12 = 'Brimstone Path',
  //Arena
  Arena = 'Classic Arena',
  Arena3x3 = '3v3 Arena',
  //LiveArena = 'Live Arena'
  //Keeps
  ArcaneKeep = 'Arcane Keep',
  ForceKeep = 'Force Keep',
  MagicKeep = 'Magic Keep',
  SpiritKeep = 'Spirit Keep',
  VoidKeep = 'Void Keep',
  //Dungeons
  DragonsLair = "Dragon's Lair",
  FireGolemCave = "Fire Knight's Castle",
  IceGolemCave = "Ice Golem's Peak",
  IronTwinsFortressVoid = 'Iron Twins Fortress (Void)',
  IronTwinsFortressForce = 'Iron Twins Fortress (Force)',
  IronTwinsFortressMagic = 'Iron Twins Fortress (Magic)',
  IronTwinsFortressSpirit = 'Iron Twins Fortress (Spirit)',
  MinotaurCave = "Minotaur's Labyrinth",
  SandDevilsNecropolis = 'Sand Devils Necropolis',
  PhantomShogunsGrove = "Phantom Shogun's Grove",
  SpiderCave = "Spider's Den",
  //Clan Bosses
  AllianceBoss = 'Clan Boss: Demon Lord',
  //HydraBossNormal =  'Clan Boss: Hydra (Normal)', <--- Not available yet
  //HydraBossHard =  'Clan Boss: Hydra (Hard)', <--- Not available yet
  //HydraBossBrutal =  'Clan Boss: Hydra (Brutal)', <--- Not available yet
  //HydraBossNightmare =  'Clan Boss: Hydra (Nightmare)', <--- Not available yet
  //Faction Crypts
  Fraction_BannerLords = 'Banner Lord Crypt',
  Fraction_HighElves = 'High Elf Crypt',
  Fraction_SacredOrder = 'Sacred Order Crypt',
  Fraction_Barbarians = 'Barbarian Crypt',
  Fraction_OgrynTribes = 'Ogryn Tribe Crypt',
  Fraction_LizardMen = 'Lizardmen Crypt',
  Fraction_Skinwalkers = 'Skinwalker Crypt',
  Fraction_Orcs = 'Orc Crypt',
  Fraction_Demonspawn = 'Demonspawn Crypt',
  Fraction_UndeadHordes = 'Undead Horde Crypt',
  Fraction_DarkElves = 'Dark Elf Crypt',
  Fraction_KnightsRevenant = 'Knights Revenant Crypt',
  Fraction_Dwarves = 'Dwarf Crypt',
  Fraction_Shadowkin = 'Shadowkin Crypt',
  Fraction_SylvanWatchers = 'Sylvan Watcher Crypt',
  //Doom Tower
  //Doom Tower Normal - Rotation 1
  //DoomTowerWaves1 = 'Doom Tower Waves: Rotation 1',
  DoomTowerNormal10MagmaDragon1 = 'Doom Tower Normal - Floor 10: Magma Dragon: Rotation 1',
  DoomTowerNormal20ScarabKing1 = 'Doom Tower Normal - Floor 20: Scarab King: Rotation 1',
  DoomTowerNormal30NetherSpider1 = 'Doom Tower Normal - Floor 30: Nether Spider: Rotation 1',
  DoomTowerNormal40FrostSpider1 = 'Doom Tower Normal - Floor 40: Frost Spider: Rotation 1',
  DoomTowerNormal50MagmaDragon1 = 'Doom Tower Normal - Floor 50: Magma Dragon: Rotation 1',
  DoomTowerNormal60ScarabKing1 = 'Doom Tower Normal - Floor 60: Scarab King: Rotation 1',
  DoomTowerNormal70NetherSpider1 = 'Doom Tower Normal - Floor 70: Nether Spider: Rotation 1',
  DoomTowerNormal80FrostSpider1 = 'Doom Tower Normal - Floor 80: Frost Spider: Rotation 1',
  DoomTowerNormal90MagmaDragon1 = 'Doom Tower Normal - Floor 90: Magma Dragon: Rotation 1',
  DoomTowerNormal100ScarabKing1 = 'Doom Tower Normal - Floor 100: Scarab King: Rotation 1',
  DoomTowerNormal110NetherSpider1 = 'Doom Tower Normal - Floor 110: Nether Spider: Rotation 1',
  DoomTowerNormal120FrostSpider1 = 'Doom Tower Normal - Floor 120: Frost Spider: Rotation 1',
  //Doom Tower Hard - Rotation 1
  DoomTowerHard10MagmaDragon1 = 'Doom Tower Hard - Floor 10: Magma Dragon: Rotation 1',
  DoomTowerHard20ScarabKing1 = 'Doom Tower Hard - Floor 20: Scarab King: Rotation 1',
  DoomTowerHard30NetherSpider1 = 'Doom Tower Hard - Floor 30: Nether Spider: Rotation 1',
  DoomTowerHard40FrostSpider1 = 'Doom Tower Hard - Floor 40: Frost Spider: Rotation 1',
  DoomTowerHard50MagmaDragon1 = 'Doom Tower Hard - Floor 50: Magma Dragon: Rotation 1',
  DoomTowerHard60ScarabKing1 = 'Doom Tower Hard - Floor 60: Scarab King: Rotation 1',
  DoomTowerHard70NetherSpider1 = 'Doom Tower Hard - Floor 70: Nether Spider: Rotation 1',
  DoomTowerHard80FrostSpider1 = 'Doom Tower Hard - Floor 80: Frost Spider: Rotation 1',
  DoomTowerHard90MagmaDragon1 = 'Doom Tower Hard - Floor 90: Magma Dragon: Rotation 1',
  DoomTowerHard100ScarabKing1 = 'Doom Tower Hard - Floor 100: Scarab King: Rotation 1',
  DoomTowerHard110NetherSpider1 = 'Doom Tower Hard - Floor 110: Nether Spider: Rotation 1',
  DoomTowerHard120FrostSpider1 = 'Doom Tower Hard - Floor 120: Frost Spider: Rotation 1',
  //Doom Tower Normal - Rotation 2
  //DoomTowerWaves2 = 'Doom Tower Waves: Rotation 2',
  DoomTowerNormal10MagmaDragon2 = 'Doom Tower Normal - Floor 10: Magma Dragon: Rotation 2',
  DoomTowerNormal20CelestialGriffin2 = 'Doom Tower Normal - Floor 20: Celestial Griffin: Rotation 2',
  DoomTowerNormal30NetherSpider2 = 'Doom Tower Normal - Floor 30: Nether Spider: Rotation 2',
  DoomTowerNormal40EternalDragon2 = 'Doom Tower Normal - Floor 40: Eternal Dragon: Rotation 2',
  DoomTowerNormal50MagmaDragon2 = 'Doom Tower Normal - Floor 50: Magma Dragon: Rotation 2',
  DoomTowerNormal60CelestialGriffin2 = 'Doom Tower Normal - Floor 60: Celestial Griffin: Rotation 2',
  DoomTowerNormal70NetherSpider2 = 'Doom Tower Normal - Floor 70: Nether Spider: Rotation 2',
  DoomTowerNormal80EternalDragon2 = 'Doom Tower Normal - Floor 80: Eternal Dragon: Rotation 2',
  DoomTowerNormal90MagmaDragon2 = 'Doom Tower Normal - Floor 90: Magma Dragon: Rotation 2',
  DoomTowerNormal100CelestialGriffin2 = 'Doom Tower Normal - Floor 100: Celestial Griffin: Rotation 2',
  DoomTowerNormal110NetherSpider2 = 'Doom Tower Normal - Floor 110: Nether Spider: Rotation 2',
  DoomTowerNormal120EternalDragon2 = 'Doom Tower Normal - Floor 120: Eternal Dragon: Rotation 2',
  //Doom Tower Hard - Rotation 2
  DoomTowerHard10MagmaDragon2 = 'Doom Tower Hard - Floor 10: Magma Dragon: Rotation 2',
  DoomTowerHard20CelestialGriffin2 = 'Doom Tower Hard - Floor 20: Celestial Griffin: Rotation 2',
  DoomTowerHard30NetherSpider2 = 'Doom Tower Hard - Floor 30: Nether Spider: Rotation 2',
  DoomTowerHard40EternalDragon2 = 'Doom Tower Hard - Floor 40: Eternal Dragon: Rotation 2',
  DoomTowerHard50MagmaDragon2 = 'Doom Tower Hard - Floor 50: Magma Dragon: Rotation 2',
  DoomTowerHard60CelestialGriffin2 = 'Doom Tower Hard - Floor 60: Celestial Griffin: Rotation 2',
  DoomTowerHard70NetherSpider2 = 'Doom Tower Hard - Floor 70: Nether Spider: Rotation 2',
  DoomTowerHard80EternalDragon2 = 'Doom Tower Hard - Floor 80: Eternal Dragon: Rotation 2',
  DoomTowerHard90MagmaDragon2 = 'Doom Tower Hard - Floor 90: Magma Dragon: Rotation 2',
  DoomTowerHard100CelestialGriffin2 = 'Doom Tower Hard - Floor 100: Celestial Griffin: Rotation 2',
  DoomTowerHard110NetherSpider2 = 'Doom Tower Hard - Floor 110: Nether Spider: Rotation 2',
  DoomTowerHard120EternalDragon2 = 'Doom Tower Hard - Floor 120: Eternal Dragon: Rotation 2',
  //Doom Tower Normal - Rotation 3
  //DoomTowerWaves3 = 'Doom Tower Waves: Rotation 3',
  DoomTowerNormal10Dreadhorn3 = 'Doom Tower Normal - Floor 10:  Dreadhorn: Rotation 3',
  DoomTowerNormal20CScarabKing3 = 'Doom Tower Normal - Floor 20:  Scarab King: Rotation 3',
  DoomTowerNormal30CelestialGriffin3 = 'Doom Tower Normal - Floor 30:  Celestial Griffin: Rotation 3',
  DoomTowerNormal40DarkFae3 = 'Doom Tower Normal - Floor 40:  Dark Fae: Rotation 3',
  DoomTowerNormal50Dreadhorn3 = 'Doom Tower Normal - Floor 50:  Dreadhorn: Rotation 3',
  DoomTowerNormal60CScarabKing3 = 'Doom Tower Normal - Floor 60:  Scarab King: Rotation 3',
  DoomTowerNormal70CelestialGriffin3 = 'Doom Tower Normal - Floor 70:  Celestial Griffin: Rotation 3',
  DoomTowerNormal80DarkFae3 = 'Doom Tower Normal - Floor 80:  Dark Fae: Rotation 3',
  DoomTowerNormal90Dreadhorn3 = 'Doom Tower Normal - Floor 90:  Dreadhorn: Rotation 3',
  DoomTowerNormal100CScarabKing3 = 'Doom Tower Normal - Floor 100:  Scarab King: Rotation 3',
  DoomTowerNormal110CelestialGriffin3 = 'Doom Tower Normal - Floor 110:  Celestial Griffin: Rotation 3',
  DoomTowerNormal120DarkFae3 = 'Doom Tower Normal - Floor 120:  Dark Fae: Rotation 3',
  //Doom Tower Hard - Rotation 3
  DoomTowerHard10Dreadhorn3 = 'Doom Tower Hard - Floor 10:  Dreadhorn: Rotation 3',
  DoomTowerHard20CScarabKing3 = 'Doom Tower Hard - Floor 20:  Scarab King: Rotation 3',
  DoomTowerHard30CelestialGriffin3 = 'Doom Tower Hard - Floor 30:  Celestial Griffin: Rotation 3',
  DoomTowerHard40DarkFae3 = 'Doom Tower Hard - Floor 40:  Dark Fae: Rotation 3',
  DoomTowerHard50Dreadhorn3 = 'Doom Tower Hard - Floor 50:  Dreadhorn: Rotation 3',
  DoomTowerHard60CScarabKing3 = 'Doom Tower Hard - Floor 60:  Scarab King: Rotation 3',
  DoomTowerHard70CelestialGriffin3 = 'Doom Tower Hard - Floor 70:  Celestial Griffin: Rotation 3',
  DoomTowerHard80DarkFae3 = 'Doom Tower Hard - Floor 80:  Dark Fae: Rotation 3',
  DoomTowerHard90Dreadhorn3 = 'Doom Tower Hard - Floor 90:  Dreadhorn: Rotation 3',
  DoomTowerHard100CScarabKing3 = 'Doom Tower Hard - Floor 100:  Scarab King: Rotation 3',
  DoomTowerHard110CelestialGriffin3 = 'Doom Tower Hard - Floor 110:  Celestial Griffin: Rotation 3',
  DoomTowerHard120DarkFae3 = 'Doom Tower Hard - Floor 120:  Dark Fae: Rotation 3',
}
