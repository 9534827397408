import { mergeStyles } from '@fluentui/merge-styles';

export const buttonStyle = mergeStyles({
  cursor: 'pointer',
  border: 'none',
  fontSize: '1.2rem',
  display: 'flex',
  alignItems: 'center',
  gap: 15,
  padding: '10px 25px',
  transitionProperty: 'background-color color',
  transition: '.25s ease-in-out',
});
