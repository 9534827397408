import React from 'react';
import './ProductList.css';

export type IProduct = {
  id: string;
  name: string;
  description: string;
  price: number;
  currency: string;
  interval: string;
};

interface ProductTileProps {
  products: IProduct[];
  roles: any;
}

const ProductTile: React.FC<ProductTileProps> = ({ products, roles }) => {
  const uniquePrices = Array.from(new Set(products.map((product) => product.price)));

  const priceList = uniquePrices.map((price) => {
    const priceString = `${(price / 100).toFixed(2)}`;

    const interval = products.find((product) => product.price === price)?.interval;

    return (
      <li key={price}>
        ${priceString}/{interval}
      </li>
    );
  });

  if (products[0]) {
    console.log(`products[0].name`, products[0].name);
  }

  return (
    <div
      className={
        products[0] &&
        (roles.includes(`${products[0].name} (Monthly)`) ||
          roles.includes(`${products[0].name} (Yearly)`) ||
          roles.includes(`${products[0].name}`))
          ? 'product-tile active'
          : 'product-tile notActive'
      }
    >
      <h3>{products[0] && products[0].name}</h3>
      <ul>{priceList}</ul>
    </div>
  );
};

interface ProductListProps {
  products: any;
  roles: any;
}

const ProductsList: React.FC<ProductListProps> = ({ products, roles }) => {
  // Create a copy of the products array and sort it by price in ascending order
  const sortedProducts = [...products].sort((a, b) => a.price - b.price);

  // Group the products by name
  const groupedProducts: { [name: string]: IProduct[] } = {};

  sortedProducts.forEach((product) => {
    if (groupedProducts[product.name]) {
      groupedProducts[product.name]!.push(product);
    } else {
      groupedProducts[product.name] = [product];
    }
  });

  const productGroups = Object.values(groupedProducts);

  return (
    <div className="product-list flex">
      {productGroups.map((products, index) => (
        <ProductTile key={index} products={products} roles={roles} />
      ))}
    </div>
  );
};

export default ProductsList;
