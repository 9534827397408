import React, { Component } from 'react';
import './ChampMasteriesCondensed.css';
import { NextGenImage } from '../../Components/Library';
import { MasteryKindId } from 'Types';

interface ChampMasteriesCondensedProps {
  masteries: (MasteryKindId | string)[] | undefined;
  local?: boolean;
}

const ChampMasteriesCondensed: React.FC<ChampMasteriesCondensedProps> = ({ masteries, local }) => {
  const thisChampsMasteries = masteries;
  //   console.log(`thisChampsMasteries`, thisChampsMasteries);

  return (
    <div className="ChampMasteriesCondensed-wrap">
      {thisChampsMasteries && thisChampsMasteries?.length > 0 ? (
        <div
          ng-repeat="section in $ctrl.masteries"
          className="text-center masteries-tree all mt-2 masteries-tree-small ChampMasteriesCondensed"
        >
          <div className="masteries-row d-flex justify-content-center">
            {/* Offense */}
            {thisChampsMasteries?.includes(500112) || thisChampsMasteries.includes('blade-diciple') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="blade_disciple"
                  title="Blade Diciple: +75 Attack."
                  alt="Blade Diciple: +75 Attack."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="blade_disciple"
                  title="Blade Diciple: +75 Attack."
                  alt="Blade Diciple: +75 Attack."
                />
              </div>
            )}

            {thisChampsMasteries.includes(500113) || thisChampsMasteries.includes('deadly-precision') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="deadly_precision"
                  title="Deadly Precision: +5% Critical Rate."
                  alt="Deadly Precision: +5% Critical Rate."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="deadly_precision"
                  title="Deadly Precision: +5% Critical Rate."
                  alt="Deadly Precision: +5% Critical Rate."
                />
              </div>
            )}

            {/* Defense */}
            {thisChampsMasteries.includes(500212) || thisChampsMasteries.includes('tough-skin') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="tough_skin"
                  title="Tough Skin: Defense + 75."
                  alt="Tough Skin: Defense + 75."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="tough_skin"
                  title="Tough Skin: Defense + 75."
                  alt="Tough Skin: Defense + 75."
                />
              </div>
            )}

            {thisChampsMasteries.includes(500213) || thisChampsMasteries.includes('defiant') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="defiant"
                  title="Defiant: Resistance + 10."
                  alt="Defiant: Resistance + 10."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="defiant"
                  title="Defiant: Resistance + 10."
                  alt="Defiant: Resistance + 10."
                />
              </div>
            )}

            {/* Support */}
            {thisChampsMasteries.includes(500312) || thisChampsMasteries.includes('steadfast') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="steadfast"
                  title="Steadfast: Max HP +810."
                  alt="Steadfast: Max HP +810."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="steadfast"
                  title="Steadfast: Max HP +810."
                  alt="Steadfast: Max HP +810."
                />
              </div>
            )}

            {thisChampsMasteries.includes(500313) || thisChampsMasteries.includes('pinpoint-accuracy') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="pinpoint_accuracy"
                  title="Pinpoint Accuracy: Acc + 10."
                  alt="Pinpoint Accuracy: Acc + 10."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="pinpoint_accuracy"
                  title="Pinpoint Accuracy: Acc + 10."
                  alt="Pinpoint Accuracy: Acc + 10."
                />
              </div>
            )}
          </div>

          <div className="masteries-row d-flex justify-content-center darker">
            {/* Offense */}
            {thisChampsMasteries.includes(500121) || thisChampsMasteries.includes('heart-of-glory') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="heart_of_glory"
                  title="Heart of Glory: +5% damage when attacking with full health."
                  alt="Heart of Glory: +5% damage when attacking with full health."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="heart_of_glory"
                  title="Heart of Glory: +5% damage when attacking with full health."
                  alt="Heart of Glory: +5% damage when attacking with full health."
                />
              </div>
            )}

            {thisChampsMasteries.includes(500122) || thisChampsMasteries.includes('keen-strike') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="keen_strike"
                  title="Keen Strike: +10% critical damage."
                  alt="Keen Strike: +10% critical damage."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="keen_strike"
                  title="Keen Strike: +10% critical damage."
                  alt="Keen Strike: +10% critical damage."
                />
              </div>
            )}

            {thisChampsMasteries.includes(500123) || thisChampsMasteries.includes('shield-breaker') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="shield_breaker"
                  title="Shield Breaker: +25% damage to targets with a [Shield] buff."
                  alt="Shield Breaker: +25% damage to targets with a [Shield] buff."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="shield_breaker"
                  title="Shield Breaker: +25% damage to targets with a [Shield] buff."
                  alt="Shield Breaker: +25% damage to targets with a [Shield] buff."
                />
              </div>
            )}

            {thisChampsMasteries.includes(500124) || thisChampsMasteries.includes('grim-resolve') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="grim_resolve"
                  title="Grim Resolve: +5% damage when attacking with 50% hp or less."
                  alt="Grim Resolve: +5% damage when attacking with 50% hp or less."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="grim_resolve"
                  title="Grim Resolve: +5% damage when attacking with 50% hp or less."
                  alt="Grim Resolve: +5% damage when attacking with 50% hp or less."
                />
              </div>
            )}
            {/* Defense */}
            {thisChampsMasteries.includes(500221) || thisChampsMasteries.includes('blastproof') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="blastproof"
                  title="Blastproof: Decreases damage from AoE attacks by 5%."
                  alt="Blastproof: Decreases damage from AoE attacks by 5%."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="blastproof"
                  title="Blastproof: Decreases damage from AoE attacks by 5%."
                  alt="Blastproof: Decreases damage from AoE attacks by 5%."
                />
              </div>
            )}

            {thisChampsMasteries.includes(500222) || thisChampsMasteries.includes('rejuvenation') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="rejuvenation"
                  title="Rejuvenation: Increases the amount of healing and the value of [Shield] buffs this Champion receives by 5%."
                  alt="Rejuvenation: Increases the amount of healing and the value of [Shield] buffs this Champion receives by 5%."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="rejuvenation"
                  title="Rejuvenation: Increases the amount of healing and the value of [Shield] buffs this Champion receives by 5%."
                  alt="Rejuvenation: Increases the amount of healing and the value of [Shield] buffs this Champion receives by 5%."
                />
              </div>
            )}

            {thisChampsMasteries.includes(500223) || thisChampsMasteries.includes('mighty-endurance') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="mighty_endurance"
                  title="Mighty Endurance: Decreases the damage received by 10% if this Champion has [Stun], [Sleep], or [Freeze] debuffs."
                  alt="Mighty Endurance: Decreases the damage received by 10% if this Champion has [Stun], [Sleep], or [Freeze] debuffs."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="mighty_endurance"
                  title="Mighty Endurance: Decreases the damage received by 10% if this Champion has [Stun], [Sleep], or [Freeze] debuffs."
                  alt="Mighty Endurance: Decreases the damage received by 10% if this Champion has [Stun], [Sleep], or [Freeze] debuffs."
                />
              </div>
            )}

            {thisChampsMasteries.includes(500224) || thisChampsMasteries.includes('improved-parry') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="improved_parry"
                  title="Improved Parry: Decreases damage received by this Champion by 8% when this Champion is hit with a critical hit."
                  alt="Improved Parry: Decreases damage received by this Champion by 8% when this Champion is hit with a critical hit."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="improved_parry"
                  title="Improved Parry: Decreases damage received by this Champion by 8% when this Champion is hit with a critical hit."
                  alt="Improved Parry: Decreases damage received by this Champion by 8% when this Champion is hit with a critical hit."
                />
              </div>
            )}

            {/* Support */}
            {thisChampsMasteries.includes(500321) || thisChampsMasteries.includes('lay-on-hands') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="lay_on_hands"
                  title="Lay on Hands: Increases the value of heals this Champion casts by 5%."
                  alt="Lay on Hands: Increases the value of heals this Champion casts by 5%."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="lay_on_hands"
                  title="Lay on Hands: Increases the value of heals this Champion casts by 5%."
                  alt="Lay on Hands: Increases the value of heals this Champion casts by 5%."
                />
              </div>
            )}

            {thisChampsMasteries.includes(500322) || thisChampsMasteries.includes('shieldbearer') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="shieldbearer"
                  title="Shieldbearer: Increases the value of [Shield] buffs this Champion casts by 5%."
                  alt="Shieldbearer: Increases the value of [Shield] buffs this Champion casts by 5%."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="shieldbearer"
                  title="Shieldbearer: Increases the value of [Shield] buffs this Champion casts by 5%."
                  alt="Shieldbearer: Increases the value of [Shield] buffs this Champion casts by 5%."
                />
              </div>
            )}

            {thisChampsMasteries.includes(500323) || thisChampsMasteries.includes('exhalt-in-death') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="exalt_in_death"
                  title="Exhalt in Death: Heals this Champion by 10% of their Max HP the first time an enemy is killed in each round."
                  alt="Exhalt in Death: Heals this Champion by 10% of their Max HP the first time an enemy is killed in each round."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="exalt_in_death"
                  title="Exhalt in Death: Heals this Champion by 10% of their Max HP the first time an enemy is killed in each round."
                  alt="Exhalt in Death: Heals this Champion by 10% of their Max HP the first time an enemy is killed in each round."
                />
              </div>
            )}

            {thisChampsMasteries.includes(500324) || thisChampsMasteries.includes('charged-focus') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="charged_focus"
                  title="Charged Focus: Increases ACC by 20 when this Champion has no skills on Cooldown."
                  alt="Charged Focus: Increases ACC by 20 when this Champion has no skills on Cooldown."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="charged_focus"
                  title="Charged Focus: Increases ACC by 20 when this Champion has no skills on Cooldown."
                  alt="Charged Focus: Increases ACC by 20 when this Champion has no skills on Cooldown."
                />
              </div>
            )}
          </div>

          {/* Offense */}
          <div className="masteries-row d-flex justify-content-center">
            {thisChampsMasteries.includes(500131) || thisChampsMasteries.includes('single-out') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="single_out"
                  title="Single Out: Increases damage inflicted to targets with less than 40% hp by 8%."
                  alt="Single Out: Increases damage inflicted to targets with less than 40% hp by 8%."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="single_out"
                  title="Single Out: Increases damage inflicted to targets with less than 40% hp by 8%."
                  alt="Single Out: Increases damage inflicted to targets with less than 40% hp by 8%."
                />
              </div>
            )}

            {thisChampsMasteries.includes(500132) || thisChampsMasteries.includes('life-drinker') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="life_drinker"
                  title="Life Drinker: Heals by 5% of damage inflicted when attacking with 50% HP or less."
                  alt="Life Drinker: Heals by 5% of damage inflicted when attacking with 50% HP or less."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="life_drinker"
                  title="Life Drinker: Heals by 5% of damage inflicted when attacking with 50% HP or less."
                  alt="Life Drinker: Heals by 5% of damage inflicted when attacking with 50% HP or less."
                />
              </div>
            )}

            {thisChampsMasteries.includes(500133) || thisChampsMasteries.includes('whirlwind-of-death') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="whirlwind_of_death"
                  title="Whirlwind of Death: Increases speed by 6 for each enemy you have killed, stacks across rounds in a battle, to a maximum of 18 speed."
                  alt="Whirlwind of Death: Increases speed by 6 for each enemy you have killed, stacks across rounds in a battle, to a maximum of 18 speed."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="whirlwind_of_death"
                  title="Whirlwind of Death: Increases speed by 6 for each enemy you have killed, stacks across rounds in a battle, to a maximum of 18 speed."
                  alt="Whirlwind of Death: Increases speed by 6 for each enemy you have killed, stacks across rounds in a battle, to a maximum of 18 speed."
                />
              </div>
            )}

            {thisChampsMasteries.includes(500134) || thisChampsMasteries.includes('ruthless-ambush') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="ruthless_ambush"
                  title="Ruthless Ambush: Increases damage inflicted by 8% for the first hit on each enemy."
                  alt="Ruthless Ambush: Increases damage inflicted by 8% for the first hit on each enemy."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="ruthless_ambush"
                  title="Ruthless Ambush: Increases damage inflicted by 8% for the first hit on each enemy."
                  alt="Ruthless Ambush: Increases damage inflicted by 8% for the first hit on each enemy."
                />
              </div>
            )}
            {/* Defense */}
            {thisChampsMasteries.includes(500231) || thisChampsMasteries.includes('shadow-heal') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="shadow_heal"
                  title="Shadow Heal: Heals this Champion by 6% of their MAX HP each time an enemy is healed. Occurs once per turn."
                  alt="Shadow Heal: Heals this Champion by 6% of their MAX HP each time an enemy is healed. Occurs once per turn."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="shadow_heal"
                  title="Shadow Heal: Heals this Champion by 6% of their MAX HP each time an enemy is healed. Occurs once per turn."
                  alt="Shadow Heal: Heals this Champion by 6% of their MAX HP each time an enemy is healed. Occurs once per turn."
                />
              </div>
            )}

            {thisChampsMasteries.includes(500232) || thisChampsMasteries.includes('resurgent') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="resurgent"
                  title="Resurgent: Has a 50% chance to remove 1 random debuff from this Champion when they lose 25% of their MAX HP or more from a single enemy skill."
                  alt="Resurgent: Has a 50% chance to remove 1 random debuff from this Champion when they lose 25% of their MAX HP or more from a single enemy skill."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="resurgent"
                  title="Resurgent: Has a 50% chance to remove 1 random debuff from this Champion when they lose 25% of their MAX HP or more from a single enemy skill."
                  alt="Resurgent: Has a 50% chance to remove 1 random debuff from this Champion when they lose 25% of their MAX HP or more from a single enemy skill."
                />
              </div>
            )}

            {thisChampsMasteries.includes(500233) || thisChampsMasteries.includes('bloodthirst') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="bloodthirst"
                  title="Bloodthirst: Heals this Champion by 10% of their max HP when they kill an enemy target. Cooldown: 1 turn."
                  alt="Bloodthirst: Heals this Champion by 10% of their max HP when they kill an enemy target. Cooldown: 1 turn."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="bloodthirst"
                  title="Bloodthirst: Heals this Champion by 10% of their max HP when they kill an enemy target. Cooldown: 1 turn."
                  alt="Bloodthirst: Heals this Champion by 10% of their max HP when they kill an enemy target. Cooldown: 1 turn."
                />
              </div>
            )}

            {thisChampsMasteries.includes(500234) || thisChampsMasteries.includes('wisdom-of-battle') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="wisdom_of_battle"
                  title="Wisdom of Battle: Has a 30% chance of placing a [Block Debuffs] buff on this Champion for 1 turn when [Stun], [Sleep] or [Freeze] debuffs expire on this Champion."
                  alt="Wisdom of Battle: Has a 30% chance of placing a [Block Debuffs] buff on this Champion for 1 turn when [Stun], [Sleep] or [Freeze] debuffs expire on this Champion."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="wisdom_of_battle"
                  title="Wisdom of Battle: Has a 30% chance of placing a [Block Debuffs] buff on this Champion for 1 turn when [Stun], [Sleep] or [Freeze] debuffs expire on this Champion."
                  alt="Wisdom of Battle: Has a 30% chance of placing a [Block Debuffs] buff on this Champion for 1 turn when [Stun], [Sleep] or [Freeze] debuffs expire on this Champion."
                />
              </div>
            )}

            {/* Support */}
            {thisChampsMasteries.includes(500331) || thisChampsMasteries.includes('healing-savior') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="healing_savior"
                  title="Healing Savior: Increases the amount of healing and the value of [Shield] buffs placed by this Champion by 10% if the target ally has 40% HP or less."
                  alt="Healing Savior: Increases the amount of healing and the value of [Shield] buffs placed by this Champion by 10% if the target ally has 40% HP or less."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="healing_savior"
                  title="Healing Savior: Increases the amount of healing and the value of [Shield] buffs placed by this Champion by 10% if the target ally has 40% HP or less."
                  alt="Healing Savior: Increases the amount of healing and the value of [Shield] buffs placed by this Champion by 10% if the target ally has 40% HP or less."
                />
              </div>
            )}

            {thisChampsMasteries.includes(500332) || thisChampsMasteries.includes('rapid-response') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="rapid_response"
                  title="Rapid Response: Has a 30% chance of increasing the Turn Meter by 10% when a buff cast by this Champion is removed or expires."
                  alt="Rapid Response: Has a 30% chance of increasing the Turn Meter by 10% when a buff cast by this Champion is removed or expires."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="rapid_response"
                  title="Rapid Response: Has a 30% chance of increasing the Turn Meter by 10% when a buff cast by this Champion is removed or expires."
                  alt="Rapid Response: Has a 30% chance of increasing the Turn Meter by 10% when a buff cast by this Champion is removed or expires."
                />
              </div>
            )}

            {thisChampsMasteries.includes(500333) || thisChampsMasteries.includes('swarm-smiter') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="swarm_smiter"
                  title="Swarm Smiter: Increases Acc by 4 for each enemy alive. Stacks up to 16."
                  alt="Swarm Smiter: Increases Acc by 4 for each enemy alive. Stacks up to 16."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="swarm_smiter"
                  title="Swarm Smiter: Increases Acc by 4 for each enemy alive. Stacks up to 16."
                  alt="Swarm Smiter: Increases Acc by 4 for each enemy alive. Stacks up to 16."
                />
              </div>
            )}

            {thisChampsMasteries.includes(500334) || thisChampsMasteries.includes('arcane-celerity') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="arcane_celerity"
                  title="Arcane Celerity: Has a 30% chance of increasing the Turn Meter by 10% when a debuff cast by this Champion is removed or expires."
                  alt="Arcane Celerity: Has a 30% chance of increasing the Turn Meter by 10% when a debuff cast by this Champion is removed or expires."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="arcane_celerity"
                  title="Arcane Celerity: Has a 30% chance of increasing the Turn Meter by 10% when a debuff cast by this Champion is removed or expires."
                  alt="Arcane Celerity: Has a 30% chance of increasing the Turn Meter by 10% when a debuff cast by this Champion is removed or expires."
                />
              </div>
            )}
          </div>

          {/* Offense */}
          <div className="masteries-row d-flex justify-content-center darker">
            {thisChampsMasteries.includes(500141) || thisChampsMasteries.includes('bring-it-down') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="bring_it_down"
                  title="Bring it Down: Increases damage inflicted by 6% when attacking targets with higher max HP."
                  alt="Bring it Down: Increases damage inflicted by 6% when attacking targets with higher max HP."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="bring_it_down"
                  title="Bring it Down: Increases damage inflicted by 6% when attacking targets with higher max HP."
                  alt="Bring it Down: Increases damage inflicted by 6% when attacking targets with higher max HP."
                />
              </div>
            )}

            {thisChampsMasteries.includes(500142) || thisChampsMasteries.includes('wrath-of-the-slain') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="wrath_of_the_slain"
                  title="Wrath of the Slain: increases damage by 5% for each dead ally, up to 10%."
                  alt="Wrath of the Slain: increases damage by 5% for each dead ally, up to 10%."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="wrath_of_the_slain"
                  title="Wrath of the Slain: increases damage by 5% for each dead ally, up to 10%."
                  alt="Wrath of the Slain: increases damage by 5% for each dead ally, up to 10%."
                />
              </div>
            )}

            {thisChampsMasteries.includes(500143) || thisChampsMasteries.includes('cycle-of-violence') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="cycle_of_violence"
                  title="Cycle of Violence: Has a 30% chance of decreasing the cooldown of a random skill by 1 turn if the damage inflicted by a skill exceeds 30% of the target's max HP. Occurs once per turn."
                  alt="Cycle of Violence: Has a 30% chance of decreasing the cooldown of a random skill by 1 turn if the damage inflicted by a skill exceeds 30% of the target's max HP. Occurs once per turn."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="cycle_of_violence"
                  title="Cycle of Violence: Has a 30% chance of decreasing the cooldown of a random skill by 1 turn if the damage inflicted by a skill exceeds 30% of the target's max HP. Occurs once per turn."
                  alt="Cycle of Violence: Has a 30% chance of decreasing the cooldown of a random skill by 1 turn if the damage inflicted by a skill exceeds 30% of the target's max HP. Occurs once per turn."
                />
              </div>
            )}

            {thisChampsMasteries.includes(500144) || thisChampsMasteries.includes('opportunist') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="opportunist"
                  title="Opportunist: Increases damage inflicted to targets with [Stun], [Sleep], or [Freeze] debuffs by 12%."
                  alt="Opportunist: Increases damage inflicted to targets with [Stun], [Sleep], or [Freeze] debuffs by 12%."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="opportunist"
                  title="Opportunist: Increases damage inflicted to targets with [Stun], [Sleep], or [Freeze] debuffs by 12%."
                  alt="Opportunist: Increases damage inflicted to targets with [Stun], [Sleep], or [Freeze] debuffs by 12%."
                />
              </div>
            )}

            {/* Defense */}
            {thisChampsMasteries.includes(500241) || thisChampsMasteries.includes('solidarity') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="solidarity"
                  title="Solidarity: Increases Ally Resist by 5 for each buff placed on them by this Champion."
                  alt="Solidarity: Increases Ally Resist by 5 for each buff placed on them by this Champion."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="solidarity"
                  title="Solidarity: Increases Ally Resist by 5 for each buff placed on them by this Champion."
                  alt="Solidarity: Increases Ally Resist by 5 for each buff placed on them by this Champion."
                />
              </div>
            )}

            {thisChampsMasteries.includes(500242) || thisChampsMasteries.includes('delay-death') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="delay_death"
                  title="Delay Death: Reduces the damage this Champion receives from a specific enemy by 0.75% with each hit taken from that enemy, Damage reduction stacks up to 6% for each enemy."
                  alt="Delay Death: Reduces the damage this Champion receives from a specific enemy by 0.75% with each hit taken from that enemy, Damage reduction stacks up to 6% for each enemy."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="delay_death"
                  title="Delay Death: Reduces the damage this Champion receives from a specific enemy by 0.75% with each hit taken from that enemy, Damage reduction stacks up to 6% for each enemy."
                  alt="Delay Death: Reduces the damage this Champion receives from a specific enemy by 0.75% with each hit taken from that enemy, Damage reduction stacks up to 6% for each enemy."
                />
              </div>
            )}

            {thisChampsMasteries.includes(500243) || thisChampsMasteries.includes('harvest-despair') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="harvest_despair"
                  title="Harvest Despair: Has a 60% chance of placing a [Leech] debuff for 1 turn when placing [Stun], [Sleep], or [Freeze] debuffs."
                  alt="Harvest Despair: Has a 60% chance of placing a [Leech] debuff for 1 turn when placing [Stun], [Sleep], or [Freeze] debuffs."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="harvest_despair"
                  title="Harvest Despair: Has a 60% chance of placing a [Leech] debuff for 1 turn when placing [Stun], [Sleep], or [Freeze] debuffs."
                  alt="Harvest Despair: Has a 60% chance of placing a [Leech] debuff for 1 turn when placing [Stun], [Sleep], or [Freeze] debuffs."
                />
              </div>
            )}

            {thisChampsMasteries.includes(500244) || thisChampsMasteries.includes('stubborness') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="stubborness"
                  title="Stubborness: Increases RESIST by 10 for each debuff on this champion, Stacks up to 30."
                  alt="Stubborness: Increases RESIST by 10 for each debuff on this champion, Stacks up to 30."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="stubborness"
                  title="Stubborness: Increases RESIST by 10 for each debuff on this champion, Stacks up to 30."
                  alt="Stubborness: Increases RESIST by 10 for each debuff on this champion, Stacks up to 30."
                />
              </div>
            )}

            {/* Support */}
            {thisChampsMasteries.includes(500341) || thisChampsMasteries.includes('merciful-aid') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="merciful_aid"
                  title="Merciful Aid: Increases the amount of healing and the value of [Shield] buffs placed by this Champion by 15% if the target ally has [Stun], [Sleep], or [Freeze] debuffs."
                  alt="Merciful Aid: Increases the amount of healing and the value of [Shield] buffs placed by this Champion by 15% if the target ally has [Stun], [Sleep], or [Freeze] debuffs."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="merciful_aid"
                  title="Merciful Aid: Increases the amount of healing and the value of [Shield] buffs placed by this Champion by 15% if the target ally has [Stun], [Sleep], or [Freeze] debuffs."
                  alt="Merciful Aid: Increases the amount of healing and the value of [Shield] buffs placed by this Champion by 15% if the target ally has [Stun], [Sleep], or [Freeze] debuffs."
                />
              </div>
            )}

            {thisChampsMasteries.includes(500342) || thisChampsMasteries.includes('cycle-of-magic') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="cycle_of_magic"
                  title="Cycle of Magic: Has a 5% chance of decreasing the cooldown of a random skill by 1 turn at the start of every turn."
                  alt="Cycle of Magic: Has a 5% chance of decreasing the cooldown of a random skill by 1 turn at the start of every turn."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="cycle_of_magic"
                  title="Cycle of Magic: Has a 5% chance of decreasing the cooldown of a random skill by 1 turn at the start of every turn."
                  alt="Cycle of Magic: Has a 5% chance of decreasing the cooldown of a random skill by 1 turn at the start of every turn."
                />
              </div>
            )}

            {thisChampsMasteries.includes(500343) || thisChampsMasteries.includes('lore-of-steel') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="lore_of_steel"
                  title="Lore of Steel: Increases the Set Bonuses of Basic Artifact Sets by 15%. This increase is multiplicative, not additive."
                  alt="Lore of Steel: Increases the Set Bonuses of Basic Artifact Sets by 15%. This increase is multiplicative, not additive."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="lore_of_steel"
                  title="Lore of Steel: Increases the Set Bonuses of Basic Artifact Sets by 15%. This increase is multiplicative, not additive."
                  alt="Lore of Steel: Increases the Set Bonuses of Basic Artifact Sets by 15%. This increase is multiplicative, not additive."
                />
              </div>
            )}

            {thisChampsMasteries.includes(500344) || thisChampsMasteries.includes('evil-eye') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="evil_eye"
                  title="Evil Eye: Decreases the target's Turn Meter when this Champion hits target for the first time with the default skill. Decreases the Turn Meter by 20% with single-target Skills and by 5% with AoE Skills."
                  alt="Evil Eye: Decreases the target's Turn Meter when this Champion hits target for the first time with the default skill. Decreases the Turn Meter by 20% with single-target Skills and by 5% with AoE Skills."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="evil_eye"
                  title="Evil Eye: Decreases the target's Turn Meter when this Champion hits target for the first time with the default skill. Decreases the Turn Meter by 20% with single-target Skills and by 5% with AoE Skills."
                  alt="Evil Eye: Decreases the target's Turn Meter when this Champion hits target for the first time with the default skill. Decreases the Turn Meter by 20% with single-target Skills and by 5% with AoE Skills."
                />
              </div>
            )}
          </div>

          {/* Offense */}
          <div className="masteries-row d-flex justify-content-center">
            {thisChampsMasteries.includes(500151) || thisChampsMasteries.includes('methodical') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="methodical"
                  title="Methodical: Increases the damage inflicted by this Champion's default Skill by 2% each time it is used during battle. Stacks across each round in a battle, up to 10%."
                  alt="Methodical: Increases the damage inflicted by this Champion's default Skill by 2% each time it is used during battle. Stacks across each round in a battle, up to 10%."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="methodical"
                  title="Methodical: Increases the damage inflicted by this Champion's default Skill by 2% each time it is used during battle. Stacks across each round in a battle, up to 10%."
                  alt="Methodical: Increases the damage inflicted by this Champion's default Skill by 2% each time it is used during battle. Stacks across each round in a battle, up to 10%."
                />
              </div>
            )}

            {thisChampsMasteries.includes(500152) || thisChampsMasteries.includes('kill-streak') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="kill_streak"
                  title="Kill Streak: Increases damage inflicted by 6% in arena and 3% in all other locations for each enemy killed by this Champion in Battle. Stacks across each round, up to 12%."
                  alt="Kill Streak: Increases damage inflicted by 6% in arena and 3% in all other locations for each enemy killed by this Champion in Battle. Stacks across each round, up to 12%."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="kill_streak"
                  title="Kill Streak: Increases damage inflicted by 6% in arena and 3% in all other locations for each enemy killed by this Champion in Battle. Stacks across each round, up to 12%."
                  alt="Kill Streak: Increases damage inflicted by 6% in arena and 3% in all other locations for each enemy killed by this Champion in Battle. Stacks across each round, up to 12%."
                />
              </div>
            )}

            {thisChampsMasteries.includes(500153) || thisChampsMasteries.includes('blood-shield') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="blood_shield"
                  title="Blood Shield: Places a [Shield] buff on this Champion for 1 turn when this Champion kills an enemy. The value of the [Shield] is equal to 15% of this Champion's MAX HP & Occurs once per turn."
                  alt="Blood Shield: Places a [Shield] buff on this Champion for 1 turn when this Champion kills an enemy. The value of the [Shield] is equal to 15% of this Champion's MAX HP & Occurs once per turn."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="blood_shield"
                  title="Blood Shield: Places a [Shield] buff on this Champion for 1 turn when this Champion kills an enemy. The value of the [Shield] is equal to 15% of this Champion's MAX HP & Occurs once per turn."
                  alt="Blood Shield: Places a [Shield] buff on this Champion for 1 turn when this Champion kills an enemy. The value of the [Shield] is equal to 15% of this Champion's MAX HP & Occurs once per turn."
                />
              </div>
            )}

            {thisChampsMasteries.includes(500154) || thisChampsMasteries.includes('stoked-to-fury') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="stoked_to_fury"
                  title="Stoked to Fury: Increases damage inflicted by 4% for each debuff on this Champion. Stacks up to 12%."
                  alt="Stoked to Fury: Increases damage inflicted by 4% for each debuff on this Champion. Stacks up to 12%."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="stoked_to_fury"
                  title="Stoked to Fury: Increases damage inflicted by 4% for each debuff on this Champion. Stacks up to 12%."
                  alt="Stoked to Fury: Increases damage inflicted by 4% for each debuff on this Champion. Stacks up to 12%."
                />
              </div>
            )}
            {/* Defense */}
            {thisChampsMasteries.includes(500251) || thisChampsMasteries.includes('selfless-defender') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="selfless_defender"
                  title="Selfless Defender: Decreases the damage an ally received from the first enemy hit in each round by 20%. This Champion will receive that damage instead."
                  alt="Selfless Defender: Decreases the damage an ally received from the first enemy hit in each round by 20%. This Champion will receive that damage instead."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="selfless_defender"
                  title="Selfless Defender: Decreases the damage an ally received from the first enemy hit in each round by 20%. This Champion will receive that damage instead."
                  alt="Selfless Defender: Decreases the damage an ally received from the first enemy hit in each round by 20%. This Champion will receive that damage instead."
                />
              </div>
            )}

            {thisChampsMasteries.includes(500252) || thisChampsMasteries.includes('cycle-of-revenge') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="cycle_of_revenge"
                  title="Cycle of Revenge: Has a 50% chance of increasing the Turn Meter by 15% when an ally is attacked with a critical hit. Will only increase the Turn Meter once if an ally receives multiple critical hits from a single skill."
                  alt="Cycle of Revenge: Has a 50% chance of increasing the Turn Meter by 15% when an ally is attacked with a critical hit. Will only increase the Turn Meter once if an ally receives multiple critical hits from a single skill."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="cycle_of_revenge"
                  title="Cycle of Revenge: Has a 50% chance of increasing the Turn Meter by 15% when an ally is attacked with a critical hit. Will only increase the Turn Meter once if an ally receives multiple critical hits from a single skill."
                  alt="Cycle of Revenge: Has a 50% chance of increasing the Turn Meter by 15% when an ally is attacked with a critical hit. Will only increase the Turn Meter once if an ally receives multiple critical hits from a single skill."
                />
              </div>
            )}

            {thisChampsMasteries.includes(500253) || thisChampsMasteries.includes('retribution') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="retribution"
                  title="Retribution: Has a 50% chance to counterattack when this Champion loses 25% of their MAX HP or more from a single enemy skill."
                  alt="Retribution: Has a 50% chance to counterattack when this Champion loses 25% of their MAX HP or more from a single enemy skill."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="retribution"
                  title="Retribution: Has a 50% chance to counterattack when this Champion loses 25% of their MAX HP or more from a single enemy skill."
                  alt="Retribution: Has a 50% chance to counterattack when this Champion loses 25% of their MAX HP or more from a single enemy skill."
                />
              </div>
            )}

            {thisChampsMasteries.includes(500254) || thisChampsMasteries.includes('deterrence') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="deterrence"
                  title="Deterrence: Has a 20% chance to counterattack an enemy when they apply a [Stun], [Sleep], or [Freeze] debuff on an ally."
                  alt="Deterrence: Has a 20% chance to counterattack an enemy when they apply a [Stun], [Sleep], or [Freeze] debuff on an ally."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="deterrence"
                  title="Deterrence: Has a 20% chance to counterattack an enemy when they apply a [Stun], [Sleep], or [Freeze] debuff on an ally."
                  alt="Deterrence: Has a 20% chance to counterattack an enemy when they apply a [Stun], [Sleep], or [Freeze] debuff on an ally."
                />
              </div>
            )}

            {/* Support */}
            {thisChampsMasteries.includes(500351) || thisChampsMasteries.includes('lasting-gifts') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="lasting_gifts"
                  title="Lasting Gifts: Has a 30% chance to extend the duration of any buff cast by this Champion by 1 turn. It will not extend [Block Damage], [Unkillable] or [Revive on Death] buffs."
                  alt="Lasting Gifts: Has a 30% chance to extend the duration of any buff cast by this Champion by 1 turn. It will not extend [Block Damage], [Unkillable] or [Revive on Death] buffs."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="lasting_gifts"
                  title="Lasting Gifts: Has a 30% chance to extend the duration of any buff cast by this Champion by 1 turn. It will not extend [Block Damage], [Unkillable] or [Revive on Death] buffs."
                  alt="Lasting Gifts: Has a 30% chance to extend the duration of any buff cast by this Champion by 1 turn. It will not extend [Block Damage], [Unkillable] or [Revive on Death] buffs."
                />
              </div>
            )}

            {thisChampsMasteries.includes(500352) || thisChampsMasteries.includes('spirit-haste') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="spirit_haste"
                  title="Spirit Haste: Increases SPD by 8 for each dead ally. Stacks up to 24."
                  alt="Spirit Haste: Increases SPD by 8 for each dead ally. Stacks up to 24."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="spirit_haste"
                  title="Spirit Haste: Increases SPD by 8 for each dead ally. Stacks up to 24."
                  alt="Spirit Haste: Increases SPD by 8 for each dead ally. Stacks up to 24."
                />
              </div>
            )}

            {thisChampsMasteries.includes(500353) || thisChampsMasteries.includes('sniper') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="sniper"
                  title="Sniper: Increases the chances of placing any debuff from Skills or Artifacts by 5%. It will not increase the chances of placing [Stun], [Sleep], [Freeze] or [Provoke] debuffs."
                  alt="Sniper: Increases the chances of placing any debuff from Skills or Artifacts by 5%. It will not increase the chances of placing [Stun], [Sleep], [Freeze] or [Provoke] debuffs."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="sniper"
                  title="Sniper: Increases the chances of placing any debuff from Skills or Artifacts by 5%. It will not increase the chances of placing [Stun], [Sleep], [Freeze] or [Provoke] debuffs."
                  alt="Sniper: Increases the chances of placing any debuff from Skills or Artifacts by 5%. It will not increase the chances of placing [Stun], [Sleep], [Freeze] or [Provoke] debuffs."
                />
              </div>
            )}

            {thisChampsMasteries.includes(500354) || thisChampsMasteries.includes('master-hexer') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="master_hexer"
                  title="Master Hexer: Has a 30% chance to extend the duration of any debuff cast by this Champion by 1 turn. It will not extend [Stun], [Sleep], [Freeze] or [Provoke] debuffs."
                  alt="Master Hexer: Has a 30% chance to extend the duration of any debuff cast by this Champion by 1 turn. It will not extend [Stun], [Sleep], [Freeze] or [Provoke] debuffs."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="master_hexer"
                  title="Master Hexer: Has a 30% chance to extend the duration of any debuff cast by this Champion by 1 turn. It will not extend [Stun], [Sleep], [Freeze] or [Provoke] debuffs."
                  alt="Master Hexer: Has a 30% chance to extend the duration of any debuff cast by this Champion by 1 turn. It will not extend [Stun], [Sleep], [Freeze] or [Provoke] debuffs."
                />
              </div>
            )}
          </div>

          {/* Offense */}
          <div className="masteries-row d-flex justify-content-center darker last-row">
            {thisChampsMasteries.includes(500161) || thisChampsMasteries.includes('warmaster') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="warmaster"
                  title="Warmaster: Has a 60% chance of inflicting bonus damage when attacking. Bonus damage is equal to 10% of the target Champion's MAX HP or 4% of the target's MAX HP when attacking Bosses. Bonus damage can only occur once per Skill and does not count as an extra hit.Damage based on: [Enemy MAX HP]."
                  alt="Warmaster: Has a 60% chance of inflicting bonus damage when attacking. Bonus damage is equal to 10% of the target Champion's MAX HP or 4% of the target's MAX HP when attacking Bosses. Bonus damage can only occur once per Skill and does not count as an extra hit.Damage based on: [Enemy MAX HP]."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="warmaster"
                  title="Warmaster: Has a 60% chance of inflicting bonus damage when attacking. Bonus damage is equal to 10% of the target Champion's MAX HP or 4% of the target's MAX HP when attacking Bosses. Bonus damage can only occur once per Skill and does not count as an extra hit.Damage based on: [Enemy MAX HP]."
                  alt="Warmaster: Has a 60% chance of inflicting bonus damage when attacking. Bonus damage is equal to 10% of the target Champion's MAX HP or 4% of the target's MAX HP when attacking Bosses. Bonus damage can only occur once per Skill and does not count as an extra hit.Damage based on: [Enemy MAX HP]."
                />
              </div>
            )}

            {thisChampsMasteries.includes(500162) || thisChampsMasteries.includes('helmsmasher') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="helmsmasher"
                  title="Helmsmasher: Has a 50% chance to ignore 25% of your targets DEF. For skills that ignore DEF, this 15% is in addition to the amount ignored by the Skill."
                  alt="Helmsmasher: Has a 50% chance to ignore 25% of your targets DEF. For skills that ignore DEF, this 15% is in addition to the amount ignored by the Skill."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="helmsmasher"
                  title="Helmsmasher: Has a 50% chance to ignore 25% of your targets DEF. For skills that ignore DEF, this 15% is in addition to the amount ignored by the Skill."
                  alt="Helmsmasher: Has a 50% chance to ignore 25% of your targets DEF. For skills that ignore DEF, this 15% is in addition to the amount ignored by the Skill."
                />
              </div>
            )}

            {thisChampsMasteries.includes(500163) || thisChampsMasteries.includes('giant-slayer') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="giant_slayer"
                  title="Giant Slayer: Has a 30% chance of inflicting bonus damage when attacking. Bonus Damage is equal to 5% of the target's MAX HP or 2% of the Boss' MAX HP. Bonus damage can occur on each hit of a Skill, but does not counter as an extra hit. Damage based on: [Enemy Max HP]."
                  alt="Giant Slayer: Has a 30% chance of inflicting bonus damage when attacking. Bonus Damage is equal to 5% of the target's MAX HP or 2% of the Boss' MAX HP. Bonus damage can occur on each hit of a Skill, but does not counter as an extra hit. Damage based on: [Enemy Max HP]."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="giant_slayer"
                  title="Giant Slayer: Has a 30% chance of inflicting bonus damage when attacking. Bonus Damage is equal to 5% of the target's MAX HP or 2% of the Boss' MAX HP. Bonus damage can occur on each hit of a Skill, but does not counter as an extra hit. Damage based on: [Enemy Max HP]."
                  alt="Giant Slayer: Has a 30% chance of inflicting bonus damage when attacking. Bonus Damage is equal to 5% of the target's MAX HP or 2% of the Boss' MAX HP. Bonus damage can occur on each hit of a Skill, but does not counter as an extra hit. Damage based on: [Enemy Max HP]."
                />
              </div>
            )}

            {thisChampsMasteries.includes(500164) || thisChampsMasteries.includes('flawless-execution') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="flawless_execution"
                  title="Flawless Execution: Critical Damage +20%."
                  alt="Flawless Execution: Critical Damage +20%."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="flawless_execution"
                  title="Flawless Execution: Critical Damage +20%."
                  alt="Flawless Execution: Critical Damage +20%."
                />
              </div>
            )}

            {/* Defense */}
            {thisChampsMasteries.includes(500261) || thisChampsMasteries.includes('iron-skin') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="iron_skin"
                  title="Iron Skin: Def +200."
                  alt="Iron Skin: Def +200."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="iron_skin"
                  title="Iron Skin: Def +200."
                  alt="Iron Skin: Def +200."
                />
              </div>
            )}

            {thisChampsMasteries.includes(500262) || thisChampsMasteries.includes('bulwark') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="bulwark"
                  title="Bulwark: Decreases the damage all allies receive by 5%. This Champion will receive that damage instead."
                  alt="Bulwark: Decreases the damage all allies receive by 5%. This Champion will receive that damage instead."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="bulwark"
                  title="Bulwark: Decreases the damage all allies receive by 5%. This Champion will receive that damage instead."
                  alt="Bulwark: Decreases the damage all allies receive by 5%. This Champion will receive that damage instead."
                />
              </div>
            )}

            {thisChampsMasteries.includes(500263) || thisChampsMasteries.includes('fearsome-presence') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="fearsome_presence"
                  title="Fearsome Presence: Increases the chance of placing a [Stun], [Sleep], [Freeze] or [Provoke] debuff from Skills or Artifacts by 5%."
                  alt="Fearsome Presence: Increases the chance of placing a [Stun], [Sleep], [Freeze] or [Provoke] debuff from Skills or Artifacts by 5%."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="fearsome_presence"
                  title="Fearsome Presence: Increases the chance of placing a [Stun], [Sleep], [Freeze] or [Provoke] debuff from Skills or Artifacts by 5%."
                  alt="Fearsome Presence: Increases the chance of placing a [Stun], [Sleep], [Freeze] or [Provoke] debuff from Skills or Artifacts by 5%."
                />
              </div>
            )}

            {thisChampsMasteries.includes(500264) || thisChampsMasteries.includes('unshakeable') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="unshakeable"
                  title="Unshakeable: Resist +50."
                  alt="Unshakeable: Resist +50."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="unshakeable"
                  title="Unshakeable: Resist +50."
                  alt="Unshakeable: Resist +50."
                />
              </div>
            )}

            {/* Support */}
            {thisChampsMasteries.includes(500361) || thisChampsMasteries.includes('elixir-of-life') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="elixir_of_life"
                  title="Elixir of Life: Max HP + 3000."
                  alt="Elixir of Life: Max HP + 3000."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="elixir_of_life"
                  title="Elixir of Life: Max HP + 3000."
                  alt="Elixir of Life: Max HP + 3000."
                />
              </div>
            )}

            {thisChampsMasteries.includes(500362) || thisChampsMasteries.includes('timely-intervention') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="timely_intervention"
                  title="Timely Intervention: Increases this Champions' Turn Meter by 20% whenever an ally hero drops below 25% HP."
                  alt="Timely Intervention: Increases this Champions' Turn Meter by 20% whenever an ally hero drops below 25% HP."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="timely_intervention"
                  title="Timely Intervention: Increases this Champions' Turn Meter by 20% whenever an ally hero drops below 25% HP."
                  alt="Timely Intervention: Increases this Champions' Turn Meter by 20% whenever an ally hero drops below 25% HP."
                />
              </div>
            )}

            {thisChampsMasteries.includes(500363) || thisChampsMasteries.includes('oppressor') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="oppressor"
                  title="Oppressor: Increases the Turn Meter fill rate by 2.5% for each active debuff cast by the Champion. Stacks up to 10%."
                  alt="Oppressor: Increases the Turn Meter fill rate by 2.5% for each active debuff cast by the Champion. Stacks up to 10%."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="oppressor"
                  title="Oppressor: Increases the Turn Meter fill rate by 2.5% for each active debuff cast by the Champion. Stacks up to 10%."
                  alt="Oppressor: Increases the Turn Meter fill rate by 2.5% for each active debuff cast by the Champion. Stacks up to 10%."
                />
              </div>
            )}

            {thisChampsMasteries.includes(500364) || thisChampsMasteries.includes('eagle-eye') ? (
              <div className="masteries-mastery selected">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="eagle_eye"
                  title="Eagle Eye: Acc + 50."
                  alt="Eagle Eye: Acc + 50."
                />
              </div>
            ) : (
              <div className="masteries-mastery disabled">
                <NextGenImage
                  local={local}
                  whichDirectory="_NotExtracted/Masteries"
                  whichImage="eagle_eye"
                  title="Eagle Eye: Acc + 50."
                  alt="Eagle Eye: Acc + 50."
                />
              </div>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
export default ChampMasteriesCondensed;
