import { ArtifactTile, NextGenImage } from 'Components';
import { WindowScroller, Grid, AutoSizer, GridCellRenderer } from 'react-virtualized';
import { getGlobalRootStore } from 'Model';
import React from 'react';
import './ArtifactList.css';
import { ChampionItem, GearSlot } from 'Types';
import { getEquippedArtifacts } from './DataView/Helpers';
import { observer } from 'mobx-react-lite';
import { ArtifactType } from 'Types/Entities/ArtifactType';

export interface ArtifactListProps {
  items: readonly ArtifactType[];
  statsOutsideArtifact?: boolean;
}

const itemPadding = 5;
const itemWidth = 135 + itemPadding * 2;
const itemHeight = 280 + itemPadding * 2;

export const ArtifactList: React.FC<ArtifactListProps> = observer(({ items, statsOutsideArtifact }) => {
  return (
    <div id="ArtifactList" className="ArtifactList-wrap">
      {/* <a className="btn" href="#ArtifactList">
        Snap to Artifacts Area
      </a> */}
      <WindowScroller scrollElement={document.body}>
        {({ height, isScrolling, registerChild, onChildScroll, scrollTop }) => (
          <section
            style={{ flex: '1 1 auto' }}
            className={`Artifacts-section ${statsOutsideArtifact === false && 'statsInsideArtifact'}`}
          >
            <AutoSizer disableHeight>
              {({ width }) => {
                const columnCount = Math.max(Math.floor(width / itemWidth), 1);
                const rowCount = Math.ceil(items.length / columnCount);
                const gridWidth = width;

                const cellRenderer: GridCellRenderer = ({ columnIndex, rowIndex, style }) => {
                  const item = items[rowIndex * columnCount + columnIndex];
                  return (
                    item && (
                      <div
                        key={item.id}
                        style={{ ...style, padding: itemPadding, marginTop: 15 }}
                        className={`ArtifactTile-wrap ${item.kindId} ${item.setKindId} ${item.rarity} ${item.rank}`}
                      >
                        <ArtifactTile gearSlot={GearSlot[item.kindId]} item={item} statsOutsideArtifact={true} />
                      </div>
                    )
                  );
                };
                return (
                  <>
                    {height && (
                      <div ref={registerChild}>
                        <Grid
                          autoHeight
                          height={height}
                          columnWidth={itemWidth}
                          rowHeight={itemHeight}
                          cellRenderer={cellRenderer}
                          columnCount={columnCount}
                          overscanRowCount={10}
                          scrollTop={scrollTop}
                          onScroll={onChildScroll}
                          isScrolling={isScrolling}
                          rowCount={rowCount + 1}
                          width={gridWidth}
                        />
                      </div>
                    )}
                  </>
                );
              }}
            </AutoSizer>
          </section>
        )}
      </WindowScroller>
    </div>
  );
});
