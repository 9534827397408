import React from 'react';
import { StarRating } from '../Library';
import { CornerMenu } from './CornerMenu';
import './Key.css';
import { NextGenImage } from '../../Components/Library';

//class Header extends React.PureComponent {
export class Key extends React.PureComponent {
  override render() {
    return (
      <CornerMenu panelClassName="menu-box key" text="Key" position="bottom right">
        <h3 className="with-underline mb-1">Key</h3>

        {/* Tier Rating Colors */}
        <section>
          <h6>Tier Rating Colors</h6>
          <p className="caption small mb-0">The same as in-game...</p>
          <div className="rarity-color-table">
            <ul>
              <li className="colorize-legendary" />
              <li className="colorize-epic" />
              <li className="colorize-rare" />
              <li className="colorize-uncommon" />
              <li className="colorize-common" />
            </ul>
            <div className="rarity-scale">
              <span>Best</span>
              <span>→</span>
              <span>Worst</span>
            </div>
          </div>
        </section>

        {/* Calculated Overall Star Ratings */}
        <section>
          <h6 id="CalculatedRaidChampsStarRating">Calculated Overall Star Ratings</h6>
          <dl>
            <dt>
              <strong>
                <u>81-100</u>
              </strong>
            </dt>
            <dd>
              <StarRating totalStars={5} filledStars={5} size="small" color="var(--rarity-legendary)" />
            </dd>
            <dt>
              <strong>
                <u>61-80.99</u>
              </strong>
            </dt>
            <dd>
              <StarRating totalStars={5} filledStars={4} size="small" color="var(--rarity-epic)" />
            </dd>
            <dt>
              <strong>
                <u>41-60.99</u>
              </strong>
            </dt>
            <dd>
              <StarRating totalStars={5} filledStars={3} size="small" color="var(--rarity-rare)" />
            </dd>
            <dt>
              <strong>
                <u>21-40.99</u>
              </strong>
            </dt>
            <dd>
              <StarRating totalStars={5} filledStars={2} size="small" color="var(--rarity-uncommon)" />
            </dd>
            <dt>
              <strong>
                <u>0-20.99</u>
              </strong>
            </dt>
            <dd>
              <StarRating totalStars={5} filledStars={1} size="small" color="var(--rarity-common)" />
            </dd>
          </dl>
        </section>

        {/* Book Value Indicators */}
        <section>
          <h6>Book Value Indicators</h6>
          <dl>
            <dt>
              <NextGenImage
                whichDirectory="_NotExtracted/myCreations/Books"
                whichImage="Skillbook_Rare"
                className="inlineIMG"
                alt="Book Value of 6 or 7"
                title="Book Value of 6 or 7"
              />
            </dt>
            <dd>6 or 7</dd>
            <dt>
              <NextGenImage
                whichDirectory="_NotExtracted/myCreations/Books"
                whichImage="Skillbook_Epic"
                className="inlineIMG"
                alt="Book Value of 8 or 9"
                title="Book Value of 8 or 9"
              />
            </dt>
            <dd>8 or 9</dd>
            <dt>
              <NextGenImage
                whichDirectory="_NotExtracted/myCreations/Books"
                whichImage="Skillbook_Legendary"
                className="inlineIMG"
                alt="Book Value of 10"
                title="Book Value of 10"
              />
            </dt>
            <dd>10</dd>
            <dt>
              <NextGenImage
                whichDirectory="_NotExtracted/myCreations/Books"
                whichImage="Skillbook_Rare"
                className="inlineIMG semi-transparent"
                alt="Book Value of 5 or lower"
                title="Book Value of 5 or lower"
              />
            </dt>
            <dd>5 or lower</dd>
          </dl>
        </section>

        {/* Damage Based On Indicators */}
        <section>
          <h6>Damage Based On Indicators</h6>
          <div className="row">
            <dl className="damageBasedOn">
              <dt className="hit-damageBasedOn-img-wrap info Attack">
                <NextGenImage
                  whichDirectory="_NotExtracted/myCreations/ItemSetIcons"
                  whichImage="Offense"
                  className="inlineIMG"
                  alt="Damage Based On Attack"
                  title="Damage Based On Attack"
                />
              </dt>
              <dd>Attack</dd>

              <dt className="hit-damageBasedOn-img-wrap info Defense">
                <NextGenImage
                  whichDirectory="_NotExtracted/myCreations/ItemSetIcons"
                  whichImage="Defense"
                  className="inlineIMG"
                  alt="Damage Based On Defense"
                  title="Damage Based On Defense"
                />
              </dt>
              <dd>Defense</dd>

              <dt className="hit-damageBasedOn-img-wrap info MaxHP">
                <NextGenImage
                  whichDirectory="_NotExtracted/myCreations/ItemSetIcons"
                  whichImage="Life"
                  className="inlineIMG"
                  alt="Damage Based On Max HP"
                  title="Damage Based On Max HP"
                />
              </dt>
              <dd>Max HP</dd>

              <dt className="hit-damageBasedOn-img-wrap info CurrentHP">
                <NextGenImage
                  whichDirectory="_NotExtracted/myCreations/ItemSetIcons"
                  whichImage="Immortal"
                  className="inlineIMG"
                  alt="Damage Based On Current HP"
                  title="Damage Based On Current HP"
                />
              </dt>
              <dd>Current HP</dd>
            </dl>
          </div>
          <div className="row">
            <dl className="damageBasedOn">
              <dt className="hit-damageBasedOn-img-wrap info EnemyMaxHP">
                <NextGenImage
                  whichDirectory="_NotExtracted/myCreations/ItemSetIcons"
                  whichImage="Destroy"
                  className="inlineIMG"
                  alt="Damage Based On Max HP"
                  title="Damage Based On Max HP"
                />
              </dt>
              <dd>Enemy Max HP</dd>

              <dt className="hit-damageBasedOn-img-wrap info Speed">
                <NextGenImage
                  whichDirectory="_NotExtracted/myCreations/ItemSetIcons"
                  whichImage="Speed"
                  className="inlineIMG"
                  alt="Damage Based On Enemy Speed"
                  title="Damage Based On Enemy Speed"
                />
              </dt>
              <dd>Speed</dd>

              <dt className="hit-damageBasedOn-img-wrap info N-A">
                <NextGenImage
                  whichDirectory="_NotExtracted/myCreations/ItemSetIcons"
                  whichImage="Curing"
                  className="inlineIMG"
                  alt="Support champs that don't do damage"
                  title="Support champs that don't do damage"
                />
              </dt>
              <dd>N/A</dd>
            </dl>
          </div>
        </section>

        {/* Champ Role Symbols */}
        {/*         <section>
          <h6>Champ Role Symbols</h6>
          <dl className="vertical">
            <dt>⚔️</dt>
            <dd>How many chances to PROC</dd>
            <dt>⏳</dt>
            <dd>How many turns it lasts</dd>
            <dt>🔃</dt>
            <dd>Cooldown when fully booked</dd>
            <dt>💯</dt>
            <dd>100% Chance when fully booked</dd>
          </dl>
          <p className="caption small">
            <span className="yellow">*</span> Icons can be copied from above to paste into the Advanced Ability Filter
            or the Champ Roles Filter...
          </p>
        </section> */}
      </CornerMenu>
    );
  }
}
