import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

export const StripeCancel: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Stripe Checkout Canceled | RaidChamps.com</title>
        <meta
          name="description"
          content="All the different ways for you to support or stripeCancel to RaidChamps.com."
        />
        <meta itemProp="name" content="StripeCancel | RaidChamps.com" />
        <meta
          itemProp="description"
          content="All the different ways for you to support or stripeCancel to RaidChamps.com."
        />
        <meta itemProp="image" content="https://ik.imagekit.io/raidchamps/_NotExtracted/logo.avif" />
        <meta property="og:url" content="https://raidchamps.com/mychamps" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="StripeCancel | RaidChamps.com" />
        <meta
          property="og:description"
          content="All the different ways for you to support or stripeCancel to RaidChamps.com."
        />
        <meta property="og:image" content="https://ik.imagekit.io/raidchamps/_NotExtracted/logo.avif" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="StripeCancel | RaidChamps.com" />
        <meta
          name="twitter:description"
          content="All the different ways for you to support or stripeCancel to RaidChamps.com."
        />
        <meta name="twitter:image" content="https://ik.imagekit.io/raidchamps/_NotExtracted/logo.avif" />
      </Helmet>
      <div className="stripeCancel-page page">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 center">
              <div className="text-center">
                <h1 className="with-underline">Stripe Checkout Canceled</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
