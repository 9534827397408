import EventEmitter from 'events';
import type { UnionToIntersection } from 'Types';
import { ChampionTypeEx } from 'Types/Entities/ChampionTypeEx';
import { FormsArray } from 'Types/Entities/Forms';
import type { Lookup } from './Types/Lookups';

// retype heroTypes to augment data from ratings
export type AllLookups = Omit<UnionToIntersection<Lookup>, 'heroTypes'> & { heroTypes: Record<string, ChampionTypeEx> };

let loadPromise: Promise<void>;
const data: AllLookups = {
  faction: {} as any,
  rarity: {} as any,
  role: {} as any,
  affinity: {} as any,
  area: {} as any,
  artifactRarity: {} as any,
  artifactSlots: {} as any,
  artifactSets: {} as any,
  statusEffect: {} as any,
  effectKind: {} as any,
  targetType: {} as any,
  damageVariables: {} as any,
  heroTypes: {} as any,
  ratings: {} as any,
  skills: {} as any,
  rank: {} as any,
  tag: {} as any,
  blessing: {} as any,
  masteries: {} as any,
  aura: {} as any,
  auraArea: {} as any,
  arenaLeagues: {} as any,
  stat: {} as any, // Add this line
  artifactPrimaryStat: {} as any, // Add this line
  arenaRoles: {} as any,
  forms: {} as any,
};

let totalChunks = 0;
let chunksLoaded = 0;

function startLoad(_name: string) {
  ++totalChunks;
  LoadState.emit('change', { totalChunks, chunksLoaded });
}

function endLoad(_name: string) {
  ++chunksLoaded;
  LoadState.emit('change', { totalChunks, chunksLoaded });
}

export interface LoadStateEvent {
  totalChunks: number;
  chunksLoaded: number;
}
export const LoadState = new EventEmitter();

async function loadSlice<T extends keyof AllLookups>(
  key: T,
  chunkLoader: () => Promise<{ lookup?: { [k in T]: any } }>
) {
  startLoad(key);
  const chunkData = await chunkLoader();
  if (chunkData.lookup) {
    Object.assign(data[key], chunkData.lookup[key]);
  }
  endLoad(key);
}

function ensureReady() {
  if (!loadPromise) {
    loadPromise = (async () => {
      await Promise.all([
        loadSlice('affinity', () => import(/* webpackChunkName: "enum-data" */ './Data/affinity.json')),
        loadSlice('area', () => import(/* webpackChunkName: "enum-data" */ './Data/area.json')),
        loadSlice('arenaLeagues', () => import(/* webpackChunkName: "enum-data" */ './Data/arenaLeagues.json')),
        loadSlice('artifactRarity', () => import(/* webpackChunkName: "enum-data" */ './Data/artifactRarity.json')),
        loadSlice('artifactSlots', () => import(/* webpackChunkName: "enum-data" */ './Data/artifactSlots.json')),
        loadSlice('artifactSets', () => import(/* webpackChunkName: "enum-data" */ './Data/artifactSets.json')),
        loadSlice('aura', () => import(/* webpackChunkName: "enum-data" */ './Data/aura.json')),
        loadSlice('auraArea', () => import(/* webpackChunkName: "enum-data" */ './Data/auraArea.json')),
        loadSlice('ratings', () => import(/* webpackChunkName: "ratings-data" */ './Data/ratings.json')),
        loadSlice('rank', () => import(/* webpackChunkName: "enum-data" */ './Data/rank.json')),
        loadSlice('tag', () => import(/* webpackChunkName: "enum-data" */ './Data/tag.json')),
        loadSlice('blessing', () => import(/* webpackChunkName: "enum-data" */ './Data/blessing.json')),
        loadSlice('masteries', () => import(/* webpackChunkName: "enum-data" */ './Data/masteries.json')),
        loadSlice('damageVariables', () => import(/* webpackChunkName: "enum-data" */ './Data/damageVariables.json')),
        loadSlice('effectKind', () => import(/* webpackChunkName: "enum-data" */ './Data/effectKind.json')),
        loadSlice('faction', () => import(/* webpackChunkName: "enum-data" */ './Data/faction.json')),
        // loadSlice('gearSlot', () => import(/* webpackChunkName: "enum-data" */ './Data/gearSlot.json')),
        loadSlice('heroTypes', () => import(/* webpackChunkName: "hero-data" */ './Data/heroTypes.json') as any),
        loadSlice('rarity', () => import(/* webpackChunkName: "enum-data" */ './Data/rarity.json')),
        loadSlice('role', () => import(/* webpackChunkName: "enum-data" */ './Data/role.json')),
        loadSlice(
          'skills',
          () =>
            import(/* webpackChunkName: "skill-data" */ './Data/skills.json') as Promise<{ lookup?: { skills: any } }>
        ),
        // loadSlice('statKind', () => import(/* webpackChunkName: "enum-data" */ './Data/statKind.json')),
        loadSlice('statusEffect', () => import(/* webpackChunkName: "enum-data" */ './Data/statusEffect.json')),
        loadSlice('targetType', () => import(/* webpackChunkName: "enum-data" */ './Data/targetType.json')),
        loadSlice('heroTypes', () => import(/* webpackChunkName: "hero-data" */ './Data/heroTypes.json') as any),
        loadSlice('forms', () => import(/* webpackChunkName: "forms-data" */ './Data/forms.json') as any),
      ]);

      for (const [, hero] of Object.entries(data.heroTypes)) {
        hero.ratings = data.ratings[hero.slug] || { detailed: {}, overall: 0 };

        // If data.forms[hero.typeId] is undefined, use an empty array.
        // If it's an array, assign it directly.
        /* const formsData = data.forms[hero.typeId];
        if (Array.isArray(formsData)) {
          hero.forms = formsData;
        } else {
          hero.forms = []; // Default to an empty array
        } */
      }
    })();
  }
  return loadPromise;
}

export function loadStaticData() {
  if (!!loadPromise) {
    console.warn('Static data was accessed before loading');
  }
  return ensureReady();
}

export function useStaticData(): AllLookups {
  return data;
}

// export as both names so it isn't confusing to use useHookXyz naming outside of react code
export const getStaticData = useStaticData;
