import React from 'react';
import { Link } from 'react-router-dom';
import './Contact.css';
import { Helmet } from 'react-helmet-async';

export const Contact: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Contact | RaidChamps.com</title>
        <meta name="description" content="How to contact me about RaidChamps.com." />
        <meta itemProp="name" content="Contact | RaidChamps.com" />
        <meta itemProp="description" content="How to contact me about RaidChamps.com." />
        <meta itemProp="image" content="https://ik.imagekit.io/raidchamps/_NotExtracted/logo.avif" />
        <meta property="og:url" content="https://raidchamps.com/mychamps" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Contact | RaidChamps.com" />
        <meta property="og:description" content="How to contact me about RaidChamps.com." />
        <meta property="og:image" content="https://ik.imagekit.io/raidchamps/_NotExtracted/logo.avif" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Contact | RaidChamps.com" />
        <meta name="twitter:description" content="How to contact me about RaidChamps.com." />
        <meta name="twitter:image" content="https://ik.imagekit.io/raidchamps/_NotExtracted/logo.avif" />
      </Helmet>
      <div className="contact-page page">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 center">
              <div className="text-center">
                <h1 className="with-underline">Contact Me</h1>
              </div>
              <p>
                The quickest way to get ahold of me is to join my{' '}
                <a href="https://discord.gg/zhqmadUyys" target="_blank" rel="noopener noreferrer">
                  Discord server
                </a>{' '}
                and mention me.
              </p>
              <p>
                Or you may also contact me using the form below and I will get back to you next time I go through my
                emails.
              </p>
              <form name="Contact Form" method="POST" data-netlify="true">
                <input type="hidden" name="form-name" value="Contact Form" />
                <p>
                  <input type="text" name="name" placeholder="Name" />
                </p>
                <p>
                  <input type="email" name="email" placeholder="Email" />
                </p>
                <p>
                  <textarea
                    name="message"
                    id="message"
                    className="c-input-field c-input-field--multiline"
                    placeholder="Message"
                  ></textarea>
                </p>
                <p>
                  <button type="submit">Send</button>
                </p>
              </form>

              {/* No clue why this does not work
               <div className="ContentCreatorsFreePowers-wrap">
                <h3 className="yellow">Free Powers for Content Creators</h3>
                <p>
                  If you are an active Content Creator that has done a video about this site, send me the link to the
                  video and I will give you all the Powers for free in hopes that you keep using the site and do more
                  videos about it. Please use the form below to submit your request...
                </p>

                <form name="Content Creator Free Powers Request Form" method="POST" data-netlify="true">
                  <input type="hidden" name="form-name" value="Content Creator Free Powers Request Form" />
                  <p>
                    <input type="text" name="name" placeholder="Channel Name" />
                  </p>
                  <p>
                    <input type="email" name="email" placeholder="Email" />
                  </p>
                  <p>
                    <input type="text" name="link" placeholder="Video Link" />
                  </p>
                  <p>
                    <textarea
                      name="message"
                      id="message"
                      className="c-input-field c-input-field--multiline"
                      placeholder="Message (Optional)"
                    ></textarea>
                  </p>
                  <p>
                    <button type="submit">Submit Request</button>
                  </p>
                </form>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
