import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import './AccordionMenu.css';

export interface AccordionMenuItem {
  text: string;
  href: string;
}

export interface AccordionMenuProps {
  categories: Record<string, AccordionMenuItem[]>;
}

export const AccordionMenu: React.FC<AccordionMenuProps> = ({ categories }) => {
  return (
    <Accordion className="menu-accordion" allowZeroExpanded allowMultipleExpanded>
      {Object.entries(categories).map(([categoryTitle, items]) => (
        <AccordionItem key={categoryTitle}>
          <AccordionItemHeading>
            <AccordionItemButton>{categoryTitle}</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            {items.map((item) => (
              <a key={item.text} href={item.href} target="_blank" rel="noopener noreferrer">
                {item.text}
              </a>
            ))}
          </AccordionItemPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
};
