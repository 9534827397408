import React from 'react';
import {
  Box,
  CircularProgress,
  Divider,
  Fab,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  SxProps,
  ThemeProvider,
} from '@mui/material';
import { Check, DeleteForever, FileDownload, FileUpload, Power, PowerOff } from '@mui/icons-material';
import { useRootStore } from '../../Model';
import { ConnectionState } from '../../Model/RTKClient';
import { observer } from 'mobx-react-lite';
import { green, grey } from '@mui/material/colors';
import { darkTheme } from '../Layout';
import { UserAvatarId } from '@raid-toolkit/webclient';
import { exportDataToFile, importDataToIndexedDB } from 'Components/DataView/Helpers';
import { runInAction } from 'mobx';

const buttonSx = {
  bgcolor: grey[500],
  '&:hover': {
    bgcolor: grey[700],
  },
  '&.success': {
    bgcolor: green[500],
    '&:hover': {
      bgcolor: green[700],
    },
  },
  '& .MuiSvgIcon-root': {
    transform: 'rotate(90deg)',
  },
};
export const RTKConnectButton: React.FC = observer(() => {
  const store = useRootStore();
  const disconnected = store.client.state === ConnectionState.None;
  const connecting = store.client.state === ConnectionState.Connecting;
  const connected = store.client.state === ConnectionState.Connected;

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const showMenu = (e: React.MouseEvent<HTMLImageElement>) => {
    setAnchorEl(e.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };
  const open = !!anchorEl;

  const avatarImage = store.accounts.selectedAccount?.info.avatar
    ? `https://ik.imagekit.io/raidchamps/heroAvatars/${UserAvatarId[store.accounts.selectedAccount.info.avatar]}.png`
    : 'https://ik.imagekit.io/raidchamps/rtk_avatar.png';
  const buttonBox: SxProps = {
    m: 1,
    cursor: 'pointer',
    position: 'relative',
    width: 64,
    height: 64,
    '& .avatarButton': {
      backgroundImage: `url(${avatarImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      width: 64,
      height: 64,
      position: 'relative',
      borderRadius: '15px',
      boxShadow: 'inset 0px 0px 2px 0px var(--foreground), 0 0 1px 0px rgba(255, 255, 255, 0.6)',
      '&:hover': {
        boxShadow:
          'inset 0px 0px 2px 0px var(--foreground), 0 0 2px 1px var(--accent), 0 0 1px 0px rgba(255, 255, 255, 0.6)',
      },
    },
  };

  const accountMenuItems = Array.from(store.accounts.accounts.entries()).map(([key, acct]) => (
    <MenuItem
      key={acct.info.id}
      value={acct.info.id}
      onClick={() => {
        store.accounts.selectedAccountId = acct.info.id;
        closeMenu();
      }}
    >
      {store.accounts.selectedAccountId === acct.info.id && (
        <ListItemIcon>
          <Check />
        </ListItemIcon>
      )}
      <ListItemText inset={store.accounts.selectedAccountId !== acct.info.id}>{acct.name}</ListItemText>
    </MenuItem>
  ));

  const handleFileChange = (event: { target: { files: any[] } }) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsText(file, 'UTF-8');
      reader.onload = function (evt) {
        let data;
        if (evt.target !== null && evt?.target?.result !== null) {
          data = JSON.parse(String(evt.target.result));
        }
        importDataToIndexedDB(data);
      };
    }
  };

  const onConnectionToggle = disconnected ? store.client.connect : store.client.disconnect;
  return (
    <ThemeProvider theme={darkTheme}>
      <div style={{ fontSize: 30, display: 'flex', gap: 10 }}>
        <Box sx={buttonBox}>
          <div
            className="avatarButton"
            onClick={showMenu}
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          ></div>
          <Box sx={{ position: 'absolute', left: 36, top: -16 }}>
            <Fab
              size="small"
              aria-label="save"
              className={connected ? 'success' : undefined}
              sx={buttonSx}
              onClick={onConnectionToggle}
            >
              {disconnected ? <PowerOff /> : <Power />}
            </Fab>
            {connecting && (
              <CircularProgress
                size={46}
                sx={{
                  color: green[500],
                  position: 'absolute',
                  top: 2,
                  left: -3,
                  zIndex: 1,
                }}
              />
            )}
          </Box>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={closeMenu}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            {accountMenuItems}
            <Divider />
            <MenuItem
              onClick={() => {
                exportDataToFile(store.accounts.selectedAccount?.info);
                closeMenu();
              }}
            >
              <ListItemIcon>
                <FileDownload />
              </ListItemIcon>
              <ListItemText>Export</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                document.getElementById('fileInput').click();
                closeMenu();
              }}
            >
              <ListItemIcon>
                <FileUpload />
              </ListItemIcon>
              <ListItemText>Import</ListItemText>
            </MenuItem>

            <Divider />
            <MenuItem
              onClick={() => {
                runInAction(() => {
                  store.reset();
                });
                closeMenu();
              }}
            >
              <ListItemIcon>
                <DeleteForever />
              </ListItemIcon>
              <ListItemText>Clear all data</ListItemText>
            </MenuItem>
          </Menu>
        </Box>
        <input type="file" id="fileInput" style={{ display: 'none' }} onChange={handleFileChange} />
      </div>
    </ThemeProvider>
  );
});
