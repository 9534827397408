import React from 'react';
import {
  Chip,
  Avatar,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Icon,
  InputBaseComponentProps,
  StyledComponentProps,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import Dropdown from './Dropdown';
import { Input, MenuList, IconButton } from './utils';
import ITEMS from './items';
import { filterItems } from './Helpers';
import './MultiSelectGroup.css';
import Tooltip from 'rc-tooltip';

const styles = {
  root: {
    listStyle: 'none' as const,
    display: 'flex' as const,
    padding: 0,
    alignItems: 'center' as const,
    flexWrap: 'wrap' as const,
    width: '100%',
    border: '1px solid black',
    borderRadius: 2,
  },
  chip: {
    marginRight: 4,
    height: 25,
    borderRadius: 2,
  },
  item: {
    display: 'inline-flex' as const,
    marginRight: 4,
    '& > div': {
      borderRadius: 1,
      height: 28,
    },
    '& > div:first-child': {
      background: 'rgba(200,200,200,.3)',
    },
    '& > div + div': {
      marginLeft: 1,
      background: 'rgba(200,200,200,.7)',
    },
  },
  search: {
    borderBottom: '2px solid rgba(200,200,200,.5)',
  },
};

export type SelectedItem = [key: string, value: string, id?: string, slug?: string];

interface MultiSelectGroupState {
  inputValue: string;
  inputKey: string;
  inputColon: boolean;
  selectedItems: SelectedItem[];
}

export interface MultiSelectGroupProps extends StyledComponentProps {
  data: any;
  search?: string;
  inputProps?: InputBaseComponentProps;
  theme?: any; // not sure where this prop should be coming from, seems specific to withStyles usage
  SelectedItemsCallback?: (items: SelectedItem[]) => void;
  AbilityFiltersIsOnCallback?: (enabled: boolean) => void;
}

class MultiSelectGroup extends React.PureComponent<MultiSelectGroupProps, MultiSelectGroupState> {
  private inputEl?: HTMLInputElement;
  private rootEl?: HTMLUListElement;

  // static propTypes = { // will use TS instead
  // 	data: PropTypes.object.isRequired,
  // 	search: PropTypes.string,
  // };
  override state: MultiSelectGroupState = {
    inputValue: '',
    inputKey: '',
    inputColon: false, // boolean separator between key and value (':')
    selectedItems:
      typeof this.props.search === 'string'
        ? [...new URLSearchParams(this.props.search)].map(([k, v]) => {
            const entry = [...(this.props.data[k] || [])].find(([id, o]) => v === o.name); // find a value with this name
            return [k, v, entry ? entry[0] : undefined];
          })
        : [], // ArrayOf([key: String, value: String, id?: String]) // optional id for an existing item in data (todo clean that)
  };

  override componentDidMount() {
    document.addEventListener('keydown', this.keyDown);
  }

  override componentWillUnmount() {
    document.removeEventListener('keydown', this.keyDown);
  }

  // ref callback passed to either input key and input value, only one of them exist at a time
  setInputEl = (el: HTMLInputElement | undefined) => {
    if (el) {
      this.inputEl = el;
    }
  };

  refocus() {
    if (this.inputEl) {
      this.inputEl.focus();
      this.inputEl.selectionStart = this.inputEl.value.length;
    }
  }

  // data: {inputKey: String, inputColon: Boolean, inputValue: String, selectedItems: Array}
  update = (data: any, shouldFocus?: boolean) => {
    this.setState(data, shouldFocus ? this.refocus : undefined);
  };

  keyDown = (e: KeyboardEvent) => {
    if (!this.rootEl || !this.rootEl.contains(document.activeElement)) return;
    // todo: if we press : or tab during fieldSelection, move to value, same for value -> fields
    if (e.key === 'Backspace') {
      // pop latest select item
      const { selectedItems, inputColon, inputKey, inputValue } = this.state;
      if ((!inputColon && inputKey) || (inputColon && inputValue)) return;

      const lastItem = selectedItems[selectedItems.length - 1];
      if (!lastItem) {
        return;
      }
      if (inputColon) {
        this.update(
          {
            inputKey: '',
            inputColon: false,
            inputValue: '',
            selectedItems: selectedItems.slice(0, -1),
          },
          true
        );
      } else {
        this.update(
          {
            inputColon: true,
            inputKey: lastItem[0],
            inputValue: '',
            selectedItems: [...selectedItems.slice(0, -1), [lastItem[0]]],
          },
          true
        );
      }
    }
    const li: HTMLLIElement | null =
      document.activeElement &&
      ((document.activeElement.tagName === 'li'
        ? document.activeElement
        : document.activeElement.closest('li')) as HTMLLIElement);
    if (!li) return;
    if (e.key === 'Delete' && li.dataset.key) {
      const { selectedItems } = this.state;
      const i = +li.dataset.key;
      this.setState({
        selectedItems: [...selectedItems.slice(0, i), ...selectedItems.slice(i + 1)],
      });
    }
    if (e.key === 'ArrowLeft' && li.previousElementSibling) {
      (li.previousElementSibling as HTMLElement).focus();
    }
    if (e.key === 'ArrowRight' && li.nextElementSibling) {
      (li.nextElementSibling as HTMLElement).focus();
    }
    // todo handle keydown/up like gitlab issues
  };

  renderInput() {
    const { inputValue, inputColon, inputKey, selectedItems } = this.state;
    const item = ITEMS[inputKey];
    if (inputColon && item) {
      const { menu: MenuComp } = item;
      return (
        <MenuComp
          placeholder="Advanced Ability Filters (Results should now be accurate)"
          data={this.props.data[inputKey] && [...this.props.data[inputKey].values()]}
          value={inputValue}
          style={{ flex: 1 }}
          setInputEl={this.setInputEl}
          setValue={(v: any) => this.update({ inputValue: v })}
          addValue={(name: string, id: string, RslValue: any, ChampRoleValue: any) =>
            this.update({
              inputColon: false,
              inputKey: '',
              inputValue: '',
              selectedItems: [...selectedItems.slice(0, -1), [inputKey, name, id, RslValue, ChampRoleValue]],
            })
          }
        />
      );
    }

    const filteredKeys = filterItems(
      Object.keys(ITEMS),
      inputKey,
      selectedItems.filter(([key]) => !ITEMS[key]!.multi).map(([key]) => key)
    );

    return (
      <Dropdown
        component="li"
        style={{ flex: 1 }}
        input={
          <Input
            key="input"
            value={inputKey}
            inputRef={this.setInputEl}
            onChange={(e) => this.update({ inputKey: e.target.value })}
            inputProps={{
              ...this.props.inputProps,
              ...(selectedItems.length ? { placeholder: undefined } : undefined),
            }}
          />
        }
      >
        <MenuList>
          {/* 					<MenuItem className={this.props.classes.search} onClick={console.log}>
						<ListItemIcon>
							<Icon style={{ marginRight: 0 }}>🔎</Icon>
						</ListItemIcon>
						<ListItemText inset primary="Press enter or click to search" />
					</MenuItem> */}
          {filteredKeys
            .map((key) => ({ ...ITEMS[key], key }))
            .map(({ key, label, icon }) => (
              <MenuItem
                key={key}
                onClick={() => {
                  this.update({ inputKey: key, inputColon: true, selectedItems: [...selectedItems, [key]] }, true);
                  window.setTimeout(() => this.onTrigger(selectedItems), 0);
                }}
              >
                <ListItemIcon>
                  <Icon style={{ marginRight: 0 }}>{icon}</Icon>
                </ListItemIcon>
                <ListItemText inset primary={label} />
              </MenuItem>
            ))}
        </MenuList>
      </Dropdown>
    );
  }

  override render() {
    const { classes, theme, inputProps, ...rest } = this.props;
    const { inputValue = '', selectedItems } = this.state;

    return (
      <ul
        className={classes?.root}
        {...rest}
        ref={(el) => {
          this.rootEl = el || undefined;
        }}
      >
        {selectedItems.map(([key, name, id, _slug], i) => {
          const item = ITEMS[key];
          if (!item) return null; // normally it never happens, if no typos

          const { avatar, color } = (id && this.props.data[key].get(id)) || {};
          return (
            item && (
              <li
                key={i}
                role="button"
                tabIndex={0}
                data-key={i}
                className={classes?.item}
                onClick={(e) => e.currentTarget.focus()}
              >
                <Chip label={item.icon} />
                {name && (
                  <Chip
                    //avatar={avatar ? avatar && <Avatar src={avatar} /> : ""}
                    label={name}
                    style={color && { background: color, color: theme.palette.getContrastText(color) }}
                    onDelete={() =>
                      this.update({
                        selectedItems: [...selectedItems.slice(0, i), ...selectedItems.slice(i + 1)],
                      })
                    }
                  />
                )}
              </li>
            )
          );
        })}
        {this.renderInput()}
        {selectedItems.length || inputValue ? (
          <li>
            <IconButton
              onClick={() => this.update({ inputValue: '', inputKey: '', inputColon: false, selectedItems: [] })}
            >
              <Tooltip
                arrowContent={<div className="rc-tooltip-arrow-inner" />}
                placement="bottom"
                overlay={<span>Clear All Selected Options</span>}
              >
                <span>x</span>
              </Tooltip>
            </IconButton>
          </li>
        ) : null}
      </ul>
    );
  }

  onTrigger = (value: SelectedItem[], AbilityFiltersIsOn?: boolean) => {
    this.props.SelectedItemsCallback?.(value);
    this.props.AbilityFiltersIsOnCallback?.(!!AbilityFiltersIsOn);
  };
}

export default withStyles(styles, { withTheme: true })(MultiSelectGroup);
