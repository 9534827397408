import React, { useCallback } from 'react';
import Tooltip from 'rc-tooltip';

const ResetFiltersButton = () => {
  const handleClick = useCallback(() => {
    const { protocol, host, pathname } = window.location;
    window.location.href = `${protocol}//${host}${pathname}`;
  }, []);

  return (
    <div className="center filterButtons-wrap">
      <button
        onClick={handleClick}
        title='Clicking this button will reload the page and revert the filter settings back to the ones you saved or the default filter settings if you have not saved them. You can even set a Hotkey to trigger this button on the "Hotkeys" page.'
      >
        Reset Filters
      </button>
    </div>
  );
};

export default ResetFiltersButton;
