export enum Rank {
  stars1 = 'stars1',
  stars2 = 'stars2',
  stars3 = 'stars3',
  stars4 = 'stars4',
  stars5 = 'stars5',
  stars6 = 'stars6',
}

export function rankToNumber(rank: Rank): number {
  switch (rank) {
    case Rank.stars1:
      return 1;
    case Rank.stars2:
      return 2;
    case Rank.stars3:
      return 3;
    case Rank.stars4:
      return 4;
    case Rank.stars5:
      return 5;
    case Rank.stars6:
      return 6;
  }
}

export function numberToRank(rank: number): Rank {
  switch (rank) {
    case 1:
      return Rank.stars1;
    case 2:
      return Rank.stars2;
    case 3:
      return Rank.stars3;
    case 4:
      return Rank.stars4;
    case 5:
      return Rank.stars5;
    case 6:
      return Rank.stars6;
    default:
      throw new Error('Unexpected rank value');
  }
}
