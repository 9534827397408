export enum FactionId {
  unknown = 'unknown',
  bannerLords = 'bannerlords',
  highElves = 'highelves',
  sacredOrder = 'sacredorder',
  ogrynTribes = 'ogryntribes',
  lizardMen = 'lizardmen',
  skinwalkers = 'skinwalkers',
  orcs = 'orcs',
  demonspawn = 'demonspawn',
  undeadHordes = 'undeadhordes',
  undeadHorde = 'undeadhordes',
  darkElves = 'darkelves',
  knightsRevenant = 'knightsrevenant',
  knightRevenant = 'knightsrevenant',
  barbarians = 'barbarians',
  samurai = 'samurai',
  shadowKin = 'samurai',
  dwarves = 'dwarves',
  nyresanelves = 'nyresanelves',
  nyresanElves = 'nyresanelves',
  sylvanwatchers = 'nyresanelves',
  sylvanWatchers = 'nyresanelves',
  covenOfMagi = 'covenofmagi',
}
