import { mergeStyles } from '@fluentui/merge-styles';

export const small = mergeStyles({
  display: 'inline-block',
  fontSize: '14px',
  textShadow: '-1px 1px 2px rgb(0 0 0 / 60%)',
});

export const normal = mergeStyles({
  display: 'inline-block',
  fontSize: '1.2rem',
  textShadow: '-1px 1px 2px rgb(0 0 0 / 60%)',
});

export const large = mergeStyles({
  display: 'inline-block',
  fontSize: '1.6rem',
  letterSpacing: '-0.7rem',
  textShadow: '-2px 1px 2px rgb(0 0 0 / 90%)',
});

export const ascended = mergeStyles({
  color: '#e74bc7',
  position: 'absolute',
  top: '-4px',
  left: '-67px',
});

export const awakened = mergeStyles({
  color: '#FF0408',
  position: 'absolute',
  top: '-4px',
  left: '-67px',
});
