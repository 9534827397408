import { mergeStyles } from '@fluentui/merge-styles';

export const root = mergeStyles({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  backgroundColor: 'var(--item-background-color)',
  borderColor: 'var(--item-border-color)',
  borderStyle: 'solid',
  borderWidth: 1,

  /*   ':hover': {
    borderColor: 'var(--item-border-color-accent)',
  }, */

  color: 'var(--text-color-normal)',
});
