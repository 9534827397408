import { useRootStore } from 'Model';
import { HeroStatsBuilder, StatSource } from 'Model/HeroStatsBuilder';
import React from 'react';
import { ChampionItem, ChampionType, StatKind, Stats } from 'Types';
import { formatStat, scaleBaseStats, orderedStatNameToStatKind } from './StatsHelpers';
import { observer } from 'mobx-react-lite';

export interface ChampStatsProps {
  item?: ChampionItem;
  champType?: ChampionType;
  headers?: boolean;
}

export const BookedSkills: React.FC<ChampStatsProps> = observer(({ item, champType, headers }) => {
  let championType;
  if (champType) {
    championType = champType;
  } else {
    championType = item?.type;
  }

  const account = useRootStore().accounts.selectedAccount;
  const stats = item?.instance && account?.championStatsMap[item?.instance.id];
  let baseStats: Stats;
  if (item?.instance) {
    baseStats = stats?.statSources[StatSource.base] ?? scaleBaseStats(championType?.baseStats);
  }
  if (championType?.baseStats) {
    baseStats = scaleBaseStats(championType?.baseStats);
  }

  //console.log(`ChampStats stats`, stats);

  //console.log(`ChampStats baseStats`, baseStats);
  return (
    <div className="bookedSkills-wrap">
      {headers !== false && (
        <>
          <h3>Stats</h3>
          <div>
            STAT: <span className="stat baseStat">Base</span>
            {item?.instance && <span className="stat myStat"> / Mine</span>}
          </div>
          <hr />
        </>
      )}
      {Object.entries(orderedStatNameToStatKind).map(([statName, statKind]) => (
        <div></div>
      ))}
    </div>
  );
});
