import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import HexagonGrid from 'Components/HexagonGrid';
import DynamicImage from 'Components/DynamicImage';
import Cookies from 'js-cookie';

const exampleHexagonData = [
  {
    imageUrl:
      'https://cdn.discordapp.com/attachments/1134547775284052018/1146992376187977739/b18510fd32bc54bc332decc4f1f77c00.gif',
    title: 'Gif 1',
    description: 'Miles Morales as Spiderman Swinging',
    link: 'https://cdn.discordapp.com/attachments/1134547775284052018/1146992376187977739/b18510fd32bc54bc332decc4f1f77c00.gif',
  },
  {
    imageUrl:
      'https://media.discordapp.net/attachments/1134547775284052018/1146990898551476256/Default_Miles_Morales_as_Spiderman_full_body_length_standing_o_0_12863856-50ba-4829-820c-2fb1764580e4_1.gif',
    title: 'Gif 2',
    description: 'AI Generated Miles Morales',
    link: 'https://media.discordapp.net/attachments/1134547775284052018/1146990898551476256/Default_Miles_Morales_as_Spiderman_full_body_length_standing_o_0_12863856-50ba-4829-820c-2fb1764580e4_1.gif',
  },
  {
    imageUrl:
      'https://media.discordapp.net/attachments/1134547775284052018/1146989604159885402/Miles_Spiderman_Falling.gif',
    title: 'Gif 3',
    description: 'Miles Morales as Spiderman Falling',
    link: 'https://media.discordapp.net/attachments/1134547775284052018/1146989604159885402/Miles_Spiderman_Falling.gif',
  },
  {
    imageUrl: 'https://media.discordapp.net/attachments/1134547775284052018/1146988033942503544/GoKart1.gif',
    title: 'Gif 4',
    description: 'AI Generated GoKart',
    link: 'https://media.discordapp.net/attachments/1134547775284052018/1146988033942503544/GoKart1.gif',
  },
  {
    imageUrl:
      'https://media.discordapp.net/attachments/1134547775284052018/1146986231188688926/Vokein-Strongbreaker-Ovethaga-PhotoshopedToHaveBlueSkin.gif',
    title: 'Gif 5',
    description: 'AI Generated D&D Character: Vokein Strongbreaker Ovethaga',
    link: 'https://media.discordapp.net/attachments/1134547775284052018/1146986231188688926/Vokein-Strongbreaker-Ovethaga-PhotoshopedToHaveBlueSkin.gif',
  },
  {
    imageUrl: 'https://media.discordapp.net/attachments/1134547775284052018/1146985812253233162/tmp7abirf33.gif',
    title: 'Gif 6',
    description: 'AI Generated D&D Character: Helfi Kunz',
    link: 'https://media.discordapp.net/attachments/1134547775284052018/1146985812253233162/tmp7abirf33.gif',
  },
  {
    imageUrl: 'https://media.discordapp.net/attachments/1134547775284052018/1135418661432205342/20230730223946.gif',
    title: 'Gif 7',
    description: 'AI Generated Trippy Rabbit Turning into Cat',
    link: 'https://media.discordapp.net/attachments/1134547775284052018/1135418661432205342/20230730223946.gif',
  },
  {
    imageUrl: 'https://media.discordapp.net/attachments/1134547775284052018/1135017957681025054/20230729210919.gif',
    title: 'Gif 8',
    description: 'AI Generated Trippy Journey Through a Temple',
    link: 'https://media.discordapp.net/attachments/1134547775284052018/1135017957681025054/20230729210919.gif',
  },
  {
    imageUrl: 'https://media.discordapp.net/attachments/1134547775284052018/1135009794890149958/20230729201625.gif',
    title: 'Gif 9',
    description: 'AI Generated Another Trippy Journey Through a Temple',
    link: 'https://media.discordapp.net/attachments/1134547775284052018/1135009794890149958/20230729201625.gif',
  },
  {
    imageUrl:
      'https://cdn.discordapp.com/attachments/1134547775284052018/1146992376187977739/b18510fd32bc54bc332decc4f1f77c00.gif',
    title: 'Gif 1',
    description: 'Miles Morales as Spiderman Swinging',
    link: 'https://cdn.discordapp.com/attachments/1134547775284052018/1146992376187977739/b18510fd32bc54bc332decc4f1f77c00.gif',
  },
  {
    imageUrl:
      'https://media.discordapp.net/attachments/1134547775284052018/1146990898551476256/Default_Miles_Morales_as_Spiderman_full_body_length_standing_o_0_12863856-50ba-4829-820c-2fb1764580e4_1.gif',
    title: 'Gif 2',
    description: 'AI Generated Miles Morales',
    link: 'https://media.discordapp.net/attachments/1134547775284052018/1146990898551476256/Default_Miles_Morales_as_Spiderman_full_body_length_standing_o_0_12863856-50ba-4829-820c-2fb1764580e4_1.gif',
  },
  {
    imageUrl:
      'https://media.discordapp.net/attachments/1134547775284052018/1146989604159885402/Miles_Spiderman_Falling.gif',
    title: 'Gif 3',
    description: 'Miles Morales as Spiderman Falling',
    link: 'https://media.discordapp.net/attachments/1134547775284052018/1146989604159885402/Miles_Spiderman_Falling.gif',
  },
  {
    imageUrl: 'https://media.discordapp.net/attachments/1134547775284052018/1146988033942503544/GoKart1.gif',
    title: 'Gif 4',
    description: 'AI Generated GoKart',
    link: 'https://media.discordapp.net/attachments/1134547775284052018/1146988033942503544/GoKart1.gif',
  },
  {
    imageUrl:
      'https://media.discordapp.net/attachments/1134547775284052018/1146986231188688926/Vokein-Strongbreaker-Ovethaga-PhotoshopedToHaveBlueSkin.gif',
    title: 'Gif 5',
    description: 'AI Generated D&D Character: Vokein Strongbreaker Ovethaga',
    link: 'https://media.discordapp.net/attachments/1134547775284052018/1146986231188688926/Vokein-Strongbreaker-Ovethaga-PhotoshopedToHaveBlueSkin.gif',
  },
  {
    imageUrl: 'https://media.discordapp.net/attachments/1134547775284052018/1146985812253233162/tmp7abirf33.gif',
    title: 'Gif 6',
    description: 'AI Generated D&D Character: Helfi Kunz',
    link: 'https://media.discordapp.net/attachments/1134547775284052018/1146985812253233162/tmp7abirf33.gif',
  },
  {
    imageUrl: 'https://media.discordapp.net/attachments/1134547775284052018/1135418661432205342/20230730223946.gif',
    title: 'Gif 7',
    description: 'AI Generated Trippy Rabbit Turning into Cat',
    link: 'https://media.discordapp.net/attachments/1134547775284052018/1135418661432205342/20230730223946.gif',
  },
  {
    imageUrl: 'https://media.discordapp.net/attachments/1134547775284052018/1135017957681025054/20230729210919.gif',
    title: 'Gif 8',
    description: 'AI Generated Trippy Journey Through a Temple',
    link: 'https://media.discordapp.net/attachments/1134547775284052018/1135017957681025054/20230729210919.gif',
  },
  {
    imageUrl: 'https://media.discordapp.net/attachments/1134547775284052018/1135009794890149958/20230729201625.gif',
    title: 'Gif 9',
    description: 'AI Generated Another Trippy Journey Through a Temple',
    link: 'https://media.discordapp.net/attachments/1134547775284052018/1135009794890149958/20230729201625.gif',
  },
  {
    imageUrl:
      'https://cdn.discordapp.com/attachments/1134547775284052018/1146992376187977739/b18510fd32bc54bc332decc4f1f77c00.gif',
    title: 'Gif 1',
    description: 'Miles Morales as Spiderman Swinging',
    link: 'https://cdn.discordapp.com/attachments/1134547775284052018/1146992376187977739/b18510fd32bc54bc332decc4f1f77c00.gif',
  },
  {
    imageUrl:
      'https://media.discordapp.net/attachments/1134547775284052018/1146990898551476256/Default_Miles_Morales_as_Spiderman_full_body_length_standing_o_0_12863856-50ba-4829-820c-2fb1764580e4_1.gif',
    title: 'Gif 2',
    description: 'AI Generated Miles Morales',
    link: 'https://media.discordapp.net/attachments/1134547775284052018/1146990898551476256/Default_Miles_Morales_as_Spiderman_full_body_length_standing_o_0_12863856-50ba-4829-820c-2fb1764580e4_1.gif',
  },
  {
    imageUrl:
      'https://media.discordapp.net/attachments/1134547775284052018/1146989604159885402/Miles_Spiderman_Falling.gif',
    title: 'Gif 3',
    description: 'Miles Morales as Spiderman Falling',
    link: 'https://media.discordapp.net/attachments/1134547775284052018/1146989604159885402/Miles_Spiderman_Falling.gif',
  },
  {
    imageUrl: 'https://media.discordapp.net/attachments/1134547775284052018/1146988033942503544/GoKart1.gif',
    title: 'Gif 4',
    description: 'AI Generated GoKart',
    link: 'https://media.discordapp.net/attachments/1134547775284052018/1146988033942503544/GoKart1.gif',
  },
  {
    imageUrl:
      'https://media.discordapp.net/attachments/1134547775284052018/1146986231188688926/Vokein-Strongbreaker-Ovethaga-PhotoshopedToHaveBlueSkin.gif',
    title: 'Gif 5',
    description: 'AI Generated D&D Character: Vokein Strongbreaker Ovethaga',
    link: 'https://media.discordapp.net/attachments/1134547775284052018/1146986231188688926/Vokein-Strongbreaker-Ovethaga-PhotoshopedToHaveBlueSkin.gif',
  },
  {
    imageUrl: 'https://media.discordapp.net/attachments/1134547775284052018/1146985812253233162/tmp7abirf33.gif',
    title: 'Gif 6',
    description: 'AI Generated D&D Character: Helfi Kunz',
    link: 'https://media.discordapp.net/attachments/1134547775284052018/1146985812253233162/tmp7abirf33.gif',
  },
  {
    imageUrl: 'https://media.discordapp.net/attachments/1134547775284052018/1135418661432205342/20230730223946.gif',
    title: 'Gif 7',
    description: 'AI Generated Trippy Rabbit Turning into Cat',
    link: 'https://media.discordapp.net/attachments/1134547775284052018/1135418661432205342/20230730223946.gif',
  },
  {
    imageUrl: 'https://media.discordapp.net/attachments/1134547775284052018/1135017957681025054/20230729210919.gif',
    title: 'Gif 8',
    description: 'AI Generated Trippy Journey Through a Temple',
    link: 'https://media.discordapp.net/attachments/1134547775284052018/1135017957681025054/20230729210919.gif',
  },
  {
    imageUrl: 'https://media.discordapp.net/attachments/1134547775284052018/1135009794890149958/20230729201625.gif',
    title: 'Gif 9',
    description: 'AI Generated Another Trippy Journey Through a Temple',
    link: 'https://media.discordapp.net/attachments/1134547775284052018/1135009794890149958/20230729201625.gif',
  },
];

export const Sandbox: React.FC = () => {
  // Initialize trackingData cookie if it doesn't exist
  if (!Cookies.get('trackingData')) {
    Cookies.set('trackingData', JSON.stringify({ preference: '' }));
  }

  const trackingDataString = Cookies.get('trackingData');
  const trackingData = trackingDataString ? JSON.parse(trackingDataString) : {};

  const [userPreference, setUserPreference] = useState<string>(trackingData.preference || '');

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPreference = e.target.value;
    setUserPreference(newPreference);
    Cookies.set('trackingData', JSON.stringify({ preference: newPreference }));
  };
  return (
    <>
      <Helmet>
        <title>Sandbox | RaidChamps.com</title>
        <meta name="description" content="How to contact me about RaidChamps.com." />
        <meta itemProp="name" content="Sandbox | RaidChamps.com" />
        <meta itemProp="description" content="How to contact me about RaidChamps.com." />
        <meta itemProp="image" content="https://ik.imagekit.io/raidchamps/_NotExtracted/logo.avif" />
        <meta property="og:url" content="https://raidchamps.com/mychamps" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Sandbox | RaidChamps.com" />
        <meta property="og:description" content="How to contact me about RaidChamps.com." />
        <meta property="og:image" content="https://ik.imagekit.io/raidchamps/_NotExtracted/logo.avif" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Sandbox | RaidChamps.com" />
        <meta name="twitter:description" content="How to contact me about RaidChamps.com." />
        <meta name="twitter:image" content="https://ik.imagekit.io/raidchamps/_NotExtracted/logo.avif" />
      </Helmet>
      <div className="page">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 center">
              <div className="text-center">
                <h1 className="with-underline">Sandbox</h1>
              </div>

              <label htmlFor="preferenceInput">Your Preference: </label>
              <input id="preferenceInput" type="text" value={userPreference} onChange={handleInputChange} />
              <DynamicImage trackingData={{ preference: userPreference }} />
              <HexagonGrid hexagonData={exampleHexagonData} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
