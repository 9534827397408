import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

export const Callback: React.FC = () => {
  const { error, isAuthenticated, handleRedirectCallback, user } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    console.log('useEffect is running');
    const handleCallback = async () => {
      if (isAuthenticated) {
        console.log('User is authenticated');
        let roles;
        if (user) {
          roles = user['https://raidchamps.com/roles'];
        }
        if (roles.includes('Admin')) {
          navigate('/profile');
        } else {
          // Navigate to previous page
          const return_url = localStorage.getItem('return_url');
          navigate(return_url || '/'); // Fallback to '/' if no previous path found
          localStorage.removeItem('return_url'); // Clear return_url from local storage
        }
      }
    };

    handleCallback();
  }, [handleRedirectCallback, isAuthenticated, navigate, user]);

  if (error) {
    return <div>Oops! Something went wrong: {error.message}</div>;
  }

  return null;
};
