import React from 'react';
import { ChampionItem } from '../Types';
import './ChampionDetailsTile.css';
import './ChampionModal.css';
import { observer } from 'mobx-react-lite';
import {
  Ability,
  ArtifactList,
  BookedSkills,
  ChampionArtifacts,
  ChampionTile,
  ChampMasteryScrolls,
  ChampStats,
  NextGenImage,
} from 'Components';
import ChampMasteriesCondensed from './Library/ChampMasteriesCondensed';
import { getEquippedArtifacts } from './DataView/Helpers';
import { getAffinityData, getAreaData, getArtifactSets, getAuraData } from 'Data';

export interface ChampionDetailsTileProps {
  item: ChampionItem;
  leaderKeys: (string | number | undefined)[];
  onRemove: () => void;
  index: number;
  isFirst: boolean;
  isLast: boolean;
  moveTile: (direction: string, index: number) => void;
}

export const ChampionDetailsTile: React.FC<ChampionDetailsTileProps> = observer(
  ({ item, leaderKeys, onRemove, index, isFirst, isLast, moveTile }) => {
    const allEquippedArtifacts = getEquippedArtifacts(item);

    const handleArrowClick = (direction: string) => {
      moveTile(direction, index);
    };

    let leaderData;
    let auraDescriptionOfLeader: string = '';
    if (item && leaderKeys && leaderKeys.includes(String(item.key))) {
      leaderData = item;
    }

    if (leaderData) {
      const aura = leaderData?.type.aura || 'none';
      if (aura !== 'none') {
        const auraData = getAuraData(aura.statKind);
        const area = (aura.area && getAreaData(aura.area))?.statKind ?? 'All Battles';
        const affinityData = aura.affinity && getAffinityData(aura.affinity);
        const alliesPart = ['All', affinityData?.statKind, 'Ally'].filter(Boolean).join(' ');
        const amountPart = aura.absolute ? +aura.value : `${Math.round(aura.value * 100)}%`;
        auraDescriptionOfLeader = `Increases ${alliesPart} ${auraData.statKind} in ${area} by ${amountPart}`;
      }
    }
    // minimal setup:
    type SetKindId = string;

    const countBySetKindId: Record<SetKindId, number> = {};
    for (const artifact of allEquippedArtifacts) {
      if (typeof artifact === 'object' && 'setKindId' in artifact) {
        countBySetKindId[artifact.setKindId] = (countBySetKindId[artifact.setKindId] ?? 0) + 1;
      }
    }

    const artifactSetData = getArtifactSets();

    const artifactSetDataArray = Object.entries(artifactSetData);

    const heroTypeId = `${item.type?.typeId && item.type?.typeId - (item.type?.typeId % 10)}`;

    const allSkillTypeIds = item.type.forms?.flatMap((form) => form.skillTypeIds) || [];

    return (
      <div className={`ChampionDetailsTile ${leaderData && item && item.key === leaderData.key && `isLeader`}`}>
        <button className="removeChampionDetailsTile" onClick={onRemove} title="Click here to remove this tile.">
          X
        </button>
        {!isFirst && (
          <button
            className="ChampionDetailsTile-arrow left"
            onClick={() => handleArrowClick('left')}
            title="Click here to move this tile one position to the left."
          >
            &lt;
          </button>
        )}
        {!isLast && (
          <button
            className="ChampionDetailsTile-arrow right"
            onClick={() => handleArrowClick('right')}
            title="Click here to move this tile one position to the right."
          >
            &gt;
          </button>
        )}

        {item && item.key === leaderData?.key && (
          <div className="auraDescriptionOfLeader-wrap">{auraDescriptionOfLeader}</div>
        )}
        <div className="ChampDetailsItem-wrap">
          <div className="item-wrap">
            <div className="ChampionDetailsTileContent-wrap">
              <div className="ChampTile-wrap">
                <ChampionTile item={item} local={true} />
              </div>
              <div className="EverythingRightOfChampTile-wrap">
                <div className="StatsAndCompleteSets-wrap">
                  <div className="flex">
                    <div className="Stats-wrap">
                      <ChampStats item={item} headers={false} />
                    </div>
                    <div className="BookedSkills-wrap">
                      {allSkillTypeIds.map((skillId: any) => (
                        <Ability
                          key={`skill${skillId}`}
                          skillId={skillId}
                          heroTypeId={heroTypeId}
                          skillIndex={Number(String(skillId).slice(-1))}
                          instance={item.instance}
                          imageOnly={true}
                          local={true}
                        />
                      ))}
                    </div>
                  </div>
                  <div className="CompleteSets-wrap">
                    <div className="CompleteSets">
                      {artifactSetDataArray.map((item) => {
                        //console.log(`SetLogs: artifactSetDataArray item`, item);
                        let setKindId;
                        let setCount: number = 0;
                        let setSize: number = 10;
                        let completedSetCount: number = 0;
                        let thisChampsCompletedSets = [];
                        if (item[1].exportedKey) {
                          setKindId = item[1].exportedKey;
                        }
                        if (setKindId) {
                          setCount = countBySetKindId[setKindId] || 0;
                        }
                        if (item[1].set_size) {
                          setSize = item[1].set_size;
                          //console.log(`SetLogs: ${setKindId} Set Size`, setSize);
                        }
                        if (setCount >= setSize) {
                          completedSetCount = 1;
                          thisChampsCompletedSets.push(item[1]);
                          if (setCount >= setSize * 2) {
                            thisChampsCompletedSets.push(item[1]);
                            completedSetCount = 2;
                            if (setCount >= setSize * 3) {
                              thisChampsCompletedSets.push(item[1]);
                              completedSetCount = 3;
                            }
                          }
                          console.log(`SetLogs: This champ has ${completedSetCount} complete set of`, setKindId);
                          console.log(`thisChampsCompletedSets`, thisChampsCompletedSets);
                        }
                        if (thisChampsCompletedSets) {
                          let stoneSkinDisplayed = false;
                          let protectionDisplayed = false;
                          return (
                            <>
                              {thisChampsCompletedSets.map((item) => {
                                const regexReplaceSpacesWithUnderscores = /\b( )\b/gm;

                                const setName = item.name;
                                const underscore = `_`;

                                let fileName;
                                if (setName) {
                                  fileName = setName.replace(regexReplaceSpacesWithUnderscores, underscore);
                                }

                                // Check if item name is 'Stone Skin' or 'Protection' and if it's already been displayed
                                if (
                                  (setName === 'Stone Skin' && stoneSkinDisplayed) ||
                                  (setName === 'Protection' && protectionDisplayed)
                                ) {
                                  return null;
                                }

                                if (setName === 'Stone Skin') {
                                  stoneSkinDisplayed = true;
                                }

                                if (setName === 'Protection') {
                                  protectionDisplayed = true;
                                }

                                return (
                                  <div key={item.ordinal} className={`SetIconCircle ${fileName} ${item.exportedKey}`}>
                                    <NextGenImage
                                      whichDirectory={`_NotExtracted/myCreations/ItemSetIcons/ConsistentSize`}
                                      whichImage={`${fileName}`}
                                      title={`${item.name} Set`}
                                      alt={`${item.name} Set`}
                                      local={true}
                                    />
                                  </div>
                                );
                              })}
                            </>
                          );
                        }
                        return;
                      })}
                    </div>
                  </div>
                </div>
                <div className="masteries-wrap">
                  <div className="ChampMasteriesCondensed-wrap">
                    <ChampMasteriesCondensed masteries={item?.instance?.masteries} local={true} />
                  </div>

                  <div className="masteryScrolls-wrap">
                    <ChampMasteryScrolls totalMasteryScrolls={item?.instance?.totalMasteryScrolls} local={true} />
                  </div>
                </div>
              </div>
            </div>
            <div className="ArtifactList-wrap">
              {item && <ChampionArtifacts item={item} statsOutsideArtifact={false} local={true} />}
            </div>
          </div>
        </div>
      </div>
    );
  }
);
