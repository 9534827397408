import React from 'react';
import { FormControl } from '@mui/material';
import { DisplayMetadata } from '../../../Data/Types';
import { FilterDescriptor, useListView, useRootStore } from '../../../Model';
import { DataItem } from 'Types';
import { action, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react-lite';
import { ListViewStore } from '../../../Model/ListViewStore';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { NextGenImage, useSetting } from 'Components';
import { useSearchParams } from 'react-router-dom';
import { loadFilterSettings } from '../Helpers';

export interface ToggleFilterProps<T extends DataItem = DataItem, TNarrow extends T = T> {
  id: string;
  items: Record<string, DisplayMetadata>;
  defaultValue: string;
  minWidth?: string | number;
  canHandle: (opts: any) => boolean;
  filter: (value: string, item: TNarrow, store: any) => boolean | TNarrow | undefined;
  save?: boolean;
  saveAsString?: boolean;
  persist?: boolean;
  getOptionCount?: (option: string) => number;
}

export class ObservableToggleFilter<T extends DataItem = DataItem, TNarrow extends T = T>
  implements FilterDescriptor<T>
{
  private selectedValue: string = '';
  private id: string;

  public isDefaultValue() {
    return this.selectedValue === this.props.defaultValue;
  }

  public getURLRepresentation() {
    return this.selectedValue;
  }

  constructor(private readonly props: ToggleFilterProps<T, TNarrow>) {
    //console.log('Props:', props);
    this.id = props.id;
    const searchParams = new URLSearchParams(document.location.search);
    let value = searchParams.get(this.props.id);
    if (value === null) {
      const filterSettings = loadFilterSettings();
      if (filterSettings && filterSettings[this.props.id]) {
        this.setSelectedValue(filterSettings[this.props.id]);
      }
    } else {
      this.setSelectedValue(value);
    }
    makeObservable(this, {
      selectedValue: observable,
      canHandle: false,
      filterFn: false,
      render: false,
      setSelectedValue: action,
    });
  }

  get Component() {
    return this.render;
  }

  get canHandle() {
    return this.props.canHandle as any;
  }

  filterFn = (listView: ListViewStore, item: T) => {
    if (!this.props.canHandle(listView.options)) {
      return true;
    }
    const store = useRootStore();
    const result = this.props.filter(this.selectedValue || this.props.defaultValue, item as TNarrow, store);
    return !!result;
  };

  private setSelectedValue(key: string) {
    this.selectedValue = key;
  }

  render = observer(() => {
    if (!this.props.canHandle(useListView().options)) {
      return null;
    }
    const { items, id, minWidth, save, saveAsString, persist, getOptionCount } = this.props;

    const [searchParams, setSearchParams] = useSearchParams();
    React.useEffect(() => {
      const params = Object.fromEntries(searchParams);
      if (this.selectedValue) {
        params[id] = this.selectedValue;
      } else if (params[id]) {
        delete params[id];
      } else {
        return;
      }
      setSearchParams(params);
    }, [this.selectedValue]);

    const [savedSelection, setSavedSelection] = useSetting(`saved-selection-${id}`);

    const onSelectionChange = React.useCallback((_event: React.SyntheticEvent, newValue: string) => {
      this.setSelectedValue(newValue);
      if (save === true || saveAsString === true) {
        if (saveAsString !== true) {
          setSavedSelection(Number(newValue));
        } else {
          setSavedSelection(String(newValue));
        }
      }
    }, []);

    const optionCounts: Record<string, number> = {};
    Object.keys(items).forEach((key) => {
      const count = getOptionCount ? getOptionCount(key) : undefined;
      //console.log('Getting option count for key:', key, 'Count:', count);
      if (count !== undefined) {
        optionCounts[key] = count;
      }
    });

    return (
      <FormControl sx={minWidth ? { m: 1, minWidth: minWidth } : { m: 1, minWidth: 80 }} variant="standard">
        <ToggleButtonGroup
          color="primary"
          id={`${id}-toggle`}
          value={persist ? savedSelection : this.selectedValue || this.props.defaultValue}
          exclusive={true}
          onChange={onSelectionChange}
        >
          {Object.entries(items).map(([key, item]) => {
            const optionCount = optionCounts[key];
            //console.log(`Toggle Filter Option Count`, optionCount);
            return (
              <ToggleButton
                key={key}
                value={key}
                className={`ToggleButton-Filter toggleOption ${item.color && item.color}`}
              >
                {item.imageName || item.iconImage ? (
                  <NextGenImage
                    whichDirectory={item.imageDirectory || item.iconDirectory}
                    whichImage={item.imageName || item.iconImage}
                  />
                ) : (
                  `${item.name} ${optionCount !== undefined ? `(${optionCount})` : ``}`
                )}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
      </FormControl>
    );
  });
}
