import * as React from 'react';
import { useTheme, styled } from '@mui/material/styles';
import Popper, { PopperPlacementType } from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import Autocomplete, { autocompleteClasses, createFilterOptions } from '@mui/material/Autocomplete';
import ButtonBase from '@mui/material/ButtonBase';
import InputBase from '@mui/material/InputBase';
import Box from '@mui/material/Box';
import { getRankNumber, getRarityColor } from 'Components/DataView/Helpers';
import { ChampionItem } from '../../Types/Entities/ChampionItem';
import { ChampionDetailsTile, DownloadMyTeamImage, NextGenImage } from 'Components';
import { Input, MenuItem, MenuList, Select } from '@mui/material';
import ScrollContainer from 'react-indiana-drag-scroll';
import Tooltip from 'rc-tooltip';
import { getGlobalRootStore, RootStoreContext } from 'Model';
import { RegionTypeId, RegionTypeName } from 'Types';
import { useRef } from 'react';

interface PopperComponentProps {
  anchorEl?: any;
  disablePortal?: boolean;
  open: boolean;
  placement?: PopperPlacementType;
}

const SelectThenDisplayTeamPopper = styled('div')(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: 'none',
    margin: 0,
    color: 'inherit',
    fontSize: 13,
  },
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      minHeight: 'auto',
      alignItems: 'flex-start',
      padding: 8,
      borderBottom: `1px solid  ${theme.palette.mode === 'light' ? ' #eaecef' : '#30363d'}`,
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]: {
        backgroundColor: theme.palette.action.hover,
      },
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: 'relative',
  },
}));

const StyledPopper = styled(Popper)(({ theme }) => ({
  border: `1px solid ${theme.palette.mode === 'light' ? '#e1e4e8' : '#30363d'}`,
  boxShadow: `0 8px 24px ${theme.palette.mode === 'light' ? 'rgba(149, 157, 165, 0.2)' : 'rgb(1, 4, 9)'}`,
  borderRadius: 6,
  width: 300,
  zIndex: theme.zIndex.modal,
  fontSize: 13,
  color: theme.palette.mode === 'light' ? '#24292e' : '#c9d1d9',
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
}));

function withPlacement(Component) {
  return function WrappedComponent(props) {
    const { placement, ...other } = props;
    return <Component {...other} PopperProps={{ placement }} />;
  };
}

const StyledPopperWithPlacement = withPlacement(StyledPopper);

function PopperComponent(props) {
  const { anchorEl, disablePortal, open, ...other } = props;
  return <StyledPopperWithPlacement anchorEl={anchorEl} disablePortal={disablePortal} open={open} {...other} />;
}

const StyledInput = styled(InputBase)(({ theme }) => ({
  padding: 10,
  width: '100%',
  borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'}`,
  '& input': {
    borderRadius: 4,
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#0d1117',
    padding: 8,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    border: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'}`,
    fontSize: 14,
    '&:focus': {
      boxShadow: `0px 0px 0px 3px ${theme.palette.mode === 'light' ? 'rgba(3, 102, 214, 0.3)' : 'rgb(12, 45, 107)'}`,
      borderColor: theme.palette.mode === 'light' ? '#0366d6' : '#388bfd',
    },
  },
}));

const Button = styled(ButtonBase)(({ theme }) => ({
  fontSize: 13,
  width: '100%',
  textAlign: 'left',
  paddingBottom: 8,
  color: theme.palette.mode === 'light' ? '#586069' : '#8b949e',
  fontWeight: 600,
  '&:hover,&:focus': {
    color: theme.palette.mode === 'light' ? '#0366d6' : '#58a6ff',
  },
  '& span': {
    width: '100%',
  },
  '& svg': {
    width: 16,
    height: 16,
  },
}));

export default function SelectThenDisplayTeam(props: { options: readonly any[] }) {
  const [anchorElChamps, setAnchorElChamps] = React.useState<null | HTMLElement>(null);
  const [champsValue, setChampsValue] = React.useState<ChampionItem[]>([]);
  const [presetsValue, setPresetsValue] = React.useState<RegionTypeName[]>([]);
  const [pendingChampsValue, setPendingChampsValue] = React.useState<ChampionItem[]>([]);
  const [pendingPresetsValue, setPendingPresetsValue] = React.useState<RegionTypeName[]>([]);
  const [teamLocationInputValue, setTeamLocationInputValue] = React.useState('');
  const [anchorElPresets, setAnchorElPresets] = React.useState<null | HTMLElement>(null);
  const [slots, setSlots] = React.useState<number>(0);
  const [champsSelectionMade, setChampsSelectionMade] = React.useState(false);
  const [presetsSelectionMade, setPresetsSelectionMade] = React.useState(false);

  console.log(`champsValue`, champsValue);
  console.log(`presetsValue`, presetsValue);
  //console.log(`pendingChampsValue`, pendingChampsValue);
  //console.log(`pendingPresetsValue`, pendingPresetsValue);
  console.log(`champsSelectionMade`, champsSelectionMade);
  console.log(`presetsSelectionMade`, presetsSelectionMade);
  console.log(`slots`, slots);

  const theme = useTheme();

  console.log(`theme`, theme.palette.mode);
  const lightOrDarkMode = theme.palette.mode;

  const rootStore = React.useContext(RootStoreContext);
  let account;
  if (rootStore !== null) {
    account = rootStore.accounts.selectedAccount;
  }
  const presets = account?.presets || {};
  const champions = account?.champions || [];
  //console.log(`presets`, presets);
  //console.log(`champions`, champions);

  const handleClickSelectChamps = (event: React.MouseEvent<HTMLElement>) => {
    setPendingChampsValue(champsValue);
    setAnchorElChamps(event.currentTarget);
  };

  const handleClickSelectPresets = (event: React.MouseEvent<HTMLElement>) => {
    setPendingPresetsValue(presetsValue);
    setAnchorElPresets(event.currentTarget);
  };

  const handleCloseChampsSelection = () => {
    setChampsValue(pendingChampsValue);
    if (anchorElChamps) {
      anchorElChamps.focus();
    }
    setAnchorElChamps(null);
  };

  const handleClosePresetsSelection = () => {
    setPresetsValue(pendingPresetsValue);
    if (anchorElPresets) {
      anchorElPresets.focus();
    }
    setAnchorElPresets(null);
  };

  const handleClearAll = () => {
    setChampsValue([]);
    setPendingChampsValue([]);
    setPresetsValue([]);
    setPendingPresetsValue([]);
    setChampsSelectionMade(false);
    setPresetsSelectionMade(false);
    setTeamLocationInputValue('');
  };

  const handleRemoveChamps = (index: number, champsValueLength: number) => {
    const newValue = [...champsValue];
    if (champsValueLength > 1) {
      newValue.splice(index, 1);
      setChampsValue(newValue);
    } else {
      handleClearAll();
    }
  };

  const filterChampionOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: ChampionItem) => `${option.type.name} ${option.instance?.id}`,
  });

  const openChampsSelection = Boolean(anchorElChamps);
  const champsId = openChampsSelection ? 'styled-autocomplete-modal-open' : undefined;

  const leaderKeys: (string | number | undefined)[] = [];
  if (champsValue[0]?.key) {
    leaderKeys.push(champsValue[0]?.key);
  }
  if (slots === 6) {
    leaderKeys.push(champsValue[6]?.key);
  }
  if (slots === 5) {
    leaderKeys.push(champsValue[5]?.key);
    leaderKeys.push(champsValue[10]?.key);
    leaderKeys.push(champsValue[15]?.key);
    leaderKeys.push(champsValue[20]?.key);
    leaderKeys.push(champsValue[25]?.key);
  }
  if (slots === 4) {
    leaderKeys.push(champsValue[4]?.key);
    leaderKeys.push(champsValue[8]?.key);
    leaderKeys.push(champsValue[12]?.key);
    leaderKeys.push(champsValue[16]?.key);
    leaderKeys.push(champsValue[20]?.key);
  }

  const openPresetsSelection = Boolean(anchorElPresets);
  const presetsId = openPresetsSelection ? 'styled-autocomplete-modal-open' : undefined;

  const moveChampsTile = (direction: string, index: number) => {
    const newValue = [...champsValue];
    if (direction === 'right' && index < newValue.length - 1) {
      [newValue[index]!, newValue[index + 1]!] = [newValue[index + 1]!, newValue[index]!];
    } else if (direction === 'left' && index > 0) {
      [newValue[index]!, newValue[index - 1]!] = [newValue[index - 1]!, newValue[index]!];
    }
    setChampsValue(newValue);
  };

  const unwantedKeys = ['Tower'];
  const RegionOptions: any[] = Object.entries(RegionTypeName)
    .filter(
      ([key]) =>
        !key.startsWith('Region') /* && !key.startsWith('DoomTower') */ &&
        isNaN(Number(key)) &&
        !unwantedKeys.includes(key)
    )
    .map(([key, presetsValue]) => ({
      label: presetsValue as RegionTypeName,
      presetsValue: key,
    }));

  let screenshotAreaWidth;
  if (slots === 5) {
    screenshotAreaWidth = '2334px';
  } else if (slots === 4) {
    screenshotAreaWidth = '1874px';
  } else {
    screenshotAreaWidth = '2800px';
  }

  //console.log(`selectionCount`, selectionCount);

  const screenshotAreaRef = useRef<HTMLDivElement | null>(null);

  const handleClickSnapToDisplayArea = () => {
    if (screenshotAreaRef.current !== null) {
      screenshotAreaRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <React.Fragment>
      <>
        {presetsSelectionMade === false && (
          <Button className="startHereBtn" disableRipple aria-describedby={champsId} onClick={handleClickSelectChamps}>
            <span className="startHereBtn-text">
              <SettingsIcon className="SettingsIcon" />
              Select all the champs that you want to share, starting with the leader if sharing a team.
            </span>
          </Button>
        )}
        {presetsSelectionMade === false && champsSelectionMade === false && <>OR</>}
        {champsSelectionMade === false && (
          <Button
            className="startHereBtn selectPresetBtn"
            disableRipple
            aria-describedby={presetsId}
            onClick={handleClickSelectPresets}
          >
            <span className="startHereBtn-text selectPresetBtn-text">
              <SettingsIcon className="SettingsIcon" />
              Select an area of the game to load the team that you used last.
            </span>
          </Button>
        )}
        {champsValue.length > 1 && (
          <>
            <div className="typeSomethingHere-wrap container">
              {champsSelectionMade === true && (
                <>
                  <p className="mt-3 DungeonInfoInput-HelperText">
                    <span className="yellow">Optional:</span> Type something in the field below to have it included at
                    the top of the image you download and it will also be included in the filename when you download it.
                  </p>
                  <p>
                    <Input
                      style={{ width: '59.6%' }}
                      type="text"
                      className="DungeonInfoInput"
                      placeholder="Ex. Dragon 25"
                      onChange={(event) => setTeamLocationInputValue(event.target.value)}
                    />
                  </p>
                </>
              )}

              {presetsSelectionMade === true && (
                <>
                  <p className="mt-3 mb-1 DungeonInfoInput-HelperText">
                    <span className="green">Ability to select multiple locations:</span> I made it so that you can
                    select multiple locations but this does come with a few caveats...
                  </p>
                  <div className="caveats">
                    <p className="mt-3 DungeonInfoInput-HelperText">
                      <span className="red">Caveat 1:</span> While this does allow you to select multiple, it will not
                      work perfectly when selecting teams that do not fill all of their available slots. It will still
                      add all the champs from the additional locations you select but they will fill the empty slots
                      from the previous team you selected and therefore will not have each of the selected teams in
                      their own row like I wanted but apparently that is an extremely difficult problem to solve.
                    </p>
                    <p className="mt-3 DungeonInfoInput-HelperText">
                      <span className="red">Caveat 2:</span> I put a lot of time into this to ensure that when you
                      select a location it will adjust the width of the display area to fit the number of slots of the
                      location that you selected. But if you are making multiple selections it will always change the
                      width to the fit the number of slots of the last location you selected. So it should work great if
                      you are selecting multiple locations that have the same number of slots and all the slots are
                      filled in all of them but it will not work perfectly if selecting multiple locations with
                      different amount of slots or not all the slots are filled in any of the locations you select.
                    </p>
                    <p className="mt-3 DungeonInfoInput-HelperText">
                      <span className="red">Caveat 3:</span> It will add all the location names of each location that
                      you select to the text at the top left separated by commas. The caveat is that if you select too
                      many locations this text can start to overlap the RaidChamps logo.
                    </p>
                  </div>
                  <hr />
                  <div>
                    <p className="mt-3 DungeonInfoInput-HelperText">
                      <span className="yellow">Optional:</span> The name of the area of the game that you selected was
                      already added for you but if you want to change the text to say something else feel free to type
                      it in the input below. This text will be in the top left corner of your image just like it is
                      below and it will also be included in the filename when you download it.
                    </p>
                    <p>
                      <Input
                        style={{ width: '86.6%', textAlign: 'center' }}
                        type="text"
                        className="DungeonInfoInput"
                        placeholder="Type here to replace text..."
                        onChange={(event) => setTeamLocationInputValue(event.target.value)}
                      />
                    </p>
                  </div>
                </>
              )}
            </div>
          </>
        )}
        {champsValue.length > 0 && (
          <>
            <div className="buttonsAboveScreenshotArea-wrap" ref={screenshotAreaRef}>
              <DownloadMyTeamImage
                value={champsValue}
                locationText={teamLocationInputValue}
                slots={slots ? slots : 0}
                presetsSelectionCount={presetsValue.length ? presetsValue.length : 0}
              />

              <button className="btn" onClick={handleClickSnapToDisplayArea}>
                Snap to Display Area
              </button>
              <button className="clearAllBtn" onClick={handleClearAll}>
                <span className="clearAllBtn-text">Clear All</span>
              </button>
            </div>
            <div id="screenshotArea-wrap-wrap" className="snapToHere">
              <ScrollContainer className="screenshotArea-wrap">
                <div id="screenshotArea" style={{ width: screenshotAreaWidth }}>
                  <ScrollContainer className="screenshotArea-innerWrap">
                    <div className="top-wrap">
                      <div className="branding">
                        {lightOrDarkMode === 'dark' ? (
                          <NextGenImage
                            local={true}
                            whichDirectory={`_NotExtracted`}
                            whichImage={`logo`}
                            title={'RaidChamps.com'}
                            alt={`RaidChamps.com`}
                          />
                        ) : (
                          <NextGenImage
                            local={true}
                            whichDirectory={`_NotExtracted`}
                            whichImage={`black-logo`}
                            title={'RaidChamps.com'}
                            alt={`RaidChamps.com`}
                          />
                        )}
                      </div>
                      <div className="optionalText AboveResultsStuff">{teamLocationInputValue}</div>
                    </div>
                    <div className="sectionFlex-wrap">
                      {champsValue.map((label, index) => (
                        <>
                          <div className={`ChampionDetailsTile-wrap`}>
                            <ChampionDetailsTile
                              item={label}
                              leaderKeys={leaderKeys}
                              onRemove={() => handleRemoveChamps(index, champsValue.length)}
                              index={index}
                              isFirst={index === 0}
                              isLast={index === champsValue.length - 1}
                              moveTile={moveChampsTile}
                            />
                          </div>
                        </>
                      ))}

                      {/* Seemed simple but wasted a whole day trying to get something like this working, only works for the first selection which is pointless
                       {slots === 5 && champsValue.length === 1 && (
                        <>
                          <div className={`ChampionDetailsTile-wrap`} style={{ width: '448px' }}></div>
                          <div className={`ChampionDetailsTile-wrap`} style={{ width: '448px' }}></div>
                          <div className={`ChampionDetailsTile-wrap`} style={{ width: '448px' }}></div>
                          <div className={`ChampionDetailsTile-wrap`} style={{ width: '448px' }}></div>
                        </>
                      )}

                      {slots === 5 && champsValue.length === 2 && (
                        <>
                          <div className={`ChampionDetailsTile-wrap`} style={{ width: '448px' }}></div>
                          <div className={`ChampionDetailsTile-wrap`} style={{ width: '448px' }}></div>
                          <div className={`ChampionDetailsTile-wrap`} style={{ width: '448px' }}></div>
                        </>
                      )}

                      {slots === 5 && champsValue.length === 3 && (
                        <>
                          <div className={`ChampionDetailsTile-wrap`} style={{ width: '448px' }}></div>
                          <div className={`ChampionDetailsTile-wrap`} style={{ width: '448px' }}></div>
                        </>
                      )}

                      {slots === 5 && champsValue.length === 4 && (
                        <div className={`ChampionDetailsTile-wrap`} style={{ width: '448px' }}></div>
                      )} */}
                    </div>
                  </ScrollContainer>
                </div>
              </ScrollContainer>
            </div>
          </>
        )}
      </>
      <StyledPopper id={champsId} open={openChampsSelection} anchorEl={anchorElChamps} placement="bottom-start">
        <ClickAwayListener onClickAway={handleCloseChampsSelection}>
          <div className="elementThatPopsUpAfterClickingStartHere">
            <Box
              sx={{
                borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'}`,
                padding: '8px 10px',
                fontWeight: 600,
                width: '100%',
              }}
            >
              1) Start by typing the name of your leader below and select them from the options below, followed by the
              rest of the champs in the team you want to share...
            </Box>
            <Autocomplete
              PopperComponent={PopperComponent}
              disablePortal={true}
              multiple
              open={true}
              id="tags-standard"
              renderInput={(params) => (
                <StyledInput
                  ref={params.InputProps.ref}
                  inputProps={params.inputProps}
                  autoFocus
                  placeholder="Type here to filter yours champs by their name..."
                />
              )}
              options={props.options}
              getOptionLabel={(option) => option.type.name + option.instance?.id}
              filterOptions={filterChampionOptions}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Box
                    component={DoneIcon}
                    sx={{ width: 17, height: 17, mr: '5px', ml: '-2px' }}
                    style={{
                      visibility: selected ? 'visible' : 'hidden',
                    }}
                  />
                  <Box
                    sx={{
                      flexGrow: 1,
                      '& span': {
                        color: '#586069',
                      },
                    }}
                  >
                    <div>
                      <span
                        style={{
                          color: getRarityColor(option.type.rarity),
                          fontWeight: 'bold',
                        }}
                      >
                        {option.type.name}
                      </span>
                      <br />
                      <span
                        style={{
                          color: getRarityColor(option.type.rarity),
                          opacity: '0.7',
                        }}
                      >
                        {/* {console.log(`option.instance`, option.instance)} */}
                        {option.instance?.rank.slice(-1)}* lvl. {option.instance?.level} champ with{' '}
                        {option.instance?.marker} Tag{' '}
                        {option.instance?.awakenRank
                          ? `ascended to lvl. ${getRankNumber(option.instance?.awakenRank)}`
                          : ''}
                        {option.instance?.empowerLevel ? `and empowered to lvl. ${option.instance?.empowerLevel}` : ''}
                        {option.instance?.blessing
                          ? ` and blessed with. ${option.instance?.blessing
                              .replace(/([a-z])([A-Z])/g, '$1 $2')
                              .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')}`
                          : ''}
                        {option.instance?.inVault ? ` sitting in your Vault` : ''}
                        {option.instance?.inDeepVault ? ` sitting in your Reserve Vault` : ''}
                      </span>
                    </div>
                    {/* Really cool but loads too slow and would be a pain in the ass to try and style it to line them up side by side in a flex-wrap considering that the options disappear as soon as it loses focus
                         <ChampionTile item={option} />
                        */}
                  </Box>
                  <Box
                    component={CloseIcon}
                    sx={{ opacity: 0.6, width: 18, height: 18 }}
                    style={{
                      visibility: selected ? 'visible' : 'hidden',
                    }}
                  />
                </li>
              )}
              value={pendingChampsValue}
              onChange={(event, newValue, reason) => {
                console.log('New Value:', newValue);
                if (
                  event.type === 'keydown' &&
                  (event as React.KeyboardEvent).key === 'Backspace' &&
                  reason === 'removeOption'
                ) {
                  return;
                }
                setPendingChampsValue(newValue);
                setChampsSelectionMade(true);
              }}
              disableCloseOnSelect={true}
              renderTags={() => null}
              noOptionsText="No labels"
            />
          </div>
        </ClickAwayListener>
      </StyledPopper>
      <StyledPopper id={presetsId} open={openPresetsSelection} anchorEl={anchorElPresets} placement="bottom-start">
        <ClickAwayListener onClickAway={handleClosePresetsSelection}>
          <div className="elementThatPopsUpAfterClickingStartHere">
            <Box
              sx={{
                borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'}`,
                padding: '8px 10px',
                fontWeight: 600,
                width: '100%',
              }}
            >
              1) Select the area of the game you would like to load your preset team from.
            </Box>
            <Autocomplete
              PopperComponent={PopperComponent}
              disablePortal={true}
              multiple
              open={true}
              id="tags-standard"
              renderInput={(params) => (
                <StyledInput
                  ref={params.InputProps.ref}
                  inputProps={params.inputProps}
                  autoFocus
                  placeholder="Type here to filter the areas of the game by their name..."
                />
              )}
              options={RegionOptions}
              getOptionLabel={(option) => option.label}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Box
                    component={DoneIcon}
                    sx={{ width: 17, height: 17, mr: '5px', ml: '-2px' }}
                    style={{
                      visibility: selected ? 'visible' : 'hidden',
                    }}
                  />
                  <Box
                    sx={{
                      flexGrow: 1,
                      '& span': {
                        color: '#586069',
                      },
                    }}
                  >
                    <div>{option.label}</div>
                  </Box>
                  <Box
                    component={CloseIcon}
                    sx={{ opacity: 0.6, width: 18, height: 18 }}
                    style={{
                      visibility: selected ? 'visible' : 'hidden',
                    }}
                  />
                </li>
              )}
              value={pendingPresetsValue}
              onChange={(event, newValue, reason) => {
                if (
                  event.type === 'keydown' &&
                  (event as React.KeyboardEvent).key === 'Backspace' &&
                  reason === 'removeOption'
                ) {
                  return;
                }
                setPendingPresetsValue(newValue);

                let newChampionValues: ChampionItem[] = [];

                // If newValue is an array, we need to handle it differently
                if (Array.isArray(newValue)) {
                  setPendingPresetsValue(newValue);

                  newValue.forEach((preset) => {
                    const regionTypeId = RegionTypeName[preset.presetsValue as keyof typeof RegionTypeName];
                    const regionTypeNameKey = Object.keys(RegionTypeName).find(
                      (key) => RegionTypeName[key as keyof typeof RegionTypeName] === regionTypeId
                    ) as keyof typeof RegionTypeName | undefined;

                    let tempSlots; // declare a local variable
                    switch (true) {
                      case regionTypeNameKey?.startsWith('Arena'):
                        setSlots(4);
                        tempSlots = 4; // assign it the updated value
                        break;
                      case regionTypeNameKey?.startsWith('Hydra'):
                        setSlots(6);
                        tempSlots = 6; // assign it the updated value
                        break;
                      default:
                        setSlots(5);
                        tempSlots = 5; // assign it the updated value
                    }

                    // Use tempSlots instead of slots for presetKey construction
                    let presetKey;
                    if (regionTypeNameKey && !regionTypeNameKey.startsWith('DoomTower')) {
                      presetKey = `${RegionTypeId[regionTypeNameKey]}${tempSlots}`;
                    } else {
                      presetKey = `${RegionTypeId[regionTypeNameKey!]}`;
                    }
                    console.log('Preset Key:', presetKey);

                    const presetChampionIds = presets[presetKey];

                    //console.log('presetKey: ', presetKey);
                    //console.log('presetChampionIds: ', presetChampionIds);

                    // Map each champion id to corresponding champion in account champions
                    if (presetChampionIds) {
                      presetChampionIds.forEach((championId: number) => {
                        const champion = champions.find((champ) => champ.instance?.id === championId);
                        if (champion) newChampionValues.push(champion);
                      });
                    }
                    setChampsValue(newChampionValues);
                  });
                  // Print the first object to see its properties
                  console.log(newValue[0]);
                  // Join the array into a string before setting the inputValue
                  setTeamLocationInputValue(newValue.map((item) => item.label).join(', '));
                } else {
                  // If newValue is a string, we can set it directly
                  setTeamLocationInputValue(newValue);
                }
                setPresetsSelectionMade(true);
              }}
              isOptionEqualToValue={(option, value) => option.presetsValue === value.presetsValue}
              disableCloseOnSelect={true}
              renderTags={() => null}
              noOptionsText="No labels"
            />
          </div>
        </ClickAwayListener>
      </StyledPopper>
    </React.Fragment>
  );
}
