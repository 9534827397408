import {
  getRanks,
  getArtifactRarities,
  getArtifactSets,
  getArtifactStats,
  getFactions,
  DisplayMetadata,
} from '../../Data';
import { ObservableSelectFilter } from './Base/MultiSelectFilterBase';
import { ObservableToggleFilter } from './Base/ToggleFilter';
import { ObservableRangeFilter } from './Base/RangeFilter';
import { ViewOptions, ViewType } from './ViewOptions';
import { MaxedToggleFilterOptions } from './MaxedToggleFilterOptions';
import './ArtifactFilters.css';
import {
  getBestSubRollLevel,
  getCamelCaseStatName,
  returnArtifactsWithSelectedSubstat,
  returnGlyphedArtifacts,
  returnWorthRollingUpFurther,
} from './ArtifactHelpers';
import { ArtifactItem } from 'Types/Entities/ArtifactItem';

/* MultiSelect Filters */
export const ArtifactRarityFilter = new ObservableSelectFilter<ArtifactItem>({
  items: getArtifactRarities(),
  id: 'rarity',
  label: 'Rarities',
  minWidth: '85px',
  canHandle(opts: ViewOptions) {
    return opts.viewType === ViewType.Artifacts;
  },
  filter(keys, item) {
    return keys.length === 0 || keys.includes(item.rarity);
  },
});

export const ArtifactFactionFilter = new ObservableSelectFilter<ArtifactItem>({
  items: getFactions(),
  id: 'faction',
  label: 'Factions',
  minWidth: '95px',
  canHandle(opts: ViewOptions) {
    return opts.viewType === ViewType.Artifacts;
  },
  filter(keys, item) {
    //Use to find the actual exported key for new factions
    //console.log(`item.setKindId`, item.faction);
    return keys.length === 0 || keys.includes(item.faction);
  },
});

const artifactSets = Object.values(getArtifactSets()).filter((artifactSet) => !artifactSet.hidden);

export const ArtifactSetsFilter = new ObservableSelectFilter<ArtifactItem>({
  items: getArtifactSets(),
  id: 'artifactSets',
  label: 'Sets',
  minWidth: '60px',
  autocomplete: true,
  canHandle(opts: ViewOptions) {
    return opts.viewType === ViewType.Artifacts;
  },
  filter(keys, item) {
    //Use to find the actual exported key for new sets
    //console.log(`item.setKindId`, item.setKindId);
    return keys.length === 0 || keys.includes(item.setKindId);
  },
});

export const ArtifactRequiredSubstatsFilter = new ObservableSelectFilter<ArtifactItem>({
  items: getArtifactStats(),
  id: 'artifactSubstats',
  label: 'Required Substats',
  minWidth: '60px',
  canHandle(opts: ViewOptions) {
    return opts.viewType === ViewType.Artifacts;
  },
  filter(keys, item) {
    return keys.length === 0 || keys.includes(String(item.secondaryBonuses));
  },
});

export const ArtifactExcludedSubstatsFilter = new ObservableSelectFilter<ArtifactItem>({
  items: getArtifactStats(),
  id: 'excludeArtifactSubstats',
  label: 'Excluded Substats',
  minWidth: '60px',
  canHandle(opts: ViewOptions) {
    return opts.viewType === ViewType.Artifacts;
  },
  filter(keys, item) {
    return keys.length === 0 || keys.includes(String(item.secondaryBonuses));
  },
});

export const ArtifactRankFilter = new ObservableSelectFilter<ArtifactItem>({
  items: getRanks(),
  id: 'rank',
  label: 'Ranks',
  minWidth: '75px',
  canHandle(opts: ViewOptions) {
    return opts.viewType === ViewType.Artifacts;
  },
  filter(keys, item) {
    return keys.length === 0 || keys.includes(item.rank);
  },
});

/* It would also be useful to share the state of RequiredOrExcludedToggle and then have this filter the results based on the state of that toggle */
export const ArtifactPrimaryStatFilter = new ObservableSelectFilter<ArtifactItem>({
  items: getArtifactStats(),
  id: 'primaryStat',
  label: 'PrimaryStat',
  minWidth: '75px',
  canHandle(opts: ViewOptions) {
    return opts.viewType === ViewType.Artifacts;
  },
  filter(keys, item) {
    return keys.length === 0 || keys.includes(String(item.primaryBonus));
  },
});

/* Range Filters */

export const ArtifactLevelFilter = new ObservableRangeFilter<ArtifactItem>({
  id: 'artifactLevel',
  label: 'Level',
  min: 0,
  max: 16,
  minWidth: 120,
  canHandle(opts: ViewOptions) {
    return opts.viewType === ViewType.Artifacts;
  },
  filter(min, max, item) {
    return !item || (item.level >= min && item.level <= max);
  },
});

export const ArtifactAscendLevelFilter = new ObservableRangeFilter<ArtifactItem>({
  id: 'artifactAscendLevel',
  label: 'Ascend Level',
  min: 0,
  max: 6,
  minWidth: 120,
  canHandle(opts: ViewOptions) {
    return opts.viewType === ViewType.Artifacts;
  },
  filter(min, max, item) {
    return !item || (item.ascendLevel >= min && item.ascendLevel <= max);
  },
});

export const ArtifactBestSubRollLevelFilter = new ObservableRangeFilter<ArtifactItem>({
  id: 'artifactBestSubRollLevel',
  label: 'Best Sub-Roll Level',
  min: 0,
  max: 4,
  minWidth: 120,
  canHandle(opts: ViewOptions) {
    return opts.viewType === ViewType.Artifacts;
  },
  filter(min, max, item) {
    return !item || (getBestSubRollLevel(item) >= min && getBestSubRollLevel(item) <= max);
  },
});

/* Toggle Filters */

const ArtifactSlotOptions: Record<string, DisplayMetadata> = {
  weapon: {
    color: '',
    name: 'Weapon',
    iconDirectory: '_NotExtracted/myCreations/ItemSlots',
    iconImage: 'Weapon',
  },
  helmet: {
    color: '',
    name: 'Helmet',
    iconDirectory: '_NotExtracted/myCreations/ItemSlots',
    iconImage: 'Helmet',
  },
  shield: {
    color: '',
    name: 'Shield',
    iconDirectory: '_NotExtracted/myCreations/ItemSlots',
    iconImage: 'Shield',
  },
  gloves: {
    color: '',
    name: 'Gloves',
    iconDirectory: '_NotExtracted/myCreations/ItemSlots',
    iconImage: 'Gloves',
  },
  chest: {
    color: '',
    name: 'Chest',
    iconDirectory: '_NotExtracted/myCreations/ItemSlots',
    iconImage: 'Chest',
  },
  boots: {
    color: '',
    name: 'Boots',
    iconDirectory: '_NotExtracted/myCreations/ItemSlots',
    iconImage: 'Boots',
  },
  ring: {
    color: '',
    name: 'Ring',
    iconDirectory: '_NotExtracted/myCreations/ItemSlots',
    iconImage: 'Ring',
  },
  cloak: {
    color: '',
    name: 'Cloak',
    iconDirectory: '_NotExtracted/myCreations/ItemSlots',
    iconImage: 'Cloak',
  },
  banner: {
    color: '',
    name: 'Banner',
    iconDirectory: '_NotExtracted/myCreations/ItemSlots',
    iconImage: 'Banner',
  },
};

export const ArtifactSlotsFilter = new ObservableSelectFilter<ArtifactItem>({
  items: ArtifactSlotOptions,
  id: 'artifactSlots',
  label: 'Slots',
  minWidth: '65px',
  iconOnly: true,
  canHandle(opts: ViewOptions) {
    return opts.viewType === ViewType.Artifacts;
  },
  filter(keys, item) {
    return keys.length === 0 || keys.includes(item.kindId);
  },
});

//const selectedArtifactSlot = window.localStorage.getItem('setting:selected-artifact-slot');
//console.log(`ArtifactFilters: selectedArtifactSlot`, selectedArtifactSlot);

//const selectedArtifactPrimaryStat = window.localStorage.getItem('setting:saved-selection-artifactPrimaryStat');
//console.log(`ArtifactFilters: selectedArtifactPrimaryStat`, selectedArtifactPrimaryStat);

export const ArtifactSlotToggleFilter = new ObservableToggleFilter<ArtifactItem>({
  items: ArtifactSlotOptions,
  defaultValue: '',
  id: 'artifactSlot',
  canHandle(opts: ViewOptions) {
    return opts.viewType === ViewType.Artifacts;
  },
  filter(value, item) {
    switch (value) {
      case 'weapon':
        return item.kindId === 'weapon';
      case 'helmet':
        return item.kindId === 'helmet';
      case 'shield':
        return item.kindId === 'shield';
      case 'gloves':
        return item.kindId === 'gloves';
      case 'chest':
        return item.kindId === 'chest';
      case 'boots':
        return item.kindId === 'boots';
      case 'ring':
        return item.kindId === 'ring';
      case 'cloak':
        return item.kindId === 'pendant';
      case 'banner':
        return item.kindId === 'banner';
    }
    return true;
  },
});

/* Primary Stat Filter */
const ArtifactPrimaryStatToggleFilterOptions: Record<string, DisplayMetadata> = {
  off: {
    color: '',
    name: 'Primary Stat',
    //imageDirectory: 'https://ik.imagekit.io/raidchamps/_NotExtracted/myCreations/ItemSlots',
    //imageName: 'Ring',
  },
  healthPercent: {
    name: 'HP%',
    color: '',
    iconUrl: '_NotExtracted/myCreations/Stats/healthPercent.png',
    iconDirectory: '_NotExtracted/myCreations/Stats',
    iconImage: 'healthPercent',
  },
  health: {
    name: 'HP',
    color: '',
    iconUrl: '_NotExtracted/myCreations/Stats/Health.png',
    iconDirectory: '_NotExtracted/myCreations/Stats',
    iconImage: 'Health',
  },
  attackPercent: {
    name: 'ATK%',
    color: '',
    iconUrl: '_NotExtracted/myCreations/Stats/attackPercent.png',
    iconDirectory: '_NotExtracted/myCreations/Stats',
    iconImage: 'attackPercent',
  },
  attack: {
    name: 'ATK',
    color: '',
    iconUrl: '_NotExtracted/myCreations/Stats/Attack.png',
    iconDirectory: '_NotExtracted/myCreations/Stats',
    iconImage: 'Attack',
  },
  defensePercent: {
    name: 'DEF%',
    color: '',
    iconUrl: '_NotExtracted/myCreations/Stats/defensePercent.png',
    iconDirectory: '_NotExtracted/myCreations/Stats',
    iconImage: 'defensePercent',
  },
  defense: {
    name: 'DEF',
    color: '',
    iconUrl: '_NotExtracted/myCreations/Stats/Defense.png',
    iconDirectory: '_NotExtracted/myCreations/Stats',
    iconImage: 'Defense',
  },
  speed: {
    name: 'SPD',
    color: '',
    iconUrl: '_NotExtracted/myCreations/Stats/Speed.png',
    iconDirectory: '_NotExtracted/myCreations/Stats',
    iconImage: 'Speed',
  },
  criticalchance: {
    name: 'C.RATE',
    color: '',
    iconUrl: '_NotExtracted/myCreations/Stats/Critical_Rate.png',
    iconDirectory: '_NotExtracted/myCreations/Stats',
    iconImage: 'Critical_Rate',
  },
  criticaldamage: {
    name: 'C.DMG',
    color: '',
    iconUrl: '_NotExtracted/myCreations/Stats/Critical_Damage.png',
    iconDirectory: '_NotExtracted/myCreations/Stats',
    iconImage: 'Critical_Damage',
  },
  resistance: {
    name: 'RES',
    color: '',
    iconUrl: '_NotExtracted/myCreations/Stats/Resistance.png',
    iconDirectory: '_NotExtracted/myCreations/Stats',
    iconImage: 'Resistance',
  },
  accuracy: {
    name: 'ACC',
    color: '',
    iconUrl: '_NotExtracted/myCreations/Stats/Accuracy.png',
    iconDirectory: '_NotExtracted/myCreations/Stats',
    iconImage: 'Accuracy',
  },
};
export const ArtifactPrimaryStatToggleFilter = new ObservableToggleFilter<ArtifactItem>({
  items: ArtifactPrimaryStatToggleFilterOptions,
  defaultValue: 'off',
  id: 'artifactPrimaryStat',
  canHandle(opts: ViewOptions) {
    return opts.viewType === ViewType.Artifacts;
  },
  filter(value, item) {
    switch (value) {
      case 'healthPercent':
        return item.primaryBonus.statKind.toLowerCase() === 'health' && item.primaryBonus.absolute === false;
      case 'health':
        return item.primaryBonus.statKind.toLowerCase() === 'health' && item.primaryBonus.absolute === true;
      case 'attackPercent':
        return item.primaryBonus.statKind.toLowerCase() === 'attack' && item.primaryBonus.absolute === false;
      case 'attack':
        return item.primaryBonus.statKind.toLowerCase() === 'attack' && item.primaryBonus.absolute === true;
      case 'defensePercent':
        return item.primaryBonus.statKind.toLowerCase() === 'defense' && item.primaryBonus.absolute === false;
      case 'defense':
        return item.primaryBonus.statKind.toLowerCase() === 'defense' && item.primaryBonus.absolute === true;
      case 'speed':
        return item.primaryBonus.statKind.toLowerCase() === 'speed';
      case 'criticalchance':
        return item.primaryBonus.statKind.toLowerCase() === 'criticalchance';
      case 'criticaldamage':
        return item.primaryBonus.statKind.toLowerCase() === 'criticaldamage';
      case 'resistance':
        return item.primaryBonus.statKind.toLowerCase() === 'resistance';
      case 'accuracy':
        return item.primaryBonus.statKind.toLowerCase() === 'accuracy';
    }
    return true;
  },
});

/* Substat Filter */
const ArtifactSubstatToggleFilterOptions: Record<string, DisplayMetadata> = {
  off: {
    color: '',
    name: 'Substat',
    //imageDirectory: 'https://ik.imagekit.io/raidchamps/_NotExtracted/myCreations/ItemSlots',
    //imageName: 'Ring',
  },
  healthPercent: {
    name: 'HP%',
    color: '',
    iconUrl: '_NotExtracted/myCreations/Stats/healthPercent.png',
    iconDirectory: '_NotExtracted/myCreations/Stats',
    iconImage: 'healthPercent',
  },
  health: {
    name: 'HP',
    color: '',
    iconUrl: '_NotExtracted/myCreations/Stats/Health.png',
    iconDirectory: '_NotExtracted/myCreations/Stats',
    iconImage: 'Health',
  },
  attackPercent: {
    name: 'ATK%',
    color: '',
    iconUrl: '_NotExtracted/myCreations/Stats/attackPercent.png',
    iconDirectory: '_NotExtracted/myCreations/Stats',
    iconImage: 'attackPercent',
  },
  attack: {
    name: 'ATK',
    color: '',
    iconUrl: '_NotExtracted/myCreations/Stats/Attack.png',
    iconDirectory: '_NotExtracted/myCreations/Stats',
    iconImage: 'Attack',
  },
  defensePercent: {
    name: 'DEF%',
    color: '',
    iconUrl: '_NotExtracted/myCreations/Stats/defensePercent.png',
    iconDirectory: '_NotExtracted/myCreations/Stats',
    iconImage: 'defensePercent',
  },
  defense: {
    name: 'DEF',
    color: '',
    iconUrl: '_NotExtracted/myCreations/Stats/Defense.png',
    iconDirectory: '_NotExtracted/myCreations/Stats',
    iconImage: 'Defense',
  },
  speed: {
    name: 'SPD',
    color: '',
    iconUrl: '_NotExtracted/myCreations/Stats/Speed.png',
    iconDirectory: '_NotExtracted/myCreations/Stats',
    iconImage: 'Speed',
  },
  criticalchance: {
    name: 'C.RATE',
    color: '',
    iconUrl: '_NotExtracted/myCreations/Stats/Critical_Rate.png',
    iconDirectory: '_NotExtracted/myCreations/Stats',
    iconImage: 'Critical_Rate',
  },
  criticaldamage: {
    name: 'C.DMG',
    color: '',
    iconUrl: '_NotExtracted/myCreations/Stats/Critical_Damage.png',
    iconDirectory: '_NotExtracted/myCreations/Stats',
    iconImage: 'Critical_Damage',
  },
  resistance: {
    name: 'RES',
    color: '',
    iconUrl: '_NotExtracted/myCreations/Stats/Resistance.png',
    iconDirectory: '_NotExtracted/myCreations/Stats',
    iconImage: 'Resistance',
  },
  accuracy: {
    name: 'ACC',
    color: '',
    iconUrl: '_NotExtracted/myCreations/Stats/Accuracy.png',
    iconDirectory: '_NotExtracted/myCreations/Stats',
    iconImage: 'Accuracy',
  },
};
export const ArtifactSubstatToggleFilter = new ObservableToggleFilter<ArtifactItem>({
  items: ArtifactSubstatToggleFilterOptions,
  defaultValue: 'off',
  id: 'ArtifactSubstatToggleFilter',
  canHandle(opts: ViewOptions) {
    return opts.viewType === ViewType.Artifacts;
  },
  filter(value, item) {
    switch (value) {
      case 'healthPercent':
        return item.secondaryBonuses.some((bonus) => bonus.statKind === 'health' && !bonus.absolute);
      case 'health':
        return item.secondaryBonuses.some((bonus) => bonus.statKind === 'health' && bonus.absolute);
      case 'attackPercent':
        return item.secondaryBonuses.some((bonus) => bonus.statKind === 'attack' && !bonus.absolute);
      case 'attack':
        return item.secondaryBonuses.some((bonus) => bonus.statKind === 'attack' && bonus.absolute);
      case 'defensePercent':
        return item.secondaryBonuses.some((bonus) => bonus.statKind === 'defense' && !bonus.absolute);
      case 'defense':
        return item.secondaryBonuses.some((bonus) => bonus.statKind === 'defense' && bonus.absolute);
      case 'speed':
        return item.secondaryBonuses.some((bonus) => bonus.statKind === 'speed');
      case 'criticalchance':
        return item.secondaryBonuses.some((bonus) => bonus.statKind === 'criticalchance' && !bonus.absolute);
      case 'criticaldamage':
        return item.secondaryBonuses.some((bonus) => bonus.statKind === 'criticaldamage' && !bonus.absolute);
      case 'resistance':
        return item.secondaryBonuses.some((bonus) => bonus.statKind === 'resistance');
      case 'accuracy':
        return item.secondaryBonuses.some((bonus) => bonus.statKind === 'accuracy');
    }
    return true;
  },
});

export const ArtifactSubstatsFilter = new ObservableSelectFilter<ArtifactItem>({
  items: ArtifactSubstatToggleFilterOptions,
  id: 'ArtifactSubstats',
  label: 'Substats',
  minWidth: '95px',
  canHandle(opts: ViewOptions) {
    return opts.viewType === ViewType.Artifacts;
  },
  filter(keys, item) {
    let returnedArtifacts;
    item.secondaryBonuses.forEach((bonus) => {
      const camelCaseName = getCamelCaseStatName(bonus) || '';
      if (keys.includes(camelCaseName)) {
        returnedArtifacts = returnArtifactsWithSelectedSubstat(item, bonus.statKind, bonus.absolute);
      }
    });
    return keys.length === 0 || returnedArtifacts;
  },
});

export const ArtifactSubstatsTwoFilter = new ObservableSelectFilter<ArtifactItem>({
  items: ArtifactSubstatToggleFilterOptions,
  id: 'ArtifactSubstats2',
  label: 'Substats 2',
  minWidth: '125px',
  canHandle(opts: ViewOptions) {
    return opts.viewType === ViewType.Artifacts;
  },
  filter(keys, item) {
    let returnedArtifacts;
    item.secondaryBonuses.forEach((bonus) => {
      const camelCaseName = getCamelCaseStatName(bonus) || '';
      if (keys.includes(camelCaseName)) {
        returnedArtifacts = returnArtifactsWithSelectedSubstat(item, bonus.statKind, bonus.absolute);
      }
    });
    return keys.length === 0 || returnedArtifacts;
  },
});

export const ArtifactSubstatsThreeFilter = new ObservableSelectFilter<ArtifactItem>({
  items: ArtifactSubstatToggleFilterOptions,
  id: 'ArtifactSubstats3',
  label: 'Substats 3',
  minWidth: '125px',
  canHandle(opts: ViewOptions) {
    return opts.viewType === ViewType.Artifacts;
  },
  filter(keys, item) {
    let returnedArtifacts;
    item.secondaryBonuses.forEach((bonus) => {
      const camelCaseName = getCamelCaseStatName(bonus) || '';
      if (keys.includes(camelCaseName)) {
        returnedArtifacts = returnArtifactsWithSelectedSubstat(item, bonus.statKind, bonus.absolute);
      }
    });
    return keys.length === 0 || returnedArtifacts;
  },
});

export const ArtifactSubstatsFourFilter = new ObservableSelectFilter<ArtifactItem>({
  items: ArtifactSubstatToggleFilterOptions,
  id: 'ArtifactSubstats4',
  label: 'Substats 4',
  minWidth: '125px',
  canHandle(opts: ViewOptions) {
    return opts.viewType === ViewType.Artifacts;
  },
  filter(keys, item) {
    let returnedArtifacts;
    item.secondaryBonuses.forEach((bonus) => {
      const camelCaseName = getCamelCaseStatName(bonus) || '';
      if (keys.includes(camelCaseName)) {
        returnedArtifacts = returnArtifactsWithSelectedSubstat(item, bonus.statKind, bonus.absolute);
      }
    });
    return keys.length === 0 || returnedArtifacts;
  },
});

export const MaxLevelArtifactToggleFilter = new ObservableToggleFilter<ArtifactItem>({
  items: MaxedToggleFilterOptions,
  defaultValue: 'off',
  id: 'maxLevelArtifact',
  canHandle(opts: ViewOptions) {
    return opts.viewType === ViewType.Artifacts;
  },
  filter(value, item) {
    switch (value) {
      case 'no':
        return item.level < 16;
      case 'yes':
        return item.level === 16;
    }
    return true;
  },
});

const EquippedArtifactFilterOptions: Record<string, DisplayMetadata> = {
  no: {
    color: 'red',
    name: 'No',
  },
  off: {
    color: '',
    name: 'Equipped?',
  },
  yes: {
    color: 'lime',
    name: 'Yes',
  },
};

export const EquippedArtifactToggleFilter = new ObservableToggleFilter<ArtifactItem>({
  items: EquippedArtifactFilterOptions,
  defaultValue: 'off',
  id: 'Equipped',
  canHandle(opts: ViewOptions) {
    return opts.viewType === ViewType.Artifacts;
  },
  filter(value, item) {
    switch (value) {
      case 'no':
        return item.activated === false;
      case 'yes':
        return item.activated === true;
    }
    return true;
  },
});
const NewArtifactFilterOptions: Record<string, DisplayMetadata> = {
  no: {
    color: 'red',
    name: 'No',
  },
  off: {
    color: '',
    name: 'New?',
  },
  yes: {
    color: 'lime',
    name: 'Yes',
  },
};

export const NewArtifactToggleFilter = new ObservableToggleFilter<ArtifactItem>({
  items: NewArtifactFilterOptions,
  defaultValue: 'off',
  id: 'New',
  canHandle(opts: ViewOptions) {
    return opts.viewType === ViewType.Artifacts;
  },
  filter(value, item) {
    switch (value) {
      case 'no':
        return item.seen === true;
      case 'yes':
        return item.seen === false;
    }
    return true;
  },
});

const AccessoriesOrArtifactsFilterOptions: Record<string, DisplayMetadata> = {
  artifacts: {
    color: 'yellow',
    name: 'Artifacts',
  },
  off: {
    color: '',
    name: 'OR',
  },
  accessories: {
    color: 'yellow',
    name: 'Accessories',
  },
};

export const AccessoriesOrArtifactsToggleFilter = new ObservableToggleFilter<ArtifactItem>({
  items: AccessoriesOrArtifactsFilterOptions,
  defaultValue: 'off',
  id: 'ArtifactItem',
  canHandle(opts: ViewOptions) {
    return opts.viewType === ViewType.Artifacts;
  },
  filter(value, item) {
    switch (value) {
      case 'artifacts':
        return (
          item.kindId === 'weapon' ||
          item.kindId === 'helmet' ||
          item.kindId === 'shield' ||
          item.kindId === 'gloves' ||
          item.kindId === 'chest' ||
          item.kindId === 'boots'
        );
      case 'off':
        return item;
      case 'accessories':
        return item.kindId === 'ring' || item.kindId === 'pendant' || item.kindId === 'banner';
    }
    return true;
  },
});

const GlyphedFilterOptions: Record<string, DisplayMetadata> = {
  no: {
    color: 'red',
    name: 'No',
  },
  off: {
    color: '',
    name: 'Glyphed?',
  },
  yes: {
    color: 'lime',
    name: 'Yes',
  },
};

export const GlyphedToggleFilter = new ObservableToggleFilter<ArtifactItem>({
  items: GlyphedFilterOptions,
  defaultValue: 'off',
  id: 'Glyphed',
  canHandle(opts: ViewOptions) {
    return opts.viewType === ViewType.Artifacts;
  },
  filter(value, item) {
    switch (value) {
      case 'no':
        return !returnGlyphedArtifacts(item).includes(item);
      case 'off':
        return item;
      case 'yes':
        return returnGlyphedArtifacts(item).includes(item);
    }
    return true;
  },
});

/* Substats Filter for Selected Slot and Primary Stat */
const SelectedSlotAndPrimaryStatSubstatsFilterOptions: Record<string, DisplayMetadata> = {
  yes: {
    color: 'red',
    name: 'Yes',
  },
  /*   withoutOffensiveSubstats: {
    color: 'red',
    name: 'w/o Offensive',
  },
  withoutDefensiveSubstats: {
    color: 'red',
    name: 'w/o Defensive',
  }, */
  off: {
    color: '',
    name: 'Has atleast 2 flat stats',
  },
  /*   withDefensiveSubstats: {
    color: 'lime',
    name: 'w/ Defensive',
  },
  withOffensiveSubstats: {
    color: 'lime',
    name: 'w/ Offensive',
  }, */
  no: {
    color: 'lime',
    name: 'No',
  },
};

export const ArtifactFlatSubstatsToggleFilter = new ObservableToggleFilter<ArtifactItem>({
  items: SelectedSlotAndPrimaryStatSubstatsFilterOptions,
  defaultValue: 'off',
  id: 'artifactFlatSubstats',
  canHandle(opts: ViewOptions) {
    return opts.viewType === ViewType.Artifacts;
  },
  filter(value, item) {
    switch (value) {
      case 'yes':
        return (
          item.secondaryBonuses &&
          item.secondaryBonuses.filter(
            (substat) =>
              (substat.statKind === 'Attack' || substat.statKind === 'Defense' || substat.statKind === 'Health') &&
              substat.absolute === true
          ).length >= 2
        );

      case 'no':
        return (
          item.secondaryBonuses &&
          item.secondaryBonuses.filter(
            (substat) =>
              (substat.statKind === 'Attack' || substat.statKind === 'Defense' || substat.statKind === 'Health') &&
              substat.absolute === true
          ).length < 2
        );

      default:
        return true;
    }
  },
});

const WorthRollingUpFurtherFilterOptions: Record<string, DisplayMetadata> = {
  no: {
    color: 'red',
    name: 'No',
  },
  off: {
    color: '',
    name: 'Worth Rolling up Further?',
  },
  yes: {
    color: 'lime',
    name: 'Yes',
  },
};
export const WorthRollingUpFurtherToggleFilter = new ObservableToggleFilter<ArtifactItem>({
  items: WorthRollingUpFurtherFilterOptions,
  defaultValue: 'off',
  id: 'WorthRollingUpFurtherFilterOptions',
  canHandle(opts: ViewOptions) {
    return opts.viewType === ViewType.Artifacts;
  },
  filter(value, item) {
    switch (value) {
      case 'no':
        //Atleast 3 right subs and 1 right main
        //Atleast 6* with 3 right stats
        return (
          !returnWorthRollingUpFurther(item) &&
          /* !returnArtifactsThatRolledAllInSingleGoodStat(item) && */
          item.level !== 16 /* && */
          /* !returnAttackPercentageStatsForAllFourSubstats(item, requiredRollsByStageOfGame - 1) */
        );
      case 'yes':
        return (
          returnWorthRollingUpFurther(item) /*  || returnArtifactsThatRolledAllInSingleGoodStat(item) */ &&
          item.level !== 16 /* && */
          /* returnAttackPercentageStatsForAllFourSubstats(item, requiredRollsByStageOfGame - 1) */
        );
    }
    return true;
  },
});

const SellOrKeepFilterOptions: Record<string, DisplayMetadata> = {
  no: {
    color: 'red',
    name: 'Sell',
  },
  off: {
    color: '',
    name: 'Sell or Keep?',
  },
  yes: {
    color: 'lime',
    name: 'Keep',
  },
};
export const SellOrKeepToggleFilter = new ObservableToggleFilter<ArtifactItem>({
  items: SellOrKeepFilterOptions,
  defaultValue: 'off',
  id: 'SellOrKeepFilterOptions',
  canHandle(opts: ViewOptions) {
    return opts.viewType === ViewType.Artifacts;
  },
  filter(value, item) {
    switch (value) {
      case 'no':
        //Atleast 3 right subs and 1 right main
        //Atleast 6* with 3 right stats
        return !returnWorthRollingUpFurther(item) /*  && !returnArtifactsThatRolledAllInSingleGoodStat(item) */;
      case 'yes':
        return returnWorthRollingUpFurther(item) /*  || returnArtifactsThatRolledAllInSingleGoodStat(item) */;
    }
    return true;
  },
});

const StageOfTheGameFilterOptions: Record<string, DisplayMetadata> = {
  earlyGame: {
    color: '',
    name: '1. Early Game',
  },
  midGame: {
    color: '',
    name: '2. Mid Game',
  },
  lateGame: {
    color: '',
    name: '3. Late Game',
  },
  endGame: {
    color: '',
    name: '4. End Game',
  },
};

export const StageOfTheGamSelector = new ObservableSelectFilter<ArtifactItem>({
  items: StageOfTheGameFilterOptions,
  id: 'stageOfTheGamSelector',
  label: 'Stage of the Game',
  minWidth: '175px',
  single: true,
  saveAsString: true,
  reload: true,
  canHandle(opts: ViewOptions) {
    return opts.viewType === ViewType.Artifacts;
  },
  filter(item) {
    if (item) {
      return true;
    }
  },
});
