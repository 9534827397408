import Auth0LoginLogout from 'Components/Layout/Auth0LoginLogout';
import Auth0Upgrade from 'Components/Layout/Auth0Upgrade';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useSearchParams } from 'react-router-dom';
import UserRoles from './UserRoles';

const StripeSuccess = () => {
  const [userEmail, setUserEmail] = useState('');
  const [updatedRoles, setUpdatedRoles] = useState<string[]>([]);
  const [productId, setProductId] = useState('');

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const userEmail = searchParams.get('email');
    const session_id = searchParams.get('session_id');
    setUserEmail(userEmail || '');

    if (session_id) {
      fetch(`/.netlify/functions/getSubscriptionStatus?session_id=${session_id}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.subscription_status === 'active') {
            setUpdatedRoles([data.subscription_level]);
          }
        })
        .catch((error) => console.error(error));
    }
  }, [searchParams]);

  return (
    <div>
      <Helmet>
        <title>Payment Successful | RaidChamps.com</title>
        <meta
          name="description"
          content="Success page members get directed to after subscribing to a RaidChamps.com Membership."
        />
        <meta itemProp="name" content="Payment Successful | RaidChamps.com" />
        <meta
          itemProp="description"
          content="Success page members get directed to after subscribing to a RaidChamps.com Membership."
        />
        <meta itemProp="image" content="https://ik.imagekit.io/raidchamps/_NotExtracted/logo.avif" />
        <meta property="og:url" content="https://raidchamps.com/mychamps" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Success | RaidChamps.com" />
        <meta
          property="og:description"
          content="Success page members get directed to after subscribing to a RaidChamps.com Membership."
        />
        <meta property="og:image" content="https://ik.imagekit.io/raidchamps/_NotExtracted/logo.avif" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Success | RaidChamps.com" />
        <meta
          name="twitter:description"
          content="Success page members get directed to after subscribing to a RaidChamps.com Membership."
        />
        <meta name="twitter:image" content="https://ik.imagekit.io/raidchamps/_NotExtracted/logo.avif" />
      </Helmet>
      <div className="success-page page">
        <div className="container center">
          <h1 className="yellow">Thank you for for Subscribing!</h1>
          <a className="btn" href="/profile">
            View Profile
          </a>
        </div>
      </div>
    </div>
  );
};

export default StripeSuccess;
