import React from 'react';
import { WindowScroller, Grid, AutoSizer, GridCellRenderer } from 'react-virtualized';
import { ChampionTile } from './ChampionTile';
import './ChampionList.css';
import { ChampionItem, ChampionType } from '../Types';

export interface ChampionListProps {
  items: readonly ChampionItem[];
  myChampsSlugs?: (string | undefined)[];
}

const itemPadding = 5;
const itemWidth = 120 + itemPadding * 2;
const itemHeight = 260 + itemPadding * 2;

export const ChampionList: React.FC<ChampionListProps> = ({ items, myChampsSlugs }) => {
  /* if (window.location.hostname === 'localhost') {
    console.log(`items`, items);
  } */
  return (
    <>
      <WindowScroller scrollElement={document.body}>
        {({ height, isScrolling, registerChild, onChildScroll, scrollTop }) => (
          <div style={{ flex: '1 1 auto' }}>
            <AutoSizer disableHeight>
              {({ width }) => {
                const columnCount = Math.max(Math.floor(width / itemWidth), 1);
                const rowCount = Math.ceil(items.length / columnCount);
                const gridWidth = width;
                const cellRenderer: GridCellRenderer = ({ columnIndex, rowIndex, style }) => {
                  const item = items[rowIndex * columnCount + columnIndex];
                  return (
                    item && (
                      <div
                        key={item.key}
                        style={{ ...style, padding: itemPadding, marginTop: 15 }}
                        className={`ChampionTile-wrap ${
                          myChampsSlugs && (myChampsSlugs.includes(item.type.slug) ? 'MyChamp' : 'NotMyChamp')
                        }`}
                      >
                        <ChampionTile item={item} showBothRoles={true} />
                      </div>
                    )
                  );
                };
                return (
                  <>
                    {height && (
                      <div ref={registerChild}>
                        <Grid
                          autoHeight
                          height={height}
                          columnWidth={itemWidth}
                          rowHeight={itemHeight}
                          cellRenderer={cellRenderer}
                          columnCount={columnCount}
                          overscanRowCount={10}
                          scrollTop={scrollTop}
                          onScroll={onChildScroll}
                          isScrolling={isScrolling}
                          rowCount={rowCount + 1}
                          width={gridWidth}
                        />
                      </div>
                    )}
                  </>
                );
              }}
            </AutoSizer>
          </div>
        )}
      </WindowScroller>
    </>
  );
};
