export enum EffectGroup {
  Active = 0,
  Passive = 1,
}
export enum EffectKindId {
  Revive = 0,
  Heal = 1000,
  StartOfStatusBuff = 2000,
  BlockDamage = 2001,
  BlockDebuff = 2002,
  ContinuousHeal = 2003,
  Shield = 2004,
  StatusCounterattack = 2005,
  ReviveOnDeath = 2006,
  ShareDamage = 2007,
  Unkillable = 2008,
  DamageCounter = 2009,
  ReflectDamage = 2010,
  HitCounterShield = 2012,
  Invisible = 2013,
  ReduceDamageTaken = 2014,
  CrabShell = 2015,
  CritShield = 2016,
  SkyWrath = 2017,
  Enrage = 2018,
  VoidAbyss = 2019,
  StatusIncreaseAttack = 2101,
  StatusIncreaseDefence = 2102,
  StatusIncreaseSpeed = 2103,
  StatusIncreaseCriticalChance = 2104,
  StatusChangeDamageMultiplier = 2105,
  StatusIncreaseAccuracy = 2106,
  StatusIncreaseCriticalDamage = 2107,
  EndOfStatusBuff = 2999,
  StartOfStatusDebuff = 3000,
  Freeze = 3001,
  Provoke = 3002,
  Sleep = 3003,
  Stun = 3004,
  BlockHeal = 3005,
  BlockActiveSkills = 3006,
  ContinuousDamage = 3007,
  BlockBuffs = 3008,
  TimeBomb = 3009,
  IncreaseDamageTaken = 3010,
  BlockRevive = 3011,
  Mark = 3012,
  LifeDrainOnDamage = 3013,
  AoEContinuousDamage = 3014,
  Fear = 3015,
  IncreasePoisoning = 3016,
  BlockPassiveSkills = 3017,
  StatusReduceAttack = 3101,
  StatusReduceDefence = 3102,
  StatusReduceSpeed = 3103,
  StatusReduceCriticalChance = 3104,
  StatusReduceAccuracy = 3105,
  StatusReduceCriticalDamage = 3106,
  EndOfStatusDebuff = 3999,
  ApplyBuff = 4000,
  IncreaseStamina = 4001,
  RemoveDebuff = 4003,
  ActivateSkill = 4004,
  ShowHiddenSkill = 4005,
  TeamAttack = 4006,
  ExtraTurn = 4007,
  LifeShare = 4008,
  ReduceCooldown = 4009,
  ReduceDebuffLifetime = 4010,
  IncreaseBuffLifetime = 4011,
  PassiveCounterattack = 4012,
  PassiveChangeStats = 4013,
  PassiveBlockDebuff = 4014,
  PassiveBonus = 4015,
  MultiplyBuff = 4016,
  PassiveReflectDamage = 4017,
  PassiveShareDamage = 4018,
  IncreaseShield = 4020,
  ReturnDebuffs = 4021,
  TransferDebuff = 4022,
  EndOfInstantBuff = 4999,
  ApplyDebuff = 5000,
  ReduceStamina = 5001,
  StealBuff = 5002,
  RemoveBuff = 5003,
  IncreaseCooldown = 5004,
  ReduceBuffLifetime = 5005,
  PassiveDebuffLifetime = 5006,
  SwapHealth = 5007,
  IncreaseDebuffLifetime = 5008,
  DestroyHp = 5009,
  Detonate = 5010,
  MultiplyDebuff = 5011,
  ReduceShield = 5012,
  EndOfInstantDebuff = 5999,
  Damage = 6000,
  HitTypeModifier = 7000,
  ChangeDefenceModifier = 7001,
  ChangeEffectAccuracy = 7002,
  MultiplyEffectChance = 7003,
  ChangeDamageMultiplier = 7004,
  IgnoreProtectionEffects = 7005,
  ChangeCalculatedDamage = 7006,
  ChangeEffectRepeatCount = 7007,
  ChangeDestroyHpAmount = 7008,
  ChangeHealMultiplier = 7009,
  ChangeStaminaModifier = 7010,
  Summon = 8000,
  CopyHero = 8001,
  ChangeEffectTarget = 9000,
  CancelEffect = 9001,
  ForceStatusEffectTick = 9002,
  ChangeSkyWrathCounter = 9005,
  UpdateCombo = 9006,
  SetVoidAbyssCounter = 9007,
  StatusBanish = 9010,
  Banish = 9011,
  EffectDurationModifier = 10000,
  CheckTargetForCondition = 11000,
  EffectContainer = 11001,
}
export enum EffectTargetType {
  Target = 0, // 19, 20, 22, 32. 33, 34, 35, 36, 37, 38, 39, 40
  Self = 1, // 4, 31
  //Producer = 1,
  //RelationTarget = 2,
  //RelationProducer = 3,
  //Owner = 4,
  Random = 5, // 5, 6, 13, 14, 25
  //RandomAlly = 5,
  //RandomEnemy = 6,
  Aoe = 7, // 7, 8, 9, 26, 29
  //AllAllies = 7,
  //AllEnemies = 8,
  //AllDeadAllies = 9,
  //RandomDeadAlly = 13,
  //RandomDeadEnemy = 14,
  //MostInjuredAlly = 19,
  //MostInjuredEnemy = 20,
  //Boss = 22,
  //RandomRevivableAlly = 25,
  //OwnerAllies = 26,
  //AllHeroes = 29,
  //ActiveHero = 31,
  //AllyWithLowestMaxHp = 32,
  //HeroCausedRelationUnapply = 33,
  //HeroThatKilledProducer = 34,
  //RelationTargetDuplicates = 35,
  //AllyWithLowestStamina = 36,
  //AllyWithHighestStamina = 37,
  //EnemyWithLowestStamina = 38,
  //EnemyWithHighestStamina = 39,
  //RelationProducerOrTeamAttackInitiator = 40,
}
export enum TargetExclusion {
  Target = 0,
  Producer = 1,
  RelationTarget = 2,
  RelationProducer = 3,
}
export enum StatusEffectTypeId {
  Stun = 10,
  Freeze = 20,
  Sleep = 30,
  Provoke = 40,
  Counterattack = 50,
  BlockDamage = 60,
  BlockHeal100p = 70,
  BlockHeal50p = 71,
  ContinuousDamage5p = 80,
  ContinuousDamage025p = 81,
  ContinuousHeal075p = 90,
  ContinuousHeal15p = 91,
  BlockDebuff = 100,
  BlockBuffs = 110,
  IncreaseAttack25 = 120,
  IncreaseAttack50 = 121,
  DecreaseAttack25 = 130,
  DecreaseAttack50 = 131,
  IncreaseDefence30 = 140,
  IncreaseDefence60 = 141,
  DecreaseDefence30 = 150,
  DecreaseDefence60 = 151,
  IncreaseSpeed15 = 160,
  IncreaseSpeed30 = 161,
  DecreaseSpeed15 = 170,
  DecreaseSpeed30 = 171,
  IncreaseAccuracy25 = 220,
  IncreaseAccuracy50 = 221,
  DecreaseAccuracy25 = 230,
  DecreaseAccuracy50 = 231,
  IncreaseCriticalChance15 = 240,
  IncreaseCriticalChance30 = 241,
  DecreaseCriticalChance15 = 250,
  DecreaseCriticalChance30 = 251,
  IncreaseCriticalDamage15 = 260,
  IncreaseCriticalDamage30 = 261,
  DecreaseCriticalDamage15p = 270,
  DecreaseCriticalDamage25p = 271,
  Shield = 280,
  BlockActiveSkills = 290,
  ReviveOnDeath = 300,
  ShareDamage50 = 310,
  ShareDamage25 = 311,
  Unkillable = 320,
  TimeBomb = 330,
  DamageCounter = 340,
  IncreaseDamageTaken25 = 350,
  IncreaseDamageTaken15 = 351,
  BlockRevive = 360,
  ArtifactSet_Shield = 370,
  ReflectDamage15 = 410,
  ReflectDamage30 = 411,
  MinotaurIncreaseDamage = 420,
  MinotaurIncreaseDamageTaken = 430,
  Mark = 440,
  HitCounterShield = 450,
  LifeDrainOnDamage10p = 460,
  Burn = 470,
  Invisible1 = 480,
  Invisible2 = 481,
  Fear1 = 490,
  Fear2 = 491,
  IncreasePoisoning25 = 500,
  IncreasePoisoning50 = 501,
  ReduceDamageTaken15 = 510,
  ReduceDamageTaken25 = 511,
  CrabShell = 520,
  CritShield25 = 530,
  CritShield50 = 531,
  CritShield75 = 532,
  CritShield100 = 533,
  SkyWrath = 540,
  Enrage = 550,
  BlockPassiveSkills = 560,
  StatusBanish = 570,
  VoidAbyss = 580,
  DecreaseResistance25 = 720,
  DecreaseResistance50 = 721,
}
export enum EffectKindGroup {
  Undefined = 1,
  StatusDebuff = 2,
  ResistibleInstantDebuff = 3,
  EffectThatApplyStatusDebuffs = 4,
}
export enum LifetimeUpdateType {
  OnStartTurn = 0,
  OnEndTurn = 1,
  Custom = 2,
}
export enum ApplyMode {
  Unresistable = 0,
  Guaranteed = 1,
}
export enum ProtectionMode {
  ProtectedFromEnemies = 0,
  FullyProtected = 1,
}
export enum UnapplyEffectTarget {
  Target = 1,
  Producer = 2,
}
export enum UnapplyEffectMode {
  Selected = 0,
  AllExceptSelected = 1,
}
export enum HitType {
  Normal = 0,
  Crushing = 1,
  Critical = 2,
  Glancing = 3,
}
export enum ElementRelation {
  Disadvantage = -1,
  Neutral = 0,
  Advantage = 1,
}
export enum EvenMode {
  Average = 0,
  Highest = 1,
  Lowest = 2,
}
export enum ActivateSkillOwner {
  Producer = 0,
  Target = 1,
}
export enum SkillToChange {
  Random = 1,
  ByIndex = 2,
  SkillOfCurrentContext = 3,
  All = 4,
}
export enum AppliedEffectType {
  Buff = 0,
  Debuff = 1,
}
export enum CrabShellLayerType {
  First = 0,
  Second = 1,
  Third = 2,
}
export enum PassiveBonus {
  Heal = 1,
  ShieldCreation = 2,
  StaminaRecovery = 3,
  ArtifactSetStats = 4,
}
export enum SkillBonusType {
  Attack = 'Attack',
  Health = 'Health',
  EffectChance = 'EffectChance',
  CooltimeTurn = 'CooltimeTurn',
  ShieldCreation = 'ShieldCreation',
}
