import { PanelDirection, SiteMenuButton, Hamburger, Panel } from 'Components';
import React, { CSSProperties } from 'react';
import './CornerMenu.css';

export type VerticalPosition = 'top' | 'bottom';
export type HorizontalPosition = 'left' | 'right';

export interface CornerMenuProps {
  position: `${VerticalPosition} ${HorizontalPosition}`;
  style?: React.CSSProperties;
  className?: string;
  panelClassName?: string;
  text: string;
}

export const CornerMenu: React.FC<CornerMenuProps> = ({
  position,
  text,
  children,
  style,
  className,
  panelClassName,
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [siteMenuVisible, setSiteMenuVisible] = React.useState(false);
  const toggleSiteMenu = React.useCallback(() => setSiteMenuVisible((state) => !state), []);
  const closeSiteMenu = React.useCallback(() => setSiteMenuVisible(false), []);
  const [positionStyle, direction, alignItems, flexDirection] = React.useMemo(() => {
    const props = position.split(' ') as [VerticalPosition, HorizontalPosition];
    return [
      props.reduce<CSSProperties>((style, prop) => ({ ...style, [prop]: 0 }), {}),
      props.includes('left') ? PanelDirection.right : PanelDirection.left,
      props.includes('left') ? 'start' : 'end',
      props.includes('bottom') ? 'column-reverse' : ('column' as React.CSSProperties['flexDirection']),
    ];
  }, [position]);

  return (
    <div
      ref={ref}
      className={['dark', className].filter(Boolean).join(' ')}
      style={{
        ...positionStyle,
        position: 'fixed',
        zIndex: 12,
        display: 'flex',
        flexDirection,
        alignItems,
        marginRight: 10,
      }}
    >
      <SiteMenuButton style={{ height: 50 }} active={siteMenuVisible} icon={<Hamburger />} onActivate={toggleSiteMenu}>
        {text}
      </SiteMenuButton>
      <div style={{ position: 'relative' }}>
        <Panel
          owner={ref.current}
          className="dark"
          style={{ position: 'absolute', ...positionStyle, width: 'max-content' }}
          direction={direction}
          visible={siteMenuVisible}
          onDismiss={closeSiteMenu}
        >
          <div style={style} className={panelClassName}>
            {children}
          </div>
        </Panel>
      </div>
    </div>
  );
};
