import { mergeStyles } from '@fluentui/merge-styles';
import React from 'react';
import './Hamburger.css';

export interface HamburgerProps {
  className?: string;
  style?: React.CSSProperties;
}

export const Hamburger: React.FC<HamburgerProps> = ({ className, style }) => {
  return (
    <div className={mergeStyles('icon-hamburger', className)} style={{ ...style }}>
      <span />
      <span />
      <span />
    </div>
  );
};
