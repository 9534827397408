import {
  getAffinities,
  getFactions,
  getRarities,
  getRoles,
  getDamageVariables,
  getSkills,
  getRanks,
  getMasteries,
  getTags,
  getAuraAreas,
  getAuras,
  DisplayMetadata,
  getArtifactSets,
  OrderedDisplayMetadata,
  getStatusEffects,
  getTargetTypes,
  getEffectKinds,
  getBlessings,
  getAllChamps,
  getRolesFromFormsData,
} from '../../Data';
import {
  AbilityType,
  AreaId,
  AuraAreas,
  ChampionInstance,
  ChampionItem,
  EffectKindId,
  MasteryKindId,
  StatusEffectTypeId,
  ChampionInstanceData,
  AbilityData,
  FactionId,
  Rarity,
  ArtifactSetKindId,
  EffectGroup,
} from '../../Types';
import { ObservableSelectFilter } from './Base/MultiSelectFilterBase';
import { ObservableToggleFilter } from './Base/ToggleFilter';
import { ObservableRangeFilter } from './Base/RangeFilter';
import { ObservableTextFilter } from './Base/TextFilter';
import { ViewOptions, ViewType } from './ViewOptions';
import {
  getFactionName,
  getRankNumber,
  getRoleNameThatMatches,
  getStatusEffectId,
  isPhrase,
  isString,
  returnEffectsWithApplyStatusEffectParams,
} from '../../Components/DataView/Helpers';
import { getGlobalRootStore } from 'Model';
import {
  getAwakenRankNumber,
  getPotentialFactionGuardians,
  getFWRatingNumber,
  returnEffectWithThisId,
  getFactionGuardians,
} from './Helpers';
import { specificCaseFixes } from './AdvancedAbilityFilters';
import { RootStore } from 'Model/RootStore';
import { EffectRelation } from '@raid-toolkit/webclient';
import { useFilteredChampions } from './FilteredChampionsContext';

const regexPassive = /\[P\]|\[Passive\]/gim;

function getOptionCountGeneric(optionKey: string, filterFn: (champ: ChampionItem) => boolean) {
  const champions = getAllChamps();
  //console.log('Total champions:', champions.length); // Debugging line
  const filteredChampions = champions.filter(filterFn);
  //console.log('Filtered champions:', filteredChampions.length); // Debugging line
  return filteredChampions.length;
}

type CustomFilterFunc<T> = (min: number, max: number, item: T) => boolean;

// Create filter functions
const createObservableRangeFilter = (
  id: string,
  label: string,
  source: string,
  min: number = 0,
  max: number = 5,
  isAreaRating: boolean = true,
  customFilter?: CustomFilterFunc<T>
) => {
  return new ObservableRangeFilter<ChampionItem>({
    id: `rating.${source}.${id}`,
    label,
    min,
    max,
    canHandle: (opts: ViewOptions) => {
      return opts.viewType === ViewType.Account || opts.viewType === ViewType.Index;
    },
    filter: (min, max, item) => {
      if (customFilter) {
        return customFilter(min, max, item);
      }
      let sourceData;
      if (isAreaRating) {
        // Make sure item.type and item.type.ratings and item.type.ratings.detailed exist
        if (item.type && item.type.ratings && item.type.ratings.detailed) {
          if (source === 'ayumilove') {
            sourceData = item.type.ratings.detailed.ayumilove;
          } else if (source === 'hh' || source === 'hellhades') {
            sourceData = item.type.ratings.detailed.hellhades;
          } else if (source === 'chosen' || source === 'au') {
            sourceData = item.type.ratings.detailed.chosen;
          } else {
            sourceData = item.type;
          }
        }

        // Check if sourceData is defined before accessing areaRatings
        if (sourceData && sourceData.areaRatings) {
          const rating = sourceData.areaRatings[id];
          if (rating === undefined) {
            return true;
          }
          return rating >= min && rating <= max;
        } else {
          return true; // or however you want to handle this case
        }
      } else {
        // New logic for non-areaRatings
        sourceData = item.type[source];
        if (sourceData === undefined) {
          return true;
        }
        return sourceData >= min && sourceData <= max;
      }
    },
  });
};

type CustomFilterConfig<T> = {
  getOptionCount?: (value: string) => number;
  canHandle?: (opts: ViewOptions) => boolean;
  filter?: (keys: string[], item: T) => boolean;
};

type ObservableSelectFilterConfig<T> = {
  single?: boolean;
  autocomplete?: boolean;
  hideIcons?: boolean;
  iconOnly?: boolean;
};

function createObservableSelectFilter<T, U>(
  id: string,
  label: string,
  minWidth: string,
  attribute: string,
  items: U[] | Partial<Record<string, DisplayMetadata>>,
  customConfig?: CustomFilterConfig<T>,
  observableConfig?: ObservableSelectFilterConfig<T>
) {
  const defaultGetOptionCount = (value: string) =>
    attribute === 'role'
      ? getOptionCountGeneric(value, (champ: any) => {
          const role = champ.type.forms[0]?.role?.toLowerCase();
          return role === value.toLowerCase();
        })
      : getOptionCountGeneric(value, (champ: any) => champ.type[attribute] === value.toLowerCase());

  const defaultCanHandle = (opts: ViewOptions) =>
    opts.viewType === ViewType.Account || opts.viewType === ViewType.Index;
  const defaultFilter = (keys: string[], item: any) => {
    if (keys.length === 0) return true;

    if (attribute === 'role') {
      // Handle the 'role' attribute specially
      const role = item.type.forms[0]?.role?.toLowerCase();
      return keys.some((key) => key.toLowerCase() === role);
    } else {
      // Handle other attributes
      return keys.includes(item.type[attribute]);
    }
  };

  return new ObservableSelectFilter<T>({
    ...observableConfig,
    items,
    id,
    label,
    minWidth,
    getOptionCount: customConfig?.getOptionCount || defaultGetOptionCount,
    canHandle: customConfig?.canHandle || defaultCanHandle,
    filter: customConfig?.filter || defaultFilter,
  });
}

const createAuraRangeFilter = (isPercentage: boolean = false) => {
  return (min: number, max: number, item: ChampionItem) => {
    if (min === 0 && (max === 100 || max === 50)) return true;
    if (!item.type.aura) return false;

    let value = item.type.aura.value;
    if (isPercentage) {
      if (item.type.aura.absolute) return false;
      value *= 100;
    } else {
      if (item.type.aura.absolute === false) return false;
    }

    return value >= min && value <= max;
  };
};

type FilterFunction<T> = (champ: ChampionItem, value: T) => boolean;

function createCustomConfig<T>(
  filterFn: FilterFunction<T>,
  canHandleFn: (opts: ViewOptions) => boolean = (opts) =>
    opts.viewType === ViewType.Account || opts.viewType === ViewType.Index
): CustomFilterConfig<ChampionItem> {
  return {
    getOptionCount: (value: string) => {
      return getOptionCountGeneric(value, (champ: ChampionItem) => filterFn(champ, value as unknown as T));
    },
    canHandle: canHandleFn,
    filter: (keys, item) => {
      return keys.length === 0 || keys.some((key) => filterFn(item, key as unknown as T));
    },
  };
}

export const RarityFilter = createObservableSelectFilter('rarity', 'Rarity', '75px', 'rarity', getRarities());
export const RoleFilter = createObservableSelectFilter('type', 'Type', '65px', 'role', getRoles());
export const AffinityFilter = createObservableSelectFilter('affinity', 'Affinity', '85px', 'affinity', getAffinities());
export const FactionFilter = createObservableSelectFilter('faction', 'Faction', '85px', 'faction', getFactions());

// Define the filter function that matches the FilterFunction<T> type
const statusEffectFilterFn: FilterFunction<ChampionItem> = (champ: ChampionItem, statusEffectLabel: any) => {
  let returnChamp = false;

  // Initialize an empty array to collect all skillTypeIds from each form
  let allSkillIds: any[] = [];

  // Collect all skillTypeIds from each form into allSkillIds
  if (champ.type.forms) {
    champ.type.forms.forEach((form) => {
      if (form.skillTypeIds) {
        allSkillIds = [...allSkillIds, ...form.skillTypeIds];
      }
    });
  }

  // Loop through the collected skillTypeIds
  allSkillIds.forEach((skillId) => {
    const abilityData = skills[skillId];
    const effects = abilityData?.effects || [];
    const statusEffects = returnEffectsWithApplyStatusEffectParams(effects) || [];
    statusEffects.forEach((statusEffect) => {
      if (statusEffect?.applyStatusEffectParams.statusEffectInfos) {
        const statusEffectInfos = statusEffect?.applyStatusEffectParams.statusEffectInfos;
        statusEffectInfos.forEach((statusEffectInfo: { typeId: any }) => {
          if (statusEffectInfo.typeId && String(statusEffectInfo.typeId) === String(statusEffectLabel)) {
            returnChamp = true;
          }
        });
      }
    });
  });

  return returnChamp;
};

// Use createCustomConfig with the filter function
const statusEffectCustomConfig = createCustomConfig(statusEffectFilterFn);

export const StatusEffectMultiSelectFilter = createObservableSelectFilter(
  'statusEffectMultiSelect',
  'Status Effects',
  '155px',
  'statusEffect', // attribute to filter by
  getStatusEffects() as unknown as ChampionItem[],
  statusEffectCustomConfig,
  {
    single: false,
    autocomplete: true,
  }
);
export const StatusEffectTwoMultiSelectFilter = createObservableSelectFilter(
  'statusEffectMultiSelect2',
  'Status Effects 2',
  '155px',
  'statusEffect', // attribute to filter by
  getStatusEffects() as unknown as ChampionItem[],
  statusEffectCustomConfig,
  {
    single: false,
    autocomplete: true,
  }
);
export const StatusEffectThreeMultiSelectFilter = createObservableSelectFilter(
  'statusEffectMultiSelect3',
  'Status Effects 3',
  '155px',
  'statusEffect', // attribute to filter by
  getStatusEffects() as unknown as ChampionItem[],
  statusEffectCustomConfig,
  {
    single: false,
    autocomplete: true,
  }
);

const effectKindFilterFn = (champ: ChampionItem, effectKindLabel: string) => {
  const effectKindTypeId = effectKindLabel; // Assuming effectKindLabel is the typeId you want to filter by
  let returnChamp = false;

  // Initialize an empty array to collect all skillTypeIds from each form
  let allSkillIds: any[] = [];

  // Collect all skillTypeIds from each form into allSkillIds
  if (champ.type.forms) {
    champ.type.forms.forEach((form) => {
      if (form.skillTypeIds) {
        allSkillIds = [...allSkillIds, ...form.skillTypeIds];
      }
    });
  }

  // Loop through the collected skillTypeIds
  allSkillIds.forEach((item) => {
    const abilityData = skills[item];
    const effects = abilityData?.effects || [];
    effects.forEach((effect: { kindId: any }) => {
      if (effect.kindId && String(effect.kindId) === effectKindTypeId) {
        returnChamp = true;
      }
    });
  });

  return returnChamp;
};

const effectKindCustomConfig: CustomFilterConfig<ChampionItem> = {
  getOptionCount: (effectKindLabel: string) => {
    return getOptionCountGeneric(effectKindLabel, (champ) => effectKindFilterFn(champ, effectKindLabel));
  },
  filter: (keys, item) => {
    return keys.length === 0 || keys.every((key) => effectKindFilterFn(item, key));
  },
  canHandle: (opts: ViewOptions) => {
    return opts.viewType === ViewType.Account || opts.viewType === ViewType.Index;
  },
};

export const EffectKindsMultiSelectFilter = createObservableSelectFilter(
  'effectKindMultiSelect',
  'Effect Kinds',
  '139px',
  'effectKind', // attribute to filter by
  getEffectKinds() as DisplayMetadata[],
  effectKindCustomConfig,
  {
    single: false,
    autocomplete: true,
    hideIcons: true,
  }
);

export const EffectKindsTwoMultiSelectFilter = createObservableSelectFilter(
  'effectKindMultiSelect2',
  'Effect Kinds 2',
  '139px',
  'effectKind', // attribute to filter by
  getEffectKinds() as DisplayMetadata[],
  effectKindCustomConfig,
  {
    single: false,
    autocomplete: true,
    hideIcons: true,
  }
);

export const EffectKindsThreeMultiSelectFilter = createObservableSelectFilter(
  'effectKindMultiSelect3',
  'Effect Kinds 3',
  '139px',
  'effectKind', // attribute to filter by
  getEffectKinds() as DisplayMetadata[],
  effectKindCustomConfig,
  {
    single: false,
    autocomplete: true,
    hideIcons: true,
  }
);

const targetTypeFilterFn = (champ: ChampionItem, targetTypeLabel: string) => {
  let returnChamp = false;
  const targetTypeKey = targetTypeLabel; // Assuming targetTypeLabel is the key you want to filter by

  // Initialize an empty array to collect all skillTypeIds from each form
  let allSkillIds: any[] = [];

  // Collect all skillTypeIds from each form into allSkillIds
  if (champ.type.forms) {
    champ.type.forms.forEach((form) => {
      if (form.skillTypeIds) {
        allSkillIds = [...allSkillIds, ...form.skillTypeIds];
      }
    });
  }

  // Loop through the collected skillTypeIds
  allSkillIds.forEach((item) => {
    const abilityData = skills[item];
    const effects = abilityData?.effects || [];
    effects.forEach((effect: { relation: { effectTypeId: number } }) => {
      let targetTypeKeyForEffect: number | undefined;
      let relationEffectData = effect.relation ? returnEffectWithThisId(effects, effect.relation.effectTypeId) : effect;
      let relationOfRelationEffectData = relationEffectData?.relation
        ? returnEffectWithThisId(effects, relationEffectData.relation.effectTypeId)
        : null;

      if (relationOfRelationEffectData) {
        targetTypeKeyForEffect = relationOfRelationEffectData?.targetParams?.targetType;
      } else {
        targetTypeKeyForEffect = relationEffectData?.targetParams?.targetType;
      }

      if (String(targetTypeKeyForEffect) === targetTypeKey) {
        returnChamp = true;
      }
    });
  });

  return returnChamp;
};

const targetTypeCustomConfig = createCustomConfig<string>(targetTypeFilterFn);

export const TargetTypesMultiSelectFilter = createObservableSelectFilter(
  'targetTypeMultiSelect',
  'Target Types',
  '139px',
  'targetType', // attribute to filter by
  getTargetTypes() as DisplayMetadata[],
  targetTypeCustomConfig,
  {
    single: false,
    autocomplete: true,
  }
);

export const TargetTypesTwoMultiSelectFilter = createObservableSelectFilter(
  'targetTypeMultiSelect2',
  'Target Types 2',
  '139px',
  'targetType', // attribute to filter by
  getTargetTypes() as DisplayMetadata[],
  targetTypeCustomConfig,
  {
    single: false,
    autocomplete: true,
  }
);

export const TargetTypesThreeMultiSelectFilter = createObservableSelectFilter(
  'targetTypeMultiSelect3',
  'Target Types 3',
  '139px',
  'targetType', // attribute to filter by
  getTargetTypes() as DisplayMetadata[],
  targetTypeCustomConfig,
  {
    single: false,
    autocomplete: true,
  }
);

const damageBasedOnCustomConfig = createCustomConfig<string>((champ, damageVariable) => {
  return !!champ.type.indexed.damageBasedOn && champ.type.indexed.damageBasedOn.includes(damageVariable);
});

export const DamageBasedOnFilter = createObservableSelectFilter(
  'damageBasedOn',
  'Damage Based On',
  '180px',
  'indexed.damageBasedOn', // attribute to filter by
  getDamageVariables(),
  damageBasedOnCustomConfig,
  {
    single: false,
    autocomplete: false,
  }
);

const areaIdToAuraArea: Record<AreaId, AuraAreas> = {
  allianceboss: AuraAreas.allianceBoss,
  arena: AuraAreas.arena,
  arena3x3: AuraAreas.arena,
  doomtower: AuraAreas.doomTower,
  dungeon: AuraAreas.dungeons,
  fractions: AuraAreas.fractions,
  hydra: AuraAreas.hydra,
  story: AuraAreas.campaign,
  allbattles: AuraAreas.allBattles,
};

const auraAreaCustomConfig = createCustomConfig<AuraAreas>((champ, auraArea) => {
  const hasAura = !!champ.type.aura;
  const area = hasAura && (champ.type.aura!.area ?? AreaId.unknown);
  return area && areaIdToAuraArea[area] === auraArea;
});

export const AuraAreaFilter = createObservableSelectFilter(
  'auraArea',
  'Aura Area',
  '105px',
  'aura.area', // attribute to filter by
  getAuraAreas(),
  auraAreaCustomConfig,
  {
    single: false,
    autocomplete: false,
  }
);

// For AuraAffinityFilter
const auraAffinityCustomConfig = createCustomConfig<string>((champ, auraAffinity) => {
  return !!champ.type.aura?.affinity && champ.type.aura?.affinity === auraAffinity;
});

export const AuraAffinityFilter = createObservableSelectFilter(
  'auraAffinity',
  'Aura Affinity',
  '125px',
  'aura.affinity',
  getAffinities(),
  auraAffinityCustomConfig,
  {
    single: false,
    autocomplete: false,
  }
);

// For AuraStatFilter
const auraStatCustomConfig = createCustomConfig<string>((champ, auraStat) => {
  return !!champ.type.aura?.statKind && champ.type.aura?.statKind.includes(auraStat);
});

export const AuraStatFilter = createObservableSelectFilter(
  'auraStat',
  'Aura Stat',
  '100px',
  'aura.statKind',
  getAuras(),
  auraStatCustomConfig,
  {
    single: false,
    autocomplete: false,
  }
);

export const AuraFlatIncreaseFilter = createObservableRangeFilter(
  'auraFlatIncrease',
  'Aura Flat Increase',
  'aura',
  0,
  100,
  false,
  createAuraRangeFilter()
);

export const AuraPercentageFilter = createObservableRangeFilter(
  'auraPercentage',
  'Aura Percentage',
  'aura',
  0,
  50,
  false,
  createAuraRangeFilter(true)
);

export const AuraFactionFilter = new ObservableSelectFilter<ChampionItem>({
  items: getFactions(),
  id: 'auraFaction',
  label: 'Aura Faction',
  minWidth: '85px',
  canHandle(opts: ViewOptions) {
    return opts.viewType === ViewType.Account || opts.viewType === ViewType.Index;
  },
  filter(keys, item) {
    return !!(keys.length === 0 || (item.type.aura?.faction && keys.includes(item.type.aura.faction)));
  },
});

export const BookValueFilter = createObservableRangeFilter(
  'bookvalue',
  'Book Value',
  'hellhades',
  0,
  10,
  false,
  (min, max, item) => {
    const rating = item.type.ratings.detailed.hellhades?.bookRating ?? 1;
    return rating >= min && rating <= max;
  }
);

const createOverallRatingRangeFilterFunc = (source: string, ratingField: string, defaultRating: string | number) => {
  return (min: number, max: number, item: ChampionItem) => {
    const ratingValue = item.type.ratings.detailed[source]?.[ratingField] ?? defaultRating;
    const rating = typeof ratingValue === 'string' ? parseFloat(ratingValue) : ratingValue;
    return rating >= min && rating <= max;
  };
};

// Usage
export const HellHadesRatingFilter = createObservableRangeFilter(
  'hh',
  'HellHades Overall Rating',
  'hellhades',
  0,
  5,
  false,
  createOverallRatingRangeFilterFunc('hellhades', 'overall', 0)
);

export const ChosenRatingFilter = createObservableRangeFilter(
  'chosen',
  'AftershockUnited Overall Rating',
  'chosen',
  0,
  100,
  false,
  createOverallRatingRangeFilterFunc('chosen', 'overall', '0')
);

export const AyumiloveRatingFilter = createObservableRangeFilter(
  'ayumilove',
  'Ayumilove Overall Rating',
  'ayumilove',
  0,
  5,
  false,
  createOverallRatingRangeFilterFunc('ayumilove', 'base5', '0')
);

/* ----- areaRatings ----- */

/* ChoseN */
export const ChosenPotionKeepArcaneRatingFilter = createObservableRangeFilter(
  'PotionKeepArcane',
  'Arcane Keep Rating',
  'chosen',
  0,
  100
);
export const ChosenPotionKeepForceRatingFilter = createObservableRangeFilter(
  'PotionKeepForce',
  'Force Keep Rating',
  'chosen',
  0,
  100
);
export const ChosenPotionKeepMagicRatingFilter = createObservableRangeFilter(
  'PotionKeepMagic',
  'Magic Keep Rating',
  'chosen',
  0,
  100
);
export const ChosenPotionKeepSpiritRatingFilter = createObservableRangeFilter(
  'PotionKeepSpirit',
  'Spirit Keep Rating',
  'chosen',
  0,
  100
);
export const ChosenPotionKeepVoidRatingFilter = createObservableRangeFilter(
  'PotionKeepVoid',
  'Void Keep Rating',
  'chosen',
  0,
  100
);
export const ChosenCampaignRatingFilter = createObservableRangeFilter('Campaign', 'Campaign Rating', 'chosen', 0, 100);
export const ChosenHydraRatingFilter = createObservableRangeFilter('Hydra', 'Hydra Rating', 'chosen', 0, 100);
export const ChosenDragonRatingFilter = createObservableRangeFilter('Dragon', 'Dragon Rating', 'chosen', 0, 100);
export const ChosenSpiderRatingFilter = createObservableRangeFilter('Spider', 'Spider Rating', 'chosen', 0, 100);
export const ChosenFireKnightRatingFilter = createObservableRangeFilter(
  'FireKnight',
  'Fire Knight Rating',
  'chosen',
  0,
  100
);
export const ChosenIceGolemRatingFilter = createObservableRangeFilter('IceGolem', 'Ice Golem Rating', 'chosen', 0, 100);
export const ChosenMinotaurRatingFilter = createObservableRangeFilter('Minotaur', 'Minotaur Rating', 'chosen', 0, 100);
export const ChosenFactionWarsRatingFilter = createObservableRangeFilter(
  'FactionWars',
  'Faction Wars Rating',
  'chosen',
  0,
  100
);
export const ChosenDoomTowerRatingFilter = createObservableRangeFilter(
  'DoomTower',
  'Doom Tower Rating',
  'chosen',
  0,
  100
);
export const ChosenDemonLordRatingFilter = createObservableRangeFilter(
  'DemonLord',
  'Demon Lord Rating',
  'chosen',
  0,
  100
);
export const ChosenArenaRatingFilter = createObservableRangeFilter('Arena', 'Arena Rating', 'chosen', 0, 100);
export const ChosenScarcityRatingFilter = createObservableRangeFilter('Scarcity', 'Scarcity Rating', 'chosen', 0, 100);
export const ChosenProgressionRatingFilter = createObservableRangeFilter(
  'Progression',
  'Progression Rating',
  'chosen',
  0,
  100
);
export const ChosenEndGameRatingFilter = createObservableRangeFilter('EndGame', 'EndGame Rating', 'chosen', 0, 100);
export const ChosenIronTwinsRatingFilter = createObservableRangeFilter(
  'IronTwins',
  'Iron Twins Rating',
  'chosen',
  0,
  100
);
export const ChosenSandDevilRatingFilter = createObservableRangeFilter(
  'SandDevil',
  'Sand Devil Rating',
  'chosen',
  0,
  100
);

/* HellHades */
export const HellHadesDragonRatingFilter = createObservableRangeFilter('Dragon', 'Dragon Rating', 'hh');
export const HellHadesSpiderRatingFilter = createObservableRangeFilter('Spider', 'Spider Rating', 'hh');
export const HellHadesFireKnightRatingFilter = createObservableRangeFilter('FireKnight', 'Fire Knight Rating', 'hh');
export const HellHadesIceGolemRatingFilter = createObservableRangeFilter('IceGolem', 'Ice Golem Rating', 'hh');
export const HellHadesIronTwinsRatingFilter = createObservableRangeFilter('IronTwins', 'Iron Twins Rating', 'hh');
export const HellHadesSandDevilRatingFilter = createObservableRangeFilter('SandDevil', 'Sand Devil Rating', 'hh');
export const HellHadesPhantomShogunRatingFilter = createObservableRangeFilter(
  'PhantomShogun',
  'Phantom Shogun Rating',
  'hh'
);
export const HellHadesHardDragonRatingFilter = createObservableRangeFilter('HardDragon', 'Dragon Rating', 'hh');
export const HellHadesHardSpiderRatingFilter = createObservableRangeFilter('HardSpider', 'Spider Rating', 'hh');
export const HellHadesHardFireKnightRatingFilter = createObservableRangeFilter(
  'HardFireKnight',
  'FireKnight Rating',
  'hh'
);
export const HellHadesHardIceGolemRatingFilter = createObservableRangeFilter('HardIceGolem', 'IceGolem Rating', 'hh');
export const HellHadesFactionWarsRatingFilter = createObservableRangeFilter(
  'FactionWars',
  'Faction Wars Overall Rating',
  'hh'
);
export const HellHadesFWDecreaseDefenseRatingFilter = createObservableRangeFilter(
  'fw_decrease_def',
  'Decrease Defense Rating',
  'hh'
);
export const HellHadesFWDecreaseAttackRatingFilter = createObservableRangeFilter(
  'fw_decrease_atk',
  'Decrease Attack Rating',
  'hh'
);
export const HellHadesFWDamageRatingFilter = createObservableRangeFilter('fw_damage', 'Damage Rating', 'hh');
export const HellHadesFWCrowdControlRatingFilter = createObservableRangeFilter(
  'fw_crowd_control',
  'Crowd Control Rating',
  'hh'
);
export const HellHadesFWTurnMeterControlRatingFilter = createObservableRangeFilter(
  'fw_tm_control',
  'Turn Meter Control Rating',
  'hh'
);
export const HellHadesFWProtectionAndSupportRatingFilter = createObservableRangeFilter(
  'fw_healing_shielding',
  'Protection & Support Rating',
  'hh'
);
export const HellHadesFWReviverRatingFilter = createObservableRangeFilter('fw_reviver', 'Reviver Rating', 'hh');
export const HellHadesDoomTowerRatingFilter = createObservableRangeFilter('DoomTower', 'DoomTower Rating', 'hh');
export const HellHadesDemonLordRatingFilter = createObservableRangeFilter('DemonLord', 'Demon Lord Rating', 'hh');
export const HellHadesArenaRolesRatingFilter = createObservableRangeFilter('arenaRolesRating', 'Arena Rating', 'hh');
export const HellHadesArenaOverallRatingFilter = createObservableRangeFilter(
  'ArenaOverall',
  'Arena Overall Rating',
  'hh'
);
export const HellHadesArenaAttackRatingFilter = createObservableRangeFilter('ArenaAttack', 'Arena Attack Rating', 'hh');
export const HellHadesArenaDefenseRatingFilter = createObservableRangeFilter(
  'ArenaDefense',
  'Arena Defense Rating',
  'hh'
);
export const HellHadesHydraRatingFilter = createObservableRangeFilter('Hydra', 'Hydra Rating', 'hh');
export const HellHadesAllDungeonsRatingFilter = createObservableRangeFilter(
  'AllDungeons',
  'Dungeons Overall Rating',
  'hh'
);
export const HellHadesDTBossAgrethRatingFilter = createObservableRangeFilter(
  'DTBossAgreth',
  'Nether Spider (Agreth) Rating',
  'hh'
);
export const HellHadesDTBossAstranyxRatingFilter = createObservableRangeFilter(
  'DTBossAstranyx',
  'Dark Fae (Astranyx) Rating',
  'hh'
);
export const HellHadesDTBossBommalRatingFilter = createObservableRangeFilter(
  'DTBossBommal',
  'Dreadhorn (Bommal) Rating',
  'hh'
);
export const HellHadesDTBossBorgothRatingFilter = createObservableRangeFilter(
  'DTBossBorgoth',
  'Scarab King (Borgoth) Rating',
  'hh'
);
export const HellHadesDTBossIragothRatingFilter = createObservableRangeFilter(
  'DTBossIragoth',
  'Eternal Dragon (Iragoth) Rating',
  'hh'
);
export const HellHadesDTBossKuldathRatingFilter = createObservableRangeFilter(
  'DTBossKuldath',
  'Magma Dragon (Kuldath) Rating',
  'hh'
);
export const HellHadesDTBossCelestialGriffinRatingFilter = createObservableRangeFilter(
  'DTBossCelestialGriffin',
  'Celestial Griffin Rating',
  'hh'
);
export const HellHadesDTBossSorathRatingFilter = createObservableRangeFilter(
  'DTBossSorath',
  'Frost Spider (Sorath) Rating',
  'hh'
);

//Ayumilove Rating Filters
export const AyumiloveCampaignRatingFilter = createObservableRangeFilter('Campaign', 'Campaign Rating', 'ayumilove');
export const AyumiloveArenaDefenseRatingFilter = createObservableRangeFilter(
  'Arena Defense',
  'Arena Defense Rating',
  'ayumilove'
);
export const AyumiloveArenaOffenseRatingFilter = createObservableRangeFilter(
  'Arena Offense',
  'Arena Offense Rating',
  'ayumilove'
);
export const AyumiloveClanBossRatingFilter = createObservableRangeFilter('Clan Boss', 'Clan Boss Rating', 'ayumilove');
export const AyumiloveHydraRatingFilter = createObservableRangeFilter('Hydra', 'Hydra Rating', 'ayumilove');
export const AyumiloveFactionWarsRatingFilter = createObservableRangeFilter(
  'Faction Wars',
  'Faction Wars Rating',
  'ayumilove'
);

export const AyumiloveMinotaurRatingFilter = createObservableRangeFilter('Minotaur', 'Minotaur Rating', 'ayumilove');
export const AyumiloveSpiderRatingFilter = createObservableRangeFilter('Spider', 'Spider Rating', 'ayumilove');
export const AyumiloveFireKnightRatingFilter = createObservableRangeFilter(
  'Fire Knight',
  'Fire Knight Rating',
  'ayumilove'
);
export const AyumiloveDragonRatingFilter = createObservableRangeFilter('Dragon', 'Dragon Rating', 'ayumilove');
export const AyumiloveIceGolemRatingFilter = createObservableRangeFilter('Ice Golem', 'Ice Golem Rating', 'ayumilove');
export const AyumiloveIronTwinsRatingFilter = createObservableRangeFilter(
  'Iron Twins',
  'Iron Twins Rating',
  'ayumilove'
);
export const AyumiloveSandDevilRatingFilter = createObservableRangeFilter(
  'Sand Devil',
  'Sand Devil Rating',
  'ayumilove'
);

export const AyumiloveArcaneKeepRatingFilter = createObservableRangeFilter(
  'Arcane Keep',
  'Arcane Keep Rating',
  'ayumilove'
);
export const AyumiloveVoidKeepRatingFilter = createObservableRangeFilter('Void Keep', 'Void Keep Rating', 'ayumilove');
export const AyumiloveForceKeepRatingFilter = createObservableRangeFilter(
  'Force Keep',
  'Force Keep Rating',
  'ayumilove'
);
export const AyumiloveSpiritKeepRatingFilter = createObservableRangeFilter(
  'Spirit Keep',
  'Spirit Keep Rating',
  'ayumilove'
);
export const AyumiloveMagicKeepRatingFilter = createObservableRangeFilter(
  'Magic Keep',
  'Magic Keep Rating',
  'ayumilove'
);

export const AyumiloveDoomTowerFloorsRatingFilter = createObservableRangeFilter(
  'Floors',
  'Doom Tower Floors Rating',
  'ayumilove'
);
export const AyumiloveMagmaDragonRatingFilter = createObservableRangeFilter(
  'Magma Dragon',
  'Magma Dragon Rating',
  'ayumilove'
);
export const AyumiloveNetherSpiderRatingFilter = createObservableRangeFilter(
  'Nether Spider',
  'Nether Spider Rating',
  'ayumilove'
);
export const AyumiloveFrostSpiderRatingFilter = createObservableRangeFilter(
  'Frost Spider',
  'Frost Spider Rating',
  'ayumilove'
);
export const AyumiloveScarabKingRatingFilter = createObservableRangeFilter(
  'Scarab King',
  'Scarab King Rating',
  'ayumilove'
);
export const AyumiloveCelestialGriffinRatingFilter = createObservableRangeFilter(
  'Celestial Griffin',
  'Celestial Griffin Rating',
  'ayumilove'
);
export const AyumiloveEternalDragonRatingFilter = createObservableRangeFilter(
  'Eternal Dragon',
  'Eternal Dragon Rating',
  'ayumilove'
);
export const AyumiloveDreadhornRatingFilter = createObservableRangeFilter('Dreadhorn', 'Dreadhorn Rating', 'ayumilove');
export const AyumiloveDarkFaeRatingFilter = createObservableRangeFilter('Dark Fae', 'Dark Fae Rating', 'ayumilove');

const skills: any = getSkills();

export const AllChampsSearchFilter = new ObservableTextFilter({
  id: 'q',
  canHandle(opts: ViewOptions) {
    return opts.viewType === ViewType.Account || opts.viewType === ViewType.Index;
  },
  inputProps: {
    className: 'searchbox',
    label:
      'Search for champs by part of their name, roles, ability descriptions, status/effect types, rarity, type, aura, affinity, faction, or typeId...',
    variant: 'outlined',
  },
  filter(query, { type }: ChampionItem) {
    const itemTypeId = String(type.typeId - (type.typeId % 10));
    const allSkillTypeIds = type.forms?.flatMap((form) => form.skillTypeIds) || [];
    return query.split(' ').every((keyword) => {
      function filterString(value: string | undefined) {
        if (typeof value === 'string') {
          return value.toLocaleLowerCase().includes(keyword.toLocaleLowerCase());
        } else {
          return false;
        }
      }
      return [
        type.name,
        type.affinity,
        type.faction,
        type.rarity,
        type.role,
        ...(type.indexed.damageBasedOn || []),
        ...allSkillTypeIds.flatMap((skillId) => [
          isPhrase(skills[skillId]?.description, query)
            ? skills[skillId]?.description
            : filterString(skills[skillId]?.description),
          skills[skillId]?.name,
          ...(skills[skillId]?.effects?.map((effect: any) => StatusEffectTypeId[(effect as any)?.typeId]) || []),
        ]),
        type.aura?.area,
        type.aura?.statKind,
        String(itemTypeId),
      ].some((value: string | boolean | undefined): value is string => isString(value) && filterString(value));
    });
  },
});

/* ------- My Data Filters ------- */

// RankFilter
export const RankFilter = createObservableSelectFilter<ChampionItem, ChampionInstance>(
  'rank',
  'Rank',
  '75px',
  'rank',
  getRanks(),
  {
    canHandle: (opts) => opts.viewType === ViewType.Account,
    filter: (keys, item) => keys.length === 0 || keys.includes(item?.instance?.rank ?? ''),
  }
);

// MasteriesFilter
export const MasteriesFilter = createObservableSelectFilter<ChampionItem, ChampionInstance>(
  'masteries',
  'Masteries',
  '104px',
  'masteries',
  getMasteries(),
  {
    canHandle: (opts) => opts.viewType === ViewType.Account,
    filter: (keys, item) =>
      keys.length === 0 || keys.every((key) => item?.instance?.masteries?.includes(parseInt(key)) ?? false),
  },
  { autocomplete: true }
);

// TagFilter
export const TagFilter = createObservableSelectFilter<ChampionItem, ChampionInstance>(
  'tag',
  'Tag',
  '55px',
  'tag',
  getTags(),
  {
    canHandle: (opts) => opts.viewType === ViewType.Account,
    filter: (keys, item) =>
      keys.length === 0 ||
      keys.includes(item?.instance?.marker?.toLocaleLowerCase() ?? '') ||
      keys.includes(item?.instance?.marker ?? ''),
  },
  { iconOnly: true }
);

// BlessingFilter
export const BlessingFilter = createObservableSelectFilter<ChampionItem, ChampionInstance>(
  'blessing',
  'Blessing',
  '100px',
  'blessing',
  getBlessings(),
  {
    canHandle: (opts) => opts.viewType === ViewType.Account,
    filter: (keys, item) => {
      let returnChamp = false;
      if (
        item?.instance?.blessing &&
        (keys.includes(item.instance.blessing.toLocaleLowerCase()) || keys.includes(item.instance.blessing))
      ) {
        returnChamp = true;
      }
      return keys.length === 0 || returnChamp;
    },
  },
  { autocomplete: true }
);

function isArtifactSetKindId(value: string): value is ArtifactSetKindId {
  return Object.values(ArtifactSetKindId).includes(value as ArtifactSetKindId);
}

const artifactSets = getArtifactSets();
export const EquippedSetsFilter = new ObservableSelectFilter<ChampionItem, ChampionInstance>({
  items: artifactSets,
  id: 'equippedSets',
  label: 'Artifact Sets',
  minWidth: '125px',
  autocomplete: true,
  canHandle(opts: ViewOptions) {
    return opts.viewType === ViewType.Account;
  },
  filter(keys, item) {
    const rootStore = getGlobalRootStore();
    const account = rootStore.accounts.selectedAccount;
    if (!account) return false;
    if (keys.length === 0) return true;

    const artifactIds = Object.values(item?.instance.equippedArtifactIds);
    const artifactItems = artifactIds.map((id) => account.artifacts[id]);
    for (const key of keys) {
      if (!isArtifactSetKindId(key)) continue;
      const artifactSet: OrderedDisplayMetadata = artifactSets[key as ArtifactSetKindId];
      const count = artifactItems.filter(
        (item) => item?.setKindId && item.setKindId.toLocaleLowerCase() === artifactSet.exportedKey?.toLocaleLowerCase()
      ).length;
      const requiredCount = artifactSet?.set_size;
      if (requiredCount && count < requiredCount) return false;
    }
    // all sets passed check
    return true;
  },
});

/* ----- Toggle Filters ----- */

/* Maxed? */
const MaxedToggleFilterOptions: Record<string, DisplayMetadata> = {
  no: {
    color: 'red',
    name: 'No',
  },
  off: {
    color: '',
    name: 'Maxed?',
  },
  yes: {
    color: 'lime',
    name: 'Yes',
  },
};

export const MaxedToggleFilter = new ObservableToggleFilter<ChampionItem, ChampionInstance>({
  items: MaxedToggleFilterOptions,
  defaultValue: 'off',
  id: 'maxed',
  canHandle(opts: ViewOptions) {
    return opts.viewType === ViewType.Account;
  },
  filter(value, item) {
    const rankNumber = getRankNumber(item?.instance.rank);
    switch (value) {
      case 'no':
        return rankNumber < 6 || item?.instance.level < 60 || item.type.typeId % 10 < 6 || item.type.ascended < 6;
      case 'yes':
        return (
          rankNumber === 6 && item?.instance.level === 60 && item.type.typeId % 10 === 6 && item.type.ascended === 6
        );
      case 'off':
        return item;
    }
    return true;
  },
});

/* Ready To Rank Up? */
const ReadyToRankUpToggleFilterOptions: Record<string, DisplayMetadata> = {
  no: {
    color: 'red',
    name: 'No',
  },
  off: {
    color: '',
    name: 'Ready To Rank Up?',
  },
  yes: {
    color: 'lime',
    name: 'Yes',
  },
};

export const ReadyToRankUpToggleFilter = new ObservableToggleFilter<ChampionItem, ChampionInstance>({
  items: ReadyToRankUpToggleFilterOptions,
  defaultValue: 'off',
  id: 'ready',
  canHandle(opts: ViewOptions) {
    return opts.viewType === ViewType.Account;
  },
  filter(value, item) {
    const rankNumber = getRankNumber(item?.instance.rank);
    switch (value) {
      case 'no':
        return item?.instance.level < rankNumber * 10;
      case 'yes':
        return rankNumber < 6 && item?.instance.level === rankNumber * 10;
      case 'off':
        return item;
    }
    return true;
  },
});

/* Locked? */
const LockedToggleFilterOptions: Record<string, DisplayMetadata> = {
  no: {
    color: 'red',
    name: 'No',
  },
  off: {
    color: '',
    name: 'Locked?',
  },
  yes: {
    color: 'lime',
    name: 'Yes',
  },
};

export const LockedToggleFilter = new ObservableToggleFilter<ChampionItem, ChampionInstance>({
  items: LockedToggleFilterOptions,
  defaultValue: 'off',
  id: 'locked',
  canHandle(opts: ViewOptions) {
    return opts.viewType === ViewType.Account;
  },
  filter(value, item) {
    switch (value) {
      case 'no':
        return !item?.instance.locked;
      case 'yes':
        return item?.instance.locked;
      case 'off':
        return item;
    }
    return true;
  },
});

/* In Vault? */
const InVaultToggleFilterOptions: Record<string, DisplayMetadata> = {
  no: {
    color: 'red',
    name: 'No',
  },
  off: {
    color: '',
    name: 'In Vault?',
  },
  yes: {
    color: 'lime',
    name: 'Yes',
  },
};

export const InVaultToggleFilter = new ObservableToggleFilter<ChampionItem, ChampionInstance>({
  items: InVaultToggleFilterOptions,
  defaultValue: 'off',
  id: 'inVault',
  canHandle(opts: ViewOptions) {
    return opts.viewType === ViewType.Account;
  },
  filter(value, item) {
    switch (value) {
      case 'no':
        return !item?.instance.inVault && !item?.instance.inDeepVault;
      case 'yes':
        return item?.instance.inVault || item?.instance.inDeepVault;
      case 'off':
        return item;
    }
    return true;
  },
});

/* In Master Vault? */
const InMasterVaultToggleFilterOptions: Record<string, DisplayMetadata> = {
  no: {
    color: 'red',
    name: 'No',
  },
  off: {
    color: '',
    name: 'In Master Vault?',
  },
  yes: {
    color: 'lime',
    name: 'Yes',
  },
};

export const InMasterVaultToggleFilter = new ObservableToggleFilter<ChampionItem, ChampionInstance>({
  items: InMasterVaultToggleFilterOptions,
  defaultValue: 'off',
  id: 'inMasterVault',
  canHandle(opts: ViewOptions) {
    return opts.viewType === ViewType.Account;
  },
  filter(value, item) {
    switch (value) {
      case 'no':
        return !item?.instance.inVault;
      case 'yes':
        return item?.instance.inVault;
      case 'off':
        return item;
    }
    return true;
  },
});

/* In Reserve Vault? */
const InReserveVaultToggleFilterOptions: Record<string, DisplayMetadata> = {
  no: {
    color: 'red',
    name: 'No',
  },
  off: {
    color: '',
    name: 'In Reserve Vault?',
  },
  yes: {
    color: 'lime',
    name: 'Yes',
  },
};

export const InReserveVaultToggleFilter = new ObservableToggleFilter<ChampionItem, ChampionInstance>({
  items: InReserveVaultToggleFilterOptions,
  defaultValue: 'off',
  id: 'inReserveVault',
  canHandle(opts: ViewOptions) {
    return opts.viewType === ViewType.Account;
  },
  filter(value, item) {
    switch (value) {
      case 'no':
        return !item?.instance.inDeepVault;
      case 'yes':
        return item?.instance.inDeepVault;
      case 'off':
        return item;
    }
    return true;
  },
});

/* Masteries Toggle Filter */
const MasteriesToggleFilterOptions: Record<string, DisplayMetadata> = {
  none: {
    color: 'red',
    name: 'None',
  },
  uncomplete: {
    color: 'yellow',
    name: 'Uncomplete',
  },
  off: {
    color: '',
    name: 'Masteries',
  },
  oneOrMore: {
    color: 'yellow',
    name: 'One or More',
  },
  all: {
    color: 'lime',
    name: 'All',
  },
};

export const MasteriesToggleFilter = new ObservableToggleFilter<ChampionItem, ChampionInstance>({
  items: MasteriesToggleFilterOptions,
  defaultValue: 'off',
  id: 'masteries',
  canHandle(opts: ViewOptions) {
    return opts.viewType === ViewType.Account;
  },
  filter(value, item) {
    switch (value) {
      case 'all':
        return item?.instance.masteries.length === 15;
      case 'oneOrMore':
        return item?.instance.masteries.length >= 1;
      case 'uncomplete':
        return item?.instance.masteries.length >= 1 && item?.instance.masteries.length < 15;
      case 'none':
        return item?.instance.masteries.length === 0;
      case 'off':
        return item;
    }
    return true;
  },
});

/* Mastery Scrolls Toggle Filter */
const MasteryScrollsToggleFilterOptions: Record<string, DisplayMetadata> = {
  none: {
    color: 'red',
    name: 'None',
  },
  unused: {
    color: 'yellow',
    name: 'Unused',
  },
  off: {
    color: '',
    name: 'Mastery Scrolls',
  },
  partial: {
    color: 'yellow',
    name: 'Partial',
  },
  all: {
    color: 'lime',
    name: 'All',
  },
};

export const MasteryScrollsToggleFilter = new ObservableToggleFilter<ChampionItem, ChampionInstance>({
  items: MasteryScrollsToggleFilterOptions,
  defaultValue: 'off',
  id: 'masteryScrolls',
  canHandle(opts: ViewOptions) {
    return opts.viewType === ViewType.Account;
  },
  filter(value, item) {
    switch (value) {
      case 'all':
        return (
          item?.instance.totalMasteryScrolls.Bronze === 100 &&
          item?.instance.totalMasteryScrolls.Silver === 600 &&
          item?.instance.totalMasteryScrolls.Gold === 950
        );
      case 'partial':
        return (
          (item?.instance.totalMasteryScrolls.Bronze > 0 || item?.instance.totalMasteryScrolls.Silver > 0) &&
          item?.instance.totalMasteryScrolls.Gold < 950
        );
      case 'none':
        return !item?.instance.totalMasteryScrolls.Bronze;
      case 'unused':
        return (
          item?.instance.totalMasteryScrolls.Bronze === 100 &&
          item?.instance.totalMasteryScrolls.Silver === 600 &&
          item?.instance.totalMasteryScrolls.Gold === 950 &&
          item?.instance.masteries.length < 15
        );
      case 'off':
        return item;
    }
    return true;
  },
});

/* Geared Toggle Filter */
const GearedToggleFilterOptions: Record<string, DisplayMetadata> = {
  not: {
    color: 'red',
    name: 'Not',
  },
  partially: {
    color: 'yellow',
    name: 'Partially',
  },
  fully: {
    color: 'lime',
    name: 'Fully',
  },
  off: {
    color: '',
    name: 'Geared',
  },
};

export const GearedToggleFilter = new ObservableToggleFilter<ChampionItem, ChampionInstance>({
  items: GearedToggleFilterOptions,
  defaultValue: 'off',
  id: 'geared',
  canHandle(opts: ViewOptions) {
    return opts.viewType === ViewType.Account;
  },
  filter(value, item) {
    const equippedArtifactIdsArray = Object.entries(item?.instance?.equippedArtifactIds);
    switch (value) {
      case 'fully':
        return (
          (equippedArtifactIdsArray.length === 9 && item.type.ascended === 6) ||
          (equippedArtifactIdsArray.length === 8 && item.type.ascended === 5) ||
          (equippedArtifactIdsArray.length === 7 && item.type.ascended < 5)
        );
      case 'partially':
        return (
          !!equippedArtifactIdsArray &&
          ((equippedArtifactIdsArray.length > 0 && equippedArtifactIdsArray.length < 9 && item.type.ascended === 6) ||
            (equippedArtifactIdsArray.length > 0 && equippedArtifactIdsArray.length < 8 && item.type.ascended >= 5) ||
            (equippedArtifactIdsArray.length > 0 &&
              equippedArtifactIdsArray.length < 7 &&
              getRankNumber(item?.instance.rank) >= 4) ||
            (equippedArtifactIdsArray.length > 0 && equippedArtifactIdsArray.length < 6))
        );
      case 'not':
        return equippedArtifactIdsArray.length === 0;
      case 'off':
        return item;
    }
    return true;
  },
});

/* BookedAbilities Toggle Filter */
const BookedAbilitiesToggleFilterOptions: Record<string, DisplayMetadata> = {
  no: {
    color: 'red',
    name: 'No',
  },
  partially: {
    color: 'yellow',
    name: 'Partially',
  },
  fully: {
    color: 'lime',
    name: 'Fully',
  },
  bookedAbilities: {
    color: '',
    name: 'Booked Abilities',
  },
};

export const BookedAbilitiesToggleFilter = new ObservableToggleFilter<ChampionItem, ChampionInstance>({
  items: BookedAbilitiesToggleFilterOptions,
  defaultValue: 'bookedAbilities',
  id: 'bookedAbilities',
  canHandle(opts: ViewOptions) {
    return opts.viewType === ViewType.Account;
  },
  filter(value, item) {
    const skillIdsToUse =
      item.type.forms && item.type.forms.length > 0
        ? item.type.forms.flatMap((form) => form.skillTypeIds || [])
        : item.type.skillIds || [];

    let howManySkillsAreFullyBooked: number = 0;
    let howManySkillsHaveSomeUpgrades: number = 0;

    skillIdsToUse.forEach((skillId) => {
      const skillLevelsByTypeId = item?.instance?.skillLevelsByTypeId[skillId] - 1; // Subtracting 1 to not count the default level
      const skill = skills[skillId];
      const availableUpgrades = skill?.skillBonuses?.length || 0;

      if (availableUpgrades > 0) {
        if (skillLevelsByTypeId && skillLevelsByTypeId >= availableUpgrades) {
          howManySkillsAreFullyBooked += 1;
        } else if (skillLevelsByTypeId && skillLevelsByTypeId > 0) {
          howManySkillsHaveSomeUpgrades += 1;
        }
      }
    });

    switch (value) {
      case 'fully':
        return howManySkillsAreFullyBooked === skillIdsToUse.length;
      case 'partially':
        return howManySkillsHaveSomeUpgrades > 0 && howManySkillsAreFullyBooked < skillIdsToUse.length;
      case 'no':
        return howManySkillsAreFullyBooked === 0 && howManySkillsHaveSomeUpgrades === 0;
    }

    return true;
  },
});

const DupesToggleFilterOptions: Record<string, DisplayMetadata> = {
  no: {
    color: 'red',
    name: 'No',
  },
  onePlus: {
    color: 'yellow',
    name: 'One+',
  },
  twoPlus: {
    color: 'lime',
    name: 'Two+',
  },
  threePlus: {
    color: 'lime',
    name: 'Three+',
  },
  fourPlus: {
    color: 'lime',
    name: 'Four+',
  },
  dupes: {
    color: '',
    name: 'Dupes',
  },
};

export const DupesToggleFilter = new ObservableToggleFilter<ChampionItem, ChampionInstance>({
  items: DupesToggleFilterOptions,
  defaultValue: 'dupes',
  id: 'dupes',
  canHandle(opts: ViewOptions) {
    return opts.viewType === ViewType.Account;
  },
  filter(value, item: ChampionItem) {
    let champCounts: Record<string, number> = {};

    if (localStorage.getItem('champCounts') !== null) {
      champCounts = JSON.parse(localStorage.getItem('champCounts') ?? '{}');
    }

    //console.log(`champCounts in DupesToggleFilter`, champCounts);

    let champName = item.type.name;
    if (!champName) return false;

    let v = champCounts[champName] || 0;
    let champsWithoutDupes = [];
    let champsWithDupes = [];
    let champsWithTwoOrMoreDupes = [];
    let champsWithThreeOrMoreDupes = [];
    let champsWithFourOrMoreDupes = [];

    if (v === 1) {
      champsWithoutDupes.push(champName);
    }
    if (v >= 1) {
      champsWithDupes.push(champName);
    }
    if (v >= 2) {
      champsWithTwoOrMoreDupes.push(champName);
    }
    if (v >= 3) {
      champsWithThreeOrMoreDupes.push(champName);
    }
    if (v >= 4) {
      champsWithFourOrMoreDupes.push(champName);
    }

    switch (value) {
      case 'no':
        return champsWithoutDupes.includes(champName);
      case 'onePlus':
        return champsWithDupes.includes(champName);
      case 'twoPlus':
        return champsWithTwoOrMoreDupes.includes(champName);
      case 'threePlus':
        return champsWithThreeOrMoreDupes.includes(champName);
      case 'fourPlus':
        return champsWithFourOrMoreDupes.includes(champName);
      default:
        return true;
    }
  },
});

/* FactionGuardian? */
const FactionGuardianToggleFilterOptions: Record<string, DisplayMetadata> = {
  no: {
    color: 'red',
    name: 'No',
  },
  factionGuardian: {
    color: '',
    name: 'Faction Guardian?',
  },
  yes: {
    color: 'lime',
    name: 'Yes',
  },
};

export const FactionGuardianToggleFilter = new ObservableToggleFilter<ChampionItem, ChampionInstance>({
  items: FactionGuardianToggleFilterOptions,
  defaultValue: 'factionGuardian',
  id: 'factionGuardian',
  canHandle(opts: ViewOptions) {
    return opts.viewType === ViewType.Account;
  },
  filter(value: string, item, store: RootStore) {
    const keysOfAllFactionGuardians = getFactionGuardians(store.accounts.selectedAccount?.guardians);
    switch (value) {
      case 'no':
        return !keysOfAllFactionGuardians?.includes(item?.instance.id);
      case 'yes':
        return keysOfAllFactionGuardians?.includes(item?.instance.id);
    }
    return true;
  },
});

/* TODO: Not working yet */
const PotentialFactionGuardianToggleFilterOptions: Record<string, DisplayMetadata> = {
  no: {
    color: 'red',
    name: 'No',
  },
  potentialFactionGuardian: {
    color: '',
    name: 'Potential Faction Guardian?',
  },
  yes: {
    color: 'lime',
    name: 'Yes',
  },
};

export const PotentialFactionGuardianToggleFilter = new ObservableToggleFilter<ChampionItem, ChampionInstance>({
  items: PotentialFactionGuardianToggleFilterOptions,
  defaultValue: 'potentialFactionGuardian',
  id: 'potentialFactionGuardian',
  canHandle(opts: ViewOptions) {
    return opts.viewType === ViewType.Account;
  },
  filter(
    value: string,
    item: ChampionInstance,
    store: { accounts: { selectedAccount: { champions: any } } } | undefined
  ) {
    const isPotentialFactionGuardian = getPotentialFactionGuardians(item, store);

    switch (value) {
      case 'no':
        return !isPotentialFactionGuardian;
      case 'yes':
        return isPotentialFactionGuardian;
    }
    return true;
  },
});

/* Range Slider Filters */
export const LevelFilter = new ObservableRangeFilter<ChampionItem>({
  id: 'level',
  label: 'Level',
  min: 1,
  max: 60,
  minWidth: 180,
  canHandle(opts: ViewOptions) {
    return opts.viewType === ViewType.Account;
  },
  filter(min, max, item) {
    return !item?.instance || (item?.instance.level >= min && item?.instance.level <= max);
  },
});

export const AscendLevelFilter = new ObservableRangeFilter<ChampionItem>({
  id: 'ascendLevel',
  label: 'Ascend Level',
  min: 0,
  max: 6,
  minWidth: 40,
  canHandle(opts: ViewOptions) {
    return opts.viewType === ViewType.Account;
  },
  filter(min, max, item) {
    return !item.type || (item.type.ascended >= min && item.type.ascended <= max);
  },
});

export const EmpowerLevelFilter = new ObservableRangeFilter<ChampionItem>({
  id: 'empowerLevel',
  label: 'Empower Level',
  min: 0,
  max: 4,
  minWidth: 40,
  canHandle(opts: ViewOptions) {
    return opts.viewType === ViewType.Account;
  },
  filter(min, max, item) {
    return !item?.instance || (item?.instance.empowerLevel >= min && item?.instance.empowerLevel <= max);
  },
});

export const AwakenRankFilter = new ObservableRangeFilter<ChampionItem>({
  id: 'awakenRank',
  label: 'Awaken Rank',
  min: 0,
  max: 6,
  minWidth: 40,
  canHandle(opts: ViewOptions) {
    return opts.viewType === ViewType.Account;
  },
  filter(min, max, item) {
    return (
      !item?.instance ||
      (getAwakenRankNumber(item?.instance.awakenRank) >= min && getAwakenRankNumber(item?.instance.awakenRank) <= max)
    );
  },
});

/* Works but not useful considering only 4 champs appear to have unblockable status effects */
export const UnblockableStatusEffectMultiSelectFilter = new ObservableSelectFilter<ChampionItem>({
  items: getStatusEffects(),
  id: 'unblockableStatusEffectMultiSelect',
  label: 'Unblockable Status Effects',
  minWidth: '140px',
  single: false,

  canHandle(opts: ViewOptions) {
    return opts.viewType === ViewType.Account || opts.viewType === ViewType.Index;
  },
  filter(keys, item) {
    let returnChamp = false;
    let tags;
    if (item.type.indexed.tags) {
      tags = item.type.indexed.tags;

      tags.forEach((tag) => {
        if (keys.includes(String(tag.statusEffect)) && tag.unblockable === true) {
          returnChamp = true;
        }
      });
    }
    return keys.length === 0 || returnChamp;
  },
});

//Where all the logic is handle, the other Advanced filters only function is to add the selected info to the query params for this one to read.
function championA1Matches(
  item: { type: { skillIds: any[]; forms?: { skillTypeIds: any[] }[] } },
  selectedStatusEffect: any,
  selectedTarget: string,
  durationRange: { min: number; max: number },
  cooldownRange: { min: number; max: number }
) {
  // Initialize an empty array to collect all skillTypeIds from each form
  let allSkillIds: any[] = [];

  // Collect all skillTypeIds from each form into allSkillIds
  if (item.type.forms) {
    item.type.forms.forEach((form) => {
      if (form.skillTypeIds) {
        allSkillIds = [...allSkillIds, ...form.skillTypeIds];
      }
    });
  }

  // Use the first skillTypeIds for A1
  const skillId = allSkillIds.length > 0 ? allSkillIds[0] : item.type.skillIds[0];
  const abilityData = skills[skillId];

  if (!abilityData?.effects) return false;

  const effects = abilityData.effects;

  for (const effect of effects) {
    const thisStatusEffectTypeId = effect?.applyStatusEffectParams?.statusEffectInfos[0]?.typeId;

    if (Number(selectedStatusEffect) !== 0 && thisStatusEffectTypeId !== Number(selectedStatusEffect)) {
      continue;
    }

    const targetTypeCheck = (() => {
      if (selectedTarget === 'off') return true;

      const targetType = effect.targetParams?.targetType;

      if (targetType === 2) {
        const relatedTargetType = getRelationTargetType(effect, effects, selectedTarget);

        return String(relatedTargetType) === selectedTarget;
      }

      if (selectedTarget === '7') {
        return targetType === 7 || targetType === 9;
      }

      return String(targetType) === selectedTarget;
    })();

    if (!targetTypeCheck) continue;

    const durationCheck =
      effect.applyStatusEffectParams?.statusEffectInfos[0]?.duration === undefined ||
      effect.applyStatusEffectParams?.statusEffectInfos[0]?.duration === null ||
      (effect.applyStatusEffectParams?.statusEffectInfos[0]?.duration >= durationRange.min &&
        effect.applyStatusEffectParams?.statusEffectInfos[0]?.duration <= durationRange.max);

    const cooldownCheck =
      !abilityData.cooldown || (abilityData.cooldown >= cooldownRange.min && abilityData.cooldown <= cooldownRange.max);

    return durationCheck && cooldownCheck;
  }

  return false;
}

// Helper functions to extract parameters from URL
function extractA1ToggleState() {
  const regex = /(?:\?|&)advancedA1=(no|yes|off)/;
  const match = (window.location.search as string).match(regex);
  return match ? match[1] : 'off';
}

function extractTargetType() {
  const regex = /(?:\?|&)advancedTargetType=(\d+)/;
  const match = (window.location.search as string).match(regex);
  return match ? match[1] : 'off';
}

function extractDurationRange() {
  const regex = /(?:\?|&)advancedDuration=(\d)-(\d)/;
  const match = (window.location.search as string).match(regex);
  if (match) {
    return { min: parseInt(match[1] as string), max: parseInt(match[2] as string) };
  } else {
    return { min: 0, max: 6 };
  }
}

function extractCooldownRange() {
  const regex = /(?:\?|&)advancedCooldown=(\d)-(\d)/;
  const match = (window.location.search as string).match(regex);
  if (match) {
    return { min: parseInt(match[1] as string), max: parseInt(match[2] as string) };
  } else {
    return { min: 0, max: 6 };
  }
}

function extractFullyBookedCooldownRange() {
  const regex = /(?:\?|&)advancedFullyBookedCooldown=(\d)-(\d)/;
  const match = (window.location.search as string).match(regex);
  if (match) {
    return { min: parseInt(match[1] as string), max: parseInt(match[2] as string) };
  } else {
    return { min: 0, max: 6 };
  }
}

function extractMyCooldownRange() {
  const regex = /(?:\?|&)advancedMyCooldown=(\d+)-(\d+)/;
  const match = (window.location.search as string).match(regex);
  if (match) {
    return { min: parseInt(match[1] as string), max: parseInt(match[2] as string) };
  } else {
    return { min: 0, max: 6 };
  }
}

function extractHitsRange() {
  const regex = /(?:\?|&)advancedHits=(\d)-(\d)/;
  const match = (window.location.search as string).match(regex);
  if (match) {
    return { min: parseInt(match[1] as string), max: parseInt(match[2] as string) };
  } else {
    return { min: 1, max: 6 }; // Change this default range if needed
  }
}

function extractChanceToPlacePercentRange() {
  const regex = /(?:\?|&)advancedChanceToPlacePercent=(\d+)-(\d+)/;
  const match = (window.location.search as string).match(regex);
  if (match) {
    return { min: parseInt(match[1] as string), max: parseInt(match[2] as string) };
  } else {
    return { min: 0, max: 100 }; // Change this default range if needed
  }
}

function extractFullyBookedChanceToPlacePercentRange() {
  const regex = /(?:\?|&)advancedFullyBookedChanceToPlacePercent=(\d+)-(\d+)/;
  const match = (window.location.search as string).match(regex);
  if (match) {
    return { min: parseInt(match[1] as string), max: parseInt(match[2] as string) };
  } else {
    return { min: 0, max: 100 }; // Change this default range if needed
  }
}

function extractMyChanceToPlacePercentRange() {
  const regex = /(?:\?|&)advancedMyChanceToPlacePercent=(\d+)-(\d+)/;
  const match = (window.location.search as string).match(regex);
  if (match) {
    return { min: parseInt(match[1] as string), max: parseInt(match[2] as string) };
  } else {
    return { min: 0, max: 100 }; // Change this default range if needed
  }
}

function calculateFullyBookedChance(skill: any): number {
  let totalEffectChanceIncrease = 0;

  if (skill.upgrades) {
    for (const upgrade of skill.upgrades) {
      if (upgrade.type === 'EffectChance') {
        totalEffectChanceIncrease += upgrade.value;
      }
    }
  }

  return Math.min(Math.round(totalEffectChanceIncrease * 100), 100);
}

function calculateFullyBookedCooldown(skill: AbilityType) {
  const baseCooldown = skill.cooldown;
  const upgrades = skill.upgrades;

  let cooldownReduction = 0;
  for (const upgrade of upgrades) {
    if (upgrade.type === 'CooltimeTurn') {
      cooldownReduction += upgrade.value;
    }
  }

  const finalCooldown = baseCooldown - cooldownReduction;

  return finalCooldown;
}

function calculateMyCooldown(skill: AbilityType, instance: ChampionInstanceData | undefined) {
  const baseCooldown = skill.cooldown;
  const upgrades = skill.upgrades;

  let amountCooldownDecreasedBasedOnCurrentLevel = 0;
  let cooldownBasedOnCurrentLevel;

  if (instance && instance.typeId) {
    const skillIndex = Number(String(skill.typeId).slice(-1));
    const heroTypeId = Math.floor(instance.typeId / 10) * 10;
    const level = instance.skillLevelsByTypeId[Number(heroTypeId + skillIndex)];

    for (var i = 0; level && i < level; i++) {
      if (skill && skill.upgrades && skill.upgrades[i] && skill.upgrades[i]?.type === 'CooltimeTurn') {
        amountCooldownDecreasedBasedOnCurrentLevel = amountCooldownDecreasedBasedOnCurrentLevel + 1;
      }
    }
  }

  cooldownBasedOnCurrentLevel = skill.cooldown - amountCooldownDecreasedBasedOnCurrentLevel;

  let cooldownReduction = 0;
  for (const upgrade of upgrades) {
    if (upgrade.type === 'CooltimeTurn') {
      cooldownReduction += upgrade.value;
    }
  }

  const finalCooldown = cooldownBasedOnCurrentLevel - cooldownReduction;

  /* console.log(
    `${skill.name} - Base cooldown: ${baseCooldown}, Reduction: ${cooldownReduction}, Final cooldown: ${finalCooldown}`
  ); */

  return finalCooldown;
}

function calculateMyChance(
  skill: AbilityType,
  instance: ChampionInstanceData | undefined,
  selectedStatusEffect: number,
  selectedEffectKind: number
) {
  let baseChance = 0;

  const effectToCheck = selectedStatusEffect || selectedEffectKind;

  let statusEffects;
  if (skill.effects) {
    statusEffects = returnEffectsWithApplyStatusEffectParams(skill.effects) || [];

    statusEffects.forEach((statusEffect) => {
      if (statusEffect?.applyStatusEffectParams.statusEffectInfos) {
        const statusEffectInfos = statusEffect?.applyStatusEffectParams.statusEffectInfos;
        statusEffectInfos.forEach((statusEffectInfo: { typeId: any }) => {
          if (statusEffectInfo.typeId && statusEffectInfo.typeId == effectToCheck) {
            baseChance = statusEffect.chance;
          }
        });
      }
    });
  }

  /* console.log(`Base chance for ${skill.name} on ${instance?.typeId}: ${baseChance}`); */

  let amountChanceIncreasedBasedOnCurrentLevel = 0;
  let chanceBasedOnCurrentLevel;

  if (instance && skill.typeId !== undefined) {
    const level = instance.skillLevelsByTypeId[skill.typeId];

    for (var i = 0; level && i < level; i++) {
      if (skill && skill.upgrades && skill.upgrades[i] && skill.upgrades[i]?.type === 'EffectChance') {
        amountChanceIncreasedBasedOnCurrentLevel =
          amountChanceIncreasedBasedOnCurrentLevel + (skill.upgrades[i]?.value || 0);
      }
    }
  }

  /* console.log(
    `Amount chance increased based on current level for ${skill.name}: ${amountChanceIncreasedBasedOnCurrentLevel}`
  ); */

  chanceBasedOnCurrentLevel = baseChance + amountChanceIncreasedBasedOnCurrentLevel;

  //console.log(`Chance based on current level for ${skill.name}: ${chanceBasedOnCurrentLevel}`);

  const finalChance = Math.min(chanceBasedOnCurrentLevel, 1);

  //console.log(`Final chance for ${skill.name}: `, finalChance);

  let effectWithNullChance = false;
  if (skill.effects) {
    skill.effects.forEach((effect) => {
      if (effect.chance === null && selectedEffectKind !== 0) {
        effectWithNullChance = true;
      }
    });
  }

  return effectWithNullChance ? 1 : finalChance;
}

function getRelationTargetType(effect: any, effects: any[], selectedTarget: string): number | undefined {
  const relationEffectTypeId = effect?.relation?.effectTypeId;
  const relatedEffect = effects.find((e) => e.id === relationEffectTypeId);
  const targetType = relatedEffect?.targetParams?.targetType;
  const exclusion = relatedEffect?.targetParams?.exclusion;

  if (targetType === 2) {
    return getRelationTargetType(relatedEffect, effects, selectedTarget);
  }

  return targetType;
}

function hasStatusEffect(statusEffectInfos: any, selectedStatusEffect: string) {
  if (statusEffectInfos) {
    for (const statusEffectInfo of statusEffectInfos) {
      if (statusEffectInfo.typeId === Number(selectedStatusEffect)) {
        return true;
      }
    }
  }
  return false;
}

function getRelatedEffect(effect: any, effects: any[], selectedTarget: string) {
  const relationId = effect.relation?.effectTypeId;

  if (!relationId) {
    return null;
  }

  for (const e of effects) {
    if (e.id === relationId) {
      return e;
    }
  }

  return null;
}

function checkStatusEffect(
  item: ChampionItem,
  effect: {
    id?: number;
    kindId?: EffectKindId;
    typeId?: StatusEffectTypeId;
    chance?: number | undefined;
    duration?: number;
    cooldown?: number;
    count?: number;
    stack?: number;
    targetParams: any;
    applyStatusEffectParams: any;
    group?: EffectGroup | undefined;
    relation?: EffectRelation | undefined;
  },
  abilityData: AbilityType,
  selectedStatusEffect: string,
  effects: any[],
  thisChampHasBlockRevive?: boolean,
  thisChampHasTrueFear?: boolean,
  thisChampHasBigPoison?: boolean,
  a1ToggleState?: string
) {
  const statusEffectInfos = effect?.applyStatusEffectParams?.statusEffectInfos;

  const selectedTarget = extractTargetType();
  const hitsRange = extractHitsRange();
  const durationRange = extractDurationRange();
  const cooldownRange = extractCooldownRange();
  const fullyBookedCooldownRange = extractFullyBookedCooldownRange();
  const myCooldownRange = extractMyCooldownRange();
  const chanceToPlacePercentRange = extractChanceToPlacePercentRange();
  const fullyBookedChanceToPlacePercentRange = extractFullyBookedChanceToPlacePercentRange();
  const myChanceToPlacePercentRange = extractMyChanceToPlacePercentRange();

  if (Number(selectedStatusEffect) !== 0) {
    let foundMatchingEffect = false;

    if (thisChampHasBlockRevive && selectedStatusEffect === '360') {
      foundMatchingEffect = true;
    } else if (thisChampHasTrueFear && selectedStatusEffect === '491') {
      foundMatchingEffect = true;
    } else if (thisChampHasBigPoison && selectedStatusEffect === '80') {
      foundMatchingEffect = true;
    } else {
      foundMatchingEffect = hasStatusEffect(statusEffectInfos, selectedStatusEffect);
    }

    if (!foundMatchingEffect) {
      return false;
    }
  }

  const targetTypeCheck = (() => {
    if (selectedTarget === 'off') return true;

    const targetType = effect.targetParams?.targetType;
    let relatedTargetType;
    if (selectedTarget) {
      relatedTargetType = targetType === 2 ? getRelationTargetType(effect, effects, selectedTarget) : null;
    }

    const checkTargetType = (type: number | null | undefined) => {
      switch (selectedTarget) {
        case '0':
          return (
            type === 0 ||
            type === 19 ||
            type === 20 ||
            type === 22 ||
            type === 32 ||
            type === 33 ||
            type === 34 ||
            type === 35 ||
            type === 36 ||
            type === 37 ||
            type === 38 ||
            type === 39 ||
            type === 40
          );
        case '1':
          return type === 1 || type === 4 || type === 31;
        case '5':
          return type === 5 || type === 6 || type === 13 || type === 14 || type === 25;
        case '7':
          return type === 7 || type === 8 || type === 9 || type === 26 || type === 29;
        default:
          return String(type) === selectedTarget;
      }
    };

    return targetType === 2 ? checkTargetType(relatedTargetType) : checkTargetType(targetType);
  })();

  const hitsCheck = (() => {
    const hitCount = effect.count;

    const targetType = effect.targetParams?.targetType;

    //console.log('targetType:', targetType);

    let relatedEffect;
    if (targetType === 2 && selectedTarget) {
      relatedEffect = getRelatedEffect(effect, effects, selectedTarget);
      //console.log('relatedEffect:', relatedEffect);
    }

    // Consider effect.stack of related effect
    const relatedHitCount = relatedEffect?.count || hitCount;

    //console.log('hitCount:', hitCount);
    //console.log('hitsRange:', hitsRange);

    if (relatedHitCount) {
      return relatedHitCount >= hitsRange.min && relatedHitCount <= hitsRange.max;
    } else {
      return;
    }
  })();

  const chanceToPlacePercentCheck = (() => {
    const placeChance = effect.chance;

    const targetType = effect.targetParams?.targetType;

    let relatedEffect;
    if (targetType === 2 && selectedTarget) {
      relatedEffect = getRelatedEffect(effect, effects, selectedTarget);
      //console.log('relatedEffect:', relatedEffect);
    }

    // Consider effect.stack of related effect
    const relatedPlaceChance = relatedEffect?.chance || placeChance;

    // Convert relatedPlaceChance to a percentage
    const relatedPlaceChancePercentage = relatedPlaceChance * 100;

    //console.log('relatedPlaceChancePercentage:', relatedPlaceChancePercentage);
    //console.log('chanceToPlacePercentRange:', chanceToPlacePercentRange);

    const checkResult =
      relatedPlaceChancePercentage >= chanceToPlacePercentRange.min &&
      relatedPlaceChancePercentage <= chanceToPlacePercentRange.max;

    //console.log('chanceToPlacePercentCheck result:', checkResult);

    return checkResult;
  })();

  const fullyBookedChanceToPlacePercentCheck = (() => {
    const placeChance = effect.chance;

    const targetType = effect.targetParams?.targetType;

    let relatedEffect;
    if (targetType === 2 && selectedTarget) {
      relatedEffect = getRelatedEffect(effect, effects, selectedTarget);
      //console.log('relatedEffect:', relatedEffect);
    }

    // Step 3: Consider effect.count of related effect
    const relatedPlaceChance = relatedEffect?.chance || placeChance;

    // Convert relatedPlaceChance to a percentage
    const relatedPlaceChancePercentage = relatedPlaceChance * 100;
    const fullyBookedChanceIncrease = calculateFullyBookedChance(abilityData);
    const relatedPlaceChancePercentageFullyBooked = relatedPlaceChancePercentage + fullyBookedChanceIncrease;

    //console.log('relatedPlaceChancePercentage:', relatedPlaceChancePercentage);
    //console.log('fullyBookedChanceToPlacePercentRange:', fullyBookedChanceToPlacePercentRange);

    const checkResult =
      relatedPlaceChancePercentageFullyBooked >= fullyBookedChanceToPlacePercentRange.min &&
      relatedPlaceChancePercentageFullyBooked <= fullyBookedChanceToPlacePercentRange.max;

    //console.log('fullyBookedChanceToPlacePercentCheck result:', checkResult);

    return checkResult;
  })();

  const myChanceToPlacePercentCheck = (() => {
    const myChanceFinal = calculateMyChance(abilityData, item.instance, Number(selectedStatusEffect), 0) * 100;

    //console.log('myChanceFinal:', myChanceFinal);

    const checkResult =
      myChanceFinal >= myChanceToPlacePercentRange.min && myChanceFinal <= myChanceToPlacePercentRange.max;

    //console.log('checkResult:', checkResult);

    return checkResult;
  })();

  for (let condition of specificCaseFixes) {
    if (
      (selectedTarget === condition.target || selectedTarget === undefined) &&
      selectedStatusEffect === condition.effect &&
      item.type[condition.type] === condition.name
    ) {
      return condition.shouldShow;
    }
  }

  if (!targetTypeCheck) return false;

  const durationCheck =
    effect.applyStatusEffectParams?.statusEffectInfos[0]?.duration === undefined ||
    effect.applyStatusEffectParams?.statusEffectInfos[0]?.duration === null ||
    (effect.applyStatusEffectParams?.statusEffectInfos[0]?.duration >= durationRange.min &&
      effect.applyStatusEffectParams?.statusEffectInfos[0]?.duration <= durationRange.max);

  const cooldownCheck =
    !abilityData.cooldown || (abilityData.cooldown >= cooldownRange.min && abilityData.cooldown <= cooldownRange.max);

  const fullyBookedCooldown = calculateFullyBookedCooldown(abilityData);
  const fullyBookedCooldownCheck =
    fullyBookedCooldown >= fullyBookedCooldownRange.min && fullyBookedCooldown <= fullyBookedCooldownRange.max;

  const myCooldown = calculateMyCooldown(abilityData, item.instance);
  const myCooldownCheck = myCooldown >= myCooldownRange.min && myCooldown <= myCooldownRange.max;

  if (
    a1ToggleState === 'no' &&
    selectedTarget &&
    championA1Matches(item, selectedStatusEffect, selectedTarget, durationRange, cooldownRange)
  ) {
    return false;
  }

  const result =
    durationCheck &&
    cooldownCheck &&
    myCooldownCheck &&
    fullyBookedCooldownCheck &&
    hitsCheck &&
    chanceToPlacePercentCheck &&
    fullyBookedChanceToPlacePercentCheck &&
    myChanceToPlacePercentCheck;

  return result;
}

const regexBlockRevive =
  /Enemies killed by this skill cannot be revived|killed by this Champion cannot be revived|places .*Block Revive.* on them/gim;

const regexTrueFear = /chance of placing a \[True Fear\] debuff/gim;

const regexBigPoison = /5% \[Poison\] Debuff on the attacker/gim;
export const AdvancedStatusEffectAutocompleteFilter = new ObservableSelectFilter<ChampionItem>({
  items: getStatusEffects(),
  id: 'advancedStatusEffect',
  label: 'Status Effect',
  minWidth: '144px',
  single: true,
  autocomplete: true,
  getOptionCount: (statusEffectLabel: string) => {
    // Convert statusEffectLabel to typeId
    const statusEffectTypeId = statusEffectLabel;

    // Define the filter function
    const filterFn = (champ: ChampionItem) => {
      // Initialize an empty array to collect all skillTypeIds from each form
      let allSkillIds: any[] = [];

      // Collect all skillTypeIds from each form into allSkillIds
      if (champ.type.forms) {
        champ.type.forms.forEach((form) => {
          if (form.skillTypeIds) {
            allSkillIds = [...allSkillIds, ...form.skillTypeIds];
          }
        });
      }

      let returnChamp = false;
      allSkillIds.forEach((item) => {
        const abilityData = skills[item];
        const effects = abilityData?.effects || [];
        const statusEffects = returnEffectsWithApplyStatusEffectParams(effects) || [];
        statusEffects.forEach((statusEffect) => {
          if (statusEffect?.applyStatusEffectParams.statusEffectInfos) {
            const statusEffectInfos = statusEffect?.applyStatusEffectParams.statusEffectInfos;
            statusEffectInfos.forEach((statusEffectInfo: { typeId: any }) => {
              if (statusEffectInfo.typeId && String(statusEffectInfo.typeId) === statusEffectTypeId) {
                returnChamp = true;
              }
            });
          }
        });
      });
      return returnChamp;
    };

    // Use the generic function to get the count
    return getOptionCountGeneric(statusEffectTypeId, filterFn);
  },
  canHandle(opts: ViewOptions) {
    return opts.viewType === ViewType.Account || opts.viewType === ViewType.Index;
  },
  filter(keys, item) {
    if (keys.length === 0) {
      return true;
    }
    const selectedStatusEffect = keys[0];

    // Initialize an empty array to collect all skillTypeIds from each form
    let allSkillIds: any[] = [];

    // Collect all skillTypeIds from each form into allSkillIds
    if (item.type.forms) {
      item.type.forms.forEach((form) => {
        if (form.skillTypeIds) {
          allSkillIds = [...allSkillIds, ...form.skillTypeIds];
        }
      });
    }

    for (const skillId of allSkillIds) {
      const abilityData = skills[skillId];

      const skillDescription = abilityData?.description || '';

      let thisChampHasBlockRevive = false;
      if (selectedStatusEffect === '360' && regexBlockRevive.exec(skillDescription)) {
        //console.log(`${item.type.name} has Block Revive`);
        thisChampHasBlockRevive = true;
      }

      if (selectedStatusEffect === '360' && item.type.name === 'Konstantin the Dayborn') {
        thisChampHasBlockRevive = true;
      }

      let thisChampHasTrueFear = false;
      if (selectedStatusEffect === '491' && regexTrueFear.exec(skillDescription)) {
        //console.log(`${item.type.name} has True Fear`);
        thisChampHasTrueFear = true;
      }

      let thisChampHasBigPoison = false;
      if (selectedStatusEffect === '80' && regexBigPoison.exec(skillDescription)) {
        //console.log(`${item.type.name} has Big Poison`);
        thisChampHasBigPoison = true;
      }

      if (selectedStatusEffect === '491' && item.type.name === 'Yoshi the Drunkard') {
        thisChampHasTrueFear = true;
      }

      if (selectedStatusEffect === '80' && item.type.name === 'Toragi The Frog') {
        thisChampHasBigPoison = true;
      }

      if (!abilityData?.effects) continue;

      const effects = abilityData.effects;

      const a1ToggleState = extractA1ToggleState();

      for (const effect of effects) {
        if (
          selectedStatusEffect &&
          checkStatusEffect(
            item,
            effect,
            abilityData,
            selectedStatusEffect,
            abilityData.effects,
            thisChampHasBlockRevive,
            thisChampHasTrueFear,
            thisChampHasBigPoison,
            a1ToggleState
          )
        ) {
          return true;
        }
      }

      // Check passive skills if available
      if (abilityData.passiveEffects) {
        for (const effect of abilityData.passiveEffects) {
          if (
            selectedStatusEffect &&
            checkStatusEffect(
              item,
              effect,
              abilityData,
              selectedStatusEffect,
              abilityData.effects,
              thisChampHasBlockRevive,
              thisChampHasTrueFear,
              thisChampHasBigPoison,
              a1ToggleState
            )
          ) {
            return true;
          }
        }
      }

      // If the A1 toggle is set to 'yes', we only need to check the first skill (A1)
      if (a1ToggleState === 'yes') break;
    }

    return keys.length === 0;
  },
});

function checkEffectKind(
  item: ChampionItem,
  effect: {
    id?: number;
    kindId: any;
    typeId?: StatusEffectTypeId;
    chance: any;
    duration?: number;
    cooldown?: number;
    stackCount?: number;
    targetParams: any;
    applyStatusEffectParams: any;
    group?: EffectGroup | undefined;
    relation: any;
    count?: any;
  },
  abilityData: AbilityType,
  selectedEffectKind: string | undefined,
  effects: any[],
  a1ToggleState: string
) {
  const effectKindId = effect.kindId;

  const selectedTarget = extractTargetType();
  const durationRange = extractDurationRange();
  const cooldownRange = extractCooldownRange();
  const fullyBookedCooldownRange = extractFullyBookedCooldownRange();
  const myCooldownRange = extractMyCooldownRange();
  const chanceToPlacePercentRange = extractChanceToPlacePercentRange();
  const fullyBookedChanceToPlacePercentRange = extractFullyBookedChanceToPlacePercentRange();
  const myChanceToPlacePercentRange = extractMyChanceToPlacePercentRange();

  if (Number(selectedEffectKind) !== 0 && effectKindId !== Number(selectedEffectKind)) {
    return false;
  }

  const targetTypeCheck = (() => {
    if (selectedTarget === 'off') return true;

    const targetType = effect.targetParams?.targetType;
    let relatedTargetType;
    if (selectedTarget) {
      relatedTargetType = targetType === 2 ? getRelationTargetType(effect, effects, selectedTarget) : null;
    }

    const checkTargetType = (type: number | null | undefined) => {
      switch (selectedTarget) {
        case '0':
          return (
            type === 0 ||
            type === 19 ||
            type === 20 ||
            type === 22 ||
            type === 32 ||
            type === 33 ||
            type === 34 ||
            type === 35 ||
            type === 36 ||
            type === 37 ||
            type === 38 ||
            type === 39 ||
            type === 40
          );
        case '1':
          return type === 1 || type === 4 || type === 31;
        case '5':
          return type === 5 || type === 6 || type === 13 || type === 14 || type === 25;
        case '7':
          return type === 7 || type === 8 || type === 9 || type === 26 || type === 29;
        default:
          return String(type) === selectedTarget;
      }
    };

    return targetType === 2 ? checkTargetType(relatedTargetType) : checkTargetType(targetType);
  })();

  // Add the hits range check
  const hitsRange = extractHitsRange();
  const hitsCheck = (() => {
    const hitCount = effect.count;

    const targetType = effect.targetParams?.targetType;
    const relatedEffect =
      targetType === 2 ? effects.find((e: { id: any }) => e.id === effect.relation.effectTypeId) : null;

    // Consider effect.count of related effect
    const relatedHitCount = relatedEffect?.count || hitCount;

    return relatedHitCount >= hitsRange.min && relatedHitCount <= hitsRange.max;
  })();

  const chanceToPlacePercentCheck = (() => {
    const placeChance = effect.chance;

    const targetType = effect.targetParams?.targetType;

    let relatedEffect;
    if (targetType === 2 && selectedTarget) {
      relatedEffect = getRelatedEffect(effect, effects, selectedTarget);
      //console.log('relatedEffect:', relatedEffect);
    }

    // Consider effect.stack of related effect
    const relatedPlaceChance = relatedEffect?.chance || placeChance;

    // Convert relatedPlaceChance to a percentage
    const relatedPlaceChancePercentage = relatedPlaceChance * 100;

    //console.log('relatedPlaceChancePercentage:', relatedPlaceChancePercentage);
    //console.log('chanceToPlacePercentRange:', chanceToPlacePercentRange);

    const checkResult =
      relatedPlaceChancePercentage >= chanceToPlacePercentRange.min &&
      relatedPlaceChancePercentage <= chanceToPlacePercentRange.max;

    //console.log('chanceToPlacePercentCheck result:', checkResult);

    return checkResult;
  })();

  const fullyBookedChanceToPlacePercentCheck = (() => {
    const placeChance = effect.chance;

    const targetType = effect.targetParams?.targetType;

    let relatedEffect;
    if (targetType === 2 && selectedTarget) {
      relatedEffect = getRelatedEffect(effect, effects, selectedTarget);
      //console.log('relatedEffect:', relatedEffect);
    }

    // Step 3: Consider effect.count of related effect
    const relatedPlaceChance = relatedEffect?.chance || placeChance;

    // Convert relatedPlaceChance to a percentage
    const relatedPlaceChancePercentage = relatedPlaceChance * 100;
    const fullyBookedChanceIncrease = calculateFullyBookedChance(abilityData);
    const relatedPlaceChancePercentageFullyBooked = relatedPlaceChancePercentage + fullyBookedChanceIncrease;

    //console.log('relatedPlaceChancePercentage:', relatedPlaceChancePercentage);
    //console.log('fullyBookedChanceToPlacePercentRange:', fullyBookedChanceToPlacePercentRange);

    const checkResult =
      relatedPlaceChancePercentageFullyBooked >= fullyBookedChanceToPlacePercentRange.min &&
      relatedPlaceChancePercentageFullyBooked <= fullyBookedChanceToPlacePercentRange.max;

    //console.log('fullyBookedChanceToPlacePercentCheck result:', checkResult);

    return checkResult;
  })();

  const myChanceToPlacePercentCheck = (() => {
    const myChanceFinal = calculateMyChance(abilityData, item.instance, Number(selectedEffectKind), 0) * 100;

    //console.log('myChanceFinal:', myChanceFinal);

    const checkResult =
      myChanceFinal >= myChanceToPlacePercentRange.min && myChanceFinal <= myChanceToPlacePercentRange.max;

    //console.log('checkResult:', checkResult);

    return checkResult;
  })();

  if (!targetTypeCheck) return false;

  const durationCheck =
    effect.applyStatusEffectParams?.statusEffectInfos[0]?.duration === undefined ||
    effect.applyStatusEffectParams?.statusEffectInfos[0]?.duration === null ||
    (effect.applyStatusEffectParams?.statusEffectInfos[0]?.duration >= durationRange.min &&
      effect.applyStatusEffectParams?.statusEffectInfos[0]?.duration <= durationRange.max);

  const cooldownCheck =
    !abilityData.cooldown || (abilityData.cooldown >= cooldownRange.min && abilityData.cooldown <= cooldownRange.max);

  const fullyBookedCooldown = calculateFullyBookedCooldown(abilityData);
  const fullyBookedCooldownCheck =
    fullyBookedCooldown >= fullyBookedCooldownRange.min && fullyBookedCooldown <= fullyBookedCooldownRange.max;

  const myCooldown = calculateMyCooldown(abilityData, item.instance);
  /* if (item.instance) {
    console.log(`myCooldown for ${item.type.name} ${item.instance.marker}:`, myCooldown);
  } */
  const myCooldownCheck = myCooldown >= myCooldownRange.min && myCooldown <= myCooldownRange.max;

  if (
    a1ToggleState === 'no' &&
    selectedTarget &&
    championA1Matches(item, selectedEffectKind, selectedTarget, durationRange, cooldownRange)
  ) {
    return false;
  }

  const result =
    durationCheck &&
    cooldownCheck &&
    myCooldownCheck &&
    fullyBookedCooldownCheck &&
    hitsCheck &&
    chanceToPlacePercentCheck &&
    fullyBookedChanceToPlacePercentCheck &&
    myChanceToPlacePercentCheck;

  if (!result) {
    console.log(`checkStatusEffect failed for ${item.type.name}`);
  }

  return result;
}

export const AdvancedEffectKindAutocompleteFilter = new ObservableSelectFilter<ChampionItem>({
  items: getEffectKinds(),
  id: 'advancedEffectKind',
  label: 'Effect Kind',
  minWidth: '128px',
  single: true,
  autocomplete: true,
  hideIcons: true,
  getOptionCount: (effectKindLabel: string) => {
    // Convert effectKindLabel to typeId
    const effectKindTypeId = effectKindLabel;

    // Define the filter function
    const filterFn = (champ: ChampionItem) => {
      // Initialize an empty array to collect all skillTypeIds from each form
      let allSkillIds: any[] = [];

      // Collect all skillTypeIds from each form into allSkillIds
      if (champ.type.forms) {
        champ.type.forms.forEach((form) => {
          if (form.skillTypeIds) {
            allSkillIds = [...allSkillIds, ...form.skillTypeIds];
          }
        });
      }

      let returnChamp = false;
      allSkillIds.forEach((item) => {
        const abilityData = skills[item];
        const effects = abilityData?.effects || [];
        effects.forEach((effect: { kindId: any }) => {
          if (effect.kindId && String(effect.kindId) === effectKindTypeId) {
            returnChamp = true;
          }
        });
      });
      return returnChamp;
    };

    // Use the generic function to get the count
    return getOptionCountGeneric(effectKindTypeId, filterFn);
  },
  canHandle(opts: ViewOptions) {
    return opts.viewType === ViewType.Account || opts.viewType === ViewType.Index;
  },
  filter(keys, item) {
    if (keys.length === 0) {
      return true;
    }

    const plainKeys = keys.slice();
    //console.log('Plain keys:', plainKeys);

    const selectedEffectKind = keys[0];
    //console.log('Selected Effect Kind:', selectedEffectKind);

    // Initialize an empty array to collect all skillTypeIds from each form
    let allSkillIds: any[] = [];

    // Collect all skillTypeIds from each form into allSkillIds
    if (item.type.forms) {
      item.type.forms.forEach((form) => {
        if (form.skillTypeIds) {
          allSkillIds = [...allSkillIds, ...form.skillTypeIds];
        }
      });
    }

    for (const skillId of allSkillIds) {
      const abilityData = skills[skillId];
      //console.log('Ability Data:', abilityData);

      if (!abilityData?.effects) continue;

      const effects = abilityData.effects;
      //console.log('Effects:', effects);

      const a1ToggleState = extractA1ToggleState();
      //console.log('A1 Toggle State:', a1ToggleState);

      for (const effect of effects) {
        if (
          a1ToggleState &&
          checkEffectKind(item, effect, abilityData, selectedEffectKind, abilityData.effects, a1ToggleState)
        ) {
          //console.log('Effect matched in abilityData.effects:', effect);
          return true;
        }
      }

      // Check passive skills if available
      if (abilityData.passiveEffects) {
        for (const effect of abilityData.passiveEffects) {
          if (
            a1ToggleState &&
            checkEffectKind(item, effect, abilityData, selectedEffectKind, abilityData.effects, a1ToggleState)
          ) {
            //console.log('Effect matched in abilityData.passiveEffects:', effect);
            return true;
          }
        }
      }

      // If the A1 toggle is set to 'yes', we only need to check the first skill (A1)
      if (a1ToggleState === 'yes') break;
    }

    return keys.length === 0;
  },
});

const PassiveSingleToggleFilterOptions: Record<string, DisplayMetadata> = {
  passive: {
    color: 'lime',
    name: 'Has a Passive',
  },
};

export const PassiveSingleToggleFilter = new ObservableToggleFilter<ChampionItem, ChampionInstance>({
  items: PassiveSingleToggleFilterOptions,
  defaultValue: 'off',
  id: 'effectTypeToggle',
  canHandle(opts: ViewOptions) {
    return opts.viewType === ViewType.Account || opts.viewType === ViewType.Index;
  },
  filter(value, item) {
    let returnChamp = false;
    let abilityData: AbilityData | undefined;
    let passive = false;

    // Assuming `forms` is an array and you want to combine all skillTypeIds from all forms
    const allSkillTypeIds = item.type.forms?.flatMap((form) => form.skillTypeIds) || [];

    allSkillTypeIds.forEach((skillId) => {
      abilityData = skills[skillId];
      if (regexPassive.exec(String(abilityData?.name))) {
        /* if (window.location.hostname === 'localhost') {
          console.log(`abilityData?.name`, abilityData?.name);
        } */
        passive = true;
      }
    });

    if (passive !== false) {
      returnChamp = true;
    }

    switch (value) {
      case 'passive':
        return returnChamp;
    }
    return true;
  },
});

const AdvancedA1SingleToggleFilterOptions: Record<string, DisplayMetadata> = {
  no: {
    color: 'red',
    name: 'No',
  },
  off: {
    name: 'A1?',
  },
  yes: {
    color: 'lime',
    name: 'Yes',
  },
};

export const AdvancedA1SingleToggleFilter = new ObservableToggleFilter<ChampionItem, ChampionInstance>({
  items: AdvancedA1SingleToggleFilterOptions,
  defaultValue: 'off',
  id: 'advancedA1',
  save: true,
  saveAsString: true,
  getOptionCount: (toggleValue: string) => {
    // Define the filter function
    const filterFn = (champ: ChampionItem) => {
      let returnChamp = false;
      let abilityData: AbilityData | undefined;
      let a1 = false;
      let selectedStatusEffect: string | any[] | null = window.localStorage.getItem(
        'setting:saved-selection-statusEffect'
      );

      let tagChecksPassed = false;
      if (champ.type.indexed.tags) {
        const tags = champ.type.indexed.tags;
        tags.forEach((tag) => {
          if (tag && String(tag.statusEffect) === selectedStatusEffect) {
            tagChecksPassed = true;
          }
        });
      }

      champ.type.skillIds.forEach((skillId) => {
        abilityData = skills[skillId];
        if (tagChecksPassed) {
          a1 = true;
        }
      });

      if (a1 !== false) {
        returnChamp = true;
      }

      switch (toggleValue) {
        case 'true':
          return returnChamp;
        default:
          return true;
      }
    };

    // Use the generic function to get the count
    return getOptionCountGeneric(toggleValue, filterFn);
  },
  canHandle(opts: ViewOptions) {
    return opts.viewType === ViewType.Account || opts.viewType === ViewType.Index;
  },
  filter(value, item) {
    let returnChamp = false;
    let abilityData: AbilityData | undefined;
    let a1 = false;
    let selectedStatusEffect: string | any[] | null = window.localStorage.getItem(
      'setting:saved-selection-statusEffect'
    );

    let tagChecksPassed = false;
    if (item.type.indexed.tags) {
      const tags = item.type.indexed.tags;

      if (window.location.hostname === 'localhost') {
        //console.log(`tag.a1 exists & tag.statusEffect = ${tag.statusEffect} && selectedStatusEffect = ${selectedStatusEffect}`)
      }

      tags.forEach((tag) => {
        if (tag && String(tag.statusEffect) === selectedStatusEffect) {
          tagChecksPassed = true;
        }
      });
    }

    const allSkillTypeIds = item.type.forms?.flatMap((form) => form.skillTypeIds) || [];

    allSkillTypeIds.forEach((skillId) => {
      abilityData = skills[skillId];
      if (tagChecksPassed) {
        a1 = true;
      }
    });

    if (a1 !== false) {
      returnChamp = true;
    }

    switch (value) {
      case 'true':
        return true;
    }
    return true;
  },
});

export const AdvancedTargetTypesAutocompleteFilter = new ObservableSelectFilter<ChampionItem>({
  items: getTargetTypes(),
  id: 'advancedTargetType',
  label: 'Target Types',
  minWidth: '123px',
  single: true,
  autocomplete: true,
  placeholderText: 'Target Type',
  getOptionCount: (targetTypeLabel: string) => {
    // Convert targetTypeLabel to typeId
    const targetTypeTypeId = targetTypeLabel;

    // Define the filter function
    const filterFn = (champ: ChampionItem) => {
      // Initialize an empty array to collect all skillTypeIds from each form
      let allSkillIds: any[] = [];

      // Collect all skillTypeIds from each form into allSkillIds
      if (champ.type.forms) {
        champ.type.forms.forEach((form) => {
          if (form.skillTypeIds) {
            allSkillIds = [...allSkillIds, ...form.skillTypeIds];
          }
        });
      }

      let returnChamp = false;
      allSkillIds.forEach((item) => {
        const abilityData = skills[item];
        const effects = abilityData?.effects || [];
        effects.forEach((effect: any) => {
          let targetTypeKey: number | undefined;
          let relationEffectData: any;

          // Your existing logic for determining relationEffectData and targetTypeKey
          let effectTypeId = effect?.relation?.effectTypeId;
          let theTargetTypeNumber = effect?.targetParams?.targetType;
          if (effectTypeId !== undefined && theTargetTypeNumber === 2) {
            relationEffectData = returnEffectWithThisId(effects, effectTypeId);
          } else {
            relationEffectData = effect;
          }

          let relationOfRelationEffectData;
          if (relationEffectData?.relation && relationEffectData?.targetParams?.targetType === 2) {
            relationOfRelationEffectData = returnEffectWithThisId(effects, relationEffectData?.relation?.effectTypeId);
          }

          if (relationOfRelationEffectData) {
            targetTypeKey = relationOfRelationEffectData?.targetParams?.targetType;
          } else {
            targetTypeKey = relationEffectData?.targetParams?.targetType;
          }

          // End of your existing logic

          if (String(targetTypeKey) === targetTypeTypeId) {
            returnChamp = true;
          }
        });
      });
      return returnChamp;
    };

    // Use the generic function to get the count
    return getOptionCountGeneric(targetTypeTypeId, filterFn);
  },
  canHandle(opts: ViewOptions) {
    return opts.viewType === ViewType.Account || opts.viewType === ViewType.Index;
  },
  filter(keys, item) {
    return true;
  },
});
