import React, { ReactNode, useCallback, useMemo } from 'react';
import SortableContainer from './SortableContainer';
import Item from './Item';
import Appender from './Appender';
import './SortedEditor.css';
import { SortKey, parseKey } from './SortKey';

interface Props<T, K extends string> {
  value: readonly SortKey<K>[];
  dataSource: readonly T[];
  fallbackItem: (value: K) => T;
  resolveValue: (item: T) => K;
  renderItem: (item: T) => ReactNode;
  onChange: (value: readonly SortKey<K>[]) => void;
}

export default function SortedEditor<T, K extends string>(props: Props<T, K>) {
  const { value, dataSource, fallbackItem, resolveValue, renderItem, onChange } = props;
  const unusedDataSource = useMemo(
    () => dataSource.filter((i) => !value.includes(resolveValue(i))),
    [dataSource, value, resolveValue]
  );
  const create = useCallback(
    (created: K) => {
      onChange([created, ...value]);
    },
    [value, onChange]
  );
  const remove = useCallback(
    (removed: K) => {
      onChange(value.filter((v) => v !== removed && v !== `-${removed}`));
    },
    [value, onChange]
  );
  const changeDirection = useCallback(
    (key: K) => {
      const idx = value.findIndex((item) => parseKey<K>(item)[0] === key);
      if (idx < 0) return;

      const [, dir] = parseKey<K>(value[idx]!);
      const newDir = dir * -1;
      const newValue = value.slice();
      newValue[idx] = newDir === 1 ? key : `-${key}`;
      onChange(newValue);
    },
    [value, onChange]
  );
  const sort = useCallback(
    (fromIndex: number, toIndex: number) => {
      const movingItem = value[fromIndex];
      const itemRemoved = [...value.slice(0, fromIndex), ...value.slice(fromIndex + 1)];
      const itemInserted = [...itemRemoved.slice(0, toIndex), movingItem, ...itemRemoved.slice(toIndex)].filter(
        Boolean
      ) as K[];
      onChange(itemInserted);
    },
    [value, onChange]
  );
  const renderDisplayItem = (value: SortKey<K>) => {
    const [key, dir] = parseKey<K>(value);
    const item = dataSource.find((v) => resolveValue(v) === key);

    return item ? (
      <Item key={key} value={value} onDelete={remove} onChangeDirection={changeDirection}>
        {renderItem(item)}
      </Item>
    ) : (
      <span key={`hidden_${key}`} />
    );
  };

  return (
    <div id={value.toString()} className="SortedEditorIndexLayout">
      <SortableContainer items={value} onSort={sort}>
        {value.map(renderDisplayItem)}
        <Appender dataSource={unusedDataSource} resolveValue={resolveValue} renderItem={renderItem} onSelect={create} />
      </SortableContainer>
    </div>
  );
}
