import React from 'react';
import { ArtifactItem, ArtifactStatBonus, GearSlot, StatKind } from '../Types';
import './ArtifactTile.css';
import { Tile, Glow, RarityBox, NextGenImage } from './Library';
import { getRankNumber, capitalizeFirstLetter } from './DataView/Helpers';
import { getGlobalRootStore, useRootStore } from 'Model';
import { observer } from 'mobx-react-lite';
import { formatStatValue, getStatName } from './Library/StatsHelpers';
import artifactSets from '../Data/Data/artifactSets.json';
import { getAllCapsStatNameForAscendBonus } from './DataView/ArtifactHelpers';
import { ArtifactType } from 'Types/Entities/ArtifactType';

function artifactStatValue(value: number, absolute: boolean): number {
  return absolute ? value : value * 100;
}

// (bonus) is a dict with (statKind), (absolute), and (value) keys
function Bonus(bonus: ArtifactStatBonus) {
  if (!bonus || !('value' in bonus) || bonus.value === 0) {
    return '';
  }
  const kind = bonus?.statKind || 'test';
  const level = bonus.level;
  const prefix = getStatName(kind);
  const bonusLevel = (level && `(${level})`) || '';
  return (
    <>
      {prefix}
      <span className="bonusLevel info" title="How many times this stat improved from leveling">
        {bonusLevel}
      </span>{' '}
      {formatStatValue(artifactStatValue(bonus.value, bonus.absolute), bonus.absolute)}
    </>
  );
}

function Stat(stat: ArtifactStatBonus) {
  const bonus = Bonus(stat);
  if (stat.glyphPower > 0) {
    return (
      <span className="subStatAndGlyphBonus-wrap">
        {bonus}
        <span className="glyphBonus">
          {' '}
          +{formatStatValue(artifactStatValue(stat.glyphPower, stat.absolute), stat.absolute)}
        </span>
      </span>
    );
  }
  return bonus;
}

function Substats(subStats: ArtifactStatBonus[] | undefined) {
  if (!subStats || subStats.length === 0) {
    return null;
  }
  if (subStats.length === 1) {
    return Stat(subStats[0]!);
  }
  let entries: JSX.Element[] | null | undefined = [];
  subStats.forEach((subStat: any, index: React.Key | null | undefined) => {
    if (entries !== null && entries !== undefined) {
      entries.push(
        <li className="subStat" key={index}>
          {Stat(subStat)}
        </li>
      );
    }
  });
  return <ul className="substats">{entries}</ul>;
}

function AscendBonus(bonus: ArtifactStatBonus) {
  const prefix = `${getAllCapsStatNameForAscendBonus(bonus)} `;
  return (
    <>
      {prefix}
      {formatStatValue(artifactStatValue(bonus.value, bonus.absolute), bonus.absolute)}
    </>
  );
}

export interface ArtifactTileProps {
  gearSlot: GearSlot;
  item?: ArtifactType;
  statsOutsideArtifact?: boolean;
  local?: boolean;
}
export const ArtifactTile: React.FC<ArtifactTileProps> = observer(({ gearSlot, item, statsOutsideArtifact, local }) => {
  const store = useRootStore();
  let image;
  let artifactImageName = '';
  let msg;
  if (item && item.setKindId === 'None') {
    artifactImageName = item.faction;
    if (item.faction === 'knightsrevenant') {
      artifactImageName = 'KnightsRevenant';
    }
    if (item.faction === 'undeadhordes') {
      artifactImageName = 'UndeadHordes';
    }
    if (item.faction === 'highelves') {
      artifactImageName = 'HighElves';
    }
    if (item.faction === 'lizardmen') {
      artifactImageName = 'LizardMen';
    }
    if (item.faction === 'sacredorder') {
      artifactImageName = 'SacredOrder';
    }
    if (item.faction === 'darkelves') {
      artifactImageName = 'DarkElves';
    }
    if (item.faction === 'bannerlords') {
      artifactImageName = 'BannerLords';
    }
    if (item.faction === 'ogryntribes') {
      artifactImageName = 'OgrynTribes';
    }
    if (item.faction === 'nyresanelves') {
      artifactImageName = 'SylvanWatchers';
    }
  } else if (item) {
    // Create a lookup table using the exportedKey as the key and imageName as the value
    const artifactImageLookup: any = {};
    Object.values(artifactSets.lookup.artifactSets).forEach((artifactSet) => {
      artifactImageLookup[artifactSet.exportedKey] = artifactSet.imageName;
    });

    // Use the lookup table to set the artifactImageName
    artifactImageName = artifactImageLookup[item.setKindId];
    if (!artifactImageName) {
      // handle case where setKindId is not in the JSON file
      console.warn(`No imageName found for setKindId '${item.setKindId}'`);
      // set a default value or do something else to handle the error
      artifactImageName = item.setKindId;
    }
  }
  if (item && item.kindId) {
    image = (
      <>
        <div style={{ position: 'relative' }} className={`artifactRarityBox RarityBox ${item?.rarity}`}>
          {/* <Glow> */}
          <NextGenImage
            local={local}
            className="artifactImage"
            whichDirectory="artifactSets"
            whichImage={`${capitalizeFirstLetter(artifactImageName)}_${capitalizeFirstLetter(gearSlot)}`}
          />
          {/* </Glow> */}
        </div>
      </>
    );
  } else {
    image = (
      <>
        {/* <Glow> */}
        <NextGenImage
          local={local}
          className="avatar"
          whichDirectory="_NotExtracted/myCreations/ItemSlots"
          /* For some reason this is not working for the ring slot... gearSlot shows as undefined in the image name */
          width="100%"
          whichImage={`${capitalizeFirstLetter(gearSlot)}`}
          title={`Nothing equipped in the '${capitalizeFirstLetter(gearSlot)}' slot`}
        />
        {/* </Glow> */}
      </>
    );
  }

  let rankNumber = 0;
  if (item?.rank) {
    rankNumber = getRankNumber(item.rank);
  }
  //console.log(`${item && item.kindId} rankNumber`, rankNumber);

  let glyphedIcon;
  let primaryStat;
  let subStats;
  let ascendBonus;
  let starsImg;
  let ascendedStarsImg;

  /*   if (item?.id === 53793) {
    console.log(`item`, item);
  } */

  const artifactSetLookup: any = {};
  Object.values(artifactSets.lookup.artifactSets).forEach((artifactSet) => {
    artifactSetLookup[artifactSet.exportedKey] = artifactSet.name;
  });

  /* If something is equipped in this slot */
  if (item && item.kindId !== undefined) {
    const artifactSetName = artifactSetLookup[item.setKindId];

    msg = capitalizeFirstLetter(item.kindId);
    const UNICODE_STAR = '\u2605';

    const STAR_IMAGE_SRC = 'https://ik.imagekit.io/raidchamps/_NotExtracted/myCreations/Misc/regular_star.png';

    let ascendLevel = item?.ascendLevel || 0;
    /* if (ascendLevel > 0) {
      console.log(`ascendLevel`, ascendLevel);
    } */
    let ascendedStars = ascendLevel || 0;

    let starsText = `${getRankNumber(item.rank)}${UNICODE_STAR} ${capitalizeFirstLetter(
      item.rarity
    )} ${artifactSetName} ${ascendedStars > 0 ? `ascended ${ascendedStars} time` : ''}${
      ascendedStars > 0 && ascendedStars !== 1 ? `s` : ''
    }`;
    let rank = getRankNumber(item.rank);
    starsImg = [];
    for (let i = 0; i < rank; i++) {
      if (ascendedStars <= i) {
        starsImg.push(
          <div className="artifactImageStars-wrap info" title={starsText}>
            <img key={i} className="artifact_star" src={STAR_IMAGE_SRC} />
          </div>
        );
      } else {
        starsImg.push(
          <div className="artifactImageStars-wrap artifactAscendedStarsImageStars-wrap info" title={starsText}>
            <img
              key={i}
              title={`This artifact has been ascended ${ascendedStars} times`}
              className="artifact_ascendedStar artifact_star"
              src={'https://ik.imagekit.io/raidchamps/_NotExtracted/myCreations/Misc/awaken_star.png'}
            />
          </div>
        );
      }
    }

    msg = starsText + msg;

    if (item.primaryBonus !== undefined && item.primaryBonus.glyphPower > 0) {
      glyphedIcon = (
        <NextGenImage
          local={local}
          className="glyphedIcon info"
          whichDirectory="_NotExtracted/myCreations/Misc"
          whichImage={`Stone`}
          title="This artifact has been glyphed atleast once"
        />
      );
    }
    if (item.secondaryBonuses) {
      for (let i = 0; i < item.secondaryBonuses.length; i++) {
        const secondaryBonus = item.secondaryBonuses[i]?.glyphPower || 0;
        if (secondaryBonus > 0) {
          glyphedIcon = (
            <NextGenImage
              local={local}
              className="glyphedIcon"
              whichDirectory="_NotExtracted/myCreations/Misc"
              whichImage={`Stone`}
              title="This artifact has been glyphed atleast once."
            />
          );
        }
      }
    }

    ascendBonus = item.ascendBonus ? (
      <div className="ascendBonus">{AscendBonus(item.ascendBonus)}</div>
    ) : (
      <div className="ascendBonus" />
    );

    primaryStat = item.primaryBonus ? (
      <div className="primary">
        {Bonus(item.primaryBonus)} {ascendBonus}
      </div>
    ) : (
      <div className="primary" />
    );

    subStats = item.secondaryBonuses;

    /* if (String(item.id) === '62731') {
      console.log(item);
    } */
  }

  const rootStore = getGlobalRootStore();
  const account = rootStore.accounts.selectedAccount;
  const champItemOfChampThatHasThisArtifactEquipped = account?.artifactEquippedBy.get(item ? item.id : 0);

  const nameOfChampionThatHasArtifactEquipped = champItemOfChampThatHasThisArtifactEquipped?.type.name;

  const rarityOfChampionThatHasArtifactEquipped = champItemOfChampThatHasThisArtifactEquipped?.type.rarity;

  const typeIdOfChampionThatHasArtifactEquipped = champItemOfChampThatHasThisArtifactEquipped?.type.typeId;

  let typeIdThatMatchesImageName = '';
  if (typeIdOfChampionThatHasArtifactEquipped) {
    typeIdThatMatchesImageName = String(
      typeIdOfChampionThatHasArtifactEquipped - (typeIdOfChampionThatHasArtifactEquipped % 10)
    );
  }

  /*   const handleOpen = () => {
    store.modalArtifacts = item;
  }; */

  const handleOpen = () => {
    store.modalChampion = champItemOfChampThatHasThisArtifactEquipped;
  };

  return (
    <>
      <div
        className={`artifacts-wrap flex-container wrap ${statsOutsideArtifact === false && 'statsInsideArtifact'}`}
        title={msg}
      >
        <Tile className={`emphasize-container artifact-tile ${item?.rarity} artifactContent-wrap`}>
          <div className={`ArtifactImage-wrap ${item?.id}`} style={{ position: 'relative' }}>
            <div className="ArtifactTileImage-wrap">{image}</div>
          </div>
          <div className="ArtifactContent-wrap">
            <div className="artifactActivated">
              {item?.activated && (
                <div className="equippedArtifactImage-wrap" onClick={handleOpen}>
                  <NextGenImage
                    local={local}
                    className={`equippedByImg ${rarityOfChampionThatHasArtifactEquipped}`}
                    whichDirectory={`heroAvatars`}
                    whichImage={String(typeIdThatMatchesImageName)}
                    title={`Equipped by ${nameOfChampionThatHasArtifactEquipped} (Click to open Champ Details in Modal)`}
                    alt={`Equipped by ${nameOfChampionThatHasArtifactEquipped}`}
                  />
                </div>
              )}
            </div>
            <div className="artifactLevel">{item && item.level > 0 ? `+${item?.level}` : ''}</div>
            <div className="artifactRank" title={`${item?.rank} Star Artifact`}>
              {/* {item && item.kindId !== undefined && (
                <StarRating className="RankItem" totalStars={6} filledStars={rankNumber} color="#e8c817" size="large" />
              )} */}
              {starsImg}
            </div>
            {glyphedIcon && glyphedIcon}
            {statsOutsideArtifact === false && (
              <div className="artifactStats-wrap statsInsideArtifact">
                {primaryStat}
                <div>{Substats(subStats)}</div>
              </div>
            )}
          </div>
        </Tile>
      </div>
      {statsOutsideArtifact !== false && (
        <div className="artifactStats-wrap">
          {primaryStat}
          <div>{Substats(subStats)}</div>
        </div>
      )}
    </>
  );
});
