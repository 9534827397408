export enum MasteryKindId {
  BladeDisciple = 500112,
  DeadlyPrecision = 500113,
  HeartofGlory = 500121,
  KeenStrike = 500122,
  ShieldBreaker = 500123,
  GrimResolve = 500124,
  SingleOut = 500131,
  LifeDrinker = 500132,
  WhirlwindofDeath = 500133,
  RuthlessAmbush = 500134,
  BringItDown = 500141,
  WrathoftheSlain = 500142,
  CycleofViolence = 500143,
  Opportunist = 500144,
  Methodical = 500151,
  KillStreak = 500152,
  BloodShield = 500153,
  StokedToFury = 500154,
  Warmaster = 500161,
  Helmsmasher = 500162,
  GiantSlayer = 500163,
  FlawlessExecution = 500164,
  ToughSkin = 500212,
  Defiant = 500213,
  Blastproof = 500221,
  Rejuvenation = 500222,
  MightyEndurance = 500223,
  ImprovedParry = 500224,
  ShadowHeal = 500231,
  Resurgent = 500232,
  Bloodthirst = 500233,
  WisdomofBattle = 500234,
  Solidarity = 500241,
  DelayDeath = 500242,
  HarvestDespair = 500243,
  Stubbornness = 500244,
  SelflessDefender = 500251,
  CycleofRevenge = 500252,
  Retribution = 500253,
  Deterrence = 500254,
  IronSkin = 500261,
  Bulwark = 500262,
  FearsomePresence = 500263,
  Unshakeable = 500264,
  Steadfast = 500312,
  PinpointAccuracy = 500313,
  LayOnHands = 500321,
  Shieldbearer = 500322,
  ExaltinDeath = 500323,
  ChargedFocus = 500324,
  HealingSavior = 500331,
  RapidResponse = 500332,
  SwarmSmiter = 500333,
  ArcaneCelerity = 500334,
  MercifulAid = 500341,
  CycleofMagic = 500342,
  LoreofSteel = 500343,
  EvilEye = 500344,
  LastingGifts = 500351,
  SpiritHaste = 500352,
  Sniper = 500353,
  MasterHexer = 500354,
  ElixirofLife = 500361,
  TimelyIntervention = 500362,
  Oppressor = 500363,
  EagleEye = 500364,
}
