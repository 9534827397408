import { hasRole } from 'Components/Layout/Header';
import React from 'react';

export interface UserRolesProps {
  children?: any;
  roles: any;
}

const UserRoles: React.FC<UserRolesProps> = ({ children, roles }) => {
  return (
    <div className="Powers-wrap">
      <h3>My Powers:</h3>
      {roles.length > 0 ? (
        <>
          <div>{roles && roles.join(', ')}</div>
        </>
      ) : (
        <>Subscribe below to gain powers.</>
      )}
      {roles.length > 0 && hasRole(roles, ['Content Creator', 'Advanced Features']) && (
        <p className="caption small">
          Sorry but you will have to contact me in order to cancel your subscription(s). I spent over a week trying to
          get something working to allow you to unsubscribe yourself but it is just too freaking complicated for me
          right now.
        </p>
      )}
    </div>
  );
};

export default UserRoles;
