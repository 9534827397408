import { action, autorun, computed, makeObservable, observable, toJS } from 'mobx';
import { AccountManagerStore } from './AccountManagerStore';
import { RTKClient } from './RTKClient';
import { resetStores } from './Forage';
import { ListViewStore } from './ListViewStore';
import { ArtifactItem, ChampionItem, ChampionTypeEx, DataItem } from 'Types';
import * as Filters from '../Components/DataView/Filters';
import * as ArtifactFilters from '../Components/DataView/ArtifactFilters';
import { artifactSorts, sorts } from '../Components/DataView/Sorts';
import { ViewOptions, ViewType } from '../Components/DataView/ViewOptions';
import { ObservableProperty } from './ObservableProperty';
import { getAllChamps } from 'Data';

export class RootStore {
  public accounts: AccountManagerStore;
  public client: RTKClient;
  public filterStates: Map<string, string[]>;
  public filteredChampions: ChampionItem[] = [];
  public readonly listViews: {
    [ViewType.Index]: ListViewStore<ChampionItem, ViewOptions>;
    [ViewType.Account]: ListViewStore<ChampionItem, ViewOptions>;
    [ViewType.Artifacts]: ListViewStore<ArtifactItem, ViewOptions>;
  };
  private modalChampionProp = new ObservableProperty<ChampionItem>();
  private modalArtifactsProp = new ObservableProperty<ArtifactItem>();

  saveCurrentSort(viewType: ViewType, sortOrder: readonly string[]) {
    localStorage.setItem(`currentSort_${viewType}`, JSON.stringify(sortOrder));
  }

  loadSavedSort(viewType: ViewType): string[] | null {
    const savedSort = localStorage.getItem(`currentSort_${viewType}`);
    if (savedSort) {
      return JSON.parse(savedSort);
    }
    return null;
  }

  resetSortToDefault(viewType: ViewType) {
    localStorage.removeItem(`currentSort_${viewType}`);
    window.location.reload();
  }

  constructor() {
    this.accounts = new AccountManagerStore();
    this.client = new RTKClient(this.accounts);
    this.filterStates = new Map();
    const indexSavedSort = this.loadSavedSort(ViewType.Index) || ['ratingOverall', 'rarity', 'name'];
    const accountSavedSort = this.loadSavedSort(ViewType.Account) || [
      'rank',
      'empowerLevel',
      'ascensionLevel',
      'level',
      'rarity',
      'awakenRank',
      'affinity',
      'name',
    ];
    const artifactsSavedSort = this.loadSavedSort(ViewType.Artifacts) || [
      'ascendLevel',
      'ascendBonusValue',
      'setKindId',
      'kindId',
      'level',
      'rank',
      'rarity',
    ];

    this.listViews = {
      [ViewType.Index]: new ListViewStore<ChampionItem, ViewOptions>(sorts, Filters, {
        viewType: ViewType.Index,
        defaultSort: indexSavedSort,
      }),
      [ViewType.Account]: new ListViewStore<ChampionItem, ViewOptions>(sorts, Filters, {
        viewType: ViewType.Account,
        defaultSort: accountSavedSort,
      }),
      [ViewType.Artifacts]: new ListViewStore<ArtifactItem, ViewOptions>(artifactSorts, ArtifactFilters, {
        viewType: ViewType.Artifacts,
        defaultSort: artifactsSavedSort,
      }),
    };

    makeObservable<RootStore>(this, {
      accounts: observable.ref,
      client: observable.ref,
      filterStates: observable,
      modalChampion: true,
      modalArtifacts: true,
      filteredChampions: observable.ref,
      updateFilteredChampions: action.bound,
    });

    // Automatically update filteredChampions whenever any filter changes
    autorun(() => {
      //console.log('Autorun triggered');
      //console.log('filterStates:', [...this.filterStates.entries()]);
      const allChamps = getAllChamps();
      this.updateFilteredChampions(allChamps);
      //console.log('Current filteredChampions:', [...this.filteredChampions]);
    });
  }

  // Function to update the filtered list whenever any filter changes
  updateFilteredChampions(allChamps: ChampionItem[]) {
    //console.log('updateFilteredChampions called with:', allChamps);
    // Start with all champions
    let champs = allChamps;

    // Access filterStates from your RootStore
    const filterStates = this.filterStates;

    // Apply the Type filter
    const selectedRoles = filterStates.get('type') || [];
    if (selectedRoles.length > 0) {
      champs = champs?.filter((champ: { type: { role: string } }) => selectedRoles.includes(champ.type.role));
    }

    // Apply the status effect filter
    const selectedStatusEffects = filterStates.get('statusEffect') || [];
    if (selectedStatusEffects.length > 0) {
      champs = champs?.filter((champ: any) => {
        // Your logic to check if this champ has any of the selected status effects
        // ...
        return true; // or false
      });
    }

    // You can add more filters here as needed

    // Update the filteredChampions in your store or wherever you're keeping it
    this.filteredChampions = [...champs]; // Changed rootStore to this
  }

  get modalChampion() {
    return this.modalChampionProp.value;
  }

  get modalArtifacts() {
    return this.modalArtifactsProp.value;
  }

  set modalChampion(value: ChampionItem | undefined) {
    this.modalChampionProp.setValue(value);
  }

  set modalArtifacts(value: ArtifactItem | undefined) {
    this.modalArtifactsProp.setValue(value);
  }

  connect() {
    this.client.connect();
  }

  async reset() {
    this.client.disconnect();
    this.filterStates.clear();
    await resetStores();
    this.accounts = new AccountManagerStore();
    this.client = new RTKClient(this.accounts);
  }
}
