import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useAuth0 } from '@auth0/auth0-react';
import Auth0LoginLogout from '../Layout/Auth0LoginLogout';
import UserRoles from './UserRoles';
import { Auth0Upgrade } from 'Components/Layout/Auth0Upgrade';
import './Profile.css';
import { Link } from 'react-router-dom';

let roles: string | string[];

export const Profile: React.FC = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();

  if (user) {
    if (
      user['https://raidchamps.com/app_metadata'].roles &&
      user['https://raidchamps.com/app_metadata'].roles.length > 0
    ) {
      roles = user['https://raidchamps.com/app_metadata'].roles;
    } else {
      roles = [];
    }
  } else {
    roles = ['Not Logged-in'];
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Helmet>
        <title>My Profile | RaidChamps.com</title>
        <meta
          name="description"
          content="Learn how RaidChamps.com works and what all the different icons on the champ avatars mean."
        />
        <meta itemProp="name" content="My Profile | RaidChamps.com" />
        <meta
          itemProp="description"
          content="Learn how RaidChamps.com works and what all the different icons on the champ avatars mean."
        />
        <meta itemProp="image" content="https://ik.imagekit.io/raidchamps/_NotExtracted/logo.avif" />
        <meta property="og:url" content="https://raidchamps.com/mychamps" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Profile | RaidChamps.com" />
        <meta
          property="og:description"
          content="Learn how RaidChamps.com works and what all the different icons on the champ avatars mean."
        />
        <meta property="og:image" content="https://ik.imagekit.io/raidchamps/_NotExtracted/logo.avif" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Profile | RaidChamps.com" />
        <meta
          name="twitter:description"
          content="Learn how RaidChamps.com works and what all the different icons on the champ avatars mean."
        />
        <meta name="twitter:image" content="https://ik.imagekit.io/raidchamps/_NotExtracted/logo.avif" />
      </Helmet>
      <div className="profile-page page">
        <div className="container">
          {isAuthenticated ? (
            <>
              <div className="flex">
                <div className="">
                  {console.log(`user`, user)}
                  {user && console.log(`roles`, roles)}
                  <img src={user && user.picture} alt={user && user.name} />
                  <h2>{user && user.name}</h2>
                  <p>{user && user.email}</p>
                </div>
                <div className="">
                  <UserRoles roles={roles} />
                </div>
              </div>
              {user && <Auth0Upgrade user={user} />}
            </>
          ) : (
            <>
              <p className="center">
                You need to <Auth0LoginLogout /> to view this page.
              </p>
            </>
          )}

          <div className="ContentCreatorsFreePowers-wrap">
            <h3 className="yellow">Free Powers for Content Creators</h3>
            <p>
              If you are an active Content Creator that has done a video about this site, send me the link to the video
              and I will give you all the Powers for free in hopes that you keep using the site and do more videos about
              it. Please use the form on the <Link to="/contact">Contact page</Link> to submit your request and make
              sure to include the link to your video.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
