import React, { useState } from 'react';
const pako = require('pako');

// Function to export specific data from an IndexedDB database
async function exportIndexedDB(dbName: string, specificStores: string[]): Promise<any> {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName);
    request.onerror = () => reject("Couldn't open IndexedDB.");
    request.onsuccess = (event) => {
      const db = (event.target as IDBOpenDBRequest).result;
      const allStoreNames = Array.from(db.objectStoreNames) as string[];
      const accountStores = allStoreNames.filter((name) => name.startsWith('account_'));
      const accountId = accountStores.length > 0 ? accountStores[0] : null; // Capture the first accountId

      if (accountStores.length === 0) {
        return reject('No account-related object stores found.');
      }

      const exportedData: any = {};

      const transaction = db.transaction(accountStores, 'readonly');
      transaction.oncomplete = () => resolve({ data: exportedData, accountId });
      transaction.onerror = () => reject("Couldn't read object stores.");

      accountStores.forEach((storeName) => {
        if (specificStores.includes(storeName.split('_').slice(1).join('_'))) {
          const allObjects: any[] = [];
          transaction.objectStore(storeName).openCursor().onsuccess = (event) => {
            const cursor = (event.target as IDBRequest).result;
            if (cursor) {
              allObjects.push(cursor.value);
              cursor.continue();
            } else {
              exportedData[storeName] = allObjects;
            }
          };
        }
      });
    };
  });
}

// Function to generate a shareable link
async function generateShareableLink(setShareableLink: React.Dispatch<React.SetStateAction<string | null>>) {
  try {
    const yourData = await exportIndexedDB('raidchamps', ['academy', 'arena', 'champions']);
    const compressedData = pako.deflate(JSON.stringify(yourData), { to: 'string' });

    const response = await fetch('https://raidchamps.com/.netlify/functions/storeData', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ data: compressedData }),
    });

    if (response.ok) {
      const responseData = await response.json();
      const id = responseData.id;
      const shareableLink = `https://raidchamps.com/mychamps?importData&id=${id}`;
      setShareableLink(shareableLink);
    } else {
      console.error('Failed to store data:', await response.text());
    }
  } catch (error) {
    console.error('An error occurred:', error);
  }
}

const ShareButton: React.FC = () => {
  const [shareableLink, setShareableLink] = useState<string | null>(null);

  const copyToClipboard = () => {
    if (shareableLink) {
      navigator.clipboard
        .writeText(shareableLink)
        .then(() => {
          console.log('Link copied to clipboard');
        })
        .catch((err) => {
          console.error('Could not copy text: ', err);
        });
    }
  };

  return (
    <div>
      <button onClick={() => generateShareableLink(setShareableLink)}>Generate Shareable Link</button>
      {shareableLink && <button onClick={copyToClipboard}>Click to Copy Link</button>}
    </div>
  );
};

export default ShareButton;
