import React, { useCallback } from 'react';
import Tooltip from 'rc-tooltip';
import { loadFilterSettings, resetFilterSettings, saveCurrentFilters } from 'Components/DataView/Helpers';
import { useHotkeysContext } from './HotkeyProvider';

const SaveAndRemoveSavedFiltersButton = () => {
  const { savedFiltersExist, setSavedFiltersExist } = useHotkeysContext();

  const applyFilterSettings = (filterSettings: { [s: string]: unknown } | ArrayLike<unknown>) => {
    const newSearch = Object.entries(filterSettings)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
    window.history.pushState({}, '', `?${newSearch}`);
  };

  const saveFilters = () => {
    saveCurrentFilters();
    setSavedFiltersExist(true);
  };

  const removeSavedFilters = () => {
    resetFilterSettings();
    setSavedFiltersExist(false);
    location.reload();
  };

  // Apply saved filter settings on mount
  React.useEffect(() => {
    const savedFilterSettings = loadFilterSettings();
    if (savedFilterSettings) {
      applyFilterSettings(savedFilterSettings);
      setSavedFiltersExist(true);
    }
  }, []);

  return (
    <div className="center filterButtons-wrap SaveAndRemoveSavedFiltersButton-wrap">
      <button
        onClick={saveFilters}
        title="Clicking this button will save all your current filter settings so that they will be your new default filter settings. So even if you leave the site and return to it later without any query params in the URL, it will load your saved filter settings and add the corresponding query params to the URL. You can then adjust the filters further knowing that you can always use the Reset Filters button or the new Hotkey for it to revert the filter settings back to the ones that you have saved. (If you try to use this button after setting the filters and then switching pages, it will only save the filters that persist in the query params in the URL. But it should work perfectly if you make sure not to switch pages before pressing the button or using the Hotkey. Once the filters are save you can then use the Reset Filters button to reload either of the pages with your saved filter settings. )"
      >
        Save Filters
      </button>
      {savedFiltersExist && (
        <button
          onClick={removeSavedFilters}
          title="Clicking this button will reload the page & revert your saved filter settings back to the default ones where nothing is filtered."
        >
          Remove Saved Filters
        </button>
      )}
    </div>
  );
};

export default SaveAndRemoveSavedFiltersButton;
