import React from 'react';
import { Link } from 'react-router-dom';
import './Contribute.css';
import { Helmet } from 'react-helmet-async';
import { PhotoGallery } from 'Components/Library/PhotoGallery';

export const Contribute: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Contribute | RaidChamps.com</title>
        <meta name="description" content="All the different ways for you to support or contribute to RaidChamps.com." />
        <meta itemProp="name" content="Contribute | RaidChamps.com" />
        <meta
          itemProp="description"
          content="All the different ways for you to support or contribute to RaidChamps.com."
        />
        <meta itemProp="image" content="https://ik.imagekit.io/raidchamps/_NotExtracted/logo.avif" />
        <meta property="og:url" content="https://raidchamps.com/mychamps" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Contribute | RaidChamps.com" />
        <meta
          property="og:description"
          content="All the different ways for you to support or contribute to RaidChamps.com."
        />
        <meta property="og:image" content="https://ik.imagekit.io/raidchamps/_NotExtracted/logo.avif" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Contribute | RaidChamps.com" />
        <meta
          name="twitter:description"
          content="All the different ways for you to support or contribute to RaidChamps.com."
        />
        <meta name="twitter:image" content="https://ik.imagekit.io/raidchamps/_NotExtracted/logo.avif" />
      </Helmet>
      <div className="contribute-page page">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 center">
              <div className="text-center">
                <h1 className="with-underline">Contribute</h1>
              </div>
              <p>
                The best way to contribute to this site is to tell your friends and clan members about it. I am awful at
                promoting my own site, so I need all the help I can get in that department. If you would like to
                contribute financially you can subscribe to one of the Powers available on your{' '}
                <Link to="/profile">Profile</Link> page after you have logged-in to the site.
              </p>
              <p>
                If you would like to contribute further, you can{' '}
                <a href="https://www.patreon.com/raidchamps" target="_blank" rel="noopener">
                  become a Patreon Patron
                </a>{' '}
                or{' '}
                <a href="https://www.paypal.com/donate?hosted_button_id=XTYYNSSB9QXA4" target="_blank" rel="noopener">
                  donate whatever you would like to me via Paypal
                </a>
                . There is also a button in the footer at the bottom of every page that you can click to{' '}
                <a
                  href="https://www.buymeacoffee.com/cmarabate"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Click to view in new tab"
                >
                  buy me a pizza🍕
                </a>
                . On the page it takes you to, there is also a way to subscribe to become a sustaining member very
                similar to{' '}
                <a href="https://www.patreon.com/raidchamps" target="_blank" rel="noopener">
                  Patreon
                </a>
                .
              </p>
              <p>Any amount that you can contribute is greatly appreciated.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
