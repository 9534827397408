export enum RegionTypeId {
  Tower = -1,
  Region1 = 101,
  Region2 = 102,
  Region3 = 103,
  Region4 = 104,
  Region5 = 105,
  Region6 = 106,
  Region7 = 107,
  Region8 = 108,
  Region9 = 109,
  Region10 = 110,
  Region11 = 111,
  Region12 = 112,
  VoidKeep = 201,
  SpiritKeep = 202,
  MagicKeep = 203,
  ForceKeep = 204,
  ArcaneKeep = 205,
  DragonsLair = 206,
  IceGolemCave = 207,
  FireGolemCave = 208,
  SpiderCave = 209,
  MinotaurCave = 210,
  IronTwinsFortressVoid = 211,
  IronTwinsFortressForce = 212,
  IronTwinsFortressMagic = 213,
  IronTwinsFortressSpirit = 214,
  SandDevilsNecropolis = 216,
  PhantomShogunsGrove = 217,
  Arena = 301,

  AllianceBoss = 401,

  //HydraBossNormal = 402, <--- Guess
  //HydraBossHard = 403, <--- Guess
  //HydraBossBrutal = 404, <--- Guess
  //HydraBossNightmare = 405, <--- Guess

  Fraction_BannerLords = 501,
  Fraction_HighElves = 502,
  Fraction_SacredOrder = 503,
  Fraction_OgrynTribes = 505,
  Fraction_LizardMen = 506,
  Fraction_Skinwalkers = 507,
  Fraction_Orcs = 508,
  Fraction_Demonspawn = 509,
  Fraction_UndeadHordes = 510,
  Fraction_DarkElves = 511,
  Fraction_KnightsRevenant = 512,
  Fraction_Barbarians = 513,
  Fraction_SylvanWatchers = 514,
  Fraction_Shadowkin = 515,
  Fraction_Dwarves = 516,

  Arena3x3 = 601,

  //DoomTowerWaves1 = 701,
  DoomTowerNormal10MagmaDragon1 = 7011010,
  DoomTowerNormal20ScarabKing1 = 7011020,
  DoomTowerNormal30NetherSpider1 = 7011030,
  DoomTowerNormal40FrostSpider1 = 7011040,
  DoomTowerNormal50MagmaDragon1 = 7011050,
  DoomTowerNormal60ScarabKing1 = 7011060,
  DoomTowerNormal70NetherSpider1 = 7011070,
  DoomTowerNormal80FrostSpider1 = 7011080,
  DoomTowerNormal90MagmaDragon1 = 7011090,
  DoomTowerNormal100ScarabKing1 = 7011100,
  DoomTowerNormal110NetherSpider1 = 7011110,
  DoomTowerNormal120FrostSpider1 = 7011120,

  DoomTowerHard10MagmaDragon1 = 7012010,
  DoomTowerHard20ScarabKing1 = 7012020,
  DoomTowerHard30NetherSpider1 = 7012030,
  DoomTowerHard40FrostSpider1 = 7012040,
  DoomTowerHard50MagmaDragon1 = 7012050,
  DoomTowerHard60ScarabKing1 = 7012060,
  DoomTowerHard70NetherSpider1 = 7012070,
  DoomTowerHard80FrostSpider1 = 7012080,
  DoomTowerHard90MagmaDragon1 = 7012090,
  DoomTowerHard100ScarabKing1 = 7012100,
  DoomTowerHard110NetherSpider1 = 7012110,
  DoomTowerHard120FrostSpider1 = 7012120,

  //DoomTowerWaves2 = 702,
  DoomTowerNormal10MagmaDragon2 = 7021010,
  DoomTowerNormal20CelestialGriffin2 = 7021020,
  DoomTowerNormal30NetherSpider2 = 7021030,
  DoomTowerNormal40EternalDragon2 = 7021040,
  DoomTowerNormal50MagmaDragon2 = 7021050,
  DoomTowerNormal60CelestialGriffin2 = 7021060,
  DoomTowerNormal70NetherSpider2 = 7021070,
  DoomTowerNormal80EternalDragon2 = 7021080,
  DoomTowerNormal90MagmaDragon2 = 7021090,
  DoomTowerNormal100CelestialGriffin2 = 7021100,
  DoomTowerNormal110NetherSpider2 = 7021110,
  DoomTowerNormal120EternalDragon2 = 7021120,

  DoomTowerHard10MagmaDragon2 = 7022010,
  DoomTowerHard20CelestialGriffin2 = 7022020,
  DoomTowerHard30NetherSpider2 = 7022030,
  DoomTowerHard40EternalDragon2 = 7022040,
  DoomTowerHard50MagmaDragon2 = 7022050,
  DoomTowerHard60CelestialGriffin2 = 7022060,
  DoomTowerHard70NetherSpider2 = 7022070,
  DoomTowerHard80EternalDragon2 = 7022080,
  DoomTowerHard90MagmaDragon2 = 7022090,
  DoomTowerHard100CelestialGriffin2 = 7022100,
  DoomTowerHard110NetherSpider2 = 7022110,
  DoomTowerHard120EternalDragon2 = 7022120,

  //DoomTowerWaves3 = 703,
  DoomTowerNormal10Dreadhorn3 = 7031010,
  DoomTowerNormal20CScarabKing3 = 7031020,
  DoomTowerNormal30CelestialGriffin3 = 7031030,
  DoomTowerNormal40DarkFae3 = 7031040,
  DoomTowerNormal50Dreadhorn3 = 7031050,
  DoomTowerNormal60CScarabKing3 = 7031060,
  DoomTowerNormal70CelestialGriffin3 = 7031070,
  DoomTowerNormal80DarkFae3 = 7031080,
  DoomTowerNormal90Dreadhorn3 = 7031090,
  DoomTowerNormal100CScarabKing3 = 7031100,
  DoomTowerNormal110CelestialGriffin3 = 7031110,
  DoomTowerNormal120DarkFae3 = 7031120,

  DoomTowerHard10Dreadhorn3 = 7032010,
  DoomTowerHard20CScarabKing3 = 7032020,
  DoomTowerHard30CelestialGriffin3 = 7032030,
  DoomTowerHard40DarkFae3 = 7032040,
  DoomTowerHard50Dreadhorn3 = 7032050,
  DoomTowerHard60CScarabKing3 = 7032060,
  DoomTowerHard70CelestialGriffin3 = 7032070,
  DoomTowerHard80DarkFae3 = 7032080,
  DoomTowerHard90Dreadhorn3 = 7032090,
  DoomTowerHard100CScarabKing3 = 7032100,
  DoomTowerHard110CelestialGriffin3 = 7032110,
  DoomTowerHard120DarkFae3 = 7032120,

  //LiveArena = 801, <--- Guess
}
