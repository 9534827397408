import { mergeStyles } from '@fluentui/merge-styles';
import { getRarityColorForRating } from 'Components/DataView/Helpers';
import React from 'react';
import * as Styles from './Rating.styles';
import './StarRating.css';

export interface RatingProps {
  totalStars?: number;
  filledStars: number | undefined;
  ascendedStars?: number;
  awakenedStars?: number;
  className?: string;
  size?: 'small' | 'normal' | 'large';
  color?: string | undefined;
  style?: React.CSSProperties;
}

const filledStar = '★';
const emptyStar = '☆';

export const StarRating: React.FC<RatingProps> = ({
  totalStars,
  filledStars,
  ascendedStars,
  awakenedStars,
  className,
  style,
  size,
  color,
}) => {
  let empty = (totalStars || 6) - (filledStars || 0);
  let filled = (filledStars || 0) - (ascendedStars || 0);
  let awakened = awakenedStars || 0;
  return (
    <div
      /* className={mergeStyles(Styles[size || 'normal'], className)} */
      style={{ ...style, /* color: color ?? style?.color, */ display: 'inline-block' }}
      className={`${mergeStyles(Styles[size || 'normal'], className)} ${className} ${getRarityColorForRating(
        filledStars
      )}`}
    >
      <span className={`${Styles.ascended}`}>{''.padEnd(ascendedStars || 0, filledStar)}</span>
      <span className={`${Styles.awakened}`}>{''.padEnd(awakened || 0, filledStar)}</span>
      <span className={`unascendedStars`}>
        {''.padEnd(filled, filledStar)}
        {''.padEnd(empty, emptyStar)}
      </span>
    </div>
  );
};
