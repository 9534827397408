import React from 'react';
import HotkeyComponent from './HotkeyComponent';

const SaveFiltersHotkey = () => {
  return (
    <div className="center filterButtons-wrap">
      <p className="small" style={{ width: 'fit-content', margin: '5px auto 0' }}>
        Set Hotkey to Save Filters to be your new default settings:
      </p>
      <HotkeyComponent storageKey="saveFiltersHotkey" />
      <p className="small" style={{ width: 'fit-content', margin: '5px auto 0' }}>
        This hotkey will do the same thing as the "Save Filters" button.
      </p>
    </div>
  );
};

export default SaveFiltersHotkey;
