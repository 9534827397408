import { mergeStyles } from '@fluentui/merge-styles';

export const root = mergeStyles({
  visibility: 'none',
  overflow: 'hidden',
  pointerEvents: 'none',
  '&.toggle': {
    visibility: 'visible',
  },
  '& > div': {
    pointerEvents: 'auto',
    opacity: 0,
    transition: 'opacity 0.5s linear, transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1)',
  },
  '&.toggle > div': {
    transform: 'translateX(0%)',
    opacity: 1,
  },
});

export const leftAnimate = mergeStyles({
  '> div': {
    transform: 'translateX(100%)',
  },
});

export const rightAnimate = mergeStyles({
  '> div': {
    transform: 'translateX(-100%)',
  },
});
