import React from 'react';
import './BookRating.css';
import { getHeroRating } from '../../Data';
import { NextGenImage } from '../../Components/Library';

export interface BookRatingProps {
  slug: string | undefined;
}
export const BookRating: React.FC<BookRatingProps> = ({ slug }) => {
  const ratingValue = getHeroRating(slug)?.detailed?.hellhades?.bookRating || 0;
  const bookPriority = getHeroRating(slug)?.detailed?.hellhades?.bookPriority || 0;

  return (
    <div className="hit-BookRating overAvatarItem info">
      {ratingValue <= 5 ? (
        <div className="hit-hellHadesBookValue-img-wrap semi-transparent">
          <NextGenImage
            whichDirectory="_NotExtracted/myCreations/Books"
            whichImage="Skillbook_Rare"
            alt={ratingValue ? `Book Priority: ` + bookPriority + ` | Book Value: ` + ratingValue : ''}
            title={ratingValue ? `Book Priority: ` + bookPriority + ` | Book Value: ` + ratingValue : ''}
          />
        </div>
      ) : (
        <div className="hidden"></div>
      )}
      {ratingValue >= 6 && ratingValue <= 7.9 ? (
        <div className="hit-hellHadesBookValue-img-wrap">
          <NextGenImage
            whichDirectory="_NotExtracted/myCreations/Books"
            whichImage="Skillbook_Rare"
            alt={ratingValue ? `Book Priority: ` + bookPriority + ` | Book Value: ` + ratingValue : ''}
            title={ratingValue ? `Book Priority: ` + bookPriority + ` | Book Value: ` + ratingValue : ''}
          />
        </div>
      ) : (
        <div className="hidden"></div>
      )}
      {ratingValue >= 8 && ratingValue <= 9.9 ? (
        <div className="hit-hellHadesBookValue-img-wrap">
          <NextGenImage
            whichDirectory="_NotExtracted/myCreations/Books"
            whichImage="Skillbook_Epic"
            alt={ratingValue ? `Book Priority: ` + bookPriority + ` | Book Value: ` + ratingValue : ''}
            title={ratingValue ? `Book Priority: ` + bookPriority + ` | Book Value: ` + ratingValue : ''}
          />
        </div>
      ) : (
        <div className="hidden"></div>
      )}
      {ratingValue >= 10 ? (
        <div className="hit-hellHadesBookValue-img-wrap">
          <NextGenImage
            whichDirectory="_NotExtracted/myCreations/Books"
            whichImage="Skillbook_Legendary"
            alt={ratingValue ? `Book Priority: ` + bookPriority + ` | Book Value: ` + ratingValue : ''}
            title={ratingValue ? `Book Priority: ` + bookPriority + ` | Book Value: ` + ratingValue : ''}
          />
        </div>
      ) : (
        <div className="hidden"></div>
      )}
    </div>
  );
};
