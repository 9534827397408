import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, Slider } from '@mui/material';
import { FilterDescriptor, useListView, useRootStore } from '../../../Model';
import { ChampionItem, DataItem } from 'Types';
import { action, makeObservable, observable, reaction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { ListViewStore } from '../../../Model/ListViewStore';
import { loadFilterSettings } from '../Helpers';

export interface RangeFilterProps<T extends DataItem = DataItem> {
  id: string;
  min: number;
  max: number;
  label: string;
  minWidth?: string | number;
  canHandle: (opts: any) => boolean;
  filter: (min: number, max: number, item: any) => boolean;
}

export class ObservableRangeFilter<T extends DataItem = DataItem> implements FilterDescriptor<T> {
  private start: number;
  private end: number;

  // Add 'id' as a property.
  private id: string;
  //Even though it looks like 'id' is declared but its value is never read... it is needed to make it part of the filter objects. This make it so that filter.id in updateURLWithFilterSettings can access the id of each filter. Without it this.id will not explicitly be declared as an instance property, and thus will scope only to the constructor function itself. It won't be part of the filter objects when they are passed to updateURLWithFilterSettings, causing filter.id to be undefined.

  // Add 'defaultValue' as properties.
  private defaultValue: [number, number];

  public getURLRepresentation() {
    return `${this.start}-${this.end}`;
  }

  constructor(private readonly props: RangeFilterProps<T>) {
    // Set 'id' from 'props'.
    this.id = props.id;
    this.start = props.min;
    this.end = props.max;
    this.defaultValue = [props.min, props.max]; // set default value
    const searchParams = new URLSearchParams(document.location.search);
    let value = searchParams.get(this.props.id);
    if (value) {
      [this.start, this.end] = value.split('-', 2).map(Number) as [number, number];
    } else {
      const filterSettings = loadFilterSettings();
      if (filterSettings && filterSettings[this.props.id]) {
        [this.start, this.end] = filterSettings[this.props.id].split('-', 2).map(Number) as [number, number];
      }
    }
    makeObservable<ObservableRangeFilter<T>, 'start' | 'end' | 'handleChange'>(this, {
      start: observable,
      end: observable,
      canHandle: false,
      filterFn: false,
      render: false,
      handleChange: action,
    });
  }

  public isDefaultValue() {
    return this.start === this.defaultValue[0] && this.end === this.defaultValue[1];
  }

  get Component() {
    return this.render;
  }

  get canHandle() {
    return this.props.canHandle as any;
  }

  filterFn = (listView: ListViewStore, item: T) => {
    if (!this.props.canHandle(listView.options)) {
      return true;
    }
    return this.props.filter(this.start, this.end, item);
  };

  private handleChange = (event: any, value: number | number[]) => {
    if (typeof value === 'number') {
      return;
    }
    this.start = value[0]!;
    this.end = value[1]!;
  };

  render = observer(() => {
    if (!this.props.canHandle(useListView().options)) {
      return null;
    }

    const { min, max, label, id, minWidth } = this.props;

    // URL Query Params
    const [searchParams, setSearchParams] = useSearchParams();
    React.useEffect(() => {
      const params = Object.fromEntries(searchParams);
      if (this.start !== min || this.end !== max) {
        params[id] = [this.start, this.end].join('-');
      } else if (params[id]) {
        delete params[id];
      } else {
        return;
      }
      setSearchParams(params);
    }, [this.start, this.end, min, max]);

    return (
      <Box sx={{ textAlign: 'center', marginLeft: '15px', marginRight: '15px', minWidth: minWidth || 120 }}>
        <div style={{ whiteSpace: 'nowrap' }}>{label}</div>
        <Slider
          key={`slider-${id}`}
          id={`${id}-select`}
          max={max}
          min={min}
          marks={[
            { value: min, label: min },
            { value: max, label: max },
          ]}
          step={1}
          value={[this.start, this.end]} // use current start and end values
          valueLabelDisplay="auto"
          onChange={this.handleChange}
        />
      </Box>
    );
  });
}
