import { ArenaLeagueId } from '@raid-toolkit/webclient';
import { getRankNumber } from 'Components/DataView/Helpers';
import { getStaticData } from '../Data';
import {
  ArtifactType,
  ArtifactStatBonus,
  ChampionItem,
  emptyStats,
  MasteryKindId,
  rankToNumber,
  StatBonus,
  StatKind,
  StatKindKeys,
  Stats,
} from '../Types';
import { AccountStore } from './AccountStore';
import { MultiplierLookup } from './Multipliers';

const empowermentBonuses: Record<number, Partial<Stats>> = {
  1: { health: 0.1, attack: 0.1, defense: 0.1, accuracy: 15, resistance: 15 },
  2: { health: 0.1, attack: 0.1, defense: 0.1, accuracy: 10, resistance: 10, speed: 10 },
  3: { health: 0.1, attack: 0.1, defense: 0.1, accuracy: 20, resistance: 20 },
  4: {
    health: 0.1,
    attack: 0.1,
    defense: 0.1,
    accuracy: 10,
    resistance: 10,
    speed: 5,
    criticalchance: 10,
    criticalheal: 30,
    criticaldamage: 30,
  },
};

function getBlessingBonuses(rarity: string): Record<string, Record<number, StatBonus[]>> {
  //console.log(`rarity`, rarity);

  const rareBlessingStatBoosts = {
    1: [
      {
        statKind: StatKind.health,
        value: rarity === 'legendary' ? 7500 : rarity === 'epic' ? 4500 : 2250,
        absolute: true,
      },
    ],
    2: [
      {
        statKind: StatKind.attack,
        value: rarity === 'legendary' ? 750 : rarity === 'epic' ? 600 : 450,
        absolute: true,
      },
    ],
    3: [
      {
        statKind: StatKind.defense,
        value: rarity === 'legendary' ? 600 : rarity === 'epic' ? 450 : 300,
        absolute: true,
      },
    ],
    4: [
      {
        statKind: StatKind.criticalDamage,
        value: rarity === 'legendary' ? 0.38 : rarity === 'epic' ? 0.3 : 0.23,
        absolute: false,
      },
      {
        statKind: StatKind.health,
        value: 1000,
        absolute: true,
      },
    ],
    5: [
      {
        statKind: StatKind.accuracy,
        value: rarity === 'legendary' ? 75 : rarity === 'epic' ? 60 : 40,
        absolute: true,
      },
      {
        statKind: StatKind.resistance,
        value: rarity === 'legendary' ? 75 : rarity === 'epic' ? 60 : 40,
        absolute: true,
      },
    ],
    6: [{ statKind: StatKind.speed, value: rarity === 'legendary' ? 15 : rarity === 'epic' ? 10 : 7, absolute: true }],
  };

  const epicBlessingStatBoosts = {
    1: [{ statKind: StatKind.health, value: rarity !== 'legendary' ? 4500 : 7500, absolute: true }],
    2: [{ statKind: StatKind.attack, value: rarity !== 'legendary' ? 600 : 750, absolute: true }],
    3: [{ statKind: StatKind.defense, value: rarity !== 'legendary' ? 450 : 600, absolute: true }],
    4: [
      { statKind: StatKind.health, value: 1000, absolute: true },
      {
        statKind: StatKind.criticalDamage,
        value: rarity !== 'legendary' ? 0.3 : 0.38,
        absolute: false,
      },
    ],
    5: [
      {
        statKind: StatKind.accuracy,
        value: rarity !== 'legendary' ? 75 : 60,
        absolute: true,
      },
      {
        statKind: StatKind.resistance,
        value: rarity !== 'legendary' ? 75 : 60,
        absolute: true,
      },
    ],
    6: [{ statKind: StatKind.speed, value: rarity !== 'legendary' ? 10 : 15, absolute: true }],
  };

  const legendaryBlessingStatBoosts = {
    1: [{ statKind: StatKind.health, value: 7500, absolute: true }],
    2: [{ statKind: StatKind.attack, value: 750, absolute: true }],
    3: [{ statKind: StatKind.defense, value: 600, absolute: true }],
    4: [
      { statKind: StatKind.health, value: 1000, absolute: true },
      {
        statKind: StatKind.criticalDamage,
        value: 0.38,
        absolute: false,
      },
    ],
    5: [
      {
        statKind: StatKind.accuracy,
        value: 60,
        absolute: true,
      },
      {
        statKind: StatKind.resistance,
        value: 60,
        absolute: true,
      },
    ],
    6: [{ statKind: StatKind.speed, value: 15, absolute: true }],
  };

  return {
    // ---- Light Blessings ----
    // Indomitable Spirit
    Courage: rareBlessingStatBoosts,
    // Miracle Heal
    AdvancedHeal: rareBlessingStatBoosts,
    // Iron Will
    Vanguard: epicBlessingStatBoosts,
    // Heavencast
    EnhancedWeapon: epicBlessingStatBoosts,
    // Intimidating Presence
    LeadershipDomination: legendaryBlessingStatBoosts,
    // Lightning Cage
    LightOrbs: legendaryBlessingStatBoosts,
    // ---- Dark Blessings ----
    // Dark Resolve
    Fearless: rareBlessingStatBoosts,
    // Phantom Touch
    MagicOrb: rareBlessingStatBoosts,
    // Cruelty
    Exterminator: epicBlessingStatBoosts,
    // Lethal Dose
    ToxicBlade: epicBlessingStatBoosts,
    // Temporal Chains
    TimeSlowdown: legendaryBlessingStatBoosts,
    // Ward of the Fallen
    Necromancy: legendaryBlessingStatBoosts,
    // ---- War Blessings ----
    // Hero's Soul
    Amplification: rareBlessingStatBoosts,
    // Faultless Defense
    Adaptation: rareBlessingStatBoosts,
    // Commanding Presence
    AdvancedLeadership: epicBlessingStatBoosts,
    // Chainbreaker
    ChainBreaker: epicBlessingStatBoosts,
    // Life Harvest
    SoulDrinker: legendaryBlessingStatBoosts,
    // Soul Reap
    Execute: legendaryBlessingStatBoosts,
    // ---- Chaos Blessings ----
    // Carapace
    Carapace: rareBlessingStatBoosts,
    // Survival Instinct
    Agility: rareBlessingStatBoosts,
    // Crushing Rend
    Penetrator: epicBlessingStatBoosts,
    // Incinerate
    MagicFlame: epicBlessingStatBoosts,
    // Polymorph
    Polymorph: legendaryBlessingStatBoosts,
    // Brimstone
    Meteor: legendaryBlessingStatBoosts,
  };
}

export enum StatSource {
  base = 'Basic Stats',
  masteries = 'Masteries',
  gear = 'Artifacts',
  gearSets = 'Artifact Sets',
  arena = 'Arena',
  greatHall = 'Great Hall',
  empowerment = 'Empowerment',
  blessing = 'Blessing',
  factionGuardians = 'Faction Guardians',
  total = 'Total',
  // clan = 'Clan',
}

export interface ArtifactSetStatBonus extends StatBonus {
  setCount?: number;
}

export interface StatsSnapshot {
  baseStats: Stats;
  effectiveStats: Stats;
  statSources: Record<StatSource, Stats>;
}

function getBaseStat(statKind: StatKind, rank: number, level: number, baseValue: number) {
  switch (statKind) {
    case StatKind.health:
      return MultiplierLookup[rank]![level]! * baseValue * 15.003713524358135;
    case StatKind.attack:
    case StatKind.defense:
      return MultiplierLookup[rank]![level]! * baseValue;
    default:
      return baseValue;
  }
}

function getAbsoluteStatIncrease(statKind: StatKind, rawValue: number) {
  switch (statKind) {
    case StatKind.criticalChance:
    case StatKind.criticalDamage:
    case StatKind.criticalHeal:
      return rawValue * 100;
    default:
      return rawValue;
  }
}

export class HeroStatsBuilder {
  private Masteries: Set<MasteryKindId> = new Set();
  private get HasLoreOfSteel(): boolean {
    return this.Masteries.has(MasteryKindId.LoreofSteel);
  }

  public readonly Snapshot: StatsSnapshot = (() => {
    const effectiveStats = emptyStats();
    return {
      baseStats: emptyStats(),
      effectiveStats,
      statSources: {
        [StatSource.base]: emptyStats(),
        [StatSource.gear]: emptyStats(),
        [StatSource.gearSets]: emptyStats(),
        [StatSource.greatHall]: emptyStats(),
        [StatSource.arena]: emptyStats(),
        [StatSource.masteries]: emptyStats(),
        [StatSource.factionGuardians]: emptyStats(),
        [StatSource.empowerment]: emptyStats(),
        [StatSource.blessing]: emptyStats(),
        [StatSource.total]: effectiveStats,
        // [StatSource.clan]: emptyStats(),
      },
    };
  })();

  constructor({ instance, type }: ChampionItem, account?: AccountStore) {
    const level = instance?.level ?? 60;
    const rank = instance ? rankToNumber(instance.rank) : 6;
    for (const statKind of StatKindKeys) {
      const baseStat = getBaseStat(statKind, rank, level, type.baseStats[statKind]);
      this.Snapshot.effectiveStats[statKind] = baseStat;
      this.Snapshot.baseStats[statKind] = baseStat;
      this.Snapshot.statSources[StatSource.base][statKind] = baseStat;
    }
    if (!instance || !account) return;
    const greatHallBonus = account.arena.greatHallBonuses.find((bonus) => bonus.affinity === type.affinity);
    if (greatHallBonus) this.applyBonuses(StatSource.greatHall, greatHallBonus.bonus);

    const arenaLeague = getStaticData().arenaLeagues[account.arena.classic.league as ArenaLeagueId];
    if (arenaLeague) {
      this.applyBattleStats(StatSource.arena, arenaLeague);
    }

    // empowerment
    if (instance.empowerLevel > 0) {
      for (let level = 1; level <= instance.empowerLevel; ++level) {
        const empowerBonus = empowermentBonuses[level]!;
        this.applyBattleStats(StatSource.empowerment, empowerBonus);
      }
    }

    // blessing
    if (instance && instance.blessing && instance.awakenRank && type.rarity) {
      this.applyBlessingBonuses(instance.blessing, getRankNumber(instance.awakenRank), type.rarity);
    }

    // faction guardians
    const factionBonuses = account.guardians[type.faction]?.[type.rarity]?.bonuses;
    factionBonuses && this.applyBonuses(StatSource.factionGuardians, factionBonuses);

    if (instance.masteries) this.applyMasteries(instance.masteries);

    if (instance.equippedArtifactIds) {
      const equippedArtifacts = Object.values(instance.equippedArtifactIds)
        .map<ArtifactType>((artifactId) => account.artifacts[artifactId]!)
        .filter(Boolean);
      this.applyArtifacts(equippedArtifacts, rank, level);
      const setCounts = Object.entries(getStaticData().artifactSets)
        .map(([setKindId, setKind]) => ({
          setKindId,
          setKind,
          count: equippedArtifacts.filter((artifact) => artifact.setKindId === setKindId).length / setKind!.set_size,
        }))
        .filter(({ count }) => count >= 1);
      for (const { setKind, count } of setCounts) {
        setKind.bonuses && this.applyArtifactSetBonuses(count, setKind.bonuses);
      }
    }
  }

  addStat(source: StatSource, statKind: StatKind, value: number) {
    //console.log('Adding stat:', source, statKind, value);

    this.Snapshot.effectiveStats[statKind] += value;
    this.Snapshot.statSources[source][statKind] += value;
  }

  applyBlessingBonuses(blessing: string, awakenRank: number, rarity: string) {
    const blessingBonuses = getBlessingBonuses(rarity);
    const bonuses = blessingBonuses[blessing];
    if (!bonuses) return;

    for (let rank = 1; rank <= awakenRank; rank++) {
      const statBonuses = bonuses[rank];
      if (!statBonuses) continue;

      statBonuses.forEach((bonus) => {
        this.applyStatBonus(StatSource.blessing, bonus);
      });
    }
  }

  applyMasteries(masteries: MasteryKindId[]) {
    for (const mastery of masteries) {
      this.Masteries.add(mastery);
      switch (mastery) {
        case MasteryKindId.BladeDisciple:
          this.addStat(StatSource.masteries, StatKind.attack, 75);
          continue;
        case MasteryKindId.DeadlyPrecision:
          this.addStat(StatSource.masteries, StatKind.criticalChance, 5);
          continue;
        case MasteryKindId.KeenStrike:
          this.addStat(StatSource.masteries, StatKind.criticalDamage, 10);
          continue;
        case MasteryKindId.FlawlessExecution:
          this.addStat(StatSource.masteries, StatKind.criticalDamage, 20);
          continue;
        case MasteryKindId.ToughSkin:
          this.addStat(StatSource.masteries, StatKind.defence, 75);
          continue;
        case MasteryKindId.IronSkin:
          this.addStat(StatSource.masteries, StatKind.defence, 200);
          continue;
        case MasteryKindId.Defiant:
          this.addStat(StatSource.masteries, StatKind.resistance, 10);
          continue;
        case MasteryKindId.Unshakeable:
          this.addStat(StatSource.masteries, StatKind.resistance, 50);
          continue;
        case MasteryKindId.PinpointAccuracy:
          this.addStat(StatSource.masteries, StatKind.accuracy, 10);
          continue;
        case MasteryKindId.EagleEye:
          this.addStat(StatSource.masteries, StatKind.accuracy, 50);
          continue;
      }
    }
  }

  applyBonuses(source: StatSource, bonuses: StatBonus[]) {
    for (const bonus of bonuses) {
      const statKind = bonus.statKind;
      if (!statKind) continue;
      this.addStat(
        source,
        statKind,
        bonus.absolute
          ? getAbsoluteStatIncrease(statKind, bonus.value)
          : this.getFactorStatIncrease(statKind, bonus.value)
      );
    }
  }

  applyArtifactSetBonuses(numberOfSets: number, bonuses: ArtifactSetStatBonus[]) {
    for (const bonus of bonuses) {
      const statKind = bonus.statKind;
      if (!statKind) continue;
      // variable sets (stoneskin, protection)
      if (numberOfSets < (bonus.setCount ?? 0)) continue;
      const setCountMultiplier = bonus.setCount == undefined ? numberOfSets : 1;
      const masteriesMultiplier = this.HasLoreOfSteel ? 1.15 : 1;
      this.addStat(
        StatSource.gearSets,
        statKind,
        bonus.absolute
          ? getAbsoluteStatIncrease(statKind, setCountMultiplier * bonus.value * masteriesMultiplier)
          : this.getFactorStatIncrease(statKind, setCountMultiplier * bonus.value * masteriesMultiplier)
      );
    }
  }

  applyArtifactBonuses(bonus: ArtifactStatBonus, rank: number, level: number) {
    const statKind = bonus.statKind;
    if (!statKind) return;
    const value = bonus.value + bonus.glyphPower;
    this.applyStatBonus(StatSource.gear, { ...bonus, value }, rank, level);
  }

  applyArtifacts(artifacts: ArtifactType[], rank: number, level: number) {
    for (const artifact of artifacts) {
      this.applyStatBonus(StatSource.gear, artifact.primaryBonus, rank, level);
      if (artifact.secondaryBonuses) {
        for (var bonus of artifact.secondaryBonuses) this.applyStatBonus(StatSource.gear, bonus, rank, level);
      }
      if (artifact.ascendLevel > 0) {
        const ascendBonus: StatBonus = {
          statKind: artifact.ascendBonusKind.toLowerCase() as StatKind, // Convert to lowercase
          absolute: artifact.ascendBonusIsAbsolute,
          value: artifact.ascendBonusValue,
        };
        this.applyStatBonus(StatSource.gear, ascendBonus, rank, level);
      }
    }
  }

  applyStatBonus(source: StatSource, bonus: StatBonus) {
    const statKind = bonus.statKind;
    if (!statKind) return;
    let value = bonus.value;

    // Check if the statKind is HP, Attack, or Defense, and if the bonus is absolute
    if (
      bonus.absolute &&
      (statKind === StatKind.health || statKind === StatKind.attack || statKind === StatKind.defense)
    ) {
      // The value is already scaled in the Snapshot.baseStats property, so we can just use the bonus value directly
      value = bonus.value;
    }

    if (bonus.glyphPower) {
      value = bonus.value + bonus.glyphPower;
    }

    this.addStat(
      source,
      statKind,
      bonus.absolute ? getAbsoluteStatIncrease(statKind, value) : this.getFactorStatIncrease(statKind, value)
    );
  }

  applyBattleStats(source: StatSource, battleStats: Partial<Stats>) {
    for (const statKind of StatKindKeys) {
      if (!battleStats[statKind]) continue;
      this.addStat(source, statKind, this.getBattleStatIncrease(statKind, battleStats[statKind]!));
    }
  }

  getFactorStatIncrease(statKind: StatKind, rawValue: number) {
    switch (statKind) {
      case StatKind.criticalChance:
      case StatKind.criticalDamage:
      case StatKind.criticalHeal:
        return rawValue * 100;
      default:
        return this.Snapshot.baseStats[statKind] * rawValue;
    }
  }

  getBattleStatIncrease(statKind: StatKind, rawValue: number) {
    switch (statKind) {
      case StatKind.health:
      case StatKind.attack:
      case StatKind.defence:
        return this.getFactorStatIncrease(statKind, rawValue);
      default:
        return rawValue;
    }
  }
}
