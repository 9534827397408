import * as React from 'react';
import html2canvas from 'html2canvas';
import Tooltip from 'rc-tooltip';
import { slugify } from 'Data/Updater/Utilities';
/* I changed this to 'any' because the problems tab was complaining about length not existing on ChampionItem and I got even more errors when I tried adding it
import { ChampionItem } from 'Types';

export interface DownloadMyTeamImageProps {
  value: ChampionItem;
} */

export interface DownloadMyTeamImageProps {
  value: any;
  locationText: string;
  slots: number;
  presetsSelectionCount: number;
}

export const DownloadMyTeamImage: React.FC<DownloadMyTeamImageProps> = ({
  value,
  locationText,
  slots,
  presetsSelectionCount,
}) => {
  const [loading, setLoading] = React.useState(false);

  //   console.log(`DownloadMyTeamImage: value`, value);
  //   console.log(`DownloadMyTeamImage: value.length`, value.length);

  let canvas = document.getElementById('screenshotArea');
  let innerWrap = document.getElementById('screenshotArea-innerWrap');

  /* TODO: Find more uses for html2canvas */

  /* 6 Columns */
  let innerWrapWidth: number = 2785;
  /* 1 Row */
  let innerWrapHeight: number = 830;
  if (innerWrap?.scrollWidth) {
    //     console.log('This never gets triggered for some reason');
    innerWrapWidth = innerWrap.scrollWidth;
  }
  if (innerWrap?.scrollHeight) {
    innerWrapHeight = innerWrap.scrollHeight;
  } else {
    /* 1 Row */
    if (value.length <= 6) {
      innerWrapHeight = 840;
    }

    /* +745 every row */

    /* 2 Rows */
    if (value.length > 6) {
      innerWrapHeight = 1580;
    }
    /* 3 Rows */
    if (value.length > 12) {
      innerWrapHeight = 2325;
    }
    /* 4 Rows */
    if (value.length > 18) {
      innerWrapHeight = 3070;
    }
    /* 5 Rows */
    if (value.length > 24) {
      innerWrapHeight = 3815;
    }
    /* Added an extra 10 height to the rest of these */
    /* 6 Rows */
    if (value.length > 30) {
      innerWrapHeight = 4570;
    }
    /* 7 Rows */
    if (value.length > 36) {
      innerWrapHeight = 5315;
    }
    /* 8 Rows */
    if (value.length > 42) {
      innerWrapHeight = 6060;
    }
    /* 9 Rows */
    if (value.length > 48) {
      innerWrapHeight = 6805;
    }
    /* 10 Rows */
    if (value.length > 54) {
      innerWrapHeight = 7550;
    }
  }
  console.log(`slots`, slots);
  console.log(`locationText`, locationText);
  if (slots > 0) {
    if (slots === 4) {
      //Special Height for 3v3 Arena
      innerWrapHeight = 2325;
      //Special height for Classic Arena
      if (locationText === 'Classic Arena') {
        innerWrapHeight = 840;
      }
    }
    if (slots >= 5) {
      //Special Height for selecting all other teams from presets
      if (presetsSelectionCount === 1) {
        innerWrapHeight = 840;
      }
      if (presetsSelectionCount === 2) {
        innerWrapHeight = 1580;
      }
      if (presetsSelectionCount === 3) {
        innerWrapHeight = 2325;
      }
      if (presetsSelectionCount === 4) {
        innerWrapHeight = 3070;
      }
      if (presetsSelectionCount === 5) {
        innerWrapHeight = 3815;
      }
      if (presetsSelectionCount === 6) {
        innerWrapHeight = 4570;
      }
      if (presetsSelectionCount === 7) {
        innerWrapHeight = 5315;
      }
      if (presetsSelectionCount === 8) {
        innerWrapHeight = 6060;
      }
      if (presetsSelectionCount === 9) {
        innerWrapHeight = 6805;
      }
      if (presetsSelectionCount === 10) {
        innerWrapHeight = 7550;
      }
    }
  }

  /* Not sure why I can't use the width of the innerWrap instead of doing all this here... */
  if (value.length === 1) {
    innerWrapWidth = 485;
  }
  if (value.length === 2) {
    innerWrapWidth = 940;
  }
  if (value.length === 3) {
    innerWrapWidth = 1405;
  }
  if (value.length === 4) {
    innerWrapWidth = 1865;
  }
  if (value.length === 5) {
    innerWrapWidth = 2320;
  }
  if (value.length >= 6) {
    innerWrapWidth = 2785;
  }

  if (slots === 4) {
    innerWrapWidth = 1865;
  }

  console.log(`innerWrapWidth`, innerWrapWidth);
  console.log(`innerWrapHeight`, innerWrapHeight);

  const printDocument = () => {
    setLoading(true);
    setTimeout(() => {
      if (canvas !== null) {
        html2canvas(canvas, {
          width: innerWrapWidth,
          height: innerWrapHeight,
          backgroundColor: '#525151',
        }).then(function (canvas) {
          var a = document.createElement('a');
          a.href = canvas.toDataURL('image/png');
          a.download = `RaidChamps.com${locationText ? '-' : '-ShareMyTeam'}${locationText}.png`;
          a.click();
          setLoading(false);
        });
      }
    }, 0);
  };

  return (
    <>
      <button id="downloadImageBtn" onClick={printDocument} disabled={loading}>
        {loading ? 'Generating Image...' : 'Download Image'}
      </button>
    </>
  );
};
