import { RootStoreContext } from 'Model';
import { RootStore } from 'Model/RootStore';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HotkeyProvider, renderRoutes } from './Components';

import './Styles/main.css';

const rootStore = new RootStore();

ReactDOM.render(
  <RootStoreContext.Provider value={rootStore}>
    <BrowserRouter>
      <HotkeyProvider>{renderRoutes()}</HotkeyProvider>
    </BrowserRouter>
  </RootStoreContext.Provider>,
  document.getElementById('root') as HTMLElement
);
