import React, { ReactNode, useCallback } from 'react';
import styled from 'styled-components';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';

const NoAvailable = styled.span`
  color: #999;
  cursor: not-allowed;
  position: relative;
  top: 2px;
`;

interface Props<T, K extends string | number> {
  dataSource: T[];
  resolveValue: (item: T) => K;
  renderItem: (item: T) => ReactNode;
  onSelect: (value: K) => void;
}

function renderValue<K extends string | number>(selected?: K) {
  return selected || <em>Additional sort options...</em>;
}

export default function Appender<T, K extends string | number>(props: Props<T, K>) {
  const { dataSource, resolveValue, renderItem, onSelect } = props;
  const select = useCallback(
    (event: SelectChangeEvent<K>) => {
      const value = event.target.value as K;
      const [sample] = dataSource;
      if (sample && typeof value === typeof resolveValue(sample)) {
        onSelect(value);
      } else {
        throw new Error(`Unexpected value ${value}`);
      }
    },
    [dataSource, onSelect, resolveValue]
  );

  if (!dataSource.length) {
    return <NoAvailable>These can be dragged into any order you like.</NoAvailable>;
  }

  const renderOption = (item: T) => {
    const value = resolveValue(item);

    return (
      <MenuItem key={value} value={value}>
        {renderItem(item)}
      </MenuItem>
    );
  };

  return (
    <Select sx={{ minWidth: 225 }} value={'' as any} displayEmpty renderValue={renderValue} onChange={select}>
      {dataSource.map(renderOption)}
    </Select>
  );
}
