import React, { useMemo, useCallback, useEffect } from 'react';
import { Box, Modal, SxProps } from '@mui/material';
import { useRootStore } from 'Model';
import { observer } from 'mobx-react-lite';
//import { MyModalContent } from './MyModalContent';
import { ModalContent } from './ModalContent';
import ScrollContainer from 'react-indiana-drag-scroll';
import { useLocation } from 'react-router-dom';

const modalBoxStyle: SxProps = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: [4, 0, 4, 4],
};

export const ChampionModal: React.FC = observer(() => {
  const root = useRootStore();
  const location = useLocation();

  const open = useMemo(() => !!root.modalChampion, [root.modalChampion]);
  const item = useMemo(() => root.modalChampion, [root.modalChampion]);
  const handleClose = useCallback(() => {
    root.modalChampion = undefined;
  }, [root]);

  useEffect(() => {
    // Runs whenever the route changes
    return () => {
      handleClose();
    };
  }, [location, handleClose]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      disableRestoreFocus
      disableScrollLock
      disablePortal
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {item ? (
        <Box sx={modalBoxStyle}>
          <div className="ModalContent-wrap champion">
            <section className="sectionFlex-nowrap">
              <div className="ModalContent sectionFlex-wrap">
                <section>
                  <ModalContent />
                </section>
              </div>
              {/* {item?.instance && (
                <div className="MyModalContent sectionFlex-wrap">
                  <section>
                    <MyModalContent item={item} />
                  </section>
                </div>
              )} */}
              <div className="bottom">
                {/* OtherSiteLink */}
                <section>
                  <h3 className="OtherSiteLinks-Heading">View {item.type.name} on...</h3>
                  <div className="OtherSiteLinks-wrap">
                    <div className="OtherSiteLink">
                      <a
                        href={`https://aftershockunited.gg/raid/champions/${item.type.slug}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Click to view in new tab"
                        className="other-site-link"
                        onClick={(event) => event.stopPropagation()}
                      >
                        AftershockUnited.gg
                      </a>
                    </div>
                    <div className="OtherSiteLink">
                      <a
                        href={`https://hellhades.com/champions/${item.type.slug}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Click to view in new tab"
                        className="other-site-link"
                        onClick={(event) => event.stopPropagation()}
                      >
                        HellHades.com
                      </a>
                    </div>
                    <div className="OtherSiteLink">
                      <a
                        href={`https://ayumilove.net/raid-shadow-legends-${item.type.slug}-skill-mastery-equip-guide/`}
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Click to view in new tab"
                        className="other-site-link"
                        onClick={(event) => event.stopPropagation()}
                      >
                        Ayumilove.com
                      </a>
                    </div>
                  </div>
                  <div className="bottom"></div>
                </section>
              </div>
            </section>
          </div>
        </Box>
      ) : (
        <div />
      )}
    </Modal>
  );
});
