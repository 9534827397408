import React, { useEffect } from 'react';
import { Footer } from './Footer';
import { Header } from './Header';
import { Menus } from './Menus';
import { Outlet } from 'react-router';
import { createTheme, Theme, ThemeProvider } from '@mui/material';
import { DarkModeToggle } from './DarkModeToggle';
import { useSetting } from 'Components/Hooks';
import { useAsyncEffect } from '@react-hook/async';
import { loadStaticData } from 'Data';
import { LoaderBar } from './LoaderBar';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Link, { LinkProps } from '@mui/material/Link';
import ListItem, { ListItemProps } from '@mui/material/ListItem';
import Collapse from '@mui/material/Collapse';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link as RouterLink, Route, Routes, MemoryRouter, useLocation } from 'react-router-dom';
import './Breadcrumbs.css';
import { darkTheme, lightTheme } from './ThemeWrapper';
import { HelmetProvider } from 'react-helmet-async';
import { ChampionModal } from 'Components';
import ReactGA from 'react-ga4';
//import NetlifyIdentityContext from 'react-netlify-identity-gotrue';
import { Auth0Provider } from '@auth0/auth0-react';
import useIsMobile from './useIsMobile';
import { useNavigate } from 'react-router-dom';
import Hotjar from '@hotjar/browser';
//import { FilteredChampionsProvider } from 'Components/DataView/FilteredChampionsContext';

const siteId = 3572734;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

ReactGA.initialize('G-9WTW18B053');
ReactGA.send('pageview');

const { detect } = require('detect-browser');
const browser = detect();

interface ListItemLinkProps extends ListItemProps {
  to: string;
  open?: boolean;
}

const breadcrumbNameMap: { [key: string]: string } = {
  '/': 'All Champs',
  '/mychamps': 'My Champs',
  '/sharemyteam': 'Share My Team',
  '/hotkeys': 'Hotkeys',
  '/about': 'About',
  '/contribute': 'Contribute',
  '/contact': 'Contact',
  '/champions': '',
};

function ListItemLink(props: ListItemLinkProps) {
  const { to, open, ...other } = props;
  const primary = breadcrumbNameMap[to];

  let icon = null;
  if (open != null) {
    icon = open ? <ExpandLess /> : <ExpandMore />;
  }

  return (
    <li>
      <ListItem button component={RouterLink as any} to={to} {...other}>
        <ListItemText primary={primary} />
        {icon}
      </ListItem>
    </li>
  );
}

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

const LinkRouter = (props: LinkRouterProps) => <Link {...props} component={RouterLink as any} />;

export const PageLayout: React.FC = () => {
  const [themeName] = useSetting('site-theme');
  const [theme, setTheme] = React.useState<Theme>(lightTheme);
  const { status } = useAsyncEffect(loadStaticData, []);
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  function onRedirectCallback(appState?: { returnTo?: any }, user?: any) {
    navigate(appState?.returnTo || window.location.pathname, { replace: true });
  }

  let htmlTag = document.getElementsByTagName('html')[0];

  if (isMobile) {
    console.log(`main-wrapper has class mobileDevice`);
    htmlTag?.classList.add('mobileDevice');
  } else {
    htmlTag?.classList.remove('mobileDevice');
  }

  htmlTag?.classList.add(browser.name);

  useEffect(() => {
    if (isMobile) {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = 'mobileStyles.css';
      document.head.appendChild(link);
    }
  }, [isMobile]);

  const location = useLocation();
  //   console.log(`location.pathname`, location.pathname);
  const pathnames = location.pathname.split('/').filter((x) => x);

  React.useEffect(() => {
    document.documentElement.className = themeName;
    if (themeName === 'dark') {
      setTheme(darkTheme);
    } else {
      setTheme(lightTheme);
    }
  }, [themeName]);
  return (
    <Auth0Provider
      domain="cmarabate.auth0.com"
      clientId="JZ4ACkMXEzEWQ1hx6N3OBu6stzHsyEEs"
      cacheLocation="localstorage"
      useRefreshTokens={true}
      onRedirectCallback={onRedirectCallback}
      authorizationParams={{
        redirect_uri: window.location.origin + '/callback',
        scope: 'openid profile email offline_access',
      }}
    >
      {/* <NetlifyIdentityContext url={'https://raidchamps.com'}> */}
      <HelmetProvider>
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <Header />
          <LoaderBar />
          <Breadcrumbs aria-label="breadcrumb">
            <LinkRouter underline="hover" color="inherit" to="/">
              All Champs
            </LinkRouter>
            {pathnames.map((value, index) => {
              const last = index === pathnames.length - 1;
              const to = `/${pathnames.slice(0, index + 1).join('/')}`;

              return last ? (
                <Typography color="text.primary" key={to}>
                  {breadcrumbNameMap[to]}
                </Typography>
              ) : (
                <LinkRouter underline="hover" color="inherit" to={to} key={to}>
                  {breadcrumbNameMap[to]}
                </LinkRouter>
              );
            })}
          </Breadcrumbs>
          <DarkModeToggle />
          <Menus />
          <div className={`main-wrapper`} style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            <main>
              <ThemeProvider theme={theme}>
                {/* <FilteredChampionsProvider> */}
                {status === 'success' ? <Outlet /> : null}
                {/* Make sure the modal is themed */}
                <ChampionModal />
                {/* </FilteredChampionsProvider> */}
              </ThemeProvider>
            </main>
          </div>
          <Footer />
        </div>
      </HelmetProvider>
      {/* </NetlifyIdentityContext> */}
    </Auth0Provider>
  );
};

export default function RouterBreadcrumbs() {
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  return (
    <MemoryRouter initialEntries={['/inbox']} initialIndex={0}>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: 360 }}>
        <Routes>
          <Route path="*" element={<ThemeProvider theme={'light' ? 'light' : 'dark'} />} />
        </Routes>
        <Box
          sx={{
            bgcolor: 'background.paper',
            mt: 1,
          }}
          component="nav"
          aria-label="breadcrumbs"
        >
          <List>
            <ListItemLink to="/inbox" open={open} onClick={handleClick} />
            <Collapse component="li" in={open} timeout="auto" unmountOnExit>
              <List disablePadding>
                <ListItemLink sx={{ pl: 4 }} to="/inbox/important" />
              </List>
            </Collapse>
          </List>
        </Box>
      </Box>
    </MemoryRouter>
  );
}
