import React from 'react';
import { Avatar, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import Dropdown from './Dropdown';
import { Input, MenuList } from './utils';

function filterByName(items: any[], input: string) {
  return items.filter(({ name }) => name.toLowerCase().includes(input.toLowerCase()));
}

export interface MenuProps {
  placeholder?: string;
  value: any;
  setInputEl: (el: HTMLInputElement) => void;
  addValue: (name: any, id: any, ...values: any[]) => void;
  setValue: (value: any) => void;
  data: any;
  style?: React.CSSProperties;
}

export interface ItemProps {
  label: string;
  info: string;
  icon: string;
  multi?: boolean;
  menu: React.ComponentType<MenuProps>;
}

const items: Record<string, ItemProps> = {
  statusType: {
    label: 'Status Type',
    info: 'You can only select one of these options at a time but after you do you will see additional options that you can use to narrow down the results',
    icon: '☢️',
    menu: ({ value, setInputEl, setValue, addValue, data, ...props }) => (
      <Dropdown
        component="li"
        input={<Input key="input" value={value} inputRef={setInputEl} onChange={(e) => setValue(e.target.value)} />}
        {...props}
      >
        <MenuList>
          {filterByName(data, value).map((item) => (
            <MenuItem key={item.name} onClick={() => addValue(item.name, item.id, item.RslValue)}>
              <ListItemIcon>
                <Avatar src={`https://ik.imagekit.io/raidchamps/_NotExtracted/myCreations/Skills/${item.id}.webp`}>
                  <Avatar src={`https://ik.imagekit.io/raidchamps/_NotExtracted/myCreations/Skills/${item.id}.png`} />
                </Avatar>
              </ListItemIcon>
              <ListItemText primary={item.name} />
            </MenuItem>
          ))}
        </MenuList>
      </Dropdown>
    ),
  },
  instants: {
    label: 'Instants / Effect Type',
    info: 'You can only select one of these options at a time but after you do you will see additional options that you can use to narrow down the results',
    icon: '⚠️',
    menu: ({ value, setInputEl, setValue, addValue, data, ...props }) => (
      <Dropdown
        component="li"
        input={<Input key="input" value={value} inputRef={setInputEl} onChange={(e) => setValue(e.target.value)} />}
        {...props}
      >
        <MenuList>
          {filterByName(data, value).map((item) => (
            <MenuItem key={item.name} onClick={() => addValue(item.name, item.id, item.RslValue)}>
              {/* <ListItemIcon>
								<Avatar src={`https://ik.imagekit.io/raidchamps/_NotExtracted/myCreations/Skills/${item.id}.webp`}>
									<Avatar src={`https://ik.imagekit.io/raidchamps/_NotExtracted/myCreations/Skills/${item.id}.png`} />
								</Avatar>
							</ListItemIcon> */}
              <ListItemText primary={item.name} />
            </MenuItem>
          ))}
        </MenuList>
      </Dropdown>
    ),
  },
  buffs: {
    label: 'Buffs',
    info: 'You can only select one of these options at a time but after you do you will see additional options that you can use to narrow down the results',
    icon: '💪',
    menu: ({ value, setInputEl, setValue, addValue, data, ...props }) => (
      <Dropdown
        component="li"
        input={<Input key="input" value={value} inputRef={setInputEl} onChange={(e) => setValue(e.target.value)} />}
        {...props}
      >
        <MenuList>
          {filterByName(data, value).map((item) => (
            <MenuItem key={item.name} onClick={() => addValue(item.name, item.id, item.RslValue)}>
              <ListItemIcon>
                <Avatar src={`https://ik.imagekit.io/raidchamps/_NotExtracted/myCreations/Skills/${item.id}.webp`}>
                  <Avatar src={`https://ik.imagekit.io/raidchamps/_NotExtracted/myCreations/Skills/${item.id}.png`} />
                </Avatar>
              </ListItemIcon>
              <ListItemText primary={item.name} />
            </MenuItem>
          ))}
        </MenuList>
      </Dropdown>
    ),
  },
  debuffs: {
    label: 'Debuffs',
    info: 'You can only select one of these options at a time but after you do you will see additional options that you can use to narrow down the results',
    icon: '☠️',
    menu: ({ value, setInputEl, setValue, addValue, data, ...props }) => (
      <Dropdown
        component="li"
        input={<Input key="input" value={value} inputRef={setInputEl} onChange={(e) => setValue(e.target.value)} />}
        {...props}
      >
        <MenuList>
          {filterByName(data, value).map((item) => (
            <MenuItem key={item.name} onClick={() => addValue(item.name, item.id, item.RslValue)}>
              <ListItemIcon>
                <Avatar src={`https://ik.imagekit.io/raidchamps/_NotExtracted/myCreations/Skills/${item.id}.webp`}>
                  <Avatar src={`https://ik.imagekit.io/raidchamps/_NotExtracted/myCreations/Skills/${item.id}.png`} />
                </Avatar>
              </ListItemIcon>
              <ListItemText primary={item.name} />
            </MenuItem>
          ))}
        </MenuList>
      </Dropdown>
    ),
  },
  percents: {
    label: 'Percents',
    info: 'This skill has various different possible percentages, you can choose one of these options to narrow the results to only those that match the percentage you choose.',
    icon: '❔',
    menu: ({ value, setInputEl, setValue, addValue, data, ...props }) => (
      <Dropdown
        component="li"
        input={<Input key="input" value={value} inputRef={setInputEl} onChange={(e) => setValue(e.target.value)} />}
        {...props}
      >
        <MenuList>
          {filterByName(data, value).map((item) => (
            <MenuItem key={item.name} onClick={() => addValue(item.name, item.id, item.RslValue)}>
              <ListItemText primary={item.name} />
            </MenuItem>
          ))}
        </MenuList>
      </Dropdown>
    ),
  },
  specialRoles: {
    label: 'Special Roles',
    info: 'These options are special and after you select one of these options there will not be any additional options to choose because they would not return accurate results.',
    icon: '❔',
    menu: ({ value, setInputEl, setValue, addValue, data, ...props }) => (
      <Dropdown
        component="li"
        input={<Input key="input" value={value} inputRef={setInputEl} onChange={(e) => setValue(e.target.value)} />}
        {...props}
      >
        <MenuList>
          {filterByName(data, value).map((item) => (
            <MenuItem key={item.name} onClick={() => addValue(item.name, item.id, item.RslValue)}>
              <ListItemText primary={item.name} />
            </MenuItem>
          ))}
        </MenuList>
      </Dropdown>
    ),
  },
  /* effectType: {
		label: 'Effect Type',
		icon: '⚠️',
		info: "You can only select one of these options at a time but after you do you will see additional options that you can use to narrow down the results",
		menu: ({ value, setInputEl, setValue, addValue, data, ...props }) => (
			<Dropdown
				component="li"
				input={
					<Input key="input" value={value} inputRef={setInputEl} onChange={e => setValue(e.target.value)} />
				}
				{...props}
			>
				<MenuList>
					{filterByName(data, value).map(item => (
						<MenuItem key={item.name} onClick={() => addValue(item.name, item.id, item.RslValue)}>

							<ListItemText primary={item.name} />
						</MenuItem>
					))}
				</MenuList>
			</Dropdown>
		),
	}, */
  /* 	skillIndex: {
		label: 'Ability Number',
		icon: '🅰️',
		// info: "If you do not choose one of these, it will return all the champs that have any of the other options you selected on any of their abilities.",
		info: "After selecting either a Status Type or Effect type and an ability number, more filtering options will become available like Target, Cooldown when fully booked, How many chances to PROC, Status Duration, as well as tags for 💯 or [P].",
		menu: ({ value, setInputEl, setValue, addValue, data, ...props }) => (
			<Dropdown
				component="li"
				input={
					<Input key="input" value={value} inputRef={setInputEl} onChange={e => setValue(e.target.value)} />
				}
				{...props}
			>
				<MenuList>
					{filterByName(data, value).map(item => (
						<MenuItem key={item.name} onClick={() => addValue(item.name, item.id, item.RslValue)}>
							<ListItemText primary={item.name} />
						</MenuItem>
					))}
				</MenuList>
			</Dropdown>
		),
	}, */
  targetType: {
    label: 'Target',
    icon: '🎯',
    info: 'If you do not choose one of these, it will return all the champs that have all of the other options you selected targeting any of these options.',
    menu: ({ value, setInputEl, setValue, addValue, data, ...props }) => (
      <Dropdown
        component="li"
        input={<Input key="input" value={value} inputRef={setInputEl} onChange={(e) => setValue(e.target.value)} />}
        {...props}
      >
        <MenuList>
          {filterByName(data, value).map((item) => (
            <MenuItem key={item.name} onClick={() => addValue(item.name, item.id, item.RslValue, item.champRoleValue)}>
              {/* <ListItemIcon>
								<Avatar src={item.avatar} />
							</ListItemIcon> */}
              <ListItemText primary={item.name} />
            </MenuItem>
          ))}
        </MenuList>
      </Dropdown>
    ),
  },
  skillCdBooked: {
    label: 'Cooldown when fully booked',
    icon: '🔃',
    info: 'Choosing this option will only return champs that have a Cooldown of 2 or higher. If you do not choose one of these, it will return all the champs that have all of the other options you selected regardless of the cooldown.',
    menu: ({ value, setInputEl, setValue, addValue, data, ...props }) => (
      <Dropdown
        component="li"
        input={<Input key="input" value={value} inputRef={setInputEl} onChange={(e) => setValue(e.target.value)} />}
        {...props}
      >
        <MenuList>
          {filterByName(data, value).map((item) => (
            <MenuItem key={item.name} onClick={() => addValue(item.name, item.id, item.RslValue)}>
              {/* <ListItemIcon>
								<span style={{ height: 16, width: 16, background: item.color }} />
							</ListItemIcon> */}
              <ListItemText primary={item.name} />
            </MenuItem>
          ))}
        </MenuList>
      </Dropdown>
    ),
  },
  numHits: {
    label: 'Number of Hits',
    icon: '⚔️',
    info: 'Choosing this option will only return champs that have a number of hits of 2 or higher. If you do not choose one of these, it will return all the champs that have all of the other options you selected regardless of how many attacks.',
    menu: ({ value, setInputEl, setValue, addValue, data, ...props }) => (
      <Dropdown
        component="li"
        input={<Input key="input" value={value} inputRef={setInputEl} onChange={(e) => setValue(e.target.value)} />}
        {...props}
      >
        <MenuList>
          {filterByName(data, value).map((item) => (
            <MenuItem key={item.name} onClick={() => addValue(item.name, item.id, item.RslValue)}>
              {/* <ListItemIcon>
								<span style={{ height: 16, width: 16, background: item.color }} />
							</ListItemIcon> */}
              <ListItemText primary={item.name} />
            </MenuItem>
          ))}
        </MenuList>
      </Dropdown>
    ),
  },
  statusDuration: {
    label: 'Status Duration',
    icon: '⏳',
    info: 'Choosing this option will only return champs that have a Status Duration of 2 or higher. If you do not choose one of these, it will return all the champs that have all of the other options you selected regardless of how many turns it lasts.',
    menu: ({ value, setInputEl, setValue, addValue, data, ...props }) => (
      <Dropdown
        component="li"
        input={<Input key="input" value={value} inputRef={setInputEl} onChange={(e) => setValue(e.target.value)} />}
        {...props}
      >
        <MenuList>
          {filterByName(data, value).map((item) => (
            <MenuItem key={item.name} onClick={() => addValue(item.name, item.id, item.RslValue)}>
              {/* <ListItemIcon>
								<span style={{ height: 16, width: 16, background: item.color }} />
							</ListItemIcon> */}
              <ListItemText primary={item.name} />
            </MenuItem>
          ))}
        </MenuList>
      </Dropdown>
    ),
  },
  tag: {
    label: 'Tag (💯 or [P] or A1)',
    icon: '🏷️',
    multi: true,
    info: 'There are currently three options that can be applied with this. Adding the 💯 tag will return only the champs that have all of the other options you selected and have a 100% chance to PROC when fully booked. Adding the [P] tag will return only the champs that have all of the other options you selected on a Passive ability. And adding the A1 tag will return only the champs that have all of the other options you selected on a A1 ability.',
    menu: ({ value, setInputEl, setValue, addValue, data, ...props }) => (
      <Dropdown
        component="li"
        input={<Input key="input" value={value} inputRef={setInputEl} onChange={(e) => setValue(e.target.value)} />}
        {...props}
      >
        <MenuList>
          {filterByName(data, value).map((item) => (
            <MenuItem key={item.name} onClick={() => addValue(item.icon, item.name, item.id, item.RslValue)}>
              {/* <ListItemIcon>
								<span style={{ height: 16, width: 16, background: item.color }} />
							</ListItemIcon> */}
              <ListItemText primary={item.icon} secondary={item.name} className={item.id} />
            </MenuItem>
          ))}
        </MenuList>
      </Dropdown>
    ),
  },
};

export default items;
