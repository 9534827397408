import React, { useEffect, useState } from 'react';
import './HexagonGrid.css'; // Import the CSS, adjust the path if needed
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Slider from '@mui/material/Slider';

interface HexagonGridProps {
  hexagonData: HexagonData[];
}

interface HexagonData {
  imageUrl: string;
  title: string;
  description: string;
  link: string;
}

const HexagonGrid: React.FC<HexagonGridProps> = ({ hexagonData }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [fullRowColumns, setFullRowColumns] = useState(6);
  const [sliderValue, setSliderValue] = useState<number>(1);

  useEffect(() => {
    const updateLayout = () => {
      const width = window.innerWidth;
      /* if (width <= 480) {
        setFullRowColumns(4); // 4 hexagons per row for small screens
      } else if (width <= 768) {
        setFullRowColumns(5); // 5 hexagons per row for medium screens
      } else {
        setFullRowColumns(6); // 6 hexagons per row for large screens
      } */
      setFullRowColumns(6);
    };

    updateLayout();
    window.addEventListener('resize', updateLayout);

    return () => {
      window.removeEventListener('resize', updateLayout);
    };
  }, []);

  const openLightbox = (index: number) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const downloadImage = async (url: string) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const urlObject = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = urlObject;
      a.download = hexagonData[currentImage]?.title || 'image';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error('Failed to download image:', error);
    }
  };

  const hexagonSizeStyle =
    sliderValue === 1
      ? {
          width: '13.9285714286%',
          padding: '0 0 16.08380072583306% 0',
        }
      : {
          width: '27.85714285714286%',
          padding: '0 0 32.16760145166612% 0',
        };

  const renderHexagon = (hexData: HexagonData, key: number) => {
    const [isHovered, setIsHovered] = useState(false);

    const actualFullRowColumns = sliderValue === 1 ? 12 : 6;
    const rowNumber = Math.floor(key / actualFullRowColumns);
    const positionInRow = key % actualFullRowColumns;
    const isSecondHalf = positionInRow >= Math.ceil(actualFullRowColumns / 2);

    let shiftStyle = {};

    if (isSecondHalf) {
      /* if (rowNumber === 0) {
        // Special case for the first row
        shiftStyle = {
          transform: `translateX(${sliderValue === 1 ? '54%' : '50%'}) translateY(${
            sliderValue === 1 ? '-8%' : '-8%'
          }) rotate(-60deg) skewY(30deg)`,
        };
      } else if (rowNumber % 2 === 0) {
        // Even rows
        shiftStyle = {
          transform: `translateX(${sliderValue === 1 ? '54%' : '50%'}) translateY(${
            sliderValue === 1 ? '-8%' : '0%'
          }) rotate(-60deg) skewY(30deg)`,
        };
      } else {
        // Odd rows
        shiftStyle = {
          transform: `translateX(${sliderValue === 1 ? '54%' : '50%'}) translateY(${
            sliderValue === 1 ? '-4%' : '-4%'
          }) rotate(-60deg) skewY(30deg)`,
        };
      } */

      shiftStyle = {
        transform: `translateX(${sliderValue === 1 ? '54%' : '50%'}) translateY(${
          sliderValue === 1 ? '-4%' : '-4%'
        }) rotate(-60deg) skewY(30deg)`,
      };
    } else {
      shiftStyle = { transform: 'rotate(-60deg) skewY(30deg)' };
    }

    const hexagonClass = sliderValue === 2 ? 'largeHex' : '';

    return (
      <li key={key} onClick={() => openLightbox(key)} className={hexagonClass}>
        <div
          className="hexagon shimmer"
          style={{
            backgroundImage: `url(${hexData.imageUrl})`,
          }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <div
            className="hexagon-overlay"
            style={{
              background: 'linear-gradient(to top, rgba(0,0,0,0.9), rgba(0,0,0,0))',
              opacity: isHovered ? 0 : 1,
              transition: 'opacity 0.58s ease-in-out',
            }}
          ></div>
          <div className="hexagon-content">
            <h3>{hexData.title}</h3>
            <p>{hexData.description}</p>
          </div>
        </div>
      </li>
    );
  };

  const fullRows = Math.ceil(hexagonData.length / (fullRowColumns * 2)); // 2 sets of 6 hexagons per full row
  let dataIndex = 0;

  return (
    <>
      <div className="flex">
        <div className="leftOfSlider sideOfSlider">Small Hexagons</div>
        <Slider
          value={sliderValue}
          min={1}
          max={2}
          step={1}
          onChange={(e, newValue) => setSliderValue(newValue as number)}
        />
        <div className="rightOfSlider sideOfSlider">Large Hexagons</div>
      </div>
      <div className="hexagon-container">
        {Array.from({ length: fullRows }, (_, fullRowIndex) => (
          <>
            <ul id="hexagonGrid" className="clear" key={`full-${fullRowIndex}`}>
              {Array.from({ length: fullRowColumns * 2 }, () => {
                // 2 sets of 6 hexagons
                const hexData = hexagonData[dataIndex];
                return hexData ? renderHexagon(hexData, dataIndex++) : null;
              })}
            </ul>
          </>
        ))}
        {viewerIsOpen && (
          <Lightbox
            mainSrc={hexagonData[currentImage].imageUrl}
            nextSrc={hexagonData[(currentImage + 1) % hexagonData.length].imageUrl}
            prevSrc={hexagonData[(currentImage + hexagonData.length - 1) % hexagonData.length].imageUrl}
            onCloseRequest={closeLightbox}
            onMovePrevRequest={() => setCurrentImage((currentImage + hexagonData.length - 1) % hexagonData.length)}
            onMoveNextRequest={() => setCurrentImage((currentImage + 1) % hexagonData.length)}
            toolbarButtons={[
              <button
                className="downloadImage btn lightboxBtn"
                onClick={() => downloadImage(hexagonData[currentImage].imageUrl)}
                style={{ color: 'white', textDecoration: 'none' }}
                title="Download the image"
              >
                Download
              </button>,
            ]}
          />
        )}
      </div>
    </>
  );
};

export default HexagonGrid;
