class EnumLookup<T extends Record<string, string> = any> {
  private readonly values = new Set<T[keyof T]>();
  private readonly lookup = new Map<string, T[keyof T]>();
  constructor(enumType: T) {
    for (const [key, value] of Object.entries(enumType)) {
      this.values.add(value as T[keyof T]);
      this.lookup.set(key.toLowerCase(), value as T[keyof T]);
      this.lookup.set(value.toLowerCase(), value as T[keyof T]);
    }
  }
  parse(value: any): T[keyof T] {
    // Changed value type to any for demonstration
    if (typeof value === 'number') {
      value = value.toString(); // Convert number to string
    }

    if (typeof value !== 'string') {
      console.warn(`Failed to parse enum value because it's not a string: '${value}'`);
      return value as T[keyof T];
    }

    const parsedValue = this.lookup.get(value.toLowerCase());
    if (!parsedValue) {
      console.warn(`Failed to parse enum value '${value}'`);
      return value.toLowerCase().trim() as T[keyof T];
    }
    return parsedValue;
  }
}

const enumLookupMap = new Map<Record<string, string>, EnumLookup>();
function getLookup<T extends Record<string, string>>(enumType: T): EnumLookup<T> {
  if (!enumLookupMap.has(enumType)) enumLookupMap.set(enumType, new EnumLookup<T>(enumType));
  return enumLookupMap.get(enumType) as EnumLookup<T>;
}

export function parseEnum<T extends Record<string, string>>(value: string, _enumType: T): T[keyof T] {
  return getLookup<T>(_enumType).parse(value);
}
