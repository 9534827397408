import { AccordionMenu, AccordionMenuProps } from 'Components';
import React from 'react';
import { CornerMenu } from './CornerMenu';

const menuProps: AccordionMenuProps = {
  categories: {
    Apps: [
      { text: 'BlueStacks', href: 'https://www.bluestacks.com/download.html' },
      { text: 'HellHades Raid Optimizer', href: 'https://raidoptimiser.hellhades.com/' },
      {
        text: 'Plarium Play',
        href: 'https://plarium.com/landings/en/desktop/raid/dragon_fire_f038_fdb_droapp?plid=150483&pxl=google_search&publisherid=plarium%20play_kwd-540375920350_114139783915&placement=480453942383_114139783915&adpartnerset=114139783915&gclid=CjwKCAjwv_iEBhASEiwARoemvF9n6pCC5X0grDNKIixy2mQECUG7cFijC768TWEfN465thr50zPmshoCaK0QAvD_BwE',
      },
      { text: 'Raidbot', href: 'https://raidbot.app/' },
      { text: 'RaidClicker GUI', href: 'https://pastebin.com/7vUps4YP' },
      { text: 'Raid Toolkit', href: 'https://github.com/raid-toolkit/raid-toolkit-sdk/releases/latest' },
      { text: 'RSL Helper', href: 'https://github.com/FarbstoffRSL/RSL-Helper/releases' },
    ],
    Calculators: [
      { text: 'Arena Speed Calculator', href: 'https://www.deadwoodjedi.com/arena-calculator' },
      {
        text: 'Campaign Farming Calculator',
        href: 'https://docs.google.com/spreadsheets/d/1hZiJ8bghVct9fyHf8R1Mf_ZDyAiATDGi3SFUNQMikAk/edit#gid=623907573',
      },
      {
        text: 'Champ Training Event Calculator',
        href: 'https://docs.google.com/spreadsheets/d/1Aw3vHFXgocisOoNpvGpZUa8gw1d6dp8QQ71sECJ9vNU/edit',
      },
      { text: 'Clan Boss Chest Simulator', href: 'https://hellhades.com/clan-boss-chest-simulator/' },
      { text: 'Clan Boss Speed Calculator', href: 'https://www.deadwoodjedi.com/clan-boss-speed-calculator' },
      {
        text: 'Clan Boss Stun Target Calculator',
        href: 'https://docs.google.com/spreadsheets/d/1yJj53WjFemCwM3AyyuYcIGVWLLH_IlKcV57kFAMk3n0/edit#gid=2032005244',
      },
      {
        text: 'Aftershock United CvC Calculator',
        href: 'https://aftershockunited.gg/raid/CvC',
      },
      {
        text: 'Hard FK Simulator',
        href: 'https://simulator.raidtoolkit.com/?n=CardielSiciaValkMorrigaineAcrizia&id=360773918020075600',
      },
      {
        text: 'HellHades CvC Calculator',
        href: 'https://hellhades.com/raid/clan-vs-clan-calculator/',
      },
      {
        text: 'Aftershock United Offer Calculator',
        href: 'https://aftershockunited.gg/raid/offer-calc',
      },
      {
        text: 'HellHades Offer Calculator',
        href: 'https://hellhades.com/raid/pack-offer-calculator/',
      },
      {
        text: 'HellHades EHP Calculator',
        href: 'https://hellhades.com/ehp-efficiency-calculator/',
      },
      { text: 'Minotaur Scroll Mastery Calculator', href: 'https://raidshadowlegendstierlist.com/mastery-calculator' },
    ],
    Guides: [
      { text: 'Arena', href: 'https://hellhades.com/understanding-the-arena-and-the-benefits-it-can-bring/' },
      { text: 'Blessings', href: 'https://hellhades.com/blessings-guide-choosing-blessings-for-a-team/' },
      { text: 'Buffs & Debuffs', href: 'https://hellhades.com/raid-shadow-legends-debuffs-buffs/' },
      { text: 'Clan Boss', href: 'https://empyreanrule.com/raid-shadow-legends/clan-boss/' },
      { text: 'Doom Tower Floors', href: 'https://www.hellhades.com/doom-tower-floor-information-sheet/' },
      { text: 'Doom Tower Bosses', href: 'https://hellhades.com/doom-tower-bosses/' },
      { text: 'Doom Tower Secret Rooms', href: 'https://hellhades.com/doom-tower-secret-rooms/' },
      { text: 'Doom Tower Artifact Sets', href: 'https://hellhades.com/doom-tower-artifact-sets/' },
      { text: 'Hydra Clan Boss Guide', href: 'https://hellhades.com/hydra-clan-boss-guide/' },
      { text: 'Hydra Clan Boss Heads', href: 'https://hellhades.com/hydra-clan-boss-heads/' },
      { text: 'Hydra Clan Boss Rewards', href: 'https://hellhades.com/hydra-clan-boss-rewards/' },
      { text: 'Hydra Clan Boss Blessings', href: 'https://hellhades.com/the-best-blessings-for-hydra-clan-boss/' },
      { text: 'Faction Wars', href: 'https://www.youtube.com/watch?v=l4F1xekwoww' },
      { text: 'Faction Wars Bosses', href: 'https://www.youtube.com/watch?v=hJV__Igs9qo' },
      {
        text: 'Faction Wars Boss Skillsets',
        href: 'https://plarium.com/forum/en/raid-shadow-legends/673_guides-and-tutorials/152118_faction-war-boss-skillsets/',
      },
      {
        text: 'Farming',
        href: 'https://www.reddit.com/r/RaidShadowLegends/comments/axzv88/farming_101_what_to_farm_when_to_farm_it_and_how/',
      },
      {
        text: 'Fusions',
        href: 'https://hellhades.com/be-prepared-for-the-next-fusion-event/',
      },
      { text: 'Jewlery Artifacts to Keep', href: 'https://www.youtube.com/watch?v=Pg8l9XtSyyI' },
      { text: 'Team Compositions', href: 'https://empyreanrule.com/raid-shadow-legends/team-compositions/' },
      {
        text: 'Spider 20 on Auto',
        href: 'https://www.reddit.com/r/RaidShadowLegends/comments/ch0aq8/spider_20_auto_a_guide/',
      },
    ],
    Infographics: [
      { text: 'Artifact Dungeon Drop Rates', href: 'https://i.imgur.com/OyQzYml.jpg' },
      { text: "BGE's Best Skills for Each Location", href: 'https://i.imgur.com/HCNFcmI.jpg' },
      { text: 'Clan Boss Drop Rates', href: 'https://i.imgur.com/crDjPTa.jpg' },
      { text: 'Clan Boss Raw Damage', href: 'https://i.imgur.com/fQt9eJQ.png' },
      { text: 'Clan Boss Stats', href: 'https://i.imgur.com/Z0XN3em.png' },
      { text: 'Doom Tower Secret Room Info', href: 'https://i.imgur.com/bxWiOkS.png' },
      {
        text: 'Dungeon Drop Rates up to Stage 25',
        href: 'https://cdn.discordapp.com/attachments/720060683269570560/843145275472478238/unknown.png',
      },
      { text: 'Dungeon + Faction Wars Resistance by Stage', href: 'https://i.imgur.com/P1SPyOu.png' },
      { text: 'Faction Wars Boss Ability Info', href: 'https://i.imgur.com/AjVvK4S.jpg' },
      { text: 'HP Needed based on Enemy HP', href: 'https://i.imgur.com/uVKFNwc.png' },
      { text: 'Faction Wars Drop Rates', href: 'https://i.imgur.com/3lmpwdA.jpg' },
    ],
    'My Account Data Tools': [
      { text: 'Brago - Basic Raid Gear Observer', href: 'https://laughing-engelbart-62bcb5.netlify.app/' },
      { text: 'My Champs', href: '/mychamps' },
      { text: 'Raid Toolkit', href: 'https://raidtoolkit.com/' },
      { text: 'Share My Team', href: '/sharemyteam' },
    ],
    Spreadsheets: [
      {
        text: 'Accuracy Recommendations',
        href: 'https://photos.google.com/share/AF1QipNxY-PnIk4v5OsIt_VkMrUM4D7QM7_IgJN--o1CaweXiSFBl_fPA9DgYKHQNflvag?key=LUhNSlJHWlBHNWNienVwdFlSblhoTjVHVWhSdDRn',
      },
      {
        text: 'Campaign Farming Calculator',
        href: 'https://docs.google.com/spreadsheets/d/1hZiJ8bghVct9fyHf8R1Mf_ZDyAiATDGi3SFUNQMikAk/edit#gid=623907573',
      },
      { text: 'Champion Damage', href: 'https://drive.google.com/file/d/1-L8bcKT_ZtD66vsp1Fu-otVcSOtlGg_4/view' },
      {
        text: 'Doom Tower Stage Info',
        href: 'https://docs.google.com/spreadsheets/d/1tZcL6f4qAMRUZ1UXbljj4QpRfV28NGrtOpVOmtk1-u8/edit#gid=0',
      },
      {
        text: 'Faction Wars Planner',
        href: 'https://docs.google.com/spreadsheets/d/1uHOZR_D63F84-kB87hbxJj8hd4MIhbdrtakhNfv4cSQ/edit#gid=0',
      },
      {
        text: 'Fusion Planner',
        href: 'https://docs.google.com/spreadsheets/d/179nwph1n6YW8VxkB9vt7jtmr8CKjK9zXRYepZwVSpPo/edit#gid=1739738286',
      },
      {
        text: 'Minotaur Farming',
        href: 'https://docs.google.com/spreadsheets/d/1Ib1VKYIt8W3vb5IDYb1C5ain8cSxVWyUbKXJHDeRZO4/edit#gid=0',
      },
      {
        text: 'Artifact Tier List',
        href: 'https://hellhades.com/raid/artifact-tier-list/',
      },
    ],
    'Tier Lists': [
      { text: 'Ayumilove', href: 'https://ayumilove.net/raid-shadow-legends-list-of-champions-by-ranking/' },
      {
        text: 'ChoseN',
        href: 'https://docs.google.com/spreadsheets/d/1nh82R5ROEV1idhYQeujsBNM9mNEatkISdbqQYvR6ZZI/edit#gid=0',
      },
      { text: 'HH Tier List', href: 'https://hellhades.com/raid-shadow-legends-tier-list/' },
      { text: 'HH Faction Wars Tier Lists', href: 'https://hellhades.com/raid-shadow-legends-faction-wars-tier-list/' },
      { text: 'HH Blessings Tier Lists', href: 'https://hellhades.com/raid-shadow-legends-blessings-tier-list/' },
    ],
    Tools: [
      {
        text: 'Clan Boss Calculator Assistant',
        href: 'https://www.reddit.com/r/RaidShadowLegends/comments/io5wf1/tool_release_saveshare_cb_speedtunes_browser/',
      },
      { text: 'HellHades Raid Optimizer', href: 'https://raidoptimiser.hellhades.com' },
      { text: 'HellHades Stages Tool', href: 'https://hellhades.com/raid/stages-tool/' },
      { text: 'RaidBro Masteries Simulator', href: 'https://raidbro.com/masteries' },
      {
        text: 'HellHades Damage Efficiency Tool',
        href: 'https://hellhades.com/ehp-efficiency-calculator/',
      },
    ],
    Websites: [
      { text: 'AftershockUnited.gg/raid', href: 'https://AftershockUnited.gg/raid' },
      { text: 'DeadwoodJedi', href: 'https://www.deadwoodjedi.com/' },
      { text: 'HellHades', href: 'https://hellhades.com' },
      { text: 'Raid Tips Blog by Cosmos', href: 'https://raid-tips.blogspot.com/' },
    ],
    'Content Creators': [
      { text: 'Ash', href: 'https://www.youtube.com/channel/UCtXunTsAylY83zqRn4KdsaA' },
      { text: 'Chofly Elite', href: 'https://www.youtube.com/channel/UCDClIEc8CVfPz8nA8lVHiQQ' },
      { text: 'DeadwoodJedi', href: 'https://www.youtube.com/channel/UC0NwysarFXJ94lh-eXFsuqw' },
      { text: 'HellHades', href: 'https://www.youtube.com/channel/UCbAYgOLXfYtc-HgoxjCnDZQ' },
      { text: 'JGigs', href: 'https://www.youtube.com/channel/UCvYc0Q57Sxah8Nutq2vTjZQ' },
      { text: 'LeilaFox Nation', href: 'https://www.youtube.com/channel/UCGkLaozsBMDZ8axeh5h6VXw' },
      { text: 'SkratchAK47', href: 'https://www.youtube.com/skratchak47' },
    ],
  },
};

//class Header extends React.PureComponent {
export const Menus: React.FC = () => {
  return (
    <CornerMenu panelClassName="menu-box" text="Links" position="top right">
      <h3 style={{ left: 15 }} className="with-underline">
        Resources
      </h3>

      {/* <div className="MenuLinkNotInAccordion-wrap pr-2">
        <a
          href="https://simulator.raidtoolkit.com/?n=CardielSiciaValkMorrigaineAcrizia&id=360773918020075600"
          target={'_blank'}
        >
          Hard FK Simulator
        </a>
      </div> */}
      <AccordionMenu categories={menuProps.categories} />
    </CornerMenu>
  );
};
