import React from 'react';
import { NextGenImage, RichString } from 'Components';
import { getSkills } from 'Data';
import './Ability.css';
import Tooltip from 'rc-tooltip';
import { SkillUpgrade } from '@raid-toolkit/webclient';

function getUpgradeTypeName(type: string) {
  let typeName: string = '';
  if (type === 'EffectChance') {
    typeName = 'Buff/Debuff Chance';
  }

  if (type === 'CooltimeTurn') {
    typeName = 'Cooldown';
  }

  if (type === 'Attack') {
    typeName = 'Damage';
  }

  if (type === 'Health') {
    typeName = 'Health';
  }

  return typeName;
}

export const Ability: React.FC<{
  skillId: number;
  skillIndex: number;
  heroTypeId: any;
  instance?: any;
  imageOnly?: boolean;
  local?: boolean;
  currentFormIndex?: number;
  rarity?: string;
}> = ({ skillId, skillIndex, heroTypeId, instance, imageOnly, local, currentFormIndex, rarity }) => {
  const skillType = React.useMemo(() => getSkills()[skillId], [skillId]);
  if (window.location.hostname === 'localhost') {
    console.log(`skillType`, skillType);
  }

  let fullyBookedCooldown = 0;
  let amountCooldownDecreasedWhenFullyBooked = 0;
  let amountCooldownDecreasedBasedOnCurrentLevel = 0;
  let cooldownBasedOnCurrentLevel;

  amountCooldownDecreasedWhenFullyBooked = 0;
  skillType?.upgrades.forEach((upgrade: SkillUpgrade) => {
    if (upgrade.type === 'CooltimeTurn') {
      amountCooldownDecreasedWhenFullyBooked = amountCooldownDecreasedWhenFullyBooked + 1;
    }
  });
  if (skillType?.cooldown) {
    fullyBookedCooldown = skillType?.cooldown - amountCooldownDecreasedWhenFullyBooked;
  }

  const regexBadAbilityName =
    /((^(?=.*\b)(?<!(Infernal )|(Ranger's )|(Fabled )|(Exemplar of ))Skill\b)(?=.*\b(name|)\b).*$|\d* \(d* passive part\))|\d+ \(\d+ passive part\)/gim;

  const regexBadAbilityDesc = /Skill \d* description/gim;

  let level: number = 1;
  if (instance) {
    level = instance.skillLevelsByTypeId[Number(heroTypeId + skillIndex)];
    for (var i = 0; i < level; i++) {
      if (skillType?.upgrades?.[i]?.type === 'CooltimeTurn') {
        amountCooldownDecreasedBasedOnCurrentLevel = amountCooldownDecreasedBasedOnCurrentLevel + 1;
      }
    }
  }

  if (skillType?.cooldown) {
    cooldownBasedOnCurrentLevel = skillType?.cooldown - amountCooldownDecreasedBasedOnCurrentLevel;
  }

  let maxLevel = 1;
  if (skillType?.upgrades.length) {
    maxLevel = skillType?.upgrades.length + 1;
  }

  return skillType && !regexBadAbilityName.test(skillType.name) && !regexBadAbilityDesc.test(skillType.description) ? (
    <div className="ability-wrap">
      <div className="abilityImage-wrap">
        <NextGenImage
          className="abilityImage"
          whichDirectory={`skillIcons`}
          heroTypeId={heroTypeId}
          skillType={skillType}
          title={skillType.name}
          alt={skillType.name}
          skillIndex={skillIndex}
          local={local}
          currentFormIndex={currentFormIndex}
          rarity={rarity}
        />
      </div>
      {instance && (
        <div
          className="skillLevel-wrap"
          title={`This champ has this ability booked to level ${level ? level : 1}/${maxLevel}`}
        >
          <div className="skillLevel">
            <span className="theLevel">
              {level ? level : 1}/{maxLevel}
            </span>
          </div>
        </div>
      )}
      {imageOnly !== true && (
        <div className="skill">
          <h2 className="skill-name">
            {skillType.name}{' '}
            {skillType.multiplier && (
              <>
                <span className="multiplier dont-break-out ">[{skillType.multiplier}]</span>{' '}
              </>
            )}
            {skillType.cooldown > 1 && (
              <>
                {instance ? (
                  <>
                    <span
                      title={`${cooldownBasedOnCurrentLevel} Turn Cooldown based on current level.`}
                      className="cooldown"
                    >
                      <span className="cooldownIcon">
                        🔃
                        {cooldownBasedOnCurrentLevel}
                      </span>
                    </span>
                  </>
                ) : (
                  <>
                    <span title={`${skillType.cooldown} Turn Cooldown`} className="cooldown">
                      <span className="cooldownIcon">
                        🔂
                        {skillType.cooldown}
                      </span>
                    </span>
                    {amountCooldownDecreasedWhenFullyBooked > 0 && (
                      <span
                        title={`${fullyBookedCooldown} Turn Cooldown when fully booked`}
                        className="fullyBookedCooldown"
                      >
                        <span className="cooldownIcon">
                          🔁
                          {fullyBookedCooldown}
                        </span>
                      </span>
                    )}
                  </>
                )}
              </>
            )}
          </h2>

          {/* unblockable data does not seem accurate
           <p>{skillType.unblockable && <div>Unblockable Icon Here!</div>}</p> */}
          <RichString>{skillType.description}</RichString>
          {/* No clue why I can't seem to use the upgrade data here, it seems to be there for me to use because it is in the skillType data that is logged above
          this is what is returned for upgrades in skillType...
          upgrades: Array(2)
            0: {type: 'CooltimeTurn', value: 1}
            1: {type: 'CooltimeTurn', value: 1}
            length: 2
            [[Prototype]]: Array(0)
            */}
          <div>
            {skillType.upgrades.map(
              (
                upgrade: {
                  type: string;
                  value: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined;
                },
                index: React.Key | null | undefined
              ) => (
                <div
                  key={index as React.Key}
                  className={index !== undefined && index !== null && Number(index) + 2 === level ? 'currentLevel' : ''}
                  title={index !== undefined && index !== null && Number(index) + 2 === level ? 'Current Level' : ''}
                >
                  <span
                    className={index !== undefined && index !== null && Number(index) + 2 <= level && 'levelAcquired'}
                  >
                    Level {index !== undefined && index !== null && Number(index) + 2}:{' '}
                    {getUpgradeTypeName(upgrade.type)} {upgrade.type !== 'CooltimeTurn' ? ' +' : ' -'}{' '}
                    {typeof upgrade.value === 'number' && upgrade.value % 1 !== 0
                      ? `${(upgrade.value * 100).toFixed(0)}%`
                      : upgrade.value}{' '}
                    {index !== undefined && index !== null && Number(index) + 2 === level && (
                      <i title="Current Level Indicator" className="arrow left"></i>
                    )}
                  </span>
                </div>
              )
            )}
          </div>
        </div>
      )}
    </div>
  ) : null;
};
