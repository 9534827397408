import React from 'react';
import { Affinity as AffinityEnum } from '../../Types';
import { getAffinityData } from '../../Data';
import { NextGenImage } from '../../Components/Library';

export interface AffinityProps {
  className?: string;
  style?: React.CSSProperties;
  affinity: AffinityEnum | undefined;
  local?: boolean;
}

export const AffinityBanner: React.FC<AffinityProps> = ({ affinity, style, className, local }) => {
  const affinityData = getAffinityData(affinity);
  return (
    <NextGenImage
      className={className}
      style={style}
      alt={`${affinityData?.name} Affinity`}
      title={`${affinityData?.name} Affinity`}
      whichDirectory={affinityData?.iconDirectory}
      whichImage={affinityData?.iconImage}
      local={local}
    />
  );
};
