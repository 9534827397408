import { ValueOf } from 'Types';

export enum StatKind {
  health = 'health',
  hp = 'health',
  defence = 'defense',
  defense = 'defense',
  attack = 'attack',
  speed = 'speed',
  criticalChance = 'criticalchance',
  criticalDamage = 'criticaldamage',
  criticalHeal = 'criticalheal',
  resistance = 'resistance',
  accuracy = 'accuracy',
}

export const StatKindKeys: StatKind[] = [
  StatKind.health,
  StatKind.defense,
  StatKind.attack,
  StatKind.criticalChance,
  StatKind.criticalDamage,
  StatKind.criticalHeal,
  StatKind.resistance,
  StatKind.accuracy,
  StatKind.speed,
];
