import { createTheme, Theme, ThemeProvider } from '@mui/material';
import React from 'react';

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

export const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
});

export const ThemeWrapper: React.FC = ({ children }) => {
  const [theme] = React.useState<Theme>(lightTheme);
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
