import React from 'react';
import { getHeroRating } from '../../Data';

export interface HellHadesRatingProps {
  slug: string | undefined;
}
export const HellHadesRating: React.FC<HellHadesRatingProps> = ({ slug }) => {
  const ratingValue = getHeroRating(slug)?.detailed?.hellhades?.overall || 0;
  const ratingBase100Value = getHeroRating(slug)?.detailed?.hellhades?.base100 || 0;

  let correctSlugForSite = slug;
  if (slug === 'fren-zi-the-cackler') {
    correctSlugForSite = 'frenzi-the-cackler';
  }
  if (slug === 'ma-shalled') {
    correctSlugForSite = 'mashalled';
  }

  let ratingValueAsNumber = 0;
  if (ratingValue !== 0) {
    ratingValueAsNumber = parseFloat(ratingValue);
  }

  let ratingClass = 'noStar';
  if (ratingValueAsNumber > 0 && ratingValueAsNumber <= 1) {
    ratingClass = 'oneStar';
  }
  if (ratingValueAsNumber > 1 && ratingValueAsNumber <= 2) {
    ratingClass = 'twoStar';
  }
  if (ratingValueAsNumber > 2 && ratingValueAsNumber <= 3) {
    ratingClass = 'threeStar';
  }
  if (ratingValueAsNumber > 3 && ratingValueAsNumber <= 4) {
    ratingClass = 'fourStar';
  }
  if (ratingValueAsNumber > 4 && ratingValueAsNumber <= 5) {
    ratingClass = 'fiveStar';
  }

  return (
    <div className="hit-hellHadesScore RatingItem">
      <a
        href={`https://hellhades.com/champions/${correctSlugForSite}`}
        onClick={(event) => {
          event.preventDefault();
          window.open(event.currentTarget.href, 'HellHadesTab');
        }}
        title={`HellHades Score is ${ratingValue}/5 | ${ratingBase100Value} after converted to Base 100 Rating System - Click to View this champ on his Website in a New Tab and then any other champions you click the HellHades rating for will also load in that same tab which was opened previously even if you moved it to another screen.`}
        className="other-site-link"
      >
        {window.location.hostname === 'localhost' && ratingValue <= 0 ? (
          <div className="noStar" title="Not Rated by HellHades Yet - Click to Open his Website in a New Tab">
            N
          </div>
        ) : ratingValue <= 0 ? (
          <div className="hidden"></div> // Display this when ratingValue is less than or equal to 0 on live site
        ) : (
          <div className={ratingClass}>
            {ratingValue}
            <span className="hhStar">★</span>
          </div>
        )}
      </a>
    </div>
  );
};
