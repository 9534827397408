import React, { useEffect, useCallback } from 'react';
import { AbilityType } from 'Types';

export interface NextGenImageProps {
  className?: string;
  whichDirectory?: string;
  heroTypeId?: string;
  skillType?: AbilityType;
  skillIndex?: number;
  whichImage?: string;
  title?: string;
  alt?: string;
  width?: string;
  height?: string;
  style?: React.CSSProperties;
  local?: boolean;
  currentFormIndex?: number;
  rarity?: string;
}

export const NextGenImage: React.FC<NextGenImageProps> = ({
  className,
  whichDirectory,
  heroTypeId,
  skillType,
  skillIndex,
  whichImage,
  title,
  alt,
  width,
  height,
  style,
  local,
  currentFormIndex,
  rarity,
}) => {
  console.log(`NextGenImage rarity`, rarity);

  let theImageName = whichImage;
  if (heroTypeId) {
    if (rarity === '6' || rarity === 'mythic') {
      const formIndex = (currentFormIndex ?? 0) + 1; // Default to 0 if undefined
      theImageName = `${heroTypeId}_f${formIndex}_s${skillIndex}`;
    } else {
      theImageName = `${heroTypeId}_s${skillIndex}`;
    }
  }

  const [srcMinusExtension, setSrcMinusExtension] = React.useState(
    `https://ik.imagekit.io/raidchamps/${whichDirectory}/${theImageName}`
  );

  const handleOnError = React.useCallback(() => {
    // set to a default image based on whether the image is local or not
    const defaultImage = local
      ? `/img/${whichDirectory}/${theImageName}`
      : `https://ik.imagekit.io/raidchamps/${whichDirectory}/${theImageName}`;
    setSrcMinusExtension(defaultImage);
  }, [whichDirectory, theImageName, local]);

  useEffect(() => {
    if (local) {
      setSrcMinusExtension(`/img/${whichDirectory}/${theImageName}`);
    } else {
      setSrcMinusExtension(`https://ik.imagekit.io/raidchamps/${whichDirectory}/${theImageName}`);
    }
  }, [local, whichDirectory, theImageName]);

  return (
    <picture>
      <source srcSet={`${srcMinusExtension}.avif`} type="image/avif" onError={handleOnError} />
      <source srcSet={`${srcMinusExtension}.webp`} type="image/webp" onError={handleOnError} />
      <img
        //loading="lazy"
        decoding="async"
        src={`${srcMinusExtension}.png`}
        className={className}
        title={title}
        alt={alt}
        width={width}
        height={height}
        style={style}
        onError={handleOnError}
      />
    </picture>
  );
};
