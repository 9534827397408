import { updateURLWithFilterSettings } from 'Components/DataView/Helpers';
import { makeObservable, observable, computed, action } from 'mobx';
import React from 'react';
import { ArtifactType, ChampionItem, DataItem } from '../Types';
import { SortDescriptor, FilterDescriptor, FilterFunction } from './ListViewTypes';

export interface ViewOptionsBase {
  defaultSort: string[];
}

export class ListViewStore<T extends DataItem = DataItem, TOptions extends ViewOptionsBase = ViewOptionsBase> {
  private sortBy: readonly string[] = [];

  constructor(
    private readonly sortProviders: Record<string, SortDescriptor<T, TOptions>>,
    private readonly filters: Record<string, FilterDescriptor<T, TOptions>>,
    private readonly viewOptions: TOptions
  ) {
    makeObservable<ListViewStore<T, TOptions>, 'sortProviders' | 'filters' | 'sortBy' | 'options' | 'viewOptions'>(
      this,
      {
        sortProviders: false,
        filters: false,
        options: false,
        viewOptions: false,
        sortBy: observable.ref,
        sort: false,
        sortOptions: false,

        // actions
        filterAndSort: false,
        setSort: action.bound,
      }
    );
    updateURLWithFilterSettings(Object.values(this.filters));
  }

  public get options() {
    return this.viewOptions;
  }

  public get sort(): readonly string[] {
    return this.sortBy.length ? this.sortBy : this.viewOptions.defaultSort;
  }

  public get sortOptions(): [key: string, name: React.ReactNode][] {
    return Object.entries(this.sortProviders)
      .filter((entry) => entry[1].canHandle(this.options))
      .map((entry) => [entry[0], entry[1].name]);
  }

  public setSort(sortBy: readonly string[] | null) {
    this.sortBy = sortBy ?? [];
  }

  public filterAndSort(items: ChampionItem[] | ArtifactType[]) {
    // cache filters so sort can change independently
    const filteredData = computed(() => {
      const filterFns = Object.values(this.filters)
        .filter((filter) => {
          //console.log('Filter object:', filter); // Log to check each filter object
          if (filter && typeof filter.canHandle === 'function') {
            return filter.canHandle(this.viewOptions);
          } else {
            console.warn('canHandle is not a function', filter);
            return false;
          }
        })
        .map((filter) => filter.filterFn)
        .filter(Boolean) as FilterFunction<any>[];
      return items.filter((item: any) => filterFns.every((fn) => fn(this as any, item)));
    }).get();

    // cache sorted result
    return computed(() =>
      filteredData.slice().sort((a: T, b: T) => {
        for (let sortKey of this.sort) {
          // how many stupid decisions can I put in one line of code?
          const dir = sortKey.startsWith('-') ? ((sortKey = sortKey.substring(1)), -1) : 1;
          const provider = this.sortProviders[sortKey];
          if (!provider) continue;
          const value = provider.sortFn(a, b) * dir;
          if (value !== 0) return value;
        }
        return 0;
      })
    ).get();
  }
}
