import { ArenaRoles, LiveArena } from 'Data/Updater/DataSources/HellHades';
import { NumberLike } from 'Types';

export enum RatingSource {
  Ayumilove = 'ayumilove',
  HellHades = 'hellhades',
  ChoseN = 'chosen',
  ChoseNExtra = 'chosenExtra',
}

export enum RatingArea {
  Arena = 'Arena',
  IronTwins = 'IronTwins',
  SandDevil = 'SandDevil',
  PhantomShogun = 'PhantomShogun',
  PotionKeepArcane = 'PotionKeepArcane',
  PotionKeepForce = 'PotionKeepForce',
  PotionKeepMagic = 'PotionKeepMagic',
  PotionKeepSpirit = 'PotionKeepSpirit',
  PotionKeepVoid = 'PotionKeepVoid',
  Campaign = 'Campaign',
  Dragon = 'Dragon',
  Spider = 'Spider',
  FireKnight = 'FireKnight',
  IceGolem = 'IceGolem',
  HardDragon = 'HardDragon',
  HardSpider = 'HardSpider',
  HardFireKnight = 'HardFireKnight',
  HardIceGolem = 'HardIceGolem',
  Minotaur = 'Minotaur',
  FactionWars = 'FactionWars',
  FWDecreaseDef = 'FWDecreaseDef',
  FWDecreaseAtk = 'FWDecreaseAtk',
  FWDamage = 'FWDamage',
  FWCrowdControl = 'FWCrowdControl',
  FWTurnMeterControl = 'FWTurnMeterControl',
  FWHealingAndShielding = 'FWHealingAndShielding',
  FWReviver = 'FWReviver',
  DoomTower = 'DoomTower',
  DemonLord = 'DemonLord',
  Hydra = 'Hydra',
  ArenaOverall = 'ArenaOverall',
  ArenaAttack = 'ArenaAttack',
  ArenaDefense = 'ArenaDefense',
  AllDungeons = 'AllDungeons',
  DTBossAgreth = 'DTBossAgreth',
  DTBossAstranyx = 'DTBossAstranyx',
  DTBossBommal = 'DTBossBommal',
  DTBossBorgoth = 'DTBossBorgoth',
  DTBossIragoth = 'DTBossIragoth',
  DTBossKuldath = 'DTBossKuldath',
  DTBossCelestialGriffin = 'DTBossCelestialGriffin',
  DTBossSorath = 'DTBossSorath',
  Scarcity = 'Scarcity',
  Progression = 'Progression',
  EndGame = 'EndGame',
}

export interface MasteryRecommendation {
  title: string;
  masteries: string[];
}

export interface BlessingRecommendation {
  title: string;
  blessing: string[];
  teamBlessing: boolean;
}

export interface Rating {
  source: RatingSource;
  base100: number;
  base5: number;
  overall: string;
  bookRating?: number;
  bookPriority?: string[];
  videoUrls?: string[];
  images?: string[];
  videoGuideUrl?: string;
  videoGuideId?: string;
  infographicId?: string;
  areaRatings?: Partial<Record<RatingArea, number>>;
  fwRatings?: Partial<Record<RatingArea, number>>;
  pve_sets?: string[];
  pve_stats?: string[];
  pvp_sets?: string[];
  pvp_stats?: string[];
  pvp_masteries?: MasteryRecommendation[] | undefined;
  pve_masteries?: MasteryRecommendation[] | undefined;
  fw_decrease_def?: string[];
  fw_decrease_atk?: string[];
  fw_damage?: string[];
  fw_crowd_control?: string[];
  fw_tm_control?: string[];
  fw_healing_shielding?: string[];
  fw_reviver?: string[];
  blessings?: BlessingRecommendation[] | undefined;
  arenaRolesRating?: number;
  arenaRoles?: ArenaRoles;
  liveArena?: LiveArena;
}

export type RatingSet = Partial<Record<RatingSource, Rating>>;

export interface ChampionRatings {
  overall: number;
  detailed: RatingSet;
}

export interface ArtifactRatings {
  overall: number;
  detailed: RatingSet;
}
