import localforage from 'localforage';

const dbName = 'raidchamps';
const driver = [localforage.INDEXEDDB, localforage.LOCALSTORAGE];

localforage.config({
  driver,
  name: dbName,
  description: 'RaidChamps.com user storage',
  storeName: 'index',
  version: 1.0,
});

export function resetStores() {
  return localforage.dropInstance({ name: dbName });
}

export function createStore(storeName: string) {
  return localforage.createInstance({
    driver,
    name: dbName,
    storeName,
  });
}
