import { ArtifactTile } from 'Components';
import { getGlobalRootStore, useRootStore } from 'Model';
import React from 'react';
import { ArtifactType, ChampionItem, GearSlot } from 'Types';
import { getEquippedArtifacts } from './DataView/Helpers';
import { ArtifactItem } from '../Types/Entities/ArtifactItem';

export interface ChampionArtifactsProps {
  item: ChampionItem;
  statsOutsideArtifact?: boolean;
  local?: boolean;
}

let artifacts: any[] | Record<number, ArtifactType>;
let artifactIds;
let arenaLeague;
let greatHallLevels;
let factionGuardians;
let allEquippedArtifacts: any[];

/* Not sure what it is complaining about but everything seems to work...
 Type '({ item }: PropsWithChildren<ChampionArtifactsProps>) => Element | undefined' is not assignable to type 'FC<ChampionArtifactsProps>'.
  Type 'Element | undefined' is not assignable to type 'ReactElement<any, any> | null'.
    Type 'undefined' is not assignable to type 'ReactElement<any, any> | null'.
 */
export const ChampionArtifacts: React.FC<ChampionArtifactsProps> = ({ item, statsOutsideArtifact, local }) => {
  const store = useRootStore();
  const championItem = item;
  const instance = championItem?.instance;

  const rootStore = getGlobalRootStore();
  const account = rootStore.accounts.selectedAccount;
  artifacts = account?.artifacts || [];

  if (instance) {
    artifactIds = Object.values(instance.equippedArtifactIds);

    arenaLeague = store.accounts.selectedAccount?.arena.classic.league;
    /* console.log("arenaLeague:", arenaLeague) */
    greatHallLevels = store.accounts.selectedAccount?.arena.classic.greatHallBonuses;
    //factionGuardians = store.accounts.selectedAccount?.factionGuardians;

    allEquippedArtifacts = getEquippedArtifacts(item);

    //const champStatsData = getChampionArtifactsData(champStats);
    return (
      <section className={`Artifacts-section ${statsOutsideArtifact === false && 'statsInsideArtifact'}`}>
        {allEquippedArtifacts.map((item: ArtifactItem) => {
          if (item.kindId) {
            return (
              <ArtifactTile
                gearSlot={GearSlot[item.kindId]}
                item={item}
                statsOutsideArtifact={statsOutsideArtifact}
                local={local}
              />
            );
          } else {
            return (
              <ArtifactTile
                gearSlot={GearSlot[item]}
                item={item}
                statsOutsideArtifact={statsOutsideArtifact}
                local={local}
              />
            );
          }
        })}
      </section>
    );
  }
};
