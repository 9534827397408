import React, { ReactNode, useCallback } from 'react';
import styled from 'styled-components';
import { useHover } from '@huse/hover';
import { Clear, ArrowForward, ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { SortKey, parseKey } from './SortKey';
import Sortable from './Sortable';
import Tooltip from 'rc-tooltip';

const Layout = styled(Sortable)`
  display: inline-flex;
  align-items: center;
  padding: 0 4px;
  border: 1px solid transparent;
  cursor: move;

  &:hover {
    border: 1px dashed #999;
  }
`;

interface Props<K extends string> {
  value: SortKey<K>;
  children: ReactNode;
  onDelete: (value: K) => void;
  onChangeDirection: (value: K) => void;
}

export default function Item<K extends string>({ value, children, onDelete, onChangeDirection }: Props<K>) {
  const [key, dir] = parseKey<K>(value);
  const requestDelete = useCallback(() => onDelete(key), [value, onDelete]);
  const requestChangeDirection = useCallback(() => onChangeDirection(key), [value, onChangeDirection]);
  const [isHover, hoverListeners] = useHover();
  const sortIcon =
    dir === 1 ? (
      <Tooltip
        arrowContent={<div className="rc-tooltip-arrow-inner" />}
        placement="bottom"
        overlay={<span>Reverse sort to descending order</span>}
      >
        <ArrowDropDown aria-label="Descending" role="button" className="icon-button" onClick={requestChangeDirection} />
      </Tooltip>
    ) : (
      <Tooltip
        arrowContent={<div className="rc-tooltip-arrow-inner" />}
        placement="bottom"
        overlay={<span>Reverse sort to ascending order</span>}
      >
        <ArrowDropUp aria-label="Ascending" role="button" className="icon-button" onClick={requestChangeDirection} />
      </Tooltip>
    );

  return (
    <Layout id={value.toString()} {...hoverListeners} className='SortedItemLayout'>
      <Tooltip
        arrowContent={<div className="rc-tooltip-arrow-inner" />}
        placement="bottom"
        overlay={<span>Hold click & drag this sort option into the order you want</span>}
      >
        <span className="mr-1">{children}</span>
      </Tooltip>
      {sortIcon}
      {(isHover && (
        <Tooltip
          arrowContent={<div className="rc-tooltip-arrow-inner" />}
          placement="bottom"
          overlay={<span>Remove this sort option from the sort order</span>}
        >
          <Clear className="icon-button" fontSize="small" onClick={requestDelete} />
        </Tooltip>
      )) || <ArrowForward fontSize="small" onClick={requestDelete} />}
    </Layout>
  );
}
