import React from 'react';

export const NoAccountData: React.FC = () => {
  return (
    <>
      <div className="container">
        <h2 className="yellow">It looks like you still need to connect to Raid Toolkit</h2>
        <a href="https://raid-toolkit.github.io/pages/installation/" className="btn" target="_blank" rel="noopener">
          Click here to learn how to install it.
        </a>
        <p>
          After it is installed it will run in your system tray and refreshes your account data every 10 seconds or so.
        </p>
        <p>
          Then all you need to do is click this plug icon at the top of any page to connect the site to Raid Toolkit.
          <br />
          <img src="https://imgsh.net/i/gVhhwO9.png" />
        </p>
        <p>
          From then on as long as you have Raid running via Plarium Play, your account data will automatically be synced
          with this site! Your data will remain available even while Raid is not running, it just will not be able to
          sync until you have it running again.
        </p>
        <p>
          There are also more options available if you click the App Icon that is under the plug icon which changes to
          your accounts avatar image after connected (If you use a newer special avatar then it will just use the Raid
          Icon since it is hard to keep all the new special avatars updated)...
          <br />
          <img src="https://imgsh.net/i/VuiyEco.png" />
          <br />• You can select which account you want to sync if you have more than one.
          <br />• You can click <b>"Export"</b> to download a JSON file of all your account data and share it with your
          friends and clan members
          <br />• You can click <b>"Import"</b> to load your account data from the JSON file you can get using the
          export button or using the{' '}
          <a href="https://github.com/raid-toolkit/extractor/releases/latest" target="_blank" rel="noopener">
            RTK Extractor
          </a>
          . And you can also use it to import a friends account data if they shared their exported JSON file with you.
          <br />• You can also click <b>"Clear all data"</b> to remove all your account data from the site
        </p>
        <h3 className="mb-0 mt-3 yellow">Raid Toolkit Tips</h3>
        <p>
          The RTK icon in the system tray looks similar to the Raid App Icon and it can be clicked to launch Raid for
          you
        </p>
        <h3 className="mb-0 mt-3 yellow">Using Raid Toolkit with RSL Helper Tips</h3>
        <p>
          To avoid having to run Raid Toolkit as Admin, I suggest turning off{' '}
          <b>"Start Raid automatically with RSL Helper"</b> in your RSLH settings and then just click the Raid Toolkit
          icon in your system tray to launch Raid. Just make sure to open RSL Helper first if you want the Event Tracker
          to work.
        </p>
      </div>
    </>
  );
};
