import React from 'react';
import { Link } from 'react-router-dom';
import './Hotkeys.css';
import { NextGenImage } from '../../Components/Library';
import { Helmet } from 'react-helmet-async';
import ResetFiltersHotkey from 'Components/Library/ResetFiltersHotkey';
import SaveFiltersHotkey from 'Components/Library/SaveFiltersHotkey';
import ToggleChampPagesHotkey from 'Components/Library/ToggleChampPagesHotkey';

export const Hotkeys: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Hotkeys | RaidChamps.com</title>
        <meta name="description" content="Set Hotkeys to quickly perform specific functions on the site." />
        <meta itemProp="name" content="Hotkeys | RaidChamps.com" />
        <meta itemProp="description" content="Set Hotkeys to quickly perform specific functions on the site." />
        <meta itemProp="image" content="https://ik.imagekit.io/raidchamps/_NotExtracted/logo.avif" />
        <meta property="og:url" content="https://raidchamps.com/mychamps" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Hotkeys | RaidChamps.com" />
        <meta property="og:description" content="Set Hotkeys to quickly perform specific functions on the site." />
        <meta property="og:image" content="https://ik.imagekit.io/raidchamps/_NotExtracted/logo.avif" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Hotkeys | RaidChamps.com" />
        <meta name="twitter:description" content="Set Hotkeys to quickly perform specific functions on the site." />
        <meta name="twitter:image" content="https://ik.imagekit.io/raidchamps/_NotExtracted/logo.avif" />
      </Helmet>
      <div className="about-page page">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 center">
              <div className="text-center">
                <h1 className="with-underline">Hotkeys</h1>
                <p className="small" style={{ width: 'fit-content', margin: '0 auto' }}>
                  <span className="yellow">Note: </span>Some key combinations may not work because they are reserved by
                  the browser you are using.
                </p>
              </div>
              <h3>Toggle Champ Pages</h3>
              <p>
                <ToggleChampPagesHotkey />
              </p>

              <h3>Reset Filters</h3>
              <p>
                <ResetFiltersHotkey />
              </p>

              <h3>Save Filters</h3>
              <p>
                <SaveFiltersHotkey />
              </p>

              <h3>More hotkeys to come!</h3>
              <p>
                I have not thought very hard about what other hotkeys would be useful for this site but I know I will
                come up with more now that I know I can do this. Please <Link to="/contact">contact me</Link> if you
                have any ideas for additional hotkeys for me to add.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
