import { getBlessingRarity } from 'Components/DataView/Helpers';
import React from 'react';
import { NextGenImage } from '../../Components/Library';
import blessingJson from '../../Data/Data/blessing.json';

export interface blessingProps {
  className?: string;
  style?: React.CSSProperties;
  blessing?: string;
  local?: boolean;
  hellHades?: boolean;
  teamBlessing?: boolean;
}

export const Blessing: React.FC<blessingProps> = ({ blessing, style, className, local, hellHades, teamBlessing }) => {
  /*   if (window.location.hostname === 'localhost') {
    console.log(`blessing`, blessing);
    console.log(`actualName`, actualName);
  } */

  let actualName;
  let imageName;
  if (hellHades) {
    console.log(blessing);
    actualName = blessingJson.lookup.blessing[blessing].name;
    imageName = blessingJson.lookup.blessing[blessing].imageName;
    console.log(`HellHades actualName`, actualName);
  } else {
    if (blessing) {
      actualName = blessingJson.lookup.blessing[blessing.toLowerCase()].name;
      //console.log(`Instance actualName`, actualName);
    }
  }

  const rarity = getBlessingRarity(actualName);
  return (
    <div className={`blessingIcon-wrap ${hellHades ? `hellHadesBlessing` : `overAvatarItem`} `}>
      {hellHades ? (
        <div className={`blessingIcon-imageAndTeamBlessingWrap ${rarity}`}>
          {teamBlessing && (
            <NextGenImage
              whichDirectory={`blessings`}
              whichImage={`team`}
              className={`teamBlessing info`}
              alt={`Team Blessing: Consider an alternative blessing if this already exists in your team.`}
              title={`Team Blessing: Consider an alternative blessing if this already exists in your team.`}
              local={local}
            />
          )}
          <NextGenImage
            whichDirectory={`blessings/big`}
            whichImage={imageName}
            className={className}
            style={style}
            alt={actualName}
            title={actualName}
            local={local}
          />
        </div>
      ) : (
        <>
          <NextGenImage
            whichDirectory={`blessings`}
            whichImage={`frame_${rarity}`}
            className={`blessingFrame`}
            style={style}
            alt={`${actualName}`}
            title={`${actualName}`}
            local={local}
          />

          <NextGenImage
            whichDirectory={`blessings/small`}
            whichImage={blessing}
            className={className}
            style={style}
            alt={actualName}
            title={actualName}
            local={local}
          />
        </>
      )}
    </div>
  );
};
