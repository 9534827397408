import { parseEnum } from 'Helpers';
import { StatSource } from '../../Model/HeroStatsBuilder';
import { ChampionItem, StatKind, Stats } from '../../Types';
import { useRootStore } from 'Model';

export const hpScalar = 15.003713524358135;
export const maxLevelMultiplier = 11.0117;

export const statKindToStatName: Record<StatKind, string> = {
  [StatKind.health]: 'HP',
  [StatKind.attack]: 'ATK',
  [StatKind.defense]: 'DEF',
  [StatKind.speed]: 'SPD',
  [StatKind.criticalChance]: 'C.RATE',
  [StatKind.criticalDamage]: 'C.DMG',
  [StatKind.criticalHeal]: 'C.HEAL',
  [StatKind.resistance]: 'RES',
  [StatKind.accuracy]: 'ACC',
};

export function getStatName(statKindLike: string): string {
  return statKindToStatName[parseEnum(statKindLike, StatKind)];
}

export const orderedStatNameToStatKind: Record<string, StatKind> = {
  HP: StatKind.health,
  ATK: StatKind.attack,
  DEF: StatKind.defense,
  SPD: StatKind.speed,
  'C.RATE': StatKind.criticalChance,
  'C.DMG': StatKind.criticalDamage,
  RES: StatKind.resistance,
  ACC: StatKind.accuracy,
};

export const statSourceKeys: StatSource[] = [
  StatSource.base,
  StatSource.gear,
  StatSource.gearSets,
  StatSource.greatHall,
  StatSource.arena,
  StatSource.masteries,
  StatSource.factionGuardians,
  StatSource.empowerment,
  StatSource.blessing,
  StatSource.total,
];

export function scaleBaseStats({
  accuracy,
  attack,
  criticalchance,
  criticaldamage,
  criticalheal,
  defense,
  health,
  resistance,
  speed,
}: Stats): Stats {
  return {
    health: health * hpScalar * maxLevelMultiplier,
    attack: attack * maxLevelMultiplier,
    defense: defense * maxLevelMultiplier,
    speed,
    criticalchance,
    criticaldamage,
    criticalheal,
    resistance,
    accuracy,
  };
}

export function getStats(item: ChampionItem) {
  const account = useRootStore().accounts.selectedAccount;
  const stats = item?.instance && account?.championStatsMap[item?.instance.id];

  return stats;
}

export function roundTo(value: number, to: number = 0) {
  return Math.round(value * Math.pow(10, to)) / Math.pow(10, to);
}

export function formatStatValue(value: number, absolute: boolean, precision: number = 0) {
  if (absolute) {
    return roundTo(value, precision).toLocaleString();
  }
  return `${roundTo(value, precision).toLocaleString()}%`;
}

export const formatStat: Record<StatKind, (value: number) => string> = {
  [StatKind.none]: () => '',
  [StatKind.health]: (value) => formatStatValue(value, true),
  [StatKind.attack]: (value) => formatStatValue(value, true),
  [StatKind.defense]: (value) => formatStatValue(value, true),
  [StatKind.speed]: (value) => formatStatValue(value, true, 2),
  [StatKind.criticalChance]: (value) => formatStatValue(value, false, 0),
  [StatKind.criticalDamage]: (value) => formatStatValue(value, false, 0),
  [StatKind.criticalHeal]: (value) => formatStatValue(value, false, 0),
  [StatKind.resistance]: (value) => formatStatValue(value, true),
  [StatKind.accuracy]: (value) => formatStatValue(value, true),
};

export const formatStatSource: Record<StatSource, (statKind: StatKind, value: number) => string> = {
  [StatSource.base]: (statKind, value) => (value ? `${formatStat[statKind](value)}` : ''),
  [StatSource.gear]: (statKind, value) => (value ? `+${formatStat[statKind](value)}` : ''),
  [StatSource.gearSets]: (statKind, value) => (value ? `+${formatStat[statKind](value)}` : ''),
  [StatSource.greatHall]: (statKind, value) => (value ? `+${formatStat[statKind](value)}` : ''),
  [StatSource.arena]: (statKind, value) => (value ? `+${formatStat[statKind](value)}` : ''),
  [StatSource.masteries]: (statKind, value) => (value ? `+${formatStat[statKind](value)}` : ''),
  [StatSource.factionGuardians]: (statKind, value) => (value ? `+${formatStat[statKind](value)}` : ''),
  [StatSource.empowerment]: (statKind, value) => (value ? `+${formatStat[statKind](value)}` : ''),
  [StatSource.blessing]: (statKind, value) => (value ? `+${formatStat[statKind](value)}` : ''),
  [StatSource.total]: (statKind, value) => (value ? `${formatStat[statKind](value)}` : ''),
};
