import { DisplayMetadata, getTargetTypes } from '../../Data';
import { ChampionInstance, ChampionItem } from '../../Types';
import { ObservableSelectFilter } from './Base/MultiSelectFilterBase';
import { ObservableRangeFilter } from './Base/RangeFilter';
import { ObservableToggleFilter } from './Base/ToggleFilter';
import { ViewOptions, ViewType } from './ViewOptions';

export const AdvancedSkillIndexRangeFilter = new ObservableRangeFilter<ChampionItem>({
  id: 'advancedSkillIndex',
  label: 'SkillIndex',
  min: 0,
  max: 6,
  minWidth: 40,
  canHandle(opts: ViewOptions) {
    return opts.viewType === ViewType.Account || opts.viewType === ViewType.Index;
  },
  filter(min, max, item) {
    return item;
  },
});

export const AdvancedHitsRangeFilter = new ObservableRangeFilter<ChampionItem>({
  id: 'advancedHits',
  label: 'Hits',
  min: 0,
  max: 6,
  minWidth: 40,
  canHandle(opts: ViewOptions) {
    return opts.viewType === ViewType.Account || opts.viewType === ViewType.Index;
  },
  filter(min, max, item) {
    return item;
  },
});

export const AdvancedCooldownRangeFilter = new ObservableRangeFilter<ChampionItem>({
  id: 'advancedCooldown',
  label: 'Cooldown',
  min: 0,
  max: 6,
  minWidth: 40,
  canHandle(opts: ViewOptions) {
    return opts.viewType === ViewType.Account || opts.viewType === ViewType.Index;
  },
  filter(min, max, item) {
    return item;
  },
});

export const AdvancedFullyBookedCooldownRangeFilter = new ObservableRangeFilter<ChampionItem>({
  id: 'advancedFullyBookedCooldown',
  label: 'Fully Booked Cooldown',
  min: 0,
  max: 6,
  minWidth: 40,
  canHandle(opts: ViewOptions) {
    return opts.viewType === ViewType.Account || opts.viewType === ViewType.Index;
  },
  filter(min, max, item) {
    return item;
  },
});

export const AdvancedMyCooldownRangeFilter = new ObservableRangeFilter<ChampionItem>({
  id: 'advancedMyCooldown',
  label: 'My Cooldown',
  min: 0,
  max: 6,
  minWidth: 40,
  canHandle(opts: ViewOptions) {
    return opts.viewType === ViewType.Account;
  },
  filter(min, max, item) {
    return item;
  },
});

export const AdvancedDurationRangeFilter = new ObservableRangeFilter<ChampionItem>({
  id: 'advancedDuration',
  label: 'Duration',
  min: 0,
  max: 6,
  minWidth: 40,
  canHandle(opts: ViewOptions) {
    return opts.viewType === ViewType.Account || opts.viewType === ViewType.Index;
  },
  filter(min, max, item) {
    return item;
  },
});

export const AdvancedChanceToPlacePercentRangeFilter = new ObservableRangeFilter<ChampionItem>({
  id: 'advancedChanceToPlacePercent',
  label: '% Chance',
  min: 0,
  max: 100,
  minWidth: 40,
  canHandle(opts: ViewOptions) {
    return opts.viewType === ViewType.Account || opts.viewType === ViewType.Index;
  },
  filter(min, max, item) {
    return item;
  },
});

export const AdvancedFullyBookedChanceToPlacePercentRangeFilter = new ObservableRangeFilter<ChampionItem>({
  id: 'advancedFullyBookedChanceToPlacePercent',
  label: 'Fully Booked % Chance',
  min: 0,
  max: 100,
  minWidth: 40,
  canHandle(opts: ViewOptions) {
    return opts.viewType === ViewType.Account || opts.viewType === ViewType.Index;
  },
  filter(min, max, item) {
    return item;
  },
});

export const AdvancedMyChanceToPlacePercentRangeFilter = new ObservableRangeFilter<ChampionItem>({
  id: 'advancedMyChanceToPlacePercent',
  label: 'My % Chance',
  min: 0,
  max: 100,
  minWidth: 40,
  canHandle(opts: ViewOptions) {
    return opts.viewType === ViewType.Account;
  },
  filter(min, max, item) {
    return item;
  },
});

const AdvancedA1SingleToggleFilterOptions: Record<string, DisplayMetadata> = {
  no: {
    color: 'red',
    name: 'No',
  },
  off: {
    name: 'A1?',
  },
  yes: {
    color: 'lime',
    name: 'Yes',
  },
};
export const AdvancedA1SingleToggleFilter = new ObservableToggleFilter<ChampionItem, ChampionInstance>({
  items: AdvancedA1SingleToggleFilterOptions,
  defaultValue: 'off',
  id: 'advancedA1',
  save: true,
  saveAsString: true,
  canHandle(opts: ViewOptions) {
    return opts.viewType === ViewType.Account || opts.viewType === ViewType.Index;
  },
  filter(value, item) {
    switch (value) {
      case 'true':
        return true;
    }
    return true;
  },
});

export const specificCaseFixes = [
  { target: '0', effect: '360', name: 'Foli', type: 'name', shouldShow: false },
  { target: '7', effect: '360', name: 'Gamuran', type: 'name', shouldShow: false },
  { target: '0', effect: '110', name: 'Phranox', type: 'name', shouldShow: true },
  { target: '7', effect: '170', name: 'Shatterbones', type: 'name', shouldShow: true },
  { target: '0', effect: '170', name: 'Lord Champfort', type: 'name', shouldShow: true },
  { target: '7', effect: '290', name: 'Countess Lix', type: 'name', shouldShow: true },
  { target: '0', effect: '150', name: 'Cudgeler', type: 'name', shouldShow: true },
  { target: '0', effect: '150', name: 'Ifrit', type: 'name', shouldShow: true },
  { target: '0', effect: '171', name: 'Broodlord', type: 'name', shouldShow: true },
  { target: '0', effect: '491', name: 'Elder Skarg', type: 'name', shouldShow: true },
  { target: '0', effect: '491', name: 'Skull Lord Var-Gall', type: 'name', shouldShow: true },
  { target: '7', effect: '20', name: 'Rae', type: 'name', shouldShow: true },
  { target: '7', effect: '10', name: 'Archmage Hellmut', type: 'name', shouldShow: true },
  { target: '7', effect: '350', name: 'Pyxniel', type: 'name', shouldShow: true },
  { target: '0', effect: '350', name: 'Knight-Errant', type: 'name', shouldShow: true },
  { target: '0', effect: '351', name: 'Interceptor', type: 'name', shouldShow: true },
  { target: '1', effect: '60', name: 'Varl the Destroyer', type: 'name', shouldShow: true },
  { target: '1', effect: '60', name: 'Basilisk', type: 'name', shouldShow: true },
  { target: '1', effect: '60', name: 'Sandlashed Survivor', type: 'name', shouldShow: true },
  { target: '0', effect: '60', name: 'Goffred Brassclad', type: 'name', shouldShow: true },
  { target: '7', effect: '100', name: 'Maneater', type: 'name', shouldShow: true },
  { target: '0', effect: '100', name: 'Jingwon', type: 'name', shouldShow: true },
  { target: '1', effect: '100', name: 'Painsmith', type: 'name', shouldShow: true },
  { target: '7', effect: '91', name: 'Grunch Killjoy', type: 'name', shouldShow: true },
  { target: '7', effect: '91', name: 'Jareg', type: 'name', shouldShow: true },
  { target: '0', effect: '91', name: 'Oella', type: 'name', shouldShow: true },
  { target: '0', effect: '91', name: 'Bloodhorn', type: 'name', shouldShow: true },
  { target: '1', effect: '91', name: 'Lanakis the Chosen', type: 'name', shouldShow: true },
  { target: '1', effect: '91', name: 'Captain Temila', type: 'name', shouldShow: true },
  { target: '1', effect: '91', name: 'Totem', type: 'name', shouldShow: true },
  { target: '1', effect: '90', name: 'Geargrinder', type: 'name', shouldShow: true },
  { target: '1', effect: '90', name: 'Ragemonger', type: 'name', shouldShow: true },
  { target: '7', effect: '30', name: 'Chancellor Yasmin', type: 'name', shouldShow: true },
  { target: '0', effect: '30', name: 'Hellhound', type: 'name', shouldShow: true },
  { target: '0', effect: '20', name: 'Gronjarr', type: 'name', shouldShow: true },
  { target: '0', effect: '20', name: 'Tatura Rimehide', type: 'name', shouldShow: true },
  { target: '0', effect: '20', name: 'Supreme Athel', type: 'name', shouldShow: true },
  { target: '0', effect: '440', name: 'Thylessia', type: 'name', shouldShow: true },
  { target: '0', effect: '440', name: 'Cromax Moonblood', type: 'name', shouldShow: true },
  { target: '0', effect: '460', name: 'Sniktraak', type: 'name', shouldShow: true },
  { target: '0', effect: '460', name: 'Vogoth', type: 'name', shouldShow: true },
  { target: '0', effect: '40', name: 'Angar', type: 'name', shouldShow: true },
  { target: '0', effect: '80', name: 'Toragi The Frog', type: 'name', shouldShow: true },
  { target: '5', effect: '80', name: 'Chaagur', type: 'name', shouldShow: true },
  { target: '0', effect: '50', name: 'Riscarm', type: 'name', shouldShow: true },
  { target: '1', effect: '50', name: 'Ginro the Stork', type: 'name', shouldShow: true },
  { target: '1', effect: '50', name: 'Helicath', type: 'name', shouldShow: true },
  { target: '1', effect: '50', name: 'Tallia', type: 'name', shouldShow: true },
  { target: '1', effect: '50', name: 'Cudgeler', type: 'name', shouldShow: true },
  { target: '1', effect: '50', name: 'Elder', type: 'name', shouldShow: true },
  { target: '1', effect: '50', name: 'Executioner', type: 'name', shouldShow: true },
  { target: '1', effect: '50', name: 'Malbranche', type: 'name', shouldShow: true },
  { target: '1', effect: '50', name: 'Penitent', type: 'name', shouldShow: true },
  { target: '1', effect: '50', name: 'Skullsworn', type: 'name', shouldShow: true },
  { target: '0', effect: '221', name: 'Stag Knight', type: 'name', shouldShow: true },
  { target: '1', effect: '121', name: 'Holsring', type: 'name', shouldShow: true },
  { target: '1', effect: '121', name: 'Little Miss Annie', type: 'name', shouldShow: true },
  { target: '1', effect: '121', name: 'Royal Huntsman', type: 'name', shouldShow: true },
  { target: '1', effect: '121', name: 'Gory', type: 'name', shouldShow: true },
  { target: '1', effect: '121', name: 'Hexia', type: 'name', shouldShow: true },
  { target: '1', effect: '121', name: 'Scabrius', type: 'name', shouldShow: true },
  { target: '1', effect: '121', name: 'Sir Artimage', type: 'name', shouldShow: true },
  { target: '1', effect: '121', name: 'Ultimate Galek', type: 'name', shouldShow: true },
  { target: '1', effect: '121', name: 'Whisper', type: 'name', shouldShow: true },
  { target: '1', effect: '121', name: 'Yelagirna', type: 'name', shouldShow: true },
  { target: '1', effect: '261', name: 'Danag Skullreap', type: 'name', shouldShow: true },
  { target: '1', effect: '261', name: 'Fylja', type: 'name', shouldShow: true },
  { target: '1', effect: '261', name: 'Sir Artimage', type: 'name', shouldShow: true },
  { target: '1', effect: '260', name: 'Boltsmith', type: 'name', shouldShow: true },
  { target: '1', effect: '241', name: 'Elder Skarg', type: 'name', shouldShow: true },
  { target: '1', effect: '241', name: 'Faceless', type: 'name', shouldShow: true },
  { target: '1', effect: '241', name: 'Juliana', type: 'name', shouldShow: true },
  { target: '1', effect: '241', name: 'Tallia', type: 'name', shouldShow: true },
  { target: '1', effect: '240', name: 'Boltsmith', type: 'name', shouldShow: true },
  { target: '0', effect: '141', name: 'Helicath', type: 'name', shouldShow: true },
  { target: '0', effect: '141', name: 'Ginro the Stork', type: 'name', shouldShow: true },
  { target: '0', effect: '141', name: 'Kaiden', type: 'name', shouldShow: true },
  { target: '1', effect: '141', name: 'Teshada', type: 'name', shouldShow: true },
  { target: '1', effect: '141', name: 'Executioner', type: 'name', shouldShow: true },
  { target: '7', effect: '140', name: 'Riscarm', type: 'name', shouldShow: true },
  { target: '1', effect: '140', name: 'Amarantine Skeleton', type: 'name', shouldShow: true },
  { target: '1', effect: '140', name: 'Flailer', type: 'name', shouldShow: true },
  { target: '1', effect: '161', name: 'Elder Skarg', type: 'name', shouldShow: true },
  { target: '1', effect: '161', name: 'Dark Athel', type: 'name', shouldShow: true },
  { target: '1', effect: '161', name: 'Hexia', type: 'name', shouldShow: true },
  { target: '1', effect: '161', name: 'Bombardier', type: 'name', shouldShow: true },
  { target: '1', effect: '161', name: 'Skullsworn', type: 'name', shouldShow: true },
  { target: '1', effect: '161', name: 'Umetogi', type: 'name', shouldShow: true },
  { target: '7', effect: '411', name: 'Lordly Legionary', type: 'name', shouldShow: true },
  { target: '0', effect: '411', name: 'Tatura Rimehide', type: 'name', shouldShow: true },
  { target: '0', effect: '411', name: 'Warchief', type: 'name', shouldShow: true },
  { target: '1', effect: '411', name: 'Vergis', type: 'name', shouldShow: true },
  { target: '0', effect: '300', name: 'Godseeker Aniri', type: 'name', shouldShow: true },
  { target: '0', effect: '300', name: 'Lamibur', type: 'name', shouldShow: true },
  { target: '7', effect: '280', name: 'Krakarth', type: 'name', shouldShow: true },
  { target: '7', effect: '280', name: 'Tagoar', type: 'name', shouldShow: true },
  { target: '0', effect: '280', name: 'Astralon', type: 'name', shouldShow: true },
  { target: '0', effect: '280', name: 'Nekhret the Great', type: 'name', shouldShow: true },
  { target: '1', effect: '280', name: 'Nekhret the Great', type: 'name', shouldShow: true },
  { target: '0', effect: '280', name: 'Vasal of the Seal', type: 'name', shouldShow: true },
  { target: '0', effect: '280', name: 'Reinbeast', type: 'name', shouldShow: true },
  { target: '0', effect: '280', name: 'Shaman', type: 'name', shouldShow: true },
  { target: '0', effect: '280', name: 'Grinner', type: 'name', shouldShow: true },
  { target: '1', effect: '280', name: 'Soulless', type: 'name', shouldShow: true },
  { target: '1', effect: '280', name: 'Versulf the Grim', type: 'name', shouldShow: true },
  { target: '1', effect: '280', name: 'Deathchanter', type: 'name', shouldShow: true },
  { target: '1', effect: '280', name: 'Doomscreech', type: 'name', shouldShow: true },
  { target: '1', effect: '280', name: 'Odachi', type: 'name', shouldShow: true },
  { target: '1', effect: '280', name: 'Riscarm', type: 'name', shouldShow: true },
  { target: '1', effect: '280', name: 'Solaris', type: 'name', shouldShow: true },
  { target: '1', effect: '280', name: 'Gala Longbraids', type: 'name', shouldShow: true },
  { target: '1', effect: '280', name: 'Towering Titan', type: 'name', shouldShow: true },
  { target: '7', effect: '511', name: 'Pythion', type: 'name', shouldShow: true },
  { target: '7', effect: '511', name: 'Carlinia', type: 'name', shouldShow: true },
  { target: '7', effect: '511', name: 'Hoforees the Tusked', type: 'name', shouldShow: true },
  { target: '7', effect: '511', name: 'Krakarth', type: 'name', shouldShow: true },
  { target: '7', effect: '511', name: 'Morag Bronzelock', type: 'name', shouldShow: true },
  { target: '5', effect: '481', name: 'Old Hermit Jorrg', type: 'name', shouldShow: true },
  { target: '1', effect: '481', name: 'Cleopterix', type: 'name', shouldShow: true },
  { target: '1', effect: '481', name: 'Yannica', type: 'name', shouldShow: true },
  { target: '1', effect: '481', name: 'Dirandil', type: 'name', shouldShow: true },
  { target: '1', effect: '481', name: 'Necrohunter', type: 'name', shouldShow: true },
  { target: '1', effect: '481', name: 'Oboro', type: 'name', shouldShow: true },
  { target: '7', effect: '40', name: 'Urost the Soulcage', type: 'name', shouldShow: true },
  { target: '7', effect: '40', name: 'Kantra the Cyclone', type: 'name', shouldShow: true },
  { target: '5', effect: '80', name: 'Gnishak Verminlord', type: 'name', shouldShow: true },
  { target: '7', effect: '80', name: 'Gnishak Verminlord', type: 'name', shouldShow: true },
  { target: '0', effect: '81', name: 'Infiltrator', type: 'name', shouldShow: true },
  { target: '7', effect: '491', name: 'Yoshi the Drunkard', type: 'name', shouldShow: true },
  { target: '0', effect: '360', name: 'Inithwe Bloodtwin', type: 'name', shouldShow: true },
  { target: '7', effect: '360', name: 'Inithwe Bloodtwin', type: 'name', shouldShow: true },
  { target: '7', effect: '231', name: 'Judge', type: 'name', shouldShow: true },
  { target: '7', effect: '171', name: 'Judge', type: 'name', shouldShow: true },
  { target: '7', effect: '131', name: 'Judge', type: 'name', shouldShow: true },
  { target: '7', effect: '151', name: 'Judge', type: 'name', shouldShow: true },
  { target: '0', effect: '231', name: 'Judge', type: 'name', shouldShow: true },
  { target: '0', effect: '171', name: 'Judge', type: 'name', shouldShow: true },
  { target: '0', effect: '131', name: 'Judge', type: 'name', shouldShow: true },
  { target: '0', effect: '151', name: 'Judge', type: 'name', shouldShow: true },
  { target: '7', effect: '171', name: 'big-un', type: 'slug', shouldShow: true },
  { target: '1', effect: '60', name: 'zii-ixchi', type: 'slug', shouldShow: true },
  { target: '1', effect: '91', name: 'nogdar-the-headhunter', type: 'slug', shouldShow: true },
  { target: '1', effect: '91', name: 'bergoth-the-malformed', type: 'slug', shouldShow: true },
  { target: '0', effect: '80', name: 'toragi-the-frog', type: 'slug', shouldShow: true },
  { target: '0', effect: '50', name: 'endalia', type: 'slug', shouldShow: true }, //Not working for some reason
  { target: '1', effect: '411', name: 'Warchief', type: 'name', shouldShow: true }, //Not working for some reason
  { target: '1', effect: '411', name: 'Balthus Drauglord', type: 'name', shouldShow: true }, //Not working for some reason
  { target: '7', effect: '320', name: 'Meaneater', type: 'name', shouldShow: true }, //Not working for some reason
  { target: '1', effect: '320', name: 'Georgid the Breaker', type: 'name', shouldShow: true }, //Not working for some reason
  { target: '1', effect: '320', name: 'Maranix', type: 'name', shouldShow: true }, //Not working for some reason
  { target: '1', effect: '320', name: 'Bushi', type: 'name', shouldShow: true }, //Not working for some reason
  { target: '1', effect: '320', name: 'Umbral Enchantress', type: 'name', shouldShow: true }, //Not working for some reason
  { target: '1', effect: '320', name: 'Hatchet Slinger', type: 'name', shouldShow: true }, //Not working for some reason
  //Warchief actually has Reflect Damage 60% which nobody else has so not using 412 as an option unless more get it
  //{ target: '0', effect: '412', name: 'Warchief', type: 'name', shouldShow: true },
  //{ target: '1', effect: '412', name: 'Warchief', type: 'name', shouldShow: true },
];

/* 

If I decide there is a use for a SkillIndex range filter but it seems pointless since people only care if it is an A1 or not an A1...


function checkStatusEffect(
  item: ChampionItem,
  effect: {
    id?: number;
    kindId?: EffectKindId;
    typeId?: StatusEffectTypeId;
    chance?: number | undefined;
    duration?: number;
    cooldown?: number;
    stackCount?: number;
    targetParams: any;
    applyStatusEffectParams: any;
    group?: EffectGroup | undefined;
    relation?: EffectRelation | undefined;
  },
  abilityData: AbilityType,
  selectedStatusEffect: string | undefined,
  selectedTarget: string | undefined,
  durationRange: { min: any; max: any },
  cooldownRange: { min: any; max: any },
  a1ToggleState: string | undefined,
  effects: any[],
  thisChampHasBlockRevive?: boolean,
  thisChampHasTrueFear?: boolean,
  thisChampHasBigPoison?: boolean
) {
  const statusEffectInfos = effect?.applyStatusEffectParams?.statusEffectInfos;

  let thisChampsA1SkillTypeId;
  let thisChampsA2SkillTypeId;
  let thisChampsA3SkillTypeId;
  let thisChampsA4SkillTypeId;
  let thisChampsA5SkillTypeId;
  let thisChampsA6SkillTypeId;
  if (item.type.skillIds) {
    const skillIdsArray = item.type.skillIds;
    skillIdsArray.forEach((skill, i) => {
      if (i === 0) {
        thisChampsA1SkillTypeId = skill;
      }

      if (i === 1) {
        thisChampsA2SkillTypeId = skill;
      }

      if (i === 2) {
        thisChampsA3SkillTypeId = skill;
      }

      if (i === 3) {
        thisChampsA4SkillTypeId = skill;
      }

      if (i === 4) {
        thisChampsA5SkillTypeId = skill;
      }

      if (i === 5) {
        thisChampsA6SkillTypeId = skill;
      }
    });
  }
  //console.log(`A1 typeId for ${item.type.name}`, thisChampsA1SkillTypeId);
  //console.log(`A2 typeId for ${item.type.name}`, thisChampsA2SkillTypeId);
  //console.log(`A3 typeId for ${item.type.name}`, thisChampsA3SkillTypeId);
  //console.log(`A4 typeId for ${item.type.name}`, thisChampsA4SkillTypeId);
  //console.log(`A5 typeId for ${item.type.name}`, thisChampsA5SkillTypeId);
  //console.log(`A6 typeId for ${item.type.name}`, thisChampsA6SkillTypeId);

  if (Number(selectedStatusEffect) !== 0) {
    let foundMatchingEffect = false;

    if (selectedStatusEffect === '360' && thisChampHasBlockRevive) {
      foundMatchingEffect = true;
    } else if (statusEffectInfos) {
      // Add this check to ensure statusEffectInfos is defined
      for (const statusEffectInfo of statusEffectInfos) {
        if (statusEffectInfo.typeId === Number(selectedStatusEffect)) {
          foundMatchingEffect = true;
          break;
        }
      }
    }

    if (selectedStatusEffect === '491' && thisChampHasTrueFear) {
      foundMatchingEffect = true;
    } else if (statusEffectInfos) {
      // Add this check to ensure statusEffectInfos is defined
      for (const statusEffectInfo of statusEffectInfos) {
        if (statusEffectInfo.typeId === Number(selectedStatusEffect)) {
          foundMatchingEffect = true;
          break;
        }
      }
    }

    if (selectedStatusEffect === '80' && thisChampHasBigPoison) {
      foundMatchingEffect = true;
    } else if (statusEffectInfos) {
      // Add this check to ensure statusEffectInfos is defined
      for (const statusEffectInfo of statusEffectInfos) {
        if (statusEffectInfo.typeId === Number(selectedStatusEffect)) {
          foundMatchingEffect = true;
          break;
        }
      }
    }

    if (!foundMatchingEffect) {
      return false;
    }
  }

  const targetTypeCheck = (() => {
    if (selectedTarget === 'off') return true;

    const targetType = effect.targetParams?.targetType;
    const relatedTargetType = targetType === 2 ? getRelationTargetType(effect, effects, selectedTarget) : null;

    const checkTargetType = (type: number | null | undefined) => {
      switch (selectedTarget) {
        case '0':
          return (
            type === 0 ||
            type === 19 ||
            type === 20 ||
            type === 22 ||
            type === 32 ||
            type === 33 ||
            type === 34 ||
            type === 35 ||
            type === 36 ||
            type === 37 ||
            type === 38 ||
            type === 39 ||
            type === 40
          );
        case '1':
          return type === 1 || type === 4 || type === 31;
        case '5':
          return type === 5 || type === 6 || type === 13 || type === 14 || type === 25;
        case '7':
          return type === 7 || type === 8 || type === 9 || type === 26 || type === 29;
        default:
          return String(type) === selectedTarget;
      }
    };

    return targetType === 2 ? checkTargetType(relatedTargetType) : checkTargetType(targetType);
  })();

  const hitsRange = extractHitsRange();
  const hitsCheck = (() => {
    const hitCount = effect.count;

    const targetType = effect.targetParams?.targetType;
    const relatedEffect = targetType === 2 ? getRelationTargetType(effect, effects, selectedTarget) : null;

    // Consider effect.count of related effect
    const relatedHitCount = relatedEffect?.count || hitCount;

    return relatedHitCount >= hitsRange.min && relatedHitCount <= hitsRange.max;
  })();

  const skillIndexRange = extractSkillIndexRange();
  const skillIndexCheck = (() => {
    const effectId = effect.id;
  
    const targetType = effect.targetParams?.targetType;
    const relatedEffect = targetType === 2 ? getRelationTargetType(effect, effects, selectedTarget) : null;
  
    // Consider effect.count of related effect
    const relatedEffectId = relatedEffect?.id || effectId;
    let skillIndex = 0;
    
    if (String(relatedEffectId).startsWith(String(thisChampsA1SkillTypeId))) {
      skillIndex = 1;
    } else if (String(relatedEffectId).startsWith(String(thisChampsA2SkillTypeId))) {
      skillIndex = 2;
    } else if (String(relatedEffectId).startsWith(String(thisChampsA3SkillTypeId))) {
      skillIndex = 3;
    } else if (String(relatedEffectId).startsWith(String(thisChampsA4SkillTypeId))) {
      skillIndex = 4;
    } else if (String(relatedEffectId).startsWith(String(thisChampsA5SkillTypeId))) {
      skillIndex = 5;
    } else if (String(relatedEffectId).startsWith(String(thisChampsA6SkillTypeId))) {
      skillIndex = 6;
    }
  
    return skillIndex >= skillIndexRange.min && skillIndex <= skillIndexRange.max;
  })();
  
  

  for (let condition of specificCaseFixes) {
    if (
      selectedTarget === condition.target &&
      selectedStatusEffect === condition.effect &&
      item.type[condition.type] === condition.name
    ) {
      return condition.shouldShow;
    }
  }

  if (!targetTypeCheck) return false;

  const durationCheck =
    effect.applyStatusEffectParams?.statusEffectInfos[0]?.duration === undefined ||
    effect.applyStatusEffectParams?.statusEffectInfos[0]?.duration === null ||
    (effect.applyStatusEffectParams?.statusEffectInfos[0]?.duration >= durationRange.min &&
      effect.applyStatusEffectParams?.statusEffectInfos[0]?.duration <= durationRange.max);

  const cooldownCheck =
    !abilityData.cooldown || (abilityData.cooldown >= cooldownRange.min && abilityData.cooldown <= cooldownRange.max);

  if (
    a1ToggleState === 'no' &&
    championA1Matches(item, selectedStatusEffect, selectedTarget, durationRange, cooldownRange)
  ) {
    return false;
  }

  return durationCheck && cooldownCheck && hitsCheck && skillIndexCheck;
}

*/
