import React from 'react';
import { Role, Role as RoleEnum } from '../../Types';
import { getRoles } from '../../Data';
import { NextGenImage } from '../../Components/Library';

export interface RoleProps {
  className?: string;
  style?: React.CSSProperties;
  allRoles: RoleEnum[]; // Array to hold all roles from different forms
  local?: boolean;
  rarity?: string;
  showBothRoles?: boolean;
  currentFormIndex: number;
}

export const RoleIcon: React.FC<RoleProps> = ({
  allRoles,
  style,
  className,
  local,
  rarity,
  showBothRoles,
  currentFormIndex,
}) => {
  const roleDataMap = getRoles();

  const renderRoleIcon = (role: RoleEnum) => {
    const roleData = roleDataMap[role];
    if (!roleData) return null;

    return (
      <div className="role-icon-wrapper" key={roleData.name}>
        <NextGenImage
          whichDirectory={roleData.iconDirectory}
          whichImage={roleData.iconImage}
          className={className}
          style={style}
          alt={`${roleData.name} Type`}
          title={`${roleData.name} Type`}
          local={local}
        />
      </div>
    );
  };

  if (showBothRoles && (rarity === '6' || rarity === 'mythic')) {
    const uniqueRoles = [...new Set(allRoles)];
    return <div className="role-icon-parent-class">{uniqueRoles.map(renderRoleIcon)}</div>;
  } else {
    // Use currentFormIndex to pick the role to display
    let roleToDisplay;
    if (allRoles) {
      roleToDisplay = allRoles[currentFormIndex || 0];
    }
    return roleToDisplay ? renderRoleIcon(roleToDisplay) : null;
  }
};
