import { getHeroRating, getSkills } from 'Data';
import { getGlobalRootStore, useRootStore } from 'Model';
import {
  AbilityType,
  AcademyData,
  ArtifactItem,
  ChampionItem,
  EffectGroup,
  EffectKindId,
  EffectTargetType,
  FactionId,
  GearSlot,
  GuardianData,
  Rarity,
  StatusEffectTypeId,
} from 'Types';
import { DisplayMetadata, OrderedDisplayMetadata } from '../../Data/Types';
import { ViewOptions } from './ViewOptions';
import { StatusEffectDimensions } from '../../Types/Entities/StatusEffectDimensions';
import { EffectRelation, AccountInfo } from '@raid-toolkit/webclient';
import { ChampionInstance } from '../../Types/Entities/ChampionItem';
import { useState } from 'react';
import { AccountStore } from 'Model/AccountStore';
import { ObservableSelectFilter } from './Base/MultiSelectFilterBase';
import { runInAction, transaction } from 'mobx';
import { resolve } from '../../../webpack.common';
import { AccountManagerStore } from 'Model/AccountManagerStore';

const pako = require('pako');

export function canHandleAny(options: ViewOptions) {
  return true;
}

export interface DisplayMetadataWithKey extends DisplayMetadata {
  key: string;
  ordinal?: number;
}

function isSortableRecordArray(items: DisplayMetadata[]): items is OrderedDisplayMetadata[] {
  return (Object.values(items)[0] as OrderedDisplayMetadata | undefined)?.ordinal !== undefined;
}

export function convertDecimalToSortableNumber(input: number): number {
  // If the input is a percentage (decimal) between 0 and 1, convert it to a larger number by adding a large constant
  if (input > 0 && input < 1) {
    const LARGE_CONSTANT = 1e12; // You can adjust this constant to fit your desired sorting threshold
    return input * LARGE_CONSTANT;
  } else {
    // If the input is a whole number or a non-percentage decimal, return it as is
    return input;
  }
}

export function sortOrderedDisplayMetadata(a: OrderedDisplayMetadata, b: OrderedDisplayMetadata) {
  return a.ordinal - b.ordinal;
}

/* export function getSortedItemsWithKey(items: Record<string, DisplayMetadata>): DisplayMetadataWithKey[] {
  const values = Object.entries(items)
    .filter(([, item]) => !item.hidden)
    .map<DisplayMetadataWithKey>(([key, value]) => ({ key, ...value }));
  if (isSortableRecordArray(values)) {
    return values.sort(sortOrderedDisplayMetadata);
  }
  return values;
} */

export function getSortedItemsWithKey(
  items: Record<string, DisplayMetadata>,
  filterId?: string
): DisplayMetadataWithKey[] {
  const values = Object.entries(items)
    .filter(([, item]) => !item.hidden)
    .map<DisplayMetadataWithKey>(([key, value]) => ({ key, ...value }));

  if (filterId === 'artifactSlots') {
    // Apply custom sort order for ArtifactSlotsFilter
    const sortOrder = ['weapon', 'helmet', 'shield', 'gloves', 'chest', 'boots', 'ring', 'cloak', 'banner'];
    return values.sort((a, b) => sortOrder.indexOf(a.key) - sortOrder.indexOf(b.key));
  }

  if (filterId?.includes('ArtifactSubstats')) {
    // Apply custom sort order for all Artifact Substats Filters
    const sortOrder = [
      'healthPercent',
      'health',
      'attackPercent',
      'attack',
      'defensePercent',
      'defense',
      'speed',
      'criticalchance',
      'criticaldamage',
      'resistance',
      'accuracy',
    ];
    return values.sort((a, b) => sortOrder.indexOf(a.key) - sortOrder.indexOf(b.key));
  }

  if (isSortableRecordArray(values)) {
    return values.sort(sortOrderedDisplayMetadata);
  }

  return values;
}

export function getFWRatingNumber(rating: string[]) {
  let FWRatingNumber = 0;

  let stringRating = 'none';
  if (rating) {
    stringRating = rating.toString();
  }
  if (stringRating === '-') {
    FWRatingNumber = 1;
  }
  if (stringRating === 'OK') {
    FWRatingNumber = 2;
  }
  if (stringRating === 'Great') {
    FWRatingNumber = 3;
  }
  if (stringRating === 'Legendary') {
    FWRatingNumber = 4;
  }
  if (stringRating === 'Godlike') {
    FWRatingNumber = 5;
  }
  return FWRatingNumber;
}

export function getRankNumber(rank: string | undefined) {
  //console.log(`getRankNumber`, rank);
  const lowercaseRank = String(rank).toLowerCase();
  //console.log(`getRankNumber lowercaseRank`, lowercaseRank);

  let RankNumber = 1;
  if (lowercaseRank === 'stars1' || lowercaseRank === 'one') {
    RankNumber = 1;
  }
  if (lowercaseRank === 'stars2' || lowercaseRank === 'two') {
    RankNumber = 2;
  }
  if (lowercaseRank === 'stars3' || lowercaseRank === 'three') {
    RankNumber = 3;
  }
  if (lowercaseRank === 'stars4' || lowercaseRank === 'four') {
    RankNumber = 4;
  }
  if (lowercaseRank === 'stars5' || lowercaseRank === 'five') {
    RankNumber = 5;
  }
  if (lowercaseRank === 'stars6' || lowercaseRank === 'six') {
    RankNumber = 6;
  }
  return RankNumber;
}

export function getAwakenRankNumber(rank: string | undefined) {
  //console.log(`getRankNumber`, rank);
  const lowercaseRank = String(rank).toLowerCase();
  //console.log(`getRankNumber lowercaseRank`, lowercaseRank);

  let RankNumber = 0;
  if (lowercaseRank === 'stars1' || lowercaseRank === 'one') {
    RankNumber = 1;
  }
  if (lowercaseRank === 'stars2' || lowercaseRank === 'two') {
    RankNumber = 2;
  }
  if (lowercaseRank === 'stars3' || lowercaseRank === 'three') {
    RankNumber = 3;
  }
  if (lowercaseRank === 'stars4' || lowercaseRank === 'four') {
    RankNumber = 4;
  }
  if (lowercaseRank === 'stars5' || lowercaseRank === 'five') {
    RankNumber = 5;
  }
  if (lowercaseRank === 'stars6' || lowercaseRank === 'six') {
    RankNumber = 6;
  }
  return RankNumber;
}

export function getFactionName(factionKey: string | undefined) {
  let FactionName = '';
  if (factionKey === 'bannerlords') {
    FactionName = 'Banner Lords';
  }
  if (factionKey === 'barbarians') {
    FactionName = 'Barbarians';
  }
  if (factionKey === 'covenofmagi') {
    FactionName = 'Coven of Magi';
  }
  if (factionKey === 'darkelves') {
    FactionName = 'Dark Elves';
  }
  if (factionKey === 'demonspawn') {
    FactionName = 'Demonspawn';
  }
  if (factionKey === 'dwarves') {
    FactionName = 'Dwarves';
  }
  if (factionKey === 'highelves') {
    FactionName = 'High Elves';
  }
  if (factionKey === 'knightsrevenant') {
    FactionName = 'Knights Revenant';
  }
  if (factionKey === 'lizardmen') {
    FactionName = 'Lizardmen';
  }
  if (factionKey === 'nyresanelves') {
    FactionName = 'Sylvan Watchers';
  }
  if (factionKey === 'sylvanwatchers') {
    FactionName = 'Sylvan Watchers';
  }
  if (factionKey === 'ogryntribes') {
    FactionName = 'Ogryn Tribes';
  }
  if (factionKey === 'orcs') {
    FactionName = 'Orcs';
  }
  if (factionKey === 'sacredorder') {
    FactionName = 'The Sacred Order';
  }
  if (factionKey === 'samurai') {
    FactionName = 'Shadowkin';
  }
  if (factionKey === 'skinwalkers') {
    FactionName = 'Skinwalkers';
  }
  if (factionKey === 'undeadhordes') {
    FactionName = 'Undead Hordes';
  }
  return FactionName;
}

export function getRarityColorForRating(rating: number) {
  let RarityColorForRating;
  if (rating >= 1 && rating < 2) {
    RarityColorForRating = 'common';
  }
  if (rating >= 2 && rating < 3) {
    RarityColorForRating = 'uncommon';
  }
  if (rating >= 3 && rating < 4) {
    RarityColorForRating = 'rare';
  }
  if (rating >= 4 && rating < 5) {
    RarityColorForRating = 'epic';
  }
  if (rating >= 5) {
    RarityColorForRating = 'legendary';
  }
  return RarityColorForRating;
}

export function getChosenColorForRating(rating: number) {
  let RarityColorForRating = 'noStar';
  if (rating >= 1 && rating < 20) {
    RarityColorForRating = 'oneStar';
  }
  if (rating >= 20.1 && rating <= 40) {
    RarityColorForRating = 'twoStar';
  }
  if (rating >= 40.1 && rating <= 60) {
    RarityColorForRating = 'threeStar';
  }
  if (rating >= 60.1 && rating <= 80) {
    RarityColorForRating = 'fourStar';
  }
  if (rating >= 80.1) {
    RarityColorForRating = 'fiveStar';
  }
  return RarityColorForRating;
}

export function getRarityColor(rarity: string) {
  let RarityColorForRating;
  if (rarity === 'common') {
    RarityColorForRating = 'lightgrey';
  }
  if (rarity === 'uncommon') {
    RarityColorForRating = 'lime';
  }
  if (rarity === 'rare') {
    RarityColorForRating = '#509cff';
  }
  if (rarity === 'epic') {
    RarityColorForRating = '#d412d4';
  }
  if (rarity === 'legendary') {
    RarityColorForRating = '#EE8100';
  }
  return RarityColorForRating;
}

export function getAllStarRatings(slug: string | undefined) {
  /* ChoseN */

  const ChosenIronTwinsRating = getHeroRating(slug!)?.detailed.chosen?.areaRatings?.IronTwins || 0;
  let ChosenIronTwinsStarRating = 0;
  if (ChosenIronTwinsRating <= 20) {
    ChosenIronTwinsStarRating = 1;
  }
  if (ChosenIronTwinsRating >= 20.1 && ChosenIronTwinsRating < 40) {
    ChosenIronTwinsStarRating = 2;
  }
  if (ChosenIronTwinsRating >= 40.1 && ChosenIronTwinsRating < 60) {
    ChosenIronTwinsStarRating = 3;
  }
  if (ChosenIronTwinsRating >= 60.1 && ChosenIronTwinsRating < 80) {
    ChosenIronTwinsStarRating = 4;
  }
  if (ChosenIronTwinsRating >= 80.1) {
    ChosenIronTwinsStarRating = 5;
  }

  const ChosenSandDevilRating = getHeroRating(slug!)?.detailed.chosen?.areaRatings?.SandDevil || 0;
  let ChosenSandDevilStarRating = 0;
  if (ChosenSandDevilRating <= 20) {
    ChosenSandDevilStarRating = 1;
  }
  if (ChosenSandDevilRating >= 20.1 && ChosenSandDevilRating < 40) {
    ChosenSandDevilStarRating = 2;
  }
  if (ChosenSandDevilRating >= 40.1 && ChosenSandDevilRating < 60) {
    ChosenSandDevilStarRating = 3;
  }
  if (ChosenSandDevilRating >= 60.1 && ChosenSandDevilRating < 80) {
    ChosenSandDevilStarRating = 4;
  }
  if (ChosenSandDevilRating >= 80.1) {
    ChosenSandDevilStarRating = 5;
  }

  const ChosenHydraRating = getHeroRating(slug!)?.detailed.chosen?.areaRatings?.Hydra || 0;
  let ChosenHydraStarRating = 0;
  if (ChosenHydraRating <= 20) {
    ChosenHydraStarRating = 1;
  }
  if (ChosenHydraRating >= 20.1 && ChosenHydraRating < 40) {
    ChosenHydraStarRating = 2;
  }
  if (ChosenHydraRating >= 40.1 && ChosenHydraRating < 60) {
    ChosenHydraStarRating = 3;
  }
  if (ChosenHydraRating >= 60.1 && ChosenHydraRating < 80) {
    ChosenHydraStarRating = 4;
  }
  if (ChosenHydraRating >= 80.1) {
    ChosenHydraStarRating = 5;
  }

  const ChosenDragonRating = getHeroRating(slug!)?.detailed.chosen?.areaRatings?.Dragon || 0;
  let ChosenDragonStarRating = 0;
  if (ChosenDragonRating <= 20) {
    ChosenDragonStarRating = 1;
  }
  if (ChosenDragonRating >= 20.1 && ChosenDragonRating < 40) {
    ChosenDragonStarRating = 2;
  }
  if (ChosenDragonRating >= 40.1 && ChosenDragonRating < 60) {
    ChosenDragonStarRating = 3;
  }
  if (ChosenDragonRating >= 60.1 && ChosenDragonRating < 80) {
    ChosenDragonStarRating = 4;
  }
  if (ChosenDragonRating >= 80.1) {
    ChosenDragonStarRating = 5;
  }
  const ChosenSpiderRating = getHeroRating(slug!)?.detailed.chosen?.areaRatings?.Spider || 0;
  let ChosenSpiderStarRating = 0;
  if (ChosenSpiderRating <= 20) {
    ChosenSpiderStarRating = 1;
  }
  if (ChosenSpiderRating >= 20.1 && ChosenSpiderRating < 40) {
    ChosenSpiderStarRating = 2;
  }
  if (ChosenSpiderRating >= 40.1 && ChosenSpiderRating < 60) {
    ChosenSpiderStarRating = 3;
  }
  if (ChosenSpiderRating >= 60.1 && ChosenSpiderRating < 80) {
    ChosenSpiderStarRating = 4;
  }
  if (ChosenSpiderRating >= 80.1) {
    ChosenSpiderStarRating = 5;
  }
  const ChosenFireKnightRating = getHeroRating(slug!)?.detailed.chosen?.areaRatings?.FireKnight || 0;
  let ChosenFireKnightStarRating = 0;
  if (ChosenFireKnightRating <= 20) {
    ChosenFireKnightStarRating = 1;
  }
  if (ChosenFireKnightRating >= 20.1 && ChosenFireKnightRating < 40) {
    ChosenFireKnightStarRating = 2;
  }
  if (ChosenFireKnightRating >= 40.1 && ChosenFireKnightRating < 60) {
    ChosenFireKnightStarRating = 3;
  }
  if (ChosenFireKnightRating >= 60.1 && ChosenFireKnightRating < 80) {
    ChosenFireKnightStarRating = 4;
  }
  if (ChosenFireKnightRating >= 80.1) {
    ChosenFireKnightStarRating = 5;
  }
  const ChosenIceGolemRating = getHeroRating(slug!)?.detailed.chosen?.areaRatings?.IceGolem || 0;
  let ChosenIceGolemStarRating = 0;
  if (ChosenIceGolemRating <= 20) {
    ChosenIceGolemStarRating = 1;
  }
  if (ChosenIceGolemRating >= 20.1 && ChosenIceGolemRating < 40) {
    ChosenIceGolemStarRating = 2;
  }
  if (ChosenIceGolemRating >= 40.1 && ChosenIceGolemRating < 60) {
    ChosenIceGolemStarRating = 3;
  }
  if (ChosenIceGolemRating >= 60.1 && ChosenIceGolemRating < 80) {
    ChosenIceGolemStarRating = 4;
  }
  if (ChosenIceGolemRating >= 80.1) {
    ChosenIceGolemStarRating = 5;
  }
  const ChosenMinotaurRating = getHeroRating(slug!)?.detailed.chosen?.areaRatings?.Minotaur || 0;
  let ChosenMinotaurStarRating = 0;
  if (ChosenMinotaurRating <= 20) {
    ChosenMinotaurStarRating = 1;
  }
  if (ChosenMinotaurRating >= 20.1 && ChosenMinotaurRating < 40) {
    ChosenMinotaurStarRating = 2;
  }
  if (ChosenMinotaurRating >= 40.1 && ChosenMinotaurRating < 60) {
    ChosenMinotaurStarRating = 3;
  }
  if (ChosenMinotaurRating >= 60.1 && ChosenMinotaurRating < 80) {
    ChosenMinotaurStarRating = 4;
  }
  if (ChosenMinotaurRating >= 80.1) {
    ChosenMinotaurStarRating = 5;
  }

  const ChosenFWRating = getHeroRating(slug!)?.detailed.chosen?.areaRatings?.FactionWars || 0;
  let ChosenFWStarRating = 0;
  if (ChosenFWRating <= 20) {
    ChosenFWStarRating = 1;
  }
  if (ChosenFWRating >= 20.1 && ChosenFWRating < 40) {
    ChosenFWStarRating = 2;
  }
  if (ChosenFWRating >= 40.1 && ChosenFWRating < 60) {
    ChosenFWStarRating = 3;
  }
  if (ChosenFWRating >= 60.1 && ChosenFWRating < 80) {
    ChosenFWStarRating = 4;
  }
  if (ChosenFWRating >= 80.1) {
    ChosenFWStarRating = 5;
  }

  const ChosenDoomTowerRating = getHeroRating(slug!)?.detailed.chosen?.areaRatings?.DoomTower || 0;
  let ChosenDoomTowerStarRating = 0;
  if (ChosenDoomTowerRating <= 20) {
    ChosenDoomTowerStarRating = 1;
  }
  if (ChosenDoomTowerRating >= 20.1 && ChosenDoomTowerRating < 40) {
    ChosenDoomTowerStarRating = 2;
  }
  if (ChosenDoomTowerRating >= 40.1 && ChosenDoomTowerRating < 60) {
    ChosenDoomTowerStarRating = 3;
  }
  if (ChosenDoomTowerRating >= 60.1 && ChosenDoomTowerRating < 80) {
    ChosenDoomTowerStarRating = 4;
  }
  if (ChosenDoomTowerRating >= 80.1) {
    ChosenDoomTowerStarRating = 5;
  }
  const ChosenDemonLordRating = getHeroRating(slug!)?.detailed.chosen?.areaRatings?.DemonLord || 0;
  let ChosenDemonLordStarRating = 0;
  if (ChosenDemonLordRating <= 20) {
    ChosenDemonLordStarRating = 1;
  }
  if (ChosenDemonLordRating >= 20.1 && ChosenDemonLordRating < 40) {
    ChosenDemonLordStarRating = 2;
  }
  if (ChosenDemonLordRating >= 40.1 && ChosenDemonLordRating < 60) {
    ChosenDemonLordStarRating = 3;
  }
  if (ChosenDemonLordRating >= 60.1 && ChosenDemonLordRating < 80) {
    ChosenDemonLordStarRating = 4;
  }
  if (ChosenDemonLordRating >= 80.1) {
    ChosenDemonLordStarRating = 5;
  }
  const ChosenArenaRating = getHeroRating(slug!)?.detailed.chosen?.areaRatings?.Arena || 0;
  let ChosenArenaStarRating = 0;
  if (ChosenArenaRating <= 20) {
    ChosenArenaStarRating = 1;
  }
  if (ChosenArenaRating >= 20.1 && ChosenArenaRating < 40) {
    ChosenArenaStarRating = 2;
  }
  if (ChosenArenaRating >= 40.1 && ChosenArenaRating < 60) {
    ChosenArenaStarRating = 3;
  }
  if (ChosenArenaRating >= 60.1 && ChosenArenaRating < 80) {
    ChosenArenaStarRating = 4;
  }
  if (ChosenArenaRating >= 80.1) {
    ChosenArenaStarRating = 5;
  }

  const ChosenScarcityRating = getHeroRating(slug!)?.detailed.chosen?.areaRatings?.Scarcity || 0;
  let ChosenScarcityStarRating = 0;
  if (ChosenScarcityRating <= 20) {
    ChosenScarcityStarRating = 1;
  }
  if (ChosenScarcityRating >= 20.1 && ChosenScarcityRating < 40) {
    ChosenScarcityStarRating = 2;
  }
  if (ChosenScarcityRating >= 40.1 && ChosenScarcityRating < 60) {
    ChosenScarcityStarRating = 3;
  }
  if (ChosenScarcityRating >= 60.1 && ChosenScarcityRating < 80) {
    ChosenScarcityStarRating = 4;
  }
  if (ChosenScarcityRating >= 80.1) {
    ChosenScarcityStarRating = 5;
  }
  const ChosenProgressionRating = getHeroRating(slug!)?.detailed.chosen?.areaRatings?.Progression || 0;
  let ChosenProgressionStarRating = 0;
  if (ChosenProgressionRating <= 20) {
    ChosenProgressionStarRating = 1;
  }
  if (ChosenProgressionRating >= 20.1 && ChosenProgressionRating < 40) {
    ChosenProgressionStarRating = 2;
  }
  if (ChosenProgressionRating >= 40.1 && ChosenProgressionRating < 60) {
    ChosenProgressionStarRating = 3;
  }
  if (ChosenProgressionRating >= 60.1 && ChosenProgressionRating < 80) {
    ChosenProgressionStarRating = 4;
  }
  if (ChosenProgressionRating >= 80.1) {
    ChosenProgressionStarRating = 5;
  }
  const ChosenEndGameRating = getHeroRating(slug!)?.detailed.chosen?.areaRatings?.EndGame || 0;
  let ChosenEndGameStarRating = 0;
  if (ChosenEndGameRating <= 20) {
    ChosenEndGameStarRating = 1;
  }
  if (ChosenEndGameRating >= 20.1 && ChosenEndGameRating < 40) {
    ChosenEndGameStarRating = 2;
  }
  if (ChosenEndGameRating >= 40.1 && ChosenEndGameRating < 60) {
    ChosenEndGameStarRating = 3;
  }
  if (ChosenEndGameRating >= 60.1 && ChosenEndGameRating < 80) {
    ChosenEndGameStarRating = 4;
  }
  if (ChosenEndGameRating >= 80.1) {
    ChosenEndGameStarRating = 5;
  }
  const ChosenPotionKeepForceRating = getHeroRating(slug!)?.detailed.chosen?.areaRatings?.PotionKeepForce || 0;
  let ChosenPotionKeepForceStarRating = 0;
  if (ChosenPotionKeepForceRating <= 20) {
    ChosenPotionKeepForceStarRating = 1;
  }
  if (ChosenPotionKeepForceRating >= 20.1 && ChosenPotionKeepForceRating < 40) {
    ChosenPotionKeepForceStarRating = 2;
  }
  if (ChosenPotionKeepForceRating >= 40.1 && ChosenPotionKeepForceRating < 60) {
    ChosenPotionKeepForceStarRating = 3;
  }
  if (ChosenPotionKeepForceRating >= 60.1 && ChosenPotionKeepForceRating < 80) {
    ChosenPotionKeepForceStarRating = 4;
  }
  if (ChosenPotionKeepForceRating >= 80.1) {
    ChosenPotionKeepForceStarRating = 5;
  }
  const ChosenPotionKeepMagicRating = getHeroRating(slug!)?.detailed.chosen?.areaRatings?.PotionKeepMagic || 0;
  let ChosenPotionKeepMagicStarRating = 0;
  if (ChosenPotionKeepMagicRating <= 20) {
    ChosenPotionKeepMagicStarRating = 1;
  }
  if (ChosenPotionKeepMagicRating >= 20.1 && ChosenPotionKeepMagicRating < 40) {
    ChosenPotionKeepMagicStarRating = 2;
  }
  if (ChosenPotionKeepMagicRating >= 40.1 && ChosenPotionKeepMagicRating < 60) {
    ChosenPotionKeepMagicStarRating = 3;
  }
  if (ChosenPotionKeepMagicRating >= 60.1 && ChosenPotionKeepMagicRating < 80) {
    ChosenPotionKeepMagicStarRating = 4;
  }
  if (ChosenPotionKeepMagicRating >= 80.1) {
    ChosenPotionKeepMagicStarRating = 5;
  }
  const ChosenPotionKeepSpiritRating = getHeroRating(slug!)?.detailed.chosen?.areaRatings?.PotionKeepSpirit || 0;
  let ChosenPotionKeepSpiritStarRating = 0;
  if (ChosenPotionKeepSpiritRating <= 20) {
    ChosenPotionKeepSpiritStarRating = 1;
  }
  if (ChosenPotionKeepSpiritRating >= 20.1 && ChosenPotionKeepSpiritRating < 40) {
    ChosenPotionKeepSpiritStarRating = 2;
  }
  if (ChosenPotionKeepSpiritRating >= 40.1 && ChosenPotionKeepSpiritRating < 60) {
    ChosenPotionKeepSpiritStarRating = 3;
  }
  if (ChosenPotionKeepSpiritRating >= 60.1 && ChosenPotionKeepSpiritRating < 80) {
    ChosenPotionKeepSpiritStarRating = 4;
  }
  if (ChosenPotionKeepSpiritRating >= 80.1) {
    ChosenPotionKeepSpiritStarRating = 5;
  }
  const ChosenPotionKeepVoidRating = getHeroRating(slug!)?.detailed.chosen?.areaRatings?.PotionKeepVoid || 0;
  let ChosenPotionKeepVoidStarRating = 0;
  if (ChosenPotionKeepVoidRating <= 20) {
    ChosenPotionKeepVoidStarRating = 1;
  }
  if (ChosenPotionKeepVoidRating >= 20.1 && ChosenPotionKeepVoidRating < 40) {
    ChosenPotionKeepVoidStarRating = 2;
  }
  if (ChosenPotionKeepVoidRating >= 40.1 && ChosenPotionKeepVoidRating < 60) {
    ChosenPotionKeepVoidStarRating = 3;
  }
  if (ChosenPotionKeepVoidRating >= 60.1 && ChosenPotionKeepVoidRating < 80) {
    ChosenPotionKeepVoidStarRating = 4;
  }
  if (ChosenPotionKeepVoidRating >= 80.1) {
    ChosenPotionKeepVoidStarRating = 5;
  }

  const ChosenPotionKeepArcaneRating = getHeroRating(slug!)?.detailed.chosen?.areaRatings?.PotionKeepArcane || 0;
  let ChosenPotionKeepArcaneStarRating = 0;
  if (ChosenPotionKeepArcaneRating <= 20) {
    ChosenPotionKeepArcaneStarRating = 1;
  }
  if (ChosenPotionKeepArcaneRating >= 20.1 && ChosenPotionKeepArcaneRating < 40) {
    ChosenPotionKeepArcaneStarRating = 2;
  }
  if (ChosenPotionKeepArcaneRating >= 40.1 && ChosenPotionKeepArcaneRating < 60) {
    ChosenPotionKeepArcaneStarRating = 3;
  }
  if (ChosenPotionKeepArcaneRating >= 60.1 && ChosenPotionKeepArcaneRating < 80) {
    ChosenPotionKeepArcaneStarRating = 4;
  }
  if (ChosenPotionKeepArcaneRating >= 80.1) {
    ChosenPotionKeepArcaneStarRating = 5;
  }

  const ChosenCampaignRating = getHeroRating(slug!)?.detailed.chosen?.areaRatings?.Campaign || 0;
  let ChosenCampaignStarRating = 0;
  if (ChosenCampaignRating <= 20) {
    ChosenCampaignStarRating = 1;
  }
  if (ChosenCampaignRating >= 20.1 && ChosenCampaignRating < 40) {
    ChosenCampaignStarRating = 2;
  }
  if (ChosenCampaignRating >= 40.1 && ChosenCampaignRating < 60) {
    ChosenCampaignStarRating = 3;
  }
  if (ChosenCampaignRating >= 60.1 && ChosenCampaignRating < 80) {
    ChosenCampaignStarRating = 4;
  }
  if (ChosenCampaignRating >= 80.1) {
    ChosenCampaignStarRating = 5;
  }

  /* HellHades */
  const HellHadesAllDungeonsStarRating = Math.round(
    getHeroRating(slug!)?.detailed.hellhades?.areaRatings?.AllDungeons || 0
  );
  const HellHadesSpiderStarRating = Math.round(getHeroRating(slug!)?.detailed.hellhades?.areaRatings?.Spider || 0);
  const HellHadesFireKnightStarRating = Math.round(
    getHeroRating(slug!)?.detailed.hellhades?.areaRatings?.FireKnight || 0
  );
  const HellHadesDragonStarRating = Math.round(getHeroRating(slug!)?.detailed.hellhades?.areaRatings?.Dragon || 0);
  const HellHadesIceGolemStarRating = Math.round(getHeroRating(slug!)?.detailed.hellhades?.areaRatings?.IceGolem || 0);
  const HellHadesIronTwinsStarRating = Math.round(
    getHeroRating(slug!)?.detailed.hellhades?.areaRatings?.IronTwins || 0
  );
  const HellHadesSandDevilStarRating = Math.round(
    getHeroRating(slug!)?.detailed.hellhades?.areaRatings?.SandDevil || 0
  );
  const HellHadesPhantomShogunStarRating = Math.round(
    getHeroRating(slug!)?.detailed.hellhades?.areaRatings?.PhantomShogun || 0
  );
  const HellHadesFWOverallStarRating = Math.round(
    getHeroRating(slug!)?.detailed.hellhades?.areaRatings?.FactionWars || 0
  );

  /* const HellHadesHardAllDungeonsStarRating = Math.round(
    getHeroRating(slug!)?.detailed.hellhades?.areaRatings?.AllDungeons || 0
  ); */
  const HellHadesHardSpiderStarRating = Math.round(
    getHeroRating(slug!)?.detailed.hellhades?.areaRatings?.HardSpider || 0
  );
  const HellHadesHardFireKnightStarRating = Math.round(
    getHeroRating(slug!)?.detailed.hellhades?.areaRatings?.HardFireKnight || 0
  );
  const HellHadesHardDragonStarRating = Math.round(
    getHeroRating(slug!)?.detailed.hellhades?.areaRatings?.HardDragon || 0
  );
  const HellHadesHardIceGolemStarRating = Math.round(
    getHeroRating(slug!)?.detailed.hellhades?.areaRatings?.HardIceGolem || 0
  );

  const HellHadesFWDecreaseDefenseRatingString = getHeroRating(slug!)?.detailed.hellhades?.fw_decrease_def;
  const HellHadesFWDecreaseAttackRatingString = getHeroRating(slug!)?.detailed.hellhades?.fw_decrease_atk;
  const HellHadesFWDamageRatingString = getHeroRating(slug!)?.detailed.hellhades?.fw_damage;
  const HellHadesFWCrowdControlRatingString = getHeroRating(slug!)?.detailed.hellhades?.fw_crowd_control;
  const HellHadesFWTurnMeterControlRatingString = getHeroRating(slug!)?.detailed.hellhades?.fw_tm_control;
  const HellHadesFWProtectionAndSupportRatingString = getHeroRating(slug!)?.detailed.hellhades?.fw_healing_shielding;
  const HellHadesFWReviverRatingString = getHeroRating(slug!)?.detailed.hellhades?.fw_reviver;

  const HellHadesFWDecreaseDefenseStarRating = getFWRatingNumber(HellHadesFWDecreaseDefenseRatingString!);
  const HellHadesFWDecreaseAttackStarRating = getFWRatingNumber(HellHadesFWDecreaseAttackRatingString!);
  const HellHadesFWDamageStarRating = getFWRatingNumber(HellHadesFWDamageRatingString!);
  const HellHadesFWCrowdControlStarRating = getFWRatingNumber(HellHadesFWCrowdControlRatingString!);
  const HellHadesFWTurnMeterControlStarRating = getFWRatingNumber(HellHadesFWTurnMeterControlRatingString!);
  const HellHadesFWProtectionAndSupportStarRating = getFWRatingNumber(HellHadesFWProtectionAndSupportRatingString!);
  const HellHadesFWReviverStarRating = getFWRatingNumber(HellHadesFWReviverRatingString!);

  const HellHadesDemonLordStarRating = Math.round(
    getHeroRating(slug!)?.detailed.hellhades?.areaRatings?.DemonLord || 0
  );
  const HellHadesHydraStarRating = Math.round(getHeroRating(slug!)?.detailed.hellhades?.areaRatings?.Hydra || 0);

  const HellHadesArenaOverallStarRating = Math.round(
    getHeroRating(slug!)?.detailed.hellhades?.areaRatings?.ArenaOverall || 0
  );
  const HellHadesArenaAttackStarRating = Math.round(
    getHeroRating(slug!)?.detailed.hellhades?.areaRatings?.ArenaAttack || 0
  );
  const HellHadesArenaDefenseStarRating = Math.round(
    getHeroRating(slug!)?.detailed.hellhades?.areaRatings?.ArenaDefense || 0
  );
  const HellHadesDoomTowerOverallStarRating = Math.round(
    getHeroRating(slug!)?.detailed.hellhades?.areaRatings?.DoomTower || 0
  );
  const HellHadesDTBossKuldathStarRating = Math.round(
    getHeroRating(slug!)?.detailed.hellhades?.areaRatings?.DTBossKuldath || 0
  );
  const HellHadesDTBossSorathStarRating = Math.round(
    getHeroRating(slug!)?.detailed.hellhades?.areaRatings?.DTBossSorath || 0
  );
  const HellHadesDTBossAgrethStarRating = Math.round(
    getHeroRating(slug!)?.detailed.hellhades?.areaRatings?.DTBossAgreth || 0
  );
  const HellHadesDTBossBorgothStarRating = Math.round(
    getHeroRating(slug!)?.detailed.hellhades?.areaRatings?.DTBossBorgoth || 0
  );
  const HellHadesDTBossIragothStarRating = Math.round(
    getHeroRating(slug!)?.detailed.hellhades?.areaRatings?.DTBossIragoth || 0
  );
  const HellHadesDTBossCelestialGriffinStarRating = Math.round(
    getHeroRating(slug!)?.detailed.hellhades?.areaRatings?.DTBossCelestialGriffin || 0
  );
  const HellHadesDTBossAstranyxStarRating = Math.round(
    getHeroRating(slug!)?.detailed.hellhades?.areaRatings?.DTBossAstranyx || 0
  );
  const HellHadesDTBossBommalStarRating = Math.round(
    getHeroRating(slug!)?.detailed.hellhades?.areaRatings?.DTBossBommal || 0
  );

  const allStarRatings = {
    ChosenDragonStarRating: ChosenDragonStarRating,
    ChosenSpiderStarRating: ChosenSpiderStarRating,
    ChosenFireKnightStarRating: ChosenFireKnightStarRating,
    ChosenIceGolemStarRating: ChosenIceGolemStarRating,
    ChosenIronTwinsStarRating: ChosenIronTwinsStarRating,
    ChosenSandDevilStarRating: ChosenSandDevilStarRating,
    ChosenMinotaurStarRating: ChosenMinotaurStarRating,
    ChosenFWStarRating: ChosenFWStarRating,
    ChosenDoomTowerStarRating: ChosenDoomTowerStarRating,
    ChosenDemonLordStarRating: ChosenDemonLordStarRating,
    ChosenHydraStarRating: ChosenHydraStarRating,
    ChosenArenaStarRating: ChosenArenaStarRating,
    ChosenScarcityStarRating: ChosenScarcityStarRating,
    ChosenProgressionStarRating: ChosenProgressionStarRating,
    ChosenEndGameStarRating: ChosenEndGameStarRating,
    ChosenPotionKeepArcaneStarRating: ChosenPotionKeepArcaneStarRating,
    ChosenPotionKeepForceStarRating: ChosenPotionKeepForceStarRating,
    ChosenPotionKeepMagicStarRating: ChosenPotionKeepMagicStarRating,
    ChosenPotionKeepSpiritStarRating: ChosenPotionKeepSpiritStarRating,
    ChosenPotionKeepVoidStarRating: ChosenPotionKeepVoidStarRating,
    ChosenCampaignStarRating: ChosenCampaignStarRating,
    HellHadesAllDungeonsStarRating: HellHadesAllDungeonsStarRating,
    HellHadesSpiderStarRating: HellHadesSpiderStarRating,
    HellHadesFireKnightStarRating: HellHadesFireKnightStarRating,
    HellHadesDragonStarRating: HellHadesDragonStarRating,
    HellHadesIceGolemStarRating: HellHadesIceGolemStarRating,
    HellHadesIronTwinsStarRating: HellHadesIronTwinsStarRating,
    HellHadesSandDevilStarRating: HellHadesSandDevilStarRating,
    HellHadesPhantomShogunStarRating: HellHadesPhantomShogunStarRating,

    HellHadesHardSpiderStarRating: HellHadesHardSpiderStarRating,
    HellHadesHardFireKnightStarRating: HellHadesHardFireKnightStarRating,
    HellHadesHardDragonStarRating: HellHadesHardDragonStarRating,
    HellHadesHardIceGolemStarRating: HellHadesHardIceGolemStarRating,

    HellHadesFWOverallStarRating: HellHadesFWOverallStarRating,
    HellHadesFWDecreaseDefenseStarRating: HellHadesFWDecreaseDefenseStarRating,
    HellHadesFWDecreaseAttackStarRating: HellHadesFWDecreaseAttackStarRating,
    HellHadesFWDamageStarRating: HellHadesFWDamageStarRating,
    HellHadesFWCrowdControlStarRating: HellHadesFWCrowdControlStarRating,
    HellHadesFWTurnMeterControlStarRating: HellHadesFWTurnMeterControlStarRating,
    HellHadesFWProtectionAndSupportStarRating: HellHadesFWProtectionAndSupportStarRating,
    HellHadesFWReviverStarRating: HellHadesFWReviverStarRating,

    HellHadesDemonLordStarRating: HellHadesDemonLordStarRating,
    HellHadesHydraStarRating: HellHadesHydraStarRating,

    HellHadesArenaOverallStarRating: HellHadesArenaOverallStarRating,
    HellHadesArenaAttackStarRating: HellHadesArenaAttackStarRating,
    HellHadesArenaDefenseStarRating: HellHadesArenaDefenseStarRating,
    HellHadesDoomTowerOverallStarRating: HellHadesDoomTowerOverallStarRating,
    HellHadesDTBossKuldathStarRating: HellHadesDTBossKuldathStarRating,
    HellHadesDTBossSorathStarRating: HellHadesDTBossSorathStarRating,
    HellHadesDTBossAgrethStarRating: HellHadesDTBossAgrethStarRating,
    HellHadesDTBossBorgothStarRating: HellHadesDTBossBorgothStarRating,
    HellHadesDTBossIragothStarRating: HellHadesDTBossIragothStarRating,
    HellHadesDTBossCelestialGriffinStarRating: HellHadesDTBossCelestialGriffinStarRating,
    HellHadesDTBossAstranyxStarRating: HellHadesDTBossAstranyxStarRating,
    HellHadesDTBossBommalStarRating: HellHadesDTBossBommalStarRating,
  };

  return allStarRatings;
}

export function capitalizeFirstLetter(string: string) {
  if (string && string.charAt(0)) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  } else {
    return;
  }
}

export function getEquippedArtifacts(item: ChampionItem) {
  const rootStore = getGlobalRootStore();
  const account = rootStore.accounts.selectedAccount;
  const artifacts = account?.artifacts || [];

  let allEquippedArtifacts: (ArtifactItem | string)[] = [];
  const equipmentTypes: GearSlot[] = [
    GearSlot.weapon,
    GearSlot.helmet,
    GearSlot.shield,
    GearSlot.gloves,
    GearSlot.chest,
    GearSlot.boots,
    GearSlot.ring,
    GearSlot.pendant,
    GearSlot.banner,
  ];

  for (const type of equipmentTypes) {
    const artifactId = item?.instance?.equippedArtifactIds?.[type];
    const artifact = artifactId !== undefined ? artifacts[artifactId] : undefined;
    allEquippedArtifacts.push(artifact !== undefined ? artifact : type);
  }

  return allEquippedArtifacts;
}

export function returnMatchesForChampsThatHaveSelectedSkills(item: ChampionItem) {
  const matchingItems = [];
  for (var i = 0; i < 6; i++) {
    matchingItems.push(item.type.skillIds[i]);
  }
  //   console.log(`returnMatchesForChampsThatHaveSelectedSkills`, matchingItems);
  return matchingItems;
}

export function returnChampAbilityNumbers(item: ChampionItem) {
  const thisChampsSkillIds = item.type?.skillIds!;
  //   console.log(`thisChampsSkillIds`, thisChampsSkillIds);

  let abilityNumbers: any[] = [];

  thisChampsSkillIds.forEach((item) => {
    //     console.log(`thisChampsSkillIds > item`, item);
    abilityNumbers.push(item);
  });

  return abilityNumbers;
}

export function returnTargetTypeOfRelationEffect(
  effects: {
    id: number;
    kindId: EffectKindId;
    typeId: StatusEffectTypeId;
    chance: number | undefined;
    duration: number;
    cooldown: number;
    stackCount: number;
    targetType: EffectTargetType | undefined;
    group: EffectGroup | undefined;
    relation: EffectRelation | undefined;
  }[]
) {
  let targetType: number | undefined;

  effects.forEach((effect) => {
    targetType = effect.targetType;
    //     console.log(`returnTargetTypeOfRelationEffect targetType`, targetType);
  });

  return targetType;
}

export function returnIdOfRelationEffect(
  effects:
    | {
        id: number;
        kindId: EffectKindId;
        typeId: StatusEffectTypeId;
        chance: number | undefined;
        duration: number;
        cooldown: number;
        stackCount: number;
        targetType: EffectTargetType | undefined;
        group: EffectGroup | undefined;
        relation: EffectRelation | undefined;
      }[]
    | undefined
) {
  let relationEffectId: number | undefined;

  if (effects) {
    effects.forEach((effect) => {
      if (effect.relation) {
        relationEffectId = effect.relation.effectTypeId;
      } else {
        relationEffectId = 0;
      }
      //console.log(`returnIdOfRelationEffect relationEffectId`, relationEffectId);
    });
  }

  return relationEffectId;
}

export function returnEffectsWithApplyStatusEffectParams(effects: any[]) {
  let effectsWithApplyStatusEffectParams: any[] = [];

  if (effects) {
    effects.forEach((effect) => {
      if (effect.applyStatusEffectParams) {
        effectsWithApplyStatusEffectParams.push(effect);
      }
    });
  }

  return effectsWithApplyStatusEffectParams;
}

export function returnTargetTypeOfEffectWithThisId(effects: any, id: number) {
  let targetType: number = 0;

  effects.forEach((effect: { id: number; targetParams: { targetType: number } }) => {
    if (effect.id === id) {
      targetType = effect.targetParams.targetType;
    }
  });

  return targetType;
}

export function returnEffectWithThisId(effects: any, id: number) {
  let effectData: any;

  effects.forEach((effect: { id: number; targetParams: { targetType: number } }) => {
    if (effect.id === id) {
      effectData = effect;
    }
  });

  return effectData;
}

export function wordCount(string: string | any[], word: string) {
  let length = typeof string === 'string' && typeof word === 'string' && (word.length as number),
    loop = +length,
    index = 0,
    count = 0;

  while (loop) {
    index = string.indexOf(word, index);
    if (index !== -1) {
      count += 1;
      index += loop;
    } else {
      loop = 0;
    }
  }

  return count;
}

export function getFactionGuardians(guardians: Record<FactionId, Record<Rarity, GuardianData>> | undefined) {
  if (!guardians) return;

  let keysOfAllFactionGuardians: any[] = [];

  Object.values(guardians).forEach((faction) => {
    Object.values(faction).forEach((rarity) => {
      if (rarity.hasOwnProperty('assignedHeroes')) {
        rarity.assignedHeroes.forEach((assignedHero) => {
          Object.values(assignedHero).forEach((item) => {
            keysOfAllFactionGuardians.push(item);
          });
        });
      }
    });
  });

  return keysOfAllFactionGuardians;
}

type FactionIdHelper =
  | 'bannerlords'
  | 'barbarians'
  | 'darkelves'
  | 'demonspawn'
  | 'dwarves'
  | 'highelves'
  | 'knightsrevenant'
  | 'lizardmen'
  | 'ogryntribes'
  | 'orcs'
  | 'sacredorder'
  | 'samurai'
  | 'nyresanelves'
  | 'skinwalkers'
  | 'undeadhordes';

function getFactionsWithoutAllGuardians(account: AccountStore, rarity: string) {
  const factions: FactionIdHelper[] = [
    'bannerlords',
    'barbarians',
    'darkelves',
    'demonspawn',
    'dwarves',
    'highelves',
    'knightsrevenant',
    'lizardmen',
    'ogryntribes',
    'orcs',
    'sacredorder',
    'samurai',
    'nyresanelves',
    'skinwalkers',
    'undeadhordes',
  ];

  return factions.filter((faction) => {
    let guardians;
    if (account) {
      guardians = account.guardians[faction]?.[rarity as Rarity];
    }
    return !(guardians && guardians.assignedHeroes && guardians.assignedHeroes.length === 5);
  });
}

export function getPotentialFactionGuardiansList(
  champions: ChampionInstance[],
  store:
    | { accounts: { selectedAccount: { guardians: Record<FactionId, Record<Rarity, GuardianData>> | undefined } } }
    | undefined
) {
  if (!store) return [];
  let champCounts: Record<string, number> = {};
  let champsWithDupes = new Set();

  champions.forEach((champion) => {
    if (!champion.type.name) return;
    let key = champion.type.name;
    let v = key in champCounts ? champCounts[key] : 0;
    if (v !== undefined) {
      champCounts[key] = v + 1;
      if (v >= 1) {
        champsWithDupes.add(key);
      }
    }
  });

  const keysOfAllFactionGuardians = getFactionGuardians(store?.accounts?.selectedAccount?.guardians) || [];

  let champsThatHaveAtleastTwoCopiesThatAreNotFactionGuardians = Array.from(champsWithDupes).filter((champName) => {
    const champInstances = champions.filter((champ) => champ.type.name === champName);
    const nonGuardianInstances = champInstances.filter(
      (champ) => !keysOfAllFactionGuardians.includes(champ.instance.id)
    );
    return nonGuardianInstances.length >= 2;
  });

  return champsThatHaveAtleastTwoCopiesThatAreNotFactionGuardians;
}

export function getPotentialFactionGuardians(item: ChampionInstance, store: any) {
  const champions = store.accounts.selectedAccount?.champions;

  if (!item.type.name || !champions) return false;

  const champsThatHaveAtleastTwoCopiesThatAreNotFactionGuardians = getPotentialFactionGuardiansList(champions, store);

  const selectedAccount = store.accounts.selectedAccount;

  const rarity = item.type.rarity.toLowerCase();
  const faction = item.type.faction.toLowerCase() as FactionIdHelper;

  // Exclude common and uncommon champions
  if (rarity === 'common' || rarity === 'uncommon') {
    return false;
  }

  const factionsWithoutAllGuardians = getFactionsWithoutAllGuardians(selectedAccount, rarity);

  return (
    champsThatHaveAtleastTwoCopiesThatAreNotFactionGuardians.includes(item.type.name) &&
    factionsWithoutAllGuardians.includes(faction)
  );
}

export function getChampCounts(items: readonly ChampionItem[]) {
  let champCounts: Record<string, number> = {};
  if (!items) return champCounts; // add a check to ensure items is defined
  items.forEach((champion) => {
    const champName = champion.type.name;
    if (!champName) return;
    champCounts[champName] = (champCounts[champName] || 0) + 1;
  });
  return champCounts;
}

/*     if (keys.length > 1 && keys.includes(String(targetTypeKey)) && tagChecksPassed) {
      returnChamp = true;
    } else if (keys[0] === targetTypeKey && tagChecksPassed) {
      returnChamp = true;
    } */

export function getTargetUsingEffectsRelationData(selectedStatusEffect: string | null, effects: string | any[]) {
  let targetTypeKey: string | undefined;
  let relationEffectData: any;
  let arrayLength = effects.length;
  for (let i = 0; i < arrayLength; i++) {
    let currentlySelectedStatusEffect: string = '0';
    if (selectedStatusEffect !== null) {
      currentlySelectedStatusEffect = String(selectedStatusEffect);
    }
    //console.log(`currentlySelectedStatusEffect`, currentlySelectedStatusEffect)

    const thisStatusEffectTypeId = String(effects[i]?.applyStatusEffectParams?.statusEffectInfos[0]?.typeId);
    //console.log(`thisStatusEffectTypeId`, thisStatusEffectTypeId);

    //console.log(`Checking if ${thisStatusEffectTypeId} === ${currentlySelectedStatusEffect}`);

    if (
      (thisStatusEffectTypeId === currentlySelectedStatusEffect && currentlySelectedStatusEffect !== '0') ||
      currentlySelectedStatusEffect === '0'
    ) {
      if (effects[i]?.relation && effects[i]?.targetParams.targetType === 2) {
        relationEffectData = returnEffectWithThisId(effects, effects[i]?.relation?.effectTypeId);
      } else {
        relationEffectData = effects[i];
      }
    }

    let relationOfRelationEffectData;
    if (relationEffectData?.relation && relationEffectData?.targetParams?.targetType === 2) {
      relationOfRelationEffectData = returnEffectWithThisId(effects, relationEffectData?.relation?.effectTypeId);
    }

    if (relationOfRelationEffectData) {
      //console.log(`relationOfRelationEffectData`, relationOfRelationEffectData?.targetParams?.targetType);
      targetTypeKey = relationOfRelationEffectData?.targetParams?.targetType;
    } else {
      targetTypeKey = relationEffectData?.targetParams?.targetType;
    }
  }

  return targetTypeKey;
}

export function isString(value: string | boolean | undefined): value is string {
  return typeof value === 'string';
}

export function isPhrase(value: string | undefined, query: string) {
  if (typeof value === 'string') {
    return value.toLocaleLowerCase().includes(query.toLocaleLowerCase());
  } else {
    return false;
  }
}

export function getQueryParams() {
  const searchParams = new URLSearchParams(window.location.search);
  return Object.fromEntries(searchParams);
}

export function setQueryParams(id: string | number, filterValue: string) {
  const searchParams = new URLSearchParams(document.location.search);
  const params = Object.fromEntries(searchParams);

  if (filterValue) {
    params[id] = filterValue;
  } else if (params[id]) {
    delete params[id];
  }

  const newSearch = Object.entries(params)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  // Update localStorage
  //localStorage.setItem('saved_query_params', newSearch);

  window.history.pushState({}, '', `${newSearch ? `?${newSearch}` : ``}`);
}

export function saveFilterSettings(params: { [k: string]: string }) {
  try {
    //Going to need to use usePageSetting function here in order to make it set a page specific localStorage key here is the code for it...
    /*
      export function usePageSetting<K extends keyof Settings>(
        settingName: K
      ): [value: Settings[K], setValue: SetValue<Settings[K]>] {
        const { pathname } = useLocation();
        const initialValue = DefaultSettings[settingName];
        return useSettingInternal(`${pathname}:${settingName}` as unknown as K, initialValue);
      }
    */

    const currentQueryParams = Object.entries(params)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    localStorage.setItem('savedFilterState', JSON.stringify(params));
    localStorage.setItem('saved_query_params', currentQueryParams);
  } catch (e) {
    console.error('Error saving to localStorage', e);
  }
}

export function resetFilterSettings() {
  try {
    localStorage.removeItem('savedFilterState');
    localStorage.removeItem('saved_query_params');
    window.history.pushState({}, '', window.location.pathname);
  } catch (e) {
    console.error('Error removing from localStorage', e);
  }
}

export function loadFilterSettings() {
  const filterSettingsString = localStorage.getItem('savedFilterState');
  return filterSettingsString ? JSON.parse(filterSettingsString) : null;
}

export function setSearchParams() {
  let savedSearchParams = localStorage.getItem('saved_query_params');
  let searchParams = '';

  if (savedSearchParams !== null) {
    searchParams = new URLSearchParams(savedSearchParams);
  } else {
    searchParams = new URLSearchParams(document.location.search);
  }

  return searchParams;
}

export const saveCurrentFilters = () => {
  const searchParams = new URLSearchParams(document.location.search);
  const params = Object.fromEntries(searchParams);
  saveFilterSettings(params); // Pass in the current params
};

export function updateURLWithFilterSettings(filters: Array<any>) {
  const searchParams = new URLSearchParams(window.location.search);

  filters.forEach((filter) => {
    if (filter.id === undefined || filter.id === 'undefined') {
      // Ignore filters with undefined id
      return;
    }

    if (!filter.isDefaultValue()) {
      const value = filter.getURLRepresentation();

      if (value) {
        searchParams.set(filter.id, value);
      } else {
        searchParams.delete(filter.id);
      }
    }
  });

  //console.log(`searchParams`, searchParams);

  const newURL = `${window.location.protocol}//${window.location.host}${window.location.pathname}${
    searchParams.toString() === '' ? '' : '?'
  }${searchParams.toString()}`;
  window.history.pushState({ path: newURL }, '', newURL);
}

export type BlessingRarity = 'legendary' | 'epic' | 'rare';

interface Blessing {
  name: string;
  rarity: BlessingRarity;
}

const blessings: Blessing[] = [
  { name: 'Brimstone', rarity: 'legendary' },
  { name: 'Soul Reap', rarity: 'legendary' },
  { name: 'Lightning Cage', rarity: 'legendary' },
  { name: 'Intimidating Presence', rarity: 'legendary' },
  { name: 'Temporal Chains', rarity: 'legendary' },
  { name: 'Polymorph', rarity: 'legendary' },
  { name: 'Ward of the Fallen', rarity: 'legendary' },
  { name: 'Life Harvest', rarity: 'legendary' },
  { name: 'Crushing Rend', rarity: 'epic' },
  { name: 'Commanding Presence', rarity: 'epic' },
  { name: 'Cruelty', rarity: 'epic' },
  { name: 'Heavencast', rarity: 'epic' },
  { name: 'Iron Will', rarity: 'epic' },
  { name: 'Chainbreaker', rarity: 'epic' },
  { name: 'Incinerate', rarity: 'epic' },
  { name: 'Lethal Dose', rarity: 'epic' },
  { name: 'Phantom Touch', rarity: 'rare' },
  { name: 'Hero Soul', rarity: 'rare' },
  { name: "Hero's Soul", rarity: 'rare' },
  { name: 'Faultless Defence', rarity: 'rare' },
  { name: 'Faultless Defense', rarity: 'rare' },
  { name: 'Miracle Heal', rarity: 'rare' },
  { name: 'Carapace', rarity: 'rare' },
  { name: 'Survival Instinct', rarity: 'rare' },
  { name: 'Indomitable Spirit', rarity: 'rare' },
  { name: 'Dark Resolve', rarity: 'rare' },
];

export function getBlessingRarity(blessingName: string): BlessingRarity | null {
  const blessing = blessings.find((b) => b.name === blessingName);
  return blessing ? blessing.rarity : null;
}

export function getStatusEffectId(label: keyof typeof StatusEffectTypeId): number | undefined {
  return StatusEffectTypeId[label as any];
}

export function getRoleNameThatMatches(role: string | undefined): string | undefined {
  let roleToReturn;
  if (role === 'attack') {
    roleToReturn = 'offense';
  } else {
    roleToReturn = role;
  }
  return roleToReturn;
}

export function extractNumberAtEnd(str: string): number | null {
  const match = str.match(/\d+$/);
  return match ? parseInt(match[0], 10) : null;
}

export const exportDataToFile = async (info: Readonly<AccountInfo> | undefined) => {
  const accountId = info?.id;
  const dbName = `account_${accountId}`;
  // Debug: Log the dbName and accountId
  console.log('Debug: dbName:', dbName);
  console.log('Debug: accountId:', accountId);

  return new Promise<void>((resolve, reject) => {
    const request = indexedDB.open('raidchamps'); // Open the root database

    request.onerror = () => {
      console.error("Couldn't open IndexedDB.");
      reject("Couldn't open IndexedDB.");
    };

    request.onsuccess = async (event) => {
      const db = (event.target as IDBOpenDBRequest).result;

      // Debug: Log all object store names
      console.log('Debug: Available Object Stores:', Array.from(db.objectStoreNames));

      if (!db.objectStoreNames.contains(dbName)) {
        console.error(`Object Store ${dbName} not found.`);
        reject(`Object Store ${dbName} not found.`);
        return;
      }

      const transaction = db.transaction(dbName, 'readonly');
      const store = transaction.objectStore(dbName);
      const cursorRequest = store.openCursor();

      const exportedData: any[] = [];

      const avatar: string = info?.avatar || '';
      const avatarNumber: string = String(extractNumberAtEnd(avatar));

      cursorRequest.onsuccess = (e) => {
        const cursor = (e.target as IDBRequest).result as IDBCursorWithValue;
        if (cursor) {
          exportedData.push({ key: cursor.key, value: cursor.value });
          cursor.continue();
        } else {
          // Additional data
          const additionalData = {
            avatar: avatar,
            avatarId: avatarNumber,
            avatarUrl: `https://ik.imagekit.io/raidchamps/heroAvatars/${avatarNumber}.webp`,
            id: accountId,
            lastUpdated: info?.lastUpdated,
            level: info?.level,
            name: info?.name,
            power: info?.power,
          };
          exportedData.push({ key: 'additionalData', value: additionalData });
          // Done cursoring over object store
          const blob = new Blob([JSON.stringify(exportedData)], { type: 'text/json' });
          const a = document.createElement('a');
          const filename = `RaidChamps Account Data - ${info?.name}`;
          console.log(`filename`, filename);
          a.href = URL.createObjectURL(blob);
          a.download = filename;
          a.click();
          resolve();
        }
      };

      cursorRequest.onerror = (err) => {
        console.error(`Failed to cursor over ${dbName}`, err);
        reject(`Failed to cursor over ${dbName}`);
      };
    };
  });
};

export async function importDataToIndexedDB(data: any[]) {
  return new Promise<void>((resolve, reject) => {
    const request = indexedDB.open('raidchamps', 6); // Increment the version if needed

    let additionalData: any = {};

    data.forEach((item) => {
      if (item.key === 'additionalData') {
        additionalData = item.value;
      }
    });

    const accountDbName = `account_${additionalData.id}`;

    request.onupgradeneeded = (event) => {
      const db = (event.target as IDBOpenDBRequest).result;
      if (!db.objectStoreNames.contains('accounts')) {
        db.createObjectStore('accounts');
      }
      if (!db.objectStoreNames.contains(accountDbName)) {
        db.createObjectStore(accountDbName);
      }
    };

    request.onerror = (event) => {
      console.error("Couldn't open IndexedDB.", event);
      reject("Couldn't open IndexedDB.");
    };

    request.onsuccess = (event) => {
      const db = (event.target as IDBOpenDBRequest).result;
      const transaction = db.transaction(['accounts', accountDbName], 'readwrite');
      const accountsStore = transaction.objectStore('accounts');
      const accountDataStore = transaction.objectStore(accountDbName);

      // Add the rest of the data to the account-specific store
      data.forEach((item) => {
        if (item.key !== 'additionalData') {
          accountDataStore.put(item.value, item.key);
        }
      });

      // Add or update accountInfo
      const accountInfoEntry = [
        additionalData.id,
        {
          avatar: additionalData.avatar,
          avatarId: additionalData.avatarId,
          avatarUrl: additionalData.avatarUrl,
          id: additionalData.id,
          lastUpdated: additionalData.lastUpdated,
          level: additionalData.level,
          name: additionalData.name,
          power: additionalData.power,
        },
      ];

      const getInfoRequest = accountsStore.get('accountInfo');
      getInfoRequest.onsuccess = (e) => {
        const existingData = (e.target as IDBRequest).result as any[];

        if (existingData) {
          const existingIndex = existingData.findIndex((entry) => entry[0] === additionalData.id);

          if (existingIndex !== -1) {
            // Update existing entry
            existingData[existingIndex] = accountInfoEntry;
          } else {
            // Add new entry to existing array
            existingData.push(accountInfoEntry);
          }

          accountsStore.put(existingData, 'accountInfo');
        } else {
          // Create new array with the new data
          accountsStore.add([accountInfoEntry], 'accountInfo');
        }
      };

      // Update selectedAccountId
      accountsStore.put(additionalData.id, 'selectedAccountId');

      transaction.oncomplete = () => {
        resolve();
        window.location.reload();
      };

      transaction.onerror = () => {
        console.error("Couldn't complete the transaction.");
        reject("Couldn't complete the transaction.");
      };
    };
  });
}
