import React, { ChangeEventHandler } from 'react';
import { useSearchParams } from 'react-router-dom';
import { TextField, TextFieldProps } from '@mui/material';
import { action, makeObservable, observable } from 'mobx';
import { DataItem } from '../../../Types';
import { FilterDescriptor } from '../../../Model';
import { ListViewStore } from '../../../Model/ListViewStore';
import { observer } from 'mobx-react-lite';
import { loadFilterSettings } from '../Helpers';

export interface TextFilterProps<T extends DataItem = DataItem> {
  id: string;
  canHandle: (opts: any) => boolean;
  filter: (queryText: string, item: T) => boolean;
  inputProps?: Omit<TextFieldProps, 'onChange'>;
}

export class ObservableTextFilter<T extends DataItem = DataItem> implements FilterDescriptor<T> {
  private queryText: string = '';

  public isDefaultValue() {
    return this.queryText === '';
  }

  public getURLRepresentation() {
    return this.queryText;
  }

  // Add 'id' as a property.
  private id: string;
  //Even though it looks like 'id' is declared but its value is never read... it is needed to make it part of the filter objects. This make it so that filter.id in updateURLWithFilterSettings can access the id of each filter. Without it this.id will not explicitly be declared as an instance property, and thus will scope only to the constructor function itself. It won't be part of the filter objects when they are passed to updateURLWithFilterSettings, causing filter.id to be undefined.

  constructor(private readonly props: TextFilterProps<T>) {
    // Set 'id' from 'props'.
    this.id = props.id;
    const searchParams = new URLSearchParams(document.location.search);
    let value = searchParams.get(this.props.id);
    if (value) {
      this.queryText = value;
    } else {
      const filterSettings = loadFilterSettings();
      if (filterSettings && filterSettings[this.props.id]) {
        this.queryText = filterSettings[this.props.id];
      }
    }
    makeObservable<ObservableTextFilter<T>, 'handleChange' | 'queryText' | 'props'>(this, {
      queryText: observable,
      props: false,
      Component: false,
      canHandle: false,
      filterFn: false,
      render: false,
      handleChange: action,
    });
    //console.log(`Created TextFilter: id=${this.props.id}, queryText=${this.queryText}`);
  }

  get Component() {
    return this.render;
  }

  get canHandle() {
    return this.props.canHandle as any;
  }

  filterFn = (listView: ListViewStore, item: T) => {
    if (!this.props.canHandle(listView.options)) {
      return true;
    }
    return this.props.filter(this.queryText, item);
  };

  render = observer(() => {
    // URL Query Params
    const [searchParams, setSearchParams] = useSearchParams();
    const saveToQueryString = React.useCallback(() => {
      const id = this.props.id;
      const params = Object.fromEntries(searchParams);
      if (this.queryText) {
        params[id] = this.queryText;
      } else if (params[id]) {
        delete params[id];
      } else {
        return;
      }
      setSearchParams(params);
    }, [searchParams]);

    // Trigger saveToQueryString immediately when component renders
    React.useEffect(() => {
      saveToQueryString();
    }, [saveToQueryString]);

    return (
      <TextField
        {...this.props.inputProps}
        id={`${this.props.id}-select`}
        value={this.queryText}
        onChange={this.handleChange}
        onBlur={saveToQueryString}
      />
    );
  });

  private handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    this.queryText = event.target.value;
  };
}
