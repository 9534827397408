import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const Auth0LoginLogout = () => {
  const { user, isAuthenticated, isLoading, loginWithRedirect, logout } = useAuth0();

  const handleClick = () => {
    localStorage.setItem('return_url', window.location.pathname);
    loginWithRedirect();
  };

  /*   useEffect(() => {
    if (isAuthenticated) {
      // Call your Netlify function after a user logs in
      fetch('/.netlify/functions/updateRoles', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: user?.email }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error('Network response was not ok');
        })
        .then((data) => {
          console.log(data);
        })
        .catch((error) => {
          console.error('There was a problem calling the Netlify function:', error);
        });
    }
  }, [isAuthenticated, user?.email]); */

  if (isLoading) {
    return <></>;
  } else {
    return (
      <>
        {isAuthenticated ? (
          <button onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>Log Out</button>
        ) : (
          <>
            <button onClick={handleClick}>Login</button>
          </>
        )}
      </>
    );
  }
};

export default Auth0LoginLogout;
