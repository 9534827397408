import { makeObservable, observable, action, computed, autorun, reaction, runInAction, ObservableMap } from 'mobx';
import { AccountInfo } from '@raid-toolkit/webclient';
import { AccountStore } from './AccountStore';
import { createStore } from './Forage';

export class AccountManagerStore {
  public accounts: ObservableMap<string, AccountStore> = new ObservableMap();
  public selectedAccountId: string = '';
  private forage = createStore('accounts');

  constructor() {
    makeObservable<AccountManagerStore, 'initializeData'>(this, {
      accounts: observable,
      selectedAccountId: observable,
      selectedAccount: computed,
      discoverAccounts: action,
      initializeData: action.bound,
    });
    Promise.all([
      this.forage.getItem<string>('selectedAccountId'),
      this.forage.getItem<[string, AccountInfo][]>('accountInfo'),
    ]).then((args) => this.initializeData(...args));
  }

  private initializeData(selectedAccountId: string | null, accountInfo: [string, AccountInfo][] | null) {
    if (selectedAccountId) {
      this.selectedAccountId = selectedAccountId;
    }
    if (accountInfo) {
      this.accounts = new ObservableMap(accountInfo.map(([key, info]) => [key, new AccountStore(key, info)]));
    }
    reaction(
      () => this.selectedAccountId,
      (accountId) => this.forage.setItem('selectedAccountId', accountId)
    );
    reaction(
      () => Object.fromEntries(this.accounts),
      (accounts) =>
        this.forage.setItem(
          'accountInfo',
          Object.entries(accounts).map(([key, account]) => [key, account.info])
        )
    );
  }

  get selectedAccount(): AccountStore | undefined {
    return this.selectedAccountId ? this.accounts.get(this.selectedAccountId) : undefined;
  }

  discoverAccounts(accounts: AccountInfo[]) {
    let firstAccount: AccountStore | undefined;
    for (const account of accounts) {
      let accountStore = this.accounts.get(account.id);

      if (!accountStore) {
        this.accounts.set(account.id, (accountStore = new AccountStore(account.id)));
      }
      accountStore.updateInfo(account);
      firstAccount = accountStore;
    }
    if (this.accounts.size === 1 && firstAccount && !this.selectedAccountId) {
      this.selectedAccountId = firstAccount.info.id;
    }
  }
}
