import React from 'react';
import { Link } from 'react-router-dom';
import './About.css';
import { NextGenImage } from '../../Components/Library';
import { Helmet } from 'react-helmet-async';

export const About: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>About | RaidChamps.com</title>
        <meta
          name="description"
          content="Learn how RaidChamps.com works and what all the different icons on the champ avatars mean."
        />
        <meta itemProp="name" content="About | RaidChamps.com" />
        <meta
          itemProp="description"
          content="Learn how RaidChamps.com works and what all the different icons on the champ avatars mean."
        />
        <meta itemProp="image" content="https://ik.imagekit.io/raidchamps/_NotExtracted/logo.avif" />
        <meta property="og:url" content="https://raidchamps.com/mychamps" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="About | RaidChamps.com" />
        <meta
          property="og:description"
          content="Learn how RaidChamps.com works and what all the different icons on the champ avatars mean."
        />
        <meta property="og:image" content="https://ik.imagekit.io/raidchamps/_NotExtracted/logo.avif" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="About | RaidChamps.com" />
        <meta
          name="twitter:description"
          content="Learn how RaidChamps.com works and what all the different icons on the champ avatars mean."
        />
        <meta name="twitter:image" content="https://ik.imagekit.io/raidchamps/_NotExtracted/logo.avif" />
      </Helmet>
      <div className="about-page page">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 center">
              <div className="text-center">
                <h1 className="with-underline">About</h1>
              </div>
              <p>
                I created this site to have somewhere to go to easily search for and filter champions for Raid Shadow
                Legends. I developed it to be easy to use and easy to see most of the important things about each
                champion from the home page without having to click them to find what I was looking for. I also made
                sure to implement all the features that I felt were lacking from similar websites like the ability to
                filter & sort champions by various different metrics. It is mostly geared towards end game players but I
                could see it being useful for anybody who is endgame focused.{' '}
              </p>

              <h3>Multiple Tier Ratings</h3>
              <p>
                I also wanted to be able to compare the tier ratings from different sites/spreadsheets without having to
                look up the champion on each of them to see if they are all in agreement or not. I may add tier ratings
                from other sources eventually but for now I included HellHades, Aftershock United, and Ayumilove because
                they are the ones that I always check for each champion.
              </p>
              <p>
                Each of these sources has a different colors associated with the different tier levels, or no color at
                all. So I decided to standardize them on my site so that it would be really easy to look at the color of
                all the ratings that appears on the champion's image and see if they all are the same color or not. If
                they are not all the same color, that means there is some disagreement about what tier that champion.
              </p>
              <p>
                I figured the most obvious way to determine the color of each tier level would be to use the same colors
                and have them ranked in the same order that champion rarity is in the game. Like so...
              </p>
              <p className="Legendary">Legendary Tier Ratings (Best)</p>
              <p className="Epic">Epic Tier Ratings</p>
              <p className="Rare">Rare Tier Ratings</p>
              <p className="Uncommon">Uncommon Tier Ratings</p>
              <p className="Common">Common Tier Ratings (Worst)</p>
              <p>That is easy to remember right?</p>
              <p>
                Each of the rating sources I chose just happened to use a different rating system too, which makes it
                really easy to differentiate between each of them. HellHades uses whole numbers, ChoseN uses numbers
                with decimals on a 10 point scale and Ayumilove uses letters. But just like all the icons I pulled into
                each champion image, you can hover over them and they will tell you what they are. I also added links to
                each of the ratings that will take you to that champion on the corresponding site/spreadsheet.
              </p>

              <h3 id="CalculatedRaidChampsStarRating">Calculated RaidChamps Star Ratings</h3>
              <div className="CalculatedRaidChampsStarRating-wrap">
                <p>
                  <strong>
                    <u>0-20.99</u>
                  </strong>{' '}
                  <span className="oneStar">★☆☆☆☆</span>
                </p>
                <p>
                  <strong>
                    <u>21-40.99</u>
                  </strong>{' '}
                  <span className="twoStar">★★☆☆☆</span>
                </p>
                <p>
                  <strong>
                    <u>41-60.99</u>
                  </strong>{' '}
                  <span className="threeStar">★★★☆☆</span>
                </p>
                <p>
                  <strong>
                    <u>61-80.99</u>
                  </strong>{' '}
                  <span className="fourStar">★★★★☆</span>
                </p>
                <p>
                  <strong>
                    <u>81-100</u>
                  </strong>{' '}
                  <span className="fiveStar">★★★★★</span>
                </p>
              </div>
              <p>
                I have calculated my own star rating for each champ by converting each of the 3 completely different
                rating systems(HellHades, ChoseN, & Ayumilove) to a base 100 system. Then all I had to do was add them
                all up and divide by 3 to get a overall base 100 rating for each champ which I use to determine how many
                stars they each get.
              </p>

              <p>
                So basically you can now look at the star ratings under each champ's image for an overall rating that
                averages all the other scores displayed in the bottom right corner of each champ's image.
              </p>

              {/*             <h3>Farmable Indicator</h3>
            <NextGenImage
whichDirectory="_NotExtracted/myCreations"
whichImage="farmable"
              title="Farmable Champion"
              alt="Farmable Champion"
            />
            <p>
              To easily see which champions are farmable, look for this icon just below the top right corner of their
              image if they are farmable. When hovering over this icon it will tell you where they can be farmed.
            </p> */}

              <h3>Damage Based On Indicators</h3>
              <div className="hit-damageBasedOn-img-wrap Attack">
                <NextGenImage
                  whichDirectory="_NotExtracted/myCreations/ItemSetIcons"
                  whichImage="Offense"
                  title="Damage Based On Attack"
                  alt="Damage Based On Attack"
                />
              </div>
              <div className="hit-damageBasedOn-img-wrap Defense">
                <NextGenImage
                  whichDirectory="_NotExtracted/myCreations/ItemSetIcons"
                  whichImage="Defense"
                  title="Damage Based On Defense"
                  alt="Damage Based On Defense"
                />
              </div>
              <div className="hit-damageBasedOn-img-wrap HP">
                <NextGenImage
                  whichDirectory="_NotExtracted/myCreations/ItemSetIcons"
                  whichImage="Life"
                  title="Damage Based On HP"
                  alt="Damage Based On HP"
                />
              </div>
              <div className="hit-damageBasedOn-img-wrap N-A">
                <NextGenImage
                  whichDirectory="_NotExtracted/myCreations/ItemSetIcons"
                  whichImage="Curing"
                  title="Support champs that doesn't do damage"
                  alt="Support champs that doesn't do damage"
                />
              </div>
              <p>These show what stat the damage for each champions abilities is based on.</p>

              <h3 id="WorthBooking">Worth Booking Indicators</h3>
              <p className="Bookline semi-transparent">
                <NextGenImage
                  whichDirectory="_NotExtracted/myCreations/Books"
                  whichImage="Skillbook_Rare"
                  title="Book Value of 5 or lower"
                  alt="Book Value of 5 or lower"
                />
              </p>
              <p className="Bookline">
                <NextGenImage
                  whichDirectory="_NotExtracted/myCreations/Books"
                  whichImage="Skillbook_Rare"
                  title="Book Value of 6 or 7"
                  alt="Book Value of 6 or 7"
                />
              </p>
              <p className="Bookline">
                <NextGenImage
                  whichDirectory="_NotExtracted/myCreations/Books"
                  whichImage="Skillbook_Epic"
                  title="Book Value of 8 or 9"
                  alt="Book Value of 8 or 9"
                />
              </p>
              <p className="Bookline">
                <NextGenImage
                  whichDirectory="_NotExtracted/myCreations/Books"
                  whichImage="Skillbook_Legendary"
                  title="Book Value of 10"
                  alt="Book Value of 10"
                />
              </p>
              <p>
                The type of book that appears at the top of each champ's avatar image indicates how worth booking each
                champion is based on HellHades book value. A rare worth booking indicator will only appear if the
                champ's book value is 6 or 7, a epic book indicator is used for book values of 8 or 9, and only those
                with a book value of 10 will get a legendary book indicator. If their book value is 5 or less, a
                semi-transparent rare book is displayed. If I have not added the book ratings for a champ yet, nothing
                will be displayed.{' '}
                <b>
                  Hovering over this indicator will still give their book value and tell you which abilities the books
                  should go into
                </b>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
