import React, { useEffect } from 'react';
import './ChampListPage.css';
import { ChampionList } from '../ChampionList';
import { useStaticData } from '../../Data';
import { ChampionItem, FactionId, Rarity } from '../../Types';
import { SharedFilters } from './SharedFilters';
import { Key } from '../Layout/Key';
import { useListView, useRootStore } from '../../Model';
import { observer } from 'mobx-react-lite';
import ToggleButton from '@mui/material/ToggleButton';
import { ViewType } from '../DataView/ViewOptions';
import { usePageSetting, withViewType } from '../Hooks';
import { AllChampsSearchFilter } from 'Components/DataView/Filters';
import { Helmet } from 'react-helmet-async';
import HexagonGrid from 'Components/HexagonGrid';

export const AllChamps: React.FC = withViewType(
  ViewType.Index,
  observer(() => {
    const root = useRootStore();
    const [MyChampsOnlyBoolean, setMyChampsOnlyBoolean] = usePageSetting('my-champs-only');

    useEffect(() => {
      const bodyTag = document.getElementsByTagName('body')[0];

      if (MyChampsOnlyBoolean) {
        bodyTag?.classList.add('MyChampsOnly');
        bodyTag?.classList.remove('AllChamps');
      } else {
        bodyTag?.classList.remove('MyChampsOnly');
        bodyTag?.classList.add('AllChamps');
      }
    }, [MyChampsOnlyBoolean]);

    const myChampSlugs = root.accounts.selectedAccount?.ownedChampionSlugs;

    const champions = React.useMemo<ChampionItem[]>(
      () =>
        Object.values(useStaticData().heroTypes)
          .filter((heroType) => {
            const heroTypeId = heroType.typeId;
            if (heroTypeId % 10 === 6 || heroType.rarity === Rarity.common) {
              return heroType.faction !== FactionId.unknown;
            }
            return false;
          })
          .map((type) => ({ type, key: `type_${type.slug}` })),
      []
    );

    const listView = useListView();
    const items = listView.filterAndSort(champions);

    /*     if (window.location.hostname === 'localhost') {
      console.log(`items`, items);
    } */

    return (
      <div className="allchamps-page">
        <Helmet>
          <title>RaidChamps.com</title>
          <meta name="description" content="Search, Sort & Filter through all the champions in Raid: Shadow Legends" />
          <meta itemProp="name" content="RaidChamps.com" />
          <meta
            itemProp="description"
            content="Search, Sort & Filter through all the champions in Raid: Shadow Legends"
          />
          <meta itemProp="image" content="https://ik.imagekit.io/raidchamps/_NotExtracted/logo.avif" />
          <meta property="og:url" content="https://raidchamps.com/mychamps" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="RaidChamps.com" />
          <meta
            property="og:description"
            content="Search, Sort & Filter through all the champions in Raid: Shadow Legends"
          />
          <meta property="og:image" content="https://ik.imagekit.io/raidchamps/_NotExtracted/logo.avif" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="RaidChamps.com" />
          <meta
            name="twitter:description"
            content="Search, Sort & Filter through all the champions in Raid: Shadow Legends"
          />
          <meta name="twitter:image" content="https://ik.imagekit.io/raidchamps/_NotExtracted/logo.avif" />
        </Helmet>
        <div
          className="AllChampsPage ChampListPage ListPage"
          style={{ display: 'flex', flexDirection: 'column', padding: 15 }}
        >
          <h1 className="with-underline deep-underline center">All Champs</h1>
          <AllChampsSearchFilter.Component />
          <SharedFilters />
          <div className="TogglesRow-wrap">
            <div className="ToggleButton-wrap singleToggle-wrap">
              <ToggleButton
                className="MyChampsOnlyToggle"
                value="check"
                selected={MyChampsOnlyBoolean}
                onChange={() => {
                  setMyChampsOnlyBoolean(!MyChampsOnlyBoolean);
                }}
              >
                <span>My Champs Only</span>
              </ToggleButton>
            </div>
          </div>
          <div style={{ flex: 1 }}>
            <div className="center">
              Displaying <span className="yellow champCount">{items.length}</span> champs
            </div>
            <div className="ChampionList-wrap">
              <ChampionList items={items} myChampsSlugs={myChampSlugs} />
            </div>
          </div>
        </div>
        <Key />
      </div>
    );
  })
);
