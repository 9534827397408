import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import { Link } from 'react-router-dom';
import { hasRole } from './Header';

const Auth0Subscribe = (roles: any) => {
  const { user, isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <></>;
  } else {
    return (
      <>
        {isAuthenticated && user && roles && roles.roles ? (
          <div className="ProfileLinkAndDivider-wrap">
            {roles.roles.length > 0 && hasRole(roles.roles, ['Content Creator', 'Advanced Features']) && (
              <span className="white vdivider"> | </span>
            )}
            <Link to="/profile">Profile</Link>
          </div>
        ) : (
          <></>
        )}
      </>
    );
  }
};

export default Auth0Subscribe;
