import React from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import './NavLinks.css';

export interface NavLinksProps {
  className?: string;
}

export const NavLinks: React.FC<NavLinksProps> = ({ className, children }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const navigateWithQueryParams = (to: string) => {
    navigate(to, { search: location.search });
  };

  return (
    <nav role="navigation">
      <ul className={['navbar-nav', className].filter(Boolean).join(' ')}>
        <li className="nav-item">
          <NavLink to="/" onClick={() => navigateWithQueryParams('/')}>
            All Champs
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/mychamps" onClick={() => navigateWithQueryParams('/mychamps')}>
            My Champs
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/myartifacts" onClick={() => navigateWithQueryParams('/myartifacts')}>
            My Artifacts
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/sharemyteam" onClick={() => navigateWithQueryParams('/sharemyteam')}>
            Share My Team
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/hotkeys" onClick={() => navigateWithQueryParams('/hotkeys')}>
            Hotkeys
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/about" onClick={() => navigateWithQueryParams('/about')}>
            About
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/contribute" onClick={() => navigateWithQueryParams('/contribute')}>
            Contribute
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/contact" onClick={() => navigateWithQueryParams('/contact')}>
            Contact
          </NavLink>
        </li>
        {children}
      </ul>
    </nav>
  );
};

export default NavLinks;
