export enum Tag {
  arenafirst = 'arenafirst',
  arenasecond = 'arenasecond',
  first = 'first',
  second = 'second',
  third = 'third',
  attack = 'attack',
  defense = 'defense',
  favorite = 'favorite',
  speed = 'speed',
  support = 'support',
}
