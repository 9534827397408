import React from 'react';
import { LinearProgress } from '@mui/material';
import { LoadState, LoadStateEvent } from 'Data';

export const LoaderBar: React.FC = () => {
  const [{ chunksLoaded, totalChunks }, setState] = React.useState<LoadStateEvent>({ chunksLoaded: 0, totalChunks: 1 });
  React.useEffect(() => {
    LoadState.on('change', setState);
    return () => {
      LoadState.off('change', setState);
    };
  }, []);
  return chunksLoaded !== totalChunks ? (
    <div className="loaderBar">
      <div>
        <LinearProgress variant="determinate" value={(chunksLoaded / totalChunks) * 100} />
      </div>
    </div>
  ) : null;
};
