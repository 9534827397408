import React, { useState, useEffect } from 'react';
import { useHotkeysContext } from './HotkeyProvider';

const shiftKeyCodes = {
  Digit1: '1',
  Digit2: '2',
  Digit3: '3',
  Digit4: '4',
  Digit5: '5',
  Digit6: '6',
  Digit7: '7',
  Digit8: '8',
  Digit9: '9',
  Digit0: '0',
  Minus: '-',
  Equal: '=',
  BracketLeft: '[',
  BracketRight: ']',
  Backslash: '\\',
  Semicolon: ';',
  Quote: "'",
  Comma: ',',
  Period: '.',
  Slash: '/',
  Backquote: '`',
};

interface HotkeyComponentProps {
  storageKey: string;
}

function HotkeyComponent({ storageKey }: HotkeyComponentProps): JSX.Element {
  const { setResetFiltersHotkey, setSaveFiltersHotkey, setToggleChampPagesHotkey } = useHotkeysContext();

  const initialHotkey = localStorage.getItem(storageKey) || '';
  const [hotkey, setHotkey] = useState(initialHotkey);
  const [listening, setListening] = useState(false);
  const [tempHotkey, setTempHotkey] = useState('');

  const handleKeyPress = (event: KeyboardEvent) => {
    if (listening) {
      let newHotkey = '';
      if (event.ctrlKey) newHotkey += 'Control+';
      if (event.altKey) newHotkey += 'Alt+';
      if (event.shiftKey) newHotkey += 'Shift+';

      if (!['ControlLeft', 'ShiftLeft', 'AltLeft', 'ControlRight', 'ShiftRight', 'AltRight'].includes(event.code)) {
        newHotkey += shiftKeyCodes[event.code as keyof typeof shiftKeyCodes] || event.key;

        setHotkey(newHotkey);
        localStorage.setItem(storageKey, newHotkey);
        setListening(false);
      } else {
        setTempHotkey(newHotkey);
      }
    }
  };

  const handleKeyUp = (event: KeyboardEvent) => {
    setTempHotkey('');
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    document.addEventListener('keyup', handleKeyUp);

    if (storageKey === 'resetFiltersHotkey') {
      setResetFiltersHotkey(hotkey);
    }

    if (storageKey === 'saveFiltersHotkey') {
      setSaveFiltersHotkey(hotkey);
    }

    if (storageKey === 'togglePagesHotkey') {
      setToggleChampPagesHotkey(hotkey);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [hotkey, listening]);

  const handleInputFocus = () => {
    setListening(true);
  };

  const handleInputBlur = () => {
    if (tempHotkey) {
      setTempHotkey('');
    }
    setListening(false);
  };

  const handleInputClick = () => {
    setHotkey('');
    localStorage.removeItem(storageKey);
  };

  return (
    <div>
      <input
        id="hotkey-input"
        type="text"
        value={listening ? tempHotkey : hotkey}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        onClick={handleInputClick}
        style={{ width: 'fit-content', minWidth: '100px' }}
        readOnly
      />
    </div>
  );
}

export default HotkeyComponent;
