import { useEffect, useState } from 'react';

const MOBILE_USER_AGENT_REGEX = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;

function useIsMobile() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent;
    setIsMobile(MOBILE_USER_AGENT_REGEX.test(userAgent));
  }, []);

  return isMobile;
}

export default useIsMobile;
