import React from 'react';
import { FactionId } from '../../Types';
import { getFactions } from '../../Data';
import { NextGenImage } from '../../Components/Library';

export interface FactionProps {
  className?: string;
  style?: React.CSSProperties;
  faction: FactionId | undefined;
  local?: boolean;
}

export const FactionBanner: React.FC<FactionProps> = ({ faction, style, className, local }) => {
  const factionData = getFactions()[faction];
  return (
    <div className="info" title={`${factionData?.name} Faction`}>
      <NextGenImage
        whichDirectory={factionData?.imageDirectory}
        whichImage={factionData?.imageName}
        className={className}
        style={style}
        local={local}
      />
    </div>
  );
};
