import { NextGenImage } from 'Components';
import React from 'react';

export interface ChampMasteryScrollsProps {
  totalMasteryScrolls: any;
  local?: boolean;
}

export const ChampMasteryScrolls: React.FC<ChampMasteryScrollsProps> = ({ totalMasteryScrolls, local }) => {
  //const champMasteryScrollsData = getChampMasteryScrollsData(champMasteryScrolls);
  return (
    <div className="masteryScrolls-wrap">
      {totalMasteryScrolls?.Bronze > 0 && (
        <div className="masteryScrollsAndNumbers-wrap">
          <NextGenImage
            local={local}
            whichDirectory="_NotExtracted/myCreations/masteryScrolls"
            whichImage="BronzeMasteryScroll"
            title={`${totalMasteryScrolls.Bronze} Bronze Mastery Scrolls`}
            alt={`${totalMasteryScrolls.Bronze} Bronze Mastery Scrolls`}
          />{' '}
          <div className={`totalMasteryScrolls Bronze`}>{totalMasteryScrolls.Bronze}</div>
        </div>
      )}
      {totalMasteryScrolls?.Silver > 0 && (
        <div className="masteryScrollsAndNumbers-wrap">
          <NextGenImage
            local={local}
            whichDirectory="_NotExtracted/myCreations/masteryScrolls"
            whichImage="SilverMasteryScroll"
            title={`${totalMasteryScrolls.Silver} Silver Mastery Scrolls`}
            alt={`${totalMasteryScrolls.Silver} Silver Mastery Scrolls`}
          />{' '}
          <div className={`totalMasteryScrolls Silver`}>{totalMasteryScrolls.Silver}</div>
        </div>
      )}
      {totalMasteryScrolls?.Gold > 0 && (
        <div className="masteryScrollsAndNumbers-wrap">
          <NextGenImage
            local={local}
            whichDirectory="_NotExtracted/myCreations/masteryScrolls"
            whichImage="GoldMasteryScroll"
            title={`${totalMasteryScrolls.Gold} Gold Mastery Scrolls`}
            alt={`${totalMasteryScrolls.Gold} Gold Mastery Scrolls`}
          />{' '}
          <div className={`totalMasteryScrolls Gold`}>{totalMasteryScrolls.Gold}</div>
        </div>
      )}
    </div>
  );
};
