// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".grFGpTUi1A4MDDCHtWOQ {\n  position: absolute;\n  margin: 0 auto;\n  width: 100%;\n  -webkit-animation: AHEePNvZLe33XWl8ZUp5 3s ease-in-out;\n  animation: AHEePNvZLe33XWl8ZUp5 3s ease-in-out;\n  opacity: 0;\n  right: 0;\n}\n\n@-webkit-keyframes AHEePNvZLe33XWl8ZUp5 {\n  0% {\n    opacity: 0;\n  }\n  10% {\n    opacity: 1;\n  }\n  90% {\n    opacity: 1;\n  }\n  100% {\n    opacity: 0;\n  }\n}\n\n@keyframes AHEePNvZLe33XWl8ZUp5 {\n  0% {\n    opacity: 0;\n  }\n  10% {\n    opacity: 1;\n  }\n  90% {\n    opacity: 1;\n  }\n  100% {\n    opacity: 0;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/Components/Library/BoundSortedEditor.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,cAAc;EACd,WAAW;EACX,sDAA2C;EAC3C,8CAAmC;EACnC,UAAU;EACV,QAAQ;AACV;;AAEA;EACE;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;AACF;;AAEA;EACE;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;AACF","sourcesContent":[".message {\n  position: absolute;\n  margin: 0 auto;\n  width: 100%;\n  -webkit-animation: fadeInOut 3s ease-in-out;\n  animation: fadeInOut 3s ease-in-out;\n  opacity: 0;\n  right: 0;\n}\n\n@-webkit-keyframes fadeInOut {\n  0% {\n    opacity: 0;\n  }\n  10% {\n    opacity: 1;\n  }\n  90% {\n    opacity: 1;\n  }\n  100% {\n    opacity: 0;\n  }\n}\n\n@keyframes fadeInOut {\n  0% {\n    opacity: 0;\n  }\n  10% {\n    opacity: 1;\n  }\n  90% {\n    opacity: 1;\n  }\n  100% {\n    opacity: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"message": "grFGpTUi1A4MDDCHtWOQ",
	"fadeInOut": "AHEePNvZLe33XWl8ZUp5"
};
export default ___CSS_LOADER_EXPORT___;
