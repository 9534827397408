import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import ProductsList from './ProductsList';

const stripePromise = loadStripe('pk_live_c14jl1Iq7eyEAPud2cChZT3s');

type IProduct = {
  id: string;
  name: string;
  description: string;
  price: number;
  currency: string;
  interval: string;
};

interface ProductOptionsProps {
  products: IProduct[];
  selectedProduct: IProduct | undefined;
  setSelectedProduct: Dispatch<SetStateAction<IProduct | undefined>>;
}

const ProductOptions = ({ products, selectedProduct, setSelectedProduct }: ProductOptionsProps) => {
  const handleProductSelection = (event: SelectChangeEvent<string>) => {
    const productId = event.target.value;
    const product = products.find((product: { id: string }) => product.id === productId);
    setSelectedProduct(product);
  };

  const groupAndSort = (products: IProduct[]): IProduct[] => {
    const groups: { [name: string]: IProduct[] } = {};
    const zeroCostProducts: IProduct[] = [];

    products.forEach((product) => {
      if (product.price === 0) {
        zeroCostProducts.push(product);
      } else {
        if (!groups[product.name]) {
          groups[product.name] = [];
        }
        groups[product.name].push(product);
      }
    });

    const sortedGroups = Object.values(groups).sort((a, b) => {
      const lowestMonthlyPriceA = Math.min(
        ...a.filter((product) => product.interval === 'month').map((product) => product.price)
      );
      const lowestMonthlyPriceB = Math.min(
        ...b.filter((product) => product.interval === 'month').map((product) => product.price)
      );

      if (lowestMonthlyPriceA === lowestMonthlyPriceB) {
        return a[0].name.localeCompare(b[0].name);
      }

      return lowestMonthlyPriceA - lowestMonthlyPriceB;
    });

    const sortedProducts = [...zeroCostProducts];
    sortedGroups.forEach((group) => {
      const sortedGroup = group.sort((a, b) => {
        if (a.interval === 'month' && b.interval === 'year') {
          return -1;
        }
        if (a.interval === 'year' && b.interval === 'month') {
          return 1;
        }
        return a.price - b.price;
      });
      sortedProducts.push(...sortedGroup);
    });

    return sortedProducts;
  };

  const sortedProducts = groupAndSort(products);

  return (
    <FormControl fullWidth style={{ marginLeft: '0' }}>
      <InputLabel id="product-select-label">
        Click here to select which option you would like to subscribe to.
      </InputLabel>
      <Select
        labelId="product-select-label"
        id="product-select"
        value={selectedProduct ? selectedProduct.id : ''}
        label="Select a Powers"
        onChange={handleProductSelection}
        className="SubscriptionOptions"
      >
        {sortedProducts.map((product) => (
          <MenuItem key={product.id} value={product.id}>
            {product.name} - ${product.price / 100}/{product.interval}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const Auth0Upgrade: React.FC<{ user: any }> = ({ user }) => {
  const [selectedProduct, setSelectedProduct] = useState<IProduct | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [products, setProducts] = useState<IProduct[]>([]);

  const userRoles = user['https://raidchamps.com/app_metadata'].roles;
  console.log(`userRoles`, userRoles);

  useEffect(() => {
    console.log('Fetching products...');
    const fetchProducts = async () => {
      const response = await fetch(`/.netlify/functions/getStripeProducts`);
      console.log('Received products response:', response);
      const data: IProduct[] = await response.json();
      console.log('Received products data:', data);

      // Filter out products that match the user's roles, ignoring "(Monthly)" or "(Yearly)", and exclude "Free" products
      const filteredProducts = data.filter((product) => {
        if (product.name === 'Free') return false; // Exclude "Free" products

        return !userRoles.some((role) => {
          const roleNameWithoutInterval = role.replace(/ \(Monthly\)| \(Yearly\)/, '');
          return roleNameWithoutInterval === product.name;
        });
      });

      setProducts(filteredProducts);
    };

    fetchProducts().catch((error) => {
      console.error('Error fetching products:', error);
    });
  }, [userRoles]);

  let handleUnsubscribe: ((user: { subscriptions: any[] }) => Promise<void>) | ((arg0: any) => void);

  handleUnsubscribe = async (user: { app_metadata: {}; id: string }) => {
    if (!selectedProduct) return;

    const roleToRemove = selectedProduct.name;

    console.log('roleToRemove:', roleToRemove);

    const userRoles = user['https://raidchamps.com/app_metadata'].roles;
    console.log(`userRoles`, userRoles);

    const userSubscriptionIds = user['https://raidchamps.com/app_metadata'].subscriptionIds;

    console.log(`userSubscriptionIds`, userSubscriptionIds);

    if (!userRoles || userRoles.length === 0) {
      // User is not subscribed to any plan
      return;
    }

    setLoading(true);

    // Get the subscription ID of the first active subscription
    const subscriptionId = userSubscriptionIds[0];

    console.log(`subscriptionId`, subscriptionId);

    if (!subscriptionId) {
      // User does not have any active subscription
      return;
    }

    const event = {
      body: JSON.stringify({
        user,
        subscriptionId,
        roleToRemove,
        selectedProduct,
      }),
    };

    // Cancel the subscription in Stripe
    const unsubscribeResponse = await fetch('/.netlify/functions/handle-unsubscribe', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(event),
    });

    if (!unsubscribeResponse.ok) {
      // Failed to cancel the subscription
      console.error('Failed to cancel the subscription:', unsubscribeResponse);
      return;
    }

    setLoading(false);
  };

  const handleUpgrade = async () => {
    if (!selectedProduct) return;

    setLoading(true);
    const stripe = await stripePromise;

    console.log('selectedProduct:', selectedProduct);

    const response = await fetch('/.netlify/functions/checkout_sessions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        customerEmail: user.email,
        priceId: selectedProduct.id,
      }),
    });

    const session = await response.json();

    console.log('session:', session);

    const successUrl = `https://raidchamps.com/success?userEmail=${encodeURIComponent(
      user.email
    )}&productId=${encodeURIComponent(selectedProduct.id)}`;

    const result = await stripe?.redirectToCheckout({
      lineItems: [
        {
          price: selectedProduct.id,
          quantity: 1,
        },
      ],
      mode: 'subscription',
      successUrl: successUrl,
      cancelUrl: 'https://raidchamps.com/cancel',
    });
    console.log('result:', result);

    setLoading(false);
  };

  const handleUpgradePaypal = async () => {
    console.log('handleUpgradePaypal invoked');
    setLoading(true);

    // Map selectedProduct to PayPal plan ID
    let paypalPlanId;
    if (selectedProduct?.name === 'Advanced Features' && selectedProduct?.interval === 'month') {
      paypalPlanId = 'P-050323644N559310SMUR6OUI';
    } else if (selectedProduct?.name === 'Advanced Features' && selectedProduct?.interval === 'year') {
      paypalPlanId = 'P-8JS29896E8980971YMUR6PAY';
    }

    if (!paypalPlanId) {
      console.error('Invalid product selected');
      setLoading(false);
      return;
    }

    // Call your Netlify function to handle the PayPal subscription
    try {
      // Set subscriptionPower to match Plan Name
      const subscriptionPower = `${selectedProduct?.name} (${
        selectedProduct?.interval === 'month' ? 'Monthly' : 'Yearly'
      })`;

      const requestBody = {
        subscriptionPower,
        userEmail: user.email, // Assuming `user` object has an `email` field
      };
      console.log('Sending request body:', requestBody);

      console.log('Sending request to Netlify function'); // Log before sending request
      const response = await fetch('/.netlify/functions/checkout_sessions_paypal', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        const text = await response.text();
        console.error(`Server responded with an error: ${text}`); // Log server error
        setLoading(false);
        return; // Exit the function here, no need to proceed further
      }

      const data = await response.json();
      console.log(`Server responded with: ${JSON.stringify(data)}`); // Log server response

      // Redirect to PayPal checkout
      if (data.approval_url) {
        window.location.href = data.approval_url;
      } else {
        console.error('Failed to get approval URL');
      }
    } catch (error) {
      console.error('Fetch failed:', error);
      setLoading(false);
    }
  };

  const roles = user['https://raidchamps.com/app_metadata'].roles;

  console.log('user:', user);
  console.log('products:', products);
  console.log('selectedProduct:', selectedProduct);
  console.log('loading:', loading);
  if (roles && roles.length <= 0) {
    return (
      <div>
        <h2>Current Powers</h2>
        <p>{roles[0]}</p>
        <h3>Powers</h3>
        {console.log('Powers', products)}
        <ProductsList products={products} roles={roles} />
        <ProductOptions products={products} selectedProduct={selectedProduct} setSelectedProduct={setSelectedProduct} />
        <button onClick={handleUpgrade} disabled={!selectedProduct || loading}>
          {loading ? 'Loading...' : 'Subscribe via Stripe'}
        </button>
        <button onClick={handleUpgradePaypal} disabled={!selectedProduct || loading}>
          {loading ? 'Loading...' : 'Subscribe via Paypal'}
        </button>
      </div>
    );
  } else {
    return (
      <div>
        <h2>Gain Powers</h2>
        <p>Below are the powers available for you to subscribe to...</p>
        <ProductsList products={products} roles={['']} />
        <ProductOptions products={products} selectedProduct={selectedProduct} setSelectedProduct={setSelectedProduct} />
        <button onClick={handleUpgrade} disabled={!selectedProduct || loading}>
          {loading ? 'Loading...' : 'Subscribe via Stripe'}
        </button>
        <button onClick={handleUpgradePaypal} disabled={!selectedProduct || loading}>
          {loading ? 'Loading...' : 'Subscribe via Paypal'}
        </button>
        <p className="red">
          The Subscribe via Paypal option still needs some work to get it to automatically give you access to the
          Advanced Features immediately after subscribing but I can always give you access manually as soon as I notice
          you have subscribed.
        </p>
      </div>
    );
  }
};

export default Auth0Upgrade;
