import { ViewType } from '../DataView/ViewOptions';
import React from 'react';

const ViewTypeContext = React.createContext(ViewType.Index);

export interface ViewTypeProviderProps {
  viewType: ViewType;
}
export const ViewTypeProvider: React.FC<ViewTypeProviderProps> = ({ viewType, children }) => (
  <ViewTypeContext.Provider value={viewType}>{children}</ViewTypeContext.Provider>
);

export function useViewType(): ViewType {
  return React.useContext(ViewTypeContext);
}

export function withViewType<P extends JSX.IntrinsicAttributes>(
  viewType: ViewType,
  Component: React.ComponentType<P>
): React.FC<P> {
  return (props: P) => (
    <ViewTypeProvider viewType={viewType}>
      <Component {...props} />
    </ViewTypeProvider>
  );
}
